<template>
    <div :class="[
    'component',
    'component__note',
    !danger && !success && !custom ? 'component__note--default' : '',
    danger ? 'component__note--danger' : '',
    success ? 'component__note--success' : '',
    custom ? 'component__note--custom' : '',
    margin ? `component__note--margin-${margin}` : '',
    whiteSpace ? `component__note--white-space-${whiteSpace}` : ''
    ]">
        <div class="columns is-vcentered is-variable is-2">
            <div class="column is-narrow">
                <div class="component__note_icon">
                    <template v-if="danger">
                        <v-icon :name="iconName || 'alert-triangle'"/>
                    </template>
                    <template v-else-if="success">
                        <v-icon :name="iconName || 'check'"/>
                    </template>
                    <template v-else>
                        <v-icon :name="iconName || 'info'"/>
                    </template>
                </div>
            </div>
            <div class="column">
                <div class="columns is-vcentered">
                    <div class="column">
                        <span><slot></slot></span>
                    </div>
                    <template v-if="linkActive">
                        <div :class="[
                        'column',
                        'is-narrow'
                    ]">
                            <component-button
                                :name="'component-note-link'"
                                :text="!linkButton"
                                :to="linkTo"
                                :size="'normal'"
                                :underline="!linkButton"
                                @click="emitLink"
                            >{{ linkText }}
                            </component-button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'component-note',
  components: {
    ComponentButton: () => import('@/components/default/shared/ComponentButton')
  },
  props: {
    iconName: {
      type: String
    },
    success: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    },
    custom: {
      type: Boolean,
      default: false
    },
    linkActive: {
      type: Boolean,
      default: false
    },
    linkButton: {
      type: Boolean,
      default: false
    },
    linkText: {
      type: String,
      default: 'Read more'
    },
    linkTo: {
      type: Object,
      default: () => {
      }
    },
    margin: {
      type: String
    },
    whiteSpace: {
      type: String
    }
  },
  methods: {
    emitLink () {
      this.$emit('emit-link')
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentnote";
</style>
