import axios from 'axios'
import api from '@/api'
import { checkResponseNotFound } from '@/interceptorsHelpers'

const lmsUserWorkspace = {
  state: {
    data: {
      id: '',
      name: '',
      status: 'draft',
      workspaceId: ''
    },
    default: {}
  },
  getters: {
    getLmsUserWorkspace: state => {
      return state.data
    },
    getLmsUserWorkspaceId: state => {
      return state.data.id
    },
    getLmsUserWorkspaceName: state => {
      return state.data.name
    },
    getLmsUserWorkspaceStatus: state => {
      return state.data.status
    }
  },
  mutations: {
    LMS_USER_GET_WORKSPACE (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    //
    LMS_USER_CLEAR_WORKSPACE (state) {
      state.data = {
        id: '',
        name: '',
        status: 'draft',
        workspaceId: ''
      }
      state.default = JSON.parse(JSON.stringify(state.data))
    },
    LMS_USER_RESET_WORKSPACE (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    }
  },
  actions: {
    lmsUserGetWorkspace ({ commit }, id) {
      commit('LMS_USER_CLEAR_WORKSPACE')
      axios({
        method: 'get',
        url: `${api.subscriptionmanager}/workspaces/${id}`
      }).then(response => {
        commit('LMS_USER_GET_WORKSPACE', response.data)
      }).catch(function (error) {
        checkResponseNotFound(error.response.status)
      })
    }
  }
}

export default lmsUserWorkspace
