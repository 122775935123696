import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const lmsSuperadminWorkspaces = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkspaces: state => {
      return state.data
    },
    getLmsSuperadminWorkspacesMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems || !rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: `${item.name} (${item.customer ? item.customer.name : item.customerName})`,
          rawLabel: item.name,
          value: item.id,
          customerName: item.customerName,
          customerId: item.customerId,
          feedbackFormsEnabled: item.feedbackFormsEnabled
        }
      })
    },
    getLmsSuperadminWorkspacesMappedIdNameStatus: state => {
      const rawItems = state.data
      if (!rawItems || !rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          customer: item.customer ? item.customer.name : item.customerName,
          status: item.status,
          feedbackFormsEnabled: item.feedbackFormsEnabled
        }
      })
    },
    getLmsSuperadminWorkspacesPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKSPACES_SET (state, payload) {
      state.data = payload
    },
    LMS_SUPERADMIN_WORKSPACES_SET_PAGINATION (state, payload) {
      state.pagination = payload
    },
    LMS_SUPERADMIN_WORKSPACES_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_WORKSPACES_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsSuperadminWorkspacesGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data && data.queryUrlParams ? data.queryUrlParams : '?pageSize=9999'
        commit('LMS_SUPERADMIN_WORKSPACES_CLEAR')
        commit('LMS_SUPERADMIN_WORKSPACES_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/workspaces${queryUrlParams}&fields=customer.name`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACES_SET', response.data.data)
          commit('LMS_SUPERADMIN_WORKSPACES_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspacesSet ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACES_CLEAR')
      commit('LMS_SUPERADMIN_WORKSPACES_CLEAR_PAGINATION')
      commit('LMS_SUPERADMIN_WORKSPACES_SET', data.data)
      commit('LMS_SUPERADMIN_WORKSPACES_SET_PAGINATION', data.pagination)
    },
    lmsSuperadminWorkspacesExtendOrgUnits ({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        const workspaces = state.data
        let loopCounter = 0

        for (let index in workspaces) {
          let workspace = workspaces[index]
          dispatch('lmsSuperadminOrgUnitsGet', workspace.id).then(response => {
            let orgUnitsTree = JSON.parse(JSON.stringify(response))
            if (orgUnitsTree.parentId === null) {
              orgUnitsTree.id = undefined
            }
            workspace.orgUnits = [orgUnitsTree]
          }).catch(error => {
            workspace.orgUnits = []
            reject(error)
          }).finally(() => {
            loopCounter++
            if (loopCounter === workspaces.length) {
              resolve()
            }
          })
        }
      })
    }
  }
}

export default lmsSuperadminWorkspaces
