import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const lmsSuperadminAdmins = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminAdmins: state => {
      return state.data
    },
    getLmsSuperadminAdminsPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    LMS_SUPERADMIN_ADMINS_SET (state, payload) {
      state.data = payload
    },
    LMS_SUPERADMIN_ADMINS_SET_PAGINATION (state, payload) {
      state.pagination = payload
    },
    LMS_SUPERADMIN_ADMINS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_ADMINS_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsSuperadminAdminsGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'

        commit('LMS_SUPERADMIN_ADMINS_CLEAR')
        commit('LMS_SUPERADMIN_ADMINS_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.sso}/v2/role-users/superadmin${queryUrlParams}`
        }).then(function (response) {
          commit('LMS_SUPERADMIN_ADMINS_SET', response.data.data)
          commit('LMS_SUPERADMIN_ADMINS_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default lmsSuperadminAdmins
