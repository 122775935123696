<template>
    <div :class="[
        'component',
        'component__badge_role',
        type ? `component__badge_role--type-${type}` : '',
        size ? `component__badge_role--size-${size}` : ''
    ]">
        <span>{{ roleOutput }}</span>
    </div>
</template>

<script>
import { User } from '@/User'

export default {
  name: 'component-badge-role',
  data () {
    return {
      user: new User()
    }
  },
  props: {
    role: {
      type: String
    },
    type: {
      type: String,
      default: 'first'
    },
    size: {
      type: String
    }
  },
  computed: {
    roleOutput () {
      return this.user.getProfileRolesLibraryName(this.role)
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentbadgerole";
</style>
