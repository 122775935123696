<template>
    <div class="filter__list_icons">
        <div class="columns  is-variable is-2 is-vcentered">
            <div
                class="column"
                v-for="(filter, index) in filters"
                :key="index"
            >
                <component-button
                    :class="'btn--icon-sort'"
                    :theme="listView === filter ? 'primary' : ''"
                    :icon="true"
                    :icon-name="filter === 'table' ? 'list' : '' || filter === 'grid' ? 'grid' : '' || filter === 'calendar' ? 'calendar' : ''"
                    :icon-aria-label="filter === 'table' ? 'List view' : '' || filter === 'grid' ? 'Grid view' : '' || filter === 'calendar' ? 'Calendar view' : ''"
                    :icon-raw="true"
                    :tooltip-active="tooltipActive"
                    :tooltip-position="'center'"
                    :tooltip-text="filter === 'table' ? 'List view' : '' || filter === 'grid' ? 'Card view' : '' || filter === 'calendar' ? 'Sessions calendar' : ''"
                    @click="emitSwitchListView(filter)"
                    :name="'filter-list-icons-button'"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'filter-list-icons',
  components: {
    ComponentButton: () => import('@/components/default/shared/ComponentButton')
  },
  props: {
    filters: {
      type: [Array, Object],
      default: () => ['table', 'grid']
    },
    tooltipActive: {
      type: Boolean,
      default: false
    },
    listView: {}
  },
  methods: {
    emitSwitchListView (value) {
      this.$emit('emit-switch-list-view', value)
    }
  }
}
</script>
