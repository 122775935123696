import axios from 'axios'
import api from '@/api'

const rawGroup = {
  workspaceId: '',
  customerId: '',
  suggestedCourses: [],
  disableSuggestedCourses: [],
  name: ''
}

const data = {
  data: [],
  default: '',
  custom: {
    customerId: '',
    workspaceId: ''
  }
}

const lmsSuperadminGroups = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminGroups: state => {
      return state.data
    },
    getLmsSuperadminGroupsWithCourses: state => {
      const courses = state.data
      return courses.filter(obj => obj.suggestedCourses.length)
    },
    getLmsSuperadminGroupsCustomCustomerId: state => {
      return state.custom.customerId
    },
    getLmsSuperadminGroupsCustomWorkspaceId: state => {
      return state.custom.workspaceId
    },
    getLmsSuperadminGroupsMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.name,
          value: item.id,
          extraData: item
        }
      })
    },
    getLmsSuperadminGroupsMappedIdName: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name
        }
      })
    },
    getLmsSuperadminGroupsMappedIdNameSuggestedCourses: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          suggestedCourses: item.suggestedCourses
        }
      })
    }
  },
  mutations: {
    LMS_SUPERADMIN_GROUPS_SET (state, payload) {
      state.data = payload
    },
    LMS_SUPERADMIN_GROUPS_ADD (state) {
      state.data.unshift(JSON.parse(JSON.stringify(rawGroup)))
    },
    LMS_SUPERADMIN_GROUPS_REMOVE (state, payload) {
      let stateData = state.data
      const index = payload

      stateData.splice(index, 1)
    },
    LMS_SUPERADMIN_GROUPS_SET_VALUE (state, payload) {
      let stateData = state.data
      const data = JSON.parse(JSON.stringify(payload))
      const index = data.index
      const key = data.key
      const value = data.value

      stateData[index][key] = value
    },
    LMS_SUPERADMIN_GROUPS_CLEAR_CUSTOM (state) {
      state.custom = JSON.parse(JSON.stringify(data.custom))
    },
    LMS_SUPERADMIN_GROUPS_CLEAR_DATA (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    LMS_SUPERADMIN_GROUPS_SET_CUSTOM_CUSTOMERID (state, payload) {
      state.custom.customerId = payload
    },
    LMS_SUPERADMIN_GROUPS_SET_CUSTOM_WORKSPACEID (state, payload) {
      state.custom.workspaceId = payload
    },
    LMS_SUPERADMIN_GROUPS_SET_WORKSPACEID (state, payload) {
      state.data.workspaceId = payload
    }
  },
  actions: {
    lmsSuperadminGroupsGet ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data && data.queryUrlParams ? data.queryUrlParams : ''
        let workspaceId

        if (data) {
          if (data.constructor === String) {
            workspaceId = data
          } else {
            if (data.requestData !== undefined && data.requestData !== '') {
              workspaceId = data.requestData
            } else {
              workspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
            }
          }
        } else {
          workspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        }

        commit('LMS_SUPERADMIN_GROUPS_CLEAR_DATA')
        axios({
          method: 'get',
          url: `${api.sso}/workspace-groups/${workspaceId}${queryUrlParams}`
        }).then(response => {
          commit('LMS_SUPERADMIN_GROUPS_SET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminGroupsGetWithSuggestedCourses ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data ? (data.requestData !== undefined ? data.requestData : data) : getters.getAuthUserSecondStageTokenDataWorkspaceId

        commit('LMS_SUPERADMIN_GROUPS_CLEAR_DATA')
        axios({
          method: 'get',
          url: `${api.sso}/workspace-groups-with-courses/${workspaceId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_GROUPS_SET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminGroupsAdd ({ commit }) {
      commit('LMS_SUPERADMIN_GROUPS_ADD')
    },
    lmsSuperadminGroupsSetValue ({ commit }, data) {
      commit('LMS_SUPERADMIN_GROUPS_SET_VALUE', data)
    },
    lmsSuperadminGroupsSetCustomCustomerId ({ commit }, data) {
      commit('LMS_SUPERADMIN_GROUPS_SET_CUSTOM_CUSTOMERID', data)
    },
    lmsSuperadminGroupsSetCustomWorkspaceId ({ commit }, data) {
      commit('LMS_SUPERADMIN_GROUPS_SET_CUSTOM_WORKSPACEID', data)
    },
    lmsSuperadminGroupsClearData ({ commit }) {
      commit('LMS_SUPERADMIN_GROUPS_CLEAR_DATA')
    },
    lmsSuperadminGroupsClearCustom ({ commit }) {
      commit('LMS_SUPERADMIN_GROUPS_CLEAR_CUSTOM')
    },
    lmsSuperadminGroupsSwitchWorkspace ({ commit, dispatch }, data) {
      return dispatch('lmsSuperadminGroupsGet', data).then(response => {
        if (!response.data.length) {
          commit('LMS_SUPERADMIN_GROUPS_ADD')
        }
      }).catch(() => {
        commit('LMS_SUPERADMIN_GROUPS_SET_WORKSPACEID', data)
      })
    }
  }
}

export default lmsSuperadminGroups
