import axios from 'axios'
import api from '@/api'
import { checkResponseNotFound } from '@/interceptorsHelpers'
import router from '@/router'
import { getNestedByArray } from '@/functions'

const rootRule = {
  rule: 'AndExpression',
  threshold: 0,
  expressions: []
}

const rule = {
  rule: 'ActivityCompleted',
  type: '',
  threshold: 0,
  id: '',
  expressions: []
}

const data = {
  data: {
    id: '',
    name: '',
    description: '',
    rewards: [],
    status: 'draft',
    rule: ''
  },
  default: {}
}

const lmsUserAchievement = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsUserAchievement: state => {
      return state.data
    },
    getLmsUserAchievementId: state => {
      return state.data.id
    },
    getLmsUserAchievementName: state => {
      return state.data.name
    },
    getLmsUserAchievementDescription: state => {
      return state.data.description
    },
    getLmsUserAchievementStatus: state => {
      return state.data.status
    },
    getLmsUserAchievementDefaultStatus: state => {
      return state.default.status
    },
    getLmsUserAchievementRule: state => {
      return state.data.rule
    },
    getLmsUserAchievementRewards: state => {
      return state.data.rewards
    }
  },
  mutations: {
    LMS_USER_ACHIEVEMENT_GET (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    //
    LMS_USER_ACHIEVEMENT_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    LMS_USER_ACHIEVEMENT_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    LMS_USER_ACHIEVEMENT_SET_NAME (state, payload) {
      state.data.name = payload
    },
    LMS_USER_ACHIEVEMENT_SET_DESCRIPTION (state, payload) {
      state.data.description = payload
    },
    LMS_USER_ACHIEVEMENT_SET_STATUS (state, payload) {
      state.data.status = payload
    },
    LMS_USER_ACHIEVEMENT_ADD_ROOTRULE (state) {
      state.data.rule = JSON.parse(JSON.stringify(rootRule))
    },
    LMS_USER_ACHIEVEMENT_ADD_RULE (state, payload) {
      const rules = state.data.rule.expressions
      let indexArr = []
      for (let index = 0; index < payload.length; index++) {
        indexArr.push(payload[index], 'expressions')
      }
      getNestedByArray(rules, indexArr).push(JSON.parse(JSON.stringify(rule)))
    },
    LMS_USER_ACHIEVEMENT_REMOVE_ROOTRULE (state) {
      state.data.rule = ''
    },
    LMS_USER_ACHIEVEMENT_REMOVE_RULE (state, payload) {
      const rules = state.data.rule.expressions
      const lastIndex = payload[payload.length - 1]
      let indexArr = []
      for (let index = 0; index < payload.length; index++) {
        if ((index + 1) < payload.length) {
          indexArr.push(payload[index])
          indexArr.push('expressions')
        }
      }
      getNestedByArray(rules, indexArr).splice(lastIndex, 1)
    },
    LMS_USER_ACHIEVEMENT_SET_RULE_VALUE (state, payload) {
      const indexes = payload.indexes
      const key = payload.key
      const rules = state.data.rule
      let value = payload.value ? (payload.value.value || payload.value) : payload.value
      if (value) {
        value = value.value || value
        if (value.constructor === Array) {
          value = value.map(item => {
            return item.value
          })
        }
      }
      let indexArr = []
      for (let index = 0; index < indexes.length; index++) {
        indexArr.push('expressions')
        indexArr.push(indexes[index])
      }
      getNestedByArray(rules, indexArr)[key] = value
    },
    LMS_USER_ACHIEVEMENT_SET_REWARDS (state, payload) {
      let rewards = []
      rewards.push({
        type: 'certificate',
        rewardId: payload.value
      })
      state.data.rewards = rewards
    }
  },
  actions: {
    lmsUserAchievementGet ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('LMS_USER_ACHIEVEMENT_CLEAR')
        return axios({
          method: 'get',
          url: `${api.learningactivities}/achievements/${id}`
        }).then(response => {
          let data = JSON.parse(JSON.stringify(response.data))
          if (data.rule === null) {
            data.rule = ''
          } else {
            data.rule = JSON.parse(data.rule.definition)
          }
          commit('LMS_USER_ACHIEVEMENT_GET', data)
          resolve(response)
        }).catch(error => {
          checkResponseNotFound(error.response.status)
          reject(error)
        })
      })
    },
    lmsUserAchievementCreate ({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        let data = JSON.parse(JSON.stringify(state.data))
        if (data.rule === '') {
          data.rule = null
        } else {
          data.rule = JSON.stringify(data.rule)
        }
        return axios({
          method: 'post',
          url: `${api.learningactivities}/achievements`,
          data: data
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Achievement create',
            message: `Achievement has been successfully created.`,
            status: 'success'
          })
          router.push({ name: 'lms-user-achievement-list' })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Achievement create',
            message: `Achievement hasn't been created.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsUserAchievementRemove ({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        return axios({
          method: 'delete',
          url: `${api.learningactivities}/achievements/${id}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Achievement remove',
            message: `Achievement has been successfully removed.`,
            status: 'success'
          })
          router.push({ name: 'lms-user-achievement-list' })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Achievement remove',
            message: `Achievement hasn't been removed.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsUserAchievementUpdate ({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        const id = state.data.id
        let data = JSON.parse(JSON.stringify(state.data))
        if (data.rule === '') {
          data.rule = null
        } else {
          data.rule = JSON.stringify(data.rule)
        }
        return axios({
          method: 'post',
          url: `${api.learningactivities}/achievements/${id}`,
          data: data
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Achievement update',
            message: `Achievement has been successfully updated.`,
            status: 'success'
          })
          router.push({ name: 'lms-user-achievement-list' })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Achievement update',
            message: `Achievement hasn't been updated.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsUserAchievementUpdatePartial ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const id = data.id
        const body = data.body
        return axios({
          method: 'patch',
          url: `${api.learningactivities}/achievements/${id}`,
          data: body
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Achievement update',
            message: `Achievement has been successfully updated.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Achievement update',
            message: `Achievement hasn't been updated.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsUserAchievementReset ({ commit }) {
      commit('LMS_USER_ACHIEVEMENT_RESET')
    },
    lmsUserAchievementClear ({ commit }) {
      commit('LMS_USER_ACHIEVEMENT_CLEAR')
    },
    lmsUserAchievementSetName ({ commit }, data) {
      commit('LMS_USER_ACHIEVEMENT_SET_NAME', data)
    },
    lmsUserAchievementSetDescription ({ commit }, data) {
      commit('LMS_USER_ACHIEVEMENT_SET_DESCRIPTION', data)
    },
    lmsUserAchievementSetStatus ({ commit }, data) {
      commit('LMS_USER_ACHIEVEMENT_SET_STATUS', data)
    },
    lmsUserAchievementAddRule ({ commit, state }, data) {
      if (state.data.rule === '') {
        commit('LMS_USER_ACHIEVEMENT_ADD_ROOTRULE')
      }
      commit('LMS_USER_ACHIEVEMENT_ADD_RULE', data)
      const rules = state.data.rule
      let path = JSON.parse(JSON.stringify(data))
      let indexArr = []
      for (let index = 0; index < path.length; index++) {
        indexArr.push('expressions')
        indexArr.push(path[index])
      }
      const parentObj = JSON.parse(JSON.stringify(getNestedByArray(rules, indexArr)))
      if (parentObj.rule === 'ActivityCompleted') {
        commit('LMS_USER_ACHIEVEMENT_SET_RULE_VALUE', { indexes: data, key: 'rule', value: 'AndExpression' })
        commit('LMS_USER_ACHIEVEMENT_SET_RULE_VALUE', { indexes: data, key: 'id', value: '' })
        commit('LMS_USER_ACHIEVEMENT_SET_RULE_VALUE', { indexes: data, key: 'type', value: '' })
        commit('LMS_USER_ACHIEVEMENT_SET_RULE_VALUE', { indexes: data, key: 'threshold', value: 0 })
      }
      if (parentObj.expressions.length === 1 && parentObj.id !== undefined && parentObj.type !== undefined && parentObj.threshold !== undefined) {
        let childData = JSON.parse(JSON.stringify(data))
        childData.push('0')
        commit('LMS_USER_ACHIEVEMENT_SET_RULE_VALUE', { indexes: childData, key: 'id', value: parentObj.id })
        commit('LMS_USER_ACHIEVEMENT_SET_RULE_VALUE', { indexes: childData, key: 'type', value: parentObj.type })
        commit('LMS_USER_ACHIEVEMENT_SET_RULE_VALUE', { indexes: childData, key: 'threshold', value: parentObj.threshold })
      }
    },
    lmsUserAchievementRemoveRule ({ commit, state }, data) {
      const rules = state.data.rule
      let path = JSON.parse(JSON.stringify(data))
      const pathLastItem = path.pop()
      let parentIndexArr = []
      for (let index = 0; index < path.length; index++) {
        parentIndexArr.push('expressions')
        parentIndexArr.push(path[index])
      }
      let childIndexArr = JSON.parse(JSON.stringify(parentIndexArr))
      childIndexArr.push('expressions')
      childIndexArr.push(pathLastItem)
      const childObj = JSON.parse(JSON.stringify(getNestedByArray(rules, childIndexArr)))
      commit('LMS_USER_ACHIEVEMENT_REMOVE_RULE', data)
      const parentObj = JSON.parse(JSON.stringify(getNestedByArray(rules, parentIndexArr)))
      if (path.length) {
        if (parentObj.expressions.length === 0) {
          commit('LMS_USER_ACHIEVEMENT_SET_RULE_VALUE', { indexes: path, key: 'rule', value: 'ActivityCompleted' })
          commit('LMS_USER_ACHIEVEMENT_SET_RULE_VALUE', { indexes: path, key: 'type', value: childObj.type })
          commit('LMS_USER_ACHIEVEMENT_SET_RULE_VALUE', { indexes: path, key: 'id', value: childObj.id })
          commit('LMS_USER_ACHIEVEMENT_SET_RULE_VALUE', { indexes: path, key: 'threshold', value: childObj.threshold })
        }
      }
      if (!rules.expressions.length) {
        commit('LMS_USER_ACHIEVEMENT_REMOVE_ROOTRULE')
        commit('LMS_USER_ACHIEVEMENT_SET_STATUS', 'draft')
      }
    },
    lmsUserAchievementSetRuleValue ({ commit }, data) {
      commit('LMS_USER_ACHIEVEMENT_SET_RULE_VALUE', data)
    },
    lmsUserAchievementSetRewards ({ commit }, data) {
      commit('LMS_USER_ACHIEVEMENT_SET_REWARDS', data)
    }
  }
}

export default lmsUserAchievement
