const data = {
  data: {
    users: [],
    groups: [],
    groupsLabelValue: [],
    actionPending: false,
    actionCompleted: false
  }
}

const lmsSuperadminUsersBulkActionsRemoveFromGroups = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminUsersBulkActionsRemoveFromGroupsUsers: state => {
      return state.data.users
    },
    getLmsSuperadminUsersBulkActionsRemoveFromGroupsUsersProfileIds: state => {
      return state.data.users.map(obj => obj.profileId)
    },
    getLmsSuperadminUsersBulkActionsRemoveFromGroupsUsersChecked: state => {
      const users = state.data.users
      const usersChecked = users.filter(obj => obj.checked)

      return usersChecked
    },
    getLmsSuperadminUsersBulkActionsRemoveFromGroupsUsersCheckedProfileIds: state => {
      const users = state.data.users
      const usersChecked = users.filter(obj => obj.checked)
      const usersMapped = usersChecked.map(obj => obj.profileId)

      return usersMapped
    },
    getLmsSuperadminUsersBulkActionsRemoveFromGroupsUsersUpdatedProfileIds: state => {
      const users = state.data.users
      const usersUpdated = users.filter(obj => obj.updateStatus === 'updated')
      const usersMapped = usersUpdated.map(obj => obj.profileId)

      return usersMapped
    },
    getLmsSuperadminUsersBulkActionsRemoveFromGroupsGroups: state => {
      return state.data.groups
    },
    getLmsSuperadminUsersBulkActionsRemoveFromGroupsGroupsLabelValue: state => {
      return state.data.groupsLabelValue
    },
    getLmsSuperadminUsersBulkActionsRemoveFromGroupsActionPending: state => {
      return state.data.actionPending
    },
    getLmsSuperadminUsersBulkActionsRemoveFromGroupsActionCompleted: state => {
      return state.data.actionCompleted
    }
  },
  mutations: {
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_USERS (state, payload) {
      let users = state.data.users
      let item = payload.item
      item.checked = true
      item.updateStatus = 'ready'

      const value = payload.value
      const id = payload.index
      const existingIdIndex = users.findIndex(obj => obj.profileId === id)

      if (value && existingIdIndex === -1) {
        users.push(item)
      }
      if (!value && existingIdIndex > -1) {
        users.splice(existingIdIndex, 1)
      }
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_USERS_VALUE (state, payload) {
      let users = state.data.users

      const value = payload.value
      const key = payload.key
      const profileId = payload.profileId
      const existingIdIndex = users.findIndex(obj => obj.profileId === profileId)
      let user = JSON.parse(JSON.stringify(users[existingIdIndex]))
      user[key] = value

      users.splice(existingIdIndex, 1, user)
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_USERS_CHECKED (state, payload) {
      let users = state.data.users

      const value = payload.value
      const id = payload.index
      const existingIdIndex = users.findIndex(obj => obj.profileId === id)

      users[existingIdIndex].checked = value
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_GROUPS (state, payload) {
      state.data.groups = payload.map(obj => obj.value)
      state.data.groupsLabelValue = payload
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_ACTIONPENDING (state, payload) {
      state.data.actionPending = payload
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_ACTIONCOMPLETED (state, payload) {
      state.data.actionCompleted = payload
    }
  },
  actions: {
    lmsSuperadminUsersBulkActionsRemoveFromGroupsRun ({ dispatch, getters, commit }) {
      return new Promise((resolve, reject) => {
        let profiles = getters.getLmsSuperadminUsersBulkActionsRemoveFromGroupsUsersChecked
        const profilesLength = profiles.length
        const groupIds = getters.getLmsSuperadminUsersBulkActionsRemoveFromGroupsGroups
        const groupsLength = groupIds.length
        let requestsCounter = 0
        let updatedUsersCounter = 0
        let index = 0

        if (!profiles || !profiles.length) return reject(new Error())

        dispatch('defaultNotificationToastsAdd', {
          title: `Removing users from ${groupsLength > 1 ? 'groups' : 'group'}...`,
          message: `0/${profilesLength} users removed`,
          status: 'info',
          linkTo: { name: 'lms-superadmin-user-bulk-actions-remove-from-groups-summary' },
          linkText: 'View action summary',
          iconName: 'loader',
          id: 'bulk-remove-from-group',
          preventClose: true,
          position: 'bottom'
        })

        commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_ACTIONPENDING', true)
        commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_ACTIONCOMPLETED', false)

        const performAction = () => {
          if (index >= profilesLength) {
            commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_ACTIONPENDING', false)
            commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_ACTIONCOMPLETED', true)
            return resolve()
          }

          const profile = profiles[index]
          const profileId = profile.profileId

          commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_USERS_VALUE', { key: 'updateStatus', value: 'updating', profileId })

          dispatch('lmsSuperadminWorkspaceUserProfileRemoveFromGroups', { profileId, groupIds }).then(() => {
            updatedUsersCounter++
            commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_USERS_VALUE', { key: 'updateStatus', value: 'updated', profileId })
          }).catch(() => {
            commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_USERS_VALUE', { key: 'updateStatus', value: 'error', profileId })
          }).finally(() => {
            requestsCounter++
            if (requestsCounter === Number(profilesLength)) {
              dispatch('defaultNotificationToastsAdd', {
                title: `'Users removed from ${groupsLength > 1 ? 'groups' : 'group'}`,
                message: `${updatedUsersCounter}/${profilesLength} users removed`,
                status: 'success',
                linkTo: { name: 'lms-superadmin-user-bulk-actions-remove-from-groups-summary' },
                linkText: 'View action summary',
                iconName: 'check',
                id: 'bulk-remove-from-group',
                preventClose: false,
                position: 'bottom'
              })
              resolve()
            } else {
              dispatch('defaultNotificationToastsAdd', {
                title: `Removing users from ${groupsLength > 1 ? 'groups' : 'group'}...`,
                message: `${updatedUsersCounter}/${profilesLength} users removed`,
                status: 'info',
                linkTo: { name: 'lms-superadmin-user-bulk-actions-remove-from-groups-summary' },
                linkText: 'View action summary',
                iconName: 'loader',
                id: 'bulk-remove-from-group',
                preventClose: true,
                position: 'bottom'
              })
            }

            index++
            performAction()
          })
        }

        performAction()
      })
    },
    lmsSuperadminUsersBulkActionsRemoveFromGroupsSetUsers ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_USERS', data)
    },
    lmsSuperadminUsersBulkActionsRemoveFromGroupsSetUsersValue ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_USERS_VALUE', data)
    },
    lmsSuperadminUsersBulkActionsRemoveFromGroupsSetUsersChecked ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_USERS_CHECKED', data)
    },
    lmsSuperadminUsersBulkActionsRemoveFromGroupsSetGroups ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_SET_GROUPS', data)
    },
    lmsSuperadminUsersBulkActionsRemoveFromGroupsClear ({ commit }) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_REMOVE_FROM_GROUPS_CLEAR')
    }
  }
}

export default lmsSuperadminUsersBulkActionsRemoveFromGroups
