import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { sortByKey } from '@/functions'
import i18n from '@/i18n'
import { maxNumber } from '@/variables'
import { eventbus } from '@/main'

const data = {
  data: {
    bookingSettings: {
      minBookingLimit: 1,
      // fixed/roomBased
      maxBookingLimitType: 'roomBased',
      maxBookingLimit: 1,
      bookingAcceptanceLimit: 1,
      // hour/day
      allowNoEnrolments: false,
      sendReminders: false,
      reminderInterval: 1,
      reminderIntervalType: 'day',
      bookingAcceptanceLimitType: 'day',
      bookingFormRequired: true,
      bookingForm: {
        fields: [
          {
            id: '',
            label: i18n.t('store.ebs.ebsEvent.labelSpecialRequirements'),
            type: 'text',
            validation: {
              mandatory: false
            },
            options: '',
            helpText: i18n.t('store.ebs.ebsEvent.labelYourSpecialRequirements'),
            additional: {
              other: false
            }
          }
        ]
      }
    },
    cancellationSettings: {
      selfCancellationAllowed: true,
      selfCancellationLimit: 1,
      // hour/day
      selfCancellationLimitType: 'day',
      cancellationReasonRequired: false,
      cancellationReason: '',
      cancellationReasonCustomEnabled: false,
      autoCancellationEnabled: false,
      autoCancellationLimit: 1,
      // hour/day
      autoCancellationLimitType: 'hour'
    },
    completionSettings: {
      // attendance/assessment
      completionType: 'attendance',
      enableRegisters: true,
      registersEditByAnyCoordinator: true,
      registersEditByTrainers: true,
      trainerFeedbackRequired: false,
      absenceReasonRequired: false,
      absenceReason: '',
      feedbackForm: {
        version: 'timestamp',
        id: 'uuid',
        fields: []
      }
    },
    coordinators: '',
    costsSettings: {
      sectorServiceStructure: [],
      costsEnabled: false,
      costsValue: 0,
      overridesEnabled: false,
      costsSettingsOverrides: []
    },
    courses: [],
    description: '',
    domain: '',
    eventInfo: {
      hasBookings: false
    },
    images: [],
    name: '',
    occurrences: [],
    occurrenceSettings: {
      sessionType: 'face_to_face',
      meetingLink: '',
      platform: '',
      virtualAccessDetails: '',
      defaultLocation: {
        id: '',
        name: '',
        capacity: 0
      },
      enableTrainers: true,
      defaultTrainers: []
    },
    status: 'active',
    id: '',
    subscriptions: [],
    userSubscription: '',
    customerId: '',
    usedInCourse: ''
  },
  default: {},
  custom: {
    requestLoaded: false
  }
}

const ebsEvent = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEvent: state => {
      return state.data
    },
    //
    getEventId: state => {
      return state.data.id
    },
    getEventName: state => {
      return state.data.name
    },
    getEventDefaultName: state => {
      return state.default.name
    },
    getEventDescription: state => {
      return state.data.description
    },
    getEventStatus: state => {
      return state.data.status
    },
    getEventDefaultStatus: state => {
      return state.default.status
    },
    getEventHasStatusActive: state => {
      return state.data.status === 'active'
    },
    getEventImage: state => {
      return state.data.images
    },
    getEventUsedInCourseId: state => {
      return state.data.usedInCourse
    },
    getEventCoordinators: (state, getters) => {
      const isOldLMSToken = getters.getAuthUserIsSecondStageTokenOldLMS
      let coordinators = state.data.coordinators
      let coordinatorsArray = []
      for (let i = 0; i < coordinators.length; i++) {
        if (isOldLMSToken) {
          coordinatorsArray[i] = coordinators[i].domain
          coordinatorsArray[i] = String(coordinators[i].id)
        } else {
          coordinatorsArray[i] = String(coordinators[i].userId)
        }
      }
      return coordinatorsArray
    },
    getEventOccurrences: state => {
      return sortByKey(state.data.occurrences, 'fromDate')
    },
    getEventActiveOrUpcomingOccurrences: state => {
      return state.data.occurrences.filter(obj => obj.isActive && !obj.isArchived && !obj.isCompleted && obj.status !== 'cancelled' && obj.status !== 'ended')
    },
    getEventUserSubscription: state => {
      return state.data.userSubscription
    },
    getEventUserIsSubscribed: state => {
      return !!state.data.userSubscription
    },
    getEventSubscriptions: state => {
      return state.data.subscriptions
    },
    getEventSubscriptionsMappedIdNameEmailUsername: state => {
      const rawItems = state.data.subscriptions
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.firstName + ' ' + item.lastName,
          email: item.email
        }
      })
    },
    getEventEventInfoHasBookings: state => {
      return state.data.eventInfo.hasBookings
    },
    //
    getEventOccurrenceSessionType: state => {
      return state.data.occurrenceSettings.sessionType
    },
    getEventOccurrenceMeetingLink: state => {
      return state.data.occurrenceSettings.meetingLink
    },
    getEventOccurrencePlatform: state => {
      return state.data.occurrenceSettings.platform
    },
    getEventOccurrenceDetails: state => {
      return state.data.occurrenceSettings.virtualAccessDetails
    },
    getEventOccurrenceDefaultRoomId: state => {
      return state.data.occurrenceSettings.defaultLocation.id
    },
    getEventOccurrenceEnableTrainers: state => {
      return state.data.occurrenceSettings.enableTrainers
    },
    getEventOccurrenceTrainers: state => {
      const trainers = state.data.occurrenceSettings.defaultTrainers
      let trainersId = []
      for (let i = 0; i < trainers.length; i++) {
        trainersId[i] = trainers[i].trainerId
      }
      return trainersId
    },
    //
    getEventBookingMinLimit: state => {
      return state.data.bookingSettings.minBookingLimit
    },
    getEventBookingMaxLimit: state => {
      return state.data.bookingSettings.maxBookingLimit
    },
    getEventBookingLimitType: state => {
      return state.data.bookingSettings.maxBookingLimitType
    },
    getEventBookingFormRequired: state => {
      return state.data.bookingSettings.bookingFormRequired
    },
    getEventBookingFormFields: state => {
      return state.data.bookingSettings.bookingForm.fields
    },
    getEventBookingAcceptancePeriod: state => {
      return state.data.bookingSettings.bookingAcceptanceLimit
    },
    getEventBookingAcceptancePeriodType: state => {
      return state.data.bookingSettings.bookingAcceptanceLimitType
    },
    getEventBookingAllowNoEnrolments: state => {
      return state.data.bookingSettings.allowNoEnrolments
    },
    getEventBookingSendReminders: state => {
      return state.data.bookingSettings.sendReminders
    },
    getEventBookingReminderInterval: state => {
      return state.data.bookingSettings.reminderInterval
    },
    getEventBookingReminderIntervalType: state => {
      return state.data.bookingSettings.reminderIntervalType
    },
    //
    getEventCancellationSelfAllowed: state => {
      return state.data.cancellationSettings.selfCancellationAllowed
    },
    getEventCancellationSelfLimit: state => {
      return state.data.cancellationSettings.selfCancellationLimit
    },
    getEventCancellationSelfLimitType: state => {
      return state.data.cancellationSettings.selfCancellationLimitType
    },
    getEventCancellationReasonRequired: state => {
      return state.data.cancellationSettings.cancellationReasonRequired
    },
    getEventCancellationReason: state => {
      return state.data.cancellationSettings.cancellationReason
    },
    getEventCancellationReasonOther: state => {
      return state.data.cancellationSettings.cancellationReasonCustomEnabled
    },
    getEventCancellationAutoEnabled: state => {
      return state.data.cancellationSettings.autoCancellationEnabled
    },
    getEventCancellationAutoLimit: state => {
      return state.data.cancellationSettings.autoCancellationLimit
    },
    getEventCancellationAutoLimitType: state => {
      return state.data.cancellationSettings.autoCancellationLimitType
    },
    //
    getEventCompletionType: state => {
      return state.data.completionSettings.completionType
    },
    getEventCompletionEnableRegisters: state => {
      return state.data.completionSettings.enableRegisters
    },
    getEventCompletionRegistersEditByAnyCoordinator: state => {
      return state.data.completionSettings.registersEditByAnyCoordinator
    },
    getEventCompletionRegistersEditByTrainers: state => {
      return state.data.completionSettings.registersEditByTrainers
    },
    getEventCompletionFormRequired: state => {
      return state.data.completionSettings.trainerFeedbackRequired
    },
    getEventCompletionFormFields: state => {
      return state.data.completionSettings.feedbackForm.fields
    },
    getEventCompletionAbsenceReasonRequired: state => {
      return state.data.completionSettings.absenceReasonRequired
    },
    getEventCompletionAbsenceReason: state => {
      return state.data.completionSettings.absenceReason
    },
    //
    getEventCostsEnabled: state => {
      return state.data.costsSettings.costsEnabled
    },
    getEventCostsValue: state => {
      return state.data.costsSettings.costsValue
    },
    getEventCostsOverridesEnabled: state => {
      return state.data.costsSettings.overridesEnabled
    },
    getEventCostsSettingsOverrides: state => {
      return state.data.costsSettings.costsSettingsOverrides
    },
    //
    getEventHasOccurrencesDraft: (state, getters) => {
      const occurrences = getters.getEventOccurrences
      let hasOccurrencesDraft = false

      for (let item of occurrences) {
        if (item.status === 'draft') hasOccurrencesDraft = true
      }

      return hasOccurrencesDraft
    },
    getEventHasOccurrences: (state, getters) => {
      let occurrences = getters.getEventOccurrences
      return Boolean(occurrences.length)
    },
    //
    getEventAllowEdit: (state, getters) => {
      return !getters.getEventEventInfoHasBookings
    },
    getEventAllowEditStatus: (state, getters) => {
      return (
        getters.getEventDefaultStatus === 'active' ||
        (
          Boolean(getters.getEventUsedInCourseId) &&
          Boolean(getters.getEventId)
        )
      )
    },
    //
    getEventCustomRequestLoaded: (state) => {
      return state.custom.requestLoaded
    }
  },
  mutations: {
    GET_EVENT (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    GET_EVENT_SUMMARY (state, payload) {
      const stateOccurrences = state.data.occurrences
      payload.occurrences = stateOccurrences.length ? stateOccurrences : (payload.occurrences || [])
      state.data = JSON.parse(JSON.stringify(payload))
      state.default = JSON.parse(JSON.stringify(payload))
    },
    GET_EVENT_OCCURRENCES_SUMMARY (state, payload) {
      state.data.occurrences = payload
    },
    //
    CLEAR_EVENT (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    CLEAR_EVENT_CUSTOM (state) {
      state.custom = {
        requestLoaded: false
      }
    },
    RESET_EVENT (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    SET_EVENT_NAME (state, payload) {
      state.data.name = payload
    },
    SET_EVENT_DESCRIPTION (state, payload) {
      state.data.description = payload
    },
    SET_EVENT_IMAGES (state, payload) {
      state.data.images = [...payload]
    },
    SET_EVENT_COURSE (state, payload) {
      state.data.courses.push({
        courseId: payload[payload.length - 1].value,
        name: payload[payload.length - 1].label,
        new: true
      })
    },
    SET_EVENT_STATUS (state, payload) {
      state.data.status = payload
    },
    SET_EVENT_DOMAIN (state, payload) {
      state.data.domain = payload
    },
    SET_EVENT_COOORDINATORID (state, payload) {
      let coordinators = []
      for (let i = 0; i < payload.data.length; i++) {
        coordinators.push({
          id: payload.data[i].value,
          userId: null,
          domain: payload.domain
        })
      }
      state.data.coordinators = coordinators
    },
    SET_EVENT_COOORDINATORUSERID (state, payload) {
      let coordinators = []
      for (let i = 0; i < payload.length; i++) {
        coordinators.push({
          userId: payload[i].value,
          id: null,
          domain: null
        })
      }
      state.data.coordinators = coordinators
    },
    REMOVE_EVENT_IMAGES (state, key) {
      state.data.images.splice(key, 1)
    },
    REMOVE_EVENT_COURSE (state, key) {
      state.data.courses.splice(key, 1)
    },
    //
    SET_EVENT_OCCURRENCESESSIONTYPE (state, payload) {
      state.data.occurrenceSettings.sessionType = payload
    },
    SET_EVENT_OCCURRENCEMEETINGLINK (state, payload) {
      state.data.occurrenceSettings.meetingLink = payload
    },
    SET_EVENT_OCCURRENCEPLATFORM (state, payload) {
      state.data.occurrenceSettings.platform = payload
    },
    SET_EVENT_OCCURRENCEDETAILS (state, payload) {
      state.data.occurrenceSettings.virtualAccessDetails = payload
    },
    SET_EVENT_OCCURRENCEDEFAULTROOMID (state, payload) {
      if (!payload) payload = ''
      state.data.occurrenceSettings.defaultLocation.id = payload
      state.data.occurrenceSettings.defaultLocation.capacity = 0
      state.data.occurrenceSettings.defaultLocation.name = ''
    },
    SET_EVENT_OCCURRENCEENABLETRAINERS (state, payload) {
      state.data.occurrenceSettings.enableTrainers = payload
    },
    SET_EVENT_OCCURRENCETRAINERS (state, payload) {
      let trainers = []
      for (let i = 0; i < payload.length; i++) {
        trainers.push({ trainerId: payload[i].value })
      }
      state.data.occurrenceSettings.defaultTrainers = trainers
    },
    //
    SET_EVENT_BOOKINGMINLIMIT (state, payload) {
      state.data.bookingSettings.minBookingLimit = Number(payload)
    },
    SET_EVENT_BOOKINGMAXLIMIT (state, payload) {
      state.data.bookingSettings.maxBookingLimit = Number(payload)
    },
    SET_EVENT_BOOKINGLIMITTYPE (state, payload) {
      state.data.bookingSettings.maxBookingLimitType = payload
    },
    SET_EVENT_BOOKINGACCEPTANCEPERIOD (state, payload) {
      state.data.bookingSettings.bookingAcceptanceLimit = Number(payload)
    },
    SET_EVENT_BOOKINGACCEPTANCEPERIODTYPE (state, payload) {
      state.data.bookingSettings.bookingAcceptanceLimitType = payload
    },
    SET_EVENT_BOOKINGFORMREQUIRED (state, payload) {
      state.data.bookingSettings.bookingFormRequired = payload
    },
    SET_EVENT_BOOKINGFORMFIELD (state, payload) {
      state.data.bookingSettings.bookingForm.fields.push(payload)
    },
    MOVE_EVENT_BOOKINGFORMFIELD (state, payload) {
      const arr = state.data.bookingSettings.bookingForm.fields
      const item = arr[payload.from]
      if (payload.to !== -1) {
        state.data.bookingSettings.bookingForm.fields.splice(payload.from, 1)
        state.data.bookingSettings.bookingForm.fields.splice(payload.to, 0, item)
      }
    },
    UPDATE_EVENT_BOOKINGFORMFIELD (state, payload) {
      state.data.bookingSettings.bookingForm.fields.splice(payload.index, 1)
      state.data.bookingSettings.bookingForm.fields.splice(payload.index, 0, payload.data)
    },
    REMOVE_EVENT_BOOKINGFORMFIELD (state, payload) {
      state.data.bookingSettings.bookingForm.fields.splice(payload, 1)
    },
    SET_EVENT_BOOKINGALLOWNOENROLMENT (state, payload) {
      state.data.bookingSettings.allowNoEnrolments = payload
    },
    SET_EVENT_BOOKINGSENDREMINDERS (state, payload) {
      state.data.bookingSettings.sendReminders = payload
    },
    SET_EVENT_BOOKINGREMINDERINTERVAL (state, payload) {
      state.data.bookingSettings.reminderInterval = payload
    },
    SET_EVENT_BOOKINGREMINDERINTERVALTYPE (state, payload) {
      state.data.bookingSettings.reminderIntervalType = payload
    },
    //
    SET_EVENT_CANCELLATIONSELFALLOWED (state, payload) {
      state.data.cancellationSettings.selfCancellationAllowed = payload
    },
    SET_EVENT_CANCELLATIONSELFLIMIT (state, payload) {
      state.data.cancellationSettings.selfCancellationLimit = payload
    },
    SET_EVENT_CANCELLATIONSELFLIMITTYPE (state, payload) {
      state.data.cancellationSettings.selfCancellationLimitType = payload
    },
    SET_EVENT_CANCELLATIONREASONREQUIRED (state, payload) {
      state.data.cancellationSettings.cancellationReasonRequired = payload
    },
    SET_EVENT_CANCELLATIONREASON (state, payload) {
      state.data.cancellationSettings.cancellationReason = payload
    },
    SET_EVENT_CANCELLATIONREASONOTHER (state, payload) {
      state.data.cancellationSettings.cancellationReasonCustomEnabled = payload
    },
    SET_EVENT_CANCELLATIONAUTOENABLED (state, payload) {
      state.data.cancellationSettings.autoCancellationEnabled = payload
    },
    SET_EVENT_CANCELLATIONAUTOLIMIT (state, payload) {
      state.data.cancellationSettings.autoCancellationLimit = payload
    },
    SET_EVENT_CANCELLATIONAUTOLIMITTYPE (state, payload) {
      state.data.cancellationSettings.autoCancellationLimitType = payload
    },
    //
    SET_EVENT_COMPLETIONTYPE (state, payload) {
      state.data.completionSettings.completionType = payload
    },
    SET_EVENT_COMPLETIONENABLEREGISTERS (state, payload) {
      state.data.completionSettings.enableRegisters = payload
    },
    SET_EVENT_COMPLETIONREGISTERSEDITBYANYCOORDINATOR (state, payload) {
      state.data.completionSettings.registersEditByAnyCoordinator = (payload === 'true')
    },
    SET_EVENT_COMPLETIONREGISTERSEDITBYTRAINERS (state, payload) {
      state.data.completionSettings.registersEditByTrainers = payload
    },
    SET_EVENT_COMPLETIONFORMREQUIRED (state, payload) {
      state.data.completionSettings.trainerFeedbackRequired = payload
    },
    SET_EVENT_COMPLETIONFORM (state, payload) {
      state.data.completionSettings.feedbackForm.fields.push(payload)
    },
    SET_EVENT_COMPLETIONABSENCEREASONREQUIRED (state, payload) {
      state.data.completionSettings.absenceReasonRequired = payload
    },
    SET_EVENT_COMPLETIONABSENCEREASON (state, payload) {
      state.data.completionSettings.absenceReason = payload
    },
    MOVE_EVENT_COMPLETIONFORMFIELD (state, payload) {
      const arr = state.data.completionSettings.feedbackForm.fields
      const item = arr[payload.from]
      if (payload.to !== -1) {
        state.data.completionSettings.feedbackForm.fields.splice(payload.from, 1)
        state.data.completionSettings.feedbackForm.fields.splice(payload.to, 0, item)
      }
    },
    UPDATE_EVENT_COMPLETIONFORMFIELD (state, payload) {
      state.data.completionSettings.feedbackForm.fields.splice(payload.index, 1)
      state.data.completionSettings.feedbackForm.fields.splice(payload.index, 0, payload.data)
    },
    REMOVE_EVENT_COMPLETIONFORMFIELD (state, payload) {
      state.data.completionSettings.feedbackForm.fields.splice(payload, 1)
    },
    //
    SET_EVENT_COSTSENABLED (state, payload) {
      state.data.costsSettings.costsEnabled = payload
    },
    SET_EVENT_COSTSVALUE (state, payload) {
      state.data.costsSettings.costsValue = payload
    },
    SET_EVENT_COSTSOVERRIDESENABLED (state, payload) {
      state.data.costsSettings.overridesEnabled = payload
    },
    SET_EVENT_COSTSSETTINGSOVERRIDES (state, payload) {
      const item = payload.item
      const value = payload.value
      const override = payload.override
      const overridesIndex = override.findIndex(overrideItem => overrideItem.referenceType === item.referenceType && Number(overrideItem.reference.id) === Number(item.reference.id))
      const overridesData = {
        referenceType: item.referenceType,
        reference: item.reference,
        value: value
      }
      if (overridesIndex > -1) {
        if (value) {
          state.data.costsSettings.costsSettingsOverrides[overridesIndex] = overridesData
        } else {
          state.data.costsSettings.costsSettingsOverrides[overridesIndex].value = null
        }
      } else {
        state.data.costsSettings.costsSettingsOverrides.push(overridesData)
      }
    },
    //
    SET_EVENT_CUSTOM_REQUESTLOADED (state, payload) {
      state.custom.requestLoaded = payload
    }
  },
  actions: {
    getEvent ({ commit, getters }, id) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_EVENT')
        commit('CLEAR_EVENT_CUSTOM')
        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.classroom}/events/${id}`
        }).then(response => {
          let payload = response.data

          for (let index in payload.occurrences) {
            const fromDate = payload.occurrences[index].fromDate
            const toDate = payload.occurrences[index].toDate
            let output

            if (fromDate === toDate) {
              output = new Date(fromDate).toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: '2-digit' })
            } else {
              output = new Date(fromDate).toLocaleDateString('en-gb', {
                year: 'numeric',
                month: 'short',
                day: '2-digit'
              }) + ' - ' + new Date(toDate).toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: '2-digit' })
            }
            payload.occurrences[index].nameDate = output
          }
          payload.occurrenceSettings.sessionType = payload.occurrenceSettings.sessionType || data.data.occurrenceSettings.sessionType
          if (payload.bookingSettings.maxBookingLimitType === 'fixed' && payload.bookingSettings.maxBookingLimit === maxNumber) {
            payload.bookingSettings.maxBookingLimitType = 'unlimited'
          }

          commit('GET_EVENT', payload)
          resolve(payload)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getEventSimplified ({ commit, dispatch }, id) {
      return new Promise((resolve) => {
        commit('CLEAR_EVENT')
        commit('CLEAR_EVENT_CUSTOM')

        let collectedData = {
          event: {},
          occurrences: []
        }

        const getEventSummary = () => {
          return dispatch('getEventSummary', id).then(response => collectedData.event = response)
        }

        const getEventOccurrencesSummary = () => {
          return dispatch('getEventOccurrencesSummary', { requestData: id }).then(response => collectedData.occurrences = response)
        }

        const requestsArr = [getEventSummary, getEventOccurrencesSummary]
        let requestsCounter = 0

        for (let request of requestsArr) {
          request().finally(() => {
            requestsCounter++
            if (requestsCounter === requestsArr.length) {
              resolve({ ...collectedData.event, occurrences: collectedData.occurrences })
            }
          })
        }
      })
    },
    getEventSummary ({ commit, getters }, id) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_EVENT')
        commit('CLEAR_EVENT_CUSTOM')
        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.classroom}/events/${id}/summary`
        }).then(response => {
          commit('GET_EVENT_SUMMARY', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getEventOccurrencesSummary ({ commit, getters }, data) {
      const id = data.constructor === String ? data : data.requestData
      const queryUrlParams = data && data.queryUrlParams ? data.queryUrlParams : '?pageSize=9999'

      return new Promise((resolve, reject) => {
        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.classroom}/events/${id}/occurrences-summary${queryUrlParams}`
        }).then(response => {
          let payload = response.data

          for (let index in payload) {
            const fromDate = payload[index].fromDate
            const toDate = payload[index].toDate
            let output

            if (fromDate === toDate) {
              output = new Date(fromDate).toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: '2-digit' })
            } else {
              output = new Date(fromDate).toLocaleDateString('en-gb', {
                year: 'numeric',
                month: 'short',
                day: '2-digit'
              }) + ' - ' + new Date(toDate).toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: '2-digit' })
            }
            payload[index].nameDate = output
            payload[index].fromDateTime = payload[index].occurrenceItems.length ? payload[index].occurrenceItems[0].datetimeStart : null
          }

          commit('GET_EVENT_OCCURRENCES_SUMMARY', payload)
          resolve(payload)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getEventOccurrencesSimplified ({ commit, getters }, data) {
      const id = data.constructor === String ? data : data.requestData
      const queryUrlParams = data && data.queryUrlParams ? data.queryUrlParams : '?pageSize=9999'

      return new Promise((resolve, reject) => {
        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.classroom}/v2/events/${id}/sessions${queryUrlParams}`
        }).then(response => {
          let payload = response.data

          for (let index in payload) {
            const fromDate = payload[index].fromDate
            const toDate = payload[index].toDate
            let output

            if (fromDate === toDate) {
              output = new Date(fromDate).toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: '2-digit' })
            } else {
              output = new Date(fromDate).toLocaleDateString('en-gb', {
                year: 'numeric',
                month: 'short',
                day: '2-digit'
              }) + ' - ' + new Date(toDate).toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: '2-digit' })
            }
            payload[index].nameDate = output
          }

          commit('GET_EVENT_OCCURRENCES_SUMMARY', payload)
          resolve(payload)
        }).catch(error => {
          reject(error)
        })
      })
    },
    //
    archiveEvent ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const id = data.id
        const name = data.name

        axios({
          method: 'post',
          url: `${api.classroom}/event/${id}/archive`
        })
          .then(response => {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Event archived',
              message: `${name} has been successfully archived.`,
              status: 'success'
            })
            router.push({ name: 'ebs-event-list' })
            resolve(response)
          }).catch(error => {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Event not archived',
              message: `${name} has not been archived.`,
              status: 'error'
            })
            reject(error)
          })
      })
    },
    restoreEvent ({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.classroom}/events/${id}/reactivate`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event restored',
            message: `Event has been successfully restored.`,
            status: 'success'
          })
          eventbus.$emit('get-event')
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event not restored',
            message: `Event has not been restored.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    removeEvent (context, id) {
      axios
        .delete(`${api.classroom}/events/${id}`)
        .then(() => {
          context.commit('CLEAR_EVENT')
          context.commit('CLEAR_REPORT_CALENDAR')
          context.commit('CLEAR_EVENTS')
          context.commit('CLEAR_SERVICES')
          router.push({ name: 'ebs-event-list' })
        })
    },
    updateEvent ({ commit, state, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        commit('SET_EVENT_DOMAIN', getters.getAuthUserSecondStageTokenDataDomain)
        let stateData = JSON.parse(JSON.stringify(state.data))
        if (stateData.bookingSettings.maxBookingLimitType === 'unlimited') {
          stateData.bookingSettings.maxBookingLimitType = 'fixed'
          stateData.bookingSettings.maxBookingLimit = maxNumber
        }

        return axios({
          method: 'patch',
          url: `${api.classroom}/events`,
          data: stateData
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event update',
            message: `Event has been successfully updated.`,
            status: 'success'
          })
          commit('SET_EVENT_CUSTOM_REQUESTLOADED', true)
          commit('CLEAR_REPORT_CALENDAR')
          commit('CLEAR_SERVICES')
          commit('CLEAR_EVENTS')
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event update',
            message: `Event hasn't been updated.`,
            status: 'error'
          })
          commit('SET_EVENT_CUSTOM_REQUESTLOADED', true)
          reject(error)
        })
      })
    },
    updateEventAssignCourse ({ commit, getters, dispatch }, data) {
      let courseId = data.courseId
      let eventId = data.eventId
      axios({
        method: 'get',
        url: `${api.classroom}/events/${eventId}`
      }).then(response => {
        let data = response.data
        data.domain = getters.getAuthUserSecondStageTokenDataDomain
        data.courses.push({ courseId: courseId })
        data.onlyAddCourses = true
        axios({
          method: 'patch',
          url: `${api.classroom}/events`,
          data: data
        }).then(() => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event assign',
            message: `Event has been successfully assigned.`,
            status: 'success'
          })
          router.push({ name: 'ebs-event-show', params: { id: eventId } })
          commit('CLEAR_EVENT')
        }).catch(function () {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event assign',
            message: `Event hasn't been assigned.`,
            status: 'error'
          })
        })
      })
    },
    resetEvent (context) {
      context.commit('RESET_EVENT')
      context.commit('RESET_SERVICES')
    },
    clearEvent (context) {
      context.commit('CLEAR_EVENT')
    },
    createEvent ({ commit, getters, state, dispatch }, redirect = true) {
      return new Promise((resolve, reject) => {
        commit('SET_EVENT_DOMAIN', getters.getAuthUserSecondStageTokenDataDomain)
        const loggedInUserId = getters.getAuthUserSecondStageTokenDataUserId
        const courseId = getters.getEbsCourseId

        let stateData = JSON.parse(JSON.stringify(state.data))

        stateData.courseId = courseId

        if (stateData.bookingSettings.maxBookingLimitType === 'unlimited') {
          stateData.bookingSettings.maxBookingLimitType = 'fixed'
          stateData.bookingSettings.maxBookingLimit = maxNumber
        }

        if (!stateData.coordinators || !stateData.coordinators.length) {
          stateData.coordinators = [{
            userId: loggedInUserId,
            id: null,
            domain: null
          }]
        }

        return axios({
          method: 'post',
          url: `${api.classroom}/events`,
          data: stateData
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event create',
            message: `Event has been successfully created.`,
            status: 'success'
          })
          if (redirect) {
            router.push({ name: 'ebs-event-show', params: { id: response.data.id } })
          }
          commit('CLEAR_REPORT_CALENDAR')
          commit('CLEAR_SERVICES')
          commit('CLEAR_EVENTS')
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event create',
            message: `Event hasn't been created.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    printEventFlyer (ctx, payload) {
      axios({
        method: 'GET',
        url: `${api.pdfprinter}/flyer/event/${payload}`,
        responseType: 'arraybuffer'
      }).then((response) => {
        if (!window.navigator.msSaveOrOpenBlob) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'event-flyer.pdf')
          document.body.appendChild(link)
          link.click()
        } else {
          window.navigator.msSaveOrOpenBlob(new Blob([response.data]), 'event-flyer.pdf')
        }
      })
    },
    //
    setEventName (context, value) {
      context.commit('SET_EVENT_NAME', value)
    },
    setEventDescription (context, value) {
      context.commit('SET_EVENT_DESCRIPTION', value)
    },
    setEventStatus (context, value) {
      context.commit('SET_EVENT_STATUS', value)
    },
    setEventImage (context, data) {
      data = data.map(value => {
        return {
          imageId: value
        }
      })
      context.commit('SET_EVENT_IMAGES', data)
    },
    setEventCourse (context, data) {
      context.commit('SET_EVENT_COURSE', data)
    },
    setEventCoordinators ({ commit, getters }, data) {
      const domain = getters.getAuthUserSecondStageTokenDataDomain
      const isOldLMSToken = getters.getAuthUserIsSecondStageTokenOldLMS
      for (let index in data) {
        data[index].value = String(data[index].value)
      }
      if (isOldLMSToken) {
        commit('SET_EVENT_COOORDINATORID', { data, domain })
      } else {
        commit('SET_EVENT_COOORDINATORUSERID', data)
      }
    },
    removeEventImage (context, key) {
      context.commit('REMOVE_EVENT_IMAGES', key)
    },
    removeEventCourse (context, key) {
      context.commit('REMOVE_EVENT_COURSE', key)
    },
    //
    setEventOccurrenceSessionType (context, value) {
      context.commit('SET_EVENT_OCCURRENCESESSIONTYPE', value)
    },
    setEventOccurrenceMeetingLink (context, value) {
      context.commit('SET_EVENT_OCCURRENCEMEETINGLINK', value)
    },
    setEventOccurrencePlatform (context, value) {
      context.commit('SET_EVENT_OCCURRENCEPLATFORM', value)
    },
    setEventOccurrenceDetails (context, value) {
      context.commit('SET_EVENT_OCCURRENCEDETAILS', value)
    },
    setEventOccurrenceDefaultRoomId (context, value) {
      context.commit('SET_EVENT_OCCURRENCEDEFAULTROOMID', value.value)
    },
    setEventOccurrenceEnableTrainers (context, data) {
      context.commit('SET_EVENT_OCCURRENCEENABLETRAINERS', data)
      if (data === false) {
        context.commit('SET_EVENT_COMPLETIONFORMREQUIRED', false)
        context.commit('SET_EVENT_COMPLETIONREGISTERSEDITBYTRAINERS', false)
      }
    },
    setEventOccurrenceTrainers (context, data) {
      context.commit('SET_EVENT_OCCURRENCETRAINERS', data)
    },
    //
    setEventBookingMinLimit (context, value) {
      context.commit('SET_EVENT_BOOKINGMINLIMIT', value)
    },
    setEventBookingMaxLimit (context, value) {
      context.commit('SET_EVENT_BOOKINGMAXLIMIT', value)
    },
    setEventBookingLimitType (context, value) {
      context.commit('SET_EVENT_BOOKINGLIMITTYPE', value)
      context.commit('SET_EVENT_BOOKINGMAXLIMIT', 1)
    },
    setEventBookingFormRequired (context, value) {
      context.commit('SET_EVENT_BOOKINGFORMREQUIRED', value)
    },
    setEventBookingAcceptancePeriod (context, value) {
      context.commit('SET_EVENT_BOOKINGACCEPTANCEPERIOD', value)
    },
    setEventBookingAcceptancePeriodType (context, value) {
      context.commit('SET_EVENT_BOOKINGACCEPTANCEPERIODTYPE', value)
    },
    setEventBookingFormField (context, value) {
      context.commit('SET_EVENT_BOOKINGFORMFIELD', value)
    },
    moveEventBookingFormField (context, value) {
      context.commit('MOVE_EVENT_BOOKINGFORMFIELD', value)
    },
    updateEventBookingFormField (context, value) {
      context.commit('UPDATE_EVENT_BOOKINGFORMFIELD', value)
    },
    removeEventBookingFormField (context, value) {
      context.commit('REMOVE_EVENT_BOOKINGFORMFIELD', value)
    },
    setEventBookingAllowNoEnrolments (context, value) {
      context.commit('SET_EVENT_BOOKINGALLOWNOENROLMENT', value)
    },
    setEventBookingSendReminders (context, value) {
      context.commit('SET_EVENT_BOOKINGSENDREMINDERS', value)
    },
    setEventBookingReminderInterval (context, value) {
      context.commit('SET_EVENT_BOOKINGREMINDERINTERVAL', value)
    },
    setEventBookingReminderIntervalType (context, value) {
      context.commit('SET_EVENT_BOOKINGREMINDERINTERVALTYPE', value)
    },
    //
    setEventCancellationSelfAllowed (context, value) {
      context.commit('SET_EVENT_CANCELLATIONSELFALLOWED', value)
    },
    setEventCancellationSelfLimit (context, value) {
      context.commit('SET_EVENT_CANCELLATIONSELFLIMIT', value)
    },
    setEventCancellationSelfLimitType (context, value) {
      context.commit('SET_EVENT_CANCELLATIONSELFLIMITTYPE', value)
    },
    setEventCancellationReasonRequired (context, value) {
      context.commit('SET_EVENT_CANCELLATIONREASONREQUIRED', value)
    },
    setEventCancellationReason (context, value) {
      context.commit('SET_EVENT_CANCELLATIONREASON', value)
    },
    setEventCancellationReasonOther (context, value) {
      context.commit('SET_EVENT_CANCELLATIONREASONOTHER', value)
    },
    setEventCancellationAutoEnabled (context, value) {
      context.commit('SET_EVENT_CANCELLATIONAUTOENABLED', value)
    },
    setEventCancellationAutoLimit (context, value) {
      context.commit('SET_EVENT_CANCELLATIONAUTOLIMIT', value)
    },
    setEventCancellationAutoLimitType (context, value) {
      context.commit('SET_EVENT_CANCELLATIONAUTOLIMITTYPE', value)
    },
    //
    setEventCompletionType (context, value) {
      if (value === 'attendance') {
        context.commit('SET_EVENT_COMPLETIONENABLEREGISTERS', true)
      } else if (value === 'automatic') {
        context.commit('SET_EVENT_COMPLETIONENABLEREGISTERS', false)
      }
      context.commit('SET_EVENT_COMPLETIONTYPE', value)
    },
    setEventCompletionEnableRegisters (context, value) {
      context.commit('SET_EVENT_COMPLETIONENABLEREGISTERS', value)
    },
    setEventCompletionRegistersEditByAnyCoordinator (context, value) {
      context.commit('SET_EVENT_COMPLETIONREGISTERSEDITBYANYCOORDINATOR', value)
    },
    setEventCompletionRegistersEditByTrainers (context, value) {
      context.commit('SET_EVENT_COMPLETIONREGISTERSEDITBYTRAINERS', value)
    },
    setEventCompletionFormRequired (context, value) {
      context.commit('SET_EVENT_COMPLETIONFORMREQUIRED', value)
    },
    setEventCompletionFormField (context, value) {
      context.commit('SET_EVENT_COMPLETIONFORM', value)
    },
    setEventCompletionAbsenceReasonRequired (context, value) {
      context.commit('SET_EVENT_COMPLETIONABSENCEREASONREQUIRED', value)
    },
    setEventCompletionAbsenceReason (context, value) {
      context.commit('SET_EVENT_COMPLETIONABSENCEREASON', value)
    },
    moveEventCompletionFormField (context, value) {
      context.commit('MOVE_EVENT_COMPLETIONFORMFIELD', value)
    },
    updateEventCompletionFormField (context, value) {
      context.commit('UPDATE_EVENT_COMPLETIONFORMFIELD', value)
    },
    removeEventCompletionFormField (context, value) {
      context.commit('REMOVE_EVENT_COMPLETIONFORMFIELD', value)
    },
    //
    setEventCostsEnabled (context, value) {
      context.commit('SET_EVENT_COSTSENABLED', value)
    },
    setEventCostsValue (context, value) {
      context.commit('SET_EVENT_COSTSVALUE', value)
    },
    setEventCostsOverridesEnabled (context, value) {
      context.commit('SET_EVENT_COSTSOVERRIDESENABLED', value)
    },
    setEventCostsSettingsOverrides ({ commit, getters }, data) {
      commit('SET_EVENT_COSTSSETTINGSOVERRIDES', {
        item: data.item,
        value: data.value,
        override: getters.getEvent.costsSettings.costsSettingsOverrides
      })
      commit('SET_SERVICE_VALUE', { value: data.value, parentIndex: data.parentIndex, childIndex: data.childIndex })
    }
  }
}

export default ebsEvent
