<template>
    <div
        :class="[
            'component',
            'component__text',
            size ? `component__text--${size}` : '',
            heading ? `component__text--heading` : '',
            danger ? `component__text--danger` : '',
            success ? `component__text--success` : '',
            theme ? `component__text--${theme}` : '',
            margin ? `component__text--margin-${margin}` : '',
            weight ? `component__text--weight-${weight}` : '',
            nowrap ? `component__text--nowrap` : ''
        ]"
        @click="performEmitClick"
    >
        <div class="container">
            <div class="columns is-vcentered">
                <template v-if="icon">
                    <div class="column is-narrow">
                        <div class="component__text_icon">
                            <v-icon :name="iconName"></v-icon>
                        </div>
                    </div>
                </template>
                <div class="column">
                    <div
                        :style="{ maxHeight: readMoreActive && contentHigherThanLimit && !isReadMoreExpand ? readMoreLimit + 'px' : '' }"
                        :class="[
                          'component__text_inner',
                          { 'component__text_inner--not-expanded' : readMoreActive && contentHigherThanLimit && !isReadMoreExpand}
                        ]"
                    >
                        <template v-if="html">
                            <template v-if="isSlot">
                                <slot></slot>
                            </template>
                            <template v-else>
                                <div v-html="content"></div>
                            </template>
                        </template>
                        <template v-else>
                            <template v-if="isSlot">
                                <p><slot></slot></p>
                            </template>
                            <template v-else>
                                <p>{{ content }}</p>
                            </template>
                        </template>
                    </div>
                    <component-button
                        v-if="readMoreActive && contentHigherThanLimit"
                        class="component__text_readmore-button"
                        :name="'component-text-readmore-button'"
                        :text="true"
                        :underline="true"
                        :size="'medium'"
                        @click="switchReadMore"
                    >{{ isReadMoreExpand ? 'Read less' : 'Read more' }}
                    </component-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentButton from '@/components/default/shared/ComponentButton'

export default {
  name: 'component-text',
  components: { ComponentButton },
  data () {
    return {
      isReadMoreExpand: false,
      contentHigherThanLimit: false
    }
  },
  props: {
    nowrap: {
      type: Boolean,
      default: false
    },
    size: {
      type: String
    },
    html: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    },
    isSlot: {
      type: Boolean,
      default: false
    },
    readMoreActive: {
      type: Boolean,
      default: false
    },
    readMoreLimit: {
      type: Number,
      default: 200
    },
    icon: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String
    },
    heading: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String
    },
    margin: {
      type: String
    },
    weight: {
      type: String
    }
  },
  computed: {
    content () {
      return this.$slots.default ? this.$slots.default[0].text : ''
    }
  },
  methods: {
    switchReadMore () {
      this.isReadMoreExpand = !this.isReadMoreExpand
    },
    performEmitClick () {
      this.$emit('click')
    },
    checkContentHeight () {
      const componentTextInner = this.$el.querySelector('.component__text_inner')

      if (this.content?.length && componentTextInner.clientHeight === 0) {
        setTimeout(() => {
          this.checkContentHeight()
        }, 100)
        return
      }

      if (componentTextInner.clientHeight > this.readMoreLimit) {
        this.contentHigherThanLimit = true
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.checkContentHeight()
    })
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componenttext";
</style>
