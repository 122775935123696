import { TokenHelper } from '@/TokenHelper'
import * as Cookies from 'js-cookie'

export class User {
  constructor (data) {
    if (!data) {
      let tokenQueryParam = new URLSearchParams(location.search).get('token')
      let token = TokenHelper.getToken('secondStageToken')
      if (token || tokenQueryParam) {
        data = token ? TokenHelper.getPayload(token) : TokenHelper.getPayload(tokenQueryParam)
      } else {
        return
      }
    }
    this.data = data
    this.isTokenFromOldLMS = data.origin === 'subdomain'
    this.isTokenFromNewLMS = data.origin === 'workspace' || data.origin === 'customer'
    this.roles = data.roles
    this.customerRoles = data.customerRoles
    this.domain = data.domain
    this.userId = data.userId
    this.originUser = data.originUser
    this.customerId = data.customerId
    this.workspaceId = data.workspaceId
    this.workspace = data.workspace
    this.profile = data.profile || {}
    this.profileId = this.profile.id
    this.profile.roles = this.profile.roles || []
    this.profile.customerRoles = this.profile.customerRoles || []
    this.customerServices = data.customerServices || []
    this.managedOrganisationUnits = data.managedOrganisationUnits || []
    this.profileRoles = {
      digital_workbook_admin: this.profile.roles.includes('digital_workbook_admin'),
      event_coordinator: this.profile.roles.includes('event_coordinator'),
      infrastructure_manager: this.profile.roles.includes('infrastructure_manager'),
      learner: this.profile.roles.includes('learner'),
      learning_experience_administrator: this.profile.roles.includes('learning_experience_administrator'),
      trainer: this.profile.customerRoles.includes('trainer'),
      user_administrator: this.profile.roles.includes('user_administrator'),
      course_creator: this.profile.roles.includes('course_creator'),
      course_licence_manager: this.profile.roles.includes('course_licence_manager'),
      master_administrator: this.profile.customerRoles.includes('master_administrator'),
      manager: this.profile.roles.includes('manager'),
      admin: this.profile.roles.includes('admin')
    }
    this.customerServices = {
      EBS: this.customerServices['EBS'],
      LMS: this.customerServices['LMS'],
      DW: this.customerServices['DW'],
      CCS: this.customerServices['CCS'],
      ELMS: this.customerServices['ELMS'],
      API: this.customerServices['API']
    }
    this.profileRolesLibrary = {
      superadmin: 'superadmin',
      digital_workbook_admin: 'digital workbook admin',
      event_coordinator: 'event coordinator',
      infrastructure_manager: 'infrastructure manager',
      learner: 'learner',
      learning_experience_administrator: 'learning experience administrator',
      trainer: 'trainer',
      user_administrator: 'user administrator',
      course_creator: 'course creator',
      course_licence_manager: 'course licence manager',
      master_administrator: 'site admin',
      manager: 'manager',
      owner: 'owner',
      admin: 'admin'
    }

    window.user = this.data
  }

  isSuperadmin () {
    if (!this.roles) return false
    return this.roles.includes('superadmin')
  }

  isDigitalWorkbookAdmin () {
    if (!this.profileRoles) return false
    return this.profileRoles.digital_workbook_admin
  }

  isEventCoordinator () {
    if (!this.profileRoles) return false
    return this.profileRoles.event_coordinator
  }

  isInfrastructureManager () {
    if (!this.profileRoles) return false
    return this.profileRoles.infrastructure_manager
  }

  isUserAdministrator () {
    if (!this.profileRoles) return false
    return this.profileRoles.user_administrator
  }

  isAdmin () {
    if (!this.profileRoles) return false
    return this.profileRoles.admin
  }

  isSiteAdmin () {
    if (!this.customerRoles) return false
    return this.customerRoles.includes('master_administrator')
  }

  isCourseCreator () {
    if (!this.profileRoles) return false
    return this.profileRoles.course_creator
  }

  isCourseLicenceManager () {
    if (!this.profileRoles) return false
    return this.profileRoles.course_licence_manager
  }

  isLearningExperienceAdministrator () {
    if (!this.profileRoles) return false
    return this.profileRoles.learning_experience_administrator
  }

  isSessionTrainer (trainers) {
    if (!this.data) return false
    // trainers must be array of strings
    if (trainers.length) {
      return trainers.some(trainer => {
        if (this.isTokenFromOldLMS) {
          return trainer.lmsId.id === this.data.userId && trainer.lmsId.domain === this.data.domain
        } else {
          return trainer.userId === this.data.userId
        }
      })
    }
    return false
  }

  isSessionEventCoordinator (eventcoordinatorsId) {
    if (!this.data) return false
    if (eventcoordinatorsId) {
      return eventcoordinatorsId.some(coordinator => coordinator === this.data.userId)
    }
  }

  isLearner () {
    if (!this.profileRoles) return false
    return this.profileRoles.learner
  }

  isOnlyLearner () {
    if (!this.profileRoles) return false
    return (
      this.isLearner() &&
      !this.isSuperadmin() &&
      !this.isSiteAdmin() &&
      !this.isUserAdministrator() &&
      !this.isCourseCreator() &&
      !this.isCourseLicenceManager() &&
      !this.isEventCoordinator() &&
      !this.isInfrastructureManager() &&
      !this.isTrainer()
    )
  }

  isTrainer () {
    if (!this.customerRoles) return false
    return this.customerRoles.includes('trainer')
  }

  isOwner () {
    if (!this.customerRoles) return false
    return this.customerRoles.includes('owner')
  }

  isManager () {
    if (!this.profileRoles) return false
    return this.profileRoles.manager
  }

  isTokenFromOldLms () {
    if (!this.data) return false
    return this.isTokenFromOldLMS
  }

  isTokenFromSSO () {
    return Cookies.get('isSSO')
  }

  isTokenFromNewLms () {
    if (!this.data) return false
    return this.isTokenFromNewLMS
  }

  isInpersonated () {
    if (!this.originUser) return false
    return this.originUser !== null
  }

  hasCustomerServices (requiredCustomerServices) {
    if (!this.customerServices) return false
    return requiredCustomerServices.every(requiredCustomerService => {
      return this.customerServices[requiredCustomerService] === true
    })
  }

  hasAnyCustomerService (requiredAnyCustomerServices) {
    if (!this.customerServices || !requiredAnyCustomerServices) return false
    return requiredAnyCustomerServices.some(service => {
      return this.customerServices[service] === true
    })
  }

  hasPermissions (requiredPermissions) {
    if (!this.profileRoles) return false
    return requiredPermissions.every(permission => {
      return this.profileRoles[permission] === true
    })
  }

  hasAnyPermission (requiredAnyPermissions) {
    if (!this.profileRoles || !requiredAnyPermissions) return false
    return requiredAnyPermissions.some(permission => {
      return this.profileRoles[permission] === true
    })
  }

  hasRoles (requiredRoles) {
    if (!this.roles) return false
    return requiredRoles.every(requiredRole => {
      return this.roles[requiredRole] === true
    })
  }

  hasAnyRole (requiredRole) {
    if (!this.roles || !requiredRole) return false
    for (let index in this.roles) {
      if (requiredRole.some(role => role === this.roles[index])) {
        return true
      }
    }
    return false
  }

  hasAnyCustomerRole (requiredRole) {
    if (!this.customerRoles || !requiredRole) return false
    for (let index in this.customerRoles) {
      if (requiredRole.some(role => role === this.customerRoles[index])) {
        return true
      }
    }
    return false
  }

  hasSelectedProfile () {
    if (!this.profile) return
    return Boolean(this.profile.id)
  }

  listOrgUnitManagerUnits () {
    if (!this.managedOrganisationUnits.length) return []
    return this.managedOrganisationUnits
  }

  listPermissions () {
    if (!this.data) return false
    const roleObj = []
    const entries = Object.entries(this.profileRoles)
    for (const [key, value] of entries) {
      if (+value) {
        roleObj.push(key)
      }
    }
    return roleObj
  }

  getCustomerRoleArea () {
    return Cookies.get('customerRoleArea')
  }

  getProfileRolesLibraryName (role) {
    if (!this.profileRolesLibrary) return
    return this.profileRolesLibrary[role]
  }
  getCustomerId () {
    return this.customerId
  }
  getWorkspaceId () {
    return this.workspaceId
  }
}

window.user = new User()
