<template>
    <div class="header__top_account_nav">
        <div class="header__top_account_nav_items">
            <nav aria-label="Top navigation">
                <div class="columns is-block-mobile fullheight is-variable is-0">
                    <template v-for="(item, index) in itemsOutput">
                    <div
                        :key="`${index}-separator`"
                        v-if="item.separator"
                        :class="[
                            'column',
                            'is-hidden-mobile',
                            'is-narrow'
                        ]"
                    >
                        <div class="header__top_account_nav_items_separator">
                            <component-separator :vertical="true"/>
                        </div>
                    </div>
                    <div
                        :class="[
                        'column',
                        item.name !== '' ? 'is-narrow' : '',
                        index === 0 ? 'is-hidden-mobile' : ''
                        ]"
                        :key="index"
                    >
                        <component-button
                            :class="[
                                'header__top_nav_item',
                                `header__top_nav_item--${item.name}`,
                                item.name === '' ? `header__top_nav_item--blank` : ''
                            ]"
                            :to="{ name: item.routeName }"
                            :theme="'nav'"
                            :inverse="inverseStyle"
                            :size="''"
                            :icon-raw="item.icon"
                            :icon="item.icon"
                            :icon-name="item.iconName"
                            :align="'left'"
                            :name="`header-top-account-nav-items-button-${item.name}`"
                        >{{ !item.icon ? toCapitalize(item.title) : '' }}
                        </component-button>
                    </div>
                </template>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import ComponentButton from '@/components/default/shared/ComponentButton'
import ComponentSeparator from '@/components/default/shared/ComponentSeparator'
import { User } from '@/User'

export default {
  name: 'header-top-account-nav',
  components: { ComponentSeparator, ComponentButton },
  data () {
    let user = new User()
    return {
      user: user,
      items: [
        {
          name: '',
          visible: () => true
        },
        {
          name: 'setup',
          title: 'Setup',
          routeName: 'setup',
          visible: () => (
            (
              this.user.isSuperadmin() ||
          this.user.isEventCoordinator() ||
          this.user.isInfrastructureManager() ||
          this.user.isUserAdministrator() ||
          this.user.isSiteAdmin() ||
          this.user.isLearningExperienceAdministrator()
            ) ||
          (
            this.user.isCourseCreator() &&
          this.user.hasAnyCustomerService(['EBS', 'LMS', 'DW'])
          )
          )
        },
        {
          name: 'users',
          title: 'People',
          routeName: 'users',
          visible: () => (
            (
              this.user.isSuperadmin() ||
          this.user.isSiteAdmin() ||
          this.user.isUserAdministrator() ||
          this.user.isManager()
            ) ||
          (
            this.user.isLearningExperienceAdministrator() &&
          this.user.hasAnyCustomerService(['LMS'])
          ) ||
          (
            this.user.isTokenFromOldLms() &&
          this.user.isEventCoordinator()
          )
          )
        },
        {
          name: 'catalogue',
          title: 'Catalogue',
          routeName: 'catalogue',
          visible: () => (
            (
              (
                this.user.isCourseCreator() ||
          this.user.isCourseLicenceManager() ||
          this.user.isLearningExperienceAdministrator() ||
          this.user.isManager()
              ) &&
          this.user.hasAnyCustomerService(['CCS'])
            ) ||
          (
            (
              this.user.isInfrastructureManager() ||
          this.user.isSiteAdmin() ||
          this.user.isEventCoordinator() ||
          this.user.isTrainer()
            ) &&
          this.user.hasAnyCustomerService(['EBS'])
          ) ||
          (
            (
              this.user.isLearningExperienceAdministrator()
            ) &&
          this.user.hasAnyCustomerService(['LMS'])
          )
          )
        },
        {
          name: this.$t('header.topAccountNav.menu.reports'),
          title: this.$t('header.topAccountNav.titles.reports'),
          routeName: 'reports',
          visible: () => (
            (
              this.user.isTokenFromOldLms() &&
          this.user.isEventCoordinator()
            ) ||
          (
            this.user.isTokenFromNewLms() &&
          this.user.hasSelectedProfile() &&
          (
            this.user.isSuperadmin() ||
          this.user.isEventCoordinator() ||
          this.user.isSiteAdmin()
          ) &&
          this.user.hasAnyCustomerService(['EBS'])
          ) ||
          (
            this.user.isTokenFromNewLms() &&
          this.user.hasSelectedProfile() &&
          (
            this.user.isSuperadmin() ||
          this.user.isSiteAdmin() ||
          this.user.isLearningExperienceAdministrator()
          ) &&
          this.user.hasAnyCustomerService(['LMS'])
          )
          )
        },
        {
          name: 'my-learning',
          title: 'My learning',
          routeName: 'my-learning',
          separator: true,
          visible: () => (
            (
              (
                (
                  this.user.hasAnyCustomerService(['LMS']) ||
          this.user.hasAnyCustomerService(['EBS'])
                ) &&
          this.user.isLearner()
              )
            ) &&
          this.user.isTokenFromNewLms()
          )
        },
        {
          name: 'my-training',
          title: 'My training',
          routeName: 'my-learning',
          separator: true,
          visible: () => (
            this.user.isTrainer() &&
          !(
            (
              (
                (
                  this.user.hasAnyCustomerService(['LMS']) ||
          this.user.hasAnyCustomerService(['EBS'])
                ) &&
          this.user.isLearner()
              )
            ) &&
          this.user.isTokenFromNewLms()
          )
          )
        }
      ]
    }
  },
  props: {
    inverseStyle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    itemsOutput () {
      return this.items.filter(item => {
        return item.visible()
      })
    },
    secondStageToken () {
      return this.$store.getters.getAuthUserSecondStageTokenData
    }
  },
  watch: {
    secondStageToken () {
      this.user = new User()
    }
  }
}
</script>
