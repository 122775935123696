import axios from 'axios'
import api from '@/api'
import router from '@/router'
import { getNestedByArray } from '@/functions'
import { eventbus } from '@/main'

const CancelToken = axios.CancelToken
let cancelebsCourseUploadFileFile
let cancelEbsCourseGetAttachmentFile

const module = {
  name: '',
  description: '',
  owner: '',
  type: '',
  subType: '',
  id: '',
  nodes: [],
  required: true,
  newModule: true
}

const data = {
  data: {
    id: '',
    owner: '',
    name: '',
    description: '',
    duration: '00:00',
    file: '',
    draft: '',
    labels: [],
    contentTree: [],
    sequence: '',
    versions: [],
    releaseNotes: '',
    instantOffer: true,
    selectedOrgUnits: [],
    selectedWorkspaces: [],
    progress: '',
    contentId: '',
    certificateActive: false,
    completedPercentage: 0,
    nodes: [],
    files: [],
    urls: [],
    removedEvents: [],
    externalModulesData: [],
    eventsPinnedButNonExistentInDraftedCourseStructure: [],
    usage: '',
    enrolments: ''
  },
  default: {},
  custom: {
    labels: {
      data: [],
      pagination: {}
    },
    sharedToWorkspaces: [],
    licences: [],
    duringGetCourse: false,
    extendModulesPending: false
  }
}

const ebsCourses = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsCourse: state => {
      return state.data
    },
    getEbsCourseUsage: state => {
      return state.data.usage
    },
    getEbsCourseEnrolments: state => {
      return state.data.enrolments
    },
    getEbsCourseId: state => {
      return state.data.id
    },
    getEbsCourseIsEventType: state => {
      const externalModulesDataRaw = state.data.externalModulesData
      const externalModulesDataOutput = externalModulesDataRaw ? Object.values(externalModulesDataRaw) : []
      let allExternalModulesAreEventType = true

      for (let module of externalModulesDataOutput) {
        if (module.type !== 'event') {
          allExternalModulesAreEventType = false
        }
      }

      return allExternalModulesAreEventType
    },
    getEbsCourseIsSingleEventType: state => {
      const contentTreeRaw = state.data.contentTree
      const externalModulesDataRaw = state.data.externalModulesData
      const externalModulesDataOutput = externalModulesDataRaw ? Object.values(externalModulesDataRaw) : []
      let contentTreeOutput

      if (contentTreeRaw && contentTreeRaw.length) {
        if (contentTreeRaw[0].name === 'Root') {
          contentTreeOutput = contentTreeRaw[0].nodes
        } else {
          contentTreeOutput = contentTreeRaw
        }
      } else {
        contentTreeOutput = []
      }

      return (contentTreeOutput && contentTreeOutput.length === 1) && (externalModulesDataOutput && externalModulesDataOutput.length === 1 && externalModulesDataOutput[0].type === 'event')
    },
    getEbsCourseContentId: state => {
      return state.data.contentId
    },
    getEbsCourseDefaultContentTreeMappedId: state => {
      const contentTree = state.default.contentTree
      return contentTree ? contentTree.map(obj => obj.id) : []
    },
    getEbsCourseCatalogItemId: state => {
      return state.data.catalogItemId
    },
    getEbsCourseCatalogItemVersionId: state => {
      return state.data.catalogItemVersionId
    },
    getEbsCourseContentTreeRootNodes: state => {
      return state.data.contentTree[0].nodes
    },
    getEbsCourseContentTreeRootId: state => {
      return state.data.contentTree[0].id
    },
    getEbsCourseName: state => {
      return state.data.name
    },
    getEbsCourseExternalModulesData: state => {
      return state.data.externalModulesData
    },
    getEbsCourseDuration: state => {
      return state.data.duration
    },
    getEbsCourseLabels: state => {
      return state.data.labels ? state.data.labels.map(obj => {
        if (obj.constructor === Object) {
          return Object.keys(obj)[0]
        } else {
          return obj
        }
      }) : []
    },
    getEbsCourseLabelsNew: state => {
      return state.data.labels ? state.data.labels.filter(fObj => fObj.constructor === String).map(mObj => {
        if (mObj.constructor === String) {
          return mObj
        }
      }) : []
    },
    getEbsCourseDefaultName: state => {
      return state.default.name
    },
    getEbsCourseDescription: state => {
      return state.data.description
    },
    getEbsCourseFile: state => {
      return state.data.file ? [{ imageId: state.data.file }] : []
    },
    getEbsCourseFiles: state => {
      return state.data.files
    },
    getEbsCourseUrls: state => {
      return state.data.urls
    },
    getEbsCourseOwner: state => {
      return state.data.owner
    },
    getEbsCourseFromLoggedInWorkspace: (state, getters) => {
      return state.data.owner === getters.getAuthUserSecondStageTokenDataWorkspaceId
    },
    getEbsCourseCompletedPercentage: state => {
      return state.data
    },
    getEbsCourseCertificateActive: state => {
      return state.data.certificateActive
    },
    getEbsCourseModules: state => {
      return state.data.contentTree
    },
    getEbsCourseOwnerCustomerId: state => {
      return state.data.ownerCustomerId
    },
    getEbsCourseSequence: state => {
      return state.data.sequence
    },
    getEbsCourseNewModule: state => {
      return state.data.newModule
    },
    getEbsCourseAllowEdit: state => {
      let allowEdit = true
      const recursiveLoop = (node, nodeIndex) => {
        for (let i in node.nodes) {
          let item = node.nodes[i]
          if (item) {
            if (!item.name) {
              allowEdit = false
            }
            if (item.type === 'module' && !item.id) {
              allowEdit = false
            }
          }
          if (item && item.nodes) {
            recursiveLoop(node.nodes[i], nodeIndex ? [...nodeIndex, i] : i)
          }
        }
      }
      recursiveLoop(state.data)
      return allowEdit
    },
    getEbsCourseModulePreviousIdByIndex: state => index => {
      const stateData = JSON.parse(JSON.stringify(state.data))
      let rawIndexes = JSON.parse(JSON.stringify(index))
      let indexLast = rawIndexes.pop()
      let indexArr = ['contentTree']

      for (let i in rawIndexes) {
        indexArr.push(index[i])
        indexArr.push('nodes')
      }

      indexLast--
      indexArr.push(indexLast)

      const selectedModule = getNestedByArray(stateData, indexArr)
      return selectedModule ? selectedModule.id : null
    },
    getEbsCourseModuleInGroupByIndex: state => index => {
      const stateData = JSON.parse(JSON.stringify(state.data))
      let rawIndexes = JSON.parse(JSON.stringify(index))
      rawIndexes.pop()
      let indexParent = rawIndexes.pop()
      let indexArr = ['contentTree']

      for (let i in rawIndexes) {
        indexArr.push(index[i])
        indexArr.push('nodes')
      }

      const selectedModule = getNestedByArray(stateData, indexArr)[indexParent]
      return selectedModule ? selectedModule.id : null
    },
    getEbsCourseLastModuleId: state => {
      const contentTree = state.data.contentTree
      const lastContentTreeModule = contentTree[contentTree.length - 1]

      return lastContentTreeModule ? lastContentTreeModule.id : null
    },
    getEbsCourseIsDraftVersion: state => {
      return state.data.draft
    },
    getEbsCourseHasBeenReleased: state => {
      return state.data.versions ? Boolean(state.data.versions.length) : false
    },
    getEbsCourseCreatedAt: state => {
      return state.data.createdAt
    },
    getEbsCourseReleaseNotes: state => {
      return state.data.releaseNotes
    },
    getEbsCourseInstantOffer: state => {
      return state.data.instantOffer
    },
    getEbsCourseVersions: state => {
      const versions = state.data.versions
      const isDraft = state.data.draft
      let versionsArr = []
      for (let i in versions) {
        for (let j in versions[i].minors) {
          versionsArr.push({
            version: `${Number(i) + 1}.${j}`,
            releaseDate: versions[i].minors[j].releaseDate,
            releaseNotes: versions[i].minors[j].releaseNotes,
            releasedBy: versions[i].minors[j].userName || 'N/A'
          })
        }
      }
      if (isDraft) {
        versionsArr.push({
          version: 'Draft',
          releaseDate: '',
          releaseNotes: '',
          releasedBy: ''
        })
      }
      versionsArr.reverse()
      return versionsArr
    },
    getEbsCourseLatestVersionNumber: state => {
      const versions = state.data.versions
      if (!versions.length) return
      const latestMajorVersion = versions.length
      const latestMinorVersion = versions[latestMajorVersion - 1].minors.length
      return `${latestMajorVersion}.${latestMinorVersion - 1}`
    },
    getEbsCourseLatestVersionId: state => {
      const versions = state.data.versions
      const latestMajorVersion = versions.length ? versions[versions.length - 1] : null
      const latestMajorVersionId = latestMajorVersion ? latestMajorVersion.versionId : null

      return latestMajorVersionId
    },
    getEbsCourseLatestVersionDate: state => {
      const versions = state.data.versions
      if (!versions.length) return
      const latestMajorVersion = versions.length
      const latestMinorVersion = versions[latestMajorVersion - 1].minors.length
      return versions[latestMajorVersion - 1].minors[latestMinorVersion - 1].releaseDate
    },
    getEbsCourseLatestVersionContentTree: state => {
      const versions = state.data.versions
      if (!versions.length) return
      const latestMajorVersion = versions.length
      const latestMinorVersion = versions[latestMajorVersion - 1].minors.length
      return versions[latestMajorVersion - 1].minors[latestMinorVersion - 1].contentTree.nodes
    },
    getEbsCourseLatestVersionContentTreeMappedId: (state, getters) => {
      const contentTree = getters.getEbsCourseLatestVersionContentTree
      return contentTree ? contentTree.map(obj => obj.id) : []
    },
    getEbsCourseLatestVersionContentTreeContainsSpecificModule: state => externalId => {
      const versions = state.data.versions
      const externalModulesDataRaw = state.data.externalModulesData
      const externalModulesDataOutput = externalModulesDataRaw ? Object.values(externalModulesDataRaw) : []
      const moduleInExternalModulesData = externalModulesDataOutput.find(obj => obj.externalId === externalId)

      if (!versions.length || !moduleInExternalModulesData) return false

      const latestMajorVersion = versions.length
      const latestMinorVersion = versions[latestMajorVersion - 1].minors.length
      const contentTree = versions[latestMajorVersion - 1].minors[latestMinorVersion - 1].contentTree

      let hasSpecificModule = false

      const recursiveLoop = (node, nodeIndex) => {
        for (let i in node.nodes) {
          let item = node.nodes[i]
          if (item) {
            if (item.id === moduleInExternalModulesData.nodeIds[0]) {
              hasSpecificModule = true
            }
          }
          if (item && item.nodes) {
            recursiveLoop(node.nodes[i], nodeIndex ? [...nodeIndex, i] : i)
          }
        }
      }

      recursiveLoop(contentTree)

      return hasSpecificModule
    },
    getEbsCourseCompletionPercentage: state => {
      return state.data.completedPercentage
    },
    getEbsCourseCompletionNodes: state => {
      return state.data.nodes
    },
    getEbsCourseCompletionNodesCompleted: state => {
      const nodes = state.data.nodes
      const nodesCompleted = nodes.filter(obj => obj.completionState === 'completed' || obj.completionState === 'passed')

      return nodesCompleted
    },
    getEbsCourseModulesFirstToLaunch: state => {
      let selectedModuleId = ''
      const recursiveLoopCollectModules = (node, nodeIndex) => {
        const nodes = node.contentTree || node.nodes
        for (let i in nodes) {
          if (
            nodes[i].history &&
            (
              nodes[i].history.completionState === 'not_started' ||
              nodes[i].history.completionState === 'started'
            ) &&
            nodes[i].subType === 'scorm'
          ) {
            selectedModuleId = nodes[i].id
            break
          }
          if (nodes[i] && nodes[i].nodes) {
            recursiveLoopCollectModules(nodes[i], nodeIndex ? [...nodeIndex, i] : i)
          }
        }
      }

      recursiveLoopCollectModules(state.data)

      return selectedModuleId
    },
    getEbsCourseCustomLabelsPagination: state => {
      return state.custom.labels.pagination
    },
    getEbsCourseCustomLabelsMappedLabelValue: state => {
      const rawItems = state.custom.labels.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.label + item.id,
          customLabel: item.label,
          value: item.id
        }
      })
    },
    getEbsCourseCustomLabelsMappedIdNameStatus: state => {
      const rawItems = state.custom.labels.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.label
        }
      })
    },
    getEbsCourseCustomSharedToWorkspaces: state => {
      return state.custom.sharedToWorkspaces
    },
    getEbsCourseHasLicencesToDifferentCustomersThanOwner: (state, getters) => {
      const licences = state.custom.licences
      const courseOwnerId = state.data.owner
      const loggedInWorkspaceId = getters.getLmsSuperadminWorkspaceId
      const loggedInWorkspaceCustomerId = getters.getLmsSuperadminWorkspaceCustomerId
      const licencesFilteredByCustomerId = courseOwnerId === loggedInWorkspaceId ? licences.filter(obj => obj.offeredTo !== loggedInWorkspaceCustomerId) : []

      return Boolean(licencesFilteredByCustomerId.length)
    },
    getEbsCourseCustomLicences: state => {
      return state.custom.licences
    },
    getEbsCourseFilesUploading: state => {
      const files = state.data.files
      return files.length ? Boolean(files.find(obj => obj.loaded !== undefined && Number(obj.loaded) !== Number(obj.total))) : false
    },
    getEbsCourseRemovedEvents: state => {
      return state.data.removedEvents
    },
    getEbsCourseCustomDuringGetCourse: state => {
      return state.custom.duringGetCourse
    },
    getEbsCourseCustomExtendModulesPending: state => {
      return state.custom.extendModulesPending
    }
  },
  mutations: {
    EBS_COURSE_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(state.data))
    },
    EBS_COURSE_CLEAR_CUSTOM_LABELS (state) {
      state.custom.labels = JSON.parse(JSON.stringify(data.custom.labels))
    },
    EBS_COURSE_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    EBS_COURSE_SET (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))
      const externalModulesData = payloadData.externalModulesData

      const recursiveLoopAddExtendedDataKey = (data, nodeIndex) => {
        const nodes = data.contentTree || data.nodes
        for (let i in nodes) {
          const node = nodes[i]
          const nodeModuleId = node.moduleId
          const nodeModuleExternalModuleData = externalModulesData ? externalModulesData[nodeModuleId] : undefined
          if (node.extendedData === undefined) {
            node.extendedData = ''
          }
          if (nodeModuleExternalModuleData) {
            node.nodeIds = nodeModuleExternalModuleData.nodeIds
            node.subType = nodeModuleExternalModuleData.type
            node.previewId = nodeModuleExternalModuleData.previewId
            node.externalId = nodeModuleExternalModuleData.externalId
            node.history = {
              additionalData: '',
              completionState: '',
              contentTreeNodeId: '',
              moduleId: '',
              type: ''
            }
          }
          if (nodes[i] && nodes[i].nodes) {
            recursiveLoopAddExtendedDataKey(nodes[i], nodeIndex ? [...nodeIndex, i] : i)
          }
        }
      }
      recursiveLoopAddExtendedDataKey(payloadData)
      payloadData.certificateActive = Boolean(payloadData.contentId)
      payloadData.releaseNotes = ''
      payloadData.completedPercentage = 0
      payloadData.nodes = []
      payloadData.externalModulesData = payloadData.externalModulesData || []
      payloadData.duration = payloadData.head ? payloadData.head.duration : ''
      payloadData.labels = payloadData.head ? (payloadData.head.labels || []) : []
      payloadData.files = payloadData.files && payloadData.files.length ? payloadData.files.map(obj => {
        return {
          name: obj.name,
          size: obj.size,
          url: obj.url,
          hide: false
        }
      }) : []
      payloadData.removedEvents = state.data.removedEvents && state.data.removedEvents.length ? state.data.removedEvents : []
      if (payloadData.versions && payloadData.versions.length === 0) {
        payloadData.instantOffer = true
      }
      payloadData.eventsPinnedButNonExistentInDraftedCourseStructure = payloadData.eventsPinnedButNonExistentInDraftedCourseStructure || state.data.eventsPinnedButNonExistentInDraftedCourseStructure || []

      state.data = JSON.parse(JSON.stringify(payloadData))
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    EBS_COURSE_SET_CUSTOM_DURINGGETCOURSE (state, payload) {
      state.custom.duringGetCourse = payload
    },
    EBS_COURSE_SET_WITHOUTMODULES (state, payload) {
      const stateData = JSON.parse(JSON.stringify(state.data))
      let payloadData = payload

      payloadData.externalModulesData = stateData.id ? stateData.externalModulesData : payloadData.externalModulesData
      payloadData.contentTree = stateData.id ? stateData.contentTree : payloadData.contentTree
      payloadData.duration = payloadData.head ? payloadData.head.duration : ''
      payloadData.labels = payloadData.head ? (payloadData.head.labels || []) : []
      payloadData.files = payloadData.files && payloadData.files.length ? payloadData.files.map(obj => {
        return {
          name: obj.name,
          size: obj.size,
          url: obj.url,
          hide: false
        }
      }) : []
      payloadData.removedEvents = state.data.removedEvents && state.data.removedEvents.length ? state.data.removedEvents : []
      payloadData.eventsPinnedButNonExistentInDraftedCourseStructure = payloadData.eventsPinnedButNonExistentInDraftedCourseStructure || state.data.eventsPinnedButNonExistentInDraftedCourseStructure || []

      state.data = JSON.parse(JSON.stringify(payloadData))
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    EBS_COURSE_SET_HISTORY (state, payload) {
      const recursiveLoopExtendModule = (node, nodeIndex, data) => {
        const nodes = node.contentTree || node.nodes
        for (let i in nodes) {
          if (nodes[i].id === data.contentTreeNodeId) {
            nodes[i].history = data
            break
          }
          if (nodes[i] && nodes[i].nodes) {
            recursiveLoopExtendModule(nodes[i], nodeIndex ? [...nodeIndex, i] : i, data)
          }
        }
      }

      for (let i in payload.nodes) {
        recursiveLoopExtendModule(state.data, undefined, payload.nodes[i])
      }
      state.data.completedPercentage = payload.completedPercentage
      state.data.nodes = payload.nodes
    },
    EBS_COURSE_SET_NAME (state, payload) {
      state.data.name = payload
    },
    EBS_COURSE_SET_DESCRIPTION (state, payload) {
      state.data.description = payload
    },
    EBS_COURSE_SET_DURATION (state, payload) {
      state.data.duration = payload
    },
    EBS_COURSE_SET_IMAGE (state, payload) {
      state.data.file = payload
    },
    EBS_COURSE_ADD_URL (state, payload) {
      state.data.urls.push(payload)
    },
    EBS_COURSE_UPDATE_URL (state, payload) {
      let urls = state.data.urls
      const selectedUrlIndex = urls.findIndex(obj => obj.url === payload.url)

      urls.splice(selectedUrlIndex, 1, payload)
    },
    EBS_COURSE_REMOVE_URL (state, payload) {
      let urls = state.data.urls
      let selectedUrlIndex = urls.findIndex(obj => obj.url === payload)

      urls.splice(selectedUrlIndex, 1)
    },
    EBS_COURSE_ADD_FILE (state, payload) {
      state.data.files.push(payload)
    },
    EBS_COURSE_SET_FILE_PROGRESS (state, payload) {
      let files = state.data.files
      let selectedFile = files.find(obj => obj.url === payload.url)

      selectedFile.loaded = payload.loaded
      selectedFile.total = payload.total
    },
    EBS_COURSE_SET_FILES (state, payload) {
      state.data.files = payload
    },
    EBS_COURSE_SET_FILE_PROP (state, payload) {
      let files = state.data.files
      let selectedFileIndex = files.findIndex(obj => obj.url === payload.url)
      if (selectedFileIndex > -1) {
        files[selectedFileIndex][payload.key] = payload.value
      }
    },
    EBS_COURSE_SET_MODULE_VALUE (state, payload) {
      let stateData = state.data
      const data = JSON.parse(JSON.stringify(payload))
      const index = data.index
      const indexLast = index.pop()
      const key = data.key
      const value = data.value

      let indexArr = ['contentTree']

      for (let i in index) {
        indexArr.push(index[i])
        indexArr.push('nodes')
      }

      getNestedByArray(stateData, indexArr)[indexLast][key] = value
    },
    EBS_COURSE_SET_CERTIFICATE_ACTIVE (state, payload) {
      state.data.certificateActive = payload
    },
    EBS_COURSE_SET_ENROLMENT_TYPE (state, payload) {
      state.data.enrolmentType = payload
    },
    EBS_COURSE_REMOVE_IMAGE (state) {
      state.data.file = ''
    },
    EBS_COURSE_REMOVE_FILE (state, payload) {
      let files = JSON.parse(JSON.stringify(state.data.files))
      let selectedFileIndex = files.findIndex(obj => obj.url === payload)

      state.data.files.splice(selectedFileIndex, 1)
    },
    EBS_COURSE_ADD_MODULE (state, payload) {
      let stateData = state.data
      const data = JSON.parse(JSON.stringify(payload))
      const index = data.index || [stateData.contentTree.length]
      const type = data.type
      const subType = data.subType
      const after = data.after
      const indexLast = after ? index.pop() + 1 : index.pop()
      let newModule = JSON.parse(JSON.stringify(module))
      newModule.type = type
      newModule.subType = subType
      let indexArr = ['contentTree']

      for (let i in index) {
        indexArr.push(index[i])
        indexArr.push('nodes')
      }

      getNestedByArray(stateData, indexArr).splice(indexLast, 0, JSON.parse(JSON.stringify(newModule)))
    },
    EBS_COURSE_REMOVE_MODULE (state, payload) {
      const index = payload
      const indexLast = index.pop()
      let stateData = state.data
      let indexArr = ['contentTree']

      for (let i in index) {
        indexArr.push(index[i])
        indexArr.push('nodes')
      }

      getNestedByArray(stateData, indexArr).splice(indexLast, 1)
    },
    EBS_COURSE_MOVE_MODULE (state, payload) {
      const position = payload.position
      const fromIndex = JSON.parse(JSON.stringify(payload.from))
      const fromIndexLast = fromIndex.pop()
      let fromItem
      let fromItemRaw
      let fromIndexArr = ['contentTree']
      const toIndex = JSON.parse(JSON.stringify(payload.to))
      let toIndexLast
      let toIndexArr = ['contentTree']
      let stateData = state.data

      switch (position) {
        case 'before':
          toIndexLast = toIndex.pop()
          break
        case 'after':
          toIndexLast = toIndex.pop() + 1
          break
        case 'zero':
          toIndexLast = 0
          break
      }

      const recursiveLoop = (node, nodeIndex) => {
        const nodes = node.contentTree || node.nodes
        for (let i in nodes) {
          if (nodes[i] && nodes[i].toDelete) {
            let toRemoveItemArr = ['contentTree']
            for (let i in nodeIndex) {
              toRemoveItemArr.push(nodeIndex[i])
              toRemoveItemArr.push('nodes')
            }
            getNestedByArray(stateData, toRemoveItemArr).splice(i, 1)
          }
          if (nodes[i] && nodes[i].nodes) {
            recursiveLoop(nodes[i], nodeIndex ? [...nodeIndex, i] : i)
          }
        }
      }

      for (let i in fromIndex) {
        fromIndexArr.push(fromIndex[i])
        fromIndexArr.push('nodes')
      }
      for (let i in toIndex) {
        toIndexArr.push(toIndex[i])
        toIndexArr.push('nodes')
      }

      fromItem = getNestedByArray(stateData, fromIndexArr)[fromIndexLast]
      fromItem.toDelete = true

      fromItemRaw = JSON.parse(JSON.stringify(fromItem))
      fromItemRaw.toDelete = undefined

      getNestedByArray(stateData, toIndexArr).splice(toIndexLast, 0, fromItemRaw)
      recursiveLoop(stateData)
    },
    EBS_COURSE_SET_RELEASE_NOTES (state, payload) {
      state.data.releaseNotes = payload
    },
    EBS_COURSE_SET_CUSTOM_LABELS_DATA (state, payload) {
      state.custom.labels.data = payload
    },
    EBS_COURSE_SET_CUSTOM_LABELS_PAGINATION (state, payload) {
      state.custom.labels.pagination = payload
    },
    EBS_COURSE_SET_CUSTOM_SHAREDTOWORKSPACES (state, payload) {
      state.custom.sharedToWorkspaces = payload
    },
    EBS_COURSE_SET_CUSTOM_LICENCES (state, payload) {
      state.custom.licences = payload
    },
    EBS_COURSE_SET_LABELS (state, payload) {
      const labels = state.data.labels ? JSON.parse(JSON.stringify(state.data.labels)) : []

      state.data.labels = payload.map(mObj => {
        const labelIndex = labels.findIndex(fiObj => Object.keys(fiObj)[0] === mObj.value)
        if (labelIndex !== -1) {
          return labels[labelIndex]
        } else if (mObj.customLabel) {
          let obj = {}
          obj[mObj.value] = mObj.customLabel
          return obj
        } else {
          return mObj.value
        }
      })
    },
    EBS_COURSE_ADD_LABEL (state, payload) {
      state.data.labels.push(payload)
    },
    EBS_COURSE_SET_INSTANT_OFFER (state, payload) {
      state.data.instantOffer = payload
    },
    EBS_COURSE_SET_REMOVEDEVENTS (state, payload) {
      const eventsPinnedButNonExistentInDraftedCourseStructure = state.data.eventsPinnedButNonExistentInDraftedCourseStructure
      const removedEvents = payload.filter(obj => eventsPinnedButNonExistentInDraftedCourseStructure.find(fObj => fObj === obj.id))

      let eventsMapped = removedEvents.map(obj => {
        return {
          externalId: obj.id,
          name: obj.name,
          id: null,
          moduleId: null,
          subType: 'event',
          type: 'module',
          versionId: '',
          isRemovedEvent: true
        }
      })

      state.data.removedEvents = eventsMapped
    },
    EBS_COURSE_SET_USAGE (state, payload) {
      state.data.usage = payload
    },
    EBS_COURSE_SET_ENROLMENTS (state, payload) {
      state.data.enrolments = payload
    },
    EBS_COURSE_SET_CUSTOM_EXTENDMODULESPENDING (state, payload) {
      state.custom.extendModulesPending = payload
    }
  },
  actions: {
    ebsCourseGet ({ commit, rootState, dispatch, getters }, data) {
      return new Promise((resolve, reject) => {
        const courseId = data ? data.courseId : rootState.route.params.id
        const extendModules = data ? data.extendModules : false
        const loggedInUserWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        const performEbsCourseExtendModules = () => {
          return dispatch('ebsCourseExtendModules')
        }
        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/courses/${courseId}?workspaceId=${loggedInUserWorkspaceId}`
        }).then(response => {
          commit('EBS_COURSE_SET', response.data)
          let courseData = JSON.parse(JSON.stringify(response.data))
          let courseDataUrls = courseData.urls
          if (courseDataUrls && courseDataUrls.length) {
            commit('EBS_CATALOGITEM_SET_URLS', courseDataUrls)
          }
          if (extendModules) {
            performEbsCourseExtendModules().finally(() => {
              resolve()
            })
          } else {
            resolve(response.data)
          }

          const courseIsSingleEventType = getters.getEbsCourseIsSingleEventType

          if (!courseIsSingleEventType) {
            eventbus.$emit('ebs-course-get')
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseGetUsage ({ commit }, courseId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/courses/course-by-usage/${courseId}`
        }).then(response => {
          commit('EBS_COURSE_SET_USAGE', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseGetEnrolments ({ commit }, courseId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/courses/course-by-enrolments/${courseId}`
        }).then(response => {
          commit('EBS_COURSE_SET_ENROLMENTS', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseGetWithoutModules ({ commit, getters }, courseId) {
      return new Promise((resolve, reject) => {
        commit('EBS_COURSE_SET_CUSTOM_DURINGGETCOURSE', true)

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/courses/${courseId}`
        }).then(response => {
          commit('EBS_COURSE_SET_WITHOUTMODULES', response.data)
          resolve(response.data)

          const courseIsSingleEventType = getters.getEbsCourseIsSingleEventType

          if (!courseIsSingleEventType) {
            eventbus.$emit('ebs-course-get')
          }
        }).catch(error => {
          reject(error)
        }).finally(() => {
          commit('EBS_COURSE_SET_CUSTOM_DURINGGETCOURSE', false)
        })
      })
    },
    ebsCourseGetVersion ({ commit, dispatch, getters }, data) {
      return new Promise((resolve, reject) => {
        commit('EBS_COURSE_CLEAR')
        const courseId = data.courseId
        const versionId = data.versionId
        const extendModules = data ? data.extendModules : false
        const performEbsCourseExtendModules = () => {
          return dispatch('ebsCourseExtendModules')
        }
        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.courseCatalog}/v2/course-version/${courseId}/${versionId}`
        }).then(response => {
          let courseData = JSON.parse(JSON.stringify(response.data))
          let courseDataUrls = courseData.urls
          if (courseDataUrls.length) {
            for (let index in courseDataUrls) {
              courseDataUrls[index].providedByCourse = true
            }
          }
          commit('EBS_COURSE_SET', courseData)
          if (courseDataUrls && courseDataUrls.length) {
            commit('EBS_CATALOGITEM_SET_URLS', courseDataUrls)
          }
          if (extendModules) {
            performEbsCourseExtendModules().finally(() => {
              eventbus.$emit('ebs-course-extend-modules')
              resolve(response.data)
            })
          } else {
            resolve(response.data)
          }

          const courseIsSingleEventType = getters.getEbsCourseIsSingleEventType

          if (!courseIsSingleEventType) {
            eventbus.$emit('ebs-course-get-version')
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseGetHistory ({ commit, getters }, enrolmentId) {
      return new Promise((resolve, reject) => {
        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.courseCatalog}/v2/course-history/${enrolmentId}`
        }).then(response => {
          eventbus.$emit('ebs-course-get-history')
          commit('EBS_COURSE_SET_HISTORY', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseGetLicences ({ commit }, courseId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/list-offers?pageSize=9999&filters[courses]=${courseId}`
        }).then(response => {
          commit('EBS_COURSE_SET_CUSTOM_LICENCES', response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseGetRemovedEvents ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const courseId = data || state.data.id

        axios({
          method: 'get',
          url: `${api.classroom}/events/available-for-course/${courseId}`
        }).then(response => {
          commit('EBS_COURSE_SET_REMOVEDEVENTS', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseClone (context, data) {
      return new Promise((resolve, reject) => {
        const courseId = data

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/courses/${courseId}/clone`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseArchive ({ dispatch }, data) {
      const courseId = data.courseId
      const courseName = data.courseName

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/courses/${courseId}/archive`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: `Archiving started for  ${courseName}`,
            message: `This process may take several minutes.`,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseCreate ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const preventRedirect = data && data.preventRedirect !== undefined ? data.preventRedirect : false

        let requestsArr = []
        let requestsCount = 0
        let stateData = JSON.parse(JSON.stringify(state.data))

        stateData.ownerId = undefined
        stateData.duration = stateData.duration ? (stateData.duration.length === 5 ? stateData.duration + ':00' : stateData.duration) : null

        const stateDataNewFiles = stateData.files.filter(obj => obj.new)
        const stateDataUrls = stateData.urls

        const onResolve = (courseId) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Course create',
            message: `Course has been successfully created.`,
            status: 'success'
          })
          if (!preventRedirect) {
            router.push({ name: 'ebs-course-edit-details', params: { id: courseId } })
          }
        }
        const onCatch = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Course create',
            message: `Course hasn't been created.`,
            status: 'error'
          })
        }
        const handlePromise = (data) => {
          onResolve(data.id)
          resolve(data)
        }

        dispatch('ebsCourseCancelRequests')

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/courses`,
          data: stateData
        }).then(response => {
          const courseId = response.data.id
          if (stateDataNewFiles.length) {
            requestsArr.push({
              name: 'ebsCourseBulkAddFiles',
              data: {
                courseId: courseId,
                files: stateDataNewFiles.map(obj => {
                  return {
                    name: obj.name,
                    url: obj.url,
                    size: obj.size
                  }
                })
              }
            })
          }
          if (stateDataUrls.length) {
            requestsArr.push({
              name: 'ebsCourseAddUrls',
              data: {
                courseId: courseId,
                urls: stateDataUrls
              }
            })
          }
          if (requestsArr.length) {
            for (let request of requestsArr) {
              dispatch(request.name, request.data).finally(() => {
                requestsCount++
                if (requestsCount === requestsArr.length) {
                  handlePromise(response.data)
                }
              })
            }
          } else {
            handlePromise(response.data)
          }
        }).catch(error => {
          onCatch()
          reject(error)
        })
      })
    },
    ebsCourseExtendModules ({ commit, state, dispatch, getters }) {
      return new Promise((resolve) => {
        const courseIsSingleEventType = getters.getEbsCourseIsSingleEventType
        const stateData = JSON.parse(JSON.stringify(state.data))
        let countCompleteRequests = 0
        let modulesArr = []

        commit('EBS_COURSE_SET_CUSTOM_EXTENDMODULESPENDING', true)

        const performGetItem = (dispatchName, id) => {
          return dispatch(dispatchName, id)
        }
        const recursiveLoopCollectModules = (node, nodeIndex) => {
          const nodes = node.contentTree || node.nodes
          for (let i in nodes) {
            if (nodes[i].type === 'module') {
              modulesArr.push(nodes[i])
            }
            if (nodes[i] && nodes[i].nodes) {
              recursiveLoopCollectModules(nodes[i], nodeIndex ? [...nodeIndex, i] : i)
            }
          }
        }
        const recursiveLoopExtendModule = (node, nodeIndex, data) => {
          const nodes = node.contentTree || node.nodes
          for (let i in nodes) {
            if (nodes[i].nodeIds && nodes[i].nodeIds[0] === data.nodeIds[0]) {
              nodes[i].extendedData = data
              break
            }
            if (nodes[i] && nodes[i].nodes) {
              recursiveLoopExtendModule(nodes[i], nodeIndex ? [...nodeIndex, i] : i, data)
            }
          }
        }

        recursiveLoopCollectModules(stateData)

        if (modulesArr.length) {
          for (let module of modulesArr) {
            const moduleExternalId = module.externalId
            const moduleSubType = module.subType
            const moduleNodeIds = module.nodeIds
            let dispatchName

            switch (moduleSubType) {
              case 'dummy':
                dispatchName = 'getEventSimplified'
                break
              case 'event':
                dispatchName = 'getEventSimplified'
                break
              case 'scorm':
                dispatchName = 'ebsModuleGetSCORM'
                break
            }

            performGetItem(dispatchName, moduleExternalId).then(response => {
              let payloadModule = JSON.parse(JSON.stringify(response))
              payloadModule.nodeIds = moduleNodeIds
              recursiveLoopExtendModule(stateData, undefined, payloadModule)
            }).finally(() => {
              countCompleteRequests++
              if (countCompleteRequests === Number(modulesArr.length)) {
                setTimeout(() => {
                  commit('EBS_COURSE_SET', stateData)
                  commit('EBS_COURSE_SET_CUSTOM_EXTENDMODULESPENDING', false)
                  resolve()
                }, 1)
                if (courseIsSingleEventType) {
                  eventbus.$emit('ebs-course-get-single-event-type')
                }
              }
            })
          }
        } else {
          commit('EBS_COURSE_SET_CUSTOM_EXTENDMODULESPENDING', false)
          resolve()
        }
      })
    },
    ebsCourseExtendModule ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const moduleType = data.moduleType
        const moduleId = data.moduleId
        const stateData = state.data
        let dispatchName

        const performGetItem = (dispatchName, id) => {
          return dispatch(dispatchName, id)
        }
        const recursiveLoopExtendModule = (node, nodeIndex, data) => {
          const nodes = node.contentTree || node.nodes
          for (let i in nodes) {
            if (moduleType === 'scorm') {
              if (nodes[i].nodeIds && nodes[i].nodeIds[0] === data.nodeIds[0]) {
                nodes[i].extendedData = data
                break
              }
            } else if (moduleType === 'event') {
              if (nodes[i].externalId === data.id) {
                nodes[i].extendedData = data
                break
              }
            }
            if (nodes[i] && nodes[i].nodes) {
              recursiveLoopExtendModule(nodes[i], nodeIndex ? [...nodeIndex, i] : i, data)
            }
          }
        }

        switch (moduleType) {
          case 'dummy':
            dispatchName = 'getEventSimplified'
            break
          case 'event':
            dispatchName = 'getEventSimplified'
            break
          case 'scorm':
            dispatchName = 'ebsModuleGetSCORM'
            break
        }

        performGetItem(dispatchName, moduleId).then(response => {
          let payloadModule = JSON.parse(JSON.stringify(response))
          recursiveLoopExtendModule(stateData, undefined, payloadModule)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseHandleRelease ({ dispatch, state, rootState }) {
      return new Promise((resolve, reject) => {
        const stateData = JSON.parse(JSON.stringify(state.data))
        const courseId = stateData.id || rootState.route.params.id
        const catalogItemId = stateData.catalogItemId
        const hasVersions = stateData.versions.length
        const releaseNotes = 'Content updated'
        const courseExternalModulesData = stateData.externalModulesData

        const ebsCourseReleaseMinor = () => {
          return dispatch('ebsCourseReleaseMinor', { courseId, releaseNotes })
        }
        const ebsCourseReleaseMajor = () => {
          return dispatch('ebsCourseReleaseMajor', { courseId, releaseNotes, instantOffer: !catalogItemId })
        }
        const ebsCourseGetWithoutModules = () => {
          return dispatch('ebsCourseGetWithoutModules', courseId)
        }

        if (hasVersions && catalogItemId) {
          ebsCourseReleaseMinor().then(() => {
            resolve()
          }).catch(error => {
            reject(error.response.data)
          })
        } else {
          if (courseExternalModulesData && Object.values(courseExternalModulesData).length && Object.values(courseExternalModulesData).find(obj => obj.type === 'scorm' || obj.type === 'event')) {
            ebsCourseReleaseMajor().then(() => {
              ebsCourseGetWithoutModules().finally(() => {
                resolve()
              })
            }).catch(error => {
              reject(error.response.data)
            })
          } else {
            ebsCourseGetWithoutModules().finally(() => {
              resolve()
            })
          }
        }
      })
    },
    ebsCourseReleaseMajor ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const courseId = data && data.courseId ? data.courseId : getters.getEbsCourseId
        const releaseNotes = data && data.releaseNotes ? data.releaseNotes : getters.getEbsCourseReleaseNotes
        const instantOffer = data && data.instantOffer ? data.instantOffer : getters.getEbsCourseInstantOffer

        commit('EBS_COURSE_SET_CUSTOM_DURINGGETCOURSE', true)

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/courses/${courseId}/release/major`,
          data: {
            releaseNotes,
            instantOffer
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
          commit('EBS_COURSE_SET_CUSTOM_DURINGGETCOURSE', false)
        })
      })
    },
    ebsCourseReleaseMinor ({ commit, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const courseId = data && data.courseId ? data.courseId : getters.getEbsCourseId
        const releaseNotes = data && data.releaseNotes ? data.releaseNotes : getters.getEbsCourseReleaseNotes

        commit('EBS_COURSE_SET_CUSTOM_DURINGGETCOURSE', true)

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/courses/${courseId}/release/minor`,
          data: {
            releaseNotes
          }
        }).then(response => {
          dispatch('ebsCourseGetWithoutModules', courseId).then(() => {
            resolve(response.data)
          })
        }).catch(error => {
          reject(error)
          commit('EBS_COURSE_SET_CUSTOM_DURINGGETCOURSE', false)
        })
      })
    },
    ebsCourseUpdate ({ state, dispatch, rootState }, data) {
      return new Promise((resolve) => {
        const courseId = data && data.courseId ? data.courseId : rootState.route.params.id
        const catalogId = data && data.catalogId ? data.catalogId : state.data.catalogItemId
        const workspaceId = data && data.workspaceId ? data.workspaceId : state.data.owner
        const stateData = JSON.parse(JSON.stringify(state.data))
        const stateDefaultData = JSON.parse(JSON.stringify(state.default))
        const hasVersions = stateData.versions.length
        const stateDataNewFiles = stateData.files.filter(obj => obj.new)
        const stateDataUrls = stateData.urls
        const stateDefaultDataUrls = stateDefaultData.urls
        const releaseNotes = 'Content updated'
        const courseExternalModulesData = stateData.externalModulesData

        stateData.duration = stateData.duration ? (stateData.duration.length === 5 ? stateData.duration + ':00' : stateData.duration) : null

        const ebsCourseReleaseMinor = () => {
          return dispatch('ebsCourseReleaseMinor', { courseId, releaseNotes })
        }

        const ebsCourseReleaseMajor = () => {
          return dispatch('ebsCourseReleaseMajor', { courseId, releaseNotes })
        }

        const ebsCourseGetWithoutModules = () => {
          return dispatch('ebsCourseGetWithoutModules', courseId)
        }

        const onResolve = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Course updated',
            message: ``,
            status: 'success',
            id: 'course-update'
          })
        }
        const handleRelease = () => {
          if (hasVersions) {
            ebsCourseReleaseMinor().then(() => {
              onResolve()
              resolve()
            })
          } else {
            if (courseExternalModulesData && Object.values(courseExternalModulesData).length && Object.values(courseExternalModulesData).find(obj => obj.type === 'scorm' || obj.type === 'event')) {
              ebsCourseReleaseMajor().then(() => {
                ebsCourseGetWithoutModules().finally(() => {
                  onResolve()
                  resolve()
                })
              })
            } else {
              ebsCourseGetWithoutModules().finally(() => {
                onResolve()
                resolve()
              })
            }
          }
        }

        dispatch('ebsCourseCancelRequests')

        let actionsArr = []
        let actionsCounter = 0

        if (stateData.file !== stateDefaultData.file) {
          actionsArr.push({ name: 'ebsCourseUpdateFile', data: courseId })
        }
        if (stateDataNewFiles.length) {
          actionsArr.push({
            name: 'ebsCourseBulkAddFiles',
            data: {
              courseId: courseId,
              files: stateDataNewFiles.map(obj => {
                return {
                  name: obj.name,
                  url: obj.url,
                  size: obj.size
                }
              })
            }
          })
        }
        if (JSON.stringify(stateDataUrls) !== JSON.stringify(stateDefaultDataUrls)) {
          actionsArr.push({
            name: 'ebsCourseAddUrls',
            data: {
              courseId,
              urls: stateDataUrls
            }
          })
          if (catalogId) {
            actionsArr.push({
              name: 'ebsCatalogItemAddUrls',
              data: {
                catalogId,
                workspaceId,
                urls: stateDataUrls
              }
            })
          }
        }

        dispatch('ebsCourseUpdateDetails', courseId).finally(() => {
          if (actionsArr.length) {
            for (let action of actionsArr) {
              dispatch(action.name, action.data).finally(() => {
                actionsCounter++
                if (actionsCounter === actionsArr.length) {
                  handleRelease()
                }
              })
            }
          } else {
            handleRelease()
          }
        })
      })
    },
    ebsCourseUpdateDetails ({ state }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'patch',
          url: `${api.courseCatalog}/v2/courses/${data}/updateDetails`,
          data: {
            name: state.data.name,
            description: state.data.description,
            duration: state.data.duration ? (state.data.duration.length === 5 ? state.data.duration + ':00' : state.data.duration) : null,
            labels: state.data.labels
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseSetImage ({ commit }, data) {
      commit('EBS_COURSE_SET_IMAGE', data[0])
    },
    ebsCourseGeneratePresignedPost (ctx, data) {
      return new Promise((resolve, reject) => {
        const mimeType = 'application/pdf'
        const name = data ? data.name : undefined
        const size = data ? data.size : undefined

        axios({
          method: 'post',
          url: `${api.courseCatalog}/course-files/generatePresignedPost`,
          data: {
            mimeType,
            name,
            size
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseCancelRequests () {
      if (cancelebsCourseUploadFileFile) cancelebsCourseUploadFileFile()
      if (cancelEbsCourseGetAttachmentFile) cancelEbsCourseGetAttachmentFile()
    },
    ebsCourseUploadFile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const url = data ? data.action : undefined
        const enctype = data ? data.enctype : undefined
        const method = data ? data.method : undefined
        const form = data ? data.form : undefined
        const fileUrl = data.fileUrl

        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelebsCourseUploadFileFile = ex
          }),
          method: method,
          url: url,
          headers: {
            'Content-Type': enctype
          },
          data: form,
          onUploadProgress (progressEvent) {
            commit('EBS_COURSE_SET_FILE_PROGRESS', { url: fileUrl, size: form.get('file').size, loaded: progressEvent.loaded, total: progressEvent.total })
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseBulkAddFiles ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const courseId = data ? data.courseId : ''
        const files = data ? data.files : []

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/courses/${courseId}/bulkAddFiles`,
          data: {
            files
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
          dispatch('defaultNotificationToastsAdd', {
            title: 'Add file',
            message: `${name} file hasn't been added to the course.`,
            status: 'error'
          })
        })
      })
    },
    ebsCourseAddUrls ({ state }, data) {
      return new Promise((resolve, reject) => {
        const courseId = data ? data.courseId : state.data.id
        const urls = data ? data.urls : state.data.urls

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/courses/${courseId}/set-urls`,
          data: {
            urls
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseRemoveFile ({ commit, state }, url) {
      return new Promise((resolve, reject) => {
        const courseId = state.data.id

        if (courseId) {
          axios({
            method: 'post',
            url: `${api.courseCatalog}/v2/courses/${courseId}/removeFile`,
            data: {
              url
            }
          }).then(response => {
            commit('EBS_COURSE_REMOVE_FILE', url)
            resolve(response.data)
          }).catch(error => {
            reject(error)
          })
        } else {
          commit('EBS_COURSE_REMOVE_FILE', url)
        }
      })
    },
    ebsCourseUpdateCertificate ({ state }, data) {
      return new Promise((resolve, reject) => {
        const courseId = data ? (data.courseId !== undefined ? data.courseId : state.data.id) : state.data.id
        const contentId = data ? (data.contentId !== undefined ? data.contentId : state.data.contentId) : state.data.contentId

        axios({
          method: 'patch',
          url: `${api.courseCatalog}/v2/courses/${courseId}/updateCertificate`,
          data: {
            contentId
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseUpdateFile ({ state }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'patch',
          url: `${api.courseCatalog}/v2/courses/${data}/updateFile`,
          data: {
            file: state.data.file
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseSetFileProp ({ commit }, data) {
      commit('EBS_COURSE_SET_FILE_PROP', data)
    },
    ebsCourseUpdateNoteDetails ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const courseId = state.data.id
        const sequence = state.data.sequence
        const moduleId = data.moduleId
        const description = data.description
        axios({
          method: 'patch',
          url: `${api.courseCatalog}/v2/courses/${courseId}/note/${moduleId}/update`,
          data: {
            description,
            sequence
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Note update',
            message: `Note has been successfully updated.`,
            status: 'success'
          })
          dispatch('ebsCourseSet', response.data)
          resolve(response.data)
        }).catch(error => {
          dispatch('ebsCourseGet', { courseId }).finally(() => {
            reject(error)
          })
        })
      })
    },
    ebsCourseUpdateGroupDetails ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const courseId = state.data.id
        const sequence = state.data.sequence
        const moduleId = data.moduleId
        const name = data.name
        axios({
          method: 'patch',
          url: `${api.courseCatalog}/v2/courses/${courseId}/group/${moduleId}/update`,
          data: {
            name,
            sequence
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Module update',
            message: `Module has been successfully updated.`,
            status: 'success'
          })
          dispatch('ebsCourseSet', response.data)
          resolve(response.data)
        }).catch(error => {
          dispatch('ebsCourseGet', { courseId }).finally(() => {
            reject(error)
          })
        })
      })
    },
    ebsCourseUpdateModuleDetails ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const courseId = state.data.id
        const sequence = state.data.sequence
        const moduleId = data.moduleId
        const name = data.name
        const required = data.required
        axios({
          method: 'patch',
          url: `${api.courseCatalog}/v2/courses/${courseId}/module/${moduleId}/update`,
          data: {
            name,
            sequence,
            required
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Module update',
            message: `Module has been successfully updated.`,
            status: 'success'
          })
          dispatch('ebsCourseSet', response.data)
          resolve(response.data)
        }).catch(error => {
          dispatch('ebsCourseGet', { courseId }).finally(() => {
            reject(error)
          })
        })
      })
    },
    ebsCourseUpdateModuleRequired ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const courseId = state.data.id
        const moduleId = data.moduleId
        const required = data.required
        axios({
          method: 'patch',
          url: `${api.courseCatalog}/v2/courses/${courseId}/module/${moduleId}/updateRequired`,
          data: {
            required
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Module update',
            message: `Module has been successfully updated.`,
            status: 'success'
          })
          dispatch('ebsCourseSet', response.data)
          resolve(response.data)
        }).catch(error => {
          dispatch('ebsCourseGet', { courseId }).finally(() => {
            reject(error)
          })
        })
      })
    },
    ebsCourseGeneratePresignedUrl (ctx, data) {
      return new Promise((resolve, reject) => {
        const name = data ? data.name : ''
        const type = data ? data.type : ''
        const description = data ? data.description : ''

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/course-files/generatePresignedPost`,
          data: {
            name,
            type,
            description
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseRemoveModule ({ commit, state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const courseId = state.data.id
        const sequence = state.data.sequence
        const owner = state.data.owner
        const newModule = data.newModule
        const moduleId = data.moduleId
        const index = data.index

        const onResolve = (data) => {
          resolve(data)
        }

        if (newModule) {
          commit('EBS_COURSE_REMOVE_MODULE', index)
          resolve('removedNewModule')
        } else {
          axios({
            method: 'delete',
            url: `${api.courseCatalog}/v2/courses/${courseId}/remove/${moduleId}`,
            data: {
              owner,
              sequence
            }
          }).then(response => {
            const responseData = response.data
            const courseHasBeenReleased = responseData.versions.length

            dispatch('ebsCourseSet', responseData)

            if (courseHasBeenReleased) {
              dispatch('ebsCourseReleaseMinor', { courseId: courseId, releaseNotes: 'Content removed' }).finally(() => {
                onResolve(response.data)
              })
            } else {
              onResolve(response.data)
            }
          }).catch(error => {
            reject(error)
          })
        }
      })
    },
    ebsCoursePerformInsertModule ({ dispatch, getters }, data) {
      return new Promise((resolve, reject) => {
        const index = data && data.index ? data.index : []
        const name = data && data.name ? data.name : ''
        const description = data && data.description ? data.description : ''
        const owner = data && data.owner ? data.owner : null
        const type = data && data.type ? data.type : ''
        const id = data && data.id ? data.id : ''
        const courseId = data && data.courseId ? data.courseId : ''
        const required = data && data.required ? data.required : false
        const sequence = getters.getEbsCourseSequence
        const lastModuleId = getters.getEbsCourseLastModuleId
        const ebsCourseModuleGetPreviousIdByIndex = (index) => {
          return getters.getEbsCourseModulePreviousIdByIndex(index)
        }
        const getEbsCourseModuleInGroupByIndex = (index) => {
          return getters.getEbsCourseModuleInGroupByIndex(index)
        }
        const ebsModuleCreate = (data) => {
          return dispatch('ebsModuleCreate', data)
        }
        const ebsModuleRelease = (data) => {
          return dispatch('ebsModuleRelease', data)
        }
        const ebsCourseInsertModule = (data) => {
          return dispatch('ebsCourseInsertModule', data)
        }
        const previousId = index.length ? ebsCourseModuleGetPreviousIdByIndex(index) : lastModuleId
        const inGroup = index ? getEbsCourseModuleInGroupByIndex(index) : null

        ebsModuleCreate({ name, description, owner, type, id }).then(response => {
          const moduleId = response.id
          const wasAlreadyExisting = response.wasAlreadyExisting

          if (wasAlreadyExisting) {
            const versionId = response.versionId
            ebsCourseInsertModule({ name, description, moduleId, versionId, required, inGroup, previousId, courseId, sequence }).then(() => {
              resolve()
            })
          } else {
            ebsModuleRelease(moduleId).then(response => {
              const versionId = response.versionId
              ebsCourseInsertModule({ name, description, moduleId, versionId, required, inGroup, courseId, previousId, sequence }).then(() => {
                resolve()
              })
            })
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseSet ({ commit }, data) {
      commit('EBS_COURSE_SET', data)
    },
    ebsCourseInsertModule ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const courseId = data && data.courseId ? data.courseId : state.data.id
        const name = data ? data.name : ''
        const description = data ? data.description : ''
        const moduleId = data ? data.moduleId : ''
        const versionId = data ? data.versionId : ''
        const required = data ? data.required : ''
        const inGroup = data ? data.inGroup : ''
        const previousId = data ? data.previousId : ''
        const sequence = data ? data.sequence : ''

        const onResolve = (data) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Module add',
            message: `Module successfully added to the course.`,
            status: 'success'
          })
          resolve(data)
        }

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/courses/${courseId}/module`,
          data: {
            name,
            description,
            moduleId,
            versionId,
            required,
            inGroup,
            previousId,
            sequence
          }
        }).then(response => {
          const responseData = response.data
          const courseHasBeenReleased = responseData.versions.length

          dispatch('ebsCourseSet', responseData)

          if (courseHasBeenReleased) {
            dispatch('ebsCourseReleaseMinor', { courseId: courseId, releaseNotes: 'Content added' }).finally(() => {
              onResolve(response.data)
            })
          } else {
            onResolve(response.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseInsertNote ({ state, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const index = data ? data.index : []
        const courseId = state.data.id
        const description = data ? data.description : ''
        const sequence = state.data.sequence

        const ebsCourseModuleGetPreviousIdByIndex = (value) => {
          return getters.getEbsCourseModulePreviousIdByIndex(value)
        }
        const getEbsCourseModuleInGroupByIndex = (value) => {
          return getters.getEbsCourseModuleInGroupByIndex(value)
        }
        const onResolve = (data) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Note add',
            message: `Note successfully added to the course.`,
            status: 'success'
          })
          resolve(data)
        }

        const previousId = ebsCourseModuleGetPreviousIdByIndex(index)
        const inGroup = getEbsCourseModuleInGroupByIndex(index)

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/courses/${courseId}/note`,
          data: {
            description,
            inGroup,
            previousId,
            sequence
          }
        }).then(response => {
          const responseData = response.data
          const courseHasBeenReleased = responseData.versions.length

          dispatch('ebsCourseSet', responseData)

          if (courseHasBeenReleased) {
            dispatch('ebsCourseReleaseMinor', { courseId: courseId, releaseNotes: 'Content added' }).finally(() => {
              onResolve(response.data)
            })
          } else {
            onResolve(response.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseInsertGroup ({ state, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const index = data ? data.index : []
        const courseId = state.data.id
        const name = data ? data.name : ''
        const sequence = state.data.sequence

        const ebsCourseModuleGetPreviousIdByIndex = (value) => {
          return getters.getEbsCourseModulePreviousIdByIndex(value)
        }
        const getEbsCourseModuleInGroupByIndex = (value) => {
          return getters.getEbsCourseModuleInGroupByIndex(value)
        }
        const onResolve = (data) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Section add',
            message: `Section successfully added to the course.`,
            status: 'success'
          })
          resolve(data)
        }

        const previousId = ebsCourseModuleGetPreviousIdByIndex(index)
        const inGroup = getEbsCourseModuleInGroupByIndex(index)

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/courses/${courseId}/group`,
          data: {
            name,
            inGroup,
            previousId,
            sequence
          }
        }).then(response => {
          const responseData = response.data
          const courseHasBeenReleased = responseData.versions.length

          dispatch('ebsCourseSet', responseData)

          if (courseHasBeenReleased) {
            dispatch('ebsCourseReleaseMinor', { courseId: courseId, releaseNotes: 'Content added' }).finally(() => {
              onResolve(response.data)
            })
          } else {
            onResolve(response.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseClear ({ commit }) {
      commit('EBS_COURSE_CLEAR')
    },
    ebsCourseReset ({ commit }) {
      commit('EBS_COURSE_RESET')
    },
    ebsCourseSetName ({ commit }, data) {
      commit('EBS_COURSE_SET_NAME', data)
    },
    ebsCourseSetDescription ({ commit }, data) {
      commit('EBS_COURSE_SET_DESCRIPTION', data)
    },
    ebsCourseAddUrl ({ commit }, data) {
      commit('EBS_COURSE_ADD_URL', data)
    },
    ebsCourseUpdateUrl ({ commit }, data) {
      commit('EBS_COURSE_UPDATE_URL', data)
    },
    ebsCourseRemoveUrl ({ commit }, data) {
      commit('EBS_COURSE_REMOVE_URL', data)
    },
    ebsCourseSetFile ({ commit }, data) {
      commit('EBS_COURSE_ADD_FILE', data)
    },
    ebsCourseSetFiles ({ commit }, data) {
      commit('EBS_COURSE_SET_FILES', data)
    },
    ebsCourseSetDuration ({ commit }, data) {
      commit('EBS_COURSE_SET_DURATION', data)
    },
    ebsCourseRemoveImage ({ commit }, data) {
      commit('EBS_COURSE_REMOVE_IMAGE', data)
    },
    ebsCourseAddModule ({ commit }, data) {
      commit('EBS_COURSE_ADD_MODULE', data)
    },
    ebsCourseRemoveEventModule (ctx, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.classroom}/event/${data}/archive`
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseMoveModule ({ state, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const stateData = state.data
        const courseId = state.data.id
        const sequence = state.data.sequence
        const position = data ? data.position : ''
        const fromIndex = data ? data.from : []
        let toIndex = data ? data.to : []
        const toIndexLast = position === 'after' ? toIndex.pop() + 1 : toIndex.pop()
        let fromModuleIndex = JSON.parse(JSON.stringify(fromIndex))
        const fromModuleIndexLast = fromModuleIndex.pop()
        let fromModuleIndexArr = ['contentTree']
        const ebsCourseModuleGetPreviousIdByIndex = (value) => {
          return getters.getEbsCourseModulePreviousIdByIndex(value)
        }
        const getEbsCourseModuleInGroupByIndex = (value) => {
          return getters.getEbsCourseModuleInGroupByIndex(value)
        }
        toIndex.push(toIndexLast)

        for (let i in fromModuleIndex) {
          fromModuleIndexArr.push(fromModuleIndex[i])
          fromModuleIndexArr.push('nodes')
        }
        const fromModule = getNestedByArray(stateData, fromModuleIndexArr)[fromModuleIndexLast]

        const nodeId = fromModule.id
        const previousId = ebsCourseModuleGetPreviousIdByIndex(position === 'zero' ? [] : toIndex)
        const inGroup = getEbsCourseModuleInGroupByIndex(position === 'zero' ? [...toIndex, 0] : toIndex)

        if (nodeId !== previousId) {
          axios({
            method: 'post',
            url: `${api.courseCatalog}/v2/courses/${courseId}/reorder`,
            data: {
              nodeId,
              inGroup,
              previousId,
              sequence
            }
          }).then(response => {
            const responseData = response.data
            const courseHasBeenReleased = responseData.versions.length

            dispatch('ebsCourseSet', responseData)

            if (courseHasBeenReleased) {
              dispatch('ebsCourseReleaseMinor', { courseId: courseId, releaseNotes: 'Content added' }).finally(() => {
                resolve(response.data)
              })
            } else {
              resolve(response.data)
            }
          }).catch(error => {
            reject(error)
          })
        } else {
          reject(new Error('moduleNotMoved'))
        }
      })
    },
    ebsCourseSetModuleValue ({ commit }, data) {
      commit('EBS_COURSE_SET_MODULE_VALUE', data)
    },
    ebsCourseSetReleaseNotes ({ commit }, data) {
      commit('EBS_COURSE_SET_RELEASE_NOTES', data)
    },
    ebsCourseSetInstantOffer ({ commit }, data) {
      commit('EBS_COURSE_SET_INSTANT_OFFER', data)
    },
    ebsCourseSetCertificateActive ({ commit }, data) {
      commit('EBS_COURSE_SET_CERTIFICATE_ACTIVE', data)
    },
    ebsCourseSetEnrolmentType ({ commit }, data) {
      commit('EBS_COURSE_SET_ENROLMENT_TYPE', data)
    },
    ebsCourseGetLabels ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        commit('EBS_COURSE_CLEAR_CUSTOM_LABELS')

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/course/labels${queryUrlParams}`
        }).then(response => {
          commit('EBS_COURSE_SET_CUSTOM_LABELS_DATA', response.data.data)
          commit('EBS_COURSE_SET_CUSTOM_LABELS_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCourseSetLabels ({ commit }, data) {
      commit('EBS_COURSE_SET_LABELS', data)
    },
    ebsCourseAddLabel ({ commit }, data) {
      commit('EBS_COURSE_ADD_LABEL', data)
    }
  }
}

export default ebsCourses
