import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { fromLocalTimeToUTC, fromUTCTimeToLocal } from '@/functions'

const data = {
  data: {
    id: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    workingHours: [
      {
        weekday: 0,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      },
      {
        weekday: 1,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      },
      {
        weekday: 2,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      },
      {
        weekday: 3,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      },
      {
        weekday: 4,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      },
      {
        weekday: 5,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      },
      {
        weekday: 6,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      }
    ],
    trainerAlreadyExists: false
  },
  default: {}
}

const ebsTrainer = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getTrainer: state => {
      return state.data
    },
    //
    getTrainerId: state => {
      return state.data.id
    },
    getTrainerFirstName: state => {
      return state.data.firstName
    },
    getTrainerLastName: state => {
      return state.data.lastName
    },
    getTrainerEmail: state => {
      return state.data.emailAddress
    },
    getTrainerProfileStatus: state => {
      return state.data.profileStatus
    },
    getTrainerAlreadyExists: state => {
      return state.data.trainerAlreadyExists
    },
    getTrainerWorkingHours: state => {
      return state.data.workingHours
    }
  },
  mutations: {
    GET_TRAINER (state, payload) {
      for (let i = 0; i < payload.workingHours.length; i++) {
        const timeStart = fromUTCTimeToLocal(payload.workingHours[i].workFrom)
        const timeEnd = fromUTCTimeToLocal(payload.workingHours[i].workTo)
        payload.workingHours[i].workFromLocal = timeStart || undefined
        payload.workingHours[i].workToLocal = timeEnd || undefined
      }

      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    //
    CLEAR_TRAINER (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    RESET_TRAINER (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    SET_TRAINER_ID (state, payload) {
      state.data.id = payload
    },
    SET_TRAINER_FIRST_NAME (state, payload) {
      state.data.firstName = payload
    },
    SET_TRAINER_LAST_NAME (state, payload) {
      state.data.lastName = payload
    },
    SET_TRAINER_EMAIL (state, payload) {
      state.data.emailAddress = payload
    },
    SET_TRAINER_WORKINGHOURS (state, payload) {
      if (state.data.workingHours[payload].workFrom) {
        state.data.workingHours[payload].workFrom = ''
        state.data.workingHours[payload].workFromLocal = ''
      } else {
        state.data.workingHours[payload].workFrom = fromLocalTimeToUTC('09:00')
        state.data.workingHours[payload].workFromLocal = '09:00'
      }
      if (state.data.workingHours[payload].workTo) {
        state.data.workingHours[payload].workTo = ''
        state.data.workingHours[payload].workToLocal = ''
      } else {
        state.data.workingHours[payload].workTo = fromLocalTimeToUTC('17:00')
        state.data.workingHours[payload].workToLocal = '17:00'
      }
    },
    SET_TRAINER_WORKINGHOURS_WORKFROMLOCAL (state, payload) {
      state.data.workingHours[payload.key].workFromLocal = payload.value
    },
    SET_TRAINER_WORKINGHOURS_WORKTOLOCAL (state, payload) {
      state.data.workingHours[payload.key].workToLocal = payload.value
    },
    SET_TRAINER_WORKINGHOURS_WORKFROM (state, payload) {
      const time = state.data.workingHours[payload.key].workFromLocal
      if (!(time)) return
      state.data.workingHours[payload.key].workFrom = fromLocalTimeToUTC(time)
    },
    SET_TRAINER_WORKINGHOURS_WORKTO (state, payload) {
      const time = state.data.workingHours[payload.key].workToLocal
      if (!(time)) return
      state.data.workingHours[payload.key].workTo = fromLocalTimeToUTC(time)
    },
    SET_TRAINER_ALREADYEXISTS (state, payload) {
      state.data.trainerAlreadyExists = payload
    }
  },
  actions: {
    getTrainer ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_TRAINER')
        axios({
          method: 'get',
          url: `${api.classroom}/trainer-user/${id}`
        }).then(response => {
          commit('GET_TRAINER', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    //
    clearTrainer ({ commit }) {
      commit('CLEAR_TRAINER')
    },
    resetTrainer ({ commit }) {
      commit('RESET_TRAINER')
    },
    deleteTrainer (ctx, data) {
      return new Promise((resolve, reject) => {
        const userId = data

        axios({
          method: 'delete',
          url: `${api.sso}/v2/trainer/${userId}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    restoreTrainer (ctx, data) {
      return new Promise((resolve, reject) => {
        const userId = data

        axios({
          method: 'post',
          url: `${api.sso}/v2/trainer/${userId}/restore`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateTrainer ({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('updateTrainerSettings').then(() => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Trainer update',
            message: `Trainer has been successfully updated.`,
            status: 'success'
          })
          router.push({ name: 'ebs-trainer-list' })
          resolve()
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Trainer update',
            message: `Trainer hasn't been updated.`,
            status: 'error'
          })
          reject(new Error(error))
        })
      })
    },
    updateTrainerSettings ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const stateData = JSON.parse(JSON.stringify(state.data))
        const stateDefaultData = JSON.parse(JSON.stringify(state.default))

        const userId = data && data.userId ? data.userId : stateData.userId
        const customerId = data && data.customerId ? data.customerId : stateData.customerId
        const workingHours = stateData.workingHours

        const lmsUserAccountUpdateDetails = () => {
          const userData = {
            id: userId,
            firstName: stateData.firstName,
            lastName: stateData.lastName,
            emailAddress: stateData.emailAddress
          }
          return dispatch('lmsUserAccountUpdateDetails', userData)
        }

        const lmsUserAccountUpdateEmail = () => {
          const userData = {
            id: userId,
            emailAddress: stateData.emailAddress
          }
          return dispatch('lmsUserAccountUpdateEmail', userData)
        }

        axios({
          method: 'patch',
          url: `${api.classroom}/trainers`,
          data: {
            userId,
            customerId,
            workingHours
          }
        }).then(response => {
          let execFunctionsArr = []

          if (
            stateData.firstName !== stateDefaultData.firstName ||
          stateData.lastName !== stateDefaultData.lastName
          ) {
            execFunctionsArr.push(lmsUserAccountUpdateDetails)
          }

          if (stateData.emailAddress !== stateDefaultData.emailAddress) {
            execFunctionsArr.push(lmsUserAccountUpdateEmail)
          }

          if (execFunctionsArr.length) {
            for (let index in execFunctionsArr) {
              execFunctionsArr[index]().then(response => {
                if (Number(index) === execFunctionsArr.length - 1) {
                  resolve(response)
                }
              }).catch(error => {
                if (Number(index) === execFunctionsArr.length - 1) {
                  reject(error)
                }
              })
            }
          } else {
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    createTrainer ({ state, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        let bodyData = {}

        const customerId = getters.getAuthUserSecondStageTokenDataCustomerId

        bodyData.firstName = stateData.firstName
        bodyData.lastName = stateData.lastName
        bodyData.emailAddress = stateData.emailAddress
        bodyData.customerId = customerId

        axios({
          method: 'post',
          url: `${api.sso}/v2/trainer`,
          data: bodyData
        }).then(response => {
          const userId = response.data

          dispatch('updateTrainerSettings', { userId, customerId }).finally(() => {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Trainer create',
              message: `Trainer has been successfully created.`,
              status: 'success'
            })
            router.push({ name: 'ebs-trainer-list' })
          })
          resolve()
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Trainer create',
            message: `Trainer hasn't been created.`,
            status: 'error'
          })
          reject(new Error(error))
        })
      })
    },
    trainerCheckEmail ({ commit, dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        const authUserCheckExistsSSO = dispatch('authUserCheckExistsSSO', data)
        const alreadyFoundAUser = state.data.id

        authUserCheckExistsSSO.then(response => {
          if (response === null) reject(new Error('null'))

          const firstName = response.firstName || ''
          const lastName = response.lastName || ''
          const id = response.id || ''
          const customerRoles = response.customerRoles

          if (customerRoles && customerRoles.length && customerRoles.includes('trainer')) {
            commit('SET_TRAINER_ALREADYEXISTS', true)
          } else {
            commit('SET_TRAINER_ALREADYEXISTS', false)
          }

          commit('SET_TRAINER_FIRST_NAME', firstName)
          commit('SET_TRAINER_LAST_NAME', lastName)
          commit('SET_TRAINER_ID', id)
          resolve(response)
        }).catch(error => {
          if (alreadyFoundAUser) {
            commit('SET_TRAINER_FIRST_NAME', '')
            commit('SET_TRAINER_LAST_NAME', '')
          }
          commit('SET_TRAINER_ID', '')
          commit('SET_TRAINER_ALREADYEXISTS', false)
          reject(error)
        })
      })
    },
    //
    setTrainerName ({ commit }, value) {
      commit('SET_TRAINER_NAME', value)
    },
    setTrainerFirstName ({ commit }, value) {
      commit('SET_TRAINER_FIRST_NAME', value)
    },
    setTrainerLastName ({ commit }, value) {
      commit('SET_TRAINER_LAST_NAME', value)
    },
    setTrainerEmail ({ commit }, value) {
      commit('SET_TRAINER_EMAIL', value)
    },
    setTrainerWorkingHours ({ commit }, value) {
      commit('SET_TRAINER_WORKINGHOURS', value)
    },
    setTrainerWorkingHoursWorkFrom ({ commit }, data) {
      commit('SET_TRAINER_WORKINGHOURS_WORKFROMLOCAL', { key: data.key, value: data.value })
      commit('SET_TRAINER_WORKINGHOURS_WORKFROM', { key: data.key, value: data.value })
    },
    setTrainerWorkingHoursWorkTo ({ commit }, data) {
      commit('SET_TRAINER_WORKINGHOURS_WORKTOLOCAL', { key: data.key, value: data.value })
      commit('SET_TRAINER_WORKINGHOURS_WORKTO', { key: data.key, value: data.value })
    }
  }
}

export default ebsTrainer
