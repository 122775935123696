import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const ebsVenues = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getVenues: state => {
      return state.data
    },
    getVenuesMappedLabelValue: state => {
      const rawVenues = state.data
      if (!rawVenues.length) return []
      return rawVenues.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.name,
          value: item.id
        }
      })
    },
    getVenuesMappedIdNameStatus: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          status: item.status
        }
      })
    },
    getVenuesPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    SET_VENUES (state, payload) {
      for (let i in payload) {
        payload[i].roomsCount = payload[i].rooms.length
      }
      state.data = payload
    },
    SET_VENUES_PAGINATION (state, payload) {
      state.pagination = payload
    },
    CLEAR_VENUES (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    CLEAR_VENUES_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    getVenues ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        commit('CLEAR_VENUES')
        commit('CLEAR_VENUES_PAGINATION')

        axios
          .get(`${api.classroom}/venues${queryUrlParams}`)
          .then(function (response) {
            commit('SET_VENUES', response.data.data)
            commit('SET_VENUES_PAGINATION', response.data.pagination)
            resolve(response)
          }).catch(error => {
            reject(error)
          })
      })
    }
  }
}

export default ebsVenues
