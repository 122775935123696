import axios from 'axios'
import api from '@/api'

const data = {
  data: ''
}

const ebsSessionFeedbacks = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getSessionFeedbacks: state => {
      return state.data
    },
    getSessionFeedbacksHasFilledUp: state => {
      const feedbacks = state.data
      let feedbacksFilledUp = true

      for (const objFeedback of Object.values(feedbacks)) {
        for (const objOccurrence of Object.values(objFeedback.occurrenceItems)) {
          if (objOccurrence.feedback === null) {
            feedbacksFilledUp = false
          }
        }
      }

      return feedbacksFilledUp
    }
  },
  mutations: {
    GET_SESSIONFEEDBACKS: (state, payload) => {
      state.data = payload
    },
    CLEAR_SESSIONFEEDBACKS (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    }
  },
  actions: {
    getSessionFeedbacks ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.classroom}/feedback/trainer/${id}`
        }).then(response => {
          commit('GET_SESSIONFEEDBACKS', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ebsSessionFeedbacks
