<template>
    <ul class="header__top_account_menu_box_nav">
        <li
            v-for="(item, index) in navItems"
            :key="index"
        >
            <component-button
                :class="['header__top_account_menu_box_nav_item', `header__top_account_menu_box_nav_item--${toKebabCase(item.name)}`]"
                :to="item.routeName ? { name: item.routeName } : ''"
                :text="true"
                :icon="Boolean(item.icon)"
                :icon-name="item.icon"
                :icon-align="'right'"
                :icon-size="'small'"
                :theme="''"
                :size="''"
                :align="'left'"
                :name="'header-top_account-menu-box-nav-button'"
                v-on="item.method ? { 'click' : item.method } : ''"
                :animate-loading="item.loading ? true : false"
                :animate-loading-event-name="'reset-onboarding'"
            >{{ item.name }}
            </component-button>
        </li>
    </ul>
</template>

<script>
import ComponentButton from '@/components/default/shared/ComponentButton'

export default {
  name: 'header-top-account-menu-box-nav',
  components: { ComponentButton },
  props: {
    navItems: {
      type: Array
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/layout/header/headertopaccountmenuboxnav";
</style>
