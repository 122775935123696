import axios from 'axios'
import api from '@/api'

const data = {
  data: [],
  default: ''
}

const lmsUserAutoenrolmentsSuggestedCourses = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsUserAutoenrolmentsSuggestedCourses: state => {
      return state.data
    }
  },
  mutations: {
    LMS_USER_AUTOENROLMENTS_SUGGESTEDCOURSES_SET (state, payload) {
      state.data = payload
    },
    LMS_USER_AUTOENROLMENTS_SUGGESTEDCOURSES_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    }
  },
  actions: {
    lmsUserAutoenrolmentsSuggestedCoursesGet ({ commit, getters }, data) {
      const targets = data && data.targets ? '&targets=' + data.targets.join(',') : ''
      const workspace = data && data.workspace ? '&workspace=' + data.workspace : '&workspace=' + getters.getAuthUserSecondStageTokenDataWorkspaceId
      let query = '?' + targets + workspace

      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.learningactivities}/auto-enrolments/calculate${query}`
        }).then(response => {
          commit('LMS_USER_AUTOENROLMENTS_SUGGESTEDCOURSES_SET', response.data.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsUserAutoenrolmentsSuggestedCoursesClear ({ commit }) {
      commit('LMS_USER_AUTOENROLMENTS_SUGGESTEDCOURSES_CLEAR')
    }
  }
}

export default lmsUserAutoenrolmentsSuggestedCourses
