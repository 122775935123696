<template>
    <div class="filter__more_menu_box_item filter__more_menu_box_item--capacity">
        <div class="filter__more_menu_box_item_title">{{ $t('filter.moreMenu.title.capacity') }}</div>
        <div class="filter__more_menu_box_item_options">
            <div class="columns is-variable">
                <div class="column">
                    <input type="number" name="filter-more-menu-capacity" id="filter-more-menu-capacity" title="filter-more-menu-capacity" value="100">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'filter-more-menu-capacity'
}
</script>
