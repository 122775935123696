import 'url-search-params-polyfill'
import 'whatwg-fetch'
import 'date-time-format-timezone'
import Vue from 'vue'
import App from '@/App.vue'
import Meta from 'vue-meta'
import VeeValidate from 'vee-validate'
import store from '@/store'
import router from '@/router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { sync } from 'vuex-router-sync'
import feather from 'vue-icon'
import vSelect from 'vue-select'
import VueMask from 'v-mask'
import '@/filters'
import '@/methods'
import '@/gtm'
import { isIE11 } from '@/functions'
import plValidation from 'vee-validate/dist/locale/pl'
import enValidation from 'vee-validate/dist/locale/en'
import { TokenHelper } from '@/TokenHelper'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { requestInterceptor, responseInterceptor, errorInterceptor } from '@/Interceptors'
import i18n from './i18n'
import cssVars from 'css-vars-ponyfill'
import Fragment from 'vue-fragment'

const Promise = require('es6-promise').Promise

sync(store, router)

export const eventbus = Vue.prototype.$eventBus = new Vue()

axios.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error))
axios.interceptors.response.use(function (response) {
  return responseInterceptor(response)
}, function (error) {
  errorInterceptor(error.response)
  return Promise.reject(error)
})

Vue.config.productionTip = false

Vue.use(Fragment.Plugin)
Vue.use(VueAxios, axios)
Vue.use(Meta)
Vue.use(feather, 'v-icon')
Vue.use(VeeValidate, {
  classes: true,
  events: 'input|blur',
  i18nRootKey: 'validations',
  i18n,
  dictionary: {
    en: enValidation,
    pl: plValidation
  }
})
Vue.use(VueMask)

Vue.component('v-select', vSelect)

if (process.env.VUE_APP_ENV === 'development') {
  store.subscribeAction((action) => console.log(action.type, action.payload || ''))
}

if (isIE11() && process.env.VUE_APP_ENV === 'production') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  })
  Sentry.setContext('userState', {
    firstStageToken: TokenHelper.getToken('firstStageToken'),
    secondStageToken: TokenHelper.getToken('secondStageToken')
  })
}

const app = new Vue({
  computed: {
    validatorErrors: function () {
      return this.$validator.errors.items
    },
    loggedInWorkspaceName () {
      return this.$store.getters.getAuthUserSecondStageTokenDataWorkspaceName
    }
  },
  metaInfo () {
    return {
      title: `${this.loggedInWorkspaceName ? this.loggedInWorkspaceName + ' - Me Learning' : 'Me Learning'}`,
      titleTemplate: null
    }
  },
  methods: {
    handleRefreshTokenMouseClickListener () {
      const secondStageToken = this.$store.getters.getAuthUserSecondStageTokenData

      if (secondStageToken) {
        this.$store.dispatch('authUserRefreshSecondStageToken').catch(() => {})
      }
    }
  },
  created () {
    this.$store.dispatch('authUserInitializeTokens').catch(() => {})
    this.$store.dispatch('defaultWorkspaceInitialize').catch(() => {})
    this.$store.dispatch('defaultCustomerOptionsGetOptions').catch(() => {})
    this.$store.dispatch('lmsSuperadminCustomerGetCCSSettings').catch(() => {})
  },
  mounted () {
    const overlay = document.getElementById('app')
    overlay.addEventListener('click', this.handleRefreshTokenMouseClickListener)

    cssVars()
  },
  beforeDestroy () {
    const overlay = document.getElementById('app')
    overlay.removeEventListener('click', this.handleRefreshTokenMouseClickListener)
  },
  watch: {
    validatorErrors: function (value) {
      if (value.length) {
        this.$eventBus.$emit('stop-sending')
      }
    }
  },
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

if (window.Cypress) {
  window.__app__ = app
}

require('./assets/scss/main.scss')
