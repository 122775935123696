import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const lmsUserAccountActivityLog = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsUserAccountActivityLog: state => {
      return state.data
    },
    getLmsUserAccountActivityLogPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    LMS_USER_ACCOUNT_ACTIVITYLOG_SET (state, payload) {
      state.data = JSON.parse(JSON.stringify(payload))
      state.default = JSON.parse(JSON.stringify(payload))
    },
    LMS_USER_ACCOUNT_ACTIVITYLOG_PAGINATION_SET (state, payload) {
      state.pagination = payload
    },
    LMS_USER_ACCOUNT_ACTIVITYLOG_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    LMS_USER_ACCOUNT_ACTIVITYLOG_PAGINATION_CLEAR (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsUserAccountActivityLogGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const requestData = data ? (data.requestData !== undefined ? data.requestData : '') : ''
        commit('LMS_USER_ACCOUNT_ACTIVITYLOG_CLEAR')
        commit('LMS_USER_ACCOUNT_ACTIVITYLOG_PAGINATION_CLEAR')

        axios({
          method: 'get',
          url: `${api.sso}/v2/user-history${requestData ? `/${requestData.id}` : ''}${queryUrlParams}`
        }).then(response => {
          commit('LMS_USER_ACCOUNT_ACTIVITYLOG_SET', response.data.data)
          commit('LMS_USER_ACCOUNT_ACTIVITYLOG_PAGINATION_SET', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
export default lmsUserAccountActivityLog
