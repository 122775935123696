import axios from 'axios'
import api from '@/api'

const data = {
  data: [],
  default: []
}

const lmsUserRewards = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsUserRewards: state => {
      return state.data
    }
  },
  mutations: {
    LMS_USER_REWARDS_GET (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    LMS_USER_REWARDS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    LMS_USER_REWARDS_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    }
  },
  actions: {
    lmsUserRewardsGet ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('LMS_USER_REWARDS_CLEAR')
        return axios({
          method: 'get',
          url: `${api.learningactivities}/certificate/content`
        }).then(response => {
          commit('LMS_USER_REWARDS_GET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default lmsUserRewards
