<template>
    <div class="header__top_account_login">
        <component-button
            :class="['header__top_nav_item', 'header__top_nav_item--login']"
            :to="{ name: 'auth-login' }"
            :theme="''"
            :text="true"
            :size="''"
            :name="'header-top-account-login-login'"
            :align="'right'"
        >Log in
        </component-button>
    </div>
</template>

<script>
import ComponentButton from '@/components/default/shared/ComponentButton'
export default {
  name: 'header-top-account-login',
  components: { ComponentButton }
}
</script>
