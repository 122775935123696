import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    user: '',
    customer: '',
    courseName: '',
    completionDate: {
      date: '',
      time: ''
    },
    courseExternalId: '',
    provider: '',
    accreditation: '',
    certificate: '',
    mappingCatalogItem: ''
  },
  default: ''
}

const lmsSuperadminUserLearningHistory = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminUserLearningHistoryCourseName (state) {
      return state.data.courseName
    },
    getLmsSuperadminUserLearningHistoryCompletionDateDate (state) {
      return state.data.completionDate.date
    },
    getLmsSuperadminUserLearningHistoryCompletionDateTime (state) {
      return state.data.completionDate.time
    },
    getLmsSuperadminUserLearningHistoryProvider (state) {
      return state.data.provider
    },
    getLmsSuperadminUserLearningHistoryAccreditation (state) {
      return state.data.accreditation
    },
    getLmsSuperadminUserLearningHistoryCertificate (state) {
      return state.data.certificate
    },
    getLmsSuperadminUserLearningHistoryMappingCatalogItemId (state) {
      return state.data.mappingCatalogItem
    }
  },
  mutations: {
    LMS_SUPERADMIN_USER_LEARNINGHISTORY_GET (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_USER_LEARNINGHISTORY_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    LMS_SUPERADMIN_USER_LEARNINGHISTORY_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_COURSENAME (state, payload) {
      state.data.courseName = payload
    },
    LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_COMPLETIONDATE_DATE (state, payload) {
      state.data.completionDate.date = payload
    },
    LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_COMPLETIONDATE_TIME (state, payload) {
      state.data.completionDate.time = payload
    },
    LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_PROVIDER (state, payload) {
      state.data.provider = payload
    },
    LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_ACCREDITATION (state, payload) {
      state.data.accreditation = payload
    },
    LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_CERTIFICATE (state, payload) {
      state.data.certificate = payload ? payload[0] : ''
    },
    LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_MAPPINGCATALOGITEMID (state, payload) {
      state.data.mappingCatalogItem = payload
    }
  },
  actions: {
    lmsSuperadminUserLearningHistoryCreate ({ state, getters }, data) {
      return new Promise((resolve, reject) => {
        const stateData = JSON.parse(JSON.stringify(state.data))
        const user = data.user
        const customer = data.customer || getters.getAuthUserSecondStageTokenDataCustomerId

        axios({
          method: 'post',
          url: `${api.learningactivities}/external-history`,
          data: {
            user: user,
            customer: customer,
            courseName: stateData.courseName,
            completionDate: `${stateData.completionDate.date} ${stateData.completionDate.time}:00`,
            courseExternalId: null,
            provider: stateData.provider,
            accreditation: stateData.accreditation,
            certificate: stateData.certificate,
            mappingCatalogItem: stateData.mappingCatalogItem
          }
        }).then((response) => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminUserLearningHistoryRemove ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const entryId = data

        axios({
          method: 'delete',
          url: `${api.learningactivities}/external-history/${entryId}`
        }).then((response) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'History entry remove',
            message: 'History entry has been successfully removed.',
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'History entry remove',
            message: `History entry hasn't been removed.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminUserLearningHistoryReset ({ commit }) {
      commit('LMS_SUPERADMIN_USER_LEARNINGHISTORY_RESET')
    },
    lmsSuperadminUserLearningHistoryClear ({ commit }) {
      commit('LMS_SUPERADMIN_USER_LEARNINGHISTORY_CLEAR')
    },
    lmsSuperadminUserLearningHistorySetCourseName ({ commit }, data) {
      commit('LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_COURSENAME', data)
    },
    lmsSuperadminUserLearningHistorySetCompletionDateDate ({ commit }, data) {
      commit('LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_COMPLETIONDATE_DATE', data)
    },
    lmsSuperadminUserLearningHistorySetCompletionDateTime ({ commit }, data) {
      commit('LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_COMPLETIONDATE_TIME', data)
    },
    lmsSuperadminUserLearningHistorySetProvider ({ commit }, data) {
      commit('LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_PROVIDER', data)
    },
    lmsSuperadminUserLearningHistorySetAccreditation ({ commit }, data) {
      commit('LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_ACCREDITATION', data)
    },
    lmsSuperadminUserLearningHistorySetCertificate ({ commit }, data) {
      commit('LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_CERTIFICATE', data)
    },
    lmsSuperadminUserLearningHistorySetMappingCatalogItemId ({ commit }, data) {
      commit('LMS_SUPERADMIN_USER_LEARNINGHISTORY_SET_MAPPINGCATALOGITEMID', data)
    }
  }
}

export default lmsSuperadminUserLearningHistory
