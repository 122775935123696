var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'form',
    'form__checkbox',
    `form__checkbox--${_vm.size}`,
    _vm.switcher ? 'form__checkbox--switcher' : '',
    (_vm.switcher && _vm.disabled) ? 'form__checkbox--switcher-disabled' : '',
    _vm.disabled ? 'form__checkbox--disabled' : '',
    _vm.margin ? `form__checkbox--margin-${_vm.margin}` : '',
]},[(_vm.switcher && _vm.label)?_c('div',{staticClass:"form__checkbox_title_switcher"},[_vm._v(_vm._s(_vm._f("toCapitalize")(_vm.label)))]):_vm._e(),_c('label',{staticClass:"form__checkbox_title",attrs:{"for":_vm._f("toKebabCase")(_vm.name)}},[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.vValidate),expression:"vValidate"}],class:[
            `form__checkbox_input`,
            _vm.checked ? `form__checkbox_input--checked` : ''],attrs:{"type":"checkbox","disabled":_vm.disabled,"id":_vm._f("toKebabCase")(_vm.name),"name":_vm._f("toKebabCase")(_vm.name),"data-vv-as":_vm.dataVvAs ? _vm.dataVvAs : _vm.name.toLowerCase()},domProps:{"value":_vm.newValue,"checked":_vm.newValue},on:{"click":_vm.setNewValue}}),(_vm.sublabel)?_c('span',{class:'form__checkbox_title_sublabel'},[_vm._v(_vm._s(_vm.sublabel))]):_vm._e(),(!_vm.switcher)?_c('span',[_vm._v(_vm._s(_vm._f("toCapitalize")((_vm.switcher ? '' : _vm.label)))),(_vm.required && !_vm.switcher)?_c('span',{staticClass:"asterisk"},[_vm._v("*")]):_vm._e()]):_vm._e(),(_vm.switcher)?[(_vm.switcher)?_c('div',{staticClass:"form__checkbox_title_switcher_box"},[_c('span',[_vm._v(_vm._s(_vm._f("toCapitalize")((_vm.switcher ? '' : _vm.label)))),(_vm.required)?_c('span',{staticClass:"asterisk"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form__checkbox_title_switcher_separator_grey"}),_c('div',{staticClass:"form__checkbox_title_switcher_separator_blue"}),_c('div',{staticClass:"form__checkbox_title_switcher_active"},[_vm._v(" "+_vm._s(_vm.switcherTextActive)+" ")]),_c('div',{staticClass:"form__checkbox_title_switcher_inactive"},[_vm._v(" "+_vm._s(_vm.switcherTextInactive)+" ")])]):_vm._e()]:_vm._e()],2),(_vm.errors.first(_vm.toKebabCase(_vm.name)))?_c('span',{staticClass:"form__error form__checkbox_error"},[_vm._v(_vm._s(_vm.errors.first(_vm.toKebabCase(_vm.name))))]):_vm._e(),(_vm.$slots.default)?[_c('div',{staticClass:"form__checkbox_hint"},[_vm._t("default")],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }