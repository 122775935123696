<template>
    <div
        v-if="notificationTitle"
        :class="[
            'pop',
            'pop__toast_item',
            notificationStatus ? `pop__toast_item--${notificationStatus}` : '',
            notificationStatus === 'success' ? 'pop__toast_item--countdown' : '',
            notificationPosition ? `pop__toast_item--${notificationPosition}` : '',
            !notificationTitle ? `pop__toast_item--invalid` : ''
        ]"
    >
        <div class="pop__toast_item_box">
            <div class="pop__toast_item_box_inner">
                <div class="columns is-vcentered is-variable">
                    <template v-if="!notificationIconHidden">
                        <div class="column is-narrow">
                            <div class="pop__toast_item_box_icon">
                                <v-icon :name="notificationIconName"></v-icon>
                            </div>
                        </div>
                    </template>
                    <div class="column">
                        <div class="pop__toast_item_box_content">
                            <div class="pop__toast_item_box_content_title">{{ notificationTitle }}</div>
                            <template v-if="notificationMessage">
                                <div class="pop__toast_item_box_content_message">{{ notificationMessage }}</div>
                            </template>
                            <template v-if="notificationLinkTo">
                                <component-button
                                    :class="['pop__toast_item_box_content_link']"
                                    :name="`pop-toast-item-link-${index}`"
                                    :text="true"
                                    :underline="true"
                                    :to="notificationLinkTo"
                                    :size="'tiny'"
                                    @emit-to="performCloseNotification"
                                    @click="performCloseNotification"
                                >{{ notificationLinkText }}
                                </component-button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="!notificationPreventClose">
                <div class="pop__toast_item_box_close">
                    <component-button
                        :name="`pop-toast-item-box-close-${index}`"
                        :icon="true"
                        :icon-name="'x'"
                        :icon-raw="true"
                        :theme="''"
                        @click="performCloseNotification"
                    ></component-button>
                </div>
            </template>
            <div class="pop__toast_item_box_progress">
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentButton from '@/components/default/shared/ComponentButton'

export default {
  name: 'pop-toast-item',
  components: { ComponentButton },
  props: {
    notification: {
      type: Object,
      default: () => {
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    notificationStatus () {
      return this.notification.status || 'normal'
    },
    notificationTitle () {
      return this.notification.title
    },
    notificationMessage () {
      return this.notification.message
    },
    notificationIconHidden () {
      return this.notification.iconHidden
    },
    notificationIconName () {
      let notificationStatus = this.notificationStatus
      let defaultNotificationIconName
      let newNotificationIconName = this.notification.iconName

      switch (notificationStatus) {
        case 'success':
          defaultNotificationIconName = 'check'
          break
        case 'error':
          defaultNotificationIconName = 'alert-triangle'
          break
        default:
          defaultNotificationIconName = 'info'
          break
      }

      return newNotificationIconName || defaultNotificationIconName
    },
    notificationLinkTo () {
      return this.notification.linkTo
    },
    notificationLinkText () {
      return this.notification.linkText || 'View'
    },
    notificationPreventClose () {
      return this.notification.preventClose || false
    },
    notificationPosition () {
      return this.notification.position || 'top'
    }
  },
  methods: {
    performOpenNotification () {
      this.$el.classList.add('pop__toast_item--enter')
      setTimeout(() => {
        this.$el.classList.remove('pop__toast_item--enter')
        this.handleCountdown()
      }, 300)
    },
    performCloseNotification () {
      if (this.notificationPreventClose) return

      this.$el.classList.add('pop__toast_item--leave')
      setTimeout(() => {
        if (this.$el.classList.contains('pop__toast_item--leave')) {
          this.$el.classList.remove('pop__toast_item--leave')
          this.$store.dispatch('defaultNotificationToastsRemove', this.index)
        }
      }, 300)
    },
    handleCountdown () {
      if (this.notificationStatus === 'success') {
        setTimeout(() => {
          this.performCloseNotification()
        }, 5000)
      }
    }
  },
  mounted () {
    this.performOpenNotification()
  }
}
</script>
