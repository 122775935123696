import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}
const lmsSuperadminAuditLog = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminAuditLogData: state => {
      return state.data
    },
    getLmsSuperadminAuditLogPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    LMS_SUPERADMIN_AUDIT_LOG_SET_DATA (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_AUDIT_LOG_SET_PAGINATION (state, payload) {
      state.pagination = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_AUDIT_LOG_CLEAR_DATA (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_AUDIT_LOG_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsSuperadminAuditLogGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        let queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        queryUrlParams = queryUrlParams.replace('dateStart', 'from').replace('dateEnd', 'to')

        commit('LMS_SUPERADMIN_AUDIT_LOG_CLEAR_DATA')
        commit('LMS_SUPERADMIN_AUDIT_LOG_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.admintools}/api/audit-log${queryUrlParams}`
        }).then(response => {
          commit('LMS_SUPERADMIN_AUDIT_LOG_SET_DATA', response.data.data)
          commit('LMS_SUPERADMIN_AUDIT_LOG_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default lmsSuperadminAuditLog
