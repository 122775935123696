import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const ebsEvents = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEvents: state => {
      return state.data
    },
    getEventsMappedLabelValue: state => {
      const rawEvents = state.data
      if (!rawEvents.length) return []
      return rawEvents.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.name,
          value: item.id
        }
      })
    },
    getEventsMappedIdNameStatus: state => {
      const rawEvents = state.data
      if (!rawEvents.length) return []
      return rawEvents.map((item) => {
        return {
          id: item.id,
          name: item.name,
          status: item.status
        }
      })
    },
    getEventsMappedIdNameStatusUsedInCourse: state => {
      const rawEvents = state.data
      if (!rawEvents.length) return []
      return rawEvents.map((item) => {
        return {
          id: item.id,
          name: item.name,
          status: item.status,
          disabled: item.usedInCourse,
          usedInCourse: item.courses.name || '--'
        }
      })
    },
    getEventsPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    SET_EVENTS (state, payload) {
      state.data = payload
    },
    SET_EVENTS_PAGINATION (state, payload) {
      state.pagination = payload
    },
    CLEAR_EVENTS (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    CLEAR_EVENTS_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    getEvents ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data && data.queryUrlParams ? data.queryUrlParams : '?pageSize=9999'
        const customParams = data && data.customParams ? `${data.customParams}` : ''
        const requestData = data && data.requestData ? data.requestData : ''
        const typeOfSearch = requestData && requestData.typeOfSearch ? requestData.typeOfSearch : 'advanced'
        const typeOfSearchQuery = '&typeOfSearch=' + typeOfSearch

        commit('CLEAR_EVENTS')
        commit('CLEAR_EVENTS_PAGINATION')

        axios({
          method: 'get',
          url: `${api.classroom}/list-events${queryUrlParams}${customParams}${typeOfSearchQuery}`
        }).then(response => {
          commit('SET_EVENTS', response.data.data)
          commit('SET_EVENTS_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ebsEvents
