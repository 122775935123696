import axios from 'axios'
import api from '@/api'

const data = {
  data: '',
  default: '',
  custom: {
    dataEnrolments: [],
    dataInvitations: [],
    dataPopularCourses: [],
    dataActivity: []
  }
}

const dashboard = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getDashboardDataEnrolments: state => {
      return state.custom.dataEnrolments
    },
    getDashboardDataPopularCourses: state => {
      return state.custom.dataPopularCourses
    },
    getDashboardDataInvitations: state => {
      return state.custom.dataInvitations
    },
    getDashboardDataActivity: state => {
      return state.custom.dataActivity
    },
    getDashboardDataEnrolmentsEnrolmentsVsCompleted: (state, getters) => {
      const dataEnrolments = getters.getDashboardDataEnrolments ? JSON.parse(JSON.stringify(getters.getDashboardDataEnrolments)) : []
      let seriesArr = []

      seriesArr.push({
        name: 'Enrolments',
        data: dataEnrolments.map(obj => obj.statuses && obj.statuses.enrolled ? Number(obj.statuses.enrolled) : 0)
      })
      seriesArr.push({
        name: 'Completions',
        data: dataEnrolments.map(obj => obj.statuses && obj.statuses.completed ? Number(obj.statuses.completed) : 0)
      })

      return seriesArr
    },
    getDashboardDataEnrolmentsEnrolmentsAndInProgressAndCompleted: (state, getters) => {
      const dataEnrolments = getters.getDashboardDataEnrolments ? JSON.parse(JSON.stringify(getters.getDashboardDataEnrolments)) : []
      let valuesObj = {
        enrolled: 0,
        in_progress: 0,
        completed: 0
      }

      for (let item of dataEnrolments) {
        if (item.statuses) {
          for (let key of Object.keys(item.statuses)) {
            valuesObj[key] += item.statuses[key]
          }
        }
      }

      valuesObj = Object.values(valuesObj)
      return valuesObj
    },
    getDashboardDataEnrolmentsEnrolmentsAndInProgressAndCompletedPerUnit: (state, getters) => {
      const dataPopularCourses = getters.getDashboardDataPopularCourses ? JSON.parse(JSON.stringify(getters.getDashboardDataPopularCourses)) : []
      let categoriesArr = []
      let seriesArr = [
        {
          name: 'Not started',
          data: []
        },
        {
          name: 'In progress',
          data: []
        },
        {
          name: 'Completed',
          data: []
        }
      ]

      for (let course of dataPopularCourses) {
        categoriesArr.push(course.name)
        seriesArr[0].data.push(course.enrolled)
        seriesArr[1].data.push(course.in_progress)
        seriesArr[2].data.push(course.completed)
      }

      return { categories: categoriesArr, series: seriesArr }
    },
    getDashboardDataInvitationsInvitedAndActiveAndRejected: (state, getters) => {
      const dataInvitations = getters.getDashboardDataInvitations ? JSON.parse(JSON.stringify(getters.getDashboardDataInvitations)) : []
      let itemsArr = Object.values(dataInvitations)
      itemsArr = itemsArr.map(obj => Number(obj))

      return itemsArr
    },
    getDashboardDataActivityCounted: (state, getters) => {
      const dataActivity = getters.getDashboardDataActivity ? JSON.parse(JSON.stringify(getters.getDashboardDataActivity)) : []
      let itemsArr = Object.values(dataActivity)
      itemsArr = [
        {
          name: 'Logins',
          data: itemsArr.map(obj => Number(obj.count))
        }
      ]

      itemsArr[0].data.splice(0, 1)

      return itemsArr
    }
  },
  mutations: {
    DASHBOARD_GET_DATA_ENROLMENTS (state, payload) {
      state.custom.dataEnrolments = payload
    },
    DASHBOARD_CLEAR_DATA_ENROLMENTS (state) {
      state.custom.dataEnrolments = JSON.parse(JSON.stringify(data.custom.dataEnrolments))
    },
    DASHBOARD_GET_DATA_POPULARCOURSES (state, payload) {
      state.custom.dataPopularCourses = payload
    },
    DASHBOARD_CLEAR_DATA_POPULARCOURSES (state) {
      state.custom.dataPopularCourses = JSON.parse(JSON.stringify(data.custom.dataPopularCourses))
    },
    DASHBOARD_GET_DATA_INVITATIONS (state, payload) {
      state.custom.dataInvitations = payload
    },
    DASHBOARD_CLEAR_DATA_INVITATIONS (state) {
      state.custom.dataInvitations = JSON.parse(JSON.stringify(data.custom.dataInvitations))
    },
    DASHBOARD_GET_DATA_ACTIVITY (state, payload) {
      state.custom.dataActivity = payload
    },
    DASHBOARD_CLEAR_DATA_ACTIVITY (state) {
      state.custom.dataActivity = JSON.parse(JSON.stringify(data.custom.dataActivity))
    }
  },
  actions: {
    dashboardGenerateIcal (ctx, value) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.classroom}/dashboard/ical/${value}`
        }).then(response => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.getElementById('download-ics-file')
            link.href = url
            link.setAttribute('download', `ics.ics`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), 'download-ics-file')
          }

          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    dashboardGetDataEnrolments ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('DASHBOARD_CLEAR_DATA_ENROLMENTS')

        const dateFrom = data ? data.dateFrom !== undefined ? data.dateFrom : '' : ''
        const dateTo = data ? data.dateTo !== undefined ? data.dateTo : '' : ''
        const orgUnits = data ? data.orgUnits && data.orgUnits.length ? data.orgUnits : '' : ''

        axios({
          method: 'get',
          url: `${api.learningactivities}/dashboard-enrolments?filters[startDate]=${dateFrom}&filters[endDate]=${dateTo}${orgUnits ? `&filters[orgUnits]=${orgUnits}` : ''}`
        }).then(response => {
          commit('DASHBOARD_GET_DATA_ENROLMENTS', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    dashboardGetDataPopularCourses ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('DASHBOARD_CLEAR_DATA_POPULARCOURSES')

        const orgUnits = data ? data.orgUnits && data.orgUnits.length ? data.orgUnits : '' : ''

        axios({
          method: 'get',
          url: `${api.learningactivities}/popular-courses${orgUnits ? `?filters[orgUnits]=${orgUnits}` : ''}`
        }).then(response => {
          commit('DASHBOARD_GET_DATA_POPULARCOURSES', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    dashboardGetDataInvitations ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        commit('DASHBOARD_CLEAR_DATA_INVITATIONS')

        const organisationUnits = data && data.orgUnits ? data.orgUnits : []
        const customers = data && data.customers ? [data.customers] : [getters.getAuthUserSecondStageTokenDataCustomerId]
        const workspaces = data && data.workspaces ? [data.workspaces] : [getters.getAuthUserSecondStageTokenDataWorkspaceId]

        let dataObj = {
          customers,
          workspaces,
          organisationUnits,
          type: 'unique_logins'
        }

        axios({
          method: 'post',
          url: `${api.sso}/v2/invitations/stats`,
          data: dataObj
        }).then(response => {
          commit('DASHBOARD_GET_DATA_INVITATIONS', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    dashboardGetDataActivity ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        commit('DASHBOARD_CLEAR_DATA_ACTIVITY')

        const from = data && data.dateFrom ? data.dateFrom : ''
        const to = data && data.dateTo ? data.dateTo : ''
        const organisationUnits = data && data.orgUnits ? data.orgUnits : []
        const customers = data && data.customers ? [data.customers] : [getters.getAuthUserSecondStageTokenDataCustomerId]
        const workspaces = data && data.workspaces ? [data.workspaces] : [getters.getAuthUserSecondStageTokenDataWorkspaceId]
        const type = data && data.type ? data.type : 'unique_logins'

        let dataObj = {
          from,
          to,
          customers,
          workspaces,
          organisationUnits,
          type
        }

        axios({
          method: 'post',
          url: `${api.sso}/v2/user-activity/time-report`,
          data: dataObj
        }).then(response => {
          commit('DASHBOARD_GET_DATA_ACTIVITY', response.data.workspaces)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default dashboard
