import { isIE11, isInIframe } from '@/functions'
import { Storage } from '@/Storage'
import * as Cookies from 'js-cookie'

export class TokenHelper {
  static parseJWTPayload (payload) {
    if (!payload) return
    return decodeURIComponent(atob(payload).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

  static getToken (tokenName) {
    if (!tokenName) return
    let token
    if (isIE11() || isInIframe()) {
      token = Storage.getSession(tokenName)
    }
    if (!isIE11() || !token) {
      token = Cookies.get(tokenName)
    }
    return token
  }

  static getPayload (token) {
    if (!token) return
    return JSON.parse(this.parseJWTPayload(token.split('.')[1]))
  }
}
