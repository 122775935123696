<template>
    <div :class="[
        'form',
        'form__radiohorizontal',
        align ? `form__radiohorizontal--${align}` : ``,
        size ? `form__radiohorizontal--${size}` : ``,
        width ? `form__radiohorizontal--${width}` : '',
        isVertical && !preventVertical || forceVertical ? `form__radiohorizontal--vertical` : '',
        iconMode ? `form__radiohorizontal--icon-mode` : '',
        errors.first(toKebabCase(name)) ? 'form__error' : ''
    ]">
        <div
            v-if="label"
            class="form__radiohorizontal_title is-9-tablet"
        >{{label | toCapitalize}}<span class="asterisk" v-if="required">*</span></div>
        <template v-if="$slots.default && hintPositionTop">
            <div class="form__radiohorizontal_hint form__radiohorizontal_hint--top">
                <slot></slot>
            </div>
        </template>
        <div class="form__radiohorizontal_fields">
            <div :class="[
                'form__radiohorizontal_fields_fieldset',
                 width === 'full' ? 'columns is-gapless' : '',
                 newItems.length > 1 ? 'form__radiohorizontal_fields_fieldset--custom-nav' : '']">
                <template
                    v-for="(item, index) in newItems"
                >
                    <label
                        :class="[width === 'full' ? 'column' : '']"
                        :for="(name + '-' +  item.value) | toKebabCase"
                        :key="index"
                    >
                        <input
                            type="radio"
                            :class="['form__radiohorizontal_fields_input', (item.value === value || item.value === 'other' && otherField) ? 'checked' : '']"
                            :id="(name + '-' +  item.value) | toKebabCase"
                            :title="item.name | toKebabCase"
                            :value="item.value"
                            :name="name | toKebabCase"
                            :disabled="disabled"
                            v-model="newValue"
                            v-validate="vValidate"
                            @click="checkValue(item.value)"
                            :data-vv-as="dataVvAs ? dataVvAs : label.toLowerCase()"
                        >
                        <v-icon v-if="iconMode" :name="item.icon"></v-icon>
                        <span class="form__radiohorizontal_fields_text">{{ toUncapitalise(item.name) === 'like' ? 'contains' : item.name | toCapitalize}}</span>
                    </label>
                </template>
            </div>
            <span
                class="form__error form__radiohorizontal_error"
                v-if="errors.first(toKebabCase(name)) && !otherField"
            >{{ errors.first(toKebabCase(name)) }}</span>
        </div>
        <template v-if="$slots.default && !hintPositionTop">
            <div class="form__radiohorizontal_hint">
                <slot></slot>
            </div>
        </template>
        <transition name="fade" mode="out-in">
            <template v-if="otherField && other">
                <form-text
                    :class="'form__radiohorizontal_otherfield'"
                    :name="'other'"
                    :label="$t('forms.radioHorizontal.label')"
                    :disabled="disabled"
                    :required="true"
                    :v-validate="'required'"
                    v-model="newValue"
                >{{ $t('forms.radioHorizontal.text') }}
                </form-text>
            </template>
        </transition>
    </div>
</template>

<script>
import FormText from '@/components/default/forms/FormText'

export default {
  components: { FormText },
  name: 'form-radio-horizontal',
  data () {
    return {
      otherField: false,
      formElWidth: 0
    }
  },
  props: {
    label: {
      type: String,
      required: true,
      default: 'Label'
    },
    name: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    align: {
      type: String
    },
    // each array object need to contain name & value keys
    items: {
      type: Array,
      required: true
    },
    value: {},
    other: {
      type: Boolean,
      default: false
    },
    // one of small/big
    size: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ''
    },
    //
    dataVvAs: {
      type: String,
      default: ''
    },
    vValidate: {
      default: ''
    },
    iconMode: {
      type: Boolean,
      default: false
    },
    hintPositionTop: {
      type: Boolean,
      default: false
    },
    preventVertical: {
      type: Boolean,
      default: false
    },
    forceVertical: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    newItems: function () {
      const arr = this.items
      if (this.other) {
        arr.push({ name: 'Other', value: 'other' })
      }
      return arr
    },
    newValue: {
      get () {
        return this.value
      },
      set (newValue) {
        if (newValue !== 'other') {
          this.$emit('input', newValue)
        }
      }
    },
    isVertical () {
      let itemsNameLength = 0
      let itemsLength = this.items.length

      for (let i in this.items) {
        itemsNameLength += this.items[i].name.length
      }
      return this.formElWidth > 0 ? (itemsNameLength * itemsLength) > this.formElWidth / 3.3 : false
    }
  },
  mounted () {
    let itemsValues = []
    for (let i = 0; i < this.items.length; i++) {
      itemsValues[i] = this.items[i].value
    }
    if (!itemsValues.includes(this.value) && this.value) {
      this.otherField = true
    }
    const formEl = this.$el.getElementsByClassName('form__radiohorizontal_fields')[0]
    this.formElWidth = formEl ? formEl.getBoundingClientRect().width : 0
  },
  methods: {
    checkValue: function (value) {
      if (value === 'other') {
        this.otherField = true
        this.$emit('input', '')
      } else {
        this.otherField = false
      }
    }
  },
  inject: ['$validator']
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/forms/formradiohorizontal";
</style>
