<template>
    <div class="header__top_account_menu">
        <header-top-account-menu-thumbnail
            :button-active="boxActive"
        />
        <header-top-account-menu-box
            ref="menubox"
            :account-menu-box-active="boxActive"
            :account-menu-box-touched="boxTouched"
        />
        <pop-notify
            ref="notifyremindsessionexpire"
            :title-text="'Stay logged in?'"
            :content-text="`Your session will expire in ${ sessionTimerTotalSeconds } seconds.`"
            :cancel-button-active="false"
            :prevent-close="true"
            :overlay-prevent-close="true"
            :close-button-active="false"
            :custom-button-active="true"
            :custom-button-theme="'default'"
            :custom-button-text="'Log out'"
            :submit-button-text="'Stay logged in'"
            @emit-custom="userLogout"
            @emit-submit="refreshSession"
        ></pop-notify>
    </div>
</template>

<script>
import HeaderTopAccountMenuThumbnail from '@/components/default/layout/header/HeaderTopAccountMenuThumbnail'
import HeaderTopAccountMenuBox from '@/components/default/layout/header/HeaderTopAccountMenuBox'
import PopNotify from '@/components/default/shared/PopNotify'
export default {
  name: 'header-top-account-menu',
  components: { PopNotify, HeaderTopAccountMenuBox, HeaderTopAccountMenuThumbnail },
  data () {
    return {
      boxActive: false,
      boxTouched: false,
      sessionTimer: '',
      sessionTimerTotalSeconds: '',
      sessionTimerInterval: ''
    }
  },
  computed: {
    routeName () {
      return this.$route ? this.$route.name : ''
    },
    secondStageTokenExpiryTime () {
      return this.$store.getters.getAuthUserSecondStageTokenDataExp
    }
  },
  watch: {
    routeName () {
      this.boxActive = false
    },
    sessionTimerTotalSeconds (value) {
      if (value <= 60) {
        if (!this.$refs.notifyremindsessionexpire || !this.$refs.notifyremindsessionexpire.isActive) {
          this.openSessionExpiryNotify()
        }
      }
      if (value <= 0) {
        this.userLogout()
        this.$store.dispatch('defaultNotificationToastsAdd', {
          title: 'Logging out',
          message: 'You\'ve been logged out due to inactivity.',
          status: 'success'
        })
      }
    }
  },
  methods: {
    userLogout () {
      this.closeSessionExpiryNotify()
      this.$store.dispatch('authUserLogout')
    },
    toggleBox () {
      this.boxActive = !this.boxActive
      this.boxTouched = true
    },
    closeBox (event) {
      const path = event.path || event.composedPath()

      if (!path || !this.boxActive) return
      let close = true
      for (let i = 0; i < path.length; i++) {
        if (!path[i].className) continue
        if (
          path[i].classList.contains('header__top_account_menu')
        ) {
          close = false
        }
      }
      if (close) {
        this.boxActive = false
      }
    },
    openSessionExpiryNotify () {
      this.$refs.notifyremindsessionexpire.openNotify()
    },
    closeSessionExpiryNotify () {
      if (this.$refs.notifyremindsessionexpire) {
        this.$refs.notifyremindsessionexpire.closeNotify()
      }
    },
    refreshSession () {
      this.$store.dispatch('authUserRefreshSecondStageToken').finally(() => {
        this.closeSessionExpiryNotify()
      })
    },
    setSessionTimer () {
      const now = Date.now() / 1000
      const exp = this.secondStageTokenExpiryTime
      const totalSeconds = (exp - now)
      const mins = Math.floor(totalSeconds / 60) % 60
      const seconds = Math.floor(totalSeconds) % 60

      const formatTime = (time) => {
        return time < 10 ? `0${time >= 0 ? time : 0}` : time
      }

      const minsOutput = formatTime(mins)
      const secondsOutput = formatTime(seconds)

      this.sessionTimerTotalSeconds = Math.floor(totalSeconds)
      this.sessionTimer = minsOutput + ':' + secondsOutput
    }
  },
  mounted () {
    const overlay = document.getElementById('app')
    overlay.addEventListener('click', this.closeBox)

    if (!this.sessionTimerInterval) {
      this.setSessionTimer()

      this.sessionTimerInterval = setInterval(this.setSessionTimer, 1000)
    }
  },
  beforeDestroy () {
    const overlay = document.getElementById('app')
    overlay.removeEventListener('click', this.closeBox)

    clearInterval(this.sessionTimerInterval)
  }
}
</script>
