import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    fields: [],
    location: ''
  },
  default: '',
  custom: {
    feedbackAllowed: false
  }
}

const ebsCourseFeedback = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsCourseFeedback: state => {
      return state.data
    },
    getEbsCourseFeedbackFields: state => {
      return state.data.fields
    },
    getEbsCourseFeedbackCustomFeedbackAllowed: state => {
      return state.custom.feedbackAllowed
    }
  },
  mutations: {
    EBS_COURSEFEEDBACK_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(state.data))
      state.custom = JSON.parse(JSON.stringify(state.custom))
    },
    EBS_COURSEFEEDBACK_SET_FIELDS (state, payload) {
      state.data.fields = payload
    },
    EBS_COURSEFEEDBACK_CUSTOM_SET_FEEDBACKALLOWED (state, payload) {
      state.custom.feedbackAllowed = payload
    },
    EBS_COURSEFEEDBACK_UPDATE_FIELDS (state, payload) {
      const object = state.data.fields[payload.index]
      object.answers = payload.value
      state.data.fields.splice(payload.index, 1, object)
    }
  },
  actions: {
    ebsCourseFeedbackGet ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const enrolmentId = data ? data.enrolmentId : getters.getEbsCatalogItemLatestEnrolmentId

        axios({
          method: 'get',
          url: `${api.learningactivities}/v2/feedback?enrolmentId=${enrolmentId}`
        }).then(response => {
          commit('EBS_COURSEFEEDBACK_CUSTOM_SET_FEEDBACKALLOWED', false)
          resolve(response)
        }).catch(error => {
          commit('EBS_COURSEFEEDBACK_CUSTOM_SET_FEEDBACKALLOWED', true)
          reject(error)
        })
      })
    },
    ebsCourseFeedbackSubmit ({ state, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        const enrolmentId = getters.getEbsCatalogItemLatestEnrolmentId
        let fields = stateData.fields
        for (let field of fields) {
          if (field.answers.constructor === Array) {
            field.answers = field.answers.join('\n')
          }
        }

        axios({
          method: 'post',
          url: `${api.learningactivities}/v2/feedback`,
          data: {
            enrolmentId,
            fields
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Feedback submit',
            message: 'Feedback has been successfully submitted.',
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Feedback submit',
            message: `Feedback hasn't been submitted.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    ebsCourseFeedbackClear ({ commit }) {
      commit('EBS_COURSEFEEDBACK_CLEAR')
    },
    ebsCourseFeedbackPreloadForm ({ commit }, data) {
      commit('EBS_COURSEFEEDBACK_SET_FIELDS', data)
    },
    ebsCourseFeedbackUpdateFields ({ commit }, data) {
      commit('EBS_COURSEFEEDBACK_UPDATE_FIELDS', data)
    }
  }
}

export default ebsCourseFeedback
