import axios from 'axios'
import api from '@/api'

const ebsSubscription = {
  state: {
    data: {
      success: false
    }
  },
  getters: {
    getSubscriptionSuccess: state => {
      return state.data.success
    }
  },
  mutations: {
    CLEAR_SUBSCRIPTION: state => {
      state.data = {
        success: false
      }
    },
    SET_SUBSCRIPTION_SUCCESS: (state, payload) => {
      state.data.success = payload
    }
  },
  actions: {
    subscribeEvent ({ commit, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        const learnerId = getters.getAuthUserSecondStageTokenDataID
        const eventId = getters.getEvent.id
        const workspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        axios({
          method: 'post',
          url: `${api.classroom}/learner-subscriptions`,
          data: {
            learnerId,
            eventId,
            workspaceId
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event subscribe',
            message: `Event has been successfully subscribed.`,
            status: 'success'
          })
          commit('SET_SUBSCRIPTION_SUCCESS', true)
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event subscribe',
            message: `Event hasn't been subscribed.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    unsubscribeEvent ({ getters, dispatch }, id) {
      return new Promise((resolve, reject) => {
        let subscriptionId = id || getters.getEvent.userSubscription
        axios({
          method: 'delete',
          url: `${api.classroom}/learner-subscriptions/${subscriptionId}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event unsubscribe',
            message: `Event has been successfully unsubscribed.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event unsubscribe',
            message: `Event hasn't been unsubscribed.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    unsubscribeEventBulk ({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${api.classroom}/learner-subscriptions-bulk`,
          data: data
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event bulk unsubscribe',
            message: `Event has been successfully unsubscribed.`,
            status: 'success'
          })
          commit('SET_SUBSCRIPTION_SUCCESS', true)
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Event bulk unsubscribe',
            message: `Event hasn't been unsubscribed.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    subscribeOccurrence ({ commit, getters, dispatch }) {
      const learnerId = getters.getAuthUserSecondStageTokenDataID
      const occurrenceId = getters.getSession.id
      const workspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId

      axios({
        method: 'post',
        url: `${api.classroom}/learner-subscriptions`,
        data: {
          learnerId,
          occurrenceId,
          workspaceId
        }
      }).then(function () {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Session wait list subscribe',
          message: `Session wait list has been successfully subscribed.`,
          status: 'success'
        })
        commit('SET_SUBSCRIPTION_SUCCESS', true)
      }).catch(function () {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Session wait list subscribe',
          message: `Session wait list hasn't been subscribed.`,
          status: 'error'
        })
      })
    },
    unsubscribeOccurrence ({ commit, getters, dispatch }) {
      let subscriptionId = getters.getSession.userSubscription
      axios({
        method: 'delete',
        url: `${api.classroom}/learner-subscriptions/${subscriptionId}`
      }).then(function () {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Session wait list unsubscribe',
          message: `Session wait list has been successfully unsubscribed.`,
          status: 'success'
        })
        commit('SET_SUBSCRIPTION_SUCCESS', true)
      }).catch(function () {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Session wait list unsubscribe',
          message: `Session wait list hasn't been unsubscribed.`,
          status: 'error'
        })
      })
    },
    //
    clearSubscription ({ commit }) {
      commit('CLEAR_SUBSCRIPTION')
    },
    setSubscriptionSuccess ({ commit }, value) {
      commit('SET_SUBSCRIPTION_SUCCESS', value)
    }
  }
}

export default ebsSubscription
