<template>
    <main class="main" id="main">
        <div v-show="loading" class="main__loading">
            <component-loading/>
        </div>
        <div v-show="!loading && content" class="main__content">
            <transition name="fade" mode="out-in">
                <router-view/>
            </transition>
        </div>
    </main>
</template>

<script>
import ComponentLoading from '@/components/default/shared/ComponentLoading'

export default {
  components: { ComponentLoading },
  name: 'the-main',
  data () {
    return {
      loading: false,
      content: true
    }
  },
  methods: {
    startLoading: function () {
      this.loading = true
    },
    stopLoading: function () {
      this.loading = false
    },
    hideContent: function () {
      this.content = false
    },
    showContent: function () {
      this.content = true
    }
  },
  mounted () {
    this.$eventBus.$on('error-loading', this.hideContent)
    this.$eventBus.$on('start-loading', this.startLoading)
    this.$eventBus.$on('stop-loading', this.stopLoading)
  },
  updated () {
    if (!this.loading) {
      this.showContent()
    }
    this.$eventBus.$off('error-loading')
    this.$eventBus.$off('start-loading')
    this.$eventBus.$off('stop-loading')
    this.$eventBus.$on('error-loading', this.hideContent)
    this.$eventBus.$on('start-loading', this.startLoading)
    this.$eventBus.$on('stop-loading', this.stopLoading)
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/layout/main/main";
</style>
