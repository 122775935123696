var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.notificationTitle)?_c('div',{class:[
        'pop',
        'pop__toast_item',
        _vm.notificationStatus ? `pop__toast_item--${_vm.notificationStatus}` : '',
        _vm.notificationStatus === 'success' ? 'pop__toast_item--countdown' : '',
        _vm.notificationPosition ? `pop__toast_item--${_vm.notificationPosition}` : '',
        !_vm.notificationTitle ? `pop__toast_item--invalid` : ''
    ]},[_c('div',{staticClass:"pop__toast_item_box"},[_c('div',{staticClass:"pop__toast_item_box_inner"},[_c('div',{staticClass:"columns is-vcentered is-variable"},[(!_vm.notificationIconHidden)?[_c('div',{staticClass:"column is-narrow"},[_c('div',{staticClass:"pop__toast_item_box_icon"},[_c('v-icon',{attrs:{"name":_vm.notificationIconName}})],1)])]:_vm._e(),_c('div',{staticClass:"column"},[_c('div',{staticClass:"pop__toast_item_box_content"},[_c('div',{staticClass:"pop__toast_item_box_content_title"},[_vm._v(_vm._s(_vm.notificationTitle))]),(_vm.notificationMessage)?[_c('div',{staticClass:"pop__toast_item_box_content_message"},[_vm._v(_vm._s(_vm.notificationMessage))])]:_vm._e(),(_vm.notificationLinkTo)?[_c('component-button',{class:['pop__toast_item_box_content_link'],attrs:{"name":`pop-toast-item-link-${_vm.index}`,"text":true,"underline":true,"to":_vm.notificationLinkTo,"size":'tiny'},on:{"emit-to":_vm.performCloseNotification,"click":_vm.performCloseNotification}},[_vm._v(_vm._s(_vm.notificationLinkText)+" ")])]:_vm._e()],2)])],2)]),(!_vm.notificationPreventClose)?[_c('div',{staticClass:"pop__toast_item_box_close"},[_c('component-button',{attrs:{"name":`pop-toast-item-box-close-${_vm.index}`,"icon":true,"icon-name":'x',"icon-raw":true,"theme":''},on:{"click":_vm.performCloseNotification}})],1)]:_vm._e(),_vm._m(0)],2)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pop__toast_item_box_progress"},[_c('span')])
}]

export { render, staticRenderFns }