import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    id: '',
    name: '',
    fields: [],
    default: false
  },
  default: {}
}

const lmsSuperadminWorkspaceFeedbackForm = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkspaceFeedbackFormId: state => {
      return state.data.id
    },
    getLmsSuperadminWorkspaceFeedbackFormName: state => {
      return state.data.name
    },
    getLmsSuperadminWorkspaceFeedbackFormDefault: state => {
      return state.data.default
    },
    getLmsSuperadminWorkspaceFeedbackFormFields: state => {
      return state.data.fields
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_GET (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))

      state.data = JSON.parse(JSON.stringify(payloadData))
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.data))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_SET_NAME (state, payload) {
      state.data.name = payload
    },
    LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_SET_FIELDS (state, payload) {
      state.data.fields = payload
    },
    LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_SET_FIELD (state, payload) {
      state.data.fields.push(payload)
    },
    LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_MOVE_FIELD (state, payload) {
      const arr = state.data.fields
      const item = arr[payload.from]
      if (payload.to !== -1) {
        state.data.fields.splice(payload.from, 1)
        state.data.fields.splice(payload.to, 0, item)
      }
    },
    LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_UPDATE_FIELD (state, payload) {
      state.data.fields.splice(payload.index, 1)
      state.data.fields.splice(payload.index, 0, payload.data)
    },
    LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_REMOVE_FIELD (state, payload) {
      state.data.fields.splice(payload, 1)
    }
  },
  actions: {
    lmsSuperadminWorkspaceFeedbackFormGet ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data.workspaceId || getters.getAuthUserSecondStageTokenDataWorkspaceId
        const feedbackFormId = data.feedbackFormId

        commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_CLEAR')

        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/feedback-form-templates/${workspaceId}/${feedbackFormId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_GET', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceFeedbackFormCreate ({ dispatch, state, getters }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data && data.workspaceId ? data.workspaceId : getters.getAuthUserSecondStageTokenDataWorkspaceId
        const stateData = JSON.parse(JSON.stringify(state.data))
        const name = stateData.name
        const fields = stateData.fields

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/feedback-form-templates/${workspaceId}`,
          data: {
            name,
            fields
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Feedback form created',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Feedback form not created',
            message: ``,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceFeedbackFormUpdate ({ dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data.workspaceId
        const feedbackFormId = data.feedbackFormId
        const stateData = JSON.parse(JSON.stringify(state.data))
        const name = stateData.name
        const fields = stateData.fields

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/feedback-form-templates/${workspaceId}/${feedbackFormId}`,
          data: {
            name,
            fields
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Feedback form updated',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Feedback form not updated',
            message: '',
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceFeedbackFormRemove ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data.workspaceId
        const feedbackFormId = data.feedbackFormId

        axios({
          method: 'delete',
          url: `${api.subscriptionmanager}/feedback-form-templates/${workspaceId}/${feedbackFormId}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Feedback form removed',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Feedback form is in use',
            message: '',
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceFeedbackFormSetDefault ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data.workspaceId
        const feedbackFormId = data.feedbackFormId

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/feedback-form-templates/${workspaceId}/set-default/${feedbackFormId}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Feedback form set as default',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Feedback form not set as default',
            message: '',
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceFeedbackFormClone ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data.workspaceId
        const feedbackFormId = data.feedbackFormId

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/feedback-form-templates/${workspaceId}/clone/${feedbackFormId}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Feedback form cloned',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Feedback form not cloned',
            message: error.response.data && error.response.data.errors ? error.response.data.errors.message : '',
            status: 'error'
          })
          reject(error)
        })
      })
    },
    //
    lmsSuperadminWorkspaceFeedbackFormClear ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_CLEAR')
    },
    //
    lmsSuperadminWorkspaceFeedbackFormSetName ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_SET_NAME', data)
    },
    lmsSuperadminWorkspaceFeedbackFormSetFields ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_SET_FIELDS', data)
    },
    lmsSuperadminWorkspaceFeedbackFormSetField ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_SET_FIELD', data)
    },
    lmsSuperadminWorkspaceFeedbackFormMoveField ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_MOVE_FIELD', data)
    },
    lmsSuperadminWorkspaceFeedbackFormUpdateField ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_UPDATE_FIELD', data)
    },
    lmsSuperadminWorkspaceFeedbackFormRemoveField ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACKFORM_REMOVE_FIELD', data)
    }
  }
}

export default lmsSuperadminWorkspaceFeedbackForm
