import Vue from 'vue'
import {
  calendarDate,
  calendarTime,
  fromStringToArrayRadio,
  fromStringToArraySelect,
  fromKebabCaseToString,
  fromStringToArray,
  removeLastOneChar,
  removeLastTwoChar,
  removeLastThreeChar,
  sortByKey,
  toCapitalize,
  toUncapitalise,
  toKebabCase,
  toSnakeCase,
  toUnicase,
  toString,
  toUrlQuery,
  toLocaleDateTime,
  toISODate
} from '@/functions'

Vue.filter('calendarDate', value => {
  return calendarDate(value)
})
Vue.filter('calendarTime', value => {
  return calendarTime(value)
})
Vue.filter('fromKebabCaseToString', value => {
  return fromKebabCaseToString(value)
})
Vue.filter('fromStringToArrayRadio', value => {
  return fromStringToArrayRadio(value)
})
Vue.filter('fromStringToArraySelect', value => {
  return fromStringToArraySelect(value)
})
Vue.filter('fromStringToArray', value => {
  return fromStringToArray(value)
})
Vue.filter('removeLastOneChar', value => {
  return removeLastOneChar(value)
})
Vue.filter('removeLastTwoChar', value => {
  return removeLastTwoChar(value)
})
Vue.filter('removeLastThreeChar', value => {
  return removeLastThreeChar(value)
})
Vue.filter('sortByKey', value => {
  return sortByKey(value)
})
Vue.filter('toCapitalize', value => {
  return toCapitalize(value)
})
Vue.filter('toUncapitalise', value => {
  return toUncapitalise(value)
})
Vue.filter('toKebabCase', value => {
  return toKebabCase(value)
})
Vue.filter('toSnakeCase', value => {
  return toSnakeCase(value)
})
Vue.filter('toUnicase', value => {
  return toUnicase(value)
})
Vue.filter('toString', value => {
  return toString(value)
})
Vue.filter('toUrlQuery', value => {
  return toUrlQuery(value)
})
Vue.filter('toLocaleDateTime', value => {
  return toLocaleDateTime(value)
})
Vue.filter('toISODate', value => {
  return toISODate(value)
})
