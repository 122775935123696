<template>
    <div class="filter__search">
        <form-text
            :class="['form__text--search']"
            :label="''"
            :name="'search-field'"
            :value="value"
            :icon="true"
            :icon-name="'search'"
            :placeholder="searchPlaceholder"
            @input="emitInput"
        />
    </div>
</template>

<script>
import FormText from '@/components/default/forms/FormText'

export default {
  name: 'filter-search',
  components: { FormText },
  props: {
    value: {
      type: String,
      default: ''
    },
    searchPlaceholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('emit-input', value)
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/ebs/filters/filtersearch";
</style>
