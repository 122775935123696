import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: []
}

const lmsSuperadminWorkspaceRegisterKeys = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkspaceRegisterKeysData: state => {
      return state.data
    },
    getLmsSuperadminWorkspaceRegisterKeysPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEYS_GET_DATA (state, payload) {
      const keysData = payload.keys
      const orgUnitsNames = payload.orgUnitsWithNames
      const groupsNames = payload.groupsWithNames
      let payloadData = JSON.parse(JSON.stringify(keysData))

      for (let item of payloadData) {
        if (item.orgUnits && item.orgUnits.list && item.orgUnits.list.constructor === Array) {
          item.orgUnits.list = item.orgUnits.list.map(mObj => {
            const orgUnit = orgUnitsNames.find(fObj => fObj.id === mObj)
            return {
              name: orgUnit ? orgUnit.name : '',
              id: mObj
            }
          })
        }
        if (item.groups && item.groups.list && item.groups.list.constructor === Array) {
          item.groups.list = item.groups.list.map(mObj => {
            const group = groupsNames.find(fObj => fObj.id === mObj)
            return {
              name: group ? group.name : '',
              id: mObj
            }
          })
        }
      }

      state.data = JSON.parse(JSON.stringify(payloadData))
    },
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEYS_GET_PAGINATION (state, payload) {
      state.pagination = JSON.parse(JSON.stringify(payload))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEYS_CLEAR_DATA (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEYS_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsSuperadminWorkspaceKeysGet ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const workspaceId = data && data.requestData && data.requestData.workspaceId ? data.requestData.workspaceId : getters.getAuthUserSecondStageTokenDataWorkspaceId

        commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEYS_CLEAR_DATA')
        commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEYS_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.sso}/self-registration/workspace-keys/${workspaceId}${queryUrlParams}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEYS_GET_DATA', response.data.data)
          commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEYS_GET_PAGINATION', response.data.pagination)

          resolve(response.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    //
    lmsSuperadminWorkspaceKeysClearData ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEYS_CLEAR_DATA')
    },
    lmsSuperadminWorkspaceKeysClearPagination ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEYS_CLEAR_PAGINATION')
    }
  }
}

export default lmsSuperadminWorkspaceRegisterKeys
