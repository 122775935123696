import i18n from '@/i18n'

const notifications = {
  duplicated_name_error: {
    createVenue: i18n.t('notifications.createVenue'),
    updateVenue: i18n.t('notifications.updateVenue'),
    createRoom: i18n.t('notifications.createRoom'),
    updateRoom: i18n.t('notifications.updateRoom')
  },
  booking_onto_session: {
    sessionAlreadyStarted: i18n.t('notifications.sessionAlreadyStarted'),
    sessionFullyBooked: i18n.t('notifications.sessionFullyBooked'),
    learnerEnrolment: i18n.t('notifications.learnerEnrolment'),
    sessionNoLongerAccepting: i18n.t('notifications.sessionNoLongerAccepting')
  },
  booking_validation_error: {
    BookingValidatorEventHasNoCourses: i18n.t('notifications.bookingValidatorEventHasNoCourses'),
    BookingValidatorFullyBooked: i18n.t('notifications.bookingValidatorFullyBooked'),
    BookingValidatorAlreadyStarted: i18n.t('notifications.bookingValidatorAlreadyStarted'),
    BookingValidatorLearnerAlreadyBooked: i18n.t('notifications.bookingValidatorLearnerAlreadyBooked'),
    BookingValidatorNoLongerAccepting: i18n.t('notifications.bookingValidatorNoLongerAccepting'),
    BookingValidatorTrainerNotAllowed: i18n.t('notifications.bookingValidatorTrainerNotAllowed'),
    BookingValidatorLmsUnavailable: i18n.t('notifications.bookingValidatorLmsUnavailable'),
    BookingValidatorProfileExpirationDate: i18n.t('notifications.BookingValidatorProfileExpirationDate'),
    BookingValidatorLearnerEnrolment: i18n.t('notifications.bookingValidatorLearnerEnrolment')
  }
}

export default notifications
