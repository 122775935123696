<template>
    <div class="component__pagination">
        <ul class="component__pagination_list">
            <li
                class="component__pagination_list_item"
                v-for="(page, index) in pages"
                :key="index"
            >
                <template v-if="page === 'blank'">
                    <span class="component__pagination_list_item_forcepage">
                        <form-text
                            :name="'component-pagination-force-page'"
                            :placeholder="'...'"
                            :label="''"
                            :v-validate="{ min_value: 1, max_value: rawPages }"
                            :data-vv-as="'page'"
                            v-model="forcePage"
                        ></form-text>
                    </span>
                </template>
                <template v-else>
                    <component-button
                        class="component__pagination_list_item_button"
                        :disabled="disabled"
                        :theme="currentPage === page ? 'primary' : 'default'"
                        :name="'component-pagination-list-item-button'"
                        @click="emitPage(page)"
                    >{{ page }}</component-button>
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
import { debounce } from '@/functions'
import FormText from '@/components/default/forms/FormText'

export default {
  name: 'component-pagination',
  components: {
    FormText,
    ComponentButton: () => import('@/components/default/shared/ComponentButton')
  },
  data () {
    return {
      forcePage: ''
    }
  },
  props: {
    pagination: {
      type: [Object, String],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage () {
      return Number(this.pagination.page)
    },
    pageSize () {
      return Number(this.pagination.pageSize)
    },
    totalResults () {
      return Number(this.pagination.totalResults)
    },
    rawPages () {
      return this.totalResults && this.pageSize ? Math.ceil(this.totalResults / this.pageSize) : 0
    },
    pages () {
      let pages = []
      for (let page = 1; page <= this.rawPages; page++) {
        if (
          page === this.currentPage ||
            page === 1 ||
            page === this.rawPages ||
            (
              page > this.currentPage &&
                page < (this.currentPage + 3)
            ) ||
            (
              page < this.currentPage &&
                page > (this.currentPage - 3)
            )
        ) {
          pages.push(page)
        } else if (
          (
            page === 2 &&
                this.currentPage > 4
          ) ||
            (
              page === (this.rawPages - 1) &&
                this.currentPage < (this.rawPages - 3)
            )
        ) {
          pages.push('blank')
        }
      }
      return pages
    }
  },
  watch: {
    forcePage: debounce(function (value) {
      if (!value) return

      this.$validator.validate('component-pagination-force-page').then((result) => {
        if (result) {
          this.emitPage(value)
        }
      })
    }, 800)
  },
  methods: {
    emitPage (value) {
      this.$emit('emit-page', value)
    }
  },
  provide () {
    return {
      $validator: this.$validator
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentpagination";
</style>
