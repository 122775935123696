<template>
    <div :class="[
    'component',
    'component__datepicker',
    disabled ? 'component__datepicker--disabled' : ''
    ]">
        <template v-if="label">
            <div class="component__datepicker_date">
                <span class="component__datepicker_date_label">{{ label }}:</span> {{ formattedValue }}
            </div>
        </template>
        <datepicker
            ref="datepicker"
            :disabled="disabled"
            :highlighted="highlighted"
            :inline="true"
            :value="value"
            :open-date="openDate"
            :full-month-name="true"
            :monday-first="true"
            @selected="datepickerSelected"
            @input="datepickerInput"
            @changedMonth="datepickerChangedMonth"
        />
        <div class="component__datepicker_note">
            <component-note v-if="note">
                {{ note }}
            </component-note>
        </div>
        <template v-if="closeButtonActive">
            <div class="component__datepicker_close">
                <div class="columns is-variable">
                    <div class="column"></div>
                    <div class="column is-narrow">
                        <component-button
                            :theme="'default'"
                            class="component__datepicker_close_button"
                            @click="closeDatepicker"
                            :name="'component-datepicker-date-label-close'"
                        >{{ $t('shared.components.buttons.close') }}
                        </component-button>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import ComponentNote from "@/components/default/shared/ComponentNote.vue";

export default {
  name: 'component-datepicker',
  components: {
    ComponentNote,
    Datepicker,
    ComponentButton: () => import('@/components/default/shared/ComponentButton')
  },
  data () {
    return {
      openDate: null
    }
  },
  props: {
    label: {
      type: String
    },
    note: {
      type: String
    },
    highlighted: {
      type: Object
    },
    value: {
      type: [Date, String]
    },
    openFromMonth: {
      type: [Date, String]
    },
    openToMonth: {
      type: [Date, String]
    },
    closeButtonActive: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedValue () {
      let date = ''
      if (!this.value) return date
      date = new Date(this.value).toLocaleDateString('en-gb', {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      })
      return date
    }
  },
  watch: {
    openFromMonth (value) {
      if (value && value > this.$refs.datepicker.pageDate) {
        this.openDate = value
      }
    },
    openToMonth (value) {
      if (value && value < this.$refs.datepicker.pageDate) {
        this.openDate = value
      }
    }
  },
  methods: {
    datepickerInput (date) {
      this.$emit('datepicker-input', date)
    },
    datepickerSelected (date) {
      const dateISO = new Date(date).toISOString()
      this.$emit('datepicker-selected', dateISO)
    },
    datepickerChangedMonth (object) {
      this.$emit('changed-month', object)
    },
    closeDatepicker (date) {
      this.$emit('close-datepicker', date)
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentdatepicker";
</style>
