<template>
    <div class="header__top_account_signup">
        <component-button
            :class="['header__top_nav_item', 'header__top_nav_item--signup']"
            :to="{ name: 'auth-signup' }"
            :theme="'default'"
            :name="'header-top-account-signup-button'"
        >{{ $t('header.topAccountNav.menu.signUp') }}
        </component-button>
    </div>
</template>

<script>
import ComponentButton from '@/components/default/shared/ComponentButton'
export default {
  name: 'header-top-account-signup',
  components: { ComponentButton }
}
</script>
