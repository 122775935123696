import axios from 'axios'
import api from '@/api'
import { sortByKey } from '@/functions'

const lmsUserWorkspaces = {
  state: {
    data: ''
  },
  getters: {
    getLmsUserWorkspaces: state => {
      return state.data
    },
    getLmsUserWorkspacesGroupedByCustomer: state => {
      const workspaces = state.data
      let customers = []

      for (let workspace of workspaces) {
        let indexOfWorkspaceCustomer = customers.findIndex(obj => obj.name === workspace.customerName)

        if (indexOfWorkspaceCustomer > -1) {
          customers[indexOfWorkspaceCustomer].workspaces.push(workspace)
        } else {
          customers.push({
            name: workspace.customerName,
            workspaces: [workspace]
          })
        }
      }

      return customers
    }
  },
  mutations: {
    LMS_USER_SET_WORKSPACES (state, payload) {
      const profiles = JSON.parse(JSON.stringify(payload))
      let profilesFiltered = []

      for (let profile of profiles) {
        if (!profile.hasCustomerRole || profiles.indexOf(obj => obj.id === profile.id) === -1) {
          profilesFiltered.push(profile)
        }
      }

      state.data = sortByKey(profilesFiltered, 'name')
    },
    LMS_USER_CLEAR_WORKSPACES (state) {
      state.data = ''
    }
  },
  actions: {
    lmsUserGetWorkspaces ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('LMS_USER_CLEAR_WORKSPACES')
        const userId = getters.getAuthUserFirstStageTokenDataID || getters.getAuthUserSecondStageTokenDataUserId
        return axios({
          method: 'get',
          url: `${api.sso}/v2/user-workspaces/${userId}`
        }).then(function (response) {
          commit('LMS_USER_SET_WORKSPACES', response.data)
          resolve(response)
        }).catch(function (error) {
          reject(error.response)
        })
      })
    }
  }
}

export default lmsUserWorkspaces
