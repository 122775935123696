import axios from 'axios'
import api from '@/api'
import { eventbus } from '@/main'
import { User } from '@/User'

const data = {
  data: {
    createdAt: '',
    id: '',
    message: '',
    updatedAt: '',
    userId: ''
  }
}

const defaultInductionMessages = {
  state: {
    data: JSON.parse(JSON.stringify(data.data))
  },
  getters: {
    getDefaultInductionMessagesIsMessageActive: state => routeName => {
      const user = new User()
      const messageItems = state.data.message
      const message = messageItems.length ? messageItems.find(obj => obj.routeName === routeName) : undefined
      return user.isTokenFromNewLms() ? (message ? message.active : true) : false
    },
    getDefaultInductionMessagesId: state => {
      return state.data.id
    }
  },
  mutations: {
    DEFAULT_INDUCTION_MESSAGES_CLEAR: (state) => {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    DEFAULT_INDUCTION_MESSAGES_GET: (state, payload) => {
      payload.message = payload.message ? JSON.parse(payload.message) : []
      state.data = payload
    }
  },
  actions: {
    defaultInductionMessagesInitialize ({ state, dispatch, getters }) {
      const user = new User()
      if (
        (
          state.data.message === '' ||
      state.data.message === undefined ||
      (
        state.data.userId &&
      state.data.userId !== getters.getAuthUserSecondStageTokenDataUserId
      )
        ) &&
      user.isTokenFromNewLms()
      ) {
        dispatch('defaultInductionMessagesGet')
      }
    },
    defaultInductionMessagesGet ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('DEFAULT_INDUCTION_MESSAGES_CLEAR')

        axios({
          method: 'get',
          url: `${api.sso}/v2/userOnboardingMessage`
        }).then(response => {
          commit('DEFAULT_INDUCTION_MESSAGES_GET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    defaultInductionMessagesSet ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const messages = JSON.parse(JSON.stringify(state.data.message))
        const existingItemIndex = messages.length && messages.constructor === Array ? messages.findIndex(obj => obj.routeName === data.routeName) : -1

        if (existingItemIndex > -1) {
          messages[existingItemIndex].active = data.active
        } else {
          messages.push({
            routeName: data.routeName,
            type: data.type,
            active: data.active
          })
        }

        axios({
          method: 'post',
          url: `${api.sso}/v2/userOnboardingMessage`,
          data: {
            message: JSON.stringify(messages)
          }
        }).then(response => {
          commit('DEFAULT_INDUCTION_MESSAGES_GET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    defaultInductionMessagesReset ({ dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.sso}/v2/userOnboardingMessage`,
          data: {
            message: ''
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Onboarding reset',
            message: 'The onboarding has been reset.',
            status: 'success'
          })
          dispatch('defaultInductionMessagesGet').finally(() => {
            eventbus.$emit('reset-onboarding-message')
            resolve(response)
          })
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default defaultInductionMessages
