import store from '@/store'
import notifications from '@/notifications'
import i18n from '@/i18n'

export function setResponseNotification (response, notificationCode = '', notificationCodeProcess = '') {
  let message = response.data.errors.message
  const checkItemInUse = message.includes('item_in_use')
  const checkNotificationCode = notifications[notificationCode]

  if (checkItemInUse) {
    let outputMessage
    let outputMessageItems = ''
    let outputMessageTypes = ''
    let url = response.config.url.slice(response.config.url.indexOf('api/') + 4)
    let data = response.data.data
    let requested = url.slice(0, url.indexOf('/'))
    requested = requested.lastIndexOf('s') === (requested.length - 1) ? requested.slice(0, -1) : ''

    let dataTypes = []
    let dataItems = []

    for (let item of data) {
      if (dataTypes.indexOf(item.type) === -1) {
        dataTypes.push(item.type)
      }
    }
    for (let index in dataTypes) {
      dataItems.push({
        type: dataTypes[index],
        items: data.filter(obj => obj.type === dataTypes[index])
      })
    }
    for (let index in dataItems) {
      outputMessageItems += dataItems[index].type + ': '
      outputMessageTypes += dataItems[index].type
      for (let indexIn in dataItems[index].items) {
        outputMessageItems += `"${dataItems[index].items[indexIn].name}"`
        if (indexIn < (dataItems[index].items.length - 1)) {
          outputMessageItems += ', '
        }
      }
      if (index < (dataItems.length - 2)) {
        outputMessageItems += i18n.t('notificationsHelpers.the')
        outputMessageTypes += i18n.t('notificationsHelpers.comma')
      } else if (index < (dataItems.length - 1)) {
        outputMessageItems += i18n.t('notificationsHelpers.andThe')
        outputMessageTypes += i18n.t('notificationsHelpers.and')
      }
    }

    outputMessage = `${i18n.t('notificationsHelpers.ToThis')} ${requested} ${i18n.t('notificationsHelpers.hasBeenSetOnThe')} ${outputMessageItems}${i18n.t('notificationsHelpers.pleaseRemove')} ${outputMessageTypes} ${i18n.t('notificationsHelpers.before')}`
    store.dispatch('defaultNotificationToastSetErrorRequest', outputMessage)
  } else if (checkNotificationCode) {
    store.dispatch('defaultNotificationToastSetErrorRequest', notifications[notificationCode][notificationCodeProcess])
  } else {
    store.dispatch('defaultNotificationToastSetErrorRequest', message)
  }
}

export function setResponseValidateNotification (response) {
  let errors = []
  let errorsExtra = []

  let responseErrorCode = response.data.errors.code
  let responseErrorMessage = response.data.errors.message
  let responseErrors = response.data.data

  if (responseErrors) {
    for (let index in responseErrors) {
      let code = responseErrors[index].name
      let hint

      if (notifications[responseErrorCode][code]) {
        errors.push(notifications[responseErrorCode][code])
      }

      switch (code) {
        case 'BookingValidatorEventHasNoCourses':
          hint = i18n.t('notificationsHelpers.hintContact')
          break
        case 'BookingValidatorFullyBooked':
        case 'BookingValidatorAlreadyStarted':
        case 'BookingValidatorNoLongerAccepting':
        case 'BookingValidatorTrainerNotAllowed':
          hint = i18n.t('notificationsHelpers.hintBook')
          break
        case 'BookingValidatorLearnerEnrolment':
          hint = i18n.t('notificationsHelpers.hintEnsure')
          break
        case 'BookingValidatorLmsUnavailable':
          hint = i18n.t('notificationsHelpers.hindRefresh')
          break
        case 'BookingValidatorProfileExpirationDate':
          hint = i18n.t('notificationsHelpers.hintProfileExpired')
          break
        default:
          break
      }

      if (hint && errorsExtra.indexOf(hint) === -1) {
        errorsExtra.push(hint)
      }
    }
  } else {
    errors.push(responseErrorMessage)
  }

  store.dispatch('setValidateBookingNotification', { errors, errorsExtra })
  return { errors, errorsExtra }
}
