<template>
    <div
        class="header__top_account_menu_box"
        :class="[
            'header__top_account_menu_box',
            accountMenuBoxActive ? 'header__top_account_menu_box--active' : 'header__top_account_menu_box--inactive',
            accountMenuBoxTouched ? 'header__top_account_menu_box--touched' : ''
        ]"
    >
        <template v-if="accountMenuBoxActive">
            <header-top-account-menu-box-details/>
            <div class="is-hidden-mobile">
                <component-separator/>
            </div>
            <nav aria-label="User menu">
                <header-top-account-menu-box-nav :nav-items="[{ name: 'My profile', routeName: 'lms-user-account-personal' }]" v-if="user.isTokenFromNewLms()"/>
                <header-top-account-menu-box-enrolmentrequests v-if="user.isTokenFromNewLms() && (user.isLearningExperienceAdministrator() || user.isManager())"/>
                <header-top-account-menu-box-nav :nav-items="[{ name: 'Support guide', method: redirectToSupportGuide, icon: 'external-link' }]" v-if="user.isTokenFromNewLms()"/>
            </nav>
            <header-top-account-menu-box-workspace v-if="user.isTokenFromNewLms()"/>
            <header-top-account-menu-box-actions/>
        </template>
    </div>
</template>

<script>
import HeaderTopAccountMenuBoxNav from '@/components/default/layout/header/HeaderTopAccountMenuBoxNav'
import HeaderTopAccountMenuBoxWorkspace from '@/components/default/layout/header/HeaderTopAccountMenuBoxAccess'
import HeaderTopAccountMenuBoxActions from '@/components/default/layout/header/HeaderTopAccountMenuBoxActions'
import HeaderTopAccountMenuBoxDetails from '@/components/default/layout/header/HeaderTopAccountMenuBoxDetails'
import HeaderTopAccountMenuBoxEnrolmentrequests from '@/components/default/layout/header/HeaderTopAccountMenuBoxEnrolmentrequests'
import { User } from '@/User'
import ComponentSeparator from '@/components/default/shared/ComponentSeparator.vue'

export default {
  name: 'header-top-account-menu-box',
  components: { ComponentSeparator, HeaderTopAccountMenuBoxEnrolmentrequests, HeaderTopAccountMenuBoxDetails, HeaderTopAccountMenuBoxActions, HeaderTopAccountMenuBoxWorkspace, HeaderTopAccountMenuBoxNav },
  data () {
    return {
      user: new User()
    }
  },
  props: {
    accountMenuBoxActive: {
      type: Boolean
    },
    accountMenuBoxTouched: {
      type: Boolean
    }
  },
  methods: {
    redirectToSupportGuide () {
      window.open('https://formguide.melearning.co.uk/hc/en-gb', '_blank')
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/layout/header/headertopaccountmenubox";
</style>
