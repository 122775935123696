<template>
    <div class="header__top_account_menu_box_details">
        <div
            v-if="userName"
            class="header__top_account_menu_box_details_name"
        >{{ userName }}
        </div>
        <div
            v-if="userEmail"
            class="header__top_account_menu_box_details_email"
        >{{ userEmail }}
        </div>
        <div class="header__top_account_menu_box_details_roles">
            <component-badge-role
                v-if="user.isSuperadmin()"
                :role="'superadmin'"
                :type="'third'"
            ></component-badge-role>
            <component-badge-role
                v-if="user.isSiteAdmin()"
                :role="'master_administrator'"
                :type="'second'"
            ></component-badge-role>
            <component-badge-role
                v-if="user.isTrainer()"
                :role="'trainer'"
                :type="'second'"
            ></component-badge-role>
            <template v-if="userRoles && userRoles.length">
                <component-badge-role
                    v-for="(role, roleIndex) in userRoles"
                    :key="roleIndex"
                    :role="role"
                    :type="'first'"
                ></component-badge-role>
            </template>
        </div>
    </div>
</template>

<script>
import { User } from '@/User'
import ComponentBadgeRole from '@/components/default/shared/ComponentBadgeRole'

export default {
  name: 'header-top-account-menu-box-details',
  data () {
    return {
      user: new User()
    }
  },
  components: { ComponentBadgeRole },
  computed: {
    userMasteradminCustomerName () {
      return this.$store.getters.getAuthUserSecondStageTokenDataCustomerName
    },
    userName () {
      return this.$store.getters.getAuthUserSecondStageTokenDataNameOutput
    },
    userRoles () {
      return this.$store.getters.getAuthUserSecondStageTokenDataProfileRolesFiltered
    },
    userEmail () {
      return this.$store.getters.getAuthUserSecondStageTokenDataUserEmail
    }
  },
  methods: {
    userLogOut () {
      this.$store.dispatch('authUserLogout')
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/layout/header/headertopaccountmenuboxdetails";
</style>
