import axios from 'axios'
import api from '@/api'
import router from '@/router'

const data = {
  data: {
    customers: [],
    courses: [],
    validFromDate: null,
    validToDate: null,
    numberOfLicences: null,
    contract: '',
    courseId: '',
    id: '',
    offeredBy: '',
    offeredTo: '',
    status: '',
    frontStatus: '',
    versionId: ''
  },
  default: '',
  custom: {
    description: ''
  }
}

const ebsLicence = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsLicence: state => {
      return state.data
    },
    getEbsLicenceCustomers: state => {
      return state.data.customers
    },
    getEbsLicenceCourses: state => {
      const coursesOutput = state.data.courses.map(obj => obj.courseId)
      return coursesOutput
    },
    getEbsLicenceFromDate: state => {
      return state.data.validFromDate
    },
    getEbsLicenceToDate: state => {
      return state.data.validToDate
    },
    getEbsLicenceNumberOf: state => {
      return state.data.numberOfLicences
    },
    getEbsLicenceId: state => {
      return state.data.id
    },
    getEbsLicenceStatus: state => {
      return state.data.status
    },
    getEbsLicenceFrontStatus: state => {
      return state.data.frontStatus
    },
    getEbsLicenceLatestStateHistoryDescription: state => {
      const latestStateHistory = state.data.latestStateHistory
      return latestStateHistory ? latestStateHistory.description : null
    },
    getEbsLicenceCustomDescription: state => {
      return state.custom.description
    },
    getEbsLicenceOffers: state => {
      return state.data.offers
    }
  },
  mutations: {
    EBS_LICENCE_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    EBS_LICENCE_SET (state, payload) {
      state.data = payload
    },
    EBS_LICENCE_SET_CUSTOMERS (state, payload) {
      const customersOutput = payload.map(obj => obj.value)
      state.data.customers = customersOutput
    },
    EBS_LICENCE_SET_COURSES (state, payload) {
      const coursesOutput = payload.map(obj => {
        return {
          courseId: obj.value,
          courseName: obj.customLabel,
          versionId: obj.customValue || obj.extraData
        }
      })
      state.data.courses = coursesOutput
    },
    EBS_LICENCE_SET_NUMBER_OF_LICENCES (state, payload) {
      let numberOfLicences

      if (payload.constructor === Number) {
        numberOfLicences = payload
      } else {
        if (payload === 'no-limit') {
          numberOfLicences = null
        } else {
          numberOfLicences = 1
        }
      }

      state.data.numberOfLicences = numberOfLicences
    },
    EBS_LICENCE_SET_DATE_FROM (state, payload) {
      state.data.validFromDate = payload
    },
    EBS_LICENCE_SET_DATE_TO (state, payload) {
      state.data.validToDate = payload
    },
    EBS_LICENCE_SET_CUSTOM_DESCRIPTION (state, payload) {
      state.custom.description = payload
    }
  },
  actions: {
    ebsLicenceGet ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/offers/${id}`
        }).then(response => {
          commit('EBS_LICENCE_SET', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsLicenceCreate ({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        const stateData = JSON.parse(JSON.stringify(state.data))
        const customers = stateData.customers
        const courses = stateData.courses
        const validFromDate = stateData.validFromDate
        const validToDate = stateData.validToDate
        const numberOfLicences = stateData.numberOfLicences

        let offersArr = []

        for (let customer of customers) {
          for (let course of courses) {
            offersArr.push({
              offeredTo: customer,
              courseId: course.courseId,
              courseName: course.courseName,
              versionId: course.versionId,
              validFromDate: validFromDate,
              validToDate: validToDate,
              numberOfLicences: numberOfLicences
            })
          }
        }

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/bulk-offers`,
          data: offersArr
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence create',
            message: `Licence has been successfully created.`,
            status: 'success'
          })
          router.push({ name: 'ebs-licence-list' })
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsLicenceAccept ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/offer/${data}/accept`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence accept',
            message: `Licence has been successfully accepted.`,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence accept',
            message: `Licence hasn't been accepted.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    ebsLicenceSuspend ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const description = state.custom.description
        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/offer/${data}/suspend`,
          data: {
            description
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence suspend',
            message: `Licence has been successfully suspended.`,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence suspend',
            message: `Licence hasn't been suspended.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    ebsLicenceRestore ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const description = state.custom.description
        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/offer/${data}/restore`,
          data: {
            description
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence restore',
            message: `Licence has been successfully restored.`,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence restore',
            message: `Licence hasn't been restored.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    ebsLicenceArchive ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const description = state.custom.description
        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/offer/${data}/archive`,
          data: {
            description
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence archive',
            message: `Licence has been successfully archived.`,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence archive',
            message: `Licence hasn't been archived.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    ebsLicenceReject ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/offer/${data}/reject`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence reject',
            message: `Licence has been successfully rejected.`,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence reject',
            message: `Licence hasn't been rejected.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    ebsLicenceCancel ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/offer/${data}/cancel`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence cancel',
            message: `Licence has been successfully cancelled.`,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Licence cancel',
            message: `Licence hasn't been cancelled.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    ebsLicenceClear ({ commit }) {
      commit('EBS_LICENCE_CLEAR')
    },
    ebsLicenceSetCustomers ({ commit }, data) {
      commit('EBS_LICENCE_SET_CUSTOMERS', data)
    },
    ebsLicenceSetCourses ({ commit }, data) {
      commit('EBS_LICENCE_SET_COURSES', data)
    },
    ebsLicenceSetNumberOfLicences ({ commit }, data) {
      commit('EBS_LICENCE_SET_NUMBER_OF_LICENCES', data)
    },
    ebsLicenceSetDateFrom ({ commit }, data) {
      commit('EBS_LICENCE_SET_DATE_FROM', data)
    },
    ebsLicenceSetDateTo ({ commit }, data) {
      commit('EBS_LICENCE_SET_DATE_TO', data)
    },
    ebsLicenceSetCustomDescription ({ commit }, data) {
      commit('EBS_LICENCE_SET_CUSTOM_DESCRIPTION', data)
    }
  }
}

export default ebsLicence
