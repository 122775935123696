<template>
    <div v-if="(accessedWorkspaceId && userHasMoreThanOneProfile) || accessedCustomerRoleArea || !accessedWorkspaceId || user.isTrainer() || user.isSiteAdmin() || user.isSuperadmin()">
        <div class="is-hidden-mobile">
            <component-separator/>
        </div>
        <div class="header__top_account_menu_box_access">
            <div class="columns is-vcentered">
                <div class="column">
                    <template v-if="accessedWorkspaceId">
                        <div class="columns is-vcentered is-variable is-2">
                            <div class="column">
                                <div class="header__top_account_menu_box_access_workspacename">{{ workspaceName }}</div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="accessedCustomerRoleArea">
                        <div class="header__top_account_menu_box_access_workspacename">{{ accessedCustomerRoleArea }} area</div>
                    </template>
                    <template v-else>
                        {{ $t('header.topAccountMenuBox.titles.noWorkspaceSelected') }}
                    </template>
                </div>
                <div class="column is-narrow">
                    <component-button
                        :disabled="user.isInpersonated()"
                        :theme="'primary'"
                        :text="true"
                        :underline="true"
                        :size="''"
                        :to="{ name: 'lms-user-workspace-list' }"
                        :name="'header-top-account-menu-box-access-workspacename-button'"
                    >{{ accessedWorkspaceId || accessedCustomerRoleArea ? 'Change' : $t('header.topAccountMenuBox.buttons.choose') }}
                    </component-button>
                </div>
            </div>
        </div>
        <component-separator/>
    </div>
</template>

<script>
import ComponentSeparator from '@/components/default/shared/ComponentSeparator'
import ComponentButton from '@/components/default/shared/ComponentButton'
import { User } from '@/User'
import * as Cookies from 'js-cookie'
import { toCapitalize } from '@/functions'

export default {
  name: 'header-top-account-menu-box-access',
  components: { ComponentButton, ComponentSeparator },
  data () {
    return {
      user: new User()
    }
  },
  computed: {
    accessedCustomerRoleArea () {
      const customerRoleArea = Cookies.get('customerRoleArea')

      return customerRoleArea ? toCapitalize(this.user.getProfileRolesLibraryName(customerRoleArea)) : null
    },
    accessedWorkspaceId () {
      return this.$store.getters.getAuthUserSecondStageTokenDataWorkspaceId
    },
    workspaceName () {
      return this.$store.getters.getAuthUserSecondStageTokenDataWorkspaceName
    },
    workspaceNameShort () {
      return this.workspaceName.substr(0, 2).toUpperCase()
    },
    userHasMoreThanOneProfile () {
      const userProfiles = this.$store.getters.getLmsSuperadminUserProfiles

      return userProfiles && userProfiles.length > 1
    }
  },
  methods: {
    userLogOut () {
      this.$store.dispatch('authUserLogout')
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/layout/header/headertopaccountmenuboxaccess";
</style>
