import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { User } from '@/User'
import { fromLocalDateTimeToUTCISOCustom, fromUTCDateTimeToLocalISOCustom, toLocaleDateTime } from '@/functions'
import { checkResponseNotFound } from '@/interceptorsHelpers'
import { maxNumber } from '@/variables'

const data = {
  data: {
    acceptanceBooking: '',
    bookedLearners: [],
    bookingInfo: {
      acceptationAllowed: false,
      acceptationDate: '',
      cancellationAllowed: false,
      cancellationDate: ''
    },
    bookingSettings: {
      minBookingLimit: 0,
      // fixed/roomBased
      maxBookingLimitType: 'roomBased',
      maxBookingLimit: 0,
      bookingAcceptanceLimit: 0,
      // hour/day
      allowNoEnrolments: false,
      sendReminders: true,
      reminderInterval: 1,
      reminderIntervalType: 'day',
      bookingAcceptanceLimitType: 'hour',
      bookingFormRequired: false,
      bookingForm: {
        version: 'timestamp',
        id: 'uuid',
        fields: []
      }
    },
    cancellationSettings: {
      selfCancellationAllowed: false,
      selfCancellationLimit: 0,
      // hour/day
      selfCancellationLimitType: 'hour',
      cancellationReasonRequired: false,
      cancellationReason: '',
      cancellationReasonCustomEnabled: false,
      autoCancellationEnabled: false,
      autoCancellationLimit: 0,
      // hour/day
      autoCancellationLimitType: 'hour'
    },
    completionSettings: {
      // attendance/assessment
      completionType: 'attendance',
      enableRegisters: true,
      registersEditByAnyCoordinator: true,
      registersEditByTrainers: true,
      trainerFeedbackRequired: false,
      absenceReasonRequired: false,
      absenceReason: '',
      feedbackForm: {
        version: 'timestamp',
        id: 'uuid',
        fields: []
      }
    },
    costsSettings: {
      sectorServiceStructure: [],
      costsEnabled: false,
      costsValue: 1,
      overridesEnabled: false,
      costsSettingsOverrides: []
    },
    digitalRegisterSummaryStatus: '',
    domain: '',
    enableTrainers: false,
    eventCoordinators: [],
    eventCourses: [],
    eventId: '',
    fromDate: '',
    id: '',
    isTrainer: false,
    occurrenceItems: [
      {
        date: '',
        datetimeStart: '',
        datetimeEnd: '',
        dateStartLocal: '',
        dateEndLocal: '',
        digitalRegisterStatus: '',
        id: '',
        occurrence: '',
        roomId: '',
        room: {
          id: '',
          name: '',
          location: ''
        },
        timeStartLocal: '',
        timeEndLocal: '',
        trainers: [
          {
            id: '',
            trainerId: '',
            name: ''
          }
        ],
        userAttendenceData: {
          absentReason: '',
          attendance: ''
        },
        venueId: '',
        venue: {
          id: '',
          name: '',
          address: {
            id: '',
            formatted_address: '',
            address_1: '',
            address_2: '',
            city: '',
            county: '',
            postcode: '',
            country: '',
            lat: 0,
            lng: 0
          }
        },
        meetingLink: '',
        platform: '',
        virtualAccessDetails: ''
      }
    ],
    rooms: [],
    sessionInfo: {
      endDate: '',
      hasEnded: false,
      inProgress: false,
      startDate: ''
    },
    sessionStatus: '',
    similarSessions: '',
    spaces: {
      limit: 0,
      available: 0
    },
    status: 'draft',
    subscriptions: [],
    toDate: '',
    trainers: [],
    userBookingStatus: '',
    userCompletionStatus: '',
    userSubscription: '',
    isActive: false,
    isArchived: false,
    isCompleted: false,
    assessmentRegistryFilled: false,
    assessmentRegistryCompleted: false,
    attendanceReadyForCompletion: false,
    attendanceRegistryCompleted: false,
    sessionType: 'face_to_face',
    sendNotificationTo: null
  },
  default: {},
  custom: {
    cancellationSuccess: '',
    requestInProgress: false,
    expiredUsers: []
  }
}

const ebsSession = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getSession: state => {
      return state.data
    },
    //
    getSessionId: state => {
      return state.data.id
    },
    getSessionIsArchived: state => {
      return state.data.isArchived || false
    },
    getSessionSessionType: state => {
      return state.data.sessionType
    },
    getSessionEventId: state => {
      return state.data.eventId
    },
    getSessionEventName: state => {
      return state.data.eventName
    },
    getSessionEventCoordinatorsId: (state, getters) => {
      const coordinators = state.data.eventCoordinators
      const isSecondStageTokenOldLMS = getters.getAuthUserIsSecondStageTokenOldLMS
      let coordinatorsIds
      if (coordinators.length) {
        coordinatorsIds = coordinators.map(coordinator => {
          return isSecondStageTokenOldLMS ? coordinator.id : coordinator.userId
        })
      }
      return coordinatorsIds
    },
    getSessionStart: state => {
      let occurrenceStart
      let occurrence
      let occurrenceDate
      let occurrenceTime
      if (state.data.occurrenceItems.length) {
        occurrence = state.data.occurrenceItems[0]
        occurrenceDate = occurrence.date
        occurrenceTime = occurrence.timeStartLocal
        occurrenceStart = new Date(`${occurrenceDate}T${occurrenceTime}`)
      }
      return occurrenceStart
    },
    getSessionEnableTrainers: state => {
      return state.data.enableTrainers
    },
    getSessionStatus: state => {
      return state.data.status
    },
    getSessionFromDate: state => {
      return state.data.fromDate
    },
    getSessionToDate: state => {
      return state.data.toDate
    },
    getSessionDates: state => {
      const fromDate = state.data.fromDate
      const toDate = state.data.toDate
      if (fromDate && toDate) {
        if (fromDate === toDate) {
          return new Date(fromDate).toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: '2-digit' })
        } else {
          return new Date(fromDate).toLocaleDateString('en-gb', {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
          }) + ' - ' + new Date(toDate).toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: '2-digit' })
        }
      }
    },
    getSessionSpaces: state => {
      return state.data.spaces
    },
    getSessionSpacesTextOutput: state => {
      if (state.data.spaces.available === 0) {
        return 'No spaces available'
      } else if (state.data.bookingSettings.maxBookingLimitType === 'unlimited') {
        return `${state.data.spaces.booked} ${state.data.spaces.booked === 1 ? 'person has' : 'people have'} booked`
      } else {
        return `${state.data.spaces.available} / ${state.data.spaces.limit}` + (state.data.spaces.available !== 1 ? ` spaces` : ` space`) + ` available`
      }
    },
    getSessionSpacesAvailable: state => {
      return state.data.spaces.available
    },
    getSessionTrainers: state => {
      return state.data.trainers
    },
    getSessionTrainersLmsIds: state => {
      let trainers = state.data.trainers
      let trainersArray = []
      for (let i = 0; i < trainers.length; i++) {
        trainersArray[i] = trainers[i].lmsId.id
      }
      return trainersArray
    },
    getSessionBookedLearners: state => {
      return state.data.bookedLearners
    },
    getSessionBookedLearnersActive: state => {
      const learnersArr = state.data.bookedLearners
      let learnersActive = []
      for (let i = 0; i < learnersArr.length; i++) {
        if (learnersArr[i].bookingStatus === 'booked' ||
        learnersArr[i].bookingStatus === 'accepted' ||
        learnersArr[i].bookingStatus === 'completed' ||
        learnersArr[i].bookingStatus === 'passed' ||
        learnersArr[i].bookingStatus === 'session_cancelled') {
          learnersActive.push(learnersArr[i])
        }
      }
      return learnersActive
    },
    getSessionBookedLearnersFromOtherWorkspaces: (state, getters) => workspaceId => {
      const bookedLearners = getters.getSessionBookedLearners

      return bookedLearners.filter(obj => obj.workspaceId !== workspaceId && obj.bookingStatus === 'booked')
    },
    getSessionIsTrainer: state => {
      return state.data.isTrainer
    },
    getSessionIsCompleted: state => {
      return state.data.isCompleted === 'true' || state.data.isCompleted === true
    },
    getSessionIsIsActive: state => {
      return state.data.isActive
    },
    getSessionIsIsArchived: state => {
      return state.data.isArchived
    },
    getSessionIsAssessmentRegistryFilled: state => {
      return state.data.assessmentRegistryFilled
    },
    getSessionIsAssessmentRegistryCompleted: state => {
      return state.data.assessmentRegistryCompleted
    },
    getSessionIsAttendanceReadyForCompletion: state => {
      return state.data.attendanceReadyForCompletion
    },
    getSessionIsAttendanceRegistryCompleted: state => {
      return state.data.attendanceRegistryCompleted
    },
    getSessionDigitalRegisterSummaryStatus: state => {
      return state.data.digitalRegisterSummaryStatus
    },
    getSessionUserBookingId: state => {
      return state.data.userBookingId
    },
    getSessionUserSubscription: state => {
      return state.data.userSubscription
    },
    getSessionUserIsSubscribed: state => {
      return !!state.data.userSubscription
    },
    getSessionSubscriptions: state => {
      const subscriptionsArr = state.data.subscriptions
      let newSubscriptionsArr = []
      newSubscriptionsArr = subscriptionsArr.map(function (obj) {
        let objItem = obj
        objItem.bookingStatus = 'on-waitlist'
        return objItem
      })
      return newSubscriptionsArr
    },
    getSessionHasSubscriptions: (state, getters) => {
      const subscriptions = getters.getSessionSubscriptions

      return Boolean(subscriptions.length)
    },
    getSessionDatetimeEnd: state => {
      const index = state.data.occurrenceItems.length - 1
      return fromUTCDateTimeToLocalISOCustom(state.data.occurrenceItems[index].datetimeEnd)
    },
    getSessionSendNotificationTo: state => {
      return state.data.sendNotificationTo
    },
    //
    getSessionDefaultStatus: state => {
      return state.default.status
    },
    //
    getSessionOccurrenceItems: state => {
      return state.data.occurrenceItems
    },
    getSessionOccurrenceItemTrainers: (state) => (index) => {
      const trainers = state.data.occurrenceItems[index].trainers
      let trainersId = []
      for (let i = 0; i < trainers.length; i++) {
        trainersId[i] = trainers[i].trainerId
      }
      return trainersId
    },
    //
    getSessionBookingInfoAcceptationAllowed: state => {
      return state.data.bookingInfo.acceptationAllowed
    },
    getSessionBookingInfoAcceptationDate: state => {
      return state.data.bookingInfo.acceptationDate
    },
    getSessionBookingInfoCancellationAllowed: state => {
      return state.data.bookingInfo.cancellationAllowed
    },
    getSessionBookingInfoCancellationDate: state => {
      return state.data.bookingInfo.cancellationDate
    },
    //
    getSessionSessionInfoEndDate: state => {
      return state.data.sessionInfo.endDate
    },
    getSessionSessionInfoHasEnded: state => {
      return state.data.sessionInfo.hasEnded
    },
    getSessionSessionInfoInProgress: state => {
      return state.data.sessionInfo.inProgress
    },
    getSessionSessionInfoStartDate: state => {
      return state.data.sessionInfo.startDate
    },
    //
    getSessionBookingMinLimit: state => {
      return state.data.bookingSettings.minBookingLimit
    },
    getSessionBookingMaxLimit: state => {
      return state.data.bookingSettings.maxBookingLimit
    },
    getSessionBookingLimitType: state => {
      return state.data.bookingSettings.maxBookingLimitType
    },
    getSessionBookingFormRequired: state => {
      return state.data.bookingSettings.bookingFormRequired
    },
    getSessionBookingFormFields: state => {
      return state.data.bookingSettings.bookingForm.fields
    },
    getSessionBookingAcceptanceLimit: state => {
      return state.data.bookingSettings.bookingAcceptanceLimit
    },
    getSessionBookingAcceptanceLimitType: state => {
      return state.data.bookingSettings.bookingAcceptanceLimitType
    },
    getSessionBookingAllowNoEnrolments: state => {
      return state.data.bookingSettings.allowNoEnrolments
    },
    getSessionBookingSendReminders: state => {
      return state.data.bookingSettings.sendReminders
    },
    getSessionBookingReminderInterval: state => {
      return state.data.bookingSettings.reminderInterval
    },
    getSessionBookingReminderIntervalType: state => {
      return state.data.bookingSettings.reminderIntervalType
    },
    //
    getSessionCancellationSelfAllowed: state => {
      return state.data.cancellationSettings.selfCancellationAllowed
    },
    getSessionCancellationSelfLimit: state => {
      return state.data.cancellationSettings.selfCancellationLimit
    },
    getSessionCancellationSelfLimitType: state => {
      return state.data.cancellationSettings.selfCancellationLimitType
    },
    getSessionCancellationReasonRequired: state => {
      return state.data.cancellationSettings.cancellationReasonRequired
    },
    getSessionCancellationReason: state => {
      return state.data.cancellationSettings.cancellationReason
    },
    getSessionCancellationReasonOther: state => {
      return state.data.cancellationSettings.cancellationReasonCustomEnabled
    },
    getSessionCancellationAutoEnabled: state => {
      return state.data.cancellationSettings.autoCancellationEnabled
    },
    getSessionCancellationAutoLimit: state => {
      return state.data.cancellationSettings.autoCancellationLimit
    },
    getSessionCancellationAutoLimitType: state => {
      return state.data.cancellationSettings.autoCancellationLimitType
    },
    //
    getSessionCompletionType: state => {
      return state.data.completionSettings.completionType
    },
    getSessionCompletionEnableRegisters: state => {
      return state.data.completionSettings.enableRegisters
    },
    getSessionCompletionRegistersEditByAnyCoordinator: state => {
      return state.data.completionSettings.registersEditByAnyCoordinator
    },
    getSessionCompletionRegistersEditByTrainers: state => {
      return state.data.completionSettings.registersEditByTrainers
    },
    getSessionCompletionTrainerFeedbackRequired: state => {
      return state.data.completionSettings.trainerFeedbackRequired
    },
    getSessionCompletionFormFields: state => {
      return state.data.completionSettings.feedbackForm.fields
    },
    getSessionCompletionAbsenceReasonRequired: state => {
      return state.data.completionSettings.absenceReasonRequired
    },
    getSessionCompletionAbsenceReason: state => {
      return state.data.completionSettings.absenceReason
    },
    //
    getSessionCostsEnabled: state => {
      return state.data.costsSettings.costsEnabled
    },
    getSessionCostsValue: state => {
      return state.data.costsSettings.costsValue
    },
    getSessionCostsOverridesEnabled: state => {
      return state.data.costsSettings.overridesEnabled
    },
    getSessionCostsSettingsOverrides: state => {
      return state.data.costsSettings.costsSettingsOverrides
    },
    //
    getSessionUserBookingStatus: state => {
      return state.data.userBookingStatus
    },
    getSessionUserCompletionStatus: state => {
      return state.data.userCompletionStatus
    },
    getSessionPeople: (state, getters) => {
      const bookedLearners = getters.getSessionBookedLearners
      const subscriptions = getters.getSessionSubscriptions
      return bookedLearners.concat(subscriptions)
    },
    //
    getSessionHasUserBookingStatusBooked: state => {
      return state.data.userBookingStatus === 'booked'
    },
    getSessionHasUserActiveBooking: (state, getters) => {
      return (
        getters.getSessionUserBookingId &&
      (
        getters.getSessionUserBookingStatus === 'booked' ||
      getters.getSessionUserBookingStatus === 'accepted' ||
      getters.getSessionUserBookingStatus === 'in_progress' ||
      getters.getSessionUserBookingStatus === 'passed'
      )
      )
    },
    getSessionHasBookedLearnersActive: (state, getters) => {
      const learnersActive = getters.getSessionBookedLearnersActive
      return Boolean(learnersActive.length)
    },
    getSessionHasSpacesAvailable: state => {
      return state.data.spaces.available > 0
    },
    getSessionHasBookingForm: (state, getters) => {
      return (getters.getSessionBookingFormRequired && !!getters.getSessionBookingFormFields.length)
    },
    getSessionEventCourses: (state) => {
      return state.data.eventCourses
    },
    getSessionEventHasCourses: (state) => {
      return state.data.eventCourses ? Boolean(state.data.eventCourses.length) : false
    },
    getSessionSimilarSessions: (state) => {
      return state.data.similarSessions
    },
    getSessionSimilarSessionsMappedIdDatesRoomsTrainersSpaces: state => {
      const rawItems = state.data.similarSessions
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        let dateFrom = toLocaleDateTime(fromUTCDateTimeToLocalISOCustom(item.dateFrom))
        let dateTo = toLocaleDateTime(fromUTCDateTimeToLocalISOCustom(item.dateTo))
        let dates
        let rooms = ''
        let trainers = ''

        if (dateFrom === dateTo) {
          dates = dateFrom
        } else {
          dates = `${dateFrom} - ${dateTo}`
        }

        for (let index in item.trainers) {
          trainers += index > 0 ? `, ${item.trainers[index]}` : item.trainers[index]
        }

        for (let index in item.rooms) {
          rooms += index > 0 ? `, ${item.rooms[index].venue} - ${item.rooms[index].room}` : `${item.rooms[index].venue} - ${item.rooms[index].room}`
        }

        if (dateFrom === dateTo) {
          dates = dateFrom
        } else {
          dates = `${dateFrom} - ${dateTo}`
        }
        return {
          id: item.id,
          dates: dates,
          rooms: rooms,
          trainers: trainers,
          availableSpaces: item.availableSpaces
        }
      })
    },
    //
    getSessionAllowEdit: (state, getters) => {
      const hasBookings = getters.getSessionHasBookedLearnersActive
      const ended = getters.getSessionSessionInfoHasEnded
      const status = getters.getSessionDefaultStatus

      return !ended && (status === 'draft' || !hasBookings)
    },
    getSessionAllowRemove: (state, getters) => {
      const status = getters.getSessionDefaultStatus

      return status === 'cancelled' || status === 'draft'
    },
    getSessionAllowEditStatus: (state, getters) => {
      const hasBookings = getters.getSessionHasBookedLearnersActive
      const status = getters.getSessionDefaultStatus

      return !hasBookings && status === 'upcoming'
    },
    getSessionAllowBulkBookInfo: (state, getters) => {
      const completionType = getters.getSessionCompletionType

      return completionType === 'automatic'
    },
    getSessionAllowBulkBookPrivilege: (state, getters) => {
      const user = new User()
      const isEventCoordinator = user.isEventCoordinator()
      const isSessionTrainer = user.isSessionTrainer(getters.getSessionTrainers)
      const isManager = user.isManager()

      return isEventCoordinator || isSessionTrainer || isManager
    },
    getSessionAllowBulkBookAction: (state, getters) => {
      const allowPrivilege = getters.getSessionAllowBulkBookPrivilege
      const completionType = getters.getSessionCompletionType
      const eventStatus = getters.getEventStatus
      const sessionStatus = getters.getSessionDefaultStatus

      return (
        allowPrivilege &&
      (
        sessionStatus === 'upcoming' ||
        sessionStatus === 'draft' ||
      sessionStatus === 'in_progress' ||
      (
        sessionStatus === 'ended' &&
      completionType !== 'automatic'
      )
      ) &&
      eventStatus === 'active'
      )
    },
    getSessionAllowValidate: (state, getters) => {
      const eventStatus = getters.getEventStatus
      const sessionStatus = getters.getSessionDefaultStatus

      return eventStatus === 'active' && sessionStatus === 'upcoming'
    },
    getSessionAllowSelfCreateBooking: (state, getters) => {
      const eventIsAssignedToCourse = getters.getEventUsedInCourseId
      const eventStatus = getters.getEventStatus
      const sessionStatus = getters.getSessionDefaultStatus
      const bookingStatus = getters.getSessionUserBookingStatus

      return (
        (
          bookingStatus === 'not-booked' ||
      bookingStatus === 'cancelled'
        ) &&
      eventStatus === 'active' &&
      sessionStatus === 'upcoming' &&
      eventIsAssignedToCourse
      )
    },
    getSessionAllowSelfCancelBooking: (state, getters) => {
      const sessionStatus = getters.getSessionDefaultStatus
      const bookingStatus = getters.getSessionUserBookingStatus
      const allowCancel = getters.getSessionCancellationSelfAllowed

      return (
        sessionStatus === 'upcoming' &&
      bookingStatus === 'booked' &&
      allowCancel
      )
    },
    getSessionAllowPrint: (state, getters) => {
      const hasBookings = getters.getSessionHasBookedLearnersActive

      return hasBookings
    },
    getSessionAllowCancelSession: (state, getters) => {
      const status = getters.getSessionStatus
      return status === 'draft' || status === 'upcoming'
    },
    //
    getSessionCustomCancellationSuccess: (state) => {
      return state.custom.cancellationSuccess
    },
    getSessionCustomExpiredUsers: (state) => {
      return state.custom.expiredUsers
    }
  },
  mutations: {
    GET_SESSION (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))
      payloadData.similarSessions = []
      for (let i = 0; i < payload.occurrenceItems.length; i++) {
        const dateTimeStart = fromUTCDateTimeToLocalISOCustom(payload.occurrenceItems[i].datetimeStart)
        const dateTimeEnd = fromUTCDateTimeToLocalISOCustom(payload.occurrenceItems[i].datetimeEnd)
        const dateStart = dateTimeStart.split(' ')[0]

        payloadData.occurrenceItems[i].date = dateStart

        payloadData.occurrenceItems[i].dateStartLocal = dateTimeStart.split(' ')[0]
        payloadData.occurrenceItems[i].dateEndLocal = dateTimeEnd.split(' ')[0]
        payloadData.occurrenceItems[i].timeStartLocal = dateTimeStart.split(' ')[1]
        payloadData.occurrenceItems[i].timeEndLocal = dateTimeEnd.split(' ')[1]

        payloadData.occurrenceItems[i].meetingLink = payloadData.occurrenceItems[i].meetingLink || ''
        payloadData.occurrenceItems[i].platform = payloadData.occurrenceItems[i].platform || ''
        payloadData.occurrenceItems[i].virtualAccessDetails = payloadData.occurrenceItems[i].virtualAccessDetails || ''
        payloadData.occurrenceItems[i].venueId = payloadData.occurrenceItems[i].venue.venueId
      }
      payloadData.sessionType = payloadData.sessionType || data.data.sessionType
      if (payloadData.bookingSettings.maxBookingLimitType === 'fixed' && payloadData.bookingSettings.maxBookingLimit === maxNumber) {
        payloadData.bookingSettings.maxBookingLimitType = 'unlimited'
      }
      payloadData.sendNotificationTo = null

      state.data = payloadData
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    //
    CLEAR_SESSION (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    PRELOAD_SESSION (state, payload) {
      const venueObjectData = payload.ebsVenues.data
      const venueObjectDataArray = []
      if (venueObjectData) {
        venueObjectData.forEach(function (object) {
          object.rooms.forEach(function (room) {
            let newRoom = {
              roomId: room.id,
              venueId: object.id
            }
            venueObjectDataArray.push(newRoom)
          })
        })
      }
      const venueObject = venueObjectDataArray.filter(obj => {
        return obj.roomId === payload.ebsEvent.data.occurrenceSettings.defaultLocation.id
      })
      let trainersArray = []
      for (let i = 0; i < payload.ebsEvent.data.occurrenceSettings.defaultTrainers.length; i++) {
        trainersArray[i] = { trainerId: payload.ebsEvent.data.occurrenceSettings.defaultTrainers[i].trainerId }
      }
      const eventDefaultLocation = payload.ebsEvent.data.occurrenceSettings.defaultLocation.id
      state.data.sendNotificationTo = null
      state.data.occurrenceItems = [
        {
          roomId: eventDefaultLocation,
          venueId: venueObject.length ? venueObject[0].venueId : '',
          date: '',
          dateStartLocal: '',
          dateEndLocal: '',
          timeStartLocal: '',
          timeEndLocal: '',
          trainers: trainersArray,
          meetingLink: payload.ebsEvent.data.occurrenceSettings.meetingLink,
          platform: payload.ebsEvent.data.occurrenceSettings.platform,
          virtualAccessDetails: payload.ebsEvent.data.occurrenceSettings.virtualAccessDetails
        }
      ]
      //
      state.data.sessionType = payload.ebsEvent.data.occurrenceSettings.sessionType
      state.data.eventId = payload.ebsEvent.data.id
      const eventEnableTrainers = payload.ebsEvent.data.occurrenceSettings.enableTrainers
      if (eventEnableTrainers) {
        state.data.enableTrainers = eventEnableTrainers
      }
      const eventBookingSettings = payload.ebsEvent.data.bookingSettings
      if (eventBookingSettings) {
        state.data.bookingSettings = eventBookingSettings
      }
      const eventCancellationSettings = payload.ebsEvent.data.cancellationSettings
      if (eventCancellationSettings) {
        state.data.cancellationSettings = eventCancellationSettings
      }
      const eventCompletionSettings = payload.ebsEvent.data.completionSettings
      if (eventCompletionSettings) {
        state.data.completionSettings = eventCompletionSettings
      }
      const eventCostsSettings = payload.ebsEvent.data.costsSettings
      if (eventCostsSettings) {
        state.data.costsSettings = eventCostsSettings
      }
      const eventCourses = payload.ebsEvent.data.courses
      if (eventCourses) {
        state.data.eventCourses = eventCourses
      }
    },
    RESET_SESSION (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    SET_SESSION_EVENTID (state, payload) {
      state.data.eventId = payload
    },
    SET_SESSION_DOMAIN (state, payload) {
      state.data.domain = payload
    },
    SET_SESSION_OCCURRENCEITEM (state) {
      state.data.occurrenceItems.push(
        {
          roomId: '',
          venueId: '',
          date: '',
          dateStartLocal: '',
          dateEndLocal: '',
          timeStartLocal: '',
          timeEndLocal: '',
          trainers: [
            ''
          ],
          meetingLink: '',
          platform: '',
          virtualAccessDetails: ''
        }
      )
    },
    DUPLICATE_SESSION_OCCURRENCEITEM (state) {
      let lastOccurrence = JSON.parse(JSON.stringify(state.data.occurrenceItems[state.data.occurrenceItems.length - 1]))
      lastOccurrence.id = undefined

      state.data.occurrenceItems.push(lastOccurrence)
    },
    REMOVE_SESSION_OCCURRENCEITEM (state, key) {
      state.data.occurrenceItems.splice(key, 1)
    },
    SET_SESSION_OCCURRENCEITEM_DATE (state, payload) {
      state.data.occurrenceItems[payload.index].date = payload.data
    },
    SET_SESSION_OCCURRENCEITEM_DATESTARTLOCAL (state, payload) {
      state.data.occurrenceItems[payload.index].dateStartLocal = payload.data
    },
    SET_SESSION_OCCURRENCEITEM_DATEENDLOCAL (state, payload) {
      state.data.occurrenceItems[payload.index].dateEndLocal = payload.data
    },
    SET_SESSION_OCCURRENCEITEM_TIMESTARTLOCAL (state, payload) {
      state.data.occurrenceItems[payload.index].timeStartLocal = payload.data
    },
    SET_SESSION_OCCURRENCEITEM_TIMEENDLOCAL (state, payload) {
      state.data.occurrenceItems[payload.index].timeEndLocal = payload.data
    },
    SET_SESSION_OCCURRENCEITEM_MEETINGLINK (state, payload) {
      state.data.occurrenceItems[payload.index].meetingLink = payload.data
    },
    SET_SESSION_OCCURRENCEITEM_PLATFORM (state, payload) {
      state.data.occurrenceItems[payload.index].platform = payload.data
    },
    SET_SESSION_OCCURRENCEITEM_DETAILS (state, payload) {
      state.data.occurrenceItems[payload.index].virtualAccessDetails = payload.data
    },
    SET_SESSION_OCCURRENCEITEM_DATETIMESTART (state, payload) {
      const date = state.data.occurrenceItems[payload].dateStartLocal
      const time = state.data.occurrenceItems[payload].timeStartLocal
      if (!(date && time)) return
      const initDate = date + 'T' + time
      const outputDate = fromLocalDateTimeToUTCISOCustom(initDate)
      state.data.occurrenceItems[payload].datetimeStart = outputDate
    },
    SET_SESSION_OCCURRENCEITEM_DATETIMEEND (state, payload) {
      const date = state.data.occurrenceItems[payload].dateEndLocal
      const time = state.data.occurrenceItems[payload].timeEndLocal
      if (!(date && time)) return
      const initDate = date + 'T' + time
      const outputDate = fromLocalDateTimeToUTCISOCustom(initDate)
      state.data.occurrenceItems[payload].datetimeEnd = outputDate
    },
    SET_SESSION_OCCURRENCEITEM_ROOMID (state, payload) {
      state.data.occurrenceItems[payload.index].roomId = payload.data.value
    },
    SET_SESSION_OCCURRENCEITEM_ROOMID_ALL (state, payload) {
      for (let i = 0; i < state.data.occurrenceItems.length; i++) {
        if (typeof payload !== 'undefined') {
          state.data.occurrenceItems[i].roomId = state.data.occurrenceItems[payload].roomId
        } else {
          if (state.data.occurrenceItems[0].roomId) {
            state.data.occurrenceItems[i].roomId = state.data.occurrenceItems[0].roomId
          }
        }
      }
    },
    SET_SESSION_OCCURRENCEITEM_VENUEID (state, payload) {
      state.data.occurrenceItems[payload.index].venueId = payload.data
    },
    SET_SESSION_OCCURRENCEITEM_VENUEID_ALL (state, payload) {
      for (let i = 0; i < state.data.occurrenceItems.length; i++) {
        if (typeof payload !== 'undefined') {
          state.data.occurrenceItems[i].venueId = state.data.occurrenceItems[payload].venueId
        } else {
          if (state.data.occurrenceItems[0].venueId) {
            state.data.occurrenceItems[i].venueId = state.data.occurrenceItems[0].venueId
          }
        }
      }
    },
    SET_SESSION_OCCURRENCEITEM_VENUEID_UPDATE (state) {
      for (let i = 0; i < state.data.occurrenceItems.length; i++) {
        if (!state.data.occurrenceItems[i].venueId) {
          if (state.data.occurrenceItems[i].venue) {
            state.data.occurrenceItems[i].venueId = state.data.occurrenceItems[i].venue.venueId
          } else {
            state.data.occurrenceItems[i].venueId = state.data.occurrenceItems[0].venue.venueId
          }
        }
      }
    },
    SET_SESSION_OCCURRENCEITEM_TRAINERSID (state, payload) {
      let trainers = []
      for (let i = 0; i < payload.data.length; i++) {
        trainers.push({ trainerId: payload.data[i].value })
      }
      state.data.occurrenceItems[payload.index].trainers = trainers
    },
    SET_SESSION_OCCURRENCEITEM_TRAINERSID_ALL (state, payload) {
      for (let i = 0; i < state.data.occurrenceItems.length; i++) {
        if (typeof payload !== 'undefined') {
          state.data.occurrenceItems[i].trainers = state.data.occurrenceItems[payload].trainers
        } else {
          if (state.data.occurrenceItems[0].trainers) {
            state.data.occurrenceItems[i].trainers = state.data.occurrenceItems[0].trainers
          }
        }
      }
    },
    SET_SESSION_ENABLETRAINERS (state, payload) {
      state.data.enableTrainers = payload
    },
    SET_SESSION_SESSIONTYPE (state, payload) {
      state.data.sessionType = payload
    },
    SET_SESSION_STATUS (state, payload) {
      state.data.status = payload
    },
    SET_SESSION_SIMILARSESSIONS (state, payload) {
      state.data.similarSessions = payload
    },
    SET_SESSION_SENDNOTIFICATIONTO (state, payload) {
      let sendNotificationTo = JSON.parse(JSON.stringify(state.data.sendNotificationTo))
      const sendNotificationToPayloadIndex = sendNotificationTo ? sendNotificationTo.findIndex(obj => obj === payload) : -1

      if (sendNotificationTo === null && payload) {
        sendNotificationTo = []
      }

      if (sendNotificationToPayloadIndex > -1) {
        sendNotificationTo.splice(sendNotificationToPayloadIndex, 1)
      } else {
        sendNotificationTo.push(payload)
      }

      state.data.sendNotificationTo = sendNotificationTo && sendNotificationTo.length ? sendNotificationTo : null
    },
    //
    SET_SESSION_BOOKINGMINLIMIT (state, payload) {
      state.data.bookingSettings.minBookingLimit = Number(payload)
    },
    SET_SESSION_BOOKINGMAXLIMIT (state, payload) {
      state.data.bookingSettings.maxBookingLimit = Number(payload)
    },
    SET_SESSION_BOOKINGLIMITTYPE (state, payload) {
      state.data.bookingSettings.maxBookingLimitType = payload
    },
    SET_SESSION_BOOKINGACCEPTANCEPERIOD (state, payload) {
      state.data.bookingSettings.bookingAcceptanceLimit = Number(payload)
    },
    SET_SESSION_BOOKINGACCEPTANCEPERIODTYPE (state, payload) {
      state.data.bookingSettings.bookingAcceptanceLimitType = payload
    },
    SET_SESSION_BOOKINGFORMREQUIRED (state, payload) {
      state.data.bookingSettings.bookingFormRequired = payload
    },
    SET_SESSION_BOOKINGFORMFIELD (state, payload) {
      state.data.bookingSettings.bookingForm.fields.push(payload)
    },
    MOVE_SESSION_BOOKINGFORMFIELD (state, payload) {
      const arr = state.data.bookingSettings.bookingForm.fields
      const item = arr[payload.from]
      if (payload.to !== -1) {
        state.data.bookingSettings.bookingForm.fields.splice(payload.from, 1)
        state.data.bookingSettings.bookingForm.fields.splice(payload.to, 0, item)
      }
    },
    UPDATE_SESSION_BOOKINGFORMFIELD (state, payload) {
      state.data.bookingSettings.bookingForm.fields.splice(payload.index, 1)
      state.data.bookingSettings.bookingForm.fields.splice(payload.index, 0, payload.data)
    },
    REMOVE_SESSION_BOOKINGFORMFIELD (state, payload) {
      state.data.bookingSettings.bookingForm.fields.splice(payload, 1)
    },
    SET_SESSION_BOOKINGALLOWNOENROLMENT (state, payload) {
      state.data.bookingSettings.allowNoEnrolments = payload
    },
    SET_SESSION_BOOKINGSENDREMINDERS (state, payload) {
      state.data.bookingSettings.sendReminders = payload
    },
    SET_SESSION_BOOKINGREMINDERINTERVAL (state, payload) {
      state.data.bookingSettings.reminderInterval = payload
    },
    SET_SESSION_BOOKINGREMINDERINTERVALTYPE (state, payload) {
      state.data.bookingSettings.reminderIntervalType = payload
    },
    //
    SET_SESSION_CANCELLATIONSELFALLOWED (state, payload) {
      state.data.cancellationSettings.selfCancellationAllowed = payload
    },
    SET_SESSION_CANCELLATIONSELFLIMIT (state, payload) {
      state.data.cancellationSettings.selfCancellationLimit = payload
    },
    SET_SESSION_CANCELLATIONSELFLIMITTYPE (state, payload) {
      state.data.cancellationSettings.selfCancellationLimitType = payload
    },
    SET_SESSION_CANCELLATIONREASONREQUIRED (state, payload) {
      state.data.cancellationSettings.cancellationReasonRequired = payload
    },
    SET_SESSION_CANCELLATIONREASON (state, payload) {
      state.data.cancellationSettings.cancellationReason = payload
    },
    SET_SESSION_CANCELLATIONREASONOTHER (state, payload) {
      state.data.cancellationSettings.cancellationReasonCustomEnabled = payload
    },
    SET_SESSION_CANCELLATIONAUTOENABLED (state, payload) {
      state.data.cancellationSettings.autoCancellationEnabled = payload
    },
    SET_SESSION_CANCELLATIONAUTOLIMIT (state, payload) {
      state.data.cancellationSettings.autoCancellationLimit = payload
    },
    SET_SESSION_CANCELLATIONAUTOLIMITTYPE (state, payload) {
      state.data.cancellationSettings.autoCancellationLimitType = payload
    },
    //
    SET_SESSION_COMPLETIONTYPE (state, payload) {
      state.data.completionSettings.completionType = payload
    },
    SET_SESSION_COMPLETIONENABLEREGISTERS (state, payload) {
      state.data.completionSettings.enableRegisters = payload
    },
    SET_SESSION_COMPLETIONREGISTERSEDITBYANYCOORDINATOR (state, payload) {
      state.data.completionSettings.registersEditByAnyCoordinator = (payload === 'true')
    },
    SET_SESSION_COMPLETIONREGISTERSEDITBYTRAINERS (state, payload) {
      state.data.completionSettings.registersEditByTrainers = payload
    },
    SET_SESSION_COMPLETIONFORMREQUIRED (state, payload) {
      state.data.completionSettings.trainerFeedbackRequired = payload
    },
    SET_SESSION_COMPLETIONFORM (state, payload) {
      state.data.completionSettings.feedbackForm.fields.push(payload)
    },
    SET_SESSION_COMPLETIONABSENCEREASONREQUIRED (state, payload) {
      state.data.completionSettings.absenceReasonRequired = payload
    },
    SET_SESSION_COMPLETIONABSENCEREASON (state, payload) {
      state.data.completionSettings.absenceReason = payload
    },
    MOVE_SESSION_COMPLETIONFORMFIELD (state, payload) {
      const arr = state.data.completionSettings.feedbackForm.fields
      const item = arr[payload.from]
      if (payload.to !== -1) {
        state.data.completionSettings.feedbackForm.fields.splice(payload.from, 1)
        state.data.completionSettings.feedbackForm.fields.splice(payload.to, 0, item)
      }
    },
    UPDATE_SESSION_COMPLETIONFORMFIELD (state, payload) {
      state.data.completionSettings.feedbackForm.fields.splice(payload.index, 1)
      state.data.completionSettings.feedbackForm.fields.splice(payload.index, 0, payload.data)
    },
    REMOVE_SESSION_COMPLETIONFORMFIELD (state, payload) {
      state.data.completionSettings.feedbackForm.fields.splice(payload, 1)
    },
    //
    SET_SESSION_COSTSENABLED (state, payload) {
      state.data.costsSettings.costsEnabled = payload
    },
    SET_SESSION_COSTSVALUE (state, payload) {
      state.data.costsSettings.costsValue = payload
    },
    SET_SESSION_COSTSOVERRIDESENABLED (state, payload) {
      state.data.costsSettings.overridesEnabled = payload
    },
    SET_SESSION_COSTSSETTINGSOVERRIDES (state, payload) {
      const item = payload.item
      const value = payload.value
      const override = payload.override
      const overridesIndex = override.findIndex(overrideItem => overrideItem.referenceType === item.referenceType && Number(overrideItem.reference.id) === Number(item.reference.id))
      const overridesData = {
        referenceType: item.referenceType,
        reference: item.reference,
        value: value
      }
      if (overridesIndex > -1) {
        if (value) {
          state.data.costsSettings.costsSettingsOverrides[overridesIndex] = overridesData
        } else {
          state.data.costsSettings.costsSettingsOverrides[overridesIndex].value = null
        }
      } else {
        state.data.costsSettings.costsSettingsOverrides.push(overridesData)
      }
    },
    //
    SET_SESSION_CUSTOM_CANCELLATIONSUCCESS (state, payload) {
      state.custom.cancellationSuccess = payload
    },
    SET_SESSION_CUSTOM_REQUESTINPROGRESS (state, payload) {
      state.custom.requestInProgress = payload
    },
    SET_SESSION_CUSTOM_EXPIREDUSERS (state, payload) {
      state.custom.expiredUsers = payload
    }
  },
  actions: {
    getSession ({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        if (state.custom.requestInProgress) {
          return
        } else {
          commit('SET_SESSION_CUSTOM_REQUESTINPROGRESS', true)
        }
        commit('CLEAR_SESSION')
        axios({
          method: 'get',
          url: `${api.classroom}/occurrence/${id}`
        }).then(response => {
          commit('SET_SESSION_CUSTOM_REQUESTINPROGRESS', false)
          commit('GET_SESSION', response.data)
          resolve(response.data)
        }).catch(function (error) {
          commit('SET_SESSION_CUSTOM_REQUESTINPROGRESS', false)
          checkResponseNotFound(error.response.status)
          reject(error.response)
        })
      })
    },
    getSessionSimilarSessions ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.classroom}/available-occurrence/${id}`
        }).then(response => {
          commit('SET_SESSION_SIMILARSESSIONS', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    //
    clearSession ({ commit }) {
      commit('CLEAR_SESSION')
    },
    preloadSession ({ commit, rootState, dispatch }) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_EVENT')
        commit('CLEAR_SESSION')
        axios({
          method: 'get',
          url: `${api.classroom}/v2/events/${rootState.route.params.eventId}/settings`
        }).then(response => {
          commit('GET_EVENT', response.data)
          dispatch('getVenues').then(response => {
            commit('SET_VENUES', response.data.data)
            commit('PRELOAD_SESSION', rootState)
            resolve(response.data)
          }).catch(error => {
            reject(new Error(error))
          })
          resolve(response.data)
        }).catch(error => {
          reject(new Error(error))
        })
      })
    },
    resetSession (context) {
      context.commit('RESET_SESSION')
    },
    removeSession ({ commit, rootState, dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.classroom}/occurrence-archivization`,
          data: {
            occurrenceId: id
          }
        }).then(response => {
          commit('CLEAR_EVENT')
          commit('CLEAR_EVENTS')
          commit('CLEAR_REPORT_CALENDAR')
          dispatch('defaultNotificationToastsAdd', {
            title: 'Session archive',
            message: `Session has been successfully archived.`,
            status: 'success'
          })
          router.push({ name: 'ebs-event-show', params: { id: rootState.route.params.eventId } })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Session archive',
            message: `Session hasn't been archived`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    updateSession ({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        const defaultStatus = state.default.status
        const status = state.data.status
        const sessionId = state.data.id
        let stateData = JSON.parse(JSON.stringify(state.data))
        if (stateData.bookingSettings.maxBookingLimitType === 'unlimited') {
          stateData.bookingSettings.maxBookingLimitType = 'fixed'
          stateData.bookingSettings.maxBookingLimit = maxNumber
        }
        const updateSessionRequest = () => {
          return new Promise((resolve, reject) => {
            commit('SET_SESSION_OCCURRENCEITEM_VENUEID_UPDATE')

            axios({
              method: 'patch',
              url: `${api.classroom}/occurrence`,
              data: stateData
            }).then(response => {
              const responseData = response.data
              const expiredLearners = responseData.expiredLearners
              const expiredLearnersMappedEmails = expiredLearners && expiredLearners.length ? expiredLearners.map(obj => obj.emailAddress) : []

              dispatch('defaultNotificationToastsAdd', {
                title: 'Session update',
                message: `Session has been successfully updated.`,
                status: 'success'
              })
              commit('CLEAR_EVENTS')
              commit('CLEAR_REPORT_CALENDAR')
              commit('CLEAR_SERVICES')

              if (expiredLearnersMappedEmails && expiredLearnersMappedEmails.length) {
                commit('SET_SESSION_CUSTOM_EXPIREDUSERS', expiredLearnersMappedEmails)
              }

              resolve(response.data)
            }).catch(error => {
              dispatch('defaultNotificationToastsAdd', {
                title: 'Session update',
                message: `Session hasn't been updated.`,
                status: 'error'
              })
              reject(error)
            })
          })
        }
        const activateSessionRequest = () => {
          return new Promise((resolve, reject) => {
            axios({
              method: 'post',
              url: `${api.classroom}/occurrence-activation`,
              data: {
                occurrenceId: sessionId,
                active: status !== 'draft'
              }
            }).then(response => {
              resolve(response)
            }).catch(error => {
              reject(error)
            })
          })
        }

        if (defaultStatus !== status) {
          activateSessionRequest().then(() => {
            updateSessionRequest().then(response => {
              resolve(response)
            })
          }).catch(error => {
            reject(error)
          })
        } else {
          updateSessionRequest().then(response => {
            resolve(response)
          }).catch(error => {
            reject(error)
          })
        }
      })
    },
    createSession ({ commit, getters, rootState, state, dispatch }) {
      return new Promise((resolve, reject) => {
        const defaultStatus = state.default ? state.default.status : ''
        const status = state.data.status
        let stateData = JSON.parse(JSON.stringify(state.data))
        if (stateData.bookingSettings.maxBookingLimitType === 'unlimited') {
          stateData.bookingSettings.maxBookingLimitType = 'fixed'
          stateData.bookingSettings.maxBookingLimit = maxNumber
        }
        const createSessionOnResolve = (sessionId) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Session create',
            message: `Session has been successfully created.`,
            status: 'success',
            linkText: 'Go to session',
            linkTo: {
              name: 'ebs-session-show',
              params: { eventId: rootState.route.params.eventId, sessionId: sessionId }
            }
          })

          router.push({ name: 'ebs-event-show', params: { id: rootState.route.params.eventId } })
          commit('CLEAR_EVENT')
          commit('CLEAR_EVENTS')
          commit('CLEAR_REPORT_CALENDAR')
          commit('CLEAR_SERVICES')
        }

        const createSessionRequest = () => {
          return new Promise((resolve, reject) => {
            commit('SET_SESSION_EVENTID', rootState.route.params.eventId)
            commit('SET_SESSION_DOMAIN', getters.getAuthUserSecondStageTokenDataDomain)

            axios({
              method: 'post',
              url: `${api.classroom}/occurrence`,
              data: stateData
            }).then(response => {
              resolve(response)
            }).catch(error => {
              dispatch('defaultNotificationToastsAdd', {
                title: 'Session create',
                message: `Session hasn't been created.`,
                status: 'error'
              })
              reject(error)
            })
          })
        }
        const activateSessionRequest = (sessionId) => {
          return new Promise((resolve, reject) => {
            axios({
              method: 'post',
              url: `${api.classroom}/occurrence-activation`,
              data: {
                occurrenceId: sessionId,
                active: status !== 'draft'
              }
            }).then(response => {
              setTimeout(() => {
                resolve(response)
              }, 250)
            }).catch(error => {
              reject(error)
            })
          })
        }
        if (defaultStatus !== status) {
          createSessionRequest().then(response => {
            const sessionId = response.data.id
            activateSessionRequest(sessionId).then(response => {
              createSessionOnResolve(sessionId)
              resolve(response)
            })
          }).catch(error => {
            reject(error)
          })
        } else {
          createSessionRequest().then(response => {
            const sessionId = response.data.id
            createSessionOnResolve(sessionId)
            resolve(response)
          }).catch(error => {
            reject(error)
          })
        }
      })
    },
    cancelSession ({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const data = {
          occurrenceId: payload[0],
          targetOccurrenceId: payload[1]
        }

        axios({
          method: 'post',
          url: `${api.classroom}/cancel-occurrence`,
          data: data
        }).then(response => {
          const responseData = response.data
          const expiredLearners = responseData.expiredLearners
          const expiredLearnersMappedEmails = expiredLearners && expiredLearners.length ? expiredLearners.map(obj => obj.emailAddress) : []

          dispatch('defaultNotificationToastsAdd', {
            title: 'Session cancel',
            message: `Session has been successfully canceled.`,
            status: 'success'
          })
          commit('SET_SESSION_CUSTOM_CANCELLATIONSUCCESS', true)
          commit('CLEAR_EVENT')
          commit('CLEAR_EVENTS')
          commit('CLEAR_REPORT_CALENDAR')
          commit('CLEAR_SESSION')
          commit('CLEAR_SERVICES')

          if (expiredLearnersMappedEmails && expiredLearnersMappedEmails.length) {
            commit('SET_SESSION_CUSTOM_EXPIREDUSERS', expiredLearnersMappedEmails)
          }

          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Session cancel',
            message: `Session hasn't been canceled.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    printSessionFlyer (ctx, payload) {
      axios({
        method: 'GET',
        url: `${api.pdfprinter}/flyer/session/${payload}`,
        responseType: 'arraybuffer'
      }).then((response) => {
        if (!window.navigator.msSaveOrOpenBlob) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'session-flyer.pdf')
          document.body.appendChild(link)
          link.click()
        } else {
          window.navigator.msSaveOrOpenBlob(new Blob([response.data]), 'session-flyer.pdf')
        }
      })
    },
    checkSessionAssets (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.classroom}/events/check-assets`,
          data: payload
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    //
    setSessionSessionType (context, value) {
      context.commit('SET_SESSION_SESSIONTYPE', value)
    },
    setSessionStatus (context, value) {
      context.commit('SET_SESSION_STATUS', value)
    },
    setSessionOccurrenceItem (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM', data)
    },
    duplicateSessionOccurrenceItem (context, data) {
      context.commit('DUPLICATE_SESSION_OCCURRENCEITEM', data)
    },
    removeSessionOccurrenceItem (context, key) {
      context.commit('REMOVE_SESSION_OCCURRENCEITEM', key)
    },
    setSessionOccurrenceItemRoomId (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM_ROOMID', data)
    },
    setSessionOccurrenceItemRoomIdAll (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM_ROOMID_ALL', data)
    },
    setSessionOccurrenceItemVenueId (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM_VENUEID', data)
    },
    setSessionOccurrenceItemVenueIdAll (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM_VENUEID_ALL', data)
    },
    setSessionOccurrenceItemTrainersId (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM_TRAINERSID', data)
    },
    setSessionOccurrenceItemTrainersIdAll (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM_TRAINERSID_ALL', data)
    },
    setSessionOccurrenceItemDate (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM_DATE', data)
      context.commit('SET_SESSION_OCCURRENCEITEM_DATETIMESTART', data.index)
      context.commit('SET_SESSION_OCCURRENCEITEM_DATETIMEEND', data.index)
    },
    setSessionOccurrenceItemDateStart ({ commit, state }, data) {
      const occurrenceItemDateEnd = state.data.occurrenceItems[data.index].dateEndLocal
      commit('SET_SESSION_OCCURRENCEITEM_DATESTARTLOCAL', data)
      commit('SET_SESSION_OCCURRENCEITEM_DATETIMESTART', data.index)
      if (!occurrenceItemDateEnd || occurrenceItemDateEnd < data.data) {
        commit('SET_SESSION_OCCURRENCEITEM_DATEENDLOCAL', data)
        commit('SET_SESSION_OCCURRENCEITEM_DATETIMEEND', data.index)
      }
    },
    setSessionOccurrenceItemDateEnd ({ commit }, data) {
      commit('SET_SESSION_OCCURRENCEITEM_DATEENDLOCAL', data)
      commit('SET_SESSION_OCCURRENCEITEM_DATETIMEEND', data.index)
    },
    setSessionOccurrenceItemTimeStart (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM_TIMESTARTLOCAL', data)
      context.commit('SET_SESSION_OCCURRENCEITEM_DATETIMESTART', data.index)
    },
    setSessionOccurrenceItemTimeEnd (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM_TIMEENDLOCAL', data)
      context.commit('SET_SESSION_OCCURRENCEITEM_DATETIMEEND', data.index)
    },
    setSessionOccurrenceItemMeetingLink (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM_MEETINGLINK', data)
    },
    setSessionOccurrenceItemPlatform (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM_PLATFORM', data)
    },
    setSessionOccurrenceItemDetails (context, data) {
      context.commit('SET_SESSION_OCCURRENCEITEM_DETAILS', data)
    },
    setSessionEnableTrainers (context, data) {
      context.commit('SET_SESSION_ENABLETRAINERS', data)
    },
    setSessionSendNotificationTo (context, data) {
      context.commit('SET_SESSION_SENDNOTIFICATIONTO', data)
    },
    //
    setSessionBookingMinLimit (context, value) {
      context.commit('SET_SESSION_BOOKINGMINLIMIT', value)
    },
    setSessionBookingMaxLimit (context, value) {
      context.commit('SET_SESSION_BOOKINGMAXLIMIT', value)
    },
    setSessionBookingLimitType (context, value) {
      context.commit('SET_SESSION_BOOKINGLIMITTYPE', value)
      context.commit('SET_SESSION_BOOKINGMAXLIMIT', 1)
    },
    setSessionBookingFormRequired (context, value) {
      context.commit('SET_SESSION_BOOKINGFORMREQUIRED', value)
    },
    setSessionBookingAcceptancePeriod (context, value) {
      context.commit('SET_SESSION_BOOKINGACCEPTANCEPERIOD', value)
    },
    setSessionBookingAcceptancePeriodType (context, value) {
      context.commit('SET_SESSION_BOOKINGACCEPTANCEPERIODTYPE', value)
    },
    setSessionBookingFormField (context, value) {
      context.commit('SET_SESSION_BOOKINGFORMFIELD', value)
    },
    moveSessionBookingFormField (context, value) {
      context.commit('MOVE_SESSION_BOOKINGFORMFIELD', value)
    },
    setSessionBookingAllowNoEnrolments (context, value) {
      context.commit('SET_SESSION_BOOKINGALLOWNOENROLMENT', value)
    },
    updateSessionBookingFormField (context, value) {
      context.commit('UPDATE_SESSION_BOOKINGFORMFIELD', value)
    },
    removeSessionBookingFormField (context, value) {
      context.commit('REMOVE_SESSION_BOOKINGFORMFIELD', value)
    },
    //
    setSessionCancellationSelfAllowed (context, value) {
      context.commit('SET_SESSION_CANCELLATIONSELFALLOWED', value)
    },
    setSessionCancellationSelfLimit (context, value) {
      context.commit('SET_SESSION_CANCELLATIONSELFLIMIT', value)
    },
    setSessionCancellationSelfLimitType (context, value) {
      context.commit('SET_SESSION_CANCELLATIONSELFLIMITTYPE', value)
    },
    setSessionCancellationReasonRequired (context, value) {
      context.commit('SET_SESSION_CANCELLATIONREASONREQUIRED', value)
    },
    setSessionCancellationReason (context, value) {
      context.commit('SET_SESSION_CANCELLATIONREASON', value)
    },
    setSessionCancellationReasonOther (context, value) {
      context.commit('SET_SESSION_CANCELLATIONREASONOTHER', value)
    },
    setSessionCancellationAutoEnabled (context, value) {
      context.commit('SET_SESSION_CANCELLATIONAUTOENABLED', value)
    },
    setSessionCancellationAutoLimit (context, value) {
      context.commit('SET_SESSION_CANCELLATIONAUTOLIMIT', value)
    },
    setSessionCancellationAutoLimitType (context, value) {
      context.commit('SET_SESSION_CANCELLATIONAUTOLIMITTYPE', value)
    },
    setSessionBookingSendReminders (context, value) {
      context.commit('SET_SESSION_BOOKINGSENDREMINDERS', value)
    },
    setSessionBookingReminderInterval (context, value) {
      context.commit('SET_SESSION_BOOKINGREMINDERINTERVAL', value)
    },
    setSessionBookingReminderIntervalType (context, value) {
      context.commit('SET_SESSION_BOOKINGREMINDERINTERVALTYPE', value)
    },
    //
    setSessionCompletionType (context, value) {
      if (value === 'attendance') {
        context.commit('SET_SESSION_COMPLETIONENABLEREGISTERS', true)
      } else if (value === 'automatic') {
        context.commit('SET_SESSION_COMPLETIONENABLEREGISTERS', false)
      }
      context.commit('SET_SESSION_COMPLETIONTYPE', value)
    },
    setSessionCompletionEnableRegisters (context, value) {
      context.commit('SET_SESSION_COMPLETIONENABLEREGISTERS', value)
    },
    setSessionCompletionRegistersEditByAnyCoordinator (context, value) {
      context.commit('SET_SESSION_COMPLETIONREGISTERSEDITBYANYCOORDINATOR', value)
    },
    setSessionCompletionRegistersEditByTrainers (context, value) {
      context.commit('SET_SESSION_COMPLETIONREGISTERSEDITBYTRAINERS', value)
    },
    setSessionCompletionFormRequired (context, value) {
      context.commit('SET_SESSION_COMPLETIONFORMREQUIRED', value)
    },
    setSessionCompletionFormField (context, value) {
      context.commit('SET_SESSION_COMPLETIONFORM', value)
    },
    setSessionCompletionAbsenceReasonRequired (context, value) {
      context.commit('SET_SESSION_COMPLETIONABSENCEREASONREQUIRED', value)
    },
    setSessionCompletionAbsenceReason (context, value) {
      context.commit('SET_SESSION_COMPLETIONABSENCEREASON', value)
    },
    moveSessionCompletionFormField (context, value) {
      context.commit('MOVE_SESSION_COMPLETIONFORMFIELD', value)
    },
    updateSessionCompletionFormField (context, value) {
      context.commit('UPDATE_SESSION_COMPLETIONFORMFIELD', value)
    },
    removeSessionCompletionFormField (context, value) {
      context.commit('REMOVE_SESSION_COMPLETIONFORMFIELD', value)
    },
    //
    setSessionCostsEnabled (context, value) {
      context.commit('SET_SESSION_COSTSENABLED', value)
    },
    setSessionCostsValue (context, value) {
      context.commit('SET_SESSION_COSTSVALUE', value)
    },
    setSessionCostsOverridesEnabled (context, value) {
      context.commit('SET_SESSION_COSTSOVERRIDESENABLED', value)
    },
    setSessionCostsSettingsOverrides ({ commit, getters }, data) {
      commit('SET_SESSION_COSTSSETTINGSOVERRIDES', {
        item: data.item,
        value: data.value,
        override: getters.getSession.costsSettings.costsSettingsOverrides
      })
      commit('SET_SERVICE_VALUE', { value: data.value, parentIndex: data.parentIndex, childIndex: data.childIndex })
    },
    //
    setSessionCustomCancellationSuccess ({ commit }, value) {
      commit('SET_SESSION_CUSTOM_CANCELLATIONSUCCESS', value)
    },
    setSessionCustomExpiredUsers ({ commit }, value) {
      commit('SET_SESSION_CUSTOM_EXPIREDUSERS', value)
    }
  }
}

export default ebsSession
