import axios from 'axios'
import api from '@/api'

const data = {
  data: [],
  default: ''
}

const ebsSessions = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsBookingsData: state => {
      return state.data
    }
  },
  mutations: {
    EBS_BOOKINGS_SET_DATA (state, payload) {
      state.data = payload
    },
    EBS_BOOKINGS_CLEAR_DATA (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    }
  },
  actions: {
    ebsBookingsGetForEvent ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const eventId = data.eventId
        const learnerId = data.learnerId

        commit('EBS_BOOKINGS_CLEAR_DATA')

        axios({
          method: 'get',
          url: `${api.classroom}/v2/learner/${learnerId}/bookings?eventId=${eventId}`
        }).then(response => {
          commit('EBS_BOOKINGS_SET_DATA', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ebsSessions
