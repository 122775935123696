<template>
  <div class="filter__list">
    <div class="columns is-variable">
      <div class="column is-narrow is-vcentered">
        <filter-sort-icons
          :filters="filters"
          :list-view="listView"
          :tooltip-active="tooltipActive"
          @emit-switch-list-view="emitSwitchListView"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FilterSortIcons from '@/components/default/filters/FilterListIcons'

export default {
  data () {
    return {
      menuActive: false
    }
  },
  components: {
    FilterSortIcons
  },
  name: 'filter-list',
  props: {
    filters: {
      type: [Array, Object]
    },
    tooltipActive: {
      type: Boolean
    },
    listView: {}
  },
  methods: {
    emitSwitchListView: function (value) {
      this.$emit('emit-switch-list-view', value)
    }
  }
}
</script>

<style lang="scss">
  @import "~@/assets/scss/components/ebs/filters/filterlist";
</style>
