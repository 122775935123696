import dayjs from 'dayjs'
import store from '@/store'

export function calendarDate (value) {
  if (value) {
    let date = dayjs(value)
    return date.format('DD MMM YYYY')
  }
}

export function calendarTime (value) {
  if (value) {
    let date = dayjs(value)
    return date.format('h:mm A')
  }
}

export function debounce (fn, delay) {
  let timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    let args = arguments
    let that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export function fromArrayToStringComma (value) {
  let items = value
  let str = ''
  for (let index in items) {
    if (!items.hasOwnProperty(index)) return
    if (index > 0) {
      str += `, ${items[index]}`
    } else {
      str += items[index]
    }
  }
  return str || ''
}

export function fromObjectToStringComma (arr, key) {
  let str = ''
  for (let index in arr) {
    if (index > 0) {
      str += `, ${arr[index][key]}`
    } else {
      str += arr[index][key]
    }
  }
  return str
}

export function decodeEmail (value) {
  return atob(value).replace(/\n/g, '')
}

export function encodeEmail (value) {
  return btoa(value.replace(/[\u00A0-\u2666]/g, function (c) {
    return '&#' + c.charCodeAt(0) + ';'
  }))
}

export function isDatePast (value) {
  const valueDate = new Date(value.replace(' ', 'T') + 'Z')
  const currentDate = new Date()

  return valueDate < currentDate
}

export function fromKebabCaseToString (value) {
  if (value) {
    return value.replace(/[-_]/g, ' ').toLowerCase()
  }
}

export function fromLocalDateTimeToUTCISOCustom (value) {
  if (!value) return
  const initDate = new Date(value)
  const newDateISO = initDate.toISOString()
  const outputDate = newDateISO.replace('T', ' ').slice(0, -8)
  return outputDate
}

export function fromLocalTimeToUTC (value) {
  if (!value) return
  const initDate = new Date(new Date().toDateString() + ` ${value}`)
  const newDateISO = initDate.toISOString()
  const outputTime = newDateISO.replace('T', ' ').slice(11, -8)
  return outputTime
}

export function fromCamelCaseToString (value) {
  if (value) {
    return value.replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + ' ' + m[1]
    }).toLowerCase()
  }
}

export function fromStringToArray (value) {
  if (value) {
    const arr = value.split(/\n/)
    const data = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]) {
        data.push(arr[i])
      }
    }
    return data
  }
}

export function fromStringToArrayRadio (value) {
  if (value) {
    const arr = value.split(/\n/)
    const data = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]) {
        data.push({
          name: toCapitalize(arr[i]),
          value: arr[i]
        })
      }
    }
    return data
  }
}

export function fromStringToArraySelect (value) {
  if (value) {
    const arr = value.split(/\n/)
    const data = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]) {
        data.push({
          label: toCapitalize(arr[i]),
          value: arr[i]
        })
      }
    }
    return data
  }
}

export function fromUTCDateTimeToLocalISOCustom (value, preventReplace = false) {
  if (!value) return
  if (value.indexOf('+') > -1) {
    value = value.slice(0, value.indexOf('+'))
  }
  const valueDate = new Date(value.replace(' ', 'T') + 'Z')
  const initDate = new Date(valueDate)
  const newDate = new Date(initDate.getTime() - (valueDate.getTimezoneOffset() * 60000))
  const newDateISO = new Date(newDate).toJSON()
  let outputDate
  if (preventReplace) {
    outputDate = newDateISO.slice(0, -8)
  } else {
    outputDate = newDateISO.replace('T', ' ').slice(0, -8)
  }
  return outputDate
}

export function fromUTCTimeToLocal (value) {
  if (!value) return
  if (value.indexOf('+') > -1) {
    value = value.slice(0, value.indexOf('+'))
  } else if (value.indexOf(' ') > -1) {
    value = value.slice(value.indexOf(' '))
  }
  const now = new Date()
  const initDate = new Date(Date.parse(new Date().toDateString() + ` ${value}Z`))
  const newDate = new Date(initDate.getTime() - (now.getTimezoneOffset() * 60000))
  const newDateISO = new Date(newDate).toJSON()
  const outputTime = newDateISO.replace('T', ' ').slice(11, -8)
  return outputTime
}

export function generatePassword () {
  let retVal = ''
  const length = 8
  const charset = [
    'abcdefghijklmnopqrstuvwxyz',
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    '0123456789',
    '!@#$%^&*()_+'
  ]

  for (let i = 0, j = 0, k = 0; i < length; i++, j++) {
    if (j === charset.length) j = 0
    k = charset[j].length
    retVal += charset[j].charAt(Math.floor(Math.random() * k))
  }

  return retVal
}

export function getNestedByArray (obj, is, value) {
  if (typeof is === 'string') {
    return getNestedByArray(obj, is.split('.'), value)
  } else if (is.length === 1 && value !== undefined) {
    return obj[is[0]]
  } else if (is.length === 0) {
    return obj
  } else {
    return getNestedByArray(obj[is[0]], is.slice(1), value)
  }
}

export function getHoursDifference (from, to, suffix) {
  if (!from || !to) return 0
  const dateFrom = new Date(from)
  const dateFromTime = dateFrom.getTime()
  const dateTo = new Date(to)
  const dateToTime = dateTo.getTime()
  const difference = Math.floor(Math.abs(dateFromTime - dateToTime) / 3600000)
  let output = difference || 0
  output += suffix ? suffix + (output !== 1 ? 's' : '') : ''

  return output
}

export function isIE11 () {
  return !!window.MSInputMethodContext && !!document.documentMode
}

export function isMobileView () {
  const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const mobileBreakpointWidth = 768

  return windowWidth <= mobileBreakpointWidth
}

export function isInIframe () {
  return window.top !== window.self
}

export function removeLastOneChar (value) {
  if (value) {
    return value.slice(0, -1)
  }
}

export function removeLastThreeChar (value) {
  if (value) {
    return value.slice(0, -3)
  }
}

export function removeLastTwoChar (value) {
  if (value) {
    return value.slice(0, -2)
  }
}

export function scrollToTop () {
  document.getElementById('app').scrollTo({ top: 0, behavior: 'smooth' })
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export function sortByKey (data, key, method = 'asc', type = 'string', subKey, subType) {
  // type should be one of ['string', 'number', 'data']
  // method should be one of ['asc', 'desc']
  if (data) {
    return JSON.parse(JSON.stringify(data)).sort((a, b) => {
      let aKey = ''
      let bKey = ''

      if (key) {
        if (a.constructor === Object && b.constructor === Object) {
          if (key.constructor === Array) {
            for (let i in key) {
              aKey += a[key[i]] ? JSON.parse(JSON.stringify(a[key[i]])) : ''
              bKey += b[key[i]] ? JSON.parse(JSON.stringify(b[key[i]])) : ''
            }
          } else {
            aKey = a[key] !== undefined ? JSON.parse(JSON.stringify(a[key])) : undefined
            bKey = b[key] !== undefined ? JSON.parse(JSON.stringify(b[key])) : undefined
          }
        } else if (a.constructor === Array && b.constructor === Array) {
          aKey = JSON.parse(JSON.stringify(a.find(item => {
            return item.label === key
          })))
          bKey = JSON.parse(JSON.stringify(b.find(item => {
            return item.label === key
          })))
          aKey = aKey.sortValue ? aKey['sortValue'] : aKey['value']
          bKey = bKey.sortValue ? bKey['sortValue'] : bKey['value']
        }
      } else {
        aKey = a !== undefined ? JSON.parse(JSON.stringify(a)) : undefined
        bKey = b !== undefined ? JSON.parse(JSON.stringify(b)) : undefined
      }

      if (type === 'string') {
        aKey = String(aKey).toUpperCase()
        bKey = String(bKey).toUpperCase()
      } else if (type === 'boolean') {
        aKey = Boolean(aKey)
        bKey = Boolean(bKey)
      } else if (type === 'number') {
        aKey = Number(aKey)
        bKey = Number(bKey)
      } else if (type === 'date') {
        aKey = aKey ? new Date(aKey) : 1
        bKey = bKey ? new Date(bKey) : 1
      } else if (type === 'array') {
        if (subKey) {
          if (subKey.constructor === Array) {
            for (let i in aKey) {
              let aKeyStr = ''
              for (let j in subKey) {
                aKeyStr += aKey[i][subKey[j]]
              }
              aKey[i] = aKeyStr
            }
            for (let i in bKey) {
              let bKeyStr = ''
              for (let j in subKey) {
                bKeyStr += bKey[i][subKey[j]]
              }
              bKey[i] = bKeyStr
            }
          } else if (subKey.constructor === String) {
            for (let i in aKey) {
              aKey[i] = aKey[i][subKey]
            }
            for (let i in bKey) {
              bKey[i] = bKey[i][subKey]
            }
          }
        }
        aKey = aKey ? aKey.join() : ''
        bKey = bKey ? bKey.join() : ''
      } else if (type === 'object') {
        if (subType === 'number') {
          aKey = Number(aKey[subKey])
          bKey = Number(bKey[subKey])
        } else {
          aKey = String(aKey[subKey]).toUpperCase()
          bKey = String(bKey[subKey]).toUpperCase()
        }
      }
      if (aKey !== undefined && bKey !== undefined) {
        if (aKey < bKey) {
          return method === 'asc' ? -1 : 1
        }
        if (aKey > bKey) {
          return method === 'asc' ? 1 : -1
        }
        return 0
      }
    })
  } else {
    return data
  }
}

export function toCapitalize (value) {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
}

export function toDataURL (url, callback) {
  let xhr = new XMLHttpRequest()
  xhr.onload = function () {
    let reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export function toUncapitalise (value) {
  if (value) {
    return value.charAt(0).toLowerCase() + value.slice(1)
  }
}

export function toISODate (value) {
  if (!value) return
  let valueValid = value.constructor !== Date ? value.replace(' ', 'T') : value
  const initDate = new Date(valueValid)
  const newDateISO = initDate.toISOString()
  const outputDate = newDateISO.slice(0, 10)
  return outputDate
}

export function toISODateUTC (value) {
  if (!value) return
  const initDate = new Date(value)
  const dayUTC = initDate.getUTCDate()
  const dayUTCString = (dayUTC < 10 ? '0' : '') + String(dayUTC)
  const monthUTC = initDate.getUTCMonth() + 1
  const monthUTCString = (monthUTC < 10 ? '0' : '') + String(monthUTC)
  const yearUTCString = String(initDate.getUTCFullYear())

  const outputDate = `${yearUTCString}-${monthUTCString}-${dayUTCString}`
  return outputDate
}

export function toUTCDateTime (value) {
  if (!value) return
  return new Date(value.getTime() + value.getTimezoneOffset() * 60000)
}

export function toKebabCase (value) {
  if (value && value.constructor === String) {
    return value.replace(/[\s+_]/g, '-').toLowerCase()
  }
}

export function toLocaleDateTime (value, year = 'numeric', month = 'short', day = '2-digit', weekday = undefined) {
  if (!value) return
  value = new Date(value.replace(' ', 'T'))
  let options = {
    year: year,
    month: month,
    day: day,
    weekday: weekday
  }
  return new Date(value).toLocaleDateString('en-gb', options)
}

export function toLocaleTime (value, hour = 'numeric', minute = 'numeric', hour12 = false) {
  if (!value) return
  value = new Date(value.replace(' ', 'T'))
  let options = {
    hour: hour,
    minute: minute,
    hour12: hour12
  }
  return new Date(value).toLocaleString('en-gb', options).toUpperCase()
}

export function toSnakeCase (value) {
  if (value) {
    return value.replace(/[\s+_]/g, '_').toLowerCase()
  }
}

export function toString (value) {
  if (value) {
    return value.replace(/[\s+_]/g, ' ')
  }
}

export function toUnicase (value) {
  if (value) {
    return value.replace(/[\s+_]/g, '').toLowerCase()
  }
}

export function toUrlQuery (value) {
  if (value) {
    return value.replace(/\s+/g, '+')
  }
}

export function listCalendarDatesBetween (from, to) {
  let datesArr = []
  let dateFrom = new Date(from)
  let dateTo = new Date(to)
  dateFrom.setHours(0)
  dateTo.setHours(0)

  for (; dateFrom <= dateTo; dateFrom.setDate(dateFrom.getDate() + 1)) {
    datesArr.push(`${dateFrom.getFullYear()}-${('0' + (dateFrom.getMonth() + 1)).slice(-2)}-${('0' + dateFrom.getDate()).slice(-2)}`)
  }

  return datesArr
}

export function getDomPath (el) {
  let stack = []
  while (el.parentNode != null) {
    let sibCount = 0
    let sibIndex = 0
    for (let i = 0; i < el.parentNode.childNodes.length; i++) {
      let sib = el.parentNode.childNodes[i]
      if (sib.nodeName == el.nodeName) {
        if (sib === el) {
          // eslint-disable-next-line no-unused-vars
          sibIndex = sibCount
        }
        sibCount++
      }
    }
    stack.unshift(el)
    el = el.parentNode
  }
  return stack.slice(1)
}

export function handleCopyToClipboard (value, name) {
  let input = document.createElement('input')
  input.setAttribute('value', value)
  document.body.appendChild(input)
  input.select()
  let result = document.execCommand('copy')
  document.body.removeChild(input)

  store.dispatch('defaultNotificationToastsAdd', {
    title: `${toCapitalize(name)} copy`,
    message: `${toCapitalize(name)} has been successfully copied.`,
    status: 'success'
  })

  return result
}

export function indexOfRegex (str, regex, shift = 0) {
  const match = str.match(regex)
  return match ? str.indexOf(match[0]) + shift : -1
}

export function lastIndexOfRegex (str, regex, shift = 0) {
  const match = str.match(regex)
  return match ? str.indexOf(match[0]) + match[0].length - 1 + shift : -1
}
