var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header__top_account_menu_thumbnail"},[(_vm.isLoggedInUserInpersonated)?[_c('button',{class:[
                'header__top_account_menu_thumbnail_button',
                'header__top_account_menu_thumbnail_button--admin'
            ],attrs:{"id":"header-top-account-menu-thumbnail-button-admin"}},[_vm._v(" "+_vm._s(_vm.loggedInOriginUserNameInitials)+" ")])]:_vm._e(),_c('button',{class:[
            'header__top_account_menu_thumbnail_button',
            _vm.buttonActive ? 'header__top_account_menu_thumbnail_button--active' : '',
            _vm.isLoggedInUserInpersonated ? 'header__top_account_menu_thumbnail_button--inpersonated' : ''
        ],attrs:{"id":"header-top-account-menu-thumbnail-button"},on:{"click":_vm.toggleBox}},[_vm._v(" "+_vm._s(_vm.loggedInUserNameInitials)+" ")]),((_vm.user.isLearningExperienceAdministrator() || _vm.user.isManager()) && _vm.requestsLength)?[_c('div',{staticClass:"header__top_account_menu_thumbnail_notifications",on:{"click":_vm.redirectToEnrolments}},[_c('div',{staticClass:"header__top_account_menu_thumbnail_notifications_number"},[_vm._v(_vm._s(_vm.requestsLength))])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }