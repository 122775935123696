import axios from 'axios'
import api from '@/api'
import router from '@/router'

const data = {
  data: {
    id: '',
    tags: [],
    name: '',
    html: '',
    description: '',
    type: 'certificate'
  },
  default: ''
}

const lmsUserReward = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsUserReward: state => {
      return state.data
    },
    getLmsUserRewardName: state => {
      return state.data.name
    },
    getLmsUserRewardHtml: state => {
      return state.data.html
    },
    getLmsUserRewardDescription: state => {
      return state.data.description
    }
  },
  mutations: {
    LMS_USER_REWARD_GET (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    LMS_USER_REWARD_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    LMS_USER_REWARD_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    LMS_USER_REWARD_SET_NAME (state, payload) {
      state.data.name = payload
    },
    LMS_USER_REWARD_SET_HTML (state, payload) {
      state.data.html = payload
    },
    LMS_USER_REWARD_SET_DESCRIPTION (state, payload) {
      state.data.description = payload
    }
  },
  actions: {
    lmsUserRewardUpdate ({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'patch',
          url: `${api.learningactivities}/certificate/content`,
          data: state.data
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Reward update',
            message: `Reward has been successfully updated.`,
            status: 'success'
          })
          commit('LMS_USER_REWARD_CLEAR')
          router.push({ name: 'lms-user-achievement-add-reward' })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Reward update',
            message: `Reward hasn't been updated.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsUserRewardCreate ({ commit, state, rootState, dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.learningactivities}/certificate/content`,
          data: state.data
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Reward create',
            message: `Reward has been successfully created.`,
            status: 'success'
          })
          commit('LMS_USER_REWARD_CLEAR')
          if (rootState.route.query.achievementId) {
            router.push({ name: 'lms-user-achievement-edit-reward', params: { id: rootState.route.query.achievementId }, query: { rewardId: response.data.id } })
          } else {
            router.push({ name: 'lms-user-achievement-list' })
          }
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Reward create',
            message: `Reward hasn't been created.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsUserRewardName ({ commit }, data) {
      commit('LMS_USER_REWARD_SET_NAME', data)
    },
    lmsUserRewardHtml ({ commit }, data) {
      commit('LMS_USER_REWARD_SET_HTML', data)
    },
    lmsUserRewardDescription ({ commit }, data) {
      commit('LMS_USER_REWARD_SET_DESCRIPTION', data)
    },
    lmsUserRewardReset ({ commit }, data) {
      commit('LMS_USER_REWARD_RESET', data)
    },
    lmsUserRewardClear ({ commit }, data) {
      commit('LMS_USER_REWARD_CLEAR', data)
    }
  }
}

export default lmsUserReward
