import axios from 'axios'
import api from '@/api'
import router from '@/router'

const CancelToken = axios.CancelToken
let cancelEbsModuleUploadSCORMFile
let cancelEbsModuleGetSCORMFile

const data = {
  data: {
    externalModule: {
      moduleType: '',
      externalId: ''
    },
    id: '',
    name: '',
    image: null,
    description: '',
    duration: '00:00',
    labels: [],
    keywords: '',
    content: '',
    owner: '',
    versions: [],
    releaseNotes: '',
    draft: false
  },
  default: '',
  custom: {
    contentData: '',
    uploadProgress: {
      loaded: 0,
      total: 0
    },
    labels: {
      data: [],
      pagination: {}
    }
  }
}

const ebsModule = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsModule: state => {
      return state.data
    },
    getEbsModuleId: state => {
      return state.data.id
    },
    getEbsModuleName: state => {
      return state.data.name
    },
    getEbsModuleDraft: state => {
      return state.data.draft
    },
    getEbsModuleDefaultName: state => {
      return state.default.name
    },
    getEbsModuleDuration: state => {
      return state.data.duration
    },
    getEbsModuleDescription: state => {
      return state.data.description
    },
    getEbsModuleImage: state => {
      const image = state.data.image
      return image ? [{ imageId: image }] : ''
    },
    getEbsModuleKeywords: state => {
      return state.data.keywords
    },
    getEbsModuleLabels: state => {
      return state.data.labels.map(obj => {
        if (obj.constructor === Object) {
          return Object.keys(obj)[0]
        } else {
          return obj
        }
      })
    },
    getEbsModuleLabelsNew: state => {
      return state.data.labels ? state.data.labels.filter(fObj => fObj.constructor === String).map(mObj => {
        if (mObj.constructor === String) {
          return mObj
        }
      }) : []
    },
    getEbsModuleCustomLabelsPagination: state => {
      return state.custom.labels.pagination
    },
    getEbsModuleCustomLabelsMappedLabelValue: state => {
      const rawItems = state.custom.labels.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.label + item.id,
          customLabel: item.label,
          value: item.id
        }
      })
    },
    getEbsModuleCustomLabelsMappedIdNameStatus: state => {
      const rawItems = state.custom.labels.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.label
        }
      })
    },
    getEbsModuleContent: state => {
      return state.data.content
    },
    getEbsModuleDefaultContent: state => {
      return state.default.content
    },
    getEbsModuleExternalModule: state => {
      return state.data.externalModule
    },
    getEbsModuleVersions: state => {
      const versions = state.data.versions
      const isDraft = state.data.draft
      let versionsArr = []
      for (let i in versions) {
        for (let j in versions[i].minors) {
          versionsArr.push({
            version: `${Number(i) + 1}.${j}`,
            releaseDate: versions[i].minors[j].releaseDate,
            releaseNotes: versions[i].minors[j].releaseNotes,
            releasedBy: versions[i].minors[j].releasedBy || 'N/A'
          })
        }
      }
      if (isDraft) {
        versionsArr.push({
          version: 'Draft',
          releaseDate: '',
          releaseNotes: '',
          releasedBy: ''
        })
      }
      versionsArr.reverse()
      return versionsArr
    },
    getEbsModuleCustomContentData: state => {
      return state.custom.contentData
    },
    getEbsModuleCustomUploadProgress: state => {
      return state.custom.uploadProgress
    },
    getEbsModuleReleaseNotes: state => {
      return state.data.releaseNotes
    },
    getEbsModuleLatestVersionNumber: state => {
      const versions = state.data.versions
      const latestMajorVersion = versions.length
      const latestMinorVersion = latestMajorVersion ? versions[latestMajorVersion - 1].minors.length : 0
      return latestMajorVersion ? `${latestMajorVersion}.${latestMinorVersion - 1}` : ''
    },
    getEbsModuleLatestVersionDate: state => {
      const versions = state.data.versions
      const latestMajorVersion = versions.length
      const latestMinorVersion = latestMajorVersion ? versions[latestMajorVersion - 1].minors.length : 0
      return latestMajorVersion ? versions[latestMajorVersion - 1].minors[latestMinorVersion - 1].releaseDate : ''
    },
    getEbsModuleCustomLabels: state => {
      return state.custom.labels
    }
  },
  mutations: {
    EBS_MODULE_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
      state.custom = JSON.parse(JSON.stringify(data.custom))
    },
    EBS_MODULE_CLEAR_DATA_CONTENT (state) {
      state.data.content = JSON.parse(JSON.stringify(data.data.content))
    },
    EBS_MODULE_CLEAR_CUSTOM_LABELS (state) {
      state.custom.labels = JSON.parse(JSON.stringify(data.custom.labels))
    },
    EBS_MODULE_CLEAR_CUSTOM_CONTENTDATA (state) {
      state.custom.contentData = JSON.parse(JSON.stringify(data.custom.contentData))
    },
    EBS_MODULE_SET (state, payload) {
      const payloadData = JSON.parse(JSON.stringify(payload))
      payloadData.releaseNotes = ''
      payloadData.keywords = payloadData.keywords || ''
      state.data = JSON.parse(JSON.stringify(payloadData))
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    EBS_MODULE_SET_EXTERNAL_MODULE_EXTERNAL_ID (state, payload) {
      state.data.externalModule.externalId = payload
    },
    EBS_MODULE_SET_NAME (state, payload) {
      state.data.name = payload
    },
    EBS_MODULE_SET_DESCRIPTION (state, payload) {
      state.data.description = payload
    },
    EBS_MODULE_SET_CONTENT (state, payload) {
      state.data.content = payload
    },
    EBS_MODULE_SET_IMAGE (state, payload) {
      state.data.image = payload
    },
    EBS_MODULE_REMOVE_IMAGE (state) {
      state.data.image = null
    },
    EBS_MODULE_SET_KEYWORDS (state, payload) {
      state.data.keywords = payload
    },
    EBS_MODULE_SET_CUSTOM_CONTENTDATA (state, payload) {
      state.custom.contentData = payload
    },
    EBS_MODULE_SET_CUSTOM_UPLOADPROGRESS (state, payload) {
      state.custom.uploadProgress.loaded = payload.loaded
      state.custom.uploadProgress.total = payload.total
    },
    EBS_MODULE_SET_RELEASE_NOTES (state, payload) {
      state.data.releaseNotes = payload
    },
    EBS_MODULE_SET_DURATION (state, payload) {
      state.data.duration = payload
    },
    EBS_MODULE_SET_LABELS (state, payload) {
      const labels = JSON.parse(JSON.stringify(state.data.labels))

      state.data.labels = payload.map(mObj => {
        const labelIndex = labels.findIndex(fiObj => Object.keys(fiObj)[0] === mObj.value)
        if (labelIndex !== -1) {
          return labels[labelIndex]
        } else if (mObj.customLabel) {
          let obj = {}
          obj[mObj.value] = mObj.customLabel
          return obj
        } else {
          return mObj.value
        }
      })
    },
    EBS_MODULE_ADD_LABEL (state, payload) {
      state.data.labels.push(payload)
    },
    EBS_MODULE_SET_CUSTOM_LABELS_DATA (state, payload) {
      state.custom.labels.data = payload
    },
    EBS_MODULE_SET_CUSTOM_LABELS_PAGINATION (state, payload) {
      state.custom.labels.pagination = payload
    },
    EBS_MODULE_SET_INSTANT_OFFER (state, payload) {
      state.data.instantOffer = payload
    }
  },
  actions: {
    ebsModuleCancelRequests () {
      if (cancelEbsModuleUploadSCORMFile) cancelEbsModuleUploadSCORMFile()
      if (cancelEbsModuleGetSCORMFile) cancelEbsModuleGetSCORMFile()
    },
    ebsModuleClear ({ commit }) {
      commit('EBS_MODULE_CLEAR')
    },
    ebsModuleClearDataContent ({ commit }) {
      commit('EBS_MODULE_CLEAR_DATA_CONTENT')
    },
    ebsModuleClearCustomContentData ({ commit }) {
      commit('EBS_MODULE_CLEAR_CUSTOM_CONTENTDATA')
    },
    ebsModuleGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('EBS_MODULE_CLEAR')
        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/modules/${data}`
        }).then(response => {
          commit('EBS_MODULE_SET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleGetSCORM ({ commit, dispatch, getters }, data) {
      return new Promise((resolve, reject) => {
        commit('EBS_MODULE_CLEAR')
        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.courseCatalog}/v2/scorm-module/${data}`
        }).then(response => {
          let responseData = response.data
          commit('EBS_MODULE_SET', response.data)
          dispatch('ebsModuleGetSCORMFile', response.data.content).then(fileResponse => {
            responseData.url = fileResponse.data.url
            responseData.scormBucketUrl = fileResponse.data.scormBucketUrl
            resolve(responseData)
          }).catch(error => {
            reject(error)
          })
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleGetLabels ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        commit('EBS_MODULE_CLEAR_CUSTOM_LABELS')

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/scorm-modules/labels${queryUrlParams}`
        }).then(response => {
          commit('EBS_MODULE_SET_CUSTOM_LABELS_DATA', response.data.data)
          commit('EBS_MODULE_SET_CUSTOM_LABELS_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleCreate ({ state }, data) {
      return new Promise((resolve, reject) => {
        const name = data ? (data.name !== undefined ? data.name : state.data.name) : state.data.name
        const description = data ? (data.description !== undefined ? data.description : state.data.description) : state.data.description
        const owner = data ? (data.owner !== undefined ? data.owner : state.data.owner) : state.data.owner
        const moduleType = data ? (data.type !== undefined ? data.type : state.data.externalModule.moduleType) : state.data.externalModule.moduleType
        const externalId = data ? (data.id !== undefined ? data.id : state.data.externalModule.externalId) : state.data.externalModule.externalId

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/modules`,
          data: {
            name,
            description,
            owner,
            moduleType,
            externalId
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleDelete (ctx, data) {
      return new Promise((resolve, reject) => {
        const moduleId = data

        axios({
          method: 'delete',
          url: `${api.courseCatalog}/v2/scorm-modules/${moduleId}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleUndelete (ctx, data) {
      return new Promise((resolve, reject) => {
        const moduleId = data

        axios({
          method: 'patch',
          url: `${api.courseCatalog}/v2/scorm-modules/${moduleId}/undelete`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleCreateSCORM ({ commit, state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const release = data ? data.release : false

        const ebsModuleSCORMReleaseMajor = (data) => {
          return dispatch('ebsModuleSCORMReleaseMajor', data)
        }
        const onReleaseResolve = (moduleId) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'SCORM create',
            message: `SCORM has been successfully created and released.`,
            status: 'success'
          })

          commit('LMS_SUPERADMIN_CUSTOMERS_CLEAR')
          commit('LMS_SUPERADMIN_CUSTOMER_CLEAR')
          router.push({ name: 'ebs-module-show', params: { id: moduleId } })
        }
        const onResolve = (moduleId) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'SCORM create',
            message: `SCORM has been successfully created.`,
            status: 'success'
          })

          commit('LMS_SUPERADMIN_CUSTOMERS_CLEAR')
          commit('LMS_SUPERADMIN_CUSTOMER_CLEAR')
          router.push({ name: 'ebs-module-show', params: { id: moduleId } })
        }
        const onReleaseCatch = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'SCORM create',
            message: `SCORM has been successfully created but not released.`,
            status: 'success'
          })
        }
        const onCatch = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'SCORM create',
            message: `SCORM hasn't been created.`,
            status: 'error'
          })
        }

        let requestData = {}
        requestData.name = state.data.name
        requestData.description = state.data.description
        requestData.duration = state.data.duration ? (state.data.duration.length === 5 ? state.data.duration + ':00' : state.data.duration) : null
        requestData.labels = state.data.labels
        requestData.keywords = state.data.keywords
        requestData.image = state.data.image
        requestData.owner = state.data.owner
        requestData.content = state.data.content
        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/scorm-modules`,
          data: requestData
        }).then(response => {
          const moduleId = response.data.id
          if (release) {
            ebsModuleSCORMReleaseMajor(moduleId).then(response => {
              onReleaseResolve(moduleId)
              resolve(response.data)
            }).catch(error => {
              onReleaseCatch(moduleId)
              reject(error)
            })
          } else {
            onResolve(moduleId)
            resolve(response.data)
          }
        }).catch(error => {
          onCatch()
          reject(error)
        })
      })
    },
    ebsModuleUpdateSCORM ({ commit, state, rootState, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const release = data ? (data.release !== undefined ? data.release : false) : false
        const moduleId = data ? (data.moduleId !== undefined ? data.moduleId : rootState.route.params.id) : rootState.route.params.id
        const stateData = JSON.parse(JSON.stringify(state.data))
        const stateDefaultData = JSON.parse(JSON.stringify(state.default))
        const hasVersions = stateData.versions ? stateData.versions.length : false

        const ebsModuleSCORMUpdateDetails = () => {
          return dispatch('ebsModuleSCORMUpdateDetails', moduleId)
        }
        const ebsModuleSCORMReleaseMajor = () => {
          return dispatch('ebsModuleSCORMReleaseMajor', moduleId)
        }
        const ebsModuleSCORMReleaseMinor = () => {
          return dispatch('ebsModuleSCORMReleaseMinor', moduleId)
        }
        const onReleaseResolve = (moduleId) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'SCORM update',
            message: `SCORM has been successfully updated and released.`,
            status: 'success'
          })
          commit('LMS_SUPERADMIN_CUSTOMERS_CLEAR')
          commit('LMS_SUPERADMIN_CUSTOMER_CLEAR')
          router.push({ name: 'ebs-module-show', params: { id: moduleId } })
        }
        const onResolve = (moduleId) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'SCORM update',
            message: `SCORM has been successfully updated.`,
            status: 'success'
          })
          commit('LMS_SUPERADMIN_CUSTOMERS_CLEAR')
          commit('LMS_SUPERADMIN_CUSTOMER_CLEAR')
          router.push({ name: 'ebs-module-show', params: { id: moduleId } })
        }
        const onReleaseCatch = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'SCORM update',
            message: `SCORM has been successfully updated but not released.`,
            status: 'success'
          })
        }
        const onCatch = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'SCORM update',
            message: `SCORM hasn't been updated.`,
            status: 'error'
          })
        }

        if (
          stateData.name !== stateDefaultData.name ||
        stateData.description !== stateDefaultData.description ||
        stateData.image !== stateDefaultData.image ||
        stateData.content !== stateDefaultData.content ||
        stateData.duration !== stateDefaultData.duration ||
        stateData.labels !== stateDefaultData.labels ||
        stateData.keywords !== stateDefaultData.keywords
        ) {
          ebsModuleSCORMUpdateDetails().then(() => {
            if (release) {
              if (hasVersions) {
                ebsModuleSCORMReleaseMinor().then(() => {
                  onReleaseResolve(moduleId)
                  resolve()
                }).catch(error => {
                  onReleaseCatch()
                  reject(error)
                })
              } else {
                ebsModuleSCORMReleaseMajor().then(() => {
                  onReleaseResolve(moduleId)
                  resolve()
                }).catch(error => {
                  onReleaseCatch()
                  reject(error)
                })
              }
            } else {
              onResolve(moduleId)
              resolve()
            }
          }).catch(error => {
            onCatch()
            reject(error)
          })
        } else {
          if (release) {
            if (hasVersions) {
              ebsModuleSCORMReleaseMinor().then(() => {
                onReleaseResolve(moduleId)
                resolve()
              }).catch(error => {
                onReleaseCatch()
                reject(error)
              })
            } else {
              ebsModuleSCORMReleaseMajor().then(() => {
                onReleaseResolve(moduleId)
                resolve()
              }).catch(error => {
                onReleaseCatch()
                reject(error)
              })
            }
          } else {
            onResolve(moduleId)
            resolve()
          }
        }
      })
    },
    ebsModuleSCORMUpdateDetails ({ state }, data) {
      return new Promise((resolve, reject) => {
        const stateData = JSON.parse(JSON.stringify(state.data))
        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/scorm-modules/${data}/updateDetails`,
          data: {
            name: stateData.name,
            description: stateData.description,
            duration: stateData.duration ? (stateData.duration.length === 5 ? stateData.duration + ':00' : stateData.duration) : null,
            labels: stateData.labels,
            keywords: stateData.keywords,
            image: stateData.image,
            content: stateData.content
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleSCORMReleaseMajor ({ state }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/scorm-modules/${data}/release/major`,
          data: {
            releaseNotes: state.data.releaseNotes,
            instantOffer: state.data.instantOffer
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleSCORMReleaseMinor ({ state }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/scorm-modules/${data}/release/minor`,
          data: {
            releaseNotes: state.data.releaseNotes
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleRelease (ctx, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/modules/${id}/release`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleUpdateReference (ctx, data) {
      return new Promise((resolve, reject) => {
        const name = data ? data.name : ''
        const externalId = data ? data.externalId : ''
        const moduleId = data ? data.moduleId : ''
        const moduleType = data ? data.moduleType : ''

        axios({
          method: 'patch',
          url: `${api.courseCatalog}/v2/modules/${moduleId}/reference`,
          data: {
            name,
            externalId,
            moduleType
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleGeneratePresignedPost (ctx, data) {
      return new Promise((resolve, reject) => {
        const mimeType = 'application/zip'
        const name = data ? data.name : undefined
        const size = data ? data.size : undefined

        axios({
          method: 'post',
          url: `${api.learningactivities}/files/generatePresignedPost`,
          data: {
            mimeType,
            name,
            size
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleUploadSCORMFile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const url = data ? data.action : undefined
        const enctype = data ? data.enctype : undefined
        const method = data ? data.method : undefined
        const form = data ? data.form : undefined

        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsModuleUploadSCORMFile = ex
          }),
          method: method,
          url: url,
          headers: {
            'Content-Type': enctype
          },
          data: form,
          onUploadProgress (progressEvent) {
            commit('EBS_MODULE_SET_CUSTOM_UPLOADPROGRESS', { loaded: progressEvent.loaded, total: progressEvent.total })
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleGetSCORMFile ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsModuleGetSCORMFile = ex
          }),
          method: 'get',
          url: `${api.learningactivities}/files/${id}`
        }).then(response => {
          commit('EBS_MODULE_SET_CUSTOM_CONTENTDATA', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModuleSetExternalModuleExternalId ({ commit }, value) {
      commit('EBS_MODULE_SET_EXTERNAL_MODULE_EXTERNAL_ID', value)
    },
    ebsModuleSetName ({ commit }, data) {
      commit('EBS_MODULE_SET_NAME', data)
    },
    ebsModuleSetDescription ({ commit }, data) {
      commit('EBS_MODULE_SET_DESCRIPTION', data)
    },
    ebsModuleSetImage ({ commit }, data) {
      commit('EBS_MODULE_SET_IMAGE', data[0])
    },
    ebsModuleSetKeywords ({ commit }, data) {
      commit('EBS_MODULE_SET_KEYWORDS', data)
    },
    ebsModuleSetContent ({ commit }, data) {
      commit('EBS_MODULE_SET_CONTENT', data)
    },
    ebsModuleRemoveImage ({ commit }, data) {
      commit('EBS_MODULE_REMOVE_IMAGE', data)
    },
    ebsModuleSetReleaseNotes ({ commit }, data) {
      commit('EBS_MODULE_SET_RELEASE_NOTES', data)
    },
    ebsModuleSetDuration ({ commit }, data) {
      commit('EBS_MODULE_SET_DURATION', data)
    },
    ebsModuleSetLabels ({ commit }, data) {
      commit('EBS_MODULE_SET_LABELS', data)
    },
    ebsModuleAddLabel ({ commit }, data) {
      commit('EBS_MODULE_ADD_LABEL', data)
    },
    ebsModuleSCORMUpdateEnrolmentStatus (ctx, data) {
      return new Promise((resolve, reject) => {
        const enrolmentId = data.enrolmentId
        const courseContentTreeNodeId = data.courseContentTreeNodeId
        const status = data.status

        axios({
          method: 'post',
          url: `${api.learningactivities}/update-scorm-status/${enrolmentId}/${courseContentTreeNodeId}/${status}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ebsModule
