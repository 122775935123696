import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const lmsUserAchievements = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsUserAchievements: state => {
      return state.data
    },
    getLmsUserAchievementsMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.name,
          value: item.id
        }
      })
    },
    getLmsUserAchievementsMappedIdNameStatus: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          status: item.status
        }
      })
    },
    getLmsUserAchievementsPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    LMS_USER_ACHIEVEMENTS_SET (state, payload) {
      state.data = payload
    },
    LMS_USER_ACHIEVEMENTS_PAGINATION_SET (state, payload) {
      state.pagination = payload
    },
    LMS_USER_ACHIEVEMENTS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_USER_ACHIEVEMENTS_PAGINATION_CLEAR (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsUserAchievementsGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        commit('LMS_USER_ACHIEVEMENTS_CLEAR')
        commit('LMS_USER_ACHIEVEMENTS_PAGINATION_CLEAR')

        return axios({
          method: 'get',
          url: `${api.learningactivities}/achievements${queryUrlParams}`
        }).then(response => {
          commit('LMS_USER_ACHIEVEMENTS_SET', response.data.data)
          commit('LMS_USER_ACHIEVEMENTS_PAGINATION_SET', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default lmsUserAchievements
