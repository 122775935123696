<template>
    <div id="header__nav" class="header__nav">
        <div class="container">
            <div class="header__nav_items">
                <div class="columns is-variable">
                    <div class="column">
                        <nav aria-label="Bottom navigation" class="header__nav_items_list">
                            <li v-for="item in itemsOutput" :key="item.id">
                                <component-button
                                    :class="[
                                        'header__nav_items_list_item',
                                        `header__nav_items_list_item--${item.name}`
                                    ]"
                                    :to="{ name: item.routeName, params: item.routeParams }"
                                    :theme="'nav'"
                                    :size="'medium'"
                                    :name="`header-nav-items-list-item-${item.name}`"
                                >{{ toCapitalize(item.name) }}
                                </component-button>
                            </li>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { User } from '@/User'
import ComponentButton from '@/components/default/shared/ComponentButton'

export default {
  name: 'header-nav',
  components: { ComponentButton },
  data () {
    let user = new User()
    return {
      user: user
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    itemsOutput () {
      return this.items.filter(item => {
        return item.visible()
      })
    },
    secondStageToken () {
      return this.$store.getters.getAuthUserSecondStageTokenData
    }
  },
  watch: {
    secondStageToken () {
      this.user = new User()
    }
  },
  methods: {
    handleNavFadeEffect () {
      const navEl = this.$el.getElementsByClassName('header__nav_items')[0]
      const navElWidth = navEl.offsetWidth
      const navListEl = this.$el.getElementsByClassName('header__nav_items_list')[0]
      const navListElScrollLeft = navListEl.scrollLeft
      const listItems = navEl.getElementsByTagName('li')
      const listItemPadding = 20
      let listItemsWidth = 0

      for (let item of listItems) {
        listItemsWidth += item.offsetWidth
      }

      if ((navElWidth + navListElScrollLeft) <= (listItemsWidth - listItemPadding)) {
        navEl.classList.add('header__nav_items--fadeeffect')
      } else {
        navEl.classList.remove('header__nav_items--fadeeffect')
      }
    }
  },
  updated () {
    this.handleNavFadeEffect()
  },
  mounted () {
    this.handleNavFadeEffect()

    const navEl = this.$el.getElementsByClassName('header__nav_items_list')[0]
    navEl.addEventListener('scroll', this.handleNavFadeEffect)
  },
  beforeDestroy () {
    const navEl = this.$el.getElementsByClassName('header__nav_items_list')[0]
    navEl.removeEventListener('scroll', this.handleNavFadeEffect)
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/layout/header/headernav";
</style>
