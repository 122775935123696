import axios from 'axios'
import api from '@/api'
import { User } from '@/User'
import { TokenHelper } from '@/TokenHelper'

const customisationDefault = {
  displayName: false,
  workspaceLogo: '',
  primaryColour: '',
  textColour: '',
  backgroundColour: '',
  onboardings: [
    {
      page: '',
      isEnabled: '',
      text: '',
      image: '',
      link: ''
    }
  ],
  helpdeskEmail: '',
  helpdeskPhone: ''
}
const data = {
  data: {
    customerId: '',
    id: '',
    name: '',
    status: 'active',
    customisation: JSON.parse(JSON.stringify(customisationDefault))
  },
  default: {}
}

const defaultWorkspace = {
  state: {
    data: JSON.parse(JSON.stringify(data.data))
  },
  getters: {
    defaultWorkspaceName: state => {
      return state.data.name
    },
    defaultWorkspaceCustomisationDisplayName: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.displayName : ''
    },
    defaultWorkspaceCustomisationWorkspaceLogo: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.workspaceLogo : ''
    },
    defaultWorkspaceCustomisationWorkspaceLogoHeight: state => {
      const customisation = state.data.customisation
      return customisation && customisation.workspaceLogoHeight ? customisation.workspaceLogoHeight : undefined
    },
    defaultWorkspaceCustomisationPrimaryColor: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.primaryColour : ''
    },
    defaultWorkspaceCustomisationTextColor: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.textColour : ''
    },
    defaultWorkspaceCustomisationBackgroundColor: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.backgroundColour : ''
    },
    defaultWorkspaceCustomisationOnboardingsOnboardingText: state => {
      const customisation = state.data.customisation
      return customisation ? (customisation.onboardings.length ? customisation.onboardings[0].text : '') : ''
    },
    defaultWorkspaceCustomisationHelpdeskEmail: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.helpdeskEmail : ''
    },
    defaultWorkspaceCustomisationHelpdeskPhone: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.helpdeskPhone : ''
    }
  },
  mutations: {
    DEFAULT_WORKSPACE_CLEAR: (state) => {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    DEFAULT_WORKSPACE_GET: (state, payload) => {
      state.data = payload
    },
    DEFAULT_WORKSPACE_CUSTOMISATION_SET: (state, payload) => {
      state.data = payload
    }
  },
  actions: {
    defaultWorkspaceInitialize ({ state, dispatch, commit }, force = false) {
      return new Promise((resolve, reject) => {
        const user = new User()
        const secondStageToken = TokenHelper.getToken('secondStageToken')
        const secondStageTokenPayload = TokenHelper.getPayload(secondStageToken)
        const currentWorkspaceId = secondStageTokenPayload ? secondStageTokenPayload.workspaceId : undefined

        if (
          state.data.id &&
        !currentWorkspaceId
        ) {
          commit('DEFAULT_WORKSPACE_CLEAR')
        } else if (
          currentWorkspaceId &&
        (state.data.id !== currentWorkspaceId ||
        (
          !state.data.id &&
        currentWorkspaceId &&
        user.isTokenFromNewLms()
        ) ||
        force)
        ) {
          dispatch('defaultWorkspaceGet', currentWorkspaceId).then(response => {
            commit('DEFAULT_WORKSPACE_GET', response)
            dispatch('defaultWorkspaceProvideAppearanceColours', response)
            resolve(response)
          }).catch(error => {
            reject(error)
          })
        }
      })
    },
    defaultWorkspaceProvideAppearanceColours (context, data) {
      const element = document.getElementById('app')
      const customisation = data ? data.customisation : null
      if (element) {
        if (customisation) {
          element.style.setProperty('--primaryColor', customisation.primaryColour)
          element.style.setProperty('--textColor', customisation.textColour)
          element.style.setProperty('--backgroundColor', customisation.backgroundColour)
        } else {
          element.style.removeProperty('--primaryColor')
          element.style.removeProperty('--textColor')
          element.style.removeProperty('--backgroundColor')
        }
      }
    },
    defaultWorkspaceGet ({ commit, dispatch }, id) {
      return new Promise((resolve, reject) => {
        commit('DEFAULT_WORKSPACE_CLEAR')

        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/workspaces/${id}`
        }).then(response => {
          commit('DEFAULT_WORKSPACE_GET', response.data)
          dispatch('defaultWorkspaceProvideAppearanceColours', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    defaultWorkspaceGetCustomisation ({ commit }, selfRegKey) {
      return new Promise((resolve, reject) => {
        commit('DEFAULT_WORKSPACE_CLEAR')

        axios({
          method: 'get',
          url: `${api.sso}/self-registration/workspace-details/${selfRegKey}`
        }).then(response => {
          commit('DEFAULT_WORKSPACE_CUSTOMISATION_SET', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    defaultWorkspaceClear ({ commit, dispatch }) {
      commit('DEFAULT_WORKSPACE_CLEAR')
      dispatch('defaultWorkspaceProvideAppearanceColours')
    }
  }
}

export default defaultWorkspace
