import axios from 'axios'
import api from '@/api'
import { setResponseValidateNotification } from '@/notificationsHelpers'

const data = {
  data: {
    fields: [],
    validate: false,
    validateErrors: [],
    validateErrorsExtra: [],
    bulkItems: [],
    success: '',
    userBooking: {
      cancellationReason: '',
      cancelledAt: '',
      cancelledBy: '',
      createdBy: '',
      eventId: '',
      form: [],
      id: '',
      learnerId: '',
      occurrenceId: '',
      status: ''
    }
  },
  custom: {
    bookAndEnrolResult: {
      success: 0,
      error: 0
    }
  }
}

const ebsBooking = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getBookingFields: state => {
      return state.data.fields
    },
    getBookingValidate: state => {
      return state.data.validate
    },
    getBookingValidateErrors: state => {
      return state.data.validateErrors
    },
    getBookingValidateErrorsExtra: state => {
      return state.data.validateErrorsExtra
    },
    getBookingUserBooking: state => {
      return state.data.userBooking
    },
    getBookingSuccess: state => {
      return state.data.success
    },
    getBookingCancellationReason: state => {
      return state.data.userBooking.cancellationReason
    },
    getBookingCustomBookAndEnrolResult: state => {
      return state.custom.bookAndEnrolResult
    }
  },
  mutations: {
    GET_BOOKING_USERBOOKING (state, payload) {
      state.data.userBooking = payload
    },
    //
    CLEAR_BOOKING (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.custom = JSON.parse(JSON.stringify(data.custom))
    },
    UPDATE_BOOKING_FIELDS (state, payload) {
      const object = state.data.fields[payload.index]
      object.answers = payload.value
      state.data.fields.splice(payload.index, 1, object)
    },
    //
    SET_BOOKING_FIELDS (state, payload) {
      for (let i = 0; i < payload.length; i++) {
        if (!payload[i].answers) {
          payload[i].answers = ''
        }
      }
      state.data.fields = payload
    },
    SET_BOOKING_VALIDATE (state, payload) {
      state.data.validate = payload
    },
    SET_BOOKING_VALIDATE_ERRORS (state, payload) {
      state.data.validateErrors = payload
    },
    SET_BOOKING_VALIDATE_ERRORS_EXTRA (state, payload) {
      state.data.validateErrorsExtra = payload
    },
    SET_BOOKING_SUCCESS (state, payload) {
      state.data.success = payload
    },
    SET_BOOKING_USERBOOKING_CANCELLATIONREASON (state, payload) {
      let value
      if (payload.constructor === Object) {
        value = payload.value
      } else if (payload.constructor === Array) {
        value = []
        for (let i = 0; i < payload.length; i++) {
          value.push(payload[i].value)
        }
      }
      state.data.userBooking.cancellationReason = value
    },
    SET_BOOKING_USERBOOKING_STATUS (state, payload) {
      state.data.userBooking.status = payload
    },
    SET_BOOKING_USERBOOKING_CANCELLEDBY (state, payload) {
      state.data.userBooking.cancelledBy = payload
    },
    SET_BOOKING_CUSTOM_BOOKANDENROLRESULT (state, payload) {
      const key = payload.key
      const value = payload.value

      state.custom.bookAndEnrolResult[key] = value
    }
  },
  actions: {
    clearBooking (context) {
      context.commit('CLEAR_BOOKING')
    },
    //
    setBookingFields ({ commit, getters }, data) {
      const bookingFormFields = data ? data.bookingFormFields : getters.getSession.bookingSettings.bookingForm.fields
      const bookingFormRequired = data ? data.bookingFormRequired : getters.getSession.bookingSettings.bookingFormRequired
      if (bookingFormRequired) {
        commit('SET_BOOKING_FIELDS', bookingFormFields)
      }
    },
    updateBookingFields ({ commit }, data) {
      commit('UPDATE_BOOKING_FIELDS', data)
    },
    setBookingCancellationReason ({ commit }, data) {
      commit('SET_BOOKING_USERBOOKING_CANCELLATIONREASON', data)
    },
    //
    validateBooking ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const learnerId = data ? (data.learnerId !== undefined ? data.learnerId : getters.getAuthUserSecondStageTokenDataID) : getters.getAuthUserSecondStageTokenDataID
        const eventId = data ? (data.eventId !== undefined ? data.eventId : getters.getSession.eventId) : getters.getSession.eventId
        const occurrenceId = data ? (data.occurrenceId !== undefined ? data.occurrenceId : getters.getSession.id) : getters.getSession.id

        return axios({
          method: 'post',
          url: `${api.classroom}/validate`,
          data: {
            learnerId,
            eventId,
            occurrenceId
          }
        }).then(response => {
          commit('SET_BOOKING_VALIDATE', true)
          resolve(response)
        }).catch(error => {
          if (!data) {
            setResponseValidateNotification(error.response)
          }
          reject(error.response)
        })
      })
    },
    setValidateBookingNotification ({ commit }, data) {
      commit('SET_BOOKING_VALIDATE', false)
      commit('SET_BOOKING_VALIDATE_ERRORS', data.errors)
      commit('SET_BOOKING_VALIDATE_ERRORS_EXTRA', data.errorsExtra)
    },
    getBooking ({ commit }, id) {
      return axios({
        method: 'get',
        url: `${api.classroom}/bookings/${id}`
      }).then(function (response) {
        commit('GET_BOOKING_USERBOOKING', response.data)
      })
    },
    //
    createBooking ({ commit, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const learnerId = data ? (data.learnerId !== undefined ? data.learnerId : getters.getAuthUserSecondStageTokenDataID) : getters.getAuthUserSecondStageTokenDataID
        const eventId = data ? (data.eventId !== undefined ? data.eventId : getters.getSession.eventId) : getters.getSession.eventId
        const occurrenceId = data ? (data.occurrenceId !== undefined ? data.occurrenceId : getters.getSession.id) : getters.getSession.id
        const form = data ? (data.form !== undefined ? data.form : getters.getBookingFields) : getters.getBookingFields

        axios({
          method: 'post',
          url: `${api.classroom}/bookings`,
          data: {
            learnerId,
            eventId,
            occurrenceId,
            form
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Booking create',
            message: `Booking has been successfully created.`,
            status: 'success'
          })
          commit('CLEAR_BOOKING')
          commit('CLEAR_ENROLLED_USERS')
          commit('SET_BOOKING_SUCCESS', true)
          resolve(response)
        }).catch(error => {
          const errorData = error.response.data.data
          let outputMessage

          if (errorData.find(obj => obj.name === 'BookingValidatorProfileExpirationDate')) {
            outputMessage = `This user's profile will expire before the session end.
Please select another session for this user.`
          } else {
            outputMessage = `Booking hasn't been created.`
          }

          dispatch('defaultNotificationToastsAdd', {
            title: 'Booking error',
            message: outputMessage,
            status: 'error'
          })
          commit('SET_BOOKING_SUCCESS', false)
          reject(error)
        })
      })
    },
    createBookingWithEnrolment (ctx, data) {
      return new Promise((resolve, reject) => {
        const eventId = data.eventId
        const occurrenceId = data.occurrenceId
        const profileId = data.profileId
        const bookingForm = data.bookingForm

        axios({
          method: 'post',
          url: `${api.classroom}/book-and-enrol`,
          data: {
            profileId,
            occurrenceId,
            eventId,
            bookingForm
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    cancelBooking ({ commit, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const userBooking = data || getters.getBookingUserBooking
        let cancellationReasonOutput

        if (userBooking.cancellationReason === undefined || userBooking.cancellationReason === null) {
          cancellationReasonOutput = []
        } else if (userBooking.cancellationReason !== undefined && userBooking.cancellationReason.constructor === String) {
          cancellationReasonOutput = [userBooking.cancellationReason]
        } else {
          cancellationReasonOutput = userBooking.cancellationReason
        }

        userBooking.cancellationReason = cancellationReasonOutput
        commit('SET_BOOKING_USERBOOKING_STATUS', 'cancelled')
        commit('SET_BOOKING_USERBOOKING_CANCELLEDBY', getters.getAuthUserSecondStageTokenDataID)
        axios({
          method: 'patch',
          url: `${api.classroom}/bookings`,
          data: userBooking
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Booking cancel',
            message: `Booking has been successfully canceled.`,
            status: 'success'
          })
          commit('CLEAR_BOOKING')
          commit('CLEAR_ENROLLED_USERS')
          commit('SET_BOOKING_SUCCESS', true)
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Booking cancel',
            message: `Booking hasn't been canceled.`,
            status: 'error'
          })
          commit('SET_BOOKING_SUCCESS', false)
          reject(error)
        })
      })
    },
    //
    createBulkBooking ({ commit, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        let dataArr = []
        for (let i = 0; i < data.length; i++) {
          dataArr.push({
            learnerId: data[i],
            eventId: getters.getSession.eventId,
            occurrenceId: getters.getSession.id
          })
        }

        axios({
          method: 'post',
          url: `${api.classroom}/bookings-bulk`,
          data: dataArr
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Bulk booking create',
            message: `Bulk booking has been successfully created.`,
            status: 'success'
          })

          commit('CLEAR_BOOKING')
          commit('CLEAR_ENROLLED_USERS')
          commit('SET_BOOKING_SUCCESS', true)
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Bulk booking create',
            message: `Bulk booking hasn't been created.`,
            status: 'error'
          })
          commit('SET_BOOKING_SUCCESS', false)
          reject(error)
        })
      })
    },
    createBulkBookAndEnrol ({ commit, getters, dispatch }, data) {
      return new Promise((resolve) => {
        let dataArr = []
        let requestsCount = 0
        let bookedAndEnrolledWithSuccess = 0
        let bookedAndEnrolledWithError = 0

        for (let i = 0; i < data.length; i++) {
          dataArr.push({
            profileId: data[i],
            occurrenceId: getters.getSession.id,
            eventId: getters.getSession.eventId
          })
        }

        for (let item of dataArr) {
          dispatch('createBookingWithEnrolment', item).then(() => {
            bookedAndEnrolledWithSuccess++
            commit('SET_BOOKING_CUSTOM_BOOKANDENROLRESULT', { key: 'success', value: bookedAndEnrolledWithSuccess })
          }).catch(() => {
            bookedAndEnrolledWithError++
            commit('SET_BOOKING_CUSTOM_BOOKANDENROLRESULT', { key: 'error', value: bookedAndEnrolledWithError })
          }).finally(() => {
            requestsCount++
            dispatch('defaultNotificationToastsAdd', {
              id: 'bulk-booking',
              title: 'Bulk booking',
              message: `${bookedAndEnrolledWithSuccess} of ${dataArr.length} have been successfully booked.`,
              status: ''
            })
            if (Number(dataArr.length) === requestsCount) {
              dispatch('defaultNotificationToastsAdd', {
                id: 'bulk-booking',
                title: 'Bulk booking',
                message: `Bulk booking has been finished successfully for ${bookedAndEnrolledWithSuccess} of ${dataArr.length} learners.`,
                status: 'success'
              })
              commit('CLEAR_BOOKING')
              commit('CLEAR_ENROLLED_USERS')
              commit('SET_BOOKING_SUCCESS', true)
              resolve()
            }
          })
        }
      })
    }
  }
}

export default ebsBooking
