import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { sortByKey } from '@/functions'
import { checkResponseNotFound } from '@/interceptorsHelpers'

const ebsVenue = {
  state: {
    data: {
      sharingWorkspaces: [],
      activeRelations: {
        event: false,
        session: false
      },
      address: {
        address_1: '',
        address_2: '',
        city: '',
        country: 'United Kingdom',
        county: '',
        formatted_address: '',
        lat: '',
        lng: '',
        postcode: '',
        type: 'automatic'
      },
      contactEmail: '',
      contactPhone: '',
      description: '',
      files: [],
      id: '',
      name: '',
      subdomains: [],
      status: 'draft'
    },
    default: {}
  },
  getters: {
    getVenue: state => {
      return state.data
    },
    //
    getVenueId: state => {
      return state.data.id
    },
    getVenueName: state => {
      return state.data.name
    },
    getVenueDescription: state => {
      return state.data.description
    },
    getVenueContactEmail: state => {
      return state.data.contactEmail
    },
    getVenueContactPhone: state => {
      return state.data.contactPhone
    },
    getVenueAddress: state => {
      return state.data.address
    },
    getVenueAddressFormatted: state => {
      return state.data.address.formatted_address
    },
    getVenueAddressCustomFormatted: state => {
      return `${state.data.address.address_1} ${state.data.address.address_2} ${state.data.address.county} ${state.data.address.city} ${state.data.address.postcode} ${state.data.address.country}`
    },
    getVenueAddressAddress1: state => {
      return state.data.address.address_1
    },
    getVenueAddressAddress2: state => {
      return state.data.address.address_2
    },
    getVenueAddressCity: state => {
      return state.data.address.city
    },
    getVenueAddressCountry: state => {
      return state.data.address.country
    },
    getVenueAddressCounty: state => {
      return state.data.address.county
    },
    getVenueAddressPostcode: state => {
      return state.data.address.postcode
    },
    getVenueAddressType: state => {
      return state.data.address.type
    },
    getVenueImages: state => {
      return state.data.files
    },
    getVenueStatus: state => {
      return state.data.status
    },
    getVenueRooms: state => {
      return sortByKey(state.data.rooms, 'name')
    },
    //
    getVenueHasActiveRelations: state => {
      const activeRelations = state.data.activeRelations
      let hasActiveRelations

      for (let item in activeRelations) {
        if (activeRelations[item]) hasActiveRelations = true
      }

      return hasActiveRelations
    },
    //
    getVenueAllowEdit: (state, getters) => {
      return !getters.getVenueHasActiveRelations && getters.getVenueStatus !== 'archived'
    },
    getVenueSharingWorkspaces: state => {
      return state.data.sharingWorkspaces
    }
  },
  mutations: {
    GET_VENUE (state, payload) {
      payload.sharingWorkspaces = []

      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    //
    CLEAR_VENUE_ADDRESS (state) {
      state.data.address.address_1 = ''
      state.data.address.address_2 = ''
      state.data.address.city = ''
      state.data.address.country = 'United Kingdom'
      state.data.address.county = ''
      state.data.address.formatted_address = ''
      state.data.address.lat = ''
      state.data.address.lng = ''
      state.data.address.postcode = ''
    },
    CLEAR_VENUE (state) {
      state.data = {
        sharingWorkspaces: [],
        activeRelations: {
          event: false,
          session: false
        },
        address: {
          address_1: '',
          address_2: '',
          city: '',
          country: 'United Kingdom',
          county: '',
          formatted_address: '',
          lat: '',
          lng: '',
          postcode: '',
          type: 'automatic'
        },
        contactEmail: '',
        contactPhone: '',
        description: '',
        files: [],
        id: '',
        name: '',
        subdomains: [],
        status: 'draft'
      }
      state.default = JSON.parse(JSON.stringify(state.data))
    },
    RESET_VENUE (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    SET_VENUE_NAME (state, payload) {
      state.data.name = payload
    },
    SET_VENUE_DESCRIPTION (state, payload) {
      state.data.description = payload
    },
    SET_VENUE_CONTACTEMAIL (state, payload) {
      state.data.contactEmail = payload
    },
    SET_VENUE_CONTACTPHONE (state, payload) {
      state.data.contactPhone = payload
    },
    SET_VENUE_ADDRESS_ADDRESS1 (state, payload) {
      state.data.address.address_1 = payload
    },
    SET_VENUE_ADDRESS_ADDRESS2 (state, payload) {
      state.data.address.address_2 = payload
    },
    SET_VENUE_ADDRESS_CITY (state, payload) {
      state.data.address.city = payload
    },
    SET_VENUE_ADDRESS_COUNTRY (state, payload) {
      state.data.address.country = payload
    },
    SET_VENUE_ADDRESS_COUNTY (state, payload) {
      state.data.address.county = payload
    },
    SET_VENUE_ADDRESS_FORMATTEDADDRESS (state, payload) {
      state.data.address.formatted_address = payload
    },
    SET_VENUE_ADDRESS_LAT (state, payload) {
      state.data.address.lat = payload
    },
    SET_VENUE_ADDRESS_LNG (state, payload) {
      state.data.address.lng = payload
    },
    SET_VENUE_ADDRESS_POSTCODE (state, payload) {
      state.data.address.postcode = payload
    },
    SET_VENUE_ADDRESS_TYPE (state, payload) {
      state.data.address.type = payload
    },
    SET_VENUE_IMAGES (state, payload) {
      const currentImages = JSON.parse(JSON.stringify(state.data.files))
      state.data.files = [...currentImages, ...payload]
    },
    SET_VENUE_STATUS (state, payload) {
      state.data.status = payload
    },
    SET_VENUE_SHARING_WORKSPACES (state, payload) {
      let sharingWorkspaces = JSON.parse(JSON.stringify(state.data.sharingWorkspaces))
      const id = payload.id
      const value = payload.value
      const indexOfWorkspace = sharingWorkspaces.indexOf(id)

      if (value === true) {
        if (indexOfWorkspace === -1) sharingWorkspaces.push(id)
      } else {
        if (indexOfWorkspace > -1) sharingWorkspaces.splice(indexOfWorkspace, 1)
      }

      state.data.sharingWorkspaces = sharingWorkspaces
    },
    //
    REMOVE_VENUE_IMAGES (state, key) {
      state.data.files.splice(key, 1)
    }
  },
  actions: {
    getVenue ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_VENUE')

        axios({
          method: 'get',
          url: `${api.classroom}/venues/${id}`
        }).then(response => {
          commit('GET_VENUE', response.data)
          resolve(response.data)
        }).catch(function (error) {
          checkResponseNotFound(error.response.status)
          reject(error.response)
        })
      })
    },
    //
    clearVenue (context) {
      context.commit('CLEAR_VENUE')
    },
    resetVenue (context) {
      context.commit('RESET_VENUE')
    },
    removeVenue ({ commit, dispatch }, id) {
      axios
        .delete(`${api.classroom}/venues/${id}`)
        .then(function () {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Venue remove',
            message: `Venue has been successfully removed.`,
            status: 'success'
          })
          commit('CLEAR_VENUE')
          commit('CLEAR_VENUES')
          commit('CLEAR_ROOMS')
          router.push({ name: 'ebs-venue-list' })
        }).catch(error => {
          const errorType = error.response.data.errors.message
          const usedByEvents = error.response.data.data.map(obj => `- ${obj.name}`)
          const usedByEventsList = usedByEvents.join('\r\n')
          let toastMessage

          if (errorType === 'item_in_use') {
            toastMessage = `Venue hasn't been removed because it's used by following events:\n${usedByEventsList}`
          } else {
            toastMessage = `Venue hasn't been removed`
          }

          dispatch('defaultNotificationToastsAdd', {
            title: 'Venue remove',
            message: toastMessage,
            status: 'error'
          })
        })
    },
    archiveVenue ({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'patch',
          url: `${api.classroom}/venues/archive/${id}`
        })
          .then(response => {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Venue archived',
              message: `Venue has been successfully archived.`,
              status: 'success'
            })
            resolve(response)
          }).catch(error => {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Venue not archived',
              message: `Venue has not been archived.`,
              status: 'error'
            })
            reject(error)
          })
      })
    },
    restoreVenue ({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'patch',
          url: `${api.classroom}/venues/restore/${id}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Venue restored',
            message: `Venue has been successfully restored.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Venue not restored',
            message: `Venue has not been restored.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    updateVenue ({ commit, state, dispatch }) {
      let venueId = state.data.id
      axios({
        method: 'patch',
        url: `${api.classroom}/venues`,
        data: state.data
      }).then(function () {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Venue update',
          message: `Venue has been successfully updated.`,
          status: 'success'
        })
        commit('CLEAR_VENUE')
        commit('CLEAR_VENUES')
        commit('CLEAR_ROOMS')
        commit('CLEAR_ROOM')
        commit('CLEAR_EVENT')
        commit('CLEAR_SESSION')
        router.push({ name: 'ebs-venue-show', params: { id: venueId } })
      }).catch(function () {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Venue update',
          message: `Venue hasn't been updated.`,
          status: 'error'
        })
      })
    },
    createVenue ({ commit, state, dispatch }) {
      axios({
        method: 'post',
        url: `${api.classroom}/venues`,
        data: state.data
      }).then(function (response) {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Venue create',
          message: `Venue has been successfully created.`,
          status: 'success'
        })
        commit('CLEAR_VENUES')
        commit('CLEAR_ROOMS')
        router.push({ name: 'ebs-venue-show', params: { id: response.data.id } })
      }).catch(function () {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Venue create',
          message: `Venue hasn't been created.`,
          status: 'error'
        })
      })
    },
    //
    setVenueName (context, value) {
      context.commit('SET_VENUE_NAME', value)
    },
    setVenueDescription (context, value) {
      context.commit('SET_VENUE_DESCRIPTION', value)
    },
    setVenueContactEmail (context, value) {
      context.commit('SET_VENUE_CONTACTEMAIL', value)
    },
    setVenueContactPhone (context, value) {
      context.commit('SET_VENUE_CONTACTPHONE', value)
    },
    setVenueAddressAddress1 (context, value) {
      context.commit('SET_VENUE_ADDRESS_ADDRESS1', value)
    },
    setVenueAddressAddress2 (context, value) {
      context.commit('SET_VENUE_ADDRESS_ADDRESS2', value)
    },
    setVenueAddressCity (context, value) {
      context.commit('SET_VENUE_ADDRESS_CITY', value)
    },
    setVenueAddressCountry (context, value) {
      context.commit('SET_VENUE_ADDRESS_COUNTRY', value)
    },
    setVenueAddressCounty (context, value) {
      context.commit('SET_VENUE_ADDRESS_COUNTY', value)
    },
    setVenueAddressFormattedaddress (context, value) {
      context.commit('SET_VENUE_ADDRESS_FORMATTEDADDRESS', value)
    },
    setVenueAddressLat (context, value) {
      context.commit('SET_VENUE_ADDRESS_LAT', value)
    },
    setVenueAddressLng (context, value) {
      context.commit('SET_VENUE_ADDRESS_LNG', value)
    },
    setVenueAddressPostcode (context, value) {
      context.commit('SET_VENUE_ADDRESS_POSTCODE', value)
    },
    setVenueAddressType (context, value) {
      context.commit('SET_VENUE_ADDRESS_TYPE', value)
    },
    setVenueImages (context, data) {
      data = data.map(value => {
        return {
          fileId: value
        }
      })
      context.commit('SET_VENUE_IMAGES', data)
    },
    setVenueStatus (context, value) {
      context.commit('SET_VENUE_STATUS', value)
    },
    //
    removeVenueImages (context, key) {
      context.commit('REMOVE_VENUE_IMAGES', key)
    },
    setVenueSharingWorkspaces ({ commit }, data) {
      commit('SET_VENUE_SHARING_WORKSPACES', data)
    }
  }
}

export default ebsVenue
