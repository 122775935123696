import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const ebsTrainers = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getTrainers: state => {
      return state.data
    },
    getTrainersMappedLabelValue: state => {
      const rawTrainers = state.data
      if (!rawTrainers.length) return []
      return rawTrainers.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.name,
          value: item.id
        }
      })
    },
    getTrainersMappedIdNameEmail: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          email: item.email
        }
      })
    },
    getTrainersPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    SET_TRAINERS (state, payload) {
      state.data = payload
    },
    SET_TRAINERS_PAGINATION (state, payload) {
      state.pagination = payload
    },
    CLEAR_TRAINERS (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    CLEAR_TRAINERS_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    getTrainersActive ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const isCurrentIdsRequest = queryUrlParams ? queryUrlParams.includes('filters[ids]') : false

        let roleStatusFilterArr = ['active']
        let profileStatusFilterArr = ['active']

        if (isCurrentIdsRequest) {
          if (isCurrentIdsRequest) {
            roleStatusFilterArr.push('inactive')
          }
        }

        const roleStatusUniqueArr = [...new Set(roleStatusFilterArr)]
        const profileStatusUniqueArr = [...new Set(profileStatusFilterArr)]

        const roleStatusFilter = roleStatusUniqueArr.length ? `&filters[roleStatus]=${roleStatusUniqueArr.join(',')}` : ''
        const profileStatusFilter = profileStatusUniqueArr.length ? `&filters[profileStatus]=${profileStatusUniqueArr.join(',')}` : ''

        commit('CLEAR_TRAINERS')
        commit('CLEAR_TRAINERS_PAGINATION')

        axios
          .get(`${api.classroom}/trainers${queryUrlParams}${profileStatusFilter}${roleStatusFilter}`)
          .then(function (response) {
            commit('SET_TRAINERS', response.data.data)
            commit('SET_TRAINERS_PAGINATION', response.data.pagination)
            resolve(response)
          }).catch(error => {
            reject(error)
          })
      })
    },
    getTrainersAll ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'

        let roleStatusFilterArr = ['active', 'inactive']
        let profileStatusFilterArr = ['active', 'invited', 'cancelled', 'rejected']

        const roleStatusUniqueArr = [...new Set(roleStatusFilterArr)]
        const profileStatusUniqueArr = [...new Set(profileStatusFilterArr)]

        const roleStatusFilter = roleStatusUniqueArr.length ? `&filters[roleStatus]=${roleStatusUniqueArr.join(',')}` : ''
        const profileStatusFilter = profileStatusUniqueArr.length ? `&filters[profileStatus]=${profileStatusUniqueArr.join(',')}` : ''

        commit('CLEAR_TRAINERS')
        commit('CLEAR_TRAINERS_PAGINATION')

        axios
          .get(`${api.classroom}/trainers${queryUrlParams}${profileStatusFilter}${roleStatusFilter}`)
          .then(function (response) {
            commit('SET_TRAINERS', response.data.data)
            commit('SET_TRAINERS_PAGINATION', response.data.pagination)
            resolve(response)
          }).catch(error => {
            reject(error)
          })
      })
    }
  }
}

export default ebsTrainers
