<template>
    <div class="header__top_account_menu_box_actions">
        <component-button
            :size="'full'"
            :class="['header__top_nav_item', 'header__top_nav_item--logout-user']"
            :name="'header-top-account-menu-box-actions-logout'"
            :theme="'primary'"
            @click="userLogOut"
        >{{ $t('header.topAccountMenuBox.buttons.logout') }}
        </component-button>
        <template v-if="isLoggedInUserInpersonated">
            <component-button
                :size="'full'"
                :class="['header__top_nav_item', 'header__top_nav_item--uninpersonate']"
                :name="'header-top-account-menu-box-actions-uninpersonate'"
                @click="userUninpersonate"
            >Back to admin
            </component-button>
        </template>
        <template v-if="isOldLMSToken">
            <component-button
                :size="'full'"
                :class="'header__top_account_menu_box_actions_backtolms--lms'"
                :theme="'default'"
                @click="backToLMSUrl"
                :name="'header-top-account-menu-box-actions-back'"
            >{{ $t('header.topAccountMenuBox.buttons.backToLms') }}
            </component-button>
        </template>
    </div>
</template>

<script>
import ComponentButton from '@/components/default/shared/ComponentButton'
import { User } from '@/User'

export default {
  name: 'header-top-account-menu-box-actions',
  components: { ComponentButton },
  data () {
    return {
      user: new User()
    }
  },
  computed: {
    isOldLMSToken () {
      return this.$store.getters.getAuthUserIsSecondStageTokenOldLMS
    },
    isLoggedInUserInpersonated () {
      return this.user.isInpersonated()
    },
    secondStageToken () {
      return this.$store.getters.getAuthUserSecondStageTokenData
    }
  },
  watch: {
    secondStageToken () {
      this.user = new User()
    }
  },
  methods: {
    userLogOut () {
      this.$store.dispatch('authUserLogout')
    },
    userUninpersonate () {
      this.$store.dispatch('authUserUnimpersonateUser')
    },
    backToLMSUrl () {
      const toLMSUrl = this.$store.getters.getAuthUserSecondStageTokenDataBackToLMSUrl
      location.replace(toLMSUrl)
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/layout/header/headertopaccountmenuboxactions";
</style>
