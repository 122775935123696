var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'component',
        'component__text',
        _vm.size ? `component__text--${_vm.size}` : '',
        _vm.heading ? `component__text--heading` : '',
        _vm.danger ? `component__text--danger` : '',
        _vm.success ? `component__text--success` : '',
        _vm.theme ? `component__text--${_vm.theme}` : '',
        _vm.margin ? `component__text--margin-${_vm.margin}` : '',
        _vm.weight ? `component__text--weight-${_vm.weight}` : '',
        _vm.nowrap ? `component__text--nowrap` : ''
    ],on:{"click":_vm.performEmitClick}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-vcentered"},[(_vm.icon)?[_c('div',{staticClass:"column is-narrow"},[_c('div',{staticClass:"component__text_icon"},[_c('v-icon',{attrs:{"name":_vm.iconName}})],1)])]:_vm._e(),_c('div',{staticClass:"column"},[_c('div',{class:[
                      'component__text_inner',
                      { 'component__text_inner--not-expanded' : _vm.readMoreActive && _vm.contentHigherThanLimit && !_vm.isReadMoreExpand}
                    ],style:({ maxHeight: _vm.readMoreActive && _vm.contentHigherThanLimit && !_vm.isReadMoreExpand ? _vm.readMoreLimit + 'px' : '' })},[(_vm.html)?[(_vm.isSlot)?[_vm._t("default")]:[_c('div',{domProps:{"innerHTML":_vm._s(_vm.content)}})]]:[(_vm.isSlot)?[_c('p',[_vm._t("default")],2)]:[_c('p',[_vm._v(_vm._s(_vm.content))])]]],2),(_vm.readMoreActive && _vm.contentHigherThanLimit)?_c('component-button',{staticClass:"component__text_readmore-button",attrs:{"name":'component-text-readmore-button',"text":true,"underline":true,"size":'medium'},on:{"click":_vm.switchReadMore}},[_vm._v(_vm._s(_vm.isReadMoreExpand ? 'Read less' : 'Read more')+" ")]):_vm._e()],1)],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }