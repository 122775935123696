<template>
    <div class="form form__datebetween">
        <div class="columns is-variable">
            <template v-if="rangeActive">
                <div class="column">
                    <div class="form__datebetween_range">
                        <component-button
                            v-for="(rangeItem, index) in rangeItems"
                            :key="index"
                            :name="`form-datebetween-range-button-${rangeItem.value}`"
                            :text="true"
                            :size="'tiny'"
                            :theme="range === rangeItem.value ? 'primary' : ''"
                            @click="setRange(rangeItem.value)"
                        >{{ rangeItem.label }}</component-button>
                    </div>
                </div>
            </template>
            <div class="column">
                <component-datepicker
                    :label="$t('forms.date.labels.dateFrom')"
                    :highlighted="highlighted"
                    :value="from"
                    :open-to-month="toMonth"
                    :disabled="rangeOnly"
                    @datepicker-selected="setFrom"
                    @changed-month="changedFromMonth"
                />
            </div>
            <div class="column">
                <component-datepicker
                    :label="$t('forms.date.labels.dateTo')"
                    :highlighted="highlighted"
                    :value="to"
                    :open-from-month="fromMonth"
                    :disabled="rangeOnly"
                    @datepicker-selected="setTo"
                    @changed-month="changedToMonth"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ComponentDatepicker from '@/components/default/shared/ComponentDatepicker'
import { fromKebabCaseToString, toCapitalize } from '@/functions'

export default {
  name: 'form-datebetween',
  components: {
    ComponentButton: () => import('@/components/default/shared/ComponentButton'),
    ComponentDatepicker
  },
  data () {
    return {
      from: '',
      fromMonth: '',
      to: '',
      toMonth: '',
      range: ''
    }
  },
  props: {
    rangeActive: {
      type: Boolean,
      default: false
    },
    rangeOnly: {
      type: Boolean,
      default: false
    },
    rangeItems: {
      type: Array
    },
    name: {
      type: String,
      required: true
    },
    preselected: {
      type: [Array, Object, String]
    }
  },
  computed: {
    highlighted () {
      let dates = {}
      if (this.from && this.to) {
        dates.from = new Date(this.from)
        dates.to = new Date(this.to)
      }
      return dates
    }
  },
  watch: {
    from (value) {
      this.emitDateFrom(value)
    },
    to (value) {
      this.emitDateTo(value)
    }
  },
  methods: {
    handleEmitting () {
      let str
      if (this.range) {
        str = toCapitalize(fromKebabCaseToString(this.range))
      } else {
        if (!this.from && !this.to) {
          str = ''
        } else if (!this.from) {
          str = `to: ${this.toLocaleDateTime(this.to)}`
        } else if (!this.to) {
          str = `from: ${this.toLocaleDateTime(this.from)}`
        } else {
          str = this.toLocaleDateTime(this.from) + ' - ' + this.toLocaleDateTime(this.to)
        }
      }

      this.emitSelected(str)
      this.emitFilter({
        from: this.from,
        to: this.to,
        range: this.range
      })
    },
    clearData () {
      this.setFrom(null)
      this.setTo(null)
    },
    //
    setRange (value) {
      const todaysDate = new Date()

      this.range = value
      this.to = this.toISODate(todaysDate)

      if (value === 'last-7-days') {
        this.from = this.toISODate(new Date(todaysDate.setDate(todaysDate.getDate() - 6)))
      } else if (value === 'last-30-days') {
        this.from = this.toISODate(new Date(todaysDate.setDate(todaysDate.getDate() - 29)))
      } else if (value === 'last-12-months') {
        this.from = this.toISODate(new Date(todaysDate.setMonth(todaysDate.getMonth() - 12)))
      }

      this.handleEmitting()
    },
    setFrom (date) {
      this.range = ''
      this.from = this.toISODate(date)
      if (this.to && this.to < date) {
        this.to = this.toISODate(date)
      }
      this.handleEmitting()
    },
    setTo (date) {
      this.range = ''
      this.to = this.toISODate(date)
      if (this.from && this.from > date) {
        this.from = this.toISODate(date)
      }
      this.handleEmitting()
    },
    changedFromMonth (date) {
      if (date.constructor === Date) {
        this.fromMonth = date
      } else if (date.constructor === Object) {
        this.fromMonth = new Date(date.timestamp)
      }
    },
    changedToMonth (date) {
      if (date.constructor === Date) {
        this.toMonth = date
      } else if (date.constructor === Object) {
        this.toMonth = new Date(date.timestamp)
      }
    },
    //
    emitSelected (data) {
      this.$emit('emit-selected', data)
    },
    emitDateFrom (data) {
      this.$emit('emit-date-from', data)
    },
    emitDateTo (data) {
      this.$emit('emit-date-to', data)
    },
    emitRange (data) {
      this.$emit('emit-range', data)
    },
    emitSidebarValue (data) {
      this.$emit('emit-range-value', data)
    },
    emitFilter (data) {
      this.$emit('emit-filter', data)
    },
    initializePreselectedValue () {
      if (!this.preselected) return
      if (this.preselected.range) {
        this.setRange(this.preselected.range)
      } else {
        if (this.preselected.from) {
          this.setFrom(this.preselected.from)
        }
        if (this.preselected.to) {
          this.setTo(this.preselected.to)
        }
      }
    }
  },
  created () {
    this.initializePreselectedValue()
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/ebs/filters/filterdatebetween";
</style>
