<template>
    <div class="filter__more_menu">
        <div class="filter__more_menu_box">
            <div class="filter__more_menu_box_items">
                <template v-for="(filter, index) in filterMore">
                    <component :is="'filter-more-menu-' + filter" :key="index"/>
                </template>
            </div>
            <div class="filter__more_menu_box_actions">
                <div class="columns is-variable">
                    <div class="column"></div>
                    <div class="column is-narrow">
                        <div class="columns is-variable is-2">
                            <div class="column">
                                <button
                                    type="button"
                                    class="btn btn--small btn--tertiary btn--border"
                                    id="filter-more-menu-box-actions-clear"
                                >{{ $t('filter.moreMenu.buttons.clear') }}</button>
                            </div>
                            <div class="column">
                                <button
                                        type="button"
                                        class="btn btn--small btn--primary btn--border"
                                        @click="$parent.switchMenu()"
                                        id="filter-more-menu-box-actions-apply"
                                >{{ $t('filter.moreMenu.buttons.apply') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="filter__more_menu_overlay"></div>
    </div>
</template>

<script>
import FilterMoreMenuCost from '@/components/default/filters/FilterMoreMenuCost'
import FilterMoreMenuCapacity from '@/components/default/filters/FilterMoreMenuCapacity'

export default {
  name: 'filter-more-menu',
  components: {
    FilterMoreMenuCapacity,
    FilterMoreMenuCost
  },
  props: {
    filterMore: {
      type: Array
    }
  }
}
</script>
