import axios from 'axios'
import api from '@/api'
import router from '@/router'
import { eventbus } from '@/main'

const data = {
  data: {
    id: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    roles: [],
    profiles: []
  },
  default: ''
}

const lmsSuperadminAdmin = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminAdminId (state) {
      return state.data.id
    },
    getLmsSuperadminAdminName (state) {
      return `${state.data.firstName} ${state.data.lastName}`
    },
    getLmsSuperadminAdminFirstName (state) {
      return state.data.firstName
    },
    getLmsSuperadminAdminLastName (state) {
      return state.data.lastName
    },
    getLmsSuperadminAdminEmail (state) {
      return state.data.emailAddress
    },
    getLmsSuperadminAdminAlreadyIsSuperadmin (state) {
      return state.data.roles.includes('superadmin')
    },
    getLmsSuperadminAdminHasActiveProfile (state) {
      return state.data.profiles.findIndex(obj => obj.status === 'active') > -1
    }
  },
  mutations: {
    LMS_SUPERADMIN_ADMIN_GET (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_ADMIN_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    LMS_SUPERADMIN_ADMIN_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_ADMIN_SET_FIRSTNAME (state, payload) {
      state.data.firstName = payload
    },
    LMS_SUPERADMIN_ADMIN_SET_LASTNAME (state, payload) {
      state.data.lastName = payload
    },
    LMS_SUPERADMIN_ADMIN_SET_ID (state, payload) {
      state.data.id = payload
    },
    LMS_SUPERADMIN_ADMIN_SET_ROLES (state, payload) {
      state.data.roles = payload
    },
    LMS_SUPERADMIN_ADMIN_SET_PROFILES (state, payload) {
      state.data.profiles = payload
    },
    LMS_SUPERADMIN_ADMIN_SET_EMAIL (state, payload) {
      state.data.emailAddress = payload
    }
  },
  actions: {
    lmsSuperadminAdminCheckEmail ({ commit, getters, dispatch, state }) {
      return new Promise((resolve, reject) => {
        const authUserCheckExistsSSO = dispatch('authUserCheckExistsSSO', getters.getLmsSuperadminAdminEmail)
        const invitedUser = state.data.id
        if (invitedUser) {
          commit('LMS_SUPERADMIN_ADMIN_SET_FIRSTNAME', '')
          commit('LMS_SUPERADMIN_ADMIN_SET_LASTNAME', '')
        }

        authUserCheckExistsSSO.then(response => {
          if (response === null) reject(new Error('null'))
          const firstName = response.firstName || ''
          const lastName = response.lastName || ''
          const id = response.id || ''
          const roles = response.roles || ''
          const profiles = response.profiles || ''
          commit('LMS_SUPERADMIN_ADMIN_SET_FIRSTNAME', firstName)
          commit('LMS_SUPERADMIN_ADMIN_SET_LASTNAME', lastName)
          commit('LMS_SUPERADMIN_ADMIN_SET_ID', id)
          commit('LMS_SUPERADMIN_ADMIN_SET_ROLES', roles)
          commit('LMS_SUPERADMIN_ADMIN_SET_PROFILES', profiles)
          resolve(response)
        }).catch(error => {
          commit('LMS_SUPERADMIN_ADMIN_SET_ID', '')
          commit('LMS_SUPERADMIN_ADMIN_SET_ROLES', [])
          commit('LMS_SUPERADMIN_ADMIN_SET_PROFILES', [])
          reject(error)
        })
      })
    },
    lmsSuperadminAdminCreate ({ commit, state, dispatch }) {
      return new Promise(() => {
        const id = state.data.id
        const emailAddress = state.data.emailAddress
        const firstName = state.data.firstName
        const lastName = state.data.lastName
        const authAdminSignUpSuperadmin = () => {
          return dispatch('authAdminSignUpSuperadmin', { id, emailAddress, firstName, lastName })
        }
        const successMessage = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Super admin create',
            message: `You have successfully created super admin role.`,
            status: 'success'
          })
        }
        const errorMessage = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Super admin create',
            message: `Something went wrong while creating super admin role. Try again or contact an administrator.`,
            status: 'error'
          })
        }
        const successAction = () => {
          commit('LMS_SUPERADMIN_USER_CLEAR')
          commit('LMS_SUPERADMIN_USERS_CLEAR')
          router.push({ name: 'lms-superadmin-user-list' })
        }

        authAdminSignUpSuperadmin().then(() => {
          successAction()
          successMessage()
        }).catch(() => {
          errorMessage()
        }).finally(() => {
          eventbus.$eventBus.$emit('stop-add-admin')
        })
      })
    },
    lmsSuperadminAdminUpdate ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('authAdminUpdateUserDetails', data).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminAdminGet ({ commit, dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.sso}/v2/users/${id}`
        }).then((response) => {
          commit('LMS_SUPERADMIN_ADMIN_GET', response.data)
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Admin get',
            message: `Something went wrong while downloading admin details. Please try again or contact an administrator.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminAdminRemove ({ dispatch }, value) {
      return new Promise((resolve, reject) => {
        const userId = value
        const authUserUpdateRole = () => {
          return dispatch('authUserUpdateRole', { userId: userId, role: 'superadmin', method: 'delete' })
        }
        const authAdminSetMfaRequiredCognito = () => {
          return dispatch('authAdminSetMfaRequiredCognito', { userId: userId, enabled: false })
        }

        authUserUpdateRole().then(() => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Super admin remove',
            message: `You have successfully removed super admin.`,
            status: 'success'
          })
          authAdminSetMfaRequiredCognito()
          resolve()
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Super admin remove',
            message: `Something went wrong while removing super admin. Try again or contact an administrator.`,
            status: 'error'
          })
          reject(new Error(error))
        })
      })
    },
    lmsSuperadminAdminReset ({ commit }) {
      commit('LMS_SUPERADMIN_ADMIN_RESET')
    },
    lmsSuperadminAdminClear ({ commit }) {
      commit('LMS_SUPERADMIN_ADMIN_CLEAR')
    },
    //
    lmsSuperadminAdminSetId ({ commit }, value) {
      commit('LMS_SUPERADMIN_ADMIN_SET_ID', value)
    },
    lmsSuperadminAdminSetFirstName ({ commit }, value) {
      commit('LMS_SUPERADMIN_ADMIN_SET_FIRSTNAME', value)
    },
    lmsSuperadminAdminSetLastName ({ commit }, value) {
      commit('LMS_SUPERADMIN_ADMIN_SET_LASTNAME', value)
    },
    lmsSuperadminAdminSetEmail ({ commit }, value) {
      commit('LMS_SUPERADMIN_ADMIN_SET_EMAIL', value)
    }
  }
}

export default lmsSuperadminAdmin
