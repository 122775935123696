import { TokenHelper } from '@/TokenHelper'
import axios from 'axios'
import api from '@/api'

const data = {
  data: '',
  default: ''
}

const authAdmin = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {},
  mutations: {},
  actions: {
    authAdminSignUpSuperadmin ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const emailAddress = data.emailAddress
        const authUserCheckExistsSSO = () => {
          return dispatch('authUserCheckExistsSSO', emailAddress)
        }
        const authUserUpdateRole = (userId, role, method) => {
          return dispatch('authUserUpdateRole', { userId, role, method })
        }
        const authAdminSetMfaRequiredCognito = (userId, enabled) => {
          dispatch('authAdminSetMfaRequiredCognito', { userId: userId, enabled: enabled })
        }

        authUserCheckExistsSSO().then((response) => {
          const userId = response.id
          const authUserCheckExistsCognito = () => dispatch('authAdminGetUserCognito', userId)

          authUserUpdateRole(userId, 'superadmin').then(() => {
            authUserCheckExistsCognito().then(() => {
              authAdminSetMfaRequiredCognito(userId, true)
              resolve()
            }).catch(error => {
              authUserUpdateRole(userId, 'superadmin', 'delete').finally(() => {
                reject(error)
              })
            })
          }).catch((error) => {
            reject(new Error(error))
          })
        })
      })
    },
    authAdminSetMFACognito ({ getters }, data) {
      return new Promise((resolve, reject) => {
        const userId = data.userId || getters.getAuthUserFirstStageTokenDataID
        const enabled = data.enabled || false

        axios({
          method: 'post',
          url: `${api.sso}/v2/cognito/set-user-mfa-preference`,
          data: {
            userId,
            enabled
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    authAdminGetMFASecretCode ({ dispatch }) {
      return new Promise((resolve, reject) => {
        const accessToken = TokenHelper.getToken('accessToken')

        axios({
          method: 'post',
          url: `${api.sso}/v2/cognito/get-mfa-secret-code`,
          data: {
            accessToken
          }
        }).then(response => {
          dispatch('authUserSetMfaSecret', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    authAdminSetMfaEnabledCognito ({ getters }, data) {
      return new Promise((resolve, reject) => {
        const userId = data.userId || getters.getAuthUserFirstStageTokenDataID
        const enabled = data.enabled || false

        axios({
          method: 'post',
          url: `${api.sso}/v2/cognito/set-user-mfa-enabled`,
          data: {
            userId,
            enabled
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    authAdminSetMfaRequiredCognito ({ getters }, data) {
      return new Promise((resolve, reject) => {
        const userId = data.userId || getters.getAuthUserFirstStageTokenDataID
        const enabled = data.enabled || false

        axios({
          method: 'post',
          url: `${api.sso}/v2/cognito/set-user-mfa-required`,
          data: {
            userId,
            enabled
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    authAdminMfaDisable ({ getters, dispatch }, value) {
      return new Promise((resolve, reject) => {
        const userId = value || getters.getAuthUserFirstStageTokenDataID
        const authAdminSetMfaEnabledCognito = () => {
          return dispatch('authAdminSetMfaEnabledCognito', { enabled: false, userId: userId })
        }
        const authAdminSetMFACognito = () => {
          return dispatch('authAdminSetMFACognito', { enabled: false, userId: userId })
        }
        const authUserRefreshFirstStageToken = () => {
          return dispatch('authUserRefreshFirstStageToken')
        }
        const defaultNotificationToastsAdd = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: '2FA disabled',
            message: `You have successfully disabled two factor authentication security.`,
            status: 'success'
          })
        }
        authAdminSetMFACognito().then(() => {
          authAdminSetMfaEnabledCognito().then(() => {
            authUserRefreshFirstStageToken().then(() => {
              defaultNotificationToastsAdd()
              resolve()
            }).catch(error => {
              reject(error)
            })
          }).catch(error => {
            reject(error)
          })
        }).catch(error => {
          reject(error)
        })
      })
    },
    authAdminMfaReset ({ getters, dispatch }, value) {
      return new Promise((resolve, reject) => {
        const userId = value || getters.getAuthUserFirstStageTokenDataID
        const authAdminSetMFACognito = () => {
          return dispatch('authAdminSetMFACognito', { enabled: false, userId: userId })
        }
        const authAdminSetMfaEnabledCognito = () => {
          return dispatch('authAdminSetMfaEnabledCognito', { enabled: false, userId: userId })
        }
        const defaultNotificationToastSetSuccess = () => {
          return dispatch('defaultNotificationToastsAdd', {
            title: '2FA reset',
            message: `You have successfully reset two factor authentication for the user.`,
            status: 'success'
          })
        }
        const defaultNotificationToastSetError = () => {
          return dispatch('defaultNotificationToastsAdd', {
            title: '2FA reset',
            message: `There were some issues due to resetting user's 2FA. Please try again or contact an administrator.`,
            status: 'error'
          })
        }
        const defaultNotificationToastSetErrorLimit = () => {
          return dispatch('defaultNotificationToastsAdd', {
            title: '2FA reset',
            message: `You have reached the limit of attempts to reset user's 2FA. Please try again later or contact an administrator.`,
            status: 'error'
          })
        }
        const defaultNotificationToastSetErrorNotFound = () => {
          return dispatch('defaultNotificationToastsAdd', {
            title: '2FA reset',
            message: `2FA can't be reset for this user as he didn't registered himself yet.`,
            status: 'error'
          })
        }

        authAdminSetMFACognito().then(() => {
          authAdminSetMfaEnabledCognito().then(() => {
            defaultNotificationToastSetSuccess()
            resolve()
          })
        }).catch((error) => {
          const errorCode = error.response.data.errors.code
          if (errorCode === 'LimitExceededException') {
            defaultNotificationToastSetErrorLimit()
          } else if (errorCode === 'entity_not_found_error') {
            defaultNotificationToastSetErrorNotFound()
          } else {
            defaultNotificationToastSetError()
          }
          reject(error)
        })
      })
    },
    authAdminPasswordReset ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const userId = data
        const defaultNotificationToastSetSuccess = () => {
          return dispatch('defaultNotificationToastsAdd', {
            title: 'Password reset sent',
            message: `Password reset instructions have been sent to the user’s email address.`,
            status: 'success'
          })
        }
        const defaultNotificationToastSetError = () => {
          return dispatch('defaultNotificationToastsAdd', {
            title: 'Password reset',
            message: `There were some issues due to resetting user's password. Please try again or contact an administrator.`,
            status: 'error'
          })
        }
        const defaultNotificationToastSetErrorLimit = () => {
          return dispatch('defaultNotificationToastsAdd', {
            title: 'Password reset',
            message: `You have reached the limit of attempts to reset user's password. Please try again later or contact an administrator.`,
            status: 'error'
          })
        }
        const defaultNotificationToastSetErrorNotFound = () => {
          return dispatch('defaultNotificationToastsAdd', {
            title: 'Password reset',
            message: `The password cannot be reset for this user as they have not yet joined the workspace.`,
            status: 'error'
          })
        }

        axios({
          method: 'post',
          url: `${api.sso}/v2/cognito/reset-user-password`,
          data: {
            userId
          }
        }).then(response => {
          defaultNotificationToastSetSuccess()
          resolve(response)
        }).catch(error => {
          const errorCode = error.response.data.errors.code
          if (errorCode === 'LimitExceededException') {
            defaultNotificationToastSetErrorLimit()
          } else if (errorCode === 'entity_not_found_error') {
            defaultNotificationToastSetErrorNotFound()
          } else {
            defaultNotificationToastSetError()
          }
          reject(error)
        })
      })
    },
    authAdminGetUserCognito (context, value) {
      return new Promise((resolve, reject) => {
        const userId = value

        axios({
          method: 'get',
          url: `${api.sso}/v2/cognito/get-user/${userId}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    authAdminUpdateUserDetails (ctx, data) {
      return new Promise((resolve, reject) => {
        const userId = data.id
        const name = data.firstName
        const familyName = data.lastName

        axios({
          method: 'post',
          url: `${api.sso}/v2/cognito/update-name-attributes`,
          data: {
            userId,
            name,
            familyName
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default authAdmin
