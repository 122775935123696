import { eventbus } from '@/main'
import { TokenHelper } from '@/TokenHelper'
import { User } from './User'

import {
  checkResponseUrlValidate,
  checkResponseUrlIgnored,
  emitStopRequest,
  emitErrorNotification,
  checkResponseNoAccess
} from '@/interceptorsHelpers'
import store from '@/store'
import { UUID } from './UUID'

export function requestInterceptor (config) {
  const user = new User()
  const secondStageToken = TokenHelper.getToken('secondStageToken')
  const isSecondStageTokenFromOldLMS = user.isTokenFromOldLms()

  config.headers.common['X-Service-Name'] = 'UI'
  config.headers.common['X-Correlation-Id'] = UUID.v4()

  // checks if url is one of the opened endpoints
  if (
    config.url.includes('amazonaws.com') ||
    config.url.includes('api/v3/users/exists') ||
    config.url.includes('api/v2/users/') ||
    config.url.includes('api/v2/cognito/sign-up') ||
    config.url.includes('api/v2/cognito.open') ||
    (config.url.includes('api/v2/invitations/') && !config.url.includes('api/v2/invitations/stats')) ||
    config.url.includes('api/jwt/validate') ||
    config.url.includes('api/learner-subscriptions/') ||
    config.url.includes('api/subdomains/') ||
    config.url.includes('api/self-registration/key-details') ||
    config.url.includes('api/self-registration/register-user') ||
    config.url.includes('api/self-registration/workspace-details') ||
    config.url.includes('api/self-registration/confirm-signup') ||
    (config.url.includes('api/organisation-unit/') && config.method === 'get') ||
    config.url.includes('api/workspace-groups/') ||
    (config.url.includes('api/v2/custom-fields/') && config.method === 'get') ||
    config.url.match(/api\/v2\/\w*-invitation/) ||
    config.url.match(/^(?!.*api\b).*\/report\/[\S]+\/result\/[\S]+/) ||
    config.url.match(`/api/saml/login-methods`) ||
    config.url.match(`/api/saml/login/`)
  ) {
    return config
  }

  // checks if we're using token from old or new LMS
  if (secondStageToken && isSecondStageTokenFromOldLMS) {
    return store.dispatch('authUserValidateSecondStageToken', { allowLogoutOldLMS: true }).then((response) => {
      config.headers.Authorization = response
      return config
    })
  } else {
    if (
      config.url.includes('api/v2/user/login') ||
      config.url.includes('api/v2/exchange/token')
    ) {
      return store.dispatch('authUserValidateFirstStageToken', { preventNotification: true, preventRedirect: true }).then((response) => {
        config.headers.Authorization = response
        return config
      }).catch(() => {
        store.dispatch('authUserLogout', { preventNotification: true })
      })
    } else {
      return store.dispatch('authUserValidateFirstStageToken').then(() => {
        return store.dispatch('authUserValidateSecondStageToken').then((response) => {
          config.headers.Authorization = response
          return config
        })
      })
    }
  }
}

export function responseInterceptor (response) {
  const responseUrl = response.config.url

  if (checkResponseUrlIgnored(responseUrl)) return response
  eventbus.$emit('stop-sending')
  return response
}

export function errorInterceptor (response) {
  if (!response) {
    return
  }

  const responseUrl = response.config.url
  const responseMessage = response.data.errors ? response.data.errors.message : response.data.message

  if (checkResponseUrlIgnored(responseUrl)) return response
  if (checkResponseUrlValidate(responseUrl)) {
    emitStopRequest()
    return response
  }

  emitStopRequest()
  emitErrorNotification({ responseUrl, responseMessage })
  checkResponseNoAccess(responseMessage)

  return response
}
