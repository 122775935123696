export class UUID {
  static v4 () {
    let h = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
    let k = ['x', 'x', 'x', 'x', 'x', 'x', 'x', 'x', '-', 'x', 'x', 'x', 'x', '-', '4', 'x', 'x', 'x', '-', 'y', 'x', 'x', 'x', '-', 'x', 'x', 'x', 'x', 'x', 'x', 'x', 'x', 'x', 'x', 'x', 'x']
    let u = ''; var i = 0; var rb = Math.random() * 0xffffffff | 0
    while (i++ < 36) {
      let c = k[i - 1]; let r = rb & 0xf; let v = c === 'x' ? r : (r & 0x3 | 0x8)
      u += (c === '-' || c === '4') ? c : h[v]
      rb = i % 8 === 0 ? Math.random() * 0xffffffff | 0 : rb >> 4
    }
    return u
  }
  static validate (data) {
    return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(data)
  }
}
