<template>
    <div class="list list__table_check">
        <label
            :for="name + emitCheckboxName"
        >
            <input
                ref="input"
                type="checkbox"
                :id="name + emitCheckboxName | toKebabCase"
                :key="index"
                :checked="checked"
                :disabled="disabled"
                @click="emitChecked"
            >
            <span></span>
        </label>
    </div>
</template>

<script>
export default {
  name: 'list-table-check',
  props: {
    // must contain unique index value, e.g. `item-${index}`
    index: {
      type: [Number, String],
      required: true
    },
    allowSingle: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: '',
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    emitCheckboxName: {
      type: String,
      default: 'checkbox-action'
    },
    emitListName: {
      type: String,
      default: 'list-action'
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.$eventBus.$off(this.emitListName)
  },
  mounted () {
    this.$eventBus.$on(this.emitListName, this.setChecked)
  },
  methods: {
    setChecked: function (value) {
      if (value) {
        if (!this.$refs.input.checked) {
          this.$refs.input.click()
        }
      } else {
        if (this.$refs.input.checked) {
          this.$refs.input.click()
        }
      }
    },
    emitChecked: function (event) {
      this.$emit('emit-check', { index: this.index, value: event.target.checked })
      this.$eventBus.$emit(this.emitCheckboxName, { index: this.index, value: event.target.checked })
    }
  }
}
</script>
