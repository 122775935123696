import axios from 'axios'
import api from '@/api'
import router from '@/router'

const fileDataStructure = {
  alt: '',
  dateUploaded: '',
  description: '',
  fileType: '',
  id: '',
  isPrivate: false,
  ownerId: '',
  originalFileName: '',
  private: '',
  presignedUrls: '',
  siblings: {
    prevId: '',
    nextId: ''
  },
  title: '',
  uploaderId: ''
}

const data = {
  data: JSON.parse(JSON.stringify(fileDataStructure)),
  default: ''
}

const storageFile = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getStorageFile (state) {
      return state.data
    },
    getStorageFileId (state) {
      return state.data.id
    },
    getStorageFileTitle (state) {
      return state.data.title
    },
    getStorageFileDescription (state) {
      return state.data.description
    },
    getStorageFileAlt (state) {
      return state.data.alt
    },
    getStorageFileIsPrivate (state) {
      return state.data.isPrivate
    },
    getStorageFileFileType (state) {
      return state.data.fileType
    },
    getStorageFileOriginalFileName (state) {
      return state.data.originalFileName
    },
    getStorageFileDateUploaded (state) {
      return state.data.dateUploaded
    },
    getStorageFilePresignedUrls (state) {
      return state.data.presignedUrls
    },
    getStorageFileUploaderId (state) {
      return state.data.uploaderId
    },
    getStorageFileSiblingsPrevId (state) {
      return state.data.siblings ? state.data.siblings.prevId : ''
    },
    getStorageFileSiblingsNextId (state) {
      return state.data.siblings ? state.data.siblings.nextId : ''
    },
    getStorageFileDataStructure () {
      return fileDataStructure
    }
  },
  mutations: {
    STORAGE_FILE_SET (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    STORAGE_FILE_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    STORAGE_FILE_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    STORAGE_FILE_SET_TITLE (state, payload) {
      state.data.title = payload
    },
    STORAGE_FILE_SET_DESCRIPTION (state, payload) {
      state.data.description = payload
    },
    STORAGE_FILE_SET_ALT (state, payload) {
      state.data.alt = payload
    },
    STORAGE_FILE_SET_IS_PRIVATE (state, payload) {
      state.data.isPrivate = payload
    }
  },
  actions: {
    storageFileGet ({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.files}/api/library/${value}`
        }).then(response => {
          commit('STORAGE_FILE_SET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    storageFileRemove ({ commit, dispatch }, value) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${api.files}/api/library/${value}`
        }).then(response => {
          commit('STORAGE_FILES_CLEAR')
          commit('STORAGE_FILES_PAGINATION_CLEAR')
          dispatch('defaultNotificationToastsAdd', {
            title: 'Media remove',
            message: `Media has been successfully removed.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Media remove',
            message: `Media hasn't been removed.`,
            status: 'success'
          })
          reject(error)
        })
      })
    },
    storageFileUpdate ({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'patch',
          url: `${api.files}/api/library`,
          data: state.data
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Media update',
            message: `You have successfully updated the media.`,
            status: 'success'
          })
          router.push({ name: 'media-library-list' })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Media update',
            message: `There has been error while updating the media.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    storageFileReset ({ commit }) {
      commit('STORAGE_FILE_RESET')
    },
    storageFileClear ({ commit }) {
      commit('STORAGE_FILE_CLEAR')
    },
    storageFileSetTitle ({ commit }, value) {
      commit('STORAGE_FILE_SET_TITLE', value)
    },
    storageFileSetDescription ({ commit }, value) {
      commit('STORAGE_FILE_SET_DESCRIPTION', value)
    },
    storageFileSetAlt ({ commit }, value) {
      commit('STORAGE_FILE_SET_ALT', value)
    },
    storageFileSetIsPrivate ({ commit }, value) {
      commit('STORAGE_FILE_SET_IS_PRIVATE', value)
    }
  }
}

export default storageFile
