import axios from 'axios'
import api from '@/api'

const ebsSessionFeedback = {
  state: {
    data: {
      trainerId: '',
      occurrenceItemId: '',
      feedback: [],
      isNewFeedback: false,
      id: null
    },
    default: ''
  },
  getters: {
    getSessionFeedbackForm: state => {
      return state.data.feedback
    }
  },
  mutations: {
    CLEAR_SESSIONFEEDBACK (state) {
      state.data = {
        trainerId: '',
        occurrenceItemId: '',
        feedback: [],
        isNewFeedback: false,
        id: null
      }
    },
    UPDATE_SESSIONFEEDBACK_FEEDBACK (state, payload) {
      const object = state.data.feedback[payload.index]
      object.answers = payload.value
      state.data.feedback.splice(payload.index, 1, object)
    },
    SET_SESSIONFEEDBACK_FEEDBACK (state, payload) {
      state.data.feedback = payload
    },
    SET_SESSIONFEEDBACK_OCCURRENCEITEMID (state, payload) {
      state.data.occurrenceItemId = payload
    },
    SET_SESSIONFEEDBACK_TRAINERID (state, payload) {
      state.data.trainerId = payload
    },
    SET_SESSIONFEEDBACK_ID (state, payload) {
      state.data.id = payload
    },
    SET_SESSIONFEEDBACK_ISNEWFEEDBACK (state, payload) {
      state.data.isNewFeedback = payload
    }
  },
  actions: {
    setSessionFeedbackForm ({ commit }, data) {
      commit('SET_SESSIONFEEDBACK_FEEDBACK', data)
    },
    clearSessionFeedback ({ commit }) {
      commit('CLEAR_SESSIONFEEDBACK')
    },
    setSessionFeedbackOccurrenceItemId ({ commit }, data) {
      commit('SET_SESSIONFEEDBACK_OCCURRENCEITEMID', data)
    },
    setSessionFeedbackId ({ commit }, data) {
      commit('SET_SESSIONFEEDBACK_ID', data)
    },
    setSessionFeedbackTrainerId ({ commit }, data) {
      commit('SET_SESSIONFEEDBACK_TRAINERID', data)
    },
    setSessionFeedbackIsNewFeedback ({ commit }, data) {
      commit('SET_SESSIONFEEDBACK_ISNEWFEEDBACK', data)
    },
    updateSessionFeedbackForm ({ commit }, data) {
      commit('UPDATE_SESSIONFEEDBACK_FEEDBACK', data)
    },
    submitSessionFeedback ({ commit, state, getters, dispatch }) {
      const occurrenceId = getters.getSessionId
      let data = JSON.parse(JSON.stringify(state.data))
      data.id = data.isNewFeedback ? undefined : data.id
      data.isNewFeedback = undefined
      data.createdBy = getters.getAuthUserSecondStageTokenDataID
      axios({
        method: state.data.isNewFeedback ? 'post' : 'patch',
        url: `${api.classroom}/feedback/trainer`,
        data: data
      }).then(() => {
        dispatch('defaultNotificationToastsAdd', {
          title: `Feedback ${state.data.isNewFeedback ? 'create' : 'update'}`,
          message: `Feedback has been successfully ${state.data.isNewFeedback ? 'created' : 'updated'}.`,
          status: 'success'
        })
        commit('CLEAR_SESSION')
        commit('CLEAR_SESSIONFEEDBACK')
        commit('CLEAR_SESSIONFEEDBACKS')
        dispatch('getSessionFeedbacks', occurrenceId)
      }).catch(function () {
        dispatch('defaultNotificationToastsAdd', {
          title: `Feedback ${state.data.isNewFeedback ? 'create' : 'update'}`,
          message: `Feedback hasn't been ${state.data.isNewFeedback ? 'created' : 'updated'}.`,
          status: 'error'
        })
      })
    }
  }
}

export default ebsSessionFeedback
