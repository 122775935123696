import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    id: '',
    name: '',
    used: '',
    usageLimit: 1,
    status: 'inactive',
    code: '',
    expiryDate: '',
    orgUnits: {
      availableFor: 'all',
      list: []
    },
    groups: {
      availableFor: 'none',
      list: []
    },
    workspace: ''
  },
  default: {}
}

const lmsSuperadminWorkspaceRegisterKey = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkspaceRegisterKeyId: state => {
      return state.data.id
    },
    getLmsSuperadminWorkspaceRegisterKeyName: state => {
      return state.data.name
    },
    getLmsSuperadminWorkspaceRegisterKeyUsed: state => {
      const value = state.data.used
      return value ? Number(value) : 0
    },
    getLmsSuperadminWorkspaceRegisterKeyUsageLimit: state => {
      const value = state.data.usageLimit
      return value ? Number(value) : 0
    },
    getLmsSuperadminWorkspaceRegisterKeyStatus: state => {
      return state.data.status
    },
    getLmsSuperadminWorkspaceRegisterKeyExpiryDate: state => {
      return state.data.expiryDate
    },
    getLmsSuperadminWorkspaceRegisterKeyCode: state => {
      return state.data.key
    },
    getLmsSuperadminWorkspaceRegisterKeyOrgUnitsAvailableFor: state => {
      return state.data.orgUnits.availableFor
    },
    getLmsSuperadminWorkspaceRegisterKeyOrgUnitsList: state => {
      return state.data.orgUnits.list
    },
    getLmsSuperadminWorkspaceRegisterKeyGroupsAvailableFor: state => {
      return state.data.groups.availableFor
    },
    getLmsSuperadminWorkspaceRegisterKeyGroupsList: state => {
      return state.data.groups.list
    },
    getLmsSuperadminWorkspaceRegisterKeyWorkspaceId: state => {
      return state.data.workspace
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_GET (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))

      payloadData.orgUnits = payloadData.orgUnits || state.data.orgUnits
      payloadData.groups = payloadData.groups || state.data.groups
      payloadData.expiryDate = payloadData.expiryDate || state.data.expiryDate

      state.data = JSON.parse(JSON.stringify(payloadData))
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.data))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_NAME (state, payload) {
      state.data.name = payload
    },
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_USAGELIMIT (state, payload) {
      state.data.usageLimit = payload
    },
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_STATUS (state, payload) {
      state.data.status = payload
    },
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_EXPIRYDATE (state, payload) {
      state.data.expiryDate = payload
    },
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_ORGUNITS_AVAILABLEFOR (state, payload) {
      state.data.orgUnits.availableFor = payload
    },
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_ORGUNITS_LIST (state, payload) {
      state.data.orgUnits.list = payload.length ? payload.map(obj => obj.id) : []
    },
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_GROUPS_AVAILABLEFOR (state, payload) {
      state.data.groups.availableFor = payload
    },
    LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_GROUPS_LIST (state, payload) {
      state.data.groups.list = payload.length ? payload.map(obj => obj.value) : []
    }
  },
  actions: {
    lmsSuperadminWorkspaceKeyGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data.workspaceId
        const keyId = data.keyId

        commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_CLEAR')

        axios({
          method: 'get',
          url: `${api.sso}/self-registration/workspace-keys/${workspaceId}/${keyId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_GET', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceKeyCreate ({ dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data.workspaceId
        const stateData = JSON.parse(JSON.stringify(state.data))
        const name = stateData.name
        const usageLimit = stateData.usageLimit
        const status = stateData.status
        const expiryDate = stateData.expiryDate || null
        const orgUnits = stateData.orgUnits
        const groups = stateData.groups

        axios({
          method: 'post',
          url: `${api.sso}/self-registration/workspace-keys/${workspaceId}`,
          data: {
            name,
            usageLimit,
            status,
            expiryDate,
            orgUnits,
            groups
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Key generated',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Key not generated',
            message: ``,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceKeyUpdate ({ dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        const stateData = JSON.parse(JSON.stringify(state.data))
        const workspaceId = data.workspaceId
        const keyId = data && data.keyId ? data.keyId : stateData.id
        const name = data && data.name ? data.name : stateData.name
        const usageLimit = data && data.usageLimit ? data.usageLimit : stateData.usageLimit
        const status = data && data.status ? data.status : stateData.status
        const expiryDate = stateData.expiryDate || null
        const orgUnits = stateData.orgUnits
        const groups = stateData.groups

        axios({
          method: 'post',
          url: `${api.sso}/self-registration/workspace-keys/${workspaceId}/${keyId}`,
          data: {
            name,
            usageLimit,
            status,
            expiryDate,
            orgUnits,
            groups
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Key updated',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch((error) => {
          const errorData = error.response.data
          const errorDetails = errorData ? errorData.errors : undefined
          const errorMessage = errorDetails ? errorDetails.message : ''

          dispatch('defaultNotificationToastsAdd', {
            title: 'Key not updated',
            message: errorMessage,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    //
    lmsSuperadminWorkspaceKeyClear ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_CLEAR')
    },
    //
    lmsSuperadminWorkspaceKeySetName ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_NAME', data)
    },
    lmsSuperadminWorkspaceKeySetUsageLimit ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_USAGELIMIT', data)
    },
    lmsSuperadminWorkspaceKeySetStatus ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_STATUS', data)
    },
    lmsSuperadminWorkspaceKeySetExpiryDate ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_EXPIRYDATE', data)
    },
    lmsSuperadminWorkspaceKeySetOrgUnitsAvailableFor ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_ORGUNITS_AVAILABLEFOR', data)
    },
    lmsSuperadminWorkspaceKeySetOrgUnitsList ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_ORGUNITS_LIST', data)
    },
    lmsSuperadminWorkspaceKeySetGroupsAvailableFor ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_GROUPS_AVAILABLEFOR', data)
    },
    lmsSuperadminWorkspaceKeySetGroupsList ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_SET_GROUPS_LIST', data)
    }
  }
}

export default lmsSuperadminWorkspaceRegisterKey
