import axios from 'axios'
import api from '@/api'
import { firstBy } from 'thenby'

const data = {
  pagination: {},
  data: [],
  default: []
}

const lmsSuperadminWorkspaceApiIntegrationKeys = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkspaceApiIntegrationKeysData: state => {
      const rawData = JSON.parse(JSON.stringify(state.data))
      const sortedData = rawData.sort(firstBy('status').thenBy((v) => new Date(v.issuedAt), 'desc'))
      return sortedData
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEYS_GET_DATA (state, payload) {
      state.data = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEYS_GET_PAGINATION (state, payload) {
      state.pagination = JSON.parse(JSON.stringify(payload))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEYS_CLEAR_DATA (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEYS_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsSuperadminWorkspaceApiIntegrationKeysGet ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data && data.requestData && data.requestData.workspaceId ? data.requestData.workspaceId : getters.getAuthUserSecondStageTokenDataWorkspaceId

        commit('LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEYS_CLEAR_DATA')

        axios({
          method: 'get',
          url: `${api.euma}/form/apikeys/${workspaceId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEYS_GET_DATA', response.data.data)

          resolve(response.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    //
    lmsSuperadminWorkspaceApiIntegrationKeysClearData ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEYS_CLEAR_DATA')
    },
    lmsSuperadminWorkspaceApiIntegrationKeysClearPagination ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEYS_CLEAR_PAGINATION')
    }
  }
}

export default lmsSuperadminWorkspaceApiIntegrationKeys
