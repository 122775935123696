import axios from 'axios'
import api from '@/api'
import { User } from '@/User'

const data = {
  data: {
    emailAddress: '',
    firstName: '',
    id: '',
    invitations: '',
    isAdmin: '',
    lastName: '',
    profiles: [],
    roles: [],
    status: '',
    customFields: [],
    api: ''
  },
  default: ''
}

const ebsUsers = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsUserName: (state) => {
      return state.data.firstName + ' ' + state.data.lastName
    },
    getEbsUserHasAPI: (state) => {
      return state.data.api
    },
    getEbsUserProfilesMappedLabelValue: (state, getters) => {
      const user = new User()
      const loggedInUserProfiles = getters.getLmsSuperadminUserProfilesCustomersWithUAOrMARole
      const profiles = state.data.profiles
      const rawItems = user.isSuperadmin() ? profiles : profiles.filter(obj => loggedInUserProfiles.find(inObj => inObj.workspaceId === obj.workspaceId))
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.workspaceName + item.id,
          customLabel: item.workspaceName,
          value: item.id
        }
      })
    }
  },
  mutations: {
    EBS_USER_SET (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))
      payloadData.customFields = []

      state.data = JSON.parse(JSON.stringify(payloadData))
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    EBS_USER_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    EBS_USER_SET_PERSONAL_CUSTOMFIELD (state, payload) {
      let customFields = JSON.parse(JSON.stringify(state.data.personal.customFields))
      let existingCustomFieldIndex = customFields.findIndex(obj => obj.id === payload.id)

      if (existingCustomFieldIndex > -1) {
        if (payload.value !== '') {
          customFields[existingCustomFieldIndex].value = payload.value
        } else {
          customFields.splice(existingCustomFieldIndex, 1)
        }
      } else {
        customFields.push({
          id: payload.id,
          value: payload.value
        })
      }

      state.data.personal.customFields = customFields
    }
  },
  actions: {
    ebsUserGet ({ commit }, id) {
      return new Promise((resolve, reject) => {
        return axios({
          method: 'get',
          url: `${api.sso}/v2/users/${id}`
        }).then((response) => {
          commit('EBS_USER_SET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsUserUpdate ({ state, getters }) {
      return new Promise((resolve, reject) => {
        let body = JSON.parse(JSON.stringify(state.data))
        const workspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        let rewrittenCustomFields = {}

        for (let field of body.customFields) {
          if (field.value) {
            rewrittenCustomFields[field.id] = field.value
          }
        }

        body.customFields = rewrittenCustomFields

        return axios({
          method: 'patch',
          url: `${api.sso}/v2/users`,
          data: {
            id: body.id,
            emailAddress: body.id,
            firstName: body.firstName,
            lastName: body.lastName,
            status: body.status,
            customFields: body.customFields,
            workspaceId
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsUserClear ({ commit }) {
      commit('EBS_USER_CLEAR')
    }
  }
}

export default ebsUsers
