import axios from 'axios'
import api from '@/api'

const data = {
  data: '',
  default: ''
}

const ebsRequest = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsRequest: state => {
      return state.data
    }
  },
  mutations: {
    EBS_REQUEST_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    EBS_REQUEST_SET (state, payload) {
      state.data = payload
    }
  },
  actions: {
    ebsRequestGetApprove (ctx, data) {
      return new Promise((resolve, reject) => {
        const enrolmentId = data
        axios({
          method: 'post',
          url: `${api.learningactivities}/enrolment/${enrolmentId}/approve`
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsRequestGetReject (ctx, data) {
      return new Promise((resolve, reject) => {
        const enrolmentId = data
        axios({
          method: 'post',
          url: `${api.learningactivities}/enrolment/${enrolmentId}/reject`
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ebsRequest
