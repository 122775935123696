<template>
    <button
        type="button"
        class="btn btn--border-arrow filter__select_button"
        @click="$emit('switch-menu')"
        id="filter-select-button-select"
    >
        {{filterSelectButton}}
        <v-icon :name="`chevron-${active ? 'up' : 'down'}`"></v-icon>
    </button>
</template>

<script>
export default {
  name: 'filter-select-button',
  props: {
    filterSelectButton: {
      type: String
    },
    active: {
      type: Boolean
    }
  }
}
</script>
