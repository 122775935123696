import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    workspaceId: '',
    name: '',
    fieldType: '',
    customValidation: {
      minLength: 0,
      maxLength: 0,
      set: []
    },
    help: '',
    required: false
  },
  default: {}
}

const lmsSuperadminWorkspaceCustomField = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkspaceCustomFieldId: state => {
      return state.data.id
    },
    getLmsSuperadminWorkspaceCustomFieldName: state => {
      return state.data.name
    },
    getLmsSuperadminWorkspaceCustomFieldType: state => {
      return state.data.fieldType
    },
    getLmsSuperadminWorkspaceCustomFieldCustomValidation: state => {
      return state.data.customValidation
    },
    getLmsSuperadminWorkspaceCustomFieldCustomValidationMinLength: state => {
      return state.data.customValidation.minLength
    },
    getLmsSuperadminWorkspaceCustomFieldCustomValidationMaxLength: state => {
      return state.data.customValidation.maxLength
    },
    getLmsSuperadminWorkspaceCustomFieldCustomValidationSet: state => {
      return state.data.customValidation.set
    },
    getLmsSuperadminWorkspaceCustomFieldHelp: state => {
      return state.data.help
    },
    getLmsSuperadminWorkspaceCustomFieldRequired: state => {
      return state.data.required
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_GET (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))

      payloadData.fieldType = payloadData.type || payloadData.fieldType || ''

      state.data = JSON.parse(JSON.stringify(payloadData))
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_CLEAR_CUSTOMVALIDATION (state) {
      state.data.customValidation = JSON.parse(JSON.stringify(data.data.customValidation))
      state.default.customValidation = JSON.parse(JSON.stringify(data.data.customValidation))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_NAME (state, payload) {
      state.data.name = payload
    },
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_FIELDTYPE (state, payload) {
      state.data.fieldType = payload
    },
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_CUSTOMVALIDATION (state, payload) {
      state.data.customValidation = payload
    },
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_CUSTOMVALIDATION_MINLENGTH (state, payload) {
      state.data.customValidation.minLength = payload
    },
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_CUSTOMVALIDATION_MAXLENGTH (state, payload) {
      state.data.customValidation.maxLength = payload
    },
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_CUSTOMVALIDATION_SET (state, payload) {
      state.data.customValidation.set = payload
    },
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_HELP (state, payload) {
      state.data.help = payload
    },
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_REQUIRED (state, payload) {
      state.data.required = payload
    }
  },
  actions: {
    lmsSuperadminWorkspaceCustomFieldCreate ({ dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        const stateData = JSON.parse(JSON.stringify(state.data))
        const workspaceId = data
        const name = stateData.name
        const fieldType = stateData.fieldType
        let customValidation = stateData.customValidation
        if (customValidation) {
          customValidation.minLength = customValidation.minLength || undefined
          customValidation.maxLength = customValidation.maxLength || undefined
        }
        const help = stateData.help
        const required = stateData.required

        axios({
          method: 'post',
          url: `${api.sso}/v2/custom-fields`,
          data: {
            workspaceId,
            name,
            fieldType,
            customValidation,
            help,
            required
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Custom field created',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Custom field not created',
            message: error.response.data && error.response.data.errors ? error.response.data.errors.message : '',
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceCustomFieldUpdate ({ dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        const stateData = JSON.parse(JSON.stringify(state.data))
        const id = data && data.id ? data.id : stateData.id
        const name = data && data.name ? data.name : stateData.name
        let customValidation = data && data.customValidation ? data.customValidation : stateData.customValidation
        if (customValidation) {
          customValidation.minLength = customValidation.minLength || undefined
          customValidation.maxLength = customValidation.maxLength || undefined
        }
        const help = data && data.help ? data.help : stateData.help || undefined
        const required = data && data.required !== undefined ? data.required : stateData.required

        axios({
          method: 'patch',
          url: `${api.sso}/v2/custom-fields/${id}`,
          data: {
            name,
            customValidation,
            help,
            required
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Custom field updated',
            message: '',
            status: 'success'
          })
          resolve(response.data)
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Custom field not updated',
            message: error.response.data && error.response.data.errors ? error.response.data.errors.message : '',
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceCustomFieldRemove ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const id = data

        axios({
          method: 'delete',
          url: `${api.sso}/v2/custom-fields/${id}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Custom field updated',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Custom field not updated',
            message: ``,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    //
    lmsSuperadminWorkspaceCustomFieldClear ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_CLEAR')
    },
    lmsSuperadminWorkspaceCustomFieldClearCustomValidation ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_CLEAR_CUSTOMVALIDATION')
    },
    //
    lmsSuperadminWorkspaceCustomFieldGet ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_GET', data)
    },
    lmsSuperadminWorkspaceCustomFieldSetName ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_NAME', data)
    },
    lmsSuperadminWorkspaceCustomFieldSetFieldType ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_FIELDTYPE', data)
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_CLEAR_CUSTOMVALIDATION')
    },
    lmsSuperadminWorkspaceCustomFieldSetCustomValidation ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_CUSTOMVALIDATION', data)
    },
    lmsSuperadminWorkspaceCustomFieldSetCustomValidationMinLength ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_CUSTOMVALIDATION_MINLENGTH', data)
    },
    lmsSuperadminWorkspaceCustomFieldSetCustomValidationMaxLength ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_CUSTOMVALIDATION_MAXLENGTH', data)
    },
    lmsSuperadminWorkspaceCustomFieldSetCustomValidationSet ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_CUSTOMVALIDATION_SET', data)
    },
    lmsSuperadminWorkspaceCustomFieldSetHelp ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_HELP', data)
    },
    lmsSuperadminWorkspaceCustomFieldSetRequired ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELD_SET_REQUIRED', data)
    }
  }
}

export default lmsSuperadminWorkspaceCustomField
