import axios from 'axios'

const data = {
  data: {
    token: '',
    cancel: ''
  }
}

const storageCancelToken = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getStorageCancelToken (state) {
      return state.data
    }
  },
  mutations: {
    STORAGE_CANCEL_TOKEN_SET (state) {
      state.data = axios.CancelToken.source()
    },
    STORAGE_CANCEL_TOKEN_CANCEL (state) {
      if (state.data.token) {
        state.data.cancel()
      }
    }
  },
  actions: {
    storageCancelTokenSet ({ commit }) {
      commit('STORAGE_CANCEL_TOKEN_SET')
    },
    storageCancelTokenCancel ({ commit }) {
      commit('STORAGE_CANCEL_TOKEN_CANCEL')
    }
  }
}

export default storageCancelToken
