<template>
    <div class="filter__more_menu_box_item filter__more_menu_box_item--cost">
        <div class="filter__more_menu_box_item_title">{{ $t('filter.moreMenu.title.cost') }}</div>
        <div class="filter__more_menu_box_item_options">
            <div class="columns is-variable">
                <div class="column">
                    <select name="filter-more-menu-cost-moreless" id="filter-more-menu-cost-moreless" title="filter-more-menu-cost-moreless">
                        <option value="">{{ $t('filter.moreMenu.options.lessThan') }}</option>
                        <option value="">{{ $t('filter.moreMenu.options.one') }}</option>
                        <option value="">{{ $t('filter.moreMenu.options.two') }}</option>
                    </select>
                </div>
                <div class="column">
                    <div class="columns is-variable is-0">
                        <div class="column is-narrow">
                            <label class="filter__more_menu_box_item_customlabel" for="filter-more-menu-cost-value">£</label>
                        </div>
                        <div class="column">
                            <input type="number" id="filter-more-menu-cost-value" value="100">
                        </div>
                    </div>
                </div>
                <div class="column">
                    <select name="filter-more-menu-cost-per" id="filter-more-menu-cost-per" title="filter-more-menu-cost-per">
                        <option value="">{{ $t('filter.moreMenu.options.hour') }}</option>
                        <option value="">{{ $t('filter.moreMenu.options.one') }}</option>
                        <option value="">{{ $t('filter.moreMenu.options.two') }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'filter-more-menu-cost'
}
</script>
