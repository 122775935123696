import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: []
}

const lmsSuperadminWorkspaceFeedbackForms = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkspaceFeedbackFormsData: state => {
      return state.data
    },
    getLmsSuperadminWorkspaceFeedbackFormsPagination: state => {
      return state.pagination
    },
    getLmsSuperadminWorkspaceFeedbackFormsMappedIdNameStatus: state => {
      const rawItems = state.data
      const rawItemsFiltered = rawItems.length ? rawItems.filter(obj => obj.id !== 'default') : rawItems
      if (!rawItemsFiltered.length) return []
      return rawItemsFiltered.map((item) => {
        return {
          id: item.id,
          name: item.name
        }
      })
    },
    getLmsSuperadminWorkspaceFeedbackFormsMappedLabelValue: state => {
      const rawItems = state.data
      const rawItemsFiltered = rawItems.length ? rawItems.filter(obj => obj.id !== 'default') : rawItems
      if (!rawItemsFiltered.length) return []
      return rawItemsFiltered.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })
    },
    getLmsSuperadminWorkspaceFeedbackFormsDefault: state => {
      let feedbackForms = JSON.parse(JSON.stringify(state.data))
      let defaultFeedbackForm = feedbackForms.length ? feedbackForms.find(obj => obj.default) : null

      return defaultFeedbackForm
    },
    getLmsSuperadminWorkspaceFeedbackFormsById: state => id => {
      let feedbackForms = JSON.parse(JSON.stringify(state.data))
      let selectedFeedbackForm

      if (feedbackForms.length) {
        if (id === 'workspaceDefault') {
          selectedFeedbackForm = feedbackForms.find(obj => obj.default)
        } else {
          selectedFeedbackForm = feedbackForms.find(obj => obj.id === id)
        }
      } else {
        selectedFeedbackForm = null
      }

      return selectedFeedbackForm
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKSPACE_FEEDBACK_FORMS_GET_DATA (state, payload) {
      state.data = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_WORKSPACE_FEEDBACK_FORMS_GET_PAGINATION (state, payload) {
      state.pagination = JSON.parse(JSON.stringify(payload))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_FEEDBACK_FORMS_CLEAR_DATA (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_WORKSPACE_FEEDBACK_FORMS_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsSuperadminWorkspaceFeedbackFormsGet ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data && data.queryUrlParams ? data.queryUrlParams : '?pageSize=9999'
        const workspaceId = data && data.requestData && data.requestData.workspaceId ? data.requestData.workspaceId : getters.getAuthUserSecondStageTokenDataWorkspaceId

        commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACK_FORMS_CLEAR_DATA')

        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/feedback-form-templates/${workspaceId}${queryUrlParams}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACK_FORMS_GET_DATA', response.data)

          resolve(response.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    //
    lmsSuperadminWorkspaceFeedbackFormsClearData ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACK_FORMS_CLEAR_DATA')
    },
    lmsSuperadminWorkspaceFeedbackFormsClearPagination ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_FEEDBACK_FORMS_CLEAR_PAGINATION')
    }
  }
}

export default lmsSuperadminWorkspaceFeedbackForms
