import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const ebsUserHistory = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getUserHistory: state => {
      return state.data
    },
    getUserHistoryMappedLabelValue: state => {
      const rawUserHistory = state.data
      if (!rawUserHistory.length) return []
      return rawUserHistory.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.name,
          value: item.id
        }
      })
    },
    getUserHistoryMappedIdNameStatus: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          status: item.status
        }
      })
    },
    getUserHistoryPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    SET_USERHISTORY (state, payload) {
      state.data = payload
    },
    SET_USERHISTORY_PAGINATION (state, payload) {
      state.pagination = payload
    },
    CLEAR_USERHISTORY (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    CLEAR_USERHISTORY_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    getUserHistory ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const learnerId = data ? (data.requestData ? data.requestData.learnerId : data.learnerId) : undefined
        const type = data ? (data.requestData ? data.requestData.type : data.type) : undefined
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'

        commit('CLEAR_USERHISTORY')
        commit('CLEAR_USERHISTORY_PAGINATION')

        axios({
          method: 'get',
          url: `${api.learningactivities}/learner-history/${learnerId}/${type}${queryUrlParams}`
        }).then(function (response) {
          commit('SET_USERHISTORY', response.data.data)
          commit('SET_USERHISTORY_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    exportUserHistory (ctx, data) {
      const learnerId = data.learnerId
      const learnerName = data.learnerName

      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.learningactivities}/learner-history-xls/${learnerId}`,
          responseType: 'arraybuffer'
        }).then(response => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${learnerName}-history.xlsx`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `${learnerName}-history.xlsx`)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ebsUserHistory
