import axios from 'axios'
import api from '@/api'
import { sortByKey } from '@/functions'

const CancelToken = axios.CancelToken
let cancelLmsSuperadminWorkspaceUsersGet

const data = {
  pagination: {},
  data: [],
  default: '',
  custom: {
    workspaceId: ''
  }
}

const LmsSuperadminWorkspaceUsers = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkspaceUsers: state => {
      return state.data
    },
    getLmsSuperadminWorkspaceUsersMappedIdNameRoles: state => {
      const rawItems = state.data
      if (!rawItems || !rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: `${item.firstName} ${item.lastName}`,
          email: item.emailAddress,
          roles: item.roles,
          isAdmin: item.isAdmin
        }
      })
    },
    getLmsSuperadminWorkspaceUsersMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems || !rawItems.length) return []
      return rawItems.map((item) => {
        return {
          value: item.id,
          label: item.firstName + ' ' + item.lastName + ' ' + item.emailAddress,
          customLabel: `${item.firstName} ${item.lastName}`,
          roles: item.roles,
          isAdmin: item.isAdmin
        }
      })
    },
    getLmsSuperadminWorkspaceUsersPagination: state => {
      return state.pagination
    },
    getLmsSuperadminWorkspaceUsersCustomWorkspaceId: state => {
      return state.custom.workspaceId
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKSPACE_USERS_GET (state, payload) {
      for (let index in payload) {
        let user = payload[index]
        user.profileRoles = sortByKey(user.profileRoles)
        user.combined = user.firstName + user.lastName + user.emailAddress
      }
      state.data = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USERS_PAGINATION_GET (state, payload) {
      state.pagination = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USERS_CLEAR (state) {
      state.data = ''
      state.custom.workspaceId = ''
    },
    LMS_SUPERADMIN_WORKSPACE_USERS_PAGINATION_CLEAR (state) {
      state.pagination = ''
    },
    LMS_SUPERADMIN_WORKSPACE_USERS_SET_CUSTOM_WORKSPACEID (state, payload) {
      state.custom.workspaceId = payload
    }
  },
  actions: {
    lmsSuperadminWorkspaceUsersGetCancelRequest () {
      if (cancelLmsSuperadminWorkspaceUsersGet) cancelLmsSuperadminWorkspaceUsersGet()
    },
    lmsSuperadminWorkspaceUsersGet ({ dispatch, commit, getters }, data) {
      return new Promise((resolve, reject) => {
        dispatch('lmsSuperadminWorkspaceUsersGetCancelRequest')

        const queryUrlParams = data && data.queryUrlParams ? data.queryUrlParams : '?pageSize=9999'
        const workspaceId = data.requestData ? data.requestData.workspaceId : getters.getAuthUserSecondStageTokenDataWorkspaceId

        commit('LMS_SUPERADMIN_WORKSPACE_USERS_CLEAR')
        commit('LMS_SUPERADMIN_WORKSPACE_USERS_PAGINATION_CLEAR')
        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelLmsSuperadminWorkspaceUsersGet = ex
          }),
          method: 'get',
          url: `${api.sso}/v2/workspace-users/${workspaceId}${queryUrlParams}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_USERS_GET', response.data.data)
          commit('LMS_SUPERADMIN_WORKSPACE_USERS_PAGINATION_GET', response.data.pagination)
          commit('LMS_SUPERADMIN_WORKSPACE_USERS_SET_CUSTOM_WORKSPACEID', workspaceId)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceUsersClear ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_USERS_CLEAR')
    },
    lmsSuperadminWorkspaceUsersExportCSV (ctx, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data && data.workspaceId
        const workspaceName = data && data.workspaceName

        axios({
          method: 'get',
          url: `${api.reportengine}/export/workspace-users/${workspaceId}`,
          responseType: 'arraybuffer'
        }).then((response) => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.getElementById('download-users-list')
            link.href = url
            link.setAttribute('download', `${workspaceName}-users-list.csv`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), 'download-users-list')
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default LmsSuperadminWorkspaceUsers
