import axios from 'axios'
import api from '@/api'
import { sortByKey } from '@/functions'

const CancelToken = axios.CancelToken
let cancelEbsEnrolmentsGetAny
let cancelEbsEnrolmentsGetActive
let cancelEbsEnrolmentsGetComplete

const data = {
  pagination: {},
  data: [],
  default: '',
  custom: {
    any: {
      pagination: {},
      data: []
    },
    active: {
      pagination: {},
      data: []
    },
    external: {
      pagination: {},
      data: []
    },
    complete: {
      pagination: {},
      data: []
    }
  }
}

const ebsEnrolments = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsEnrolmentsCustomAnyData: state => {
      return state.custom.any.data
    },
    getEbsEnrolmentsCustomAnyPagination: state => {
      return state.custom.any.pagination
    },
    getEbsEnrolmentsCustomActiveData: state => {
      return sortByKey(state.custom.active.data, 'courseName', 'asc')
    },
    getEbsEnrolmentsCustomActivePagination: state => {
      return state.custom.active.pagination
    },
    getEbsEnrolmentsCustomExternalData: state => {
      return state.custom.external.data
    },
    getEbsEnrolmentsCustomExternalPagination: state => {
      return state.custom.external.pagination
    },
    getEbsEnrolmentsCustomCompleteData: state => {
      return state.custom.complete.data
    },
    getEbsEnrolmentsCustomCompletePagination: state => {
      return state.custom.complete.pagination
    }
  },
  mutations: {
    EBS_ENROLMENTS_ANY_SET_DATA (state, payload) {
      state.custom.any.data = payload
    },
    EBS_ENROLMENTS_ANY_SET_PAGINATION (state, payload) {
      state.custom.any.pagination = payload
    },
    EBS_ENROLMENTS_ANY_CLEAR_DATA (state) {
      state.custom.any.data = JSON.parse(JSON.stringify(data.custom.any.data))
    },
    EBS_ENROLMENTS_ANY_CLEAR_PAGINATION (state) {
      state.custom.any.pagination = JSON.parse(JSON.stringify(data.custom.any.pagination))
    },
    EBS_ENROLMENTS_ACTIVE_SET_DATA (state, payload) {
      state.custom.active.data = payload
    },
    EBS_ENROLMENTS_ACTIVE_SET_PAGINATION (state, payload) {
      state.custom.active.pagination = payload
    },
    EBS_ENROLMENTS_ACTIVE_CLEAR_DATA (state) {
      state.custom.active.data = JSON.parse(JSON.stringify(data.custom.active.data))
    },
    EBS_ENROLMENTS_ACTIVE_CLEAR_PAGINATION (state) {
      state.custom.active.pagination = JSON.parse(JSON.stringify(data.custom.active.pagination))
    },
    EBS_ENROLMENTS_EXTERNAL_SET_DATA (state, payload) {
      state.custom.external.data = payload
    },
    EBS_ENROLMENTS_EXTERNAL_SET_PAGINATION (state, payload) {
      state.custom.external.pagination = payload
    },
    EBS_ENROLMENTS_EXTERNAL_CLEAR_DATA (state) {
      state.custom.external.data = JSON.parse(JSON.stringify(data.custom.any.data))
    },
    EBS_ENROLMENTS_EXTERNAL_CLEAR_PAGINATION (state) {
      state.custom.external.pagination = JSON.parse(JSON.stringify(data.custom.any.pagination))
    },
    EBS_ENROLMENTS_COMPLETE_SET_DATA (state, payload) {
      state.custom.complete.data = payload
    },
    EBS_ENROLMENTS_COMPLETE_SET_PAGINATION (state, payload) {
      state.custom.complete.pagination = payload
    },
    EBS_ENROLMENTS_COMPLETE_CLEAR_DATA (state) {
      state.custom.complete.data = JSON.parse(JSON.stringify(data.custom.active.data))
    },
    EBS_ENROLMENTS_COMPLETE_CLEAR_PAGINATION (state) {
      state.custom.complete.pagination = JSON.parse(JSON.stringify(data.custom.active.pagination))
    }
  },
  actions: {
    ebsEnrolmentsCancelRequests () {
      if (cancelEbsEnrolmentsGetAny) cancelEbsEnrolmentsGetAny()
      if (cancelEbsEnrolmentsGetActive) cancelEbsEnrolmentsGetActive()
      if (cancelEbsEnrolmentsGetComplete) cancelEbsEnrolmentsGetComplete()
    },
    ebsEnrolmentsGetAny ({ commit, getters }, data) {
      let queryUrlParams = data ? data.queryUrlParams : ''
      let userId = data && data.requestData && data.requestData.userId ? data.requestData.userId : getters.getAuthUserSecondStageTokenDataUserId
      if (!queryUrlParams.includes('sortBy=')) {
        queryUrlParams += '&sortBy=enrolmentDateTime'
      }
      if (!queryUrlParams.includes('sortDirection=')) {
        queryUrlParams += '&sortDirection=asc'
      }
      return new Promise((resolve, reject) => {
        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsEnrolmentsGetActive = ex
          }),
          method: 'get',
          url: `${api.learningactivities}/v2/user-enrolments${userId ? `/` + userId : ''}${queryUrlParams}`
        }).then(response => {
          commit('EBS_ENROLMENTS_ANY_SET_DATA', response.data.data)
          commit('EBS_ENROLMENTS_ANY_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsEnrolmentsGetActive ({ commit, getters }, data) {
      let queryUrlParams = data ? data.queryUrlParams : ''
      let userId = data && data.requestData && data.requestData.userId ? data.requestData.userId : getters.getAuthUserSecondStageTokenDataUserId
      if (!queryUrlParams.includes('sortBy=')) {
        queryUrlParams += '&sortBy=enrolmentDateTime'
      }
      if (!queryUrlParams.includes('sortDirection=')) {
        queryUrlParams += '&sortDirection=asc'
      }
      queryUrlParams += '&filters[completed]=0'
      return new Promise((resolve, reject) => {
        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsEnrolmentsGetActive = ex
          }),
          method: 'get',
          url: `${api.learningactivities}/v2/user-enrolments${userId ? `/` + userId : ''}${queryUrlParams}`
        }).then(response => {
          commit('EBS_ENROLMENTS_ACTIVE_SET_DATA', response.data.data)
          commit('EBS_ENROLMENTS_ACTIVE_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsEnrolmentsGetExternal ({ commit, getters }, data) {
      // generate an array with 4 items

      let queryUrlParams = data ? data.queryUrlParams : ''
      let userId = data && data.requestData && data.requestData.userId ? data.requestData.userId : getters.getAuthUserSecondStageTokenDataUserId
      if (!queryUrlParams.includes('sortBy=')) {
        queryUrlParams += '&sortBy=completionDate'
      }
      if (!queryUrlParams.includes('sortDirection=')) {
        queryUrlParams += '&sortDirection=asc'
      }
      return new Promise((resolve, reject) => {
        commit('EBS_ENROLMENTS_EXTERNAL_CLEAR_DATA')
        commit('EBS_ENROLMENTS_EXTERNAL_CLEAR_PAGINATION')

        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsEnrolmentsGetActive = ex
          }),
          method: 'get',
          url: `${api.learningactivities}/external-history${userId ? `/user/` + userId : ''}${queryUrlParams}`
        }).then(response => {
          commit('EBS_ENROLMENTS_EXTERNAL_SET_DATA', response.data.data)
          commit('EBS_ENROLMENTS_EXTERNAL_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsEnrolmentsGetComplete ({ commit, getters }, data) {
      let queryUrlParams = data ? data.queryUrlParams : ''
      let userId = data && data.requestData && data.requestData.userId ? data.requestData.userId : getters.getAuthUserSecondStageTokenDataUserId
      if (!queryUrlParams.includes('sortBy=')) {
        queryUrlParams += '&sortBy=updatedAt'
      }
      if (!queryUrlParams.includes('sortDirection=')) {
        queryUrlParams += '&sortDirection=desc'
      }
      queryUrlParams += '&filters[completed]=1'
      return new Promise((resolve, reject) => {
        commit('EBS_ENROLMENTS_COMPLETE_CLEAR_DATA')
        commit('EBS_ENROLMENTS_COMPLETE_CLEAR_PAGINATION')

        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsEnrolmentsGetComplete = ex
          }),
          method: 'get',
          url: `${api.learningactivities}/v2/user-enrolments${userId ? `/` + userId : ''}${queryUrlParams}`
        }).then(response => {
          commit('EBS_ENROLMENTS_COMPLETE_SET_DATA', response.data.data)
          commit('EBS_ENROLMENTS_COMPLETE_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsEnrolmentsClear ({ commit }) {
      commit('EBS_ENROLMENTS_ANY_CLEAR_DATA')
      commit('EBS_ENROLMENTS_ANY_CLEAR_PAGINATION')
      commit('EBS_ENROLMENTS_ACTIVE_CLEAR_DATA')
      commit('EBS_ENROLMENTS_ACTIVE_CLEAR_PAGINATION')
      commit('EBS_ENROLMENTS_EXTERNAL_CLEAR_DATA')
      commit('EBS_ENROLMENTS_EXTERNAL_CLEAR_PAGINATION')
      commit('EBS_ENROLMENTS_COMPLETE_CLEAR_DATA')
      commit('EBS_ENROLMENTS_COMPLETE_CLEAR_PAGINATION')
    }
  }
}

export default ebsEnrolments
