<template>
    <div class="filter__more_button">
        <button
            type="button"
            class="btn btn--small btn--tertiary btn--border"
            @click="$emit('switch-menu')"
            id="filter-more-button-more"
        >{{ $t(filter.moreMenu.buttons.moreFilters) }}</button>
    </div>
</template>

<script>
export default {
  name: 'filter-more-button'
}
</script>
