import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    users: [],
    actionPending: false,
    actionCompleted: false
  }
}

const lmsSuperadminUsersBulkActionsResendInvitations = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminUsersBulkActionsResendInvitationsItems: state => {
      return state.data.users
    },
    getLmsSuperadminUsersBulkActionsResendInvitationsItemsProfileIds: state => {
      return state.data.users.map(obj => obj.profileId)
    },
    getLmsSuperadminUsersBulkActionsResendInvitationsItemsChecked: state => {
      const users = state.data.users
      const usersChecked = users.filter(obj => obj.checked)

      return usersChecked
    },
    getLmsSuperadminUsersBulkActionsResendInvitationsItemsCheckedProfileIds: state => {
      const users = state.data.users
      const usersChecked = users.filter(obj => obj.checked)
      const usersMapped = usersChecked.map(obj => obj.profileId)

      return usersMapped
    },
    getLmsSuperadminUsersBulkActionsResendInvitationsItemsInvitedProfileIds: state => {
      const users = state.data.users
      const usersUpdated = users.filter(obj => obj.updateStatus === 'invited')
      const usersMapped = usersUpdated.map(obj => obj.profileId)

      return usersMapped
    },
    getLmsSuperadminUsersBulkActionsResendInvitationsActionPending: state => {
      return state.data.actionPending
    },
    getLmsSuperadminUsersBulkActionsResendInvitationsActionCompleted: state => {
      return state.data.actionCompleted
    }
  },
  mutations: {
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ITEMS (state, payload) {
      let users = state.data.users
      let item = payload.item
      item.checked = true
      item.updateStatus = 'ready'

      const value = payload.value
      const id = payload.index
      const existingIdIndex = users.findIndex(obj => obj.profileId === id)

      if (value && existingIdIndex === -1) {
        users.push(item)
      }
      if (!value && existingIdIndex > -1) {
        users.splice(existingIdIndex, 1)
      }
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ITEMS_VALUE (state, payload) {
      let users = state.data.users

      const value = payload.value
      const key = payload.key
      const profileId = payload.profileId
      const existingIdIndex = users.findIndex(obj => obj.profileId === profileId)
      let user = JSON.parse(JSON.stringify(users[existingIdIndex]))
      user[key] = value

      users.splice(existingIdIndex, 1, user)
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ITEMS_CHECKED (state, payload) {
      let users = state.data.users

      const value = payload.value
      const id = payload.index
      const existingIdIndex = users.findIndex(obj => obj.profileId === id)

      users[existingIdIndex].checked = value
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ACTIONPENDING (state, payload) {
      state.data.actionPending = payload
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ACTIONCOMPLETED (state, payload) {
      state.data.actionCompleted = payload
    }
  },
  actions: {
    lmsSuperadminUsersBulkActionsResendInvitationsSingle (ctx, data) {
      return new Promise((resolve, reject) => {
        const profileId = data

        return axios({
          method: 'post',
          url: `${api.sso}/v2/resend-invitation/${profileId}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminUsersBulkActionsResendInvitationsRun ({ dispatch, getters, commit }) {
      return new Promise((resolve, reject) => {
        let users = getters.getLmsSuperadminUsersBulkActionsResendInvitationsItemsChecked
        const usersLength = users.length
        let requestsCounter = 0
        let requestsSuccessCounter = 0
        let index = 0

        if (!users || !users.length) return reject(new Error())

        dispatch('defaultNotificationToastsAdd', {
          title: `Resending invitations`,
          message: `0/${usersLength} invitations resent`,
          status: 'info',
          linkTo: { name: 'lms-superadmin-user-bulk-actions-resend-invitations-summary' },
          linkText: 'View action summary',
          iconName: 'loader',
          id: 'bulk-resend-invitations',
          preventClose: true,
          position: 'bottom'
        })

        commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ACTIONPENDING', true)
        commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ACTIONCOMPLETED', false)

        const performAction = () => {
          if (index >= usersLength) {
            commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ACTIONPENDING', false)
            commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ACTIONCOMPLETED', true)
            return resolve()
          }

          const user = users[index]
          const profileId = user.profileId

          commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ITEMS_VALUE', { key: 'updateStatus', value: 'resending invitation', profileId })

          dispatch('lmsSuperadminUsersBulkActionsResendInvitationsSingle', profileId).then(() => {
            requestsSuccessCounter++
            commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ITEMS_VALUE', { key: 'updateStatus', value: 'invited', profileId })
          }).catch(() => {
            commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ITEMS_VALUE', { key: 'updateStatus', value: 'error', profileId })
          }).finally(() => {
            requestsCounter++
            if (requestsCounter === Number(usersLength)) {
              dispatch('defaultNotificationToastsAdd', {
                title: `Invitations resent`,
                message: `${requestsSuccessCounter}/${usersLength} invitations`,
                status: 'success',
                linkTo: { name: 'lms-superadmin-user-bulk-actions-resend-invitations-summary' },
                linkText: 'View action summary',
                iconName: 'check',
                id: 'bulk-resend-invitations',
                preventClose: false,
                position: 'bottom'
              })
              resolve()
            } else {
              dispatch('defaultNotificationToastsAdd', {
                title: `Resending invitations`,
                message: `${requestsSuccessCounter}/${usersLength} invitations resent`,
                status: 'info',
                linkTo: { name: 'lms-superadmin-user-bulk-actions-resend-invitations-summary' },
                linkText: 'View action summary',
                iconName: 'loader',
                id: 'bulk-resend-invitations',
                preventClose: true,
                position: 'bottom'
              })
            }

            index++
            performAction()
          })
        }

        performAction()
      })
    },
    lmsSuperadminUsersBulkActionsResendInvitationsSetItems ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ITEMS', data)
    },
    lmsSuperadminUsersBulkActionsResendInvitationsSetItemsValue ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ITEMS_VALUE', data)
    },
    lmsSuperadminUsersBulkActionsResendInvitationsSetItemsChecked ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_SET_ITEMS_CHECKED', data)
    },
    lmsSuperadminUsersBulkActionsResendInvitationsClear ({ commit }) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_RESEND_INVITATIONS_CLEAR')
    }
  }
}

export default lmsSuperadminUsersBulkActionsResendInvitations
