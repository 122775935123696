var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:'header__top_account'},[_c('div',{staticClass:"header__top_account_inner"},[_c('div',{staticClass:"columns fullheight is-variable is-2"},[(_vm.allowTopAccountNav)?[_c('div',{class:[
                    'column',
                    'column--custom-nav'
                ]},[_c('div',{staticClass:"columns is-variable is-6"},[_c('div',{staticClass:"column"},[(_vm.allowForMenu)?_c('header-top-account-nav',{class:[
                                    _vm.hamburgerMenuActive ? 'header__top_account_nav--active' : 'header__top_account_nav--inactive',
                                    _vm.hamburgerMenuTouched && _vm.allowTopAccountNav ? 'header__top_account_nav--touched' : '',
                                    _vm.inverseStyle ? 'header__top_account_nav--inverse' : ''
                                ],attrs:{"inverse-style":_vm.inverseStyle}}):_vm._e()],1)])]),_c('div',{staticClass:"column is-narrow column--custom-actions"},[_c('div',{staticClass:"columns is-vcentered is-variable is-3"},[_c('div',{staticClass:"column is-narrow"},[_c('header-top-account-menu')],1)])])]:_vm._e(),(!_vm.allowTopAccountNav && !_vm.$route.meta.hideAuthLogin)?[_c('div',{staticClass:"column"}),_c('div',{staticClass:"column has-text-right is-narrow"},[_c('header-top-account-login')],1),(false)?[_c('div',{staticClass:"column"}),_c('div',{staticClass:"column is-narrow"},[_c('header-top-account-signup')],1)]:_vm._e()]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }