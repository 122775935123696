<template>
    <div :class="[
        'component',
        'component__button',
        text ? 'component__button--text' : '',
        fullheight ? 'component__button--fullheight' : '',
        listActive ? 'component__button--listactive' : ''
    ]">
        <div class="columns is-vcentered">
            <div :class="[
                'column',
                'column--btn',
                `has-text-${align}`
            ]">
                <component
                    ref="button"
                    :data-testid="name"
                    :is="!disabled ? (to || toExternal ? (to ? 'router-link' : 'a') : 'button') : 'button'"
                    :class="outputClasses"
                    :target="target ? '_blank' : ''"
                    :disabled="disabled"
                    :to="to"
                    :id="name | toKebabCase"
                    :href="toExternal || to ? (toExternal || $router.resolve(to).href) : null"
                    :type="type"
                    :tabindex="0"
                    :title="title"
                    @mouseenter="$emit('mouseenter')"
                    @mouseleave="$emit('mouseleave')"
                    @click="clickHandler"
                    @click.native="clickHandler"
                >
                    <template v-if="icon">
                        <div class="columns is-vcentered">
                            <div :class="[
                                'is-narrow',
                                'column',
                                'column--icon',
                                `column--icon-align-${iconAlign}`
                            ]">
                                <div :class="['component__button_icon', `component__button_icon--${iconSize}`]">
                                    <v-icon :name="iconName" role="img" :aria-label="iconAriaLabel"></v-icon>
                                    <v-icon v-if="iconDuplicate" :name="iconName" role="img" :aria-label="iconAriaLabel"></v-icon>
                                </div>
                            </div>
                            <template v-if="$slots.default && $slots.default.length && $slots.default[0].text">
                                <div :class="[
                                    'column',
                                    'column--label',
                                    `column--label-align-${iconAlign === 'left' ? 'right' : 'left'}`
                                ]">
                                    <div class="component__button_label">
                                        <span><slot></slot></span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="columns is-vcentered">
                            <div class="column">
                                <div class="component__button_label">
                                    <template v-if="$slots.default && $slots.default.length && $slots.default[0].text">
                                        <span><slot></slot></span>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </component>
                <template v-if="tooltipActive">
                    <div :class="[
                        'component__button_tooltip',
                        tooltipPosition ? `component__button_tooltip-position--${tooltipPosition}` : '',
                        tooltipSize ? `component__button_tooltip-size--${tooltipSize}` : ''
                    ]">
                        <div class="component__button_tooltip_text">
                            <template v-if="tooltipText">{{ tooltipText }}</template>
                            <slot name="tooltip"></slot>
                        </div>
                    </div>
                </template>
                <template v-if="hint">
                    <div class="component__button_hint">{{ hint }}</div>
                </template>
            </div>
            <template v-if="listActive">
                <div :class="[
                    'column',
                    'column--list'
                ]">
                    <div class="component__button_list">
                        <component-button-menu
                            :icon-name="'chevron-down'"
                            :theme="theme"
                            :buttons="listItems"
                            @emit-close="handleCloseListMenu"
                            @emit-open="handleOpenListMenu"
                            @emit="handleEmit"
                        ></component-button-menu>
                    </div>
                </div>
            </template>
            <template v-if="disabled && disabledHintActive">
                <div :class="['column', 'is-narrow', `column--align-${disabledHintAlign}`]">
                    <div class="component__button_hint custom-none">
                        <div class="component__button_hint_action">
                            <v-icon :name="'help-circle'"></v-icon>
                        </div>
                        <div :class="[
                            'component__button_hint_box',
                            disabledHintPosition ? `component__button_hint_box--${disabledHintPosition}` : ''
                        ]">
                            <div class="component__button_hint_box_title">{{ disabledHintTitle }}</div>
                            <template v-if="disabledHintText && disabledHintText.length">
                                <div class="component__button_hint_box_text">
                                    {{ disabledHintText }}
                                </div>
                            </template>
                            <template v-if="disabledHintItems.length">
                                <ul class="component__button_hint_box_list">
                                    <li
                                        v-for="(item, index) in disabledHintItems"
                                        :key="index"
                                    >{{ item }}
                                    </li>
                                </ul>
                            </template>
                            <template v-if="disabledHintExtraItems.length">
                                <div class="component__button_hint_box_title component__button_hint_box_title--extra">
                                    {{ disabledHintExtraTitle }}
                                </div>
                                <ul class="component__button_hint_box_list component__button_hint_box_list--extra">
                                    <li
                                        v-for="(item, index) in disabledHintExtraItems"
                                        :key="index"
                                    >{{ item }}
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <template v-if="listActive && listItems && listItems.length">

        </template>
        <template v-if="notifyActive">
            <pop-notify
                ref="componentpopnotify"
                :class="`component__button_notify--${notifyTitle}`"
                :title-text="notifyTitle"
                :content-text="notifyText"
                :prevent-close="notifyPreventClose"
                :cancel-button-text="notifyCancelText"
                :custom-button-active="notifyCustomActive"
                :custom-button-text="notifyCustomText"
                :custom-button-danger="notifyCustomDanger"
                :custom-button-animate-loading="notifyCustomAnimateLoading"
                :submit-button-active="notifySubmitActive"
                :submit-button-text="notifySubmitText"
                :submit-button-danger="notifySubmitDanger"
                :submit-button-animate-loading="notifySubmitAnimateLoading"
                :submit-button-animate-loading-name="notifySubmitAnimateLoadingName"
                :select-active="notifySelectActive"
                :select-title="notifySelectTitle"
                :select-items="notifySelectItems"
                :select-hint="notifySelectHint"
                :size="notifySize"
                @emit-custom="emitNotifyCustom"
                @emit-submit="emitNotifySubmit"
            />
        </template>
    </div>
</template>

<script>
export default {
  name: 'component-button',
  components: {
    PopNotify: () => import('@/components/default/shared/PopNotify'),
    ComponentButtonMenu: () => import('@/components/default/shared/ComponentButtonMenu')
  },
  data () {
    return {
      loading: false,
      listExpanded: false
    }
  },
  props: {
    prevent: {
      type: Boolean,
      default: false
    },
    listActive: {
      type: Boolean,
      default: false
    },
    listItems: {
      type: Array,
      default: () => []
    },
    listDynamicPadding: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    fullheight: {
      type: Boolean,
      default: false
    },
    apend: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean
    },
    notifyActive: {
      type: Boolean,
      default: false
    },
    notifyTitle: {
      type: String,
      default: function () {
        return this.$t('shared.viewAction.props.customTitle')
      }
    },
    notifyText: {
      type: String,
      default: function () {
        return this.$t('shared.viewAction.props.customText')
      }
    },
    notifySize: {
      type: String
    },
    notifyCancelText: {
      type: String,
      default: function () {
        return this.$t('shared.viewAction.props.cancel')
      }
    },
    notifyCustomActive: {
      type: Boolean,
      default: false
    },
    notifyCustomText: {
      type: String,
      default: function () {
        return this.$t('shared.viewAction.props.custom')
      }
    },
    notifyCustomDanger: {
      type: Boolean,
      default: false
    },
    notifyCustomAnimateLoading: {
      type: Boolean,
      default: true
    },
    notifySubmitActive: {
      type: Boolean,
      default: true
    },
    notifySubmitText: {
      type: String,
      default: function () {
        return this.$t('shared.viewAction.props.confirm')
      }
    },
    notifySubmitDanger: {
      type: Boolean,
      default: false
    },
    notifySubmitAnimateLoading: {
      type: Boolean,
      default: true
    },
    notifySubmitAnimateLoadingName: {
      type: String,
      default: 'submit-notify'
    },
    notifySelectActive: {
      type: Boolean,
      default: false
    },
    notifySelectTitle: {
      type: String,
      default: ''
    },
    notifySelectItems: {
      type: [Array, Object],
      default: () => {
      }
    },
    notifySelectHint: {
      type: String,
      default: ''
    },
    notifyPreventClose: {
      type: Boolean,
      default: false
    },
    //
    name: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    align: {
      type: String,
      default: 'centered'
    },
    animateLoadingForce: {
      type: Boolean,
      default: false
    },
    animateLoading: {
      type: Boolean,
      default: false
    },
    loadingIconPosition: {
      type: String
    },
    animateLoadingEventName: {
      type: String,
      default: function () {
        return this.$t('shared.components.props.sending')
      }
    },
    classes: {
      type: String,
      default: ''
    },
    danger: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledHintActive: {
      type: Boolean,
      default: false
    },
    disabledHintAlign: {
      // one of: ['left', 'right']
      type: String,
      default: 'right'
    },
    disabledHintPosition: {
      // one of: ['top', 'bottom']
      type: String,
      default: 'bottom'
    },
    disabledHintTitle: {
      type: String
    },
    disabledHintText: {
      type: String
    },
    disabledHintItems: {
      type: Array,
      default: () => []
    },
    disabledHintExtraTitle: {
      type: String,
      default: function () {
        return this.$t('shared.components.props.consider')
      }
    },
    disabledHintExtraItems: {
      type: Array,
      default: () => []
    },
    icon: {
      type: Boolean,
      default: false
    },
    iconAlign: {
      type: String,
      // one of ['left', 'right']
      default: `left`
    },
    iconName: {
      type: String,
      default: ``
    },
    iconAriaLabel: {
      type: String,
      default: ``
    },
    iconSize: {
      type: String,
      default: 'default'
    },
    iconDuplicate: {
      type: Boolean,
      default: false
    },
    inverse: {
      type: Boolean,
      default: false
    },
    size: {
      // one of: ['tiny', 'small', 'medium', 'large', 'full']
      type: String,
      default: 'small'
    },
    bold: {
      type: Boolean,
      default: false
    },
    target: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    theme: {
      // one of: ['default', 'primary', 'primary-dark', 'secondary', 'secondary-dark', 'tertiary', 'nav', 'grey']
      type: String,
      default: 'primary'
    },
    secondaryTheme: {
      // one of: ['default', 'primary', 'primary-dark', 'secondary', 'secondary-dark', 'tertiary', 'nav', 'grey']
      type: String
    },
    to: {
      type: [Array, Object, String],
      default: () => {
      }
    },
    toExternal: {
      type: String,
      default: ''
    },
    underline: {
      type: Boolean,
      default: false
    },
    iconRaw: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String
    },
    tooltipActive: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: 'Copy'
    },
    tooltipPosition: {
      type: String
    },
    tooltipSize: {
      type: String
    }
  },
  computed: {
    outputClasses () {
      return [
        `btn`,
        this.text ? `btn--text` : '',
        this.bold ? `btn--bold` : '',
        this.icon ? `btn--icon` : '',
        this.icon && this.iconAlign ? `btn--icon-align-${this.iconAlign}` : '',
        this.danger ? `btn--danger` : '',
        this.loading || this.animateLoadingForce ? `btn--loading` : '',
        this.loadingIconPosition ? `btn--loadingiconposition-${this.loadingIconPosition}` : '',
        this.inverse ? `btn--inverse` : '',
        this.underline ? `btn--underline` : '',
        this.iconRaw ? 'btn--iconraw' : '',
        this.size ? `btn--${this.size}` : '',
        this.theme ? `btn--${this.theme}` : '',
        this.secondaryTheme ? `btn--${this.secondaryTheme}` : '',
        this.$slots.default && this.$slots.default.length ? (this.$slots.default[0].text ? '' : 'btn--no-text') : 'btn--no-text',
        this.fullheight ? 'btn--fullheight' : '',
        this.isActive ? 'btn--active' : '',
        this.prevent ? 'btn--prevent' : '',
        this.classes
      ]
    }
  },
  methods: {
    handleEmit (data) {
      this.$emit('emit', data)
    },
    handleCloseListMenu () {
      if (!this.listDynamicPadding) return

      const button = this.$el

      button.style.paddingBottom = `0px`
    },
    handleOpenListMenu () {
      if (!this.listDynamicPadding) return

      const button = this.$el
      const menuList = button.querySelector('.component__button_menu_list')
      menuList.style.display = 'block'
      const newPaddingBottom = menuList.clientHeight
      button.style.paddingBottom = `${newPaddingBottom}px`
    },
    closeNotify () {
      this.$refs.componentpopnotify.closeNotify()
    },
    emitNotifySubmit () {
      this.$emit('emit-notify-submit')
    },
    emitNotifyCustom () {
      this.$emit('emit-notify-custom')
    },
    stopLoading: function () {
      this.loading = false
    },
    startLoading: function () {
      this.loading = true
    },
    //
    clickHandler () {
      if (!this.inactive) {
        switch (true) {
          case Boolean(this.toExternal):
            break
          default:
            this.submit()
            break
        }
      }
    },
    //
    submit: function () {
      if (this.disabled || this.loading || this.animateLoadingForce) return
      if (this.animateLoading) this.loading = true
      if (this.notifyActive) {
        this.$refs.componentpopnotify.openNotify()
      }
      this.$emit('click')
    }
  },
  mounted () {
    this.$eventBus.$off(`stop-${this.animateLoadingEventName}`)
    if (this.toExternal) {
      this.$refs.button.setAttribute('href', this.toExternal)
    }
  },
  updated () {
    this.$eventBus.$on(`stop-${this.animateLoadingEventName}`, this.stopLoading)
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentbutton";
</style>
