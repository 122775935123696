<template>
    <div class="component component__customform">
        <ul class="component__customform_form">
            <li
                :class="[`component__customform_form_field`, `component__customform_form_field--${field.type}`]"
                v-for="(field, index) in fieldset"
                :key="field.label"
            >
                <component
                    :key="`component-custom-form-${index}`"
                    :is="`form-${formFields[field.type]}`"
                    :label="field.label"
                    :sub-label="field.subLabel"
                    :name="`${field.label}-${index}` | toKebabCase"
                    :value="field.answers"
                    :items="filteredOptions(field.options, field.type)"
                    :other="field.additional ? field.additional.other : undefined"
                    :width="'full'"
                    :multiple="true"
                    :required="field.validation ? field.validation.mandatory: undefined"
                    :v-validate="{ required: field.validation ? field.validation.mandatory: undefined }"
                    :data-vv-as="field.name || field.label.toLowerCase()"
                    @emit-other-value="emitOtherValue($event, index)"
                    @input="emitInput($event, index)"
                >{{ field.helpText }}
                </component>
            </li>
        </ul>
    </div>
</template>

<script>
import FormCheckbox from '@/components/default/forms/FormCheckbox'
import FormRadioHorizontal from '@/components/default/forms/FormRadioHorizontal'
import FormSelect from '@/components/default/forms/FormSelect'
import FormText from '@/components/default/forms/FormText'
import FormTextarea from '@/components/default/forms/FormTextarea'

export default {
  name: 'component-customform',
  data () {
    return {
      formFields: {
        'editor': 'editor',
        'text': 'text',
        'textarea': 'textarea',
        'checkbox': 'checkbox',
        'single-choice': 'radio-horizontal',
        'multi-choice': 'select'
      },
      formValues: this.fieldset
    }
  },
  components: {
    FormEditor: () => import('@/components/default/forms/FormEditor'),
    FormTextarea,
    FormText,
    FormSelect,
    FormRadioHorizontal,
    FormCheckbox
  },
  props: {
    fieldset: {
      type: [Array, Object],
      default: () => {},
      required: true
    }
  },
  methods: {
    filteredOptions: function (items, type) {
      if (type === 'single-choice') {
        return this.fromStringToArrayRadio(items)
      } else {
        return this.fromStringToArraySelect(items)
      }
    },
    //
    emitOtherValue: function (value, index) {
      if (typeof value === 'object') {
        let object = []
        for (let i = 0; i < value.length; i++) {
          object.push(value[i].value)
        }
        value = object
      }
      this.$emit('emit-errors-value', { value: value, index: index })
    },
    emitInput: function (value, index) {
      if (typeof value === 'object') {
        let object = []
        for (let i = 0; i < value.length; i++) {
          object.push(value[i].value)
        }
        value = object
      }
      this.$emit('input', { value: value, index: index })
    }
  },
  inject: ['$validator']
}
</script>
