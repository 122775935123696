import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    id: '',
    apiKey: '',
    status: 'inactive',
    issuedAt: '',
    issuedBy: '',
    expiresAt: '',
    workspaceId: '',
    disabledAt: ''
  },
  default: {}
}

const lmsSuperadminWorkspaceApiIntegrationKey = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkspaceApiIntegrationKeyId: state => {
      return state.data.id
    },
    getLmsSuperadminWorkspaceApiIntegrationKeyApiKey: state => {
      return state.data.apiKey
    },
    getLmsSuperadminWorkspaceApiIntegrationKeyStatus: state => {
      return state.data.status
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEY_GET (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))

      state.data = JSON.parse(JSON.stringify(payloadData))
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEY_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.data))
    }
  },
  actions: {
    lmsSuperadminWorkspaceApiIntegrationKeySet ({ commit }, data) {
      commit('LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEY_GET', data)
    },
    lmsSuperadminWorkspaceApiIntegrationKeyGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const keyId = data

        commit('LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEY_CLEAR')

        axios({
          method: 'get',
          url: `${api.euma}/form/apikey/${keyId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEY_GET', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceApiIntegrationKeyCreate ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data

        axios({
          method: 'post',
          url: `${api.euma}/form/apikey`,
          data: {
            workspaceId
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'API key generated',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'API key not generated',
            message: `There can be only max 2 active keys for one workspace`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceApiIntegrationKeySuspend ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const keyId = data

        axios({
          method: 'delete',
          url: `${api.euma}/form/apikey/${keyId}/suspend`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'API key suspended',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'API key not suspended',
            message: ``,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    //
    lmsSuperadminWorkspaceApiIntegrationKeyClear ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_APIINTEGRATION_KEY_CLEAR')
    }
  }
}

export default lmsSuperadminWorkspaceApiIntegrationKey
