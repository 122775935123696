import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    name: '',
    description: '',
    items: [],
    targets: [],
    workspace: '',
    targetType: 'organisationUnit',
    reenrolment: false,
    active: 0
  },
  default: {}
}

const lmsUserAutoenrolment = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsUserAutoenrolmentType: state => {
      return state.data.targetType
    },
    getLmsUserAutoenrolmentName: state => {
      return state.data.name
    },
    getLmsUserAutoenrolmentDescription: state => {
      return state.data.description
    },
    getLmsUserAutoenrolmentSuggestedCourses: state => {
      return state.data.items
    },
    getLmsUserAutoenrolmentTargetAudience: state => {
      return state.data.targets
    },
    getLmsUserAutoenrolmentStatus: state => {
      return state.data.active
    },
    getLmsUserAutoenrolmentReenrolment: state => {
      return state.data.reenrolment
    }
  },
  mutations: {
    LMS_USER_AUTOENROLMENT_GET (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))
      payloadData.reenrolment = false
      payloadData.items = payloadData.items && payloadData.items.length ? payloadData.items.map(obj => obj.id) : []
      payloadData.targets = payloadData.targets && payloadData.targets.length ? payloadData.targets.map(obj => obj.id) : []

      state.data = payloadData
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    LMS_USER_AUTOENROLMENT_SET_TYPE (state, payload) {
      state.data.targetType = payload
    },
    LMS_USER_AUTOENROLMENT_SET_NAME (state, payload) {
      state.data.name = payload
    },
    LMS_USER_AUTOENROLMENT_SET_DESCRIPTION (state, payload) {
      state.data.description = payload
    },
    LMS_USER_AUTOENROLMENT_SET_SUGGESTEDCOURSES (state, payload) {
      state.data.items = payload.map(obj => obj.value || obj)
    },
    LMS_USER_AUTOENROLMENT_SET_TARGETAUDIENCE (state, payload) {
      state.data.targets = payload && payload.length ? payload.map(obj => obj.value || obj.id || obj) : []
    },
    LMS_USER_AUTOENROLMENT_SET_REENROLMENT (state, payload) {
      state.data.reenrolment = payload
    },
    LMS_USER_AUTOENROLMENT_SET_STATUS (state, payload) {
      state.data.active = payload
    },
    LMS_USER_AUTOENROLMENT_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    }
  },
  actions: {
    lmsUserAutoenrolmentGet ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.learningactivities}/auto-enrolments/${id}`
        }).then(response => {
          commit('LMS_USER_AUTOENROLMENT_GET', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsUserAutoenrolmentCreate ({ state, dispatch, getters }) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        stateData.workspace = getters.getAuthUserSecondStageTokenDataWorkspaceId

        axios({
          method: 'post',
          url: `${api.learningactivities}/auto-enrolments`,
          data: stateData
        }).then(response => {
          const ruleId = response.data.id
          if (stateData.reenrolment) {
            dispatch('lmsUserAutoenrolmentRun', ruleId).finally(() => {
              resolve(response.data)
            })
          } else {
            resolve(response.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsUserAutoenrolmentUpdate ({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        const autoenrolmentId = stateData.id

        axios({
          method: 'patch',
          url: `${api.learningactivities}/auto-enrolment/${autoenrolmentId}`,
          data: stateData
        }).then(response => {
          const ruleId = response.data.id
          if (stateData.reenrolment) {
            dispatch('lmsUserAutoenrolmentRun', ruleId).finally(() => {
              resolve(response.data)
            })
          } else {
            resolve(response.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsUserAutoenrolmentRemove (context, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${api.learningactivities}/auto-enrolment/${id}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsUserAutoenrolmentClear ({ commit }) {
      commit('LMS_USER_AUTOENROLMENT_CLEAR')
    },
    lmsUserAutoenrolmentRun (ctx, data) {
      return new Promise((resolve, reject) => {
        const ruleId = data

        axios({
          method: 'post',
          url: `${api.learningactivities}/auto-enrolments/${ruleId}/on-demand`
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsUserAutoenrolmentSetType ({ commit }, payload) {
      commit('LMS_USER_AUTOENROLMENT_SET_TYPE', payload)
    },
    lmsUserAutoenrolmentSetName ({ commit }, payload) {
      commit('LMS_USER_AUTOENROLMENT_SET_NAME', payload)
    },
    lmsUserAutoenrolmentSetDescription ({ commit }, payload) {
      commit('LMS_USER_AUTOENROLMENT_SET_DESCRIPTION', payload)
    },
    lmsUserAutoenrolmentSetSuggestedCourses ({ commit }, payload) {
      commit('LMS_USER_AUTOENROLMENT_SET_SUGGESTEDCOURSES', payload)
    },
    lmsUserAutoenrolmentSetTargetAudience ({ commit }, payload) {
      commit('LMS_USER_AUTOENROLMENT_SET_TARGETAUDIENCE', payload)
    },
    lmsUserAutoenrolmentSetReenrolment ({ commit }, payload) {
      commit('LMS_USER_AUTOENROLMENT_SET_REENROLMENT', payload)
    },
    lmsUserAutoenrolmentSetStatus ({ commit }, payload) {
      commit('LMS_USER_AUTOENROLMENT_SET_STATUS', payload)
    }
  }
}

export default lmsUserAutoenrolment
