import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { checkResponseNotFound } from '@/interceptorsHelpers'
import { eventbus } from '@/main'

const certificateDefault = {
  id: '',
  type: 'custom'
}
const customisationDefault = {
  displayName: false,
  workspaceLogo: '',
  workspaceLogoHeight: 25,
  primaryColour: '#0074D9',
  textColour: '#111111',
  backgroundColour: '#FFFFFF',
  onboardings: [
    {
      page: '',
      isEnabled: '',
      text: 'Welcome to Form - your organisation\'s training site.\n\nYou’re currently on the Homepage where you can find some useful information and quick links to your top tasks.\n\nTo get back to this page at any time just click your site logo.',
      image: '',
      link: ''
    }
  ],
  helpdeskEmail: '',
  helpdeskPhone: ''
}
const data = {
  data: {
    customerId: '',
    id: '',
    name: '',
    status: 'active',
    selfRegistrationEnabled: false,
    feedbackFormsEnabled: false,
    customisation: JSON.parse(JSON.stringify(customisationDefault)),
    certificate: JSON.parse(JSON.stringify(certificateDefault)),
    authSettings: [],
    apiIntegrationState: false,
    apiServiceState: false,
    checkApiStatePending: false
  },
  custom: {
    authSettingsChanged: false,
    basicData: {}
  },
  default: {}
}

const lmsSuperadminWorkspace = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkspace: state => {
      return state.data
    },
    getLmsSuperadminWorkspaceId: state => {
      return state.data.id
    },
    getLmsSuperadminWorkspaceCustomerId: state => {
      return state.data.customerId
    },
    getLmsSuperadminWorkspaceCertificateType: state => {
      return state.data.certificate.type
    },
    getLmsSuperadminWorkspaceCertificateId: state => {
      return state.data.certificate.id
    },
    getLmsSuperadminWorkspaceCustomerName: state => {
      return state.data.customerName
    },
    getLmsSuperadminWorkspaceExpirydate: state => {
      return state.data.expiryDate
    },
    getLmsSuperadminWorkspaceName: state => {
      return state.data.name
    },
    getLmsSuperadminWorkspaceAuthSettings: state => {
      return state.data.authSettings
    },
    getLmsSuperadminWorkspaceSelfRegisterStatus: state => {
      return state.data.selfRegistrationEnabled
    },
    getLmsSuperadminWorkspaceFeedbackFormsEnabled: state => {
      return state.data.feedbackFormsEnabled
    },
    getLmsSuperadminWorkspaceCustomisationDisplayName: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.displayName : ''
    },
    getLmsSuperadminWorkspaceCustomisationWorkspaceLogo: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.workspaceLogo : ''
    },
    getLmsSuperadminWorkspaceCustomisationWorkspaceLogoHeight: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.workspaceLogoHeight : ''
    },
    getLmsSuperadminWorkspaceCustomisationPrimaryColor: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.primaryColour : ''
    },
    getLmsSuperadminWorkspaceCustomisationTextColor: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.textColour : ''
    },
    getLmsSuperadminWorkspaceCustomisationBackgroundColor: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.backgroundColour : ''
    },
    getLmsSuperadminWorkspaceCustomisationOnboardingsOnboardingText: state => {
      const customisation = state.data.customisation
      return customisation ? (customisation.onboardings.length ? customisation.onboardings[0].text : '') : ''
    },
    getLmsSuperadminWorkspaceCustomisationHelpdeskEmail: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.helpdeskEmail : ''
    },
    getLmsSuperadminWorkspaceCustomisationHelpdeskPhone: state => {
      const customisation = state.data.customisation
      return customisation ? customisation.helpdeskPhone : ''
    },
    getLmsSuperadminWorkspaceServices: state => {
      return state.data.services
    },
    getLmsSuperadminWorkspaceStatus: state => {
      return state.data.status
    },
    getLmsSuperadminWorkspaceApiIntegrationState: state => {
      return state.data.apiIntegrationState
    },
    getLmsSuperadminWorkspaceApiServiceState: state => {
      return state.data.apiServiceState
    },
    getLmsSuperadminWorkspaceCheckApiStatePending: state => {
      return state.data.checkApiStatePending
    },
    getLmsSuperadminWorkspaceCustomBasicData: state => {
      return state.custom.basicData
    },
  },
  mutations: {
    LMS_SUPERADMIN_WORKSPACE_GET (state, payload) {
      const stateData = state.data
      const certificateDefaultId = stateData.certificate.id
      let payloadData = JSON.parse(JSON.stringify(payload))

      payloadData.selfRegistrationEnabled = payloadData.selfRegistrationEnabled || false
      payloadData.feedbackFormsEnabled = payloadData.feedbackFormsEnabled !== undefined ? payloadData.feedbackFormsEnabled : true
      payloadData.certificate = JSON.parse(JSON.stringify(certificateDefault))
      payloadData.certificate.id = certificateDefaultId
      payloadData.certificate.type = 'selected'
      payloadData.authSettings = []
      payloadData.apiIntegrationState = false
      payloadData.apiServiceState = false
      payloadData.checkApiStatePending = false
      if (!payloadData.customisation) {
        payloadData.customisation = customisationDefault
      }
      if (!payloadData.customisation.workspaceLogoHeight) {
        payloadData.customisation.workspaceLogoHeight = 25
      }

      state.data = payloadData
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CUSTOM_BASICDATA (state, payload) {
      state.custom.basicData = payload
    },
    //
    LMS_SUPERADMIN_WORKSPACE_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_WORKSPACE_CLEAR_CERTIFICATE (state) {
      let certificateDefaultData = JSON.parse(JSON.stringify(certificateDefault))
      certificateDefaultData.type = state.data.id ? 'selected' : 'custom'
      state.data.certificate = certificateDefaultData
    },
    LMS_SUPERADMIN_WORKSPACE_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    LMS_SUPERADMIN_WORKSPACE_UPDATE_DEFAULT (state) {
      state.default = JSON.parse(JSON.stringify(state.data))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_SET_ADMINS (state, payload) {
      state.data.admins = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMER (state, payload) {
      state.data.customerId = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_EXPIRYDATE (state, payload) {
      state.data.expiryDate = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_NAME (state, payload) {
      state.data.name = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_SELFREGISTERENABLED (state, payload) {
      state.data.selfRegistrationEnabled = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_FEEDBACKFORMSENABLED (state, payload) {
      state.data.feedbackFormsEnabled = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_SERVICES (state, payload) {
      state.data.services = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CERTIFICATETYPE (state, payload) {
      state.data.certificate.type = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CERTIFICATEID (state, payload) {
      state.data.certificate.id = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_WORKSPACELOGO (state, payload) {
      state.data.customisation.workspaceLogo = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_WORKSPACELOGOHEIGHT (state, payload) {
      state.data.customisation.workspaceLogoHeight = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_DISPLAYNAME (state, payload) {
      state.data.customisation.displayName = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_PRIMARYCOLOR (state, payload) {
      state.data.customisation.primaryColour = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_TEXTCOLOR (state, payload) {
      state.data.customisation.textColour = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_BACKGROUNDCOLOR (state, payload) {
      state.data.customisation.backgroundColour = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_ONBOARDINGS_ONBOARDING_TEXT (state, payload) {
      state.data.customisation.onboardings[0].text = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_HELPDESKEMAIL (state, payload) {
      state.data.customisation.helpdeskEmail = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_HELPDESKPHONE (state, payload) {
      state.data.customisation.helpdeskPhone = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_STATUS (state, payload) {
      state.data.status = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_AUTHSETTINGS (state, payload) {
      state.data.authSettings = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_AUTHSETTING_VALUE (state, payload) {
      let settings = state.data.authSettings
      let selectedSetting = settings.find(obj => obj.id === payload.id)

      if (selectedSetting) {
        selectedSetting.active = payload.value
        state.custom.authSettingsChanged = true
      }
    },
    LMS_SUPERADMIN_WORKSPACE_SET_APIINTEGRATION_STATE (state, payload) {
      state.data.apiIntegrationState = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_APISERVICE_STATE (state, payload) {
      state.data.apiServiceState = payload
    },
    LMS_SUPERADMIN_WORKSPACE_SET_CHECKAPISTATEPENDING (state, payload) {
      state.data.checkApiStatePending = payload
    }
  },
  actions: {
    lmsSuperadminWorkspaceGet ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('LMS_SUPERADMIN_WORKSPACE_CLEAR')

        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/workspaces/${id}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_GET', response.data)
          resolve(response.data)
        }).catch((error) => {
          checkResponseNotFound(error.response.status)
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceGetBasic ({ commit }, id) {
      commit('LMS_SUPERADMIN_WORKSPACE_CLEAR')

      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/workspaces/${id}/basic-data`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_SET_CUSTOM_BASICDATA', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceGetAuthSettings ({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        const workspaceId = id || state.data.id

        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/signup-and-authentication-settings/${workspaceId}`
        }).then(response => {
          const responseData = JSON.parse(JSON.stringify(response.data))
          const options = responseData.options
          for (let option of options) {
            commit('LMS_SUPERADMIN_WORKSPACE_SET_AUTHSETTING_VALUE', { id: option, value: true })
          }
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceGetFeedbackEnabled ({ commit }, workspaceId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/workspace-feedback-enabled/${workspaceId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_SET_FEEDBACKFORMSENABLED', response.data.feedbackFormsEnabled)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceUpdateAuthSettings ({ state }, id) {
      return new Promise((resolve, reject) => {
        const stateData = JSON.parse(JSON.stringify(state.data))
        const workspaceId = id || stateData.id
        const authSettings = stateData.authSettings
        const filteredAuthSettings = authSettings.filter(obj => obj.active)
        const mappedAuthSettings = filteredAuthSettings.map(obj => obj.id)

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/signup-and-authentication-settings/${workspaceId}`,
          data: {
            options: mappedAuthSettings
          }
        }).then(response => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    //
    lmsSuperadminWorkspaceClear (context) {
      context.commit('LMS_SUPERADMIN_WORKSPACE_CLEAR')
    },
    lmsSuperadminWorkspaceClearCertificate ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_CLEAR_CERTIFICATE')
    },
    lmsSuperadminWorkspaceReset (context) {
      context.commit('LMS_SUPERADMIN_WORKSPACE_RESET')
    },
    lmsSuperadminWorkspaceRemove ({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${api.subscriptionmanager}/workspace/${id}`
        }).then(response => {
          router.push({ name: 'lms-superadmin-workspace-list' })
          dispatch('defaultNotificationToastsAdd', {
            title: 'Workspace archive',
            message: 'Workspace has been successfully archived.',
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Workspace archive',
            message: `Workspace hasn't been archived.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceUpdate ({ commit, state, rootState, dispatch, getters }) {
      return new Promise((resolve, reject) => {
        const loggedInWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        const workspaceCertificateType = state.data.certificate.type
        const workspaceCertificateDefault = state.data.certificate.default
        const workspaceId = state.data.id
        const certificateChanged = getters.getEbsCertificateCustomChanged
        const certificateId = getters.getEbsCertificateId
        const authSettingsChanged = state.custom.authSettingsChanged
        let stateData = JSON.parse(JSON.stringify(state.data))

        const onResolve = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Workspace update',
            message: 'Workspace has been successfully updated.',
            status: 'success'
          })
          commit('LMS_SUPERADMIN_WORKSPACES_CLEAR')
          if (rootState.route.query.customerId) {
            router.push({ name: 'lms-superadmin-customer-edit-workspaces', params: { id: rootState.route.query.customerId } })
          }
          if (state.data.id === loggedInWorkspaceId) {
            dispatch('defaultWorkspaceInitialize', true)
          }
        }

        const onCatch = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Workspace update',
            message: `Workspace hasn't been updated.`,
            status: 'error'
          })
        }

        const lmsSuperadminWorkspaceUpdateAuthSettings = () => {
          return dispatch('lmsSuperadminWorkspaceUpdateAuthSettings')
        }

        const ebsCertificateCreate = () => {
          return dispatch('ebsCertificateCreate', { workspaceId: workspaceId, workspaceDefault: workspaceCertificateDefault })
        }

        const ebsCertificateUpdate = () => {
          return dispatch('ebsCertificateUpdate')
        }

        const lmsSuperadminWorkspaceUpdate = () => {
          return axios({
            method: 'patch',
            url: `${api.subscriptionmanager}/workspaces`,
            data: stateData
          }).then(response => {
            onResolve()
            resolve(response)
          }).catch(error => {
            onCatch()
            reject(error)
          })
        }

        let funcArr = [lmsSuperadminWorkspaceUpdate]
        let funcCounter = 0

        if (authSettingsChanged) {
          funcArr.push(lmsSuperadminWorkspaceUpdateAuthSettings)
        }
        if (workspaceCertificateType === 'custom') {
          funcArr.push(ebsCertificateCreate)
        } else {
          if (certificateChanged && certificateId) {
            funcArr.push(ebsCertificateUpdate)
          }
        }

        for (let func of funcArr) {
          func().finally(() => {
            funcCounter++
            if (funcCounter === funcArr.length) {
              eventbus.$emit('lmsSuperadminWorkspaceUpdate')
            }
          })
        }
      })
    },
    lmsSuperadminWorkspaceActivateSelfRegistration ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data || state.data.id

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/workspaces/${workspaceId}/self-registration`,
          data: {
            enabled: true
          }
        }).then(response => {
          dispatch('lmsSuperadminWorkspaceSetSelfRegisterEnabled', response.data.selfRegistrationEnabled)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceDeactivateSelfRegistration ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data || state.data.id

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/workspaces/${workspaceId}/self-registration`,
          data: {
            enabled: false
          }
        }).then(response => {
          dispatch('lmsSuperadminWorkspaceSetSelfRegisterEnabled', response.data.selfRegistrationEnabled)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceActivateFeedbackForms ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data || state.data.id

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/workspaces/${workspaceId}/feedback-forms`,
          data: {
            enabled: true
          }
        }).then(response => {
          dispatch('lmsSuperadminWorkspaceSetFeedbackFormsEnabled', response.data.feedbackFormsEnabled)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceDeactivateFeedbackForms ({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data || state.data.id

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/workspaces/${workspaceId}/feedback-forms`,
          data: {
            enabled: false
          }
        }).then(response => {
          dispatch('lmsSuperadminWorkspaceSetFeedbackFormsEnabled', response.data.feedbackFormsEnabled)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceGetApiIntegrationState ({ commit, state, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data || state.data.id || getters.getAuthUserSecondStageTokenDataWorkspaceId

        dispatch('lmsSuperadminWorkspaceClearApiIntegrationState')

        if (!workspaceId) return reject()
        commit('LMS_SUPERADMIN_WORKSPACE_SET_CHECKAPISTATEPENDING', true)

        axios({
          method: 'get',
          url: `${api.euma}/form/workspace/${workspaceId}`
        }).then(response => {
          dispatch('lmsSuperadminWorkspaceSetApiIntegrationState', response.data.api)
          dispatch('lmsSuperadminWorkspaceSetApiServiceState', response.data.service)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        }).finally(() => {
          commit('LMS_SUPERADMIN_WORKSPACE_SET_CHECKAPISTATEPENDING', false)
        })
      })
    },
    lmsSuperadminWorkspaceClearApiIntegrationState ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_APIINTEGRATION_STATE', false)
      commit('LMS_SUPERADMIN_WORKSPACE_SET_APISERVICE_STATE', false)
      commit('LMS_SUPERADMIN_WORKSPACE_SET_CHECKAPISTATEPENDING', false)
    },
    lmsSuperadminWorkspaceActivateApiIntegration ({ commit, state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data || state.data.id

        axios({
          method: 'patch',
          url: `${api.euma}/form/workspace/api/${workspaceId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_SET_APIINTEGRATION_STATE', true)
          dispatch('defaultNotificationToastsAdd', {
            title: 'API enabled',
            message: ``,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'API not enabled',
            message: ``,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceDeactivateApiIntegration ({ commit, state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data || state.data.id

        axios({
          method: 'delete',
          url: `${api.euma}/form/workspace/api/${workspaceId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_SET_APIINTEGRATION_STATE', false)
          dispatch('defaultNotificationToastsAdd', {
            title: 'API disabled',
            message: ``,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'API not disabled',
            message: ``,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceCreate ({ commit, state, getters, rootState, dispatch }, redirect = true) {
      return new Promise((resolve, reject) => {
        const loggedInWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        const workspaceCertificateType = state.data.certificate.type
        const workspaceCertificateId = state.data.certificate.id
        const workspaceCertificateDefault = state.data.certificate.default
        const certificateChanged = getters.getEbsCertificateCustomChanged
        const certificateId = getters.getEbsCertificateId
        let stateData = JSON.parse(JSON.stringify(state.data))

        const onResolve = (workspaceId) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Workspace create',
            message: 'Workspace has been successfully created.',
            status: 'success',
            linkText: 'Go to workspace',
            linkTo: {
              name: 'lms-superadmin-workspace-edit',
              params: { id: workspaceId }
            }
          })
          commit('LMS_SUPERADMIN_WORKSPACES_CLEAR')
          commit('LMS_SUPERADMIN_WORKSPACE_UPDATE_DEFAULT')
          commit('LMS_SUPERADMIN_CUSTOMER_CLEAR')
          if (rootState.route.query.customerId) {
            router.push({ name: 'lms-superadmin-customer-edit-workspaces', params: { id: rootState.route.query.customerId } })
          } else {
            if (redirect) {
              router.push({ name: 'lms-superadmin-workspace-list' })
            }
          }
          if (state.data.id === loggedInWorkspaceId) {
            dispatch('defaultWorkspaceInitialize', true)
          }
        }
        const onCatch = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Workspace create',
            message: `Workspace hasn't been created.`,
            status: 'error'
          })
        }
        const ebsCertificateCreate = (workspaceId) => {
          return dispatch('ebsCertificateCreate', { workspaceId: workspaceId, workspaceDefault: workspaceCertificateDefault })
        }
        const ebsCertificateUpdate = () => {
          return dispatch('ebsCertificateUpdate')
        }

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/workspaces`,
          data: stateData
        }).then(response => {
          const workspaceId = response.data.id
          if (workspaceCertificateType === 'custom' && workspaceCertificateId && certificateChanged) {
            ebsCertificateCreate(workspaceId)
          } else {
            if (certificateChanged && certificateId) {
              ebsCertificateUpdate()
            }
          }
          onResolve(workspaceId)
          resolve(response)
        }).catch(error => {
          onCatch()
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceRestoreDefaults ({ commit, state, getters, rootState, dispatch }) {
      return new Promise((resolve, reject) => {
        const loggedInWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        let stateData = JSON.parse(JSON.stringify(state.data))
        stateData.customisation = customisationDefault

        axios({
          method: 'patch',
          url: `${api.subscriptionmanager}/workspaces`,
          data: stateData
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Workspace restore defaults',
            message: 'Workspace settings has been successfully restored to defaults.',
            status: 'success'
          })
          commit('LMS_SUPERADMIN_WORKSPACES_CLEAR')
          commit('LMS_SUPERADMIN_WORKSPACE_UPDATE_DEFAULT')
          commit('LMS_SUPERADMIN_CUSTOMER_CLEAR')
          if (rootState.route.query.customerId) {
            router.push({ name: 'lms-superadmin-customer-edit-workspaces', params: { id: rootState.route.query.customerId } })
          }
          if (state.data.id === loggedInWorkspaceId) {
            dispatch('defaultWorkspaceInitialize', true)
          }
          commit('LMS_SUPERADMIN_WORKSPACE_GET', response.data)
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Workspace restore defaults',
            message: `Workspace settings hasn't been restored to defaults.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    //
    lmsSuperadminWorkspaceSetAdmins (context, value) {
      context.commit('LMS_SUPERADMIN_WORKSPACE_SET_ADMINS', value)
    },
    lmsSuperadminWorkspaceSetCustomer (context, value) {
      context.commit('LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMER', value.value)
    },
    lmsSuperadminWorkspaceSetExpirydate (context, value) {
      context.commit('LMS_SUPERADMIN_WORKSPACE_SET_EXPIRYDATE', value)
    },
    lmsSuperadminWorkspaceSetName (context, value) {
      context.commit('LMS_SUPERADMIN_WORKSPACE_SET_NAME', value)
    },
    lmsSuperadminWorkspaceSetSelfRegisterEnabled (context, value) {
      context.commit('LMS_SUPERADMIN_WORKSPACE_SET_SELFREGISTERENABLED', value)
    },
    lmsSuperadminWorkspaceSetFeedbackFormsEnabled (context, value) {
      context.commit('LMS_SUPERADMIN_WORKSPACE_SET_FEEDBACKFORMSENABLED', value)
    },
    lmsSuperadminWorkspaceSetServices (context, value) {
      context.commit('LMS_SUPERADMIN_WORKSPACE_SET_SERVICES', value)
    },
    lmsSuperadminWorkspaceSetStatus (context, value) {
      context.commit('LMS_SUPERADMIN_WORKSPACE_SET_STATUS', value)
    },
    lmsSuperadminWorkspaceSetCertificateType ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_CERTIFICATETYPE', value)
    },
    lmsSuperadminWorkspaceSetCertificateId ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_CERTIFICATEID', value)
    },
    lmsSuperadminWorkspaceSetCustomisationWorkspaceLogo ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_WORKSPACELOGO', value)
    },
    lmsSuperadminWorkspaceSetCustomisationWorkspaceLogoHeight ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_WORKSPACELOGOHEIGHT', value)
    },
    lmsSuperadminWorkspaceSetCustomisationDisplayName ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_DISPLAYNAME', value)
    },
    lmsSuperadminWorkspaceSetCustomisationPrimaryColor ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_PRIMARYCOLOR', value)
    },
    lmsSuperadminWorkspaceSetCustomisationTextColor ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_TEXTCOLOR', value)
    },
    lmsSuperadminWorkspaceSetCustomisationBackgroundColor ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_BACKGROUNDCOLOR', value)
    },
    lmsSuperadminWorkspaceSetCustomisationOnboardingsOnboardingText ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_ONBOARDINGS_ONBOARDING_TEXT', value)
    },
    lmsSuperadminWorkspaceSetCustomisationHelpdeskEmail ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_HELPDESKEMAIL', value)
    },
    lmsSuperadminWorkspaceSetCustomisationHelpdeskPhone ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_CUSTOMISATION_HELPDESKPHONE', value)
    },
    lmsSuperadminWorkspaceSetAuthSettings ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_AUTHSETTINGS', value)
    },
    lmsSuperadminWorkspaceSetAuthSettingValue ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_AUTHSETTING_VALUE', value)
    },
    lmsSuperadminWorkspaceSetApiIntegrationState ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_APIINTEGRATION_STATE', value)
    },
    lmsSuperadminWorkspaceSetApiServiceState ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_SET_APISERVICE_STATE', value)
    }
  }
}

export default lmsSuperadminWorkspace
