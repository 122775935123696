<template>
    <div
    :class="[
        `component`,
        `component__status`,
        `component__status--${toKebabCase(status)}`,
        size ? `component__status--size-${size}` : '',
        type ? `component__status--${type}` : '',
        forceDefault ? `component__status--force-default` : '',
        forceSuccess ? `component__status--force-success` : '',
        forceInfo ? `component__status--force-info` : '',
        forceDanger ? `component__status--force-danger` : '',
        capitalize ? `component__status--capitalize` : ''
    ]"
    >{{status | fromKebabCaseToString | toCapitalize}}</div>
</template>

<script>
export default {
  name: 'component-status',
  props: {
    status: {
      type: String,
      default: '',
      required: true
    },
    size: {
      type: String
    },
    type: {
      // allowed: default, primary
      type: String
    },
    forceDefault: {
      type: Boolean,
      default: false
    },
    forceSuccess: {
      type: Boolean,
      default: false
    },
    forceInfo: {
      type: Boolean,
      default: false
    },
    forceDanger: {
      type: Boolean,
      default: false
    },
    capitalize: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentstatus";
</style>
