export default {
  header: {
    nav: {
      calendar: 'Kalendarz',
      events: 'Wydarzenia',
      venues: 'Miejsca',
      trainers: 'Trenerzy',
      reports: 'Raporty',
      notifications: 'Powiadomienia',
      achievements: 'Osiągnięcia',
      myTrainings: 'Moje treningi',
      myBookings: 'Moje zapisy'
    },
    topAccountNav: {
      menu: {
        customers: 'Klienci',
        workspaces: 'Obszary robocze',
        events: 'Wydarzenia',
        settings: 'Ustawienia',
        mediaLibrary: 'Biblioteka mediów',
        superadmins: 'Super administratorzy',
        myWorkspaces: 'Moje obszary robocze',
        login: 'Zaloguj się',
        signUp: 'Zapisz się',
        reports: 'Raporty',
        setup: 'Ustawienia',
        admin: 'Administrator',
        users: 'Użytkownicy',
        dashboard: 'Tablica'
      },
      titles: {
        dashboard: 'Strona startowa',
        eventBooking: 'Rezerwacja wydarzeń',
        events: 'wydarzenia',
        settings: 'ustawienia',
        myWorkspaces: 'moje obszary robocze',
        reports: 'raporty',
        setup: 'ustawienia',
        admin: 'administrator',
        users: 'użytkownicy'
      }
    },
    topAccountMenuBox: {
      titles: {
        loggedAs: 'Zalogowany jako:',
        access: 'Dostęp do przestrzeni roboczej',
        noWorkspaceSelected: 'Nie wybrano obszaru roboczego'
      },
      menu: {
        personalInfo: 'Dane osobiste',
        security: 'Bezpieczeństwo',
        history: 'Historia'
      },
      buttons: {
        logout: 'Wyloguj się',
        backToLms: 'LMS',
        switch: 'Przełącz',
        choose: 'Wybierz'
      }
    }
  },
  footer: {
    footerText: '\'Me Learning Ltd, zarejestrowana w Anglii i Walii: Numer firmy: 5842638 \n' +
    ' Siedziba: Basepoint Business Centre, Little High St, Shoreham-by-Sea, West Sussex BN43 5EG\',',
    footerCopyright: 'Me Learning'
  },
  auth: {
    login: {
      buttons: {
        login: 'Logowanie | Zaloguj się',
        forgot: 'Zapomniałeś hasła?',
        signUp: 'Zapisz się',
        logInTo: 'Zaloguj się na inne konto?',
        confirm: 'Potwierdź'
      },
      separator: 'Nie masz konta?',
      labels: {
        email: 'Adres e-mail',
        password: 'Hasło',
        authenticationCode: 'Kod weryfikacyjny',
        newPassword: 'Nowe hasło'
      }
    },
    resetPassword: {
      title: {
        reset: 'Zresetuj hasło',
        info: 'Wpisz adres e-mail powiązany z Twoim kontem, a my wyślemy Ci link do zresetowania hasła.'
      },
      labels: {
        email: 'Adres e-mail',
        verification: 'Kod weryfikacyjny',
        newPassword: 'Nowe hasło'
      },
      buttons: {
        back: 'Powrót',
        send: 'Wyślij kod resetowania',
        next: 'Następny krok',
        confirmPassword: 'Potwierdź hasło'
      }
    },
    show: {
      title: {
        contentAccount: 'Czy masz już konto?',
        contentNewUser: 'Jesteś nowym użytkownikiem?'
      },
      notification: 'Use your existing account to log in or create new one to access the workspace.',
      buttons: {
        LogIn: 'Zaloguj się',
        SignUp: 'Zapisz się'
      },
      notificationContentJs: 'Użyj istniejącego konta, aby się zalogować lub utwórz nowe, aby uzyskać dostęp do obszaru roboczego.'
    },
    twoFactorAuth: {
      title: 'Uwierzytelnianie dwuskładnikowe',
      labels: {
        backupCode: 'Wprowadź kod aplikacji uwierzytelniającej lub kod zapasowy',
        rememberThisDevice: 'Zapamiętaj to urządzenie',
        changeConfiguration: 'Zmień konfigurację',
        authenticationApp: 'Konfiguracja aplikacji uwierzytelniającej',
        verificationCoda: 'Kod weryfikacyjny'
      },
      text: {
        twoFactorAuthIsCurrently: 'Uwierzytelnianie dwuskładnikowe jest obecnie',
        enabled: 'włączone',
        youAreGenerating: 'Generujesz kody weryfikacyjne za pomocą aplikacji uwierzytelniającej.',
        disabled: 'wyłączone',
        ifYouEnableTwoFactorAuth: 'Jeśli włączysz uwierzytelnianie dwuskładnikowe, będziesz musiał podać dodatkowy kod weryfikacyjny, gdy użyjesz hasła do zalogowania się do Me Learning na urządzeniu, którego wcześniej nie używałeś. Me Learning wyśle ​​te kody jako wiadomości e-mail lub możesz skonfigurować aplikację uwierzytelniającą, aby generowała kody dla Ciebie.',
        setUpTwoFactorAuth: 'Musisz skonfigurować uwierzytelnianie dwuskładnikowe, aby wykonać dowolne działanie na koncie.',
        youCant: 'Nie możesz wyłączyć zabezpieczeń uwierzytelniania dwuskładnikowego, dopóki jesteś superadminem.',
        scanThisCode: 'Zeskanuj ten kod za pomocą aplikacji uwierzytelniającej:',
        enterThe6Digit: 'Po dodaniu Me Learning do aplikacji uwierzytelniającej wprowadź poniżej 6-cyfrowy kod, który generuje.'
      },
      buttons: {
        verify: 'Zweryfikuj',
        troubleReceivingCode: 'Masz problem z otrzymaniem kodu?',
        enableTwoFactorAuth: 'Włącz uwierzytelnianie dwuskładnikowe',
        disableTwoFactorAuth: 'Wyłącz uwierzytelnianie dwuskładnikowe',
        cancel: 'Anuluj',
        verifyAndUpdate: 'Zweryfikuj i zaktualizuj',
        changePassword: 'Zmień hasło',
        savePassword: 'Zapisz hasło'
      },
      notify: {
        titleDisable: 'Wyłącz uwierzytelnianie dwuskładnikowe',
        textDoYouWant: 'Czy chcesz wyłączyć uwierzytelnianie dwuskładnikowe?',
        dismiss: 'Odwołaj',
        confirm: 'Potwierdź'
      },
      router: {
        customMessage: 'Musisz skonfigurować uwierzytelnianie dwuskładnikowe.',
        setSuccessfully: 'Uwierzytelnianie dwuskładnikowe zostało ustawione pomyślnie.'
      }
    },
    twoFactorHelp: {
      title: {
        troubleReceivingCode: 'Masz problem z otrzymaniem kodu?',
        howDoesWork: 'Jak działa uwierzytelnianie dwuskładnikowe?',
        authenticationCode: 'Nie możesz uzyskać kodu uwierzytelniającego?',
        stillHavingTrouble: 'Nadal masz problemy?'
      },
      buttons: {
        learnMore: 'Ucz się więcej',
        signWithCode: 'Zarejestruj się przy użyciu kodu zapasowego',
        getFurtherHelp: 'Uzyskaj dalszą pomoc',
        backToLogin: 'Powrót do logowania'
      }
    },
    signUpForm: {
      title: {
        signUp: 'Sign up',
        doYouHave: 'Already have a Me Learning account?',
        willBeAccepted: 'Twoje zaproszenie zostanie zaakceptowane zaraz po rejestracji.'
      },
      buttons: {
        signUp: 'Zapisz się',
        logIn: 'Zaloguj Się'
      },
      labels: {
        email: 'Adres e-mail',
        firstName: 'Imię',
        lastName: 'Nazwisko',
        createPassword: 'Stwórz hasło',
        iWantToReceive: 'Chcę otrzymywać wiadomości marketingowe od Me Learning. Mogę zrezygnować z otrzymywania ich w dowolnym momencie w ustawieniach mojego konta lub poprzez link w wiadomości.'
      }
    },
    invitation: {
      content: {
        someIssues: `Some issues has occurred while performing`,
        ofAnInvitation: `of an invitation. Please try again or contact an administrator.`,
        rejected: 'You have rejected an invitation to workspace.',
        accepted: 'You have accepted an invitation to workspace.'
      }
    }
  },
  dashboard: {
    titles: {
      activeSession: 'Aktywne Sesje',
      completedSessions: 'Ukończone sesje',
      name: 'Nazwa',
      startDate: 'Data rozpoczęcia',
      location: 'Lokalizacja',
      spaces: 'Przestrzeń',
      status: 'Status',
      room: 'Pokój'
    },
    labels: {
      searchSession: 'Wyszukiwanie sesji',
      date: 'Data',
      time: 'Czas',
      trainers: 'Trenerzy',
      location: 'Lokalizacja'
    },
    buttons: {
      viewDetails: 'Pokaż szczegóły',
      addToOutlook: 'Dodaj do programu Outlook',
      showMap: 'Pokaż mapę',
      close: 'Zamknij'
    },
    notifications: {
      completeFeedback: 'Pełna opinia',
      completeAssessment: 'Ocena końcowa',
      completeRegister: 'Pełny rejestr',
      default: 'Widok',
      workspaceSwitched: `Twój obszar roboczy został przełączony.`,
      cantBeSwitchingWorkspace: `Nie można zostać przekierowanym do sesji z powodu problemów z przełączaniem obszaru roboczego.`
    }
  },
  lms: {
    customer: {
      title: {
        contacts: 'Kontakty',
        customers: 'Klienci',
        subscriptions: 'subskrypcje',
        services: 'Usługi'
      },
      labels: {
        details: 'Detale',
        contacts: 'Kontakt',
        services: 'Usługi',
        workspaces: 'Obszary robocze',
        name: 'Nazwa',
        email: 'E-mail',
        phone: 'Telefon',
        address: 'Adres',
        notes: 'Notatki',
        url: 'adres URL',
        emailPhone: 'E-mail/Telefon',
        actions: 'Działania',
        status: 'Status',
        active: 'Aktywny',
        draft: 'Wersja robocza',
        archived: 'Zarchiwizowany',
        currentSubscription: 'bieżąca subskrypcja',
        subscriptionStart: 'rozpoczęcie subskrypcji',
        subscriptionEnd: 'koniec subskrypcji',
        created: 'stworzony',
        cancelled: 'anulowany'
      },
      buttons: {
        edit: 'Edycja',
        remove: 'Usuń',
        save: 'Zapisz',
        cancel: 'Anuluj'
      },
      viwAction: {
        title: `Utwórz nowy kontakt`,
        titleName: 'Edycja: ',
        notifyTitle: 'kontakt',
        notifyTitleEvent: 'klienta',
        cancelButtonText: 'przestać dodawać',
        cancelButtonTextEvent: 'Przestań edytować',
        manageButton: 'Zarządzanie',
        editButton: 'Edycja',
        service: 'serwisem',
        notifyStopManaging: 'przestań zarządzać',
        buttonStopManaging: 'Przestań zarządzać'
      },
      filter: {
        searchPlaceholder: 'Wyszukaj kontakt',
        searchPlaceholderService: 'Wyszukaj usługę',
        searchPlaceholderWorkspace: 'Wyszukaj',
        buttonTextWorkspace: 'Dodaj przestrzeń roboczą',
        buttonText: 'Dodaj kontakt',
        searchCustomer: 'Wyszukaj klienta',
        addCustomer: 'Dodaj klienta',
        addSubscription: 'Dodaj subskrypcję'
      },
      emptyState: {
        contact: 'kontakt',
        contacts: `Kontakty można dodawać podczas tworzenia klienta. Najpierw zapisz dane klienta.`,
        titleCreated: 'Nie utworzono jeszcze kontaktów',
        titleCreatedWorkspaces: 'Nie utworzono jeszcze obszarów roboczych',
        titleNotFound: 'Nie znaleziono kontaktów',
        titleNotFoundServices: 'Nie znaleziono usług',
        workspace: 'obszar roboczy',
        titleNotFoundWorkspaces: 'Nie znaleziono obszarów roboczych',
        titleServicesCanBe: `Usługami można zarządzać po utworzeniu klienta. Najpierw zapisz dane klienta.`,
        contentPlease: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
        contentClick: 'Kliknij poniżej, aby utworzyć nowy.',
        customer: 'klient',
        customerNotFound: 'Nie znaleziono klientów',
        customerNotCreate: 'Nie utworzono jeszcze klientów',
        itemSubscription: 'Subskrypcja',
        titleSubscription: 'Nie utworzono jeszcze subskrypcji'
      },
      notify: {
        titleText: `Zapisz zmiany`,
        titleTextValidation: `Błąd sprawdzania poprawności`,
        contentText: `Opuścisz stronę. Czy chcesz zapisać zmiany dla tego klienta?`,
        contentValidation: `Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.`,
        cancelButton: `Anuluj`,
        customButton: `Nie zapisuj`,
        dismiss: `Odwołaj`,
        submitButton: `Potwierdź`,
        closeButton: `Zamknij`,
        create: 'Stwórz',
        titleTextRemove: `Usuń kontakt`,
        notifyProcess: 'Anuluj dodawanie',
        notifyTitle: 'klienta',
        titleNewCustomer: 'Utwórz nowego klienta',
        contentTextRemove: `Czy chcesz usunąć ten kontakt?`,
        titleCancelSubscription: `Anuluj subskrypcje`,
        contentAreYouSureCancel: `Czy na pewno chcesz anulować tę subskrypcję?`,
        createSubscription: `Utwórz subskrypcję`,
        contentAreYouSureCreate: `Czy na pewno chcesz utworzyć nową subskrypcję?`
      },
      validatorJs: `Daty w subskrypcji nie mogą być sprzeczne z innymi.`
    },
    user: {
      account: {
        sidebar: {
          personalInfo: 'Dane osobiste',
          security: 'Bezpieczeństwo',
          history: 'Historia',
          multiFactionAuthentication: 'Uwierzytelnianie dwuskładnikowe'
        },
        personal: {
          labels: {
            firstName: 'Imię',
            lastName: 'Nazwisko',
            email: 'E-mail',
            loggedAs: 'Zalogowany jako: '
          }
        },
        security: {
          title: {
            text: 'Zmień hasło'
          },
          labels: {
            currentPassword: 'Aktualne hasło',
            newPassword: 'Nowe hasło',
            confirmPassword: 'Potwierdź hasło'
          },
          notify: {
            titleText: 'Błąd sprawdzania poprawności',
            contentText: 'Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.',
            changePassword: 'Zmień hasło',
            contentPassword: 'Czy chcesz zmienić hasło?',
            cancelButtonText: 'Zamknij',
            confirmButtonText: 'Potwierdź'
          }
        },
        invitation: {
          title: 'Zaproszenie',
          button: {
            signIn: 'Zaloguj się'
          }
        },
        history: {
          title: {
            history: 'Historia',
            historyNotFound: 'historia nie znaleziona'
          },
          filter: {
            search: 'Znajdź historię',
            pleaseModifyYourSearchRequest: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
            noHistoryHasBeenRegisteredYet: 'Podana historia nie została jeszcze zarejestrowana.'
          },
          labels: {
            event: 'Zdarzenie',
            sampleDescription: 'Data i godzina',
            notes: 'Notatki'
          },
          invitation: {
            someIssues: `Wystąpiły pewne problemy podczas wykonywania`,
            contentPlease: `zaproszenia. Spróbuj ponownie lub skontaktuj się z administratorem.`,
            rejected: 'Zaproszenie do obszaru roboczego zostało odrzucone.',
            accepted: 'Zaproszenie do obszaru roboczego zostało zaakceptowane.',
            already: 'Zaproszenie do obszaru roboczego zostało już rozwiązane.'
          }
        },
        mfa: {
          title: {
            multiFactionAuthentication: 'Uwierzytelnianie dwuskładnikowe',
            youHaveMfa: 'Musisz skonfigurować uwierzytelnianie dwuskładnikowe, aby wykonać dowolne działanie na koncie.',
            firstStep: '1. Zeskanuj kod QR za pomocą aplikacji Google Authenticator',
            secondStep: '2. Wpisz wygenerowany kod uwierzytelniający',
            enabled: 'Zabezpieczenia 2FA są włączone',
            youCantDisable: 'Nie możesz wyłączyć zabezpieczeń 2FA, dopóki jesteś superadminem.',
            doYouWant: 'Czy chcesz wyłączyć zabezpieczenia 2FA?'
          },
          buttons: {
            submitCode: 'Wyślij kod',
            disableMfa: 'Wyłącz 2FA'
          },
          customMessage: {
            successfullyDisabled: 'Pomyślnie wyłączono zabezpieczenia uwierzytelniania dwuskładnikowego.',
            successfullyForTheUser: 'Pomyślnie zresetowano uwierzytelnianie dwuskładnikowe dla użytkownika.'
          }
        }
      },
      achievements: {
        labels: {
          achievements: 'Osiągnięcia',
          createNewAchievement: 'Dodaj nowe osiągnięcie',
          editAchievement: 'Edycja osiągnięcia',
          details: 'Szczegóły osiągnięcia',
          completion: 'Zasady realizacji',
          name: 'Nazwa',
          actions: 'Działania',
          status: 'Status',
          reward: 'Nagroda',
          description: 'Opis'
        },
        text: {
          achievements: 'Osiągnięcia',
          cancel: 'Anuluj dodawanie',
          addReward: 'Dodaj nagrodę',
          cantFindReward: 'Nie możesz znaleźć nagrody? Możesz to zrobić',
          clickingHere: 'klikając tutaj',
          HereYouCanSetupTheTasks: 'Tutaj możesz skonfigurować zadania, które musisz wykonać, aby otrzymać nagrodę za to osiągnięcie.'
        },
        name: {
          achievements: 'osiągnięcia',
          search: 'Szukaj osiągnięć',
          reward: 'nagroda'
        },
        buttons: {
          add: 'Dodaj nowe',
          cancel: 'Anuluj',
          edit: 'Edytuj',
          preview: 'Zapowiedź',
          close: 'Zamknij',
          saveAchievement: 'Zapisz osiągnięcie',
          next: 'Następne',
          save: 'Zapisz',
          view: 'Widok',
          resetChanges: 'Zresetuj zmiany',
          saveChanges: 'Zapisz zmiany'
        },
        notify: {
          titleText: `Zapisz zmiany`,
          titleTextValidation: `Błąd sprawdzania poprawności`,
          contentText: `Opuścisz stronę. Czy chcesz zapisać zmiany dla tego klienta?`,
          contentValidation: `Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.`,
          cancelButton: 'Anuluj',
          customButton: `Nie zapisuj`,
          dismiss: `Odwołaj`,
          submitButton: `Potwierdź`,
          closeButton: `Zamknij`,
          create: 'Stwórz',
          titleTextRemove: `Usuń kontakt`,
          notifyProcess: 'Anuluj dodawanie',
          notifyTitle: 'klienta',
          titleNewCustomer: 'Utwórz nowego klienta',
          contentTextRemove: `Czy chcesz usunąć ten kontakt?`,
          titleCancelSubscription: `Anuluj subskrypcje`,
          contentAreYouSureCancel: `Czy na pewno chcesz anulować tę subskrypcję?`,
          createSubscription: `Utwórz subskrypcję`,
          contentAreYouSureCreate: `Czy na pewno chcesz utworzyć nową subskrypcję?`,
          successfullyUpdated: 'Osiągnięcia zostały zaktualizowane.',
          wasNotBeenUpdated: 'Osiągnięcia nie zostały zaktualizowane.',
          saveChanges: 'Zapisz zmiany',
          leaveThePage: `Opuszczasz stronę. Czy chcesz zapisać zmiany w tym osiągnięciu?`,
          created: 'Osiągnięcie zostało pomyślnie utworzone.',
          notCreated: 'Osiągnięcie nie zostało utworzone.',
          removed: 'Osiągnięcie zostało pomyślnie usunięte.',
          somethingWentWrong: 'Coś poszło nie tak podczas usuwania osiągnięcia. Spróbuj ponownie lub skontaktuj się z administratorem.'
        }
      },
      reward: {
        labels: {
          rewardName: 'Nazwa nagrody',
          description: 'Opis',
          rewardType: 'Rodzaj nagrody',
          chooseRewards: 'Wybierz nagrody',
          certificate: 'Certyfikat',
          content: 'Zawartość'
        },
        notifications: {
          updated: 'Nagroda została pomyślnie zaktualizowana.',
          notUpdated: 'Nagroda nie została zaktualizowana.',
          created: 'Nagroda została pomyślnie utworzona.',
          notCreated: 'Nagroda nie została utworzona.'
        }
      }
    },
    workspace: {
      title: {
        welcomeBack: 'Witamy spowrotem',
        pleaseChoose: 'Wybierz obszar roboczy, aby uzyskać dostęp',
        workspaces: 'Obszary robocze',
        services: 'Usługi',
        users: 'Użytkownicy'
      },
      search: {
        searchWorkspaces: 'Szukaj',
        searchUsers: 'Szukaj użytkowników',
        noWorkspacesHasBeenFound: 'Nie znaleziono obszarów roboczych',
        pleaseModifyYourSearch: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
        youAreNotAssigned: 'Nie jesteś jeszcze przypisany do żadnego obszaru roboczego.',
        event: 'Wydarzenie',
        addWorkspace: 'Dodaj przestrzeń roboczą'
      },
      buttons: {
        logout: 'Wyloguj',
        access: 'Dostęp',
        inviteUser: 'Zaproś użytkownika',
        resend: 'Wysłać ponownie',
        cancel: 'Anuluj',
        edit: 'Edytuj',
        view: 'Pogląd'
      },
      viewAction: {
        titleNewInvitation: `Create new invitation`,
        titleNewWorkspace: 'Utwórz nowy obszar roboczy',
        notifyTitleWorkspace: 'Obszar roboczy',
        notifyTitleEvent: 'wydarzenia',
        processWorkspace: 'Anuluj dodawanie',
        processStopEditing: 'Zatrzymaj edycję',
        cancelButton: 'Anuluj',
        editing: 'Edycja: ',
        user: 'użytkownik',
        processStopAdding: 'przestań dodawać',
        stopCreatingButton: 'Przestań tworzyć',
        contact: 'kontaktu',
        cancelBackButton: 'Powrót do listy użytkowników'
      },
      labels: {
        customer: 'Klient',
        name: 'Nazwa',
        firstName: 'Imię',
        lastName: 'Nazwisko',
        expiryDate: 'Data wygaśnięcia',
        description: 'Opis',
        status: 'Status',
        active: 'Aktywny',
        draft: 'Wersja robocza',
        archived: 'Zarchiwizowany',
        details: 'Detale',
        users: 'Użytkownicy',
        roles: 'Role',
        email: 'E-mail',
        actions: 'Działania',
        inactive: 'Nieaktywny',
        expired: 'Przedawniony'
      },
      emptyState: {
        user: 'Użytkownik',
        workspace: 'Obszar roboczy',
        workspaceNotFound: 'Nie znaleziono obszarów roboczych',
        workspaceNotCreated: 'Nie utworzono jeszcze obszarów roboczych',
        userNotFound: 'Nie znaleziono użytkowników',
        userNoUser: 'Nie utworzono jeszcze użytkowników',
        userCanBe: `Użytkowników można dodawać podczas tworzenia obszaru roboczego. Najpierw zapisz dane obszaru roboczego.`,
        content: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
        contentClick: 'Kliknij poniżej, aby utworzyć nowy.',
        saveChanges: `Zapisz zmiany`,
        leaveThePage: `Opuścisz stronę. Czy chcesz zapisać zmiany w tym obszarze roboczym?`,
        dontSave: `Nie zapisuj`,
        confirm: `Potwierdź`,
        cancel: `Anuluj`
      },
      notify: {
        titleTextValidation: `Błąd sprawdzania poprawności`,
        contentValidation: `Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.`,
        closeButton: `Zamknij`,
        cancelInvitation: `Anuluj zaproszenie`,
        areYouSureCancelInvitation: `Czy na pewno chcesz anulować to zaproszenie?`,
        confirm: `Potwierdź`,
        dismiss: `Odwołaj`,
        titleResend: `Wyślij zaproszenie ponownie`,
        areYouSureResend: `Czy na pewno chcesz ponownie wysłać to zaproszenie?`
      }
    },
    superadmin: {
      title: {
        superadmins: 'Superadmini',
        superadmin: 'superadmin',
        addSuperadmin: 'Dodaj superadmina'
      },
      labels: {
        firstName: 'Imię',
        lastName: 'Nazwisko',
        email: 'E-mail',
        status: 'Status',
        active: 'Aktywny',
        inactive: 'Nieaktywny',
        expired: 'przedawniony',
        actions: 'Działania',
        name: 'Nazwa'
      },
      viewAction: {
        titleNewInvitation: 'Utwórz nowe zaproszenie',
        notifyTitleSuperadmin: 'Superadmin',
        processCancel: 'Anuluj dodawanie',
        cancelButtonText: 'Powrót do listy superadminów',
        searchSuperadmin: 'Szukaj'
      },
      buttons: {
        resend: 'Wysłać ponownie',
        cancel: 'Anuluj',
        inviteSuperadmin: 'Zaproś superadministratora',
        view: 'Widok',
        edit: 'Edycja',
        resetMFA: 'Resetowanie 2FA'
      },
      emptyState: {
        titleNotFoundSuperAdmin: 'Nie znaleziono superadmina',
        titleNotCreated: 'Nie utworzono jeszcze superadmina',
        contentPlease: 'Zmodyfikuj zapytanie i spróbuj ponownie.'
      },
      popNotify: {
        validationError: `Błąd sprawdzania poprawności`,
        contentTextValid: `Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.`,
        closeButton: `Zamknij`,
        cancelInvitation: `Anuluj zaproszenie`,
        resendInvitation: `Wyślij zaproszenie ponownie`,
        contentTextInvitation: `Czy na pewno chcesz anulować to zaproszenie?`,
        dismissButton: `Odwołać`,
        confirmButton: `Potwierdzenie`,
        removeAdmin: `Usuń administratora`,
        content: `Czy na pewno chcesz usunąć tego administratora?`,
        cancel: `Anuluj`,
        process: 'Anuluj dodawanie',
        resetMFA: 'Resetowanie 2FA',
        textMFA: `Czy na pewno nie chcesz zresetować narzędzia do uwierzytelniania dwuskładnikowego użytkownika?`
      }
    },
    mediaLibrary: {
      titles: {
        mediaLibrary: 'Biblioteka medialna'
      },
      labels: {
        title: 'Tytuł',
        description: 'Opis',
        altText: 'Tekst alternatywny',
        isPrivate: 'Jest prywatne'
      },
      navigator: {
        titleNavigator: 'Usuń multimedia',
        areYouSure: 'Czy na pewno chcesz usunąć ten nośnik?'
      },
      buttons: {
        upload: 'Dodaj plik',
        close: 'Zamknij'
      },
      text: {
        selectWorkspace: 'Musisz wybrać obszar roboczy, aby uzyskać dostęp do biblioteki multimediów.',
        checkThisOption: 'Zaznacz tę opcję, jeśli nie chcesz nikomu udostępniać tego pliku',
        fileName: 'Nazwa pliku',
        fileType: 'Typ pliku',
        uploadDate: 'Data przesłania',
        validationError: 'Błąd sprawdzania poprawności',
        fieldsAreNotValid: 'Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.'
      },
      storageFile: {
        errorDownload: 'Podczas pobierania multimediów wystąpił błąd.',
        removedSuccessfully: 'Plik został pomyślnie usunięty.',
        removingFile: 'Podczas usuwania pliku wystąpiły problemy.',
        updatedTheMedia: 'Pomyślnie zaktualizowałeś media.',
        errorUpdatedTheMedia: 'Wystąpił błąd podczas aktualizacji nośnika.',
        somethingWentWrong: 'Podczas pobierania multimediów coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z administratorem.',
        successfully: 'Pliki zostały przesłane pomyślnie.',
        customMessage: 'Podczas przesyłania plików wystąpiły problemy.'
      },
      componentLibrary: {
        delete: 'Usuń',
        deleteMedia: `Usuń multimedia`,
        areYouSure: `Czy na pewno chcesz usunąć wybrane multimedia?`,
        pleaseModify: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
        noItems: 'Nie znaleziono przedmiotów',
        selectMultimedia: 'Wybierz wiele',
        upload: 'Musisz wybrać obszar roboczy przed przesłaniem jakichkolwiek mediów.'
      }
    }
  },
  ebs: {
    calendar: {
      title: {
        spacesAvailable: 'dostępne miejsca'
      },
      labels: {
        events: 'Wydarzenia',
        iAmBooked: 'Jestem zarezerwowany na',
        iAmTraining: 'trenuję',
        available: 'Dostępne na moich zapisanych kursach',
        venues: 'Miejsca',
        rooms: 'Pokoje',
        trainers: 'Trenerzy'
      },
      buttons: {
        viewDetails: 'Pokaż szczegóły',
        today: 'Dzisiaj',
        day: 'Dzień',
        week: 'Tydzień',
        month: 'Miesiąc',
        list: 'Lista',
        generate: 'Generuj'
      }
    },
    emails: {
      title: {
        search: 'Wyszukaj szablony',
        NotFound: 'Nie znaleziono żadnych e-maili',
        NotCreated: 'Nie utworzono jeszcze żadnych e-maili'
      },
      content: {
        tryAgain: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
        content: 'E-maile są organizatorami sesji i odpowiadają za rejestr i raport podsumowujący.'
      }
    },
    events: {
      add: {
        title: {
          newEvent: 'Utwórz nowe wydarzenie',
          event: 'wydarzenie'
        },
        labels: {
          details: 'Detale',
          sessions: 'Sesje',
          bookings: 'Rezerwacje',
          cancellations: 'Anulowanie',
          feedback: 'Uzupełnienia i informacje zwrotne',
          cost: 'Koszty'
        },
        buttons: {
          cancelAdd: 'anuluj dodawanie',
          cancel: 'Anuluj'
        }
      },
      edit: {
        title: {
          event: 'Wydarzenie',
          editing: 'Edycja: ',
          notifyEditing: 'przestań edytować',
          theBookingLimitText: 'Limit rezerwacji zostanie ustawiony na najmniejszy pokój dla sesji z wieloma pokojami.',
          before: 'przed datą rozpoczęcia sesji',
          ConfigureBookingForm: 'Skonfiguruj formularz rezerwacji',
          learnersMust: 'Uczniowie muszą wypełnić formularz rezerwacji podczas rezerwacji na sesję',
          addNewReason: 'Dodaj nowy powód w każdej linii',
          cancellationReason: 'Powody anulowania',
          automaticCancellations: 'Automatyczne anulowanie',
          completion: 'Ukończenie',
          hintsEvent: 'Wydarzenia są zakończone, gdy uczeń jest obecny (lub ma ważną nieobecność) we wszystkich terminach sesji. Może zostać zastąpiony przez trenera w raporcie podsumowującym.',
          registerAttendance: 'Rejestry to miejsca, w których rejestruje się obecność. Istnieje jeden rejestr dla każdej daty sesji.',
          whoCan: 'Kto może uzupełniać rejestry',
          trainers: 'Trenerzy',
          absenceReason: 'Przyczyny nieobecności',
          askAbsenceReason: 'Zapytaj o przyczynę nieobecności',
          askWhenAbsenceReason: 'Zapytaj uczniów o powód, dla którego są nieobecni na sesji lub na randce',
          feedback: 'Informacje zwrotne',
          trainersWillLeave: 'Trenerzy zostawią tę opinię w raporcie podsumowującym',
          configureFeedback: 'Skonfiguruj formularz opinii',
          associateACost: 'Powiąż koszt ze zdarzeniem',
          changeEventCost: 'Zmień koszt wydarzenia dla różnych sektorów i usług',
          thisEventIsBeing: 'To wydarzenie jest wykorzystywane na tych kursach',
          titleDetails: 'Aktywne wydarzenia są widoczne dla uczniów w bibliotece kursów. Wersja robocza wydarzenia są widoczne dla administratorów i koordynatorów wydarzeń. ' +
          'Zarchiwizowane wydarzenia są ukryte w bibliotece kursów. Wydarzenia można archiwizować tylko wtedy, gdy nie mają aktywnych sesji i nie są używane na żadnym kursie.',
          sessionsAreInstances: 'Sesje są przypadkami wydarzenia i tym, na co uczą się książki. Twórz, edytuj i wyświetlaj sesje na stronie sesji wydarzenia po utworzeniu.',
          theDefaultLocation: 'Domyślna lokalizacja zostanie automatycznie wybrana dla nowo utworzonych sesji. Lokalizacje można zmienić, edytując sesję. Nie możesz znaleźć właściwej lokalizacji?',
          trainersArePeople: 'Instruktorzy to ludzie, którzy ułatwiają wydarzenie i biorą udział.',
          titleDefaultTrainers: 'Domyślni trenerzy zostaną automatycznie wybrani dla nowo utworzonych sesji. Trenerzy można zmienić, edytując sesję. Nie możesz znaleźć odpowiedniego trenera?',
          acceptingBookings: 'Przyjmowanie rezerwacji',
          bookingForm: 'Formularz rezerwacji',
          registers: 'Rejestry',
          sessionReminder: 'Przypomnienie o sesji'
        },
        name: {
          roomCapacity: 'Pojemność pokoju',
          specificValue: 'Określona wartość',
          hours: 'Godziny',
          days: 'Dni',
          beforeStart: 'przed datą rozpoczęcia sesji',
          attendance: 'Frekwencja',
          whenSessionEnds: 'Po zakończeniu sesji (automatycznie)',
          whenAllRegisters: 'Po zakończeniu wszystkich rejestrów',
          trainerAssessment: 'Ocena trenera',
          learnersAreMarked: 'Uczniowie są oznaczeni jako zdał/nie zdał na raporcie oceniającym przez trenera.',
          assignedToThisEvent: 'Przypisany do tego wydarzenia',
          anyEventCoordinator: 'Dowolny koordynator wydarzenia',
          addNewReason: 'Dodaj nowy powód w każdej linii',
          name: 'Nazwa',
          description: 'Opis',
          image: 'Zdjęcie',
          coordinator: 'koordynator',
          active: 'Aktywny',
          draft: 'Wersja robocza',
          sessionReminderNotificationLearner: 'Interwał przypomnienia o sesji - dla uczniów',
          sessionReminderNotificationTrainer: 'Interwał przypomnienia o sesji - dla trenerów'
        },
        notification: {
          maximumBookingReached: 'Osiągnięto minimalną rezerwację',
          bookingCapacity: 'Pojemność rezerwacji jest osiągana podczas sesji',
          trainerFeedback: 'Informacje zwrotne od trenera',
          pleaseComplete: 'Proszę wypełnić poniższy formularz'
        },
        buttons: {
          stopEditing: 'Zatrzymaj edycję',
          bookOnto: `Rezerwuj na sesję`,
          viewVenues: 'Zobacz miejsca.',
          viewTrainers: 'Zobacz trenerów.'
        },
        labels: {
          maximumBookingLimits: 'Maksymalny limit rezerwacji',
          minimumBookingLimits: 'Minimalny limit rezerwacji',
          enableRegisters: 'Włącz rejestry',
          bookingLimits: 'Limity rezerwacji',
          setLimitTo: 'Ustaw limit rezerwacji na',
          accept: 'Przyjmuj rezerwacje do',
          allowBooking: 'Zezwalaj na rezerwacje bez zapisania się na kurs',
          bookingForm: 'Formularz rezerwacji',
          learnersCanCancel: 'Uczniowie mogą anulować swoje rezerwacje',
          AllowBookingsCancel: 'Zezwalaj na anulowanie rezerwacji',
          beforeSession: 'Zezwalaj na anulowanie rezerwacji',
          askForCancellationReason: 'Zapytaj o przyczynę anulowania',
          askLearners: 'Zapytaj uczniów o powód anulowania rezerwacji',
          cancellationReason: 'Powody anulowania',
          addNewReason: 'Dodaj nowy powód w każdej linii',
          automaticallyCancel: 'Automatycznie anuluj sesję, jeśli minimalna liczba rezerwacji nie zostanie osiągnięta',
          absenceReason: 'Przyczyny nieobecności',
          askTrainersToLeave: 'Poproś trenerów o pozostawienie opinii po zakończeniu sesji',
          eventCost: 'Koszt wydarzenia',
          eventHasCost: 'Wydarzenie ma swój koszt',
          costOverrides: 'Zastępuje koszty',
          name: 'Nazwa',
          eventImage: 'Obraz zdarzenia',
          eventCoordinator: 'Koordynator wydarzenia',
          defaultLocation: 'Domyślna lokalizacja',
          thisEventHasTrainers: 'To wydarzenie ma trenerów',
          defaultTrainers: 'Domyślni trenerzy',
          sidebarDetails: 'Detale',
          sidebarSessions: 'Sesje',
          sidebarBookings: 'Rezerwacje',
          sidebarCancellations: 'Anulowanie',
          sidebarCompletionsAndFeedback: 'Uzupełnienia i informacje zwrotne',
          sidebarCost: 'Koszt',
          sessionCoordinators: 'Koordynatorzy sesji',
          registers: 'Rejestry',
          clickToSelect: 'Kliknij aby wybrać',
          calculateEventCompletionOn: 'Oblicz zakończenie zdarzenia w dniu',
          eventCoordinators: 'Koordynatorzy wydarzeń',
          status: 'Status',
          sessionReminderCheck: 'Wyślij przypomnienie sesji do uczniów i trenerów',
          sessionReminderAfter: 'przed datą rozpoczęcia sesji'
        },
        notify: {
          titleError: `Błąd sprawdzania poprawności`,
          textNotValid: `Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.`,
          buttonClose: `Zamknij`,
          titleChanges: `Zmiany nie wpłyną na sesje`,
          textKeepInMind: `Przynajmniej jedno pole w wydarzeniu zostanie zaktualizowane. Pamiętaj, że te zmiany nie wpłyną na już istniejące sesje.`,
          buttonConfirm: `Potwierdzać`,
          titleDraftSession: `Sesja robocza`,
          textDraftSession: `Co najmniej jedna sesja w wydarzeniu nie jest aktywowana.`,
          titleDraftEvent: `Sesja robocza`,
          textDraftEvent: `Wydarzenia z statusami roboczymi nie są widoczne dla uczniów.`,
          titleSession: `Zmiany nie wpłyną na sesje`,
          textSession: '`Przynajmniej jedno pole w wydarzeniu zostanie zaktualizowane. Pamiętaj, że te zmiany nie wpłyną na już istniejące sesje.`'
        }
      },
      list: {
        title: {
          events: 'Wydarzenia',
          selectEvent: 'Wybierz wydarzenie'
        },
        buttons: {
          buttonCreateEvent: 'Stworzyć wydarzenie',
          buttonCancel: 'Anuluj',
          addSelectEvent: 'Dodaj wybrane wydarzenie',
          selected: 'Wybrany',
          select: 'Wybierz',
          buttonView: 'Widok'
        },
        labels: {
          searchEvents: 'Szukaj wydarzeń',
          name: 'Nazwa',
          sessions: 'Sesje',
          nextSession: 'Następna sesja',
          status: 'Status',
          courses: 'Kursy',
          actions: 'Działania',
          venues: 'Miejsca',
          rooms: 'Pokoje',
          trainers: 'Trenerzy'
        },
        emptyState: {
          titleEventsNoFound: 'Nie znaleziono żadnych wydarzeń',
          contentEventModify: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
          titleEventsNotCreate: 'Nie utworzono jeszcze żadnych wydarzeń',
          contentSessionWhichLearners: 'Wydarzenia zawierają sesje, w których uczniowie mogą zarezerwować i wziąć udział w danym miejscu lub czasie.'
        }
      },
      show: {
        title: {
          event: 'Wydarzenie',
          events: 'Wydarzenia'
        },
        buttons: {
          buttonPrint: 'Drukuj ulotkę',
          buttonEdit: 'Edytuj wydarzenie'
        }
      }
    },
    reports: {
      title: {
        reports: 'Raporty',
        events: 'Wydarzenia',
        sessions: 'Sesje',
        displayOrDownloadCCSV: 'Wyświetl lub pobierz CSV',
        report: 'raport.',
        reportView: 'raport'
      },
      buttons: {
        details: 'Detale',
        generate: 'Generuj',
        exportCSV: 'Eksportuj CSV'
      },
      note: {
        noDataHasBeenLoaded: 'Żadne dane nie zostały jeszcze załadowane. Ustaw filtry i kliknij przycisk „Generuj”, aby utworzyć raport.',
        selectedFilter: 'Brak danych wczytanych dla wybranych filtrów. Zmodyfikuj je i ponownie wygeneruj raport.'
      }
    },
    rooms: {
      add: {
        title: {
          create: 'Utwórz nowy pokój',
          notifyTitle: 'Pokój',
          notifyProcess: 'Anuluj dodawanie'
        },
        buttonCancel: 'Anuluj'
      },
      edit: {
        title: {
          editing: `Edycja: `,
          notifyTitle: 'pokój',
          notifyProcess: 'stop editing',
          venues: 'Miejsca',
          visibility: 'Widoczność'
        },
        text: {
          errorText: 'Podaj maksymalną pojemność miejsca.',
          instructions: 'Instrukcje opisujące, jak dostać się do pokoju po przybyciu na miejsce.',
          activeRooms: 'Pokoje aktywne i ich pokoje mogą być wykorzystywane podczas wydarzeń. Pokoje robocze są widoczne dla administratorów i koordynatorów wydarzeń i nie można ich używać w wydarzeniach.' +
          'Pokoje można przywrócić do wersji roboczej tylko wtedy, gdy nie są używane do żadnych aktywnych sesji.'
        },
        labels: {
          name: 'Nazwa',
          description: 'Opis',
          images: 'Zdjęcia',
          location: 'Lokacja',
          capacity: 'Pojemność',
          cost: 'Koszt',
          status: 'Status'
        },
        name: {
          name: 'nazwa',
          description: 'opis',
          images: 'zdjęcia',
          location: 'lokacja',
          capacity: 'pojemność',
          cost: 'koszt',
          status: 'status'
        },
        items: {
          perHour: 'Na godzinę',
          perDay: 'Na dzień',
          perWeek: 'Na tydzień',
          active: 'Aktywny',
          draft: 'Wersja robocza'
        },
        notify: {
          title: `Błąd sprawdzania poprawności`,
          content: `Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.`,
          close: `Zamknij`
        },
        buttons: {
          stop: 'Zatrzymaj edycję',
          go: 'Wróć'
        }
      },
      list: {
        title: {
          rooms: 'Pokoje'
        },
        text: {
          search: 'Szukaj pokoi',
          capacity: 'Pojemność: ',
          people: 'ludzie'
        },
        buttons: {
          createRooms: 'Stwórz pokój',
          view: 'Widok'
        },
        emptyState: {
          room: 'Pokój',
          titleFound: 'Nie znaleziono pokoi',
          modify: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
          titleCreated: 'Nie utworzono jeszcze pokoi',
          takePlace: 'Pokoje znajdują się wewnątrz miejsca i są miejscem, w którym odbywają się sesje.'
        },
        labels: {
          name: 'Nazwa',
          capacity: 'Pojemność',
          status: 'status',
          actions: 'działania'
        }
      },
      show: {
        title: {
          venues: 'Miejsca',
          process: 'Pokój'
        },
        buttons: {
          edit: 'Edytuj miejsce',
          view: 'Ustawienia widoku'
        },
        contactRoom: 'Kontakt'
      }
    },
    sessions: {
      add: {
        title: {
          createSession: 'Utwórz nową sesję',
          session: 'Sesja',
          process: 'anuluj dodawanie'
        },
        name: {
          events: 'Wydarzenia'
        },
        labels: {
          details: 'Detale',
          booking: 'Rezerwacje',
          cancellations: 'Anulowanie',
          feedback: 'Uzupełnienia i informacje zwrotne',
          cost: 'Koszt'
        },
        buttonCancel: 'Anuluj'
      },
      edit: {
        title: {
          session: 'sesja',
          processStop: 'przestań edytować',
          editing: `Edycja: `,
          bookingLimits: 'Limity rezerwacji',
          acceptBookings: 'Przyjmowanie rezerwacji',
          configureBookingForm: 'Skonfiguruj formularz rezerwacji',
          completion: 'Ukończenie',
          whoCan: 'Kto może uzupełniać rejestry',
          feedback: 'Informacje zwrotne',
          configureFeedback: 'Skonfiguruj formularz opinii',
          dateAndTime: 'Data i godzina',
          visibility: 'Widoczność',
          automaticCancellations: 'Automatyczne anulowanie',
          bookingForm: 'Formularz rezerwacji',
          absenceReasons: 'Przyczyny nieobecności',
          registers: 'Rejestry'
        },
        name: {
          events: 'Wydarzenia',
          roomCapacity: 'Pojemność pokoju',
          specificValue: 'Określona wartość',
          hours: 'godziny',
          days: 'Dni',
          maximumBookings: 'Osiągnięto maksymalną liczbę rezerwacji',
          attendance: 'Frekwencja',
          whenSessionEnds: 'Po zakończeniu sesji (automatycznie)',
          whenAllRegisters: 'Po zakończeniu wszystkich rejestrów',
          trainerAssessment: 'Trainer assessment',
          assignedTo: 'Przypisany do tej sesji',
          anySession: 'Dowolny koordynator sesji',
          nameActive: 'Aktywny',
          nameDraft: 'Wersja robocza'
        },
        labels: {
          minimumBookingLimits: 'Minimalny limit rezerwacji',
          enableRegisters: 'Włącz rejestry',
          setBookingLimitTo: 'Ustaw limit rezerwacji na',
          maximumLimit: 'Maksymalny limit rezerwacji',
          acceptBookings: 'Przyjmuj rezerwacje do',
          afterStart: 'przed datą rozpoczęcia sesji',
          allowBookings: 'Zezwalaj na rezerwacje bez zapisania się na kurs',
          learnerIsBooked: 'Uczeń jest rezerwowany na sesję',
          notificationTo: 'Powiadomienie koordynatora wydarzenia',
          bookingForm: 'Formularz rezerwacji',
          learnersCanCancel: 'Uczniowie mogą anulować swoje rezerwacje',
          allowBooking: 'Zezwalaj na anulowanie rezerwacji',
          beforeStart: 'przed datą rozpoczęcia sesji',
          askReason: 'Zapytaj o przyczynę anulowania',
          cancellationReason: 'Powody anulowania',
          allowOther: `Zezwalaj na „inne” powody`,
          automaticallyCancel: 'Automatycznie anuluj sesję, jeśli minimalna liczba rezerwacji nie zostanie osiągnięta',
          calculate: 'Oblicz zakończenie sesji w dniu',
          trainers: 'Trenerzy',
          askAbsenceReason: 'Zapytaj o przyczynę nieobecności',
          absenceReason: 'Przyczyny nieobecności',
          askTrainersToLeave: 'Poproś trenerów o pozostawienie opinii po zakończeniu sesji',
          sessionHasACost: 'Sesja ma swój koszt',
          sessionCost: 'Koszt sesji',
          costOverrides: 'Zastępuje koszty',
          labelFrom: 'Od',
          labelTo: 'Do',
          labelLocation: 'Lokalizacja',
          labelStatus: 'Status',
          details: 'Detale',
          booking: 'Rezerwować',
          cancellations: 'Anulowanie',
          feedback: 'Uzupełnienia i informacje zwrotne',
          cost: 'Koszt',
          sessionCoordinators: 'Koordynatorzy sesji'
        },
        buttons: {
          stop: 'Stop editing'
        },
        text: {
          bookingLimit: 'Limit rezerwacji zostanie ustawiony na najmniejszy pokój dla sesji z wieloma pokojami.',
          learnersMustComplete: 'Uczniowie muszą wypełnić formularz rezerwacji podczas rezerwacji na sesję',
          askLearners: 'Zapytaj uczniów o powód anulowania rezerwacji',
          addANewReason: 'Dodaj nowy powód w każdej linii',
          beforeStart: 'przed datą rozpoczęcia sesji',
          sessionAreCompleted: 'Sesje są zakończone, gdy uczeń jest obecny (lub ma ważną nieobecność) we wszystkich terminach sesji. Może zostać zastąpiony przez trenera w raporcie podsumowującym.',
          learnersAreMarked: 'Uczniowie są oznaczeni jako zdał/nie zdał na raporcie oceniającym przez trenera.',
          registersAreWhere: 'Rejestry to miejsca, w których rejestruje się obecność. Istnieje jeden rejestr dla każdej daty sesji.',
          askLearnersAbsent: 'Zapytaj uczniów o powód, dla którego są nieobecni na sesji lub na randce',
          trainersWillLeave: 'Trenerzy zostawią tę opinię w raporcie podsumowującym',
          associateACost: 'Skojarz koszt z sesją',
          changeSession: 'Zmień koszt sesji dla różnych sektorów i usług',
          addAnotherDate: 'Dodaj kolejną datę',
          sameLocation: 'Ta sama lokalizacja na datę',
          sameTrainers: 'Tych samych trenerów na datę',
          differentLocation: 'Inna lokalizacja na datę',
          differentTrainers: 'Różni trenerzy na datę',
          thisDateTime: 'Ta data / godzina jest poza',
          trainer: 'trener',
          trainers: 'trampki',
          standardWorkingHours: 'standardowe godziny pracy.',
          activeSession: 'Aktywne sesje są widoczne dla uczniów w bibliotece kursów.' +
          'Szkice robocze są widoczne dla administratorów i koordynatorów wydarzeń.' +
          'Sesje można sporządzać tylko wtedy, gdy nie mają żadnych rezerwacji.'
        },
        notify: {
          errorText: `Błąd sprawdzania poprawności`,
          contentText: `Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.`,
          closeNotify: `Zamknij`,
          validateCustomError: `Pole daty „od” musi być wcześniejsze lub równe polu daty.`,
          validateCustomErrorTo: `Pole daty „do” musi być późniejsze lub równe polu daty od.`,
          beforeNotify: `przed:`,
          afterNotify: `po:`
        },
        formNotification: {
          name: 'Osiągnięto maksymalną liczbę rezerwacji',
          label: 'Pojemność rezerwacyjna jest osiągana podczas sesji'
        }
      },
      list: {
        title: {
          sessionsTitle: 'Sesje',
          expressInterest: 'Wyraź zainteresowanie terminami sesji błędów',
          youWillBeNotified: 'Zostaniesz powiadomiony o utworzeniu sesji w przyszłości.',
          eventSubscriptions: 'Subskrypcje wydarzeń'
        },
        name: {
          expressedInterest: 'Wyrażone zainteresowanie'
        },
        labels: {
          name: 'nazwa',
          email: 'E-mail',
          username: 'Nazwa Użytkownika',
          date: 'data',
          location: 'lokalizacje',
          trainers: 'Trenerzy',
          spacesAvailable: 'dostępne miejsca',
          status: 'status',
          actions: 'działania'
        },
        text: {
          searchSessions: 'Szukaj sesji',
          listOfPeople: 'Lista osób, które wyraziły zainteresowanie wydarzeniem'
        },
        buttons: {
          unsubscribe: 'Wypisz się',
          noSuitable: 'Brak odpowiednich sesji?',
          cancel: 'Anuluj',
          expressInterest: 'Wyraź zainteresowanie',
          createSession: 'Utwórz sesję',
          close: 'Zamknij',
          remove: 'Usuń',
          confirm: 'Potwierdź',
          view: 'Widok'
        },
        emptyState: {
          noSessionsHasBeenFound: 'Nie znaleziono sesji',
          pleaseModifyYourSearch: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
          noSessionsHasBeenCreatedYet: 'Nie utworzono jeszcze żadnych sesji',
          sessionsAreOccurrences: 'Sesje są zdarzeniami wydarzenia - i na co uczą się książki.'
        },
        notify: {
          removeSubscriptions: 'Usuń subskrypcje',
          selectedSubscriptions: 'Wybrane subskrypcje zostaną usunięte z wydarzenia.'
        },
        return: {
          noSpacesAvailable: 'Brak dostępnych miejsc',
          spaces: ` przestrzenie`,
          space: ` przestrzeń`,
          available: ` dostępny`
        }
      },
      register: {
        title: {
          date: 'Data',
          register: `Zarejestruj się`,
          valid: 'Ważny',
          invalid: 'Nieważny',
          preview: 'Podgląd formularza rezerwacji'
        },
        name: {
          events: 'Wydarzenia',
          present: 'Obecny',
          absent: 'Nieobecny',
          chooseReason: 'Wybierz powód',
          valid: 'Ważny',
          invalid: 'Nieważny'
        },
        labels: {
          present: 'Obecny',
          absent: 'Nieobecny',
          name: 'Nazwa',
          email: 'e-mail',
          attendance: 'frekwencja',
          bookingForm: 'Formularz rezerwacji',
          notes: 'Notatki'
        },
        buttons: {
          resetChanges: 'Zresetuj zmiany',
          saveChanges: 'Zapisz zmiany',
          commit: 'Zatwierdź',
          view: 'Podgląd'
        },
        notify: {
          validationError: `Błąd sprawdzania poprawności`,
          validationText: `Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.`,
          close: 'Zamknij',
          completeRegister: `Pełny rejestr`,
          complitingText: `Wypełnienie raportu rejestru ustawi status ukończenia dla wszystkich uczniów w tej sesji i nie można go cofnąć. Czy chcesz kontynuować?`,
          cancel: `Anuluj`,
          complete: `Pełny raport z rejestru`
        },
        return: {
          completeTheRegister: 'Uzupełnij rejestr, zaznaczając obecność wszystkich wymienionych osób.',
          register: 'zarejestrować',
          printing: 'druk',
          success: 'sukces'
        }
      },
      show: {
        title: {
          cancelSession: 'Anuluj sesję',
          summary: 'Streszczenie',
          of: 'z',
          unableBecause: 'Nie można zarezerwować, ponieważ:',
          please: 'Proszę:',
          cancellationReason: 'Powód anulowania',
          feedbackForm: 'Formularz zwrotny',
          trainerFeedback: 'Informacje zwrotne od trenera',
          actionNotAllowed: 'Działanie niedozwolone',
          thereAreNotEnough: 'Brak wystarczającej ilości miejsca',
          bookingForm: 'Formularz rezerwacji',
          removeFromWaitlist: 'Usuń z listy oczekujących',
          invitePerson: 'Zaproś osobę',
          bookPerson: 'Zarezerwuj osobę',
          cancelInvite: 'Anuluj zaproszenie',
          resendInvite: 'Wyślij zaproszenie ponownie',
          declineBooking: 'Odrzuć prośbę o rezerwację',
          approveBooking: 'Zatwierdź prośbę o rezerwację',
          cancelBooking: 'Anulować rezerwację',
          cancellation: 'Anulowanie',
          previewBookingForm: 'Podgląd formularza rezerwacji',
          otherEvent: `Inne sesje wydarzeń`
        },
        name: {
          events: 'Wydarzenia',
          session: 'sesja'
        },
        text: {
          cancelSession: 'Anuluj sesję',
          cancelSessionAnd: 'Anuluj sesje i kopiuj rezerwacje',
          editSession: 'Edytuj sesję',
          pleaseCompleteTheForm: 'Wypełnij poniższy formularz, aby zarezerwować na tę sesję.',
          pleaseConfirmYourBooking: `Potwierdź swoją rezerwację, klikając przycisk „Rezerwuj na sesję” poniżej.`,
          areYouSure: 'Czy na pewno chcesz anulować rezerwację?',
          coordinatorWillReceive: 'Koordynator wydarzenia otrzyma powiadomienie o anulowaniu.',
          attendance: 'Frekwencja',
          reason: 'Powód',
          searchPeople: 'Szukaj ludzi',
          bookOnTo: 'Rezerwuj na sesję',
          alertMustBe: 'Aby móc zarezerwować, uczniowie muszą zapisać się na kursy zawierające to wydarzenie.',
          bookPeople: 'Zarezerwuj ludzi',
          selectPeople: 'Wybierz osoby do rezerwacji na tę sesję. Wybrane osoby otrzymają powiadomienie o potwierdzeniu rezerwacji.',
          davidBowie: 'David Bowie wypełnił ten formularz rezerwacji 1 stycznia 2019 r .:',
          areYouSureRemove: 'Czy na pewno chcesz usunąć tę osobę z listy oczekujących?' + '\n\n' + 'Otrzymają powiadomienie z informacją o ich usunięciu.',
          areYouSureInvite: 'Czy na pewno chcesz zaprosić tę osobę na sesję?' + '\n\n' + 'Powiadomienie zostanie wysłane do osoby powiadamiającej ją o zaproszeniu na sesję.',
          areYouSureBook: 'Czy na pewno chcesz zarezerwować tę osobę na sesję?' + '\n\n' + 'Powiadomienie zostanie wysłane do osoby powiadamiającej ją o rezerwacji.',
          areYouWontCancel: 'Czy na pewno chcesz anulować zaproszenie tej osoby?',
          areYouSureResend: 'Czy na pewno chcesz ponownie wysłać zaproszenie tej osoby?' + '\n\n' + 'Powiadomienie zostanie wysłane do osoby powiadamiającej ją o zaproszeniu na sesję.',
          areYouSureDecline: 'Czy na pewno chcesz odrzucić tę prośbę o rezerwację?' + '\n\n' + 'Powiadomienie zostanie wysłane do osoby powiadamiającej ją o odrzuconej prośbie o rezerwację.',
          areYouSureApprove: 'Czy na pewno chcesz zatwierdzić tę prośbę o rezerwację?' + '\n\n' + 'Powiadomienie zostanie wysłane do osoby powiadamiającej o ich udanej rezerwacji.',
          areYouSureCancel: 'Are you sure you want to cancel this person’s booking?' + '\n\n' + 'Powiadomienie zostanie wysłane do osoby powiadamiającej ją o anulowaniu rezerwacji.'
        },
        labels: {
          dates: 'daty',
          rooms: 'pokoje',
          trainers: 'trenerzy,',
          availableSpaces: 'dostępne miejsca',
          id: 'id',
          name: 'nazwa',
          email: 'e-mail',
          username: 'nazwa Użytkownika',
          status: 'status',
          actions: 'działania'
        },
        buttons: {
          printFlyer: 'Drukuj ulotkę',
          dismiss: 'Odwołaj',
          print: 'Drukuj',
          view: 'Widok',
          bookOnto: 'Rezerwuj na sesję',
          cancelBooking: 'Anulować rezerwację',
          cancel: 'Anuluj',
          goBack: 'Wróć',
          moreDetails: 'Więcej szczegółów',
          apply: 'Zastosować',
          bookPeople: 'Zarezerwuj ludzi',
          close: 'Zamknąć',
          remove: 'Usunąć',
          continue: 'Kontyntynuj',
          cancelInvite: 'Anuluj Zaproszenie',
          declineRequest: 'Odrzuć prośbę',
          approveRequest: 'Zatwierdź prośbę',
          viewBookingForm: 'Zobacz formularz rezerwacji',
          invite: 'Zaproś',
          book: 'Rezerwacja',
          resend: 'Wyślij ponownie',
          decline: 'Zrezygnuj',
          approve: 'Zatwierdź',
          viewCancellation: 'Zobacz anulowanie',
          viewAll: 'Zobacz wszystko',
          leaveWaitlist: 'Pozostaw listę oczekujących',
          joinWaitlist: 'Dołącz do listy oczekujących',
          save: 'Zapisz'
        },
        notify: {
          session: 'sesja',
          sessionCancel: 'Anuluj',
          unableToArchiveSession: 'Nie można zarchiwizować sesji',
          unableToArchiveSessionText: 'Nie można zarchiwizować sesji, które akceptują rezerwacje lub mają rezerwacje. Anuluj sesję przed próbą usunięcia.',
          close: 'Zamknij',
          bookPerson: 'Zarezerwuj osobę na sesję',
          pleaseComplete: 'Wypełnij poniższy formularz, aby zarezerwować użytkownika na tę sesję.',
          cancel: 'Anuluj',
          bookOnto: 'Rezerwuj na sesję',
          bookPeople: 'Zarezerwuj ludzi na sesję',
          textPeople: 'To wydarzenie ma włączony formularz rezerwacji, rezerwacja zbiorcza nie wypełni tych formularzy rezerwacji.',
          bulkBooking: 'Rezerwacja zbiorcza',
          textBooking: 'Czy na pewno chcesz zbiorczo zarezerwować w sesji wszystkie wybrane osoby?',
          confirm: 'Potwierdź',
          pleaseCompleteThe: 'Proszę wypełnić poniższy formularz',
          youHaveBooked: 'Zarezerwowałeś tę sesję',
          somethingWentWrong: 'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z administratorem.'
        },
        table: {
          transferBooking: 'Przenieś rezerwacje',
          selectSession: 'Wybierz sesję, aby przenieść rezerwacje.',
          close: 'Zamknij',
          confirm: 'Potwierdź'
        },
        description: {
          permanentlyCancelLong: `Czy chcesz trwale anulować tę sesję? Jest na nim kilka aktywnych rezerwacji. Możesz po prostu anulować tę sesję lub anulować ją i skopiować wszystkie aktywne rezerwacje do innej sesji`,
          permanentlyCancelMedium: `Czy chcesz trwale anulować tę sesję? Jest na nim kilka aktywnych rezerwacji, ale nie ma innej aktywnej sesji, w której można by je przenieść`,
          permanentlyCancelShort: `Czy chcesz trwale anulować tę sesję?`,
          register: `Zarejestrować`,
          summaryReport: 'Raport z oceny',
          bookingCancelled: 'rezerwacja została anulowana w dniu',
          becauseReason: 'z następującego powodu:',
          acceptingCancellationsUntil: 'Przyjmowanie rezygnacji do',
          acceptingBookingsUntil: 'Przyjmowanie rezerwacji do'
        }
      },
      summary: {
        title: {
          trainerAssessment: `Ocena trenera`,
          assessmentReport: 'Sprawozdanie z oceny',
          completeText: 'Uzupełnij raport z oceny, zaznaczając osoby na liście.'
        },
        text: {
          date: 'Data',
          absent: 'Nieobecny (ważny powód) Żałoba'
        },
        labels: {
          passed: 'Przeszedł',
          notPassed: 'Nie zaliczone',
          name: 'nazwa',
          email: 'e-mail',
          attendance: 'frekwencja',
          assessment: 'oszacowanie',
          notes: 'notatki'
        },
        buttons: {
          resetChanges: 'Zresetuj zmiany',
          saveChanges: 'Zapisz zmiany',
          commit: 'Zatwierdź',
          close: 'Zamknij',
          details: 'Detale'
        },
        notify: {
          completeSummary: `Pełne podsumowanie`,
          completeSummaryText: `Wypełnienie raportu podsumowującego ustawi status ukończenia dla wszystkich uczniów w tej sesji i nie można go cofnąć. Czy chcesz kontynuować?`,
          cancel: `Anuluj`,
          completeSummaryReport: `Pełny raport podsumowujący`,
          validError: `Błąd sprawdzania poprawności`,
          validText: `Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.`,
          close: `Zamknij`
        },
        return: {
          presentFor: 'Prezent dla',
          dates: 'daty',
          date: 'data'
        }
      }
    },
    trainers: {
      add: {
        alert: 'Wszystkie konta uczniów są obecnie w użyciu, utwórz nowe konto uczniów, aby utworzyć trenera.',
        title: {
          newTrainer: 'Utwórz nowego trenera',
          trainer: 'trener',
          cancelAdding: 'anuluj dodawanie'
        },
        buttonCancel: 'Anuluj',
        nameTrainer: 'Trener'
      },
      edit: {
        title: {
          editing: 'Edycja: ',
          notifyTitle: 'trainertrener',
          stopProcess: 'przestań edytować'
        },
        buttons: {
          stop: 'Przestań edytować'
        },
        labels: {
          learnerAccount: 'Konto ucznia',
          typeToSearch: 'Wpisz, aby wyszukać adres e-mail osoby uczącej się, nazwę lub nazwę użytkownika',
          name: 'Nazwa',
          email: 'E-mail',
          images: 'Zdjęcia'
        },
        text: {
          chooseLearner: 'Wybierz konto ucznia, aby stworzyć podstawę nowego trenera. Jeśli konto ucznia już nie' +
          'istnieje dla nowego trenera, utwórz nowe konto ucznia.',
          standardWorkingHours: 'Standardowe godziny pracy'
        },
        name: {
          name: 'nazwa',
          user: 'użytkownik',
          email: 'e-mail',
          images: 'zdjęcia'
        },
        notify: {
          validError: `Błąd sprawdzania poprawności`,
          validText: `Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.`,
          validContent: `Zamknij`
        }
      },
      list: {
        title: {
          trainers: 'Trenerzy'
        },
        labels: {
          name: 'nazwa',
          email: 'e-mail',
          actions: 'działania'
        },
        buttons: {
          createTrainer: 'Utwórz trenera',
          view: 'Widok'
        },
        text: {
          searchTrainer: 'Szukaj trenerów'
        },
        emptyState: {
          foundTitle: 'Nie znaleziono trenerów',
          foundContent: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
          createTitle: 'Nie utworzono jeszcze trenerów',
          createContent: 'Trenerzy są organizatorami sesji i odpowiadają za rejestr i raport podsumowujący.'
        }
      },
      show: {
        notifyTitle: 'trener',
        buttonEdit: 'Edytuj trener',
        nameTrainer: 'Trenerzy',
        titleHours: 'Standardowe godziny pracy'
      }
    },
    venues: {
      add: {
        title: {
          newVenue: 'Utwórz nowe miejsce',
          notifyVenue: 'miejsce wydarzenia',
          notifyCancel: 'Anuluj',
          cancelAdding: 'anuluj dodawanie'
        },
        buttons: {
          cancel: 'Anuluj'
        },
        name: {
          venues: 'Miejsce wydarzenia'
        }
      },
      edit: {
        title: {
          editing: 'Edycja: ',
          notifyVenue: 'miejsce wydarzenia',
          stopEditing: 'przestań edytować',
          contactInformation: 'Informacje kontaktowe',
          visibility: 'Widoczność',
          sharingTitle: 'Udostępnianie w różnych obszarach roboczych'
        },
        buttons: {
          stopEditing: 'Przestań edytować',
          goBack: 'Wróć'
        },
        labels: {
          name: 'Nazwa',
          description: 'Opis',
          images: 'Zdjęcia',
          email: 'E-mail',
          phone: 'Telefon',
          status: 'Status'
        },
        name: {
          venues: 'miejsca',
          name: 'nazwa',
          description: 'opis',
          images: 'zdjęcia',
          email: 'e-mail',
          phone: 'telefon',
          status: 'status',
          active: 'Aktywny',
          draft: 'Wersja robocza',
          visible: 'Widoczny',
          hidden: 'Ukryty'
        },
        textActiveVenues: 'Aktywne miejsca i ich pokoje mogą być wykorzystywane podczas wydarzeń.' +
        'Wersje robocze są widoczne dla administratorów i koordynatorów wydarzeń i nie można ich używać w wydarzeniach.' +
        'Miejsca mogą zostać przywrócone do wersji roboczej tylko wtedy, gdy nie są wykorzystywane do żadnych aktywnych sesji.',
        notify: {
          validError: `Błąd sprawdzania poprawności`,
          validContent: `Niektóre pola w formularzu są nieprawidłowe. Sprawdź je i spróbuj jeszcze raz.`,
          close: `Zamknąć`
        }
      },
      list: {
        title: {
          venues: 'Miejsca'
        },
        text: {
          searchUsers: 'Szukaj miejsc'
        },
        labels: {
          name: 'nazwa',
          rooms: 'pokoje',
          status: 'status',
          actions: 'działania'
        },
        buttons: {
          createVenues: 'Utwórz miejsce',
          view: 'Widok'
        },
        emptyState: {
          foundTitle: 'Nie znaleziono miejsc',
          foundContent: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
          createTitle: 'Nie zostały jeszcze utworzone żadne obiekty',
          createContent: 'Miejsca zawierają pokoje, w których odbywają się sesje.'
        },
        return: {
          room: 'pokój',
          rooms: 'pokoje'
        }
      },
      show: {
        notifyTitle: 'miejsce',
        buttonEdit: 'Edytuj miejsce',
        buttonView: 'Ustawienia widoku',
        nameVenue: 'Miejsce',
        contact: 'Kontakt'
      }
    }
  },
  forms: {
    ruleForm: {
      title: {
        rule: 'Reguła',
        childRule: 'Reguła dziecka',
        grandChildRule: 'Reguła wnuka',
        add: 'Dodaj'
      },
      labels: {
        and: 'I',
        or: 'Lub',
        subset: 'Podzbiór',
        completedWhat: 'Uzupełnij co?',
        nameOf: 'Nazwa',
        item: 'pozycja',
        achievement: 'Osiągnięcie',
        moduleRule: 'Moduł',
        course: 'Kierunek',
        event: 'Zdarzenie',
        workbook: 'Zeszyt ćwiczeń'
      },
      text: {
        completeAchievementIf: 'Ukończ osiągnięcie jeśli',
        completeRuleIf: 'Kompletna reguła, jeśli',
        rulesCompleted: 'tych zasad został zakończony',
        conditionsCompleted: 'tych warunków został spełniony',
        maximumNumber: 'Osiągnięto maksymalną liczbę zagnieżdżeń.'
      },
      notify: {
        titleText: `Usuń regułę`,
        contentText: `Czy chcesz usunąć tę regułę?`,
        cancelButtonText: 'Anuluj',
        submitButtonText: 'Potwierdź'
      }
    },
    dataVvAs: {
      name: '"nazwa"',
      hour: '"godzina"',
      firstName: '"imię"',
      lastName: '"nazwisko"',
      location: '"lokalizacja"',
      trainers: '"trenerzy"',
      currentPassword: '"aktualne hasło"',
      newPassword: '"nowe hasło"',
      confirmPassword: '"potwierdź hasło"',
      notes: '"notatki"',
      status: '"status"',
      email: '"e-mail"',
      phone: '"telefon"',
      customer: '"klient"',
      url: '"url"',
      roles: '"role"',
      address: '"adres"',
      capacity: '"pojemność"',
      minimumBookingLimits: '"minimalny limit rezerwacji"',
      setLimitTo: '"ustaw limit rezerwacji na"',
      maximumBookingLimits: '"maksymalny limit rezerwacji"',
      acceptBookings: '"przyjmować rezerwacje do"',
      cancellationReason: '"Powody anulowania"',
      absenceReason: '"przyczyny nieobecności"',
      eventCost: '"koszt wydarzenia"',
      sessionCost: '"koszt sesji"',
      learnerAccount: '"konto ucznia"',
      expiryDate: '"data wygaśnięcia"',
      code: '"kod"'
    },
    submit: {
      title: 'Niektóre z twoich pól są nieprawidłowe',
      buttons: {
        resetChanges: 'Zresetuj zmiany',
        saveChanges: 'Zapisz zmiany'
      },
      notify: {
        title: `Zresetuj zmiany`,
        content: `Czy chcesz zresetować wszystkie ostatnie zmiany?`,
        cancel: `Anuluj`,
        submit: `Potwierdź`
      }
    },
    price: 'zł',
    radioHorizontal: {
      label: 'Inne',
      text: 'Opisz wszystko, co chcesz'
    },
    select: {
      title: 'Wydarzenie nie jest używane na żadnych kursach',
      label: 'Inny powód',
      default: 'Wpisz aby wyszukać',
      buttons: {
        close: 'Zamknij pole dodawania',
        add: 'Dodaj więcej przedmiotów'
      }
    },
    customForm: {
      title: {
        thereMustBe: 'Formularz musi zawierać co najmniej jedno pole.',
        editField: 'Edytuj pole',
        addField: 'Dodaj pole',
        addOption: 'Dodaj nową opcję dla każdej linii',
        updateField: 'Zaktualizuj pole',
        AddFieldTo: 'Dodaj pole do formularza'
      },
      labels: {
        fieldLabel: 'Etykieta pola',
        fieldType: 'Typ pola',
        text: 'tekst',
        checkbox: 'pole wyboru',
        singleChoice: 'Jeden wybór',
        multiChoice: 'wielokrotny wybór',
        options: 'Opcje',
        addOtherOption: 'Dodaj opcję „Inne”',
        helpText: 'Tekst pomocy',
        mandatory: 'Pole jest obowiązkowe'
      },
      emptyState: {
        titleNotCreated: 'Nie utworzono jeszcze pól',
        contentNotCreated: 'Dodaj tekst, pola wyboru, opcje pojedynczego i wielokrotnego wyboru.'
      },
      buttons: {
        preview: 'Podgląd',
        addField: 'Dodaj pole',
        cancel: 'Anuluj',
        submitForm: 'Prześlij formularz',
        AddFieldTo: 'Dodaj pole do formularza',
        updateField: 'Zaktualizuj pole'
      },
      default: {
        completeTheForm: 'Wypełnij poniższy formularz, aby zarezerwować sesję',
        submitForm: 'Prześlij formularz',
        bookOntoSession: 'Rezerwuj'
      }
    },
    editor: {
      dynamicTags: 'Tagi',
      MeLearning: '© Me Learning'
    },
    image: {
      title: {
        dragAndDrop: 'Przeciągnij i upuść plik do przesłania',
        or: 'lub',
        PleaseEnsureUploaded: 'Upewnij się, że przesyłane obrazy są w formacie JPG, JPEG lub PNG',
        clickTheButton: 'Kliknij przycisk poniżej, aby załączyć pliki.'
      },
      buttons: {
        primarySelect: 'Wybierz',
        upload: 'Prześlij plik',
        change: 'Zmień pliki',
        select: 'Wybierz plik'
      },
      notify: {
        titleRemove: 'Usuń obraz',
        contentRemove: 'Czy na pewno chcesz usunąć ten obraz?',
        remove: 'Usuń',
        cancel: 'Anuluj'
      }
    },
    location: {
      title: {
        address: 'Adres'
      },
      labels: {
        address1: 'Adres 1',
        address2: 'Adres 2',
        townCity: 'Miejscowość',
        county: 'Województwo',
        postCode: 'Kod pocztowy',
        country: 'Państwo'
      },
      buttons: {
        toggle: 'Przełącz na wyszukiwanie adresu',
        enter: 'Wpisz adres ręcznie'
      },
      notify: {
        title: 'Wpisz adres ręcznie',
        content: 'Spowoduje to wyczyszczenie wszystkich aktualnych danych adresowych',
        submit: 'Potwierdź',
        cancel: 'Anuluj'
      },
      translatedLanguages: {
        poland: 'Polska'
      }
    },
    listSelect: {
      filter: {
        search: 'Szukaj ',
        add: 'Dodaj '
      },
      emptyState: {
        titleNo: '',
        titleFound: 'nie został znaleziony',
        content: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
        titleCreated: `nie został stworzony`
      },
      buttons: {
        selected: 'Wybrany',
        select: 'Wybierz'
      }
    },
    date: {
      days: {
        monday: 'Poniedziałek',
        tuesday: 'Wtorek',
        wednesday: 'Środa',
        thursday: 'Czwartek',
        friday: 'Piątek',
        saturday: 'Sobota',
        sunday: 'Niedziela'
      },
      labels: {
        dateFrom: 'od',
        dateTo: 'do'
      }
    },
    checkbox: {
      active: 'AKTYWNY',
      draft: 'NIEAKTYWNY',
      inactive: 'NIEAKTYWNY'
    },
    notification: 'powiadomienie kiedy'
  },
  filter: {
    moreMenu: {
      title: {
        cost: 'Koszt',
        capacity: 'Pojemność'
      },
      buttons: {
        apply: 'Zastosuj',
        clear: 'Wyczyść',
        moreFilters: 'Więcej filtrów'
      },
      options: {
        lessThan: 'Mniej niż',
        hour: 'Godzina',
        one: 'Jeden',
        two: 'Dwa'
      }
    },
    select: {
      itemExtra: `Specyficzny`,
      reset: 'Zresetuj'
    },
    sort: {
      buttonSortBy: 'Sortuj według',
      menuToDo: 'Do zrobienia'
    },
    baseFilter: {
      search: 'Szukaj',
      name: 'Nazwa',
      submit: 'Zatwierdź',
      cancel: 'Anuluj'
    }
  },
  shared: {
    components: {
      title: {
        details: 'Detale',
        capacity: 'Pojemność',
        people: 'ludzie',
        cost: 'Koszt',
        per: 'za',
        currency: 'zł',
        NotAvailable: 'Niedostępne',
        note: 'Wymienione godziny mogą być edytowane tylko przez administratorów systemu i dlatego mogą nie być prawdziwym odzwierciedleniem rzeczywistej dostępności trenerów.',
        errorLoading: 'Coś poszło nie tak i nie można załadować danych. Spróbuj ponownie później.',
        location: 'Lokalizacja'
      },
      notify: {
        titleActions: `Usuń`,
        contentTextRemove: `Czy chcesz to usunąć?`,
        contentTextArchive: `Czy chcesz to zarchiwizować?`,
        contentTextPermanently: `na stałe?`,
        contentTextDoYouWantTo: `Czy chcesz`,
        contentTextThis: 'to',
        cancel: `Anuluj`,
        confirm: `Potwierdź`,
        delete: `Usuń`,
        archive: 'Archiwum'
      },
      buttons: {
        cancel: 'Anuluj',
        create: 'Stwórz',
        edit: 'Edytuj',
        confirm: 'Potwierdź',
        close: 'Zamknij'
      },
      props: {
        sending: 'wysyłanie',
        actionNotAllowed: 'Działanie niedozwolone',
        consider: 'Rozważ:',
        titleItemsNotCreated: 'Żadne elementy nie zostały jeszcze utworzone',
        noItemsHaveBeenFound: 'Nie znaleziono przedmiotów',
        pleaseModifyYourSearch: 'Zmodyfikuj zapytanie i spróbuj ponownie.',
        searchItems: 'Szukaj przedmiotów'
      },
      labels: {
        bulk: 'Działania masowe'
      },
      return: {
        selected: 'wybrany',
        statusError: 'błąd',
        customMessageSomethingWrong: 'Coś poszło nie tak podczas usuwania osiągnięcia. Spróbuj ponownie lub skontaktuj się z administratorem.'
      }
    },
    pop: {
      title: {
        format: 'Format',
        email: 'E-mail',
        trigger: 'Wyzwalacz',
        sent: 'Wyślij',
        sentFrom: 'Wysłane z',
        sentTo: 'Wysłane do',
        willBeVisibleCc: 'Odbiorcy Cc będą widoczni dla innych odbiorców.',
        willBeVisibleBcc: 'Odbiorcy Bcc będą widoczni dla innych odbiorców.',
        successfully: 'powodzenie',
        ed: '',
        error: 'Wystąpił błąd kiedy',
        the: '',
        pleaseTryAgain: 'Proszę spróbuj ponownie.'
      },
      labels: {
        chooseReason: 'Wybierz powód',
        subject: 'Przedmiot',
        typeToAdd: 'Wpisz, aby dodać',
        search: 'Szukaj'
      },
      buttons: {
        close: 'Zamknij',
        reset: 'Reset',
        custom: 'Custom',
        update: 'Aktualizacja',
        CcOrBcc: 'Cc lub Bcc',
        leaveNow: 'Wyjdź',
        continueWorking: 'Kontynuuj pracę',
        view: 'Pogląd'
      },
      props: {
        alertMessage: 'Komunikat ostrzegawczy',
        leaveNow: 'Wyjdź',
        continue: 'Kontynuować pracę',
        saved: 'Twoje zmiany nie zostaną zapisane',
        DoYouWantToContinue: 'Czy chcesz kontynuować?',
        custom: 'Zwyczaj'
      }
    },
    viewAction: {
      notify: {
        remove: 'Usuń',
        archive: `Zarchiwizuj`,
        contentArchive: `Czy chcesz zarchiwizować`,
        cancel: `Anuluj`,
        confirm: 'Potwierdź',
        contentDoYouWant: ``,
        contentThis: ``,
        contentSelected: 'Wybrane',
        itemName: 'przedmiot | przedmioty'
      },
      props: {
        edit: 'Edycja',
        cancel: 'Anuluj',
        custom: 'Zwyczaj',
        customTitle: 'Custom title',
        customText: 'Custom text',
        confirm: 'Potwierdź',
        create: 'Stwórz'
      }
    },
    navigator: {
      buttons: {
        back: 'Powrót',
        previous: 'Poprzedni',
        next: 'Kolejny',
        delete: 'Usunąć'
      },
      props: {
        deleteItem: 'Usuń przedmiot',
        areYouSure: 'Czy na pewno chcesz usunąć ten element?'
      }
    }
  },
  notifications: {
    createVenue: 'Miejsce o tej nazwie już istnieje. Spróbuj użyć innej nazwy.',
    updateVenue: 'Miejsce o tej nazwie już istnieje. Spróbuj użyć innej nazwy.',
    createRoom: 'Pokój o tej nazwie już istnieje w tym miejscu. Spróbuj użyć innej nazwy.',
    updateRoom: 'Pokój o tej nazwie już istnieje w tym miejscu. Spróbuj użyć innej nazwy.',
    sessionAlreadyStarted: 'Nie można zarezerwować tej sesji, ponieważ już się rozpoczęła. Zarezerwuj na inną sesję.',
    sessionFullyBooked: 'Nie można zarezerwować tej sesji, ponieważ jest w pełni zarezerwowana. Zarezerwuj na inną sesję.',
    learnerEnrolment: 'Nie można zarezerwować tej sesji, ponieważ nie zapisano się na kurs zawierający to wydarzenie. Upewnij się, że zapisałeś się na kurs zawierający to wydarzenie.',
    sessionNoLongerAccepting: 'Nie można zarezerwować tej sesji, ponieważ nie akceptuje ona już rezerwacji. Zarezerwuj na inną sesję.',
    bookingValidatorEventHasNoCourses: 'wydarzenie nie jest powiązane z żadnym kursem.',
    bookingValidatorFullyBooked: 'sesja jest w pełni zarezerwowana.',
    bookingValidatorAlreadyStarted: 'sesja już się rozpoczęła.',
    bookingValidatorLearnerAlreadyBooked: 'jesteś już zarezerwowany na tę sesję.',
    bookingValidatorNoLongerAccepting: 'sesja nie przyjmuje już rezerwacji.',
    bookingValidatorTrainerNotAllowed: 'jesteś wyznaczonym trenerem.',
    bookingValidatorLmsUnavailable: 'LMS jest niedostępny.',
    bookingValidatorLearnerEnrolment: 'nie zapisał się na kurs zawierający to wydarzenie.',
    BookingValidatorProfileExpirationDate: 'wygasł Twój profil użytkownika.'
  },
  notificationsHelpers: {
    the: ', ',
    comma: ', ',
    andThe: ' i ',
    and: ' i ',
    ToThis: 'To',
    hasBeenSetOnThe: 'został ustawiony na',
    pleaseRemove: '. Usuń je z',
    before: 'przed próbą ich usunięcia.',
    hintContact: 'skontaktuj się z administratorem wydarzenia.',
    hintBook: 'zarezerwuj na inną sesję.',
    hintProfileExpired: 'skontaktuj się z administratorem.',
    hintEnsure: 'upewnij się, że zapisałeś się na kurs zawierający to wydarzenie.',
    hindRefresh: 'odśwież i spróbuj ponownie.'
  },
  store: {
    auth: {
      customMessageExpired: 'Zostałeś wylogowany z powodu wygasłej sesji.',
      customMessageRefreshing: 'Zostałeś wylogowany z powodu problemów z odświeżaniem sesji.',
      customMessageToken: 'Zostałeś przekierowany z powodu problemów z tokenami.',
      customMessageLogged: 'Zostałeś przekierowany, ponieważ nie jesteś zalogowany.',
      customMessageWorkspaces: 'Twoja sesja obszaru roboczego wygasła.',
      customMessageRedirected: 'Zostałeś przekierowany z powodu problemów z konfiguracją sesji.',
      customMessageMissingSession: 'Zostałeś przekierowany z powodu brakującej sesji.',
      customMessageLoggedSuccessfully: 'Zalogowano się pomyślnie.',
      customMessageSomeErrors: 'Wystąpiły błędy podczas logowania.',
      customMessageLoggedOutSuccessfully: 'Wylogowano pomyślnie.',
      customMessageSessionRemoved: 'Twoja sesja obszaru roboczego została usunięta.',
      customMessageVerificationCode: 'Kod weryfikacyjny został wysłany na Twój adres e-mail. Proszę, sprawdź swoją skrzynkę odbiorczą.',
      customMessageAccountCreated: 'Twoje konto zostało pomyślnie utworzone. Sprawdź swój adres e-mail, aby uzyskać hasło.',
      customMessageInvitationIsNotValid: `Nie możesz się zarejestrować, ponieważ zaproszenie jest nieprawidłowe. Spróbuj ponownie lub skontaktuj się z administratorem.`,
      //
      commitError: 'Podczas tworzenia nowego konta wystąpiły błędy.',
      commitSuccessfullyCreated: 'Pomyślnie utworzyłeś nowe konto. Najpierw potwierdź adres e-mail.',
      commitSomeError: 'Podczas tworzenia nowego konta wystąpiły błędy. Proszę spróbuj ponownie.',
      commitExistsError: 'Użytkownik o tym adresie e-mail już istnieje.',
      commitErrorsWhileTryingToReset: 'Wystąpiły błędy podczas próby zresetowania hasła.',
      commitSuccessfullyResetPassword: 'Pomyślnie zresetowałeś hasło. Możesz teraz zalogować się przy użyciu nowych danych.',
      //
      errorMessageNoExisting: 'Z tym adresem e-mail nie ma żadnego użytkownika.',
      errorMessageTryAfter: 'Przekroczono limit prób. Spróbuj po pewnym czasie.',
      errorMessageTryAgain: 'Wystąpiły pewne problemy, spróbuj ponownie.',
      errorInvalidVerification: 'Podano nieprawidłowy kod weryfikacyjny, spróbuj ponownie.',
      errorPasswordFailed: 'Hasło nie spełniało ograniczenia. Proszę użyć silniejszego hasła.',
      errorAttemptLimit: 'Przekroczono limit prób. Spróbuj po pewnym czasie.',
      errorPleaseTryAgain: 'Wystąpiły pewne problemy, spróbuj ponownie.',
      errorSomethingWentWrong: 'Coś poszło nie tak. Spróbuj wpisać kod jeszcze raz.',
      errorUserHasNoRole: 'Użytkownik nie ma wybranej roli'
    },
    default: {
      title: 'prośba o akcję'
    },
    ebs: {
      ebsBooking: {
        titleBulkBooking: 'Rezerwacja masowa',
        customMessageCreatingBulkBooking: 'Pomyślnie utworzono rezerwację zbiorczą.',
        customMessageCreatingError: 'Rezerwacja zbiorcza nie została utworzona.',
        customMessageRemovingBulkBooking: 'Rezerwacja zbiorcza została pomyślnie anulowana.',
        customMessageRemovingError: 'Rezerwacja zbiorcza nie została anulowana.'
      },
      ebsEmail: {
        titleEmail: 'E-mail',
        customMessageSavingEmail: 'E-mail został pomyślnie zapisany.',
        customMessageSavingError: 'Rezerwacja e-mail nie została zapisana.'
      },
      ebsEvent: {
        labelSpecialRequirements: 'Specjalne wymagania',
        labelYourSpecialRequirements: 'Twoje specjalne wymagania',
        titleEvent: 'wydarzenie',
        //
        customMessageEditEvent: 'Wydarzenie pomyślnie zaktualizowane.',
        customMessageEditError: 'Wydarzenie nie zostało zaktualizowane.',
        customMessageCreatingEvent: 'Wydarzenie zostało pomyślnie utworzone.',
        customMessageCreatingError: 'Wydarzenie nie zostało utworzone.',
        customMessageRemovingEvent: 'Zdarzenie zostało pomyślnie usunięte.'
      },
      ebsRegister: {
        titleRegister: 'zarejestrować',
        customMessageEditRegister: 'Rejestracja została pomyślnie zapisana.',
        customMessageEditError: 'Rejestr nie został zapisany.',
        customMessageCompletingRegister: 'Rejestracja zakończona powodzeniem.',
        customMessageCompletingError: 'Rejestr nie został zakończony.'
      },
      ebsRoom: {
        commit: 'Pokój o tej nazwie już istnieje w tym miejscu. Spróbuj użyć innej nazwy.',
        titleRoom: 'pokój',
        //
        customMessageEditRoom: 'Pokój pomyślnie zaktualizowany.',
        customMessageEditError: 'Pokój nie został zaktualizowany.',
        customMessageCreatingRoom: 'Pomyślnie utworzono pokój.',
        customMessageCreatingError: 'Pokój nie został utworzony.',
        customMessageRemovingRoom: 'Pomyślnie usunięto pokój.'
      },
      ebsSession: {
        titleSession: 'sesja',
        customMessageEditSession: 'Sesja pomyślnie zaktualizowana.',
        customMessageEditError: 'Sesja nie została zaktualizowana.',
        customMessageCreatingSession: 'Pomyślnie utworzono sesję.',
        customMessageCreatingError: 'Sesja nie została utworzona.',
        customMessageRemovingSession: 'Sesja pomyślnie anulowana.',
        customMessageRemovingError: 'Sesja nie została anulowana.'
      },
      ebsSubscription: {
        commitSuccessfullyExpressed: 'Z powodzeniem wyraziłem zainteresowanie wydarzeniem.',
        commitInterestInEvent: 'zainteresowanie wydarzeniem',
        commitSuccessfullyCancelled: 'Pomyślnie anulowano wyrażenie zainteresowania.',
        commitExpressionOfInterest: 'wyrażenie zainteresowania',
        commitCancelledSubEvent: 'Pomyślnie anulowano subskrypcje w przypadku.',
        commitSubscriptions: 'subskrypcje w wydarzeniu',
        commitJoined: 'Pomyślnie dołączono do listy oczekujących na sesję.',
        commitWaitlist: 'lista oczekujących w sesji',
        commitExpression: 'wyrażenie zainteresowania'
      },
      ebsVenue: {
        customMessage: 'Miejsce o tej nazwie już istnieje. Spróbuj użyć innej nazwy.',
        customMessageEditVenue: 'Miejsce pomyślnie zaktualizowane.',
        customMessageEditError: 'Miejsce nie zostało zaktualizowane.',
        customMessageCreatingVenue: 'Miejsce zostało pomyślnie utworzone.',
        customMessageCreatingError: 'Miejsce nie zostało utworzone.',
        customMessageRemovingVenue: 'Miejsce zostało pomyślnie usunięte.'
      },
      ebsSessionFeedback: {
        titleFeedback: 'informacje zwrotne',
        customMessageEditFeedback: 'Opinia została pomyślnie zaktualizowana.',
        customMessageEditError: 'Informacja zwrotna nie została zaktualizowana.'
      },
      ebsSummary: {
        titleSummary: 'streszczenie',
        customMessageEditSummary: 'Podsumowanie zostało pomyślnie zapisane.',
        customMessageEditError: 'Podsumowanie nie zostało zapisane.',
        customMessageCompletingSummary: 'Podsumowanie powiodło się.',
        customMessageCompletingError: 'Podsumowanie nie zostało ukończone.'
      },
      ebsTrainer: {
        titleTrainer: 'trener',
        customMessageEditTrainer: 'Trener pomyślnie zaktualizowany.',
        customMessageEditError: 'Trener nie został zaktualizowany.',
        customMessageCreatingTrainer: 'Trener pomyślnie stworzony.',
        customMessageCreatingError: 'Trener nie został stworzony.'
      }
    },
    lms: {
      superadmin: {
        lmsSuperadminAdmin: {
          customMessageCreatedSuperAdminRole: 'Pomyślnie utworzono rolę superadministratora.',
          customMessageSomethingWentWrong: 'Coś poszło nie tak podczas tworzenia roli superadministratora. Spróbuj ponownie lub skontaktuj się z administratorem.',
          customMessageSomethingWentWrongWhile: 'Coś poszło nie tak podczas pobierania danych administratora. Spróbuj ponownie lub skontaktuj się z administratorem.',
          customMessageSomethingWentWrongRemoving: 'Coś poszło nie tak podczas usuwania superadministratora. Spróbuj ponownie lub skontaktuj się z administratorem.'
        },
        lmsSuperadminCustomer: {
          titleCustomer: 'klient',
          customMessageEditCustomer: 'Klient pomyślnie zaktualizowany.',
          customMessageEditError: 'Klient nie został zaktualizowany.',
          customMessageCreatingCustomer: 'Klient został pomyślnie utworzony.',
          customMessageCreatingError: 'Klient nie został utworzony.',
          customMessageUpdatingTrainer: 'Klient pomyślnie zaktualizowany.',
          customMessageUpdatingError: 'Klient nie został zaktualizowany.'
        },
        lmsSuperadminCustomerSubscription: {
          titleCustomerSubscription: 'Subskrypcja',
          customMessageCreatingSubscription: 'Subskrypcja została pomyślnie utworzona.',
          customMessageCreatingError: 'Subskrypcja nie została utworzona.',
          customMessageRemovingSubscription: 'Subskrypcja została pomyślnie anulowana.',
          customMessageRemovingError: 'Subskrypcja nie została anulowana.'
        },
        lmsSuperadminAdminWorkspaceUser: {
          titleUser: 'użytkownik',
          titleUserInvitation: 'zaproszenie użytkownika',
          //
          customMessageInvitedInvitation: 'Użytkownik został pomyślnie zaproszony.',
          customMessageUpdatedInvitation: 'Użytkownik pomyślnie zaktualizowany.',
          customMessageUpdatedError: 'Użytkownik nie został zaktualizowany.',
          customMessageCancelInvitation: 'Zaproszenie zostało pomyślnie anulowane.',
          customMessageCancelError: 'Zaproszenie nie zostało anulowane.',
          customMessageResendingInvitation: 'Zaproszenie zostało ponownie wysłane.',
          customMessageResendingError: 'Zaproszenie nie zostało ponownie wysłane.'
        }
      },
      user: {
        lmsUserAccount: {
          customMessageWrongWhileDownloading: 'Coś poszło nie tak podczas pobierania danych użytkownika. Spróbuj ponownie lub skontaktuj się z administratorem.',
          customMessageSuccessfullyUpdated: 'Pomyślnie zaktualizowałeś dane użytkownika.',
          customMessageWrongWhileUpdating: 'Coś poszło nie tak podczas pobierania danych użytkownika. Spróbuj ponownie lub skontaktuj się z administratorem.',
          customMessageWrongWhilePerforming: 'Coś poszło nie tak podczas aktualizacji użytkownika. Spróbuj ponownie lub skontaktuj się z administratorem.',
          customMessageError: 'Podczas zmiany hasła wystąpiły błędy. Spróbuj ponownie lub skontaktuj się z administratorem.',
          customMessageSuccessfully: 'Pomyślnie zaktualizowałeś swoje hasło.'
        },
        lmsUserInvitation: {
          customMessageSuccessfully: 'Zaakceptowałeś zaproszenie do obszaru roboczego.',
          customMessageRejected: 'Odrzuciłeś zaproszenie do obszaru roboczego.'
        }
      }
    }
  },
  interceptors: {
    customMessageProblems: 'Zostałeś wylogowany z powodu problemów z uwierzytelnianiem.'
  }
}
