<template>
    <div class="component component__label">
        {{ outputLabel }}
    </div>
</template>

<script>
export default {
  name: 'component-label',
  props: {
    label: {
      type: String
    },
    truncate: {
      type: Number
    }
  },
  computed: {
    outputLabel () {
      const rawLabel = this.label
      const truncate = this.truncate
      let truncatedLabel

      if (truncate !== undefined && rawLabel.length > truncate) {
        truncatedLabel = rawLabel.substring(0, truncate)
        truncatedLabel = truncatedLabel[truncatedLabel.length - 1] === ' ' ? truncatedLabel.slice(0, -1) : truncatedLabel
        truncatedLabel += '...'
      }

      return truncatedLabel || rawLabel
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/components/default/shared/componentlabel";
</style>
