import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    users: [],
    actionPending: false,
    actionCompleted: false
  }
}

const lmsSuperadminUsersBulkActionsDeleteProfiles = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminUsersBulkActionsDeleteProfilesItems: state => {
      return state.data.users
    },
    getLmsSuperadminUsersBulkActionsDeleteProfilesItemsProfileIds: state => {
      return state.data.users.map(obj => obj.profileId)
    },
    getLmsSuperadminUsersBulkActionsDeleteProfilesItemsChecked: state => {
      const users = state.data.users
      const usersChecked = users.filter(obj => obj.checked)

      return usersChecked
    },
    getLmsSuperadminUsersBulkActionsDeleteProfilesItemsCheckedProfileIds: state => {
      const users = state.data.users
      const usersChecked = users.filter(obj => obj.checked)
      const usersMapped = usersChecked.map(obj => obj.profileId)

      return usersMapped
    },
    getLmsSuperadminUsersBulkActionsDeleteProfilesItemsDeletedProfileIds: state => {
      const users = state.data.users
      const usersUpdated = users.filter(obj => obj.updateStatus === 'archived')
      const usersMapped = usersUpdated.map(obj => obj.profileId)

      return usersMapped
    },
    getLmsSuperadminUsersBulkActionsDeleteProfilesActionPending: state => {
      return state.data.actionPending
    },
    getLmsSuperadminUsersBulkActionsDeleteProfilesActionCompleted: state => {
      return state.data.actionCompleted
    }
  },
  mutations: {
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ITEMS (state, payload) {
      let users = state.data.users
      let item = payload.item
      item.checked = true
      item.updateStatus = 'ready'

      const value = payload.value
      const id = payload.index
      const existingIdIndex = users.findIndex(obj => obj.profileId === id)

      if (value && existingIdIndex === -1) {
        users.push(item)
      }
      if (!value && existingIdIndex > -1) {
        users.splice(existingIdIndex, 1)
      }
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ITEMS_VALUE (state, payload) {
      let users = state.data.users

      const value = payload.value
      const key = payload.key
      const profileId = payload.profileId
      const existingIdIndex = users.findIndex(obj => obj.profileId === profileId)
      let user = JSON.parse(JSON.stringify(users[existingIdIndex]))
      user[key] = value

      users.splice(existingIdIndex, 1, user)
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ITEMS_CHECKED (state, payload) {
      let users = state.data.users

      const value = payload.value
      const id = payload.index
      const existingIdIndex = users.findIndex(obj => obj.profileId === id)

      users[existingIdIndex].checked = value
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ACTIONPENDING (state, payload) {
      state.data.actionPending = payload
    },
    LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ACTIONCOMPLETED (state, payload) {
      state.data.actionCompleted = payload
    }
  },
  actions: {
    lmsSuperadminUsersBulkActionsDeleteProfilesSingle (ctx, data) {
      return new Promise((resolve, reject) => {
        const profileId = data

        axios({
          method: 'delete',
          url: `${api.sso}/v2/delete-user-profile/${profileId}`
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminUsersBulkActionsDeleteProfilesRun ({ dispatch, getters, commit }) {
      return new Promise((resolve, reject) => {
        let users = getters.getLmsSuperadminUsersBulkActionsDeleteProfilesItemsChecked
        const usersLength = users.length
        let requestsCounter = 0
        let requestsSuccessCounter = 0
        let index = 0

        if (!users || !users.length) return reject(new Error())

        dispatch('defaultNotificationToastsAdd', {
          title: `Archiving profiles`,
          message: `0/${usersLength} profiles archived`,
          status: 'info',
          linkTo: { name: 'lms-superadmin-user-bulk-actions-delete-profiles-summary' },
          linkText: 'View action summary',
          iconName: 'loader',
          id: 'bulk-delete-profiles',
          preventClose: true,
          position: 'bottom'
        })

        commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ACTIONPENDING', true)
        commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ACTIONCOMPLETED', false)

        const performAction = () => {
          if (index >= usersLength) {
            commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ACTIONPENDING', false)
            commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ACTIONCOMPLETED', true)
            return resolve()
          }

          const user = users[index]
          const profileId = user.profileId

          commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ITEMS_VALUE', { key: 'updateStatus', value: 'archiving profile', profileId })

          dispatch('lmsSuperadminUsersBulkActionsDeleteProfilesSingle', profileId).then(() => {
            requestsSuccessCounter++
            commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ITEMS_VALUE', { key: 'updateStatus', value: 'archived', profileId })
          }).catch(() => {
            commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ITEMS_VALUE', { key: 'updateStatus', value: 'error', profileId })
          }).finally(() => {
            requestsCounter++
            if (requestsCounter === Number(usersLength)) {
              dispatch('defaultNotificationToastsAdd', {
                title: `Profiles archived`,
                message: `${requestsSuccessCounter}/${usersLength} profiles`,
                status: 'success',
                linkTo: { name: 'lms-superadmin-user-bulk-actions-delete-profiles-summary' },
                linkText: 'View action summary',
                iconName: 'check',
                id: 'bulk-delete-profiles',
                preventClose: false,
                position: 'bottom'
              })
              resolve()
            } else {
              dispatch('defaultNotificationToastsAdd', {
                title: `Archiving profiles`,
                message: `${requestsSuccessCounter}/${usersLength} profiles archived`,
                status: 'info',
                linkTo: { name: 'lms-superadmin-user-bulk-actions-delete-profiles-summary' },
                linkText: 'View action summary',
                iconName: 'loader',
                id: 'bulk-delete-profiles',
                preventClose: true,
                position: 'bottom'
              })
            }

            index++
            performAction()
          })
        }

        performAction()
      })
    },
    lmsSuperadminUsersBulkActionsDeleteProfilesSetItems ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ITEMS', data)
    },
    lmsSuperadminUsersBulkActionsDeleteProfilesSetItemsValue ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ITEMS_VALUE', data)
    },
    lmsSuperadminUsersBulkActionsDeleteProfilesSetItemsChecked ({ commit }, data) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_SET_ITEMS_CHECKED', data)
    },
    lmsSuperadminUsersBulkActionsDeleteProfilesClear ({ commit }) {
      commit('LMS_SUPERADMIN_USERS_BULK_ACTIONS_DELETE_PROFILES_CLEAR')
    }
  }
}

export default lmsSuperadminUsersBulkActionsDeleteProfiles
