<template>
    <div class="pop pop__toast">
        <pop-toast-item
            v-for="(notification, index) in notifications"
            :key="index"
            :index="index"
            :notification="notification"
        ></pop-toast-item>
    </div>
</template>

<script>
import PopToastItem from '@/components/default/shared/PopToastItem'

export default {
  name: 'pop-toast',
  components: { PopToastItem },
  computed: {
    notifications () {
      return this.$store.getters.getDefaultNotificationToasts
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/poptoast";
</style>
