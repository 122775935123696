import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: '',
  default: '',
  custom: {
    resultsForWorkspaceId: ''
  }
}

const ebsRequests = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsRequests: state => {
      return state.data
    },
    getEbsRequestsTotalResults: state => {
      return state.pagination.totalResults || 0
    },
    getEbsRequestsPagination: state => {
      return state.pagination
    },
    getEbsRequestsCustomResultsForWorkspaceId: state => {
      return state.custom.resultsForWorkspaceId
    }
  },
  mutations: {
    EBS_REQUESTS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    EBS_REQUESTS_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    },
    EBS_REQUESTS_SET (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))
      for (let item of payloadData) {
        item.blocked = false
      }
      state.data = payloadData
    },
    EBS_REQUESTS_SET_PAGINATION (state, payload) {
      state.pagination = payload
    },
    EBS_REQUESTS_SET_CUSTOM_RESULTSFORWORKSPACEID (state, payload) {
      state.custom.resultsForWorkspaceId = payload
    }
  },
  actions: {
    ebsRequestsGet ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const loggedInUserWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const workspaceId = loggedInUserWorkspaceId ? '&workspaceId=' + loggedInUserWorkspaceId : ''

        commit('EBS_REQUESTS_CLEAR')
        commit('EBS_REQUESTS_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.learningactivities}/enrolments/unresolved${queryUrlParams}${workspaceId}`
        }).then(response => {
          const responseData = response.data.data
          if (responseData) {
            commit('EBS_REQUESTS_SET', response.data.data)
            commit('EBS_REQUESTS_SET_CUSTOM_RESULTSFORWORKSPACEID', loggedInUserWorkspaceId)
            commit('EBS_REQUESTS_SET_PAGINATION', response.data.pagination)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsRequestsBulkApprove ({ getters, dispatch }) {
      return new Promise((resolve) => {
        const requests = getters.getEbsRequests
        let loopIteration = 0
        let loopCounter = 0
        let loopCounterSuccess = 0

        for (let request of requests) {
          loopIteration++
          setTimeout(() => {
            dispatch('ebsRequestGetApprove', request.id).then(() => {
              loopCounterSuccess++
            }).finally(() => {
              loopCounter++
              if (loopCounter === requests.length) {
                if (loopCounterSuccess === loopCounter) {
                  dispatch('defaultNotificationToastsAdd', {
                    title: 'Requests bulk approve',
                    message: 'Requests have been successfully approved.',
                    status: 'success'
                  })
                } else {
                  dispatch('defaultNotificationToastsAdd', {
                    title: 'Requests bulk approve',
                    message: `Some requests haven't been successfully approved. Please try again or contact an administrator.`,
                    status: 'error'
                  })
                }
                resolve()
              }
            })
          }, loopIteration * 250)
        }
      })
    },
    ebsRequestsBulkReject ({ getters, dispatch }) {
      return new Promise((resolve) => {
        const requests = getters.getEbsRequests
        let loopIteration = 0
        let loopCounter = 0
        let loopCounterSuccess = 0

        for (let request of requests) {
          loopIteration++
          setTimeout(() => {
            dispatch('ebsRequestGetReject', request.id).then(() => {
              loopCounterSuccess++
            }).finally(() => {
              loopCounter++
              if (loopCounter === requests.length) {
                if (loopCounterSuccess === loopCounter) {
                  dispatch('defaultNotificationToastsAdd', {
                    title: 'Requests bulk reject',
                    message: 'Requests have been successfully rejected.',
                    status: 'success'
                  })
                } else {
                  dispatch('defaultNotificationToastsAdd', {
                    title: 'Requests bulk reject',
                    message: `Some requests haven't been rejected. Please try again or contact an administrator.`,
                    status: 'error'
                  })
                }
                resolve()
              }
            })
          }, loopIteration * 250)
        }
      })
    },
    ebsRequestsClear ({ commit }) {
      commit('EBS_REQUESTS_CLEAR')
      commit('EBS_REQUESTS_CLEAR_PAGINATION')
    }
  }
}

export default ebsRequests
