export class Storage {
  static getLocal (key) {
    return localStorage.getItem(key)
  }
  static setLocal (key, value) {
    localStorage.setItem(key, value)
  }
  static removeLocal (key) {
    localStorage.removeItem(key)
  }
  static keysLocal () {
    return Object.keys(localStorage)
  }
  static getSession (key) {
    return sessionStorage.getItem(key)
  }
  static setSession (key, value) {
    sessionStorage.setItem(key, value)
  }
  static removeSession (key) {
    sessionStorage.removeItem(key)
  }
  static keysSession () {
    return Object.keys(sessionStorage)
  }
}
