<template>
    <transition name="fade" mode="out-in">
        <div
            :class="['pop', 'pop__table', size ? `pop__table--${size}` : '']"
            v-if="isActive"
        >
            <div class="pop__table_overlay"></div>
            <div class="pop__table_box">
                <header class="pop__table_box_header">
                    <div
                        v-if="titleText"
                        class="pop__table_box_header_title"
                    >{{titleText}}
                    </div>
                    <div class="pop__table_box_header_close">
                        <component-button
                            :name="'pop-table-close'"
                            :icon="true"
                            :icon-name="'x'"
                            :icon-raw="true"
                            :theme="''"
                            @click="closePop"
                        ></component-button>
                    </div>
                </header>
                <div class="pop__table_box_main">
                    <template v-if="contentText">
                        <div class="pop__table_box_main_content">{{ contentText }}</div>
                    </template>
                    <template v-if="alertMessageActive">
                        <div class="pop__table_box_main_alert">
                            <component-note>{{ alertMessageText }}</component-note>
                        </div>
                    </template>
                    <template v-if="filters">
                        <base-filter
                            :search-placeholder="$t('shared.pop.labels.search')"
                            :sort-active="false"
                            :actions-active="false"
                        />
                    </template>
                    <template v-if="getItemsDispatch || items">
                        <div class="pop__table_box_main_list">
                            <component-list
                                ref="componentlist"
                                :disallow="disallow"
                                :items="items ? (items.length ? items : undefined) : undefined"
                                :component-table="PopTableGrid"
                                :component-table-labels="labels"
                                :component-table-allow-single="allowSingle"
                                :component-table-checkbox="tableCheckbox"
                                :component-table-checkbox-name="tableCheckboxName"
                                :eventbus-emit-name="eventbusEmitName"
                                :item-name="itemName"
                                :empty-state-title="filterEmptyStateTitle"
                                :filter-search-placeholder="filterSearchPlaceholder"
                                :filter-search-queryable="filterSearchQueryable"
                                :filter-search-by-field="filterSearchByField"
                                :filter-select-active="filterSelectActive"
                                :filter-select-items="filterSelectItems"
                                :get-pagination-getter="getPaginationGetter"
                                :get-items-getter="getItemsGetter"
                                :get-items-dispatch="getItemsDispatch"
                                :get-items-dispatch-data="getItemsDispatchData"
                                :get-items-dispatch-params="getItemsDispatchParams"
                                :get-items-dispatch-trigger-name="getItemsDispatchTriggerName"
                                :selected="selected"
                                v-on="allowSingle ? { 'emit-check' : setSelectedSingle } : { 'emit-check' : setSelected }"
                                @emit-request-finally="handleEmit({ message: 'emit-request-finally' })"
                            >
                                <template v-slot:left>
                                    <template v-if="$slots.list">
                                        <slot name="list"></slot>
                                    </template>
                                </template>
                            </component-list>
                        </div>
                    </template>
                </div>
                <footer class="pop__table_box_footer">
                    <div class="columns is-variable">
                        <template v-if="$slots.footer">
                            <slot name="footer"></slot>
                        </template>
                        <template v-if="footerDetailsActive">
                            <div class="column is-narrow">
                                <div class="pop__table_box_footer_details">
                                    <component-note
                                        :danger="footerDetailsAvailableValue < selected.length"
                                    >
                                        {{ footerDetailsAvailableText }}: <strong>{{ footerDetailsAvailableValue }}</strong><br>
                                        {{ footerDetailsSelectedText }}: <strong>{{ selected.length }}</strong>
                                    </component-note>
                                </div>
                            </div>
                        </template>
                        <div class="column"></div>
                        <div class="column">
                            <div class="pop__table_box_footer_actions">
                                <div class="columns is-variable">
                                    <div class="column"></div>
                                    <template v-if="cancelButtonActive">
                                        <div class="column is-narrow">
                                            <component-button
                                                :theme="'default'"
                                                :border="true"
                                                @click="closePop()"
                                                :name="'pop-table-box-footer-actions-cancel'"
                                            >{{cancelButtonText}}
                                            </component-button>
                                        </div>
                                    </template>
                                    <transition name="fade" mode="out-in">
                                        <template v-if="submitButtonActive && selected.length || submitButtonActiveAlways">
                                            <div class="column is-narrow">
                                                <component-button
                                                    :danger="submitButtonDanger"
                                                    :disabled="submitButtonDisabled || footerDetailsLimitActive ? footerDetailsAvailableValue < selected.length : false"
                                                    :disabled-hint-active="submitButtonDisabledHintActive"
                                                    :disabled-hint-title="submitButtonDisabledHintTitle"
                                                    :disabled-hint-items="submitButtonDisabledHintItems"
                                                    :animate-loading="preventClose && submitButtonAnimateLoading"
                                                    :animate-loading-event-name="submitButtonAnimateLoadingEventName"
                                                    :disabled-hint-position="'top'"
                                                    :name="'pop-table-box-footer-actions-submit'"
                                                    @click="submit"
                                                >{{submitButtonText}}
                                                </component-button>
                                            </div>
                                        </template>
                                    </transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>

import BaseFilter from '@/components/default/filters/BaseFilter'
import PopTableGrid from '@/components/default/shared/PopTableGrid'
import ComponentList from '@/components/default/shared/ComponentList'
import ComponentNote from '@/components/default/shared/ComponentNote'
import { getDomPath } from '@/functions'

export default {
  components: {
    ComponentNote,
    ComponentList,
    ComponentButton: () => import('@/components/default/shared/ComponentButton'),
    BaseFilter
  },
  name: 'pop-table',
  data () {
    return {
      PopTableGrid,
      isActive: false,
      selected: []
    }
  },
  props: {
    disallow: {
      type: [Array, String, Number]
    },
    getPaginationGetter: {
      type: String
    },
    getItemsGetter: {
      type: String
    },
    getItemsDispatch: {
      type: String
    },
    getItemsDispatchData: {
      type: [String, Array, Object]
    },
    getItemsDispatchParams: {
      type: [String, Object]
    },
    getItemsDispatchTriggerName: {
      type: String,
      default: 'trigger-get-items'
    },
    itemName: {
      type: [String, Array],
      required: true
    },
    allowSingle: {
      type: Boolean,
      default: false
    },
    alertMessageActive: {
      type: Boolean,
      default: false
    },
    alertMessageText: {
      type: String,
      default: function () {
        return this.$t('shared.pop.props.alertMessage')
      }
    },
    //
    filters: {
      type: Boolean,
      default: false
    },
    filterEmptyStateTitle: {
      type: String
    },
    filterSelectActive: {
      type: Boolean,
      default: false
    },
    filterSelectItems: {
      type: Array,
      default: () => []
    },
    filterSearchPlaceholder: {
      type: String,
      default: 'Search items'
    },
    filterSearchQueryable: {
      type: Boolean,
      default: false
    },
    filterSearchByField: {
      type: String
    },
    footerDetailsActive: {
      type: Boolean,
      default: false
    },
    footerDetailsLimitActive: {
      type: Boolean,
      default: false
    },
    footerDetailsAvailableText: {
      type: String,
      default: 'Available'
    },
    footerDetailsAvailableValue: {
      type: [Number, String]
    },
    footerDetailsSelectedText: {
      type: String,
      default: 'Selected'
    },
    footerDetailsSelectedValue: {
      type: Number
    },
    titleText: {
      type: String,
      default: ''
    },
    contentText: {
      type: String,
      default: ''
    },
    //
    submitButtonText: {
      type: String,
      default: function () {
        return this.$t('shared.pop.props.leaveNow')
      }
    },
    submitButtonActive: {
      type: Boolean,
      default: true
    },
    submitButtonActiveAlways: {
      type: Boolean,
      default: false
    },
    submitButtonDisabled: {
      type: Boolean,
      default: false
    },
    submitButtonAnimateLoading: {
      type: Boolean,
      default: true
    },
    submitButtonAnimateLoadingEventName: {
      type: String,
      default: 'pop-table-submit'
    },
    submitButtonDisabledHintActive: {
      type: Boolean,
      default: false
    },
    submitButtonDisabledHintTitle: {
      type: String
    },
    submitButtonDisabledHintItems: {
      type: Array
    },
    submitButtonDanger: {
      type: Boolean,
      default: false
    },
    cancelButtonText: {
      type: String,
      default: function () {
        return this.$t('shared.pop.props.continue')
      }
    },
    cancelButtonActive: {
      type: Boolean,
      default: true
    },
    //
    tableCheckbox: {
      type: Boolean,
      default: false
    },
    tableCheckboxName: {
      type: String
    },
    //
    labels: {
      default: () => {
      },
      required: true
    },
    items: {
      default: () => {
      }
    },
    //
    eventbusEmitName: {
      type: String,
      default: 'pop-table'
    },
    preventClose: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'normal',
      validator: function (value) {
        return ['small', 'normal', 'big', 'wide', 'full'].indexOf(value) !== -1
      }
    }
  },
  methods: {
    clearSelected () {
      this.selected = []
      this.$emit('emit-selected', this.selected)
    },
    setPreselected (data) {
      if (data.length) {
        for (let item of data) {
          this.setSelected({ value: { value: true }, item: item })
        }
      }
    },
    setSelected (data) {
      if (this.selected.findIndex(obj => obj.id === data.item.id) === -1) {
        if (!data.value.value) return
        this.selected.push(data.item)
      } else {
        if (data.value.value) return
        const itemIndex = this.selected.findIndex(obj => {
          return obj.id === data.item.id
        })
        this.selected.splice(itemIndex, 1)
      }
      this.$emit('emit-selected', this.selected)
    },
    setSelectedSingle (data) {
      if (this.selected.length > 0 && this.selected[0].id === data.item.id && data.value.value === false) {
        this.selected = []
      } else if (this.selected.length === 0 || (this.selected[0].id !== data.item.id && data.value.value === true)) {
        this.selected = [data.item]
        this.$eventBus.$emit(`${this.eventbusEmitName}-list-action`, false)
      }

      this.$emit('emit-selected', this.selected)
    },
    closePop: function () {
      this.isActive = false
      this.selected = []
    },
    openPop: function () {
      this.isActive = true
    },
    //
    submit: function () {
      this.$emit('emit-submit', this.selected)
      if (!this.preventClose) {
        this.closePop()
      }
    },
    handleEmit (data) {
      const message = data.message
      const value = data.value
      this.$emit(message, value)
    }
  },
  updated () {
    let self = this
    let overlay = document.getElementsByClassName('pop__table_overlay')
    for (let i = 0; i < overlay.length; i++) {
      overlay[i].addEventListener('click', function () {
        self.closePop()
      })
    }
  },
  mounted () {
    const domItem = this.$el
    const domPath = getDomPath(domItem)

    for (let i in domPath) {
      const domLoopItem = domPath[i]
      if (domLoopItem.classList && domLoopItem.classList.contains('pop')) {
        domLoopItem.append(domItem)
        break
      }
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/poptable";
</style>
