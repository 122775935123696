<template>
    <div class="filter__more">
        <filter-more-button
                :class="menuActive ? 'active' : ''"
                @switch-menu="switchMenu"
        />
        <filter-more-menu
                :filter-more="filterMore"
                :class="menuActive ? 'active' : ''"
                v-show="menuActive"
        />
    </div>
</template>

<script>
import FilterMoreButton from '@/components/default/filters/FilterMoreButton'
import FilterMoreMenu from '@/components/default/filters/FilterMoreMenu'

export default {
  data () {
    return {
      menuActive: false
    }
  },
  components: {
    FilterMoreMenu,
    FilterMoreButton },
  name: 'filter-more',
  props: {
    filterMore: {
      type: Array
    }
  },
  methods: {
    switchMenu: function () {
      this.menuActive = !this.menuActive
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/ebs/filters/filtermore";
</style>
