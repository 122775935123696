import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    id: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    status: ''
  },
  default: ''
}

const lmsSuperadminUser = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminUserId (state) {
      return state.data.id
    },
    getLmsSuperadminUserName (state) {
      return `${state.data.firstName} ${state.data.lastName}`
    },
    getLmsSuperadminUserDefaultName (state) {
      return `${state.default.firstName} ${state.default.lastName}`
    },
    getLmsSuperadminUserFirstName (state) {
      return state.data.firstName
    },
    getLmsSuperadminUserLastName (state) {
      return state.data.lastName
    },
    getLmsSuperadminUserEmail (state) {
      return state.data.emailAddress
    },
    getLmsSuperadminUserStatus (state) {
      return state.data.status
    }
  },
  mutations: {
    LMS_SUPERADMIN_USER_GET (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_USER_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    LMS_SUPERADMIN_USER_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_USER_SET_FIRST_NAME (state, payload) {
      state.data.firstName = payload
    },
    LMS_SUPERADMIN_USER_SET_LAST_NAME (state, payload) {
      state.data.lastName = payload
    }
  },
  actions: {
    lmsSuperadminUserGet ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.sso}/v2/users/${id}`
        }).then((response) => {
          commit('LMS_SUPERADMIN_USER_GET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminUserReset ({ commit }) {
      commit('LMS_SUPERADMIN_USER_RESET')
    },
    lmsSuperadminUserClear ({ commit }) {
      commit('LMS_SUPERADMIN_USER_CLEAR')
    },
    lmsSuperadminUserUpdate ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const authAdminUpdateUserDetails = () => {
          return dispatch('authAdminUpdateUserDetails', data)
        }
        const lmsUserAccountUpdateHandleSSO = () => {
          return dispatch('lmsUserAccountUpdateHandleSSO', data)
        }
        const onResolve = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'User details update',
            message: 'User details has been successfully updated.',
            status: 'success'
          })
        }
        const onRejectUpdating = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'User details update',
            message: `User details hasn't been updated.`,
            status: 'error'
          })
        }

        authAdminUpdateUserDetails().then(() => {
          lmsUserAccountUpdateHandleSSO().then(() => {
            onResolve()
            resolve()
          }).catch((error) => {
            onRejectUpdating()
            reject(error)
          })
        }).catch(() => {
          lmsUserAccountUpdateHandleSSO().then(() => {
            onResolve()
            resolve()
          }).catch(error => {
            onRejectUpdating()
            reject(error)
          })
        })
      })
    },
    lmsSuperadminUserSetFirstName ({ commit }, data) {
      commit('LMS_SUPERADMIN_USER_SET_FIRST_NAME', data)
    },
    lmsSuperadminUserSetLastName ({ commit }, data) {
      commit('LMS_SUPERADMIN_USER_SET_LAST_NAME', data)
    }
  }
}

export default lmsSuperadminUser
