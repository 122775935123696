import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const ebsRooms = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getRooms: state => {
      return state.data
    },
    getRoomsMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.venue.name + ' - ' + item.name,
          value: item.id
        }
      })
    },
    getRoomsMappedIdNameStatus: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.venue.name + ' - ' + item.name,
          capacity: `${item.capacity} ${ item.capacity !== 1 ? 'seats' : 'seat' }`,
          status: item.status
        }
      })
    },
    getRoomsPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    SET_ROOMS (state, payload) {
      state.data = payload
    },
    SET_ROOMS_PAGINATION (state, payload) {
      state.pagination = payload
    },
    CLEAR_ROOMS (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    CLEAR_ROOMS_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    getRooms ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data && data.queryUrlParams ? data.queryUrlParams : '?pageSize=9999'
        const isCurrentIdsRequest = queryUrlParams ? queryUrlParams.includes('filters[ids]') : false
        const venueId = data && data.requestData && data.requestData.venueId ? `&filters[venueIds]=${data.requestData.venueId}` : ''

        let statusFilterArr = []

        if (isCurrentIdsRequest) {
          statusFilterArr.push('archived')
          statusFilterArr.push('active')
        }

        const statusFilter = statusFilterArr.length ? `&filters[status]=${statusFilterArr.join(',')}` : ''

        commit('CLEAR_ROOMS')
        commit('CLEAR_ROOMS_PAGINATION')

        axios({
          method: 'get',
          url: `${api.classroom}/rooms${queryUrlParams}${venueId}${statusFilter}`
        }).then(function (response) {
          commit('SET_ROOMS', response.data.data)
          commit('SET_ROOMS_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ebsRooms
