import axios from 'axios'
import api from '@/api'

const CancelToken = axios.CancelToken
let cancelEbsSessionsGetTrainerActive
let cancelEbsSessionsGetTrainerComplete
let cancelEbsSessionsGetLearnerActive
let cancelEbsSessionsGetLearnerComplete

const data = {
  pagination: {},
  data: [],
  default: '',
  custom: {
    active: {
      pagination: {},
      data: []
    },
    complete: {
      pagination: {},
      data: []
    }
  }
}

const ebsSessions = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsSessionsData: state => {
      return state.data
    },
    getEbsSessionsPagination: state => {
      return state.pagination
    },
    getEbsSessionsPaginationTotalResults: state => {
      return state.pagination.totalResults || 0
    },
    getEbsSessionsCustomActiveData: state => {
      return state.custom.active.data
    },
    getEbsSessionsCustomActivePagination: state => {
      return state.custom.active.pagination
    },
    getEbsSessionsCustomCompleteData: state => {
      return state.custom.complete.data
    },
    getEbsSessionsCustomCompletePagination: state => {
      return state.custom.complete.pagination
    }
  },
  mutations: {
    EBS_SESSIONS_SET_DATA (state, payload) {
      state.data = payload
    },
    EBS_SESSIONS_SET_PAGINATION (state, payload) {
      state.pagination = payload
    },
    EBS_SESSIONS_CLEAR_DATA (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    EBS_SESSIONS_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    },
    EBS_SESSIONS_ACTIVE_SET_DATA (state, payload) {
      state.custom.active.data = payload
    },
    EBS_SESSIONS_ACTIVE_SET_PAGINATION (state, payload) {
      state.custom.active.pagination = payload
    },
    EBS_SESSIONS_ACTIVE_CLEAR_DATA (state) {
      state.custom.active.data = JSON.parse(JSON.stringify(data.custom.active.data))
    },
    EBS_SESSIONS_ACTIVE_CLEAR_PAGINATION (state) {
      state.custom.active.pagination = JSON.parse(JSON.stringify(data.custom.active.pagination))
    },
    EBS_SESSIONS_COMPLETE_SET_DATA (state, payload) {
      state.custom.complete.data = payload
    },
    EBS_SESSIONS_COMPLETE_SET_PAGINATION (state, payload) {
      state.custom.complete.pagination = payload
    },
    EBS_SESSIONS_COMPLETE_CLEAR_DATA (state) {
      state.custom.complete.data = JSON.parse(JSON.stringify(data.custom.active.data))
    },
    EBS_SESSIONS_COMPLETE_CLEAR_PAGINATION (state) {
      state.custom.complete.pagination = JSON.parse(JSON.stringify(data.custom.active.pagination))
    }
  },
  actions: {
    ebsSessionsForEvent ({ commit, getters, dispatch }, data) {
      const eventId = data.constructor === String ? data : data.requestData
      const learnerId = getters.getAuthUserSecondStageTokenDataUserId
      let queryUrlParams = data && data.queryUrlParams ? data.queryUrlParams : '?pageSize=9999'

      if (!queryUrlParams.includes('sortBy')) {
        queryUrlParams += '&sortBy=sessionStart&sortDirection=asc'
      }

      return new Promise((resolve, reject) => {
        commit('EBS_SESSIONS_CLEAR_DATA')
        commit('EBS_SESSIONS_CLEAR_PAGINATION')

        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.classroom}/v2/events/${eventId}/sessions${queryUrlParams}`
        }).then(response => {
          let payloadData = response.data.data
          let payloadPagination = response.data.pagination

          dispatch('ebsBookingsGetForEvent', { eventId, learnerId }).then(response => {
            const bookings = JSON.parse(JSON.stringify(response))
            let uniqueBookings = []

            for (let booking of bookings) {
              const bookingsWithTheSameSessionId = bookings.filter(obj => obj.sessionId === booking.sessionId)

              if (bookingsWithTheSameSessionId.length > 1) {
                if (!uniqueBookings.find(findObj => findObj.sessionId === booking.sessionId)) {
                  uniqueBookings.push(bookingsWithTheSameSessionId.reduce((a, b) => new Date(a.updatedAt) > new Date(b.updatedAt) ? a : b))
                }
              } else {
                uniqueBookings.push(booking)
              }
            }

            for (let index in payloadData) {
              const startDate = payloadData[index].startDate
              const endDate = payloadData[index].endDate
              const sessionId = payloadData[index].sessionId
              const sessionBooking = uniqueBookings && uniqueBookings.length ? uniqueBookings.find(obj => obj.sessionId === sessionId) : null
              const userBookingStatus = sessionBooking ? sessionBooking.status : null
              let nameDate
              if (startDate === endDate) {
                nameDate = new Date(startDate).toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: '2-digit' })
              } else {
                nameDate = new Date(startDate).toLocaleDateString('en-gb', {
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit'
                }) + ' - ' + new Date(endDate).toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: '2-digit' })
              }
              payloadData[index].nameDate = nameDate
              payloadData[index].userBookingStatus = userBookingStatus
            }
            commit('EBS_SESSIONS_SET_DATA', payloadData)
            commit('EBS_SESSIONS_SET_PAGINATION', payloadPagination)
            resolve(payloadData)
          }).catch(error => {
            reject(new Error(error))
          })
        }).catch(error => {
          reject(new Error(error))
        })
      })
    },
    ebsSessionsCancelRequests () {
      if (cancelEbsSessionsGetTrainerActive) cancelEbsSessionsGetTrainerActive()
      if (cancelEbsSessionsGetTrainerComplete) cancelEbsSessionsGetTrainerComplete()
      if (cancelEbsSessionsGetLearnerActive) cancelEbsSessionsGetLearnerActive()
      if (cancelEbsSessionsGetLearnerComplete) cancelEbsSessionsGetLearnerComplete()
    },
    ebsSessionsGetTrainerActive ({ commit }, data) {
      let queryUrlParams = data ? data.queryUrlParams : ''
      if (!queryUrlParams.includes('sortBy=')) {
        queryUrlParams += '&sortBy=dateStart'
      }
      if (!queryUrlParams.includes('sortDirection=')) {
        queryUrlParams += '&sortDirection=asc'
      }
      const querySelect = '&filters[state]=upcoming,in_process,register_pending,assessment_pending,feedback_pending,cancelled'
      return new Promise((resolve, reject) => {
        commit('EBS_SESSIONS_ACTIVE_CLEAR_DATA')
        commit('EBS_SESSIONS_ACTIVE_CLEAR_PAGINATION')

        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsSessionsGetTrainerActive = ex
          }),
          method: 'get',
          url: `${api.classroom}/dashboard/trainer-session-states${queryUrlParams}${querySelect}`
        }).then(response => {
          commit('EBS_SESSIONS_ACTIVE_SET_DATA', response.data.data)
          commit('EBS_SESSIONS_ACTIVE_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsSessionsGetTrainerComplete ({ commit }, data) {
      let queryUrlParams = data ? data.queryUrlParams : ''
      if (!queryUrlParams.includes('sortBy=')) {
        queryUrlParams += '&sortBy=dateStart'
      }
      if (!queryUrlParams.includes('sortDirection=')) {
        queryUrlParams += '&sortDirection=desc'
      }
      const querySelect = '&filters[state]=complete,cancelled_completed'
      return new Promise((resolve, reject) => {
        commit('EBS_SESSIONS_COMPLETE_CLEAR_DATA')
        commit('EBS_SESSIONS_COMPLETE_CLEAR_PAGINATION')

        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsSessionsGetTrainerComplete = ex
          }),
          method: 'get',
          url: `${api.classroom}/dashboard/trainer-session-states${queryUrlParams}${querySelect}`
        }).then(response => {
          commit('EBS_SESSIONS_COMPLETE_SET_DATA', response.data.data)
          commit('EBS_SESSIONS_COMPLETE_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsSessionsGetLearnerActive ({ commit }, data) {
      let queryUrlParams = data ? data.queryUrlParams : ''
      if (!queryUrlParams.includes('sortBy=')) {
        queryUrlParams += '&sortBy=dateStart'
      }
      if (!queryUrlParams.includes('sortDirection=')) {
        queryUrlParams += '&sortDirection=asc'
      }
      const querySelect = '&bookingStatus=booked,pending_acceptation,accepted,in_progress'
      return new Promise((resolve, reject) => {
        commit('EBS_SESSIONS_ACTIVE_CLEAR_DATA')
        commit('EBS_SESSIONS_ACTIVE_CLEAR_PAGINATION')

        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsSessionsGetLearnerActive = ex
          }),
          method: 'get',
          url: `${api.classroom}/dashboard/learner-session-states${queryUrlParams}${querySelect}`
        }).then(response => {
          commit('EBS_SESSIONS_ACTIVE_SET_DATA', response.data.data)
          commit('EBS_SESSIONS_ACTIVE_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsSessionsGetLearnerComplete ({ commit }, data) {
      let queryUrlParams = data ? data.queryUrlParams : ''
      if (!queryUrlParams.includes('sortBy=')) {
        queryUrlParams += '&sortBy=dateStart'
      }
      if (!queryUrlParams.includes('sortDirection=')) {
        queryUrlParams += '&sortDirection=desc'
      }
      const querySelect = '&bookingStatus=passed,completed'
      return new Promise((resolve, reject) => {
        commit('EBS_SESSIONS_COMPLETE_CLEAR_DATA')
        commit('EBS_SESSIONS_COMPLETE_CLEAR_PAGINATION')

        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsSessionsGetLearnerComplete = ex
          }),
          method: 'get',
          url: `${api.classroom}/dashboard/learner-session-states${queryUrlParams}${querySelect}`
        }).then(response => {
          commit('EBS_SESSIONS_COMPLETE_SET_DATA', response.data.data)
          commit('EBS_SESSIONS_COMPLETE_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ebsSessions
