var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    `component`,
    `component__status`,
    `component__status--${_vm.toKebabCase(_vm.status)}`,
    _vm.size ? `component__status--size-${_vm.size}` : '',
    _vm.type ? `component__status--${_vm.type}` : '',
    _vm.forceDefault ? `component__status--force-default` : '',
    _vm.forceSuccess ? `component__status--force-success` : '',
    _vm.forceInfo ? `component__status--force-info` : '',
    _vm.forceDanger ? `component__status--force-danger` : '',
    _vm.capitalize ? `component__status--capitalize` : ''
]},[_vm._v(_vm._s(_vm._f("toCapitalize")(_vm._f("fromKebabCaseToString")(_vm.status))))])
}
var staticRenderFns = []

export { render, staticRenderFns }