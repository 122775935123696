var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
'component',
'component__note',
!_vm.danger && !_vm.success && !_vm.custom ? 'component__note--default' : '',
_vm.danger ? 'component__note--danger' : '',
_vm.success ? 'component__note--success' : '',
_vm.custom ? 'component__note--custom' : '',
_vm.margin ? `component__note--margin-${_vm.margin}` : '',
_vm.whiteSpace ? `component__note--white-space-${_vm.whiteSpace}` : ''
]},[_c('div',{staticClass:"columns is-vcentered is-variable is-2"},[_c('div',{staticClass:"column is-narrow"},[_c('div',{staticClass:"component__note_icon"},[(_vm.danger)?[_c('v-icon',{attrs:{"name":_vm.iconName || 'alert-triangle'}})]:(_vm.success)?[_c('v-icon',{attrs:{"name":_vm.iconName || 'check'}})]:[_c('v-icon',{attrs:{"name":_vm.iconName || 'info'}})]],2)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column"},[_c('span',[_vm._t("default")],2)]),(_vm.linkActive)?[_c('div',{class:[
                    'column',
                    'is-narrow'
                ]},[_c('component-button',{attrs:{"name":'component-note-link',"text":!_vm.linkButton,"to":_vm.linkTo,"size":'normal',"underline":!_vm.linkButton},on:{"click":_vm.emitLink}},[_vm._v(_vm._s(_vm.linkText)+" ")])],1)]:_vm._e()],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }