import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    name: '',
    description: '',
    targetAudience: {
      customer: null,
      workspaces: null,
      groups: null,
      organisationUnits: null
    },
    targetType: 'groups',
    workflowTrigger: '',
    interval: 0,
    intervalType: 'day',
    enrolTo: [],
    timeframe: 'immediately',
    enabled: true,
    emailId: null,
    retroactive: false
  },
  default: {}
}

const lmsSuperadminWorkflow = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkflowName: state => {
      return state.data.name
    },
    getLmsSuperadminWorkflowDescription: state => {
      return state.data.description
    },
    getLmsSuperadminWorkflowTargetAudience: state => target => {
      return state.data.targetAudience[target]
    },
    getLmsSuperadminWorkflowTargetType: state => {
      return state.data.targetType
    },
    getLmsSuperadminWorkflowWorkflowTrigger: state => {
      return state.data.workflowTrigger
    },
    getLmsSuperadminWorkflowInterval: state => {
      return state.data.interval
    },
    getLmsSuperadminWorkflowIntervalType: state => {
      return state.data.intervalType
    },
    getLmsSuperadminWorkflowEnrolTo: state => {
      return state.data.enrolTo
    },
    getLmsSuperadminWorkflowTimeframe: state => {
      return state.data.timeframe
    },
    getLmsSuperadminWorkflowEnabled: state => {
      return state.data.enabled
    },
    getLmsSuperadminWorkflowRetroactive: state => {
      return state.data.retroactive
    },
    getLmsSuperadminWorkflowEmailId: state => {
      return state.data.emailId
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKFLOW_GET (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))
      for (let target of Object.keys(payloadData.targetAudience)) {
        if (payloadData.targetAudience[target] !== null) {
          payloadData.targetType = target
        }
      }
      if (payloadData.interval !== 0) {
        if (payloadData.interval % 7 === 0) {
          payloadData.intervalType = 'week'
          payloadData.interval = payloadData.interval / 7
        } else {
          payloadData.intervalType = 'day'
        }
        payloadData.timeframe = 'scheduled'
      } else {
        payloadData.timeframe = JSON.parse(JSON.stringify(state.data.timeframe))
      }
      payloadData.retroactive = false

      state.data = payloadData
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    LMS_SUPERADMIN_WORKFLOW_SET_NAME (state, payload) {
      state.data.name = payload
    },
    LMS_SUPERADMIN_WORKFLOW_SET_DESCRIPTION (state, payload) {
      state.data.description = payload
    },
    LMS_SUPERADMIN_WORKFLOW_SET_TARGETAUDIENCE (state, payload) {
      const data = payload.data.map(obj => obj.id || obj.value)
      const target = payload.target
      state.data.targetAudience[target] = data.length ? data : null
    },
    LMS_SUPERADMIN_WORKFLOW_SET_TARGETTYPE (state, payload) {
      state.data.targetAudience = JSON.parse(JSON.stringify(data.data.targetAudience))
      state.data.targetType = payload
    },
    LMS_SUPERADMIN_WORKFLOW_SET_WORKFLOWTRIGGER (state, payload) {
      state.data.workflowTrigger = payload.value
    },
    LMS_SUPERADMIN_WORKFLOW_SET_INTERVAL (state, payload) {
      state.data.interval = payload
    },
    LMS_SUPERADMIN_WORKFLOW_SET_INTERVALTYPE (state, payload) {
      state.data.intervalType = payload
    },
    LMS_SUPERADMIN_WORKFLOW_SET_ENROLTO (state, payload) {
      state.data.enrolTo = payload.map(obj => obj.value)
    },
    LMS_SUPERADMIN_WORKFLOW_SET_TIMEFRAME (state, payload) {
      if (payload === 'scheduled') {
        state.data.interval = 52
        state.data.intervalType = 'week'
      } else {
        state.data.interval = 0
        state.data.intervalType = 'day'
      }

      state.data.timeframe = payload
    },
    LMS_SUPERADMIN_WORKFLOW_SET_ENABLED (state, payload) {
      state.data.enabled = payload
    },
    LMS_SUPERADMIN_WORKFLOW_SET_RETROACTIVE (state, payload) {
      state.data.retroactive = payload
    },
    LMS_SUPERADMIN_WORKFLOW_SET_EMAILID (state, payload) {
      state.data.emailId = payload
    },
    LMS_SUPERADMIN_WORKFLOW_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    }
  },
  actions: {
    lmsSuperadminWorkflowGet ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.learningactivities}/workflows/${id}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKFLOW_GET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkflowCreate ({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        if (stateData.intervalType === 'week') {
          stateData.interval = stateData.interval * 7
        }
        stateData.intervalType = undefined
        stateData.targetType = undefined

        axios({
          method: 'post',
          url: `${api.learningactivities}/workflows`,
          data: stateData
        }).then(response => {
          if (stateData.retroactive) {
            dispatch('lmsSuperadminWorkflowExecute', response.data.id).finally(() => {
              resolve(response)
            })
          } else {
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkflowUpdate ({ state, dispatch }, id) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        if (stateData.intervalType === 'week') {
          stateData.interval = stateData.interval * 7
        }
        stateData.intervalType = undefined
        stateData.targetType = undefined

        axios({
          method: 'post',
          url: `${api.learningactivities}/workflows/${id}`,
          data: stateData
        }).then(response => {
          if (stateData.retroactive) {
            dispatch('lmsSuperadminWorkflowExecute', response.data.id).finally(() => {
              resolve(response)
            })
          } else {
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkflowExecute (ctx, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.learningactivities}/workflows/execute/${id}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkflowArchive (ctx, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${api.learningactivities}/workflows/${id}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkflowClear ({ commit }) {
      commit('LMS_SUPERADMIN_WORKFLOW_CLEAR')
    },
    lmsSuperadminWorkflowSetName ({ commit }, payload) {
      commit('LMS_SUPERADMIN_WORKFLOW_SET_NAME', payload)
    },
    lmsSuperadminWorkflowSetDescription ({ commit }, payload) {
      commit('LMS_SUPERADMIN_WORKFLOW_SET_DESCRIPTION', payload)
    },
    lmsSuperadminWorkflowSetTargetAudience ({ commit }, payload) {
      commit('LMS_SUPERADMIN_WORKFLOW_SET_TARGETAUDIENCE', payload)
    },
    lmsSuperadminWorkflowSetTargetType ({ commit }, payload) {
      commit('LMS_SUPERADMIN_WORKFLOW_SET_TARGETTYPE', payload)
    },
    lmsSuperadminWorkflowSetWorkflowTrigger ({ commit }, payload) {
      commit('LMS_SUPERADMIN_WORKFLOW_SET_WORKFLOWTRIGGER', payload)
    },
    lmsSuperadminWorkflowSetInterval ({ commit }, payload) {
      commit('LMS_SUPERADMIN_WORKFLOW_SET_INTERVAL', payload)
    },
    lmsSuperadminWorkflowSetIntervalType ({ commit }, payload) {
      commit('LMS_SUPERADMIN_WORKFLOW_SET_INTERVALTYPE', payload)
    },
    lmsSuperadminWorkflowSetEnrolTo ({ commit }, payload) {
      commit('LMS_SUPERADMIN_WORKFLOW_SET_ENROLTO', payload)
    },
    lmsSuperadminWorkflowSetTimeframe ({ commit }, payload) {
      commit('LMS_SUPERADMIN_WORKFLOW_SET_TIMEFRAME', payload)
    },
    lmsSuperadminWorkflowSetEnabled ({ commit }, payload) {
      commit('LMS_SUPERADMIN_WORKFLOW_SET_ENABLED', payload)
    },
    lmsSuperadminWorkflowSetRetroactive ({ commit }, payload) {
      commit('LMS_SUPERADMIN_WORKFLOW_SET_RETROACTIVE', payload)
    },
    lmsSuperadminWorkflowSetEmailId ({ commit }, payload) {
      commit('LMS_SUPERADMIN_WORKFLOW_SET_EMAILID', payload)
    }
  }
}

export default lmsSuperadminWorkflow
