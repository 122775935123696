<template>
    <div :class="['component', 'component__separator', vertical ? 'component__separator--vertical' : '']"></div>
</template>

<script>
export default {
  name: 'component-separator',
  props: {
    vertical: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentseparator";
</style>
