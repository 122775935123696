import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { User } from '@/User'
import { checkResponseNotFound } from '@/interceptorsHelpers'

const ebsSummary = {
  state: {
    data: [
      {
        completion: '',
        email: '',
        firstName: '',
        lastName: '',
        fullName: '',
        id: '',
        learnerId: '',
        notes: '',
        occurrenceId: '',
        status: ''
      }
    ],
    default: [],
    custom: {
      requestLoaded: false,
      requestInProgress: false
    }
  },
  getters: {
    getSummary: state => {
      return state.data
    },
    getSummaryId: state => {
      if (!state.data.length) return null
      return state.data[0].id
    },
    getSummarySessionId: state => {
      if (!state.data.length) return null
      return state.data[0].occurrenceId
    },
    getSummaryStatus: state => {
      if (!state.data.length) return null
      return state.data[0].status
    },
    getSummaryAllowEdit: (state, getters) => {
      const user = new User()

      const sessionEventCoordinators = getters.getSessionEventCoordinatorsId
      const sessionTrainers = getters.getSessionTrainers
      const completionType = getters.getSessionCompletionType

      const isEventCoordinator = user.isEventCoordinator()
      const isSessionEventCoordinator = user.isSessionEventCoordinator(sessionEventCoordinators)
      const isSessionTrainer = user.isSessionTrainer(sessionTrainers)
      const isAssessmentCompleted = getters.getSessionIsAssessmentRegistryCompleted

      const allowEditByAnyCoordinator = getters.getSessionCompletionRegistersEditByAnyCoordinator

      return (
        !isAssessmentCompleted &&
      (
        (allowEditByAnyCoordinator && isEventCoordinator) ||
      (!allowEditByAnyCoordinator && isSessionEventCoordinator) ||
      (isSessionTrainer)
      ) &&
      completionType === 'assessment'
      )
    },

    getSummaryAllowDisplayComplete: (state, getters) => {
      const summaryAllowEdit = getters.getSummaryAllowEdit
      const isAssessmentCompleted = getters.getSessionIsAssessmentRegistryCompleted

      return (
        summaryAllowEdit &&
      !isAssessmentCompleted
      )
    },
    getSummaryAllowPerformComplete: (state, getters) => {
      const summaryAllowEdit = getters.getSummaryAllowEdit
      const isAssessmentFilled = getters.getSessionIsAssessmentRegistryFilled
      const isAssessmentCompleted = getters.getSessionIsAssessmentRegistryCompleted
      const status = getters.getSessionStatus

      return (
        summaryAllowEdit &&
      isAssessmentFilled &&
      !isAssessmentCompleted &&
      status === 'ended'
      )
    },
    getSummaryCustomLoaded: (state) => {
      return state.custom.requestLoaded
    }
  },
  mutations: {
    GET_SUMMARY: (state, payload) => {
      let stateData = JSON.parse(JSON.stringify(payload))

      for (let item of stateData) {
        item.fullName = item.firstName + ' ' + item.lastName
      }

      state.data = stateData
      state.default = JSON.parse(JSON.stringify(stateData))
    },
    //
    CLEAR_SUMMARY: (state) => {
      state.data = [
        {
          completion: '',
          email: '',
          firstName: '',
          id: '',
          lastName: '',
          learnerId: '',
          notes: '',
          occurrenceId: '',
          status: ''
        }
      ]
    },
    CLEAR_SUMMARY_CUSTOM: (state) => {
      state.custom = {
        requestLoaded: false
      }
    },
    RESET_SUMMARY (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    SET_SUMMARY_COMPLETION: (state, payload) => {
      let entries = state.data
      const selectedEntryIndex = entries && entries.length ? entries.findIndex(obj => obj.learnerId === payload.index) : -1

      if (selectedEntryIndex > -1) {
        state.data[selectedEntryIndex].completion = payload.value
      }
    },
    SET_SUMMARY_NOTES: (state, payload) => {
      let entries = state.data
      const selectedEntryIndex = entries && entries.length ? entries.findIndex(obj => obj.learnerId === payload.index) : -1

      if (selectedEntryIndex > -1) {
        state.data[selectedEntryIndex].notes = payload.value
      }
    },
    SET_SUMMARY_CUSTOM_REQUESTLOADED: (state, payload) => {
      state.custom.requestLoaded = payload
    },
    SET_SUMMARY_CUSTOM_REQUESTINPROGRESS: (state, payload) => {
      state.custom.requestInProgress = payload
    }
  },
  actions: {
    getSummary ({ commit, state }, id) {
      if (state.custom.requestInProgress) {
        return
      } else {
        commit('SET_SUMMARY_CUSTOM_REQUESTINPROGRESS', true)
      }
      commit('CLEAR_SUMMARY')
      axios({
        method: 'get',
        url: `${api.classroom}/digital-register-summary-list/${id}`
      }).then(response => {
        commit('GET_SUMMARY', response.data)
        commit('SET_SUMMARY_CUSTOM_REQUESTINPROGRESS', false)
        commit('SET_SUMMARY_CUSTOM_REQUESTLOADED', true)
      }).catch(function (error) {
        commit('SET_SUMMARY_CUSTOM_REQUESTINPROGRESS', false)
        checkResponseNotFound(error.response.status)
      })
    },
    createSummary ({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.classroom}/digital-register-summary-bulk`,
          data: state.data
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Summary create',
            message: `Summary has been successfully created.`,
            status: 'success'
          })
          commit('CLEAR_REGISTER')
          commit('CLEAR_REGISTER_CUSTOM')
          commit('CLEAR_SUMMARY')
          commit('CLEAR_SUMMARY_CUSTOM')
          commit('CLEAR_SESSION')
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Summary create',
            message: `Summary hasn't been created.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    completeSummary ({ commit, getters, rootState, dispatch }) {
      return new Promise((resolve, reject) => {
        const sessionId = getters.getSessionId
        axios({
          method: 'post',
          url: `${api.classroom}/occurrence/commit/${sessionId}/assessment`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Summary complete',
            message: `Summary has been successfully completed.`,
            status: 'success',
            linkText: 'Go to summary',
            linkTo: {
              name: 'ebs-session-summary',
              params: { eventId: rootState.route.params.eventId, sessionId: rootState.route.params.sessionId, summaryId: rootState.route.params.summaryId }
            }
          })
          commit('CLEAR_REGISTER')
          commit('CLEAR_REGISTER_CUSTOM')
          commit('CLEAR_SUMMARY')
          commit('CLEAR_SUMMARY_CUSTOM')
          commit('CLEAR_SESSION')
          commit('CLEAR_EVENT')
          router.push({ name: 'ebs-session-show', params: { eventId: rootState.route.params.eventId, sessionId: rootState.route.params.sessionId } })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Summary complete',
            message: `Summary hasn't been completed.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    //
    resetSummary (context) {
      context.commit('RESET_SUMMARY')
    },
    //
    setSummaryCompletion ({ commit }, data) {
      commit('SET_SUMMARY_COMPLETION', data)
    },
    setSummaryNotes ({ commit }, data) {
      commit('SET_SUMMARY_NOTES', data)
    }
  }
}

export default ebsSummary
