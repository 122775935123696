import axios from 'axios'
import api from '@/api'

const data = {
  data: [],
  default: []
}

const lmsSuperadminWorkspaceCustomFields = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkspaceCustomFieldsData: state => {
      return state.data
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELDS_GET_DATA (state, payload) {
      state.data = JSON.parse(JSON.stringify(payload))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELDS_CLEAR_DATA (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    }
  },
  actions: {
    lmsSuperadminCustomFieldsGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        let workspaceId

        if (data) {
          if (data.constructor === String) {
            workspaceId = data
          } else {
            if (data.requestData !== undefined && data.requestData !== '') {
              workspaceId = data.requestData
            }
          }
        }

        commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELDS_CLEAR_DATA')

        axios({
          method: 'get',
          url: `${api.sso}/v2/custom-fields/${workspaceId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELDS_GET_DATA', response.data)

          resolve(response.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    //
    lmsSuperadminCustomFieldsClearData ({ commit }) {
      commit('LMS_SUPERADMIN_WORKSPACE_CUSTOM_FIELDS_CLEAR_DATA')
    }
  }
}

export default lmsSuperadminWorkspaceCustomFields
