<template>
  <div class="filter__sort">
    <div class="columns is-variable">
      <div class="column">
        <filter-sort-button
          :class="menuActive ? 'active' : ''"
          @switch-menu="switchMenu"
        />
        <filter-sort-menu
          :class="menuActive ? 'active' : ''"
          v-show="menuActive"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FilterSortButton from '@/components/default/filters/FilterSortButton'
import FilterSortMenu from '@/components/default/filters/FilterSortMenu'

export default {
  data () {
    return {
      menuActive: false
    }
  },
  components: {
    FilterSortMenu,
    FilterSortButton
  },
  name: 'filter-sort',
  props: {
    filters: {
      type: [Array, Object]
    }
  },
  methods: {
    switchMenu: function () {
      this.menuActive = !this.menuActive
    }
  }
}
</script>

<style lang="scss">
  @import "~@/assets/scss/components/ebs/filters/filtersort";
</style>
