<template>
  <div class="header__breadcrumb">
    <div class="container">
      <nav class="header__breadcrumb_nav">
          <a href="" class="header__dashboard-text">{{ $t('header.topAccountNav.titles.dashboard')  }}</a>
          <v-icon name="chevron-right"/>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'header-breadcrumb'
}
</script>

<style lang="scss">
  @import "~@/assets/scss/layout/header/headerbreadcrumb";
</style>
