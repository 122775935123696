import Vue from 'vue'
import Vuex from 'vuex'

import authAdmin from '@/store/modules/auth/authAdmin'
import authUser from '@/store/modules/auth/authUser'
import dashboard from '@/store/modules/dashboard/dashboard'
import defaultNotificationToasts from '@/store/modules/default/defaultNotificationToasts'
import defaultInductionMessages from '@/store/modules/default/defaultInductionMessages'
import defaultWorkspace from '@/store/modules/default/defaultWorkspace'
import defaultCustomerOptions from '@/store/modules/default/defaultCustomerOptions'
import ebsAdmin from '@/store/modules/ebs/ebsAdmin'
import ebsAdmins from '@/store/modules/ebs/ebsAdmins'
import ebsBooking from '@/store/modules/ebs/ebsBooking'
import ebsBookings from '@/store/modules/ebs/ebsBookings'
import ebsCatalogItem from '@/store/modules/ebs/ebsCatalogItem'
import ebsCatalogItemLearnerEnrolments from '@/store/modules/ebs/ebsCatalogItemLearnerEnrolments'
import ebsCatalogItems from '@/store/modules/ebs/ebsCatalogItems'
import ebsCertificate from '@/store/modules/ebs/ebsCertificate'
import ebsCertificates from '@/store/modules/ebs/ebsCertificates'
import ebsCourse from '@/store/modules/ebs/ebsCourse'
import ebsCourses from '@/store/modules/ebs/ebsCourses'
import ebsCourseFeedback from '@/store/modules/ebs/ebsCourseFeedback'
import ebsEnrolment from '@/store/modules/ebs/ebsEnrolment'
import ebsEnrolments from '@/store/modules/ebs/ebsEnrolments'
import ebsEmail from '@/store/modules/ebs/ebsEmail'
import ebsEmails from '@/store/modules/ebs/ebsEmails'
import ebsEvent from '@/store/modules/ebs/ebsEvent'
import ebsEvents from '@/store/modules/ebs/ebsEvents'
import ebsLicence from '@/store/modules/ebs/ebsLicence'
import ebsLicenceConsumptions from '@/store/modules/ebs/ebsLicenceConsumptions'
import ebsLicences from '@/store/modules/ebs/ebsLicences'
import ebsModule from '@/store/modules/ebs/ebsModule'
import ebsModules from '@/store/modules/ebs/ebsModules'
import ebsRequest from '@/store/modules/ebs/ebsRequest'
import ebsRequests from '@/store/modules/ebs/ebsRequests'
import ebsRegister from '@/store/modules/ebs/ebsRegister'
import ebsReportBuilder from '@/store/modules/ebs/ebsReportBuilder'
import ebsReportDashboard from '@/store/modules/ebs/ebsReportDashboard'
import ebsReports from '@/store/modules/ebs/ebsReports'
import ebsRoom from '@/store/modules/ebs/ebsRoom'
import ebsRooms from '@/store/modules/ebs/ebsRooms'
import ebsServices from '@/store/modules/ebs/ebsServices'
import ebsServiceSSO from '@/store/modules/ebs/ebsServiceSSO'
import ebsSession from '@/store/modules/ebs/ebsSession'
import ebsSessionFeedback from '@/store/modules/ebs/ebsSessionFeedback'
import ebsSessionFeedbacks from '@/store/modules/ebs/ebsSessionFeedbacks'
import ebsSessions from '@/store/modules/ebs/ebsSessions'
import ebsSubscription from '@/store/modules/ebs/ebsSubscription'
import ebsSummary from '@/store/modules/ebs/ebsSummary'
import ebsTrainer from '@/store/modules/ebs/ebsTrainer'
import ebsTrainers from '@/store/modules/ebs/ebsTrainers'
import ebsUser from '@/store/modules/ebs/ebsUser'
import ebsUserHistory from '@/store/modules/ebs/ebsUserHistory'
import ebsUsers from '@/store/modules/ebs/ebsUsers'
import ebsVenue from '@/store/modules/ebs/ebsVenue'
import ebsVenues from '@/store/modules/ebs/ebsVenues'
import lmsSuperadminAdmin from '@/store/modules/lms/superadmin/lmsSuperadminAdmin'
import lmsSuperadminAdmins from '@/store/modules/lms/superadmin/lmsSuperadminAdmins'
import lmsSuperadminAuditLog from '@/store/modules/lms/superadmin/lmsSuperadminAuditLog'
import lmsSuperadminCustomer from '@/store/modules/lms/superadmin/lmsSuperadminCustomer'
import lmsSuperadminCustomerContact from '@/store/modules/lms/superadmin/lmsSuperadminCustomerContact'
import lmsSuperadminCustomerEmailSenders from '@/store/modules/lms/superadmin/lmsSuperadminCustomerEmailSenders'
import lmsSuperadminCustomerRoles from '@/store/modules/lms/superadmin/lmsSuperadminCustomerRoles'
import lmsSuperadminCustomerSubscription from '@/store/modules/lms/superadmin/lmsSuperadminCustomerSubscription'
import lmsSuperadminCustomers from '@/store/modules/lms/superadmin/lmsSuperadminCustomers'
import lmsSuperadminDeletedObject from '@/store/modules/lms/superadmin/lmsSuperadminDeletedObject'
import lmsSuperadminDeletedObjects from '@/store/modules/lms/superadmin/lmsSuperadminDeletedObjects'
import lmsSuperadminGroup from '@/store/modules/lms/superadmin/lmsSuperadminGroup'
import lmsSuperadminGroups from '@/store/modules/lms/superadmin/lmsSuperadminGroups'
import lmsSuperadminMasteradmin from '@/store/modules/lms/superadmin/lmsSuperadminMasteradmin'
import lmsSuperadminOrgUnit from '@/store/modules/lms/superadmin/lmsSuperadminOrgUnit'
import lmsSuperadminOrgUnits from '@/store/modules/lms/superadmin/lmsSuperadminOrgUnits'
import lmsSuperadminUser from '@/store/modules/lms/superadmin/lmsSuperadminUser'
import lmsSuperadminUsers from '@/store/modules/lms/superadmin/lmsSuperadminUsers'
import lmsSuperadminUserLearningHistory from '@/store/modules/lms/superadmin/lmsSuperadminUserLearningHistory'
import lmsSuperadminUserProfiles from '@/store/modules/lms/superadmin/lmsSuperadminUserProfiles'
import lmsSuperadminUsersBulk from '@/store/modules/lms/superadmin/lmsSuperadminUsersBulk'
import lmsSuperadminUsersBulkActionsAddToGroups from '@/store/modules/lms/superadmin/lmsSuperadminUsersBulkActionsAddToGroups'
import lmsSuperadminUsersBulkActionsRemoveFromGroups from '@/store/modules/lms/superadmin/lmsSuperadminUsersBulkActionsRemoveFromGroups'
import lmsSuperadminUsersBulkActionsReactivateProfiles from '@/store/modules/lms/superadmin/lmsSuperadminUsersBulkActionsReactivateProfiles'
import lmsSuperadminUsersBulkActionsDeleteProfiles from '@/store/modules/lms/superadmin/lmsSuperadminUsersBulkActionsDeleteProfiles'
import lmsSuperadminUsersBulkActionsResendInvitations from '@/store/modules/lms/superadmin/lmsSuperadminUsersBulkActionsResendInvitations'
import lmsSuperadminUsersBulkActionsCancelInvitations from '@/store/modules/lms/superadmin/lmsSuperadminUsersBulkActionsCancelInvitations'
import lmsSuperadminWorkflow from '@/store/modules/lms/superadmin/lmsSuperadminWorkflow'
import lmsSuperadminWorkflows from '@/store/modules/lms/superadmin/lmsSuperadminWorkflows'
import lmsSuperadminWorkflowsLog from '@/store/modules/lms/superadmin/lmsSuperadminWorkflowsLog'
import lmsSuperadminWorkspace from '@/store/modules/lms/superadmin/lmsSuperadminWorkspace'
import lmsSuperadminWorkspaceApiIntegrationKey from '@/store/modules/lms/superadmin/lmsSuperadminWorkspaceApiIntegrationKey'
import lmsSuperadminWorkspaceApiIntegrationKeys from '@/store/modules/lms/superadmin/lmsSuperadminWorkspaceApiIntegrationKeys'
import lmsSuperadminWorkspaceFeedbackForm from '@/store/modules/lms/superadmin/lmsSuperadminWorkspaceFeedbackForm'
import lmsSuperadminWorkspaceFeedbackForms from '@/store/modules/lms/superadmin/lmsSuperadminWorkspaceFeedbackForms'
import lmsSuperadminWorkspaceRegisterKey from '@/store/modules/lms/superadmin/lmsSuperadminWorkspaceRegisterKey'
import lmsSuperadminWorkspaceRegisterKeys from '@/store/modules/lms/superadmin/lmsSuperadminWorkspaceRegisterKeys'
import lmsSuperadminWorkspaceCustomField from '@/store/modules/lms/superadmin/lmsSuperadminWorkspaceCustomField'
import lmsSuperadminWorkspaceCustomFields from '@/store/modules/lms/superadmin/lmsSuperadminWorkspaceCustomFields'
import lmsSuperadminWorkspaceUserProfile from '@/store/modules/lms/superadmin/lmsSuperadminWorkspaceUserProfile'
import lmsSuperadminWorkspaces from '@/store/modules/lms/superadmin/lmsSuperadminWorkspaces'
import lmsSuperadminWorkspaceUsers from '@/store/modules/lms/superadmin/lmsSuperadminWorkspaceUsers'
import lmsUserAccount from '@/store/modules/lms/user/lmsUserAccount'
import lmsUserAccountActivityLog from '@/store/modules/lms/user/lmsUserAccountActivityLog'
import lmsUserAchievement from '@/store/modules/lms/user/lmsUserAchievement'
import lmsUserAutoenrolment from '@/store/modules/lms/user/lmsUserAutoenrolment'
import lmsUserAutoenrolments from '@/store/modules/lms/user/lmsUserAutoenrolments'
import lmsUserAutoenrolmentsSuggestedCourses from '@/store/modules/lms/user/lmsUserAutoenrolmentsSuggestedCourses'
import lmsUserCustomerworkspaces from '@/store/modules/lms/user/lmsUserCustomerworkspaces'
import lmsUserReward from '@/store/modules/lms/user/lmsUserReward'
import lmsUserRewards from '@/store/modules/lms/user/lmsUserRewards'
import lmsUserAchievements from '@/store/modules/lms/user/lmsUserAchievements'
import lmsUserInvitation from '@/store/modules/lms/user/lmsUserInvitation'
import lmsUserWorkspace from '@/store/modules/lms/user/lmsUserWorkspace'
import lmsUserWorkspaces from '@/store/modules/lms/user/lmsUserWorkspaces'
import storageCancelToken from '@/store/modules/storage/storageCancelToken'
import storageFile from '@/store/modules/storage/storageFile'
import storageFiles from '@/store/modules/storage/storageFiles'
import tagsContent from '@/store/modules/tags/tagsContent'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    authAdmin,
    authUser,
    dashboard,
    defaultNotificationToasts,
    defaultInductionMessages,
    defaultWorkspace,
    defaultCustomerOptions,
    ebsAdmin,
    ebsAdmins,
    ebsBooking,
    ebsBookings,
    ebsCatalogItem,
    ebsCatalogItemLearnerEnrolments,
    ebsCatalogItems,
    ebsCertificate,
    ebsCertificates,
    ebsCourse,
    ebsCourses,
    ebsCourseFeedback,
    ebsEnrolment,
    ebsEnrolments,
    ebsEmail,
    ebsEmails,
    ebsEvent,
    ebsEvents,
    ebsLicence,
    ebsLicenceConsumptions,
    ebsLicences,
    ebsModule,
    ebsModules,
    ebsRequest,
    ebsRequests,
    ebsRegister,
    ebsReportBuilder,
    ebsReportDashboard,
    ebsReports,
    ebsRoom,
    ebsRooms,
    ebsServices,
    ebsServiceSSO,
    ebsSession,
    ebsSessionFeedback,
    ebsSessionFeedbacks,
    ebsSessions,
    ebsSubscription,
    ebsSummary,
    ebsTrainer,
    ebsTrainers,
    ebsUser,
    ebsUserHistory,
    ebsUsers,
    ebsVenue,
    ebsVenues,
    lmsSuperadminAdmin,
    lmsSuperadminAdmins,
    lmsSuperadminAuditLog,
    lmsSuperadminCustomer,
    lmsSuperadminCustomerContact,
    lmsSuperadminCustomerEmailSenders,
    lmsSuperadminCustomerRoles,
    lmsSuperadminCustomerSubscription,
    lmsSuperadminCustomers,
    lmsSuperadminDeletedObject,
    lmsSuperadminDeletedObjects,
    lmsSuperadminGroup,
    lmsSuperadminGroups,
    lmsSuperadminMasteradmin,
    lmsSuperadminOrgUnit,
    lmsSuperadminOrgUnits,
    lmsSuperadminUser,
    lmsSuperadminUsers,
    lmsSuperadminUserLearningHistory,
    lmsSuperadminUserProfiles,
    lmsSuperadminUsersBulk,
    lmsSuperadminUsersBulkActionsAddToGroups,
    lmsSuperadminUsersBulkActionsRemoveFromGroups,
    lmsSuperadminUsersBulkActionsReactivateProfiles,
    lmsSuperadminUsersBulkActionsDeleteProfiles,
    lmsSuperadminUsersBulkActionsResendInvitations,
    lmsSuperadminUsersBulkActionsCancelInvitations,
    lmsSuperadminWorkflow,
    lmsSuperadminWorkflows,
    lmsSuperadminWorkflowsLog,
    lmsSuperadminWorkspace,
    lmsSuperadminWorkspaceApiIntegrationKey,
    lmsSuperadminWorkspaceApiIntegrationKeys,
    lmsSuperadminWorkspaceFeedbackForm,
    lmsSuperadminWorkspaceFeedbackForms,
    lmsSuperadminWorkspaceRegisterKey,
    lmsSuperadminWorkspaceRegisterKeys,
    lmsSuperadminWorkspaceCustomField,
    lmsSuperadminWorkspaceCustomFields,
    lmsSuperadminWorkspaceUserProfile,
    lmsSuperadminWorkspaces,
    lmsSuperadminWorkspaceUsers,
    lmsUserAccount,
    lmsUserAccountActivityLog,
    lmsUserAchievement,
    lmsUserAchievements,
    lmsUserAutoenrolment,
    lmsUserAutoenrolments,
    lmsUserAutoenrolmentsSuggestedCourses,
    lmsUserCustomerworkspaces,
    lmsUserReward,
    lmsUserRewards,
    lmsUserInvitation,
    lmsUserWorkspace,
    lmsUserWorkspaces,
    storageCancelToken,
    storageFile,
    storageFiles,
    tagsContent
  }
})

export default store
