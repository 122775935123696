import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const lmsUserAutoenrolments = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsUserAutoenrolments: state => {
      return state.data
    },
    getLmsUserAutoenrolmentsPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    LMS_USER_AUTOENROLMENTS_SET (state, payload) {
      state.data = payload
    },
    LMS_USER_AUTOENROLMENTS_SET_PAGINATION (state, payload) {
      state.pagination = payload
    },
    LMS_USER_AUTOENROLMENTS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_USER_AUTOENROLMENTS_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsUserAutoenrolmentsGet ({ commit, getters }, data) {
      const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
      const type = data && data.requestData && data.requestData.type ? '&targetType=' + data.requestData.type : ''
      const workspace = data && data.requestData && data.requestData.workspace ? '&workspace=' + data.requestData.workspace : '&workspace=' + getters.getAuthUserSecondStageTokenDataWorkspaceId

      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.learningactivities}/auto-enrolments${queryUrlParams}${type}${workspace}`
        }).then(response => {
          commit('LMS_USER_AUTOENROLMENTS_SET', response.data.data)
          commit('LMS_USER_AUTOENROLMENTS_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsUserAutoenrolmentsClear ({ commit }) {
      commit('LMS_USER_AUTOENROLMENTS_CLEAR')
      commit('LMS_USER_AUTOENROLMENTS_CLEAR_PAGINATION')
    }
  }
}

export default lmsUserAutoenrolments
