<template>
    <div class="filter__checkbox">
        <form-checkbox
            :label="$t('lms.mediaLibrary.componentLibrary.selectMultimedia')"
            :name="'filter-checkbox'"
            v-model="checkboxValue"
        />
    </div>
</template>

<script>
import FormCheckbox from '@/components/default/forms/FormCheckbox'

export default {
  name: 'filter-checkbox',
  components: { FormCheckbox },
  data () {
    return {
      checkboxValue: false
    }
  },
  watch: {
    checkboxValue (value) {
      this.$emit('emit-input', value)
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/ebs/filters/filtercheckbox";
</style>
