<template>
    <div class="pop__table_grid">
        <component-table
            :key="rerenderIndex"
            :checkbox="componentTableCheckbox"
            :checkbox-disabled="componentTableAllowSingle"
            :allow-single="componentTableAllowSingle"
            :labels="labels"
            :checkbox-name="componentTableCheckboxName"
            :list-emit-name="eventbusEmitName"
            :items="items"
            :component-table-item="PopTableGridItem"
            :selected="selected"
            :disallow="disallow"
            @emit-check="handleEmit"
            @emit-sort="handleSort"
        ></component-table>
    </div>
</template>

<script>
import ComponentTable from '@/components/default/shared/ComponentTable'
import PopTableGridItem from '@/components/default/shared/PopTableGridItem'

export default {
  name: 'pop-table-grid',
  components: { ComponentTable },
  data () {
    return {
      rerenderIndex: 0,
      PopTableGridItem
    }
  },
  props: {
    disallow: {
      type: [Array, String, Number]
    },
    componentTableAllowSingle: {
      type: Boolean,
      default: false
    },
    eventbusEmitName: {
      type: String,
      default: 'pop-table'
    },
    items: {
      required: true
    },
    labels: {
      type: [Array, Object],
      default: () => {
      },
      required: true
    },
    componentTableCheckbox: {
      type: Boolean,
      default: false
    },
    componentTableCheckboxName: {
      type: String
    },
    selected: {
      type: [Array, Object]
    }
  },
  watch: {
    items () {
      this.rerenderIndex++
    }
  },
  methods: {
    handleEmit (data) {
      const value = data.data
      const item = data.item
      this.$emit('emit-check', { value, item })
    },
    handleSort (data) {
      this.$emit('emit-sort', data)
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/poptablegrid";
</style>
