<template>
    <div class="list list__table_head_check">
        <label
            :for="name"
        >
            <input
                type="checkbox"
                :id="name | toKebabCase"
                :disabled="disabled"
                :checked="checked"
                @click="emitChecked"
            >
            <span></span>
        </label>
    </div>
</template>

<script>
export default {
  name: 'list-table-head-check',
  props: {
    name: {
      type: String,
      default: 'list-table-head'
    },
    emitListName: {
      type: String,
      default: 'list-action'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitChecked: function (event) {
      this.$eventBus.$emit(this.emitListName, event.target.checked)
    }
  }
}
</script>
