import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    id: '',
    user: {
      id: '',
      emailAddress: '',
      firstName: '',
      lastName: ''
    }
  },
  default: ''
}

const lmsUserInvitation = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsUserInvitationUserId: state => {
      return state.data.user.id
    }
  },
  mutations: {
    LMS_USER_INVITATION_GET (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    //
    LMS_USER_WORKSPACE_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    LMS_USER_WORKSPACE_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    }
  },
  actions: {
    lmsUserInvitationGet (ctx, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.sso}/v2/invitations/${id}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsUserInvitationAccept ({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.sso}/v2/accept-invitation/${id}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Invitation accept',
            message: `Invitation has been successfully accepted.`,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          reject(new Error(error))
        })
      })
    },
    lmsUserInvitationReject ({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.sso}/v2/reject-invitation/${id}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Invitation reject',
            message: `Invitation has been successfully rejected.`,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          reject(new Error(error))
        })
      })
    }
  }
}

export default lmsUserInvitation
