import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { User } from '@/User'

const ebsRegister = {
  state: {
    data: [
      {
        absenceReason: '',
        absenceReasonValid: '',
        attendance: '',
        bookingForm: [],
        email: '',
        firstName: '',
        lastName: '',
        fullName: '',
        id: '',
        learnerId: '',
        notes: '',
        occurrenceItemId: '',
        status: ''
      }
    ],
    default: [],
    custom: {
      requestLoaded: false
    }
  },
  getters: {
    getRegister: state => {
      return state.data
    },
    getRegisterCountAll: state => {
      const count = state.data.length
      return count !== 0 ? count : '0'
    },
    getRegisterCountPresent: state => {
      const count = state.data.filter(obj => {
        return obj.attendance === true
      }).length
      return count !== 0 ? count : '0'
    },
    getRegisterCountAbsent: state => {
      const count = state.data.filter(obj => {
        return obj.attendance === false
      }).length
      return count !== 0 ? count : '0'
    },
    getRegisterId: state => {
      if (!state.data.length) return null
      return state.data[0].occurrenceItemId
    },
    getRegisterStatus: state => {
      if (!state.data.length) return null
      return state.data[0].status
    },
    getRegisterAttendanceRegistryFilled: (state, getters) => {
      const sessionOccurrences = getters.getSessionOccurrenceItems
      const sessionItemId = getters.getRegisterId
      const sessionItemData = sessionOccurrences.find(obj => obj.id === sessionItemId)

      return sessionItemData ? sessionItemData.attendanceRegistryFilled : false
    },
    getRegisterAttendanceRegistryCompleted: (state, getters) => {
      const sessionOccurrences = getters.getSessionOccurrenceItems
      const sessionItemId = getters.getRegisterId
      const sessionItemData = sessionOccurrences.find(obj => obj.id === sessionItemId)

      return sessionItemData ? sessionItemData.attendanceRegistryCompleted : false
    },
    getRegisterAllowEdit: (state, getters) => {
      const user = new User()

      const sessionEventCoordinators = getters.getSessionEventCoordinatorsId
      const sessionTrainers = getters.getSessionTrainers
      const completionType = getters.getSessionCompletionType
      const enabledRegisters = getters.getSessionCompletionEnableRegisters

      const isEventCoordinator = user.isEventCoordinator()
      const isSessionEventCoordinator = user.isSessionEventCoordinator(sessionEventCoordinators)
      const isSessionTrainer = user.isSessionTrainer(sessionTrainers)
      const isRegisterCompleted = getters.getRegisterAttendanceRegistryCompleted

      const allowEditByAnyCoordinator = getters.getSessionCompletionRegistersEditByAnyCoordinator
      const allowEditByTrainer = getters.getSessionCompletionRegistersEditByTrainers

      return (
        !isRegisterCompleted &&
      (
        (allowEditByAnyCoordinator && isEventCoordinator) ||
      (!allowEditByAnyCoordinator && isSessionEventCoordinator) ||
      (allowEditByTrainer && isSessionTrainer)
      ) && (
          (completionType === 'attendance') ||
      (completionType === 'assessment' && enabledRegisters)
        )
      )
    },
    getRegisterAllowDisplayComplete: (state, getters) => {
      const registerAllowEdit = getters.getRegisterAllowEdit
      const isRegisterCompleted = getters.getRegisterAttendanceRegistryCompleted

      return (
        registerAllowEdit &&
      !isRegisterCompleted
      )
    },
    getRegisterAllowPerformComplete: (state, getters) => {
      const registerAllowEdit = getters.getRegisterAllowEdit
      const isRegisterFilled = getters.getRegisterAttendanceRegistryFilled
      const isRegisterCompleted = getters.getRegisterAttendanceRegistryCompleted
      const status = getters.getSessionStatus

      return (
        registerAllowEdit &&
      isRegisterFilled &&
      !isRegisterCompleted &&
      status === 'ended'
      )
    },
    //
    getRegisterCustomRequestLoaded: (state) => {
      return state.custom.requestLoaded
    }
  },
  mutations: {
    GET_REGISTER: (state, payload) => {
      let stateData = JSON.parse(JSON.stringify(payload))

      for (let item of stateData) {
        item.fullName = item.firstName + ' ' + item.lastName
      }

      state.data = stateData
      state.default = JSON.parse(JSON.stringify(stateData))
    },
    //
    CLEAR_REGISTER: (state) => {
      state.data = [
        {
          absenceReason: '',
          absenceValid: '',
          attendance: '',
          bookingForm: [],
          email: '',
          firstName: '',
          id: '',
          lastName: '',
          learnerId: '',
          notes: '',
          occurrenceItemId: '',
          status: ''
        }
      ]
    },
    CLEAR_REGISTER_CUSTOM: (state) => {
      state.custom = {
        requestLoaded: false
      }
    },
    RESET_REGISTER (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    SET_REGISTER_ATTENDANCE: (state, payload) => {
      let entries = state.data
      const selectedEntryIndex = entries && entries.length ? entries.findIndex(obj => obj.learnerId === payload.index) : -1

      if (selectedEntryIndex > -1) {
        state.data[selectedEntryIndex].attendance = payload.value
      }
    },
    SET_REGISTER_ABSENCEREASON: (state, payload) => {
      let entries = state.data
      const selectedEntryIndex = entries && entries.length ? entries.findIndex(obj => obj.learnerId === payload.index) : -1

      if (selectedEntryIndex > -1) {
        state.data[selectedEntryIndex].absenceReason = payload.value.value
      }
    },
    SET_REGISTER_ABSENCEREASONVALID: (state, payload) => {
      let entries = state.data
      const selectedEntryIndex = entries && entries.length ? entries.findIndex(obj => obj.learnerId === payload.index) : -1

      if (selectedEntryIndex > -1) {
        state.data[selectedEntryIndex].absenceReasonValid = payload.value
      }
    },
    SET_REGISTER_NOTES: (state, payload) => {
      let entries = state.data
      const selectedEntryIndex = entries && entries.length ? entries.findIndex(obj => obj.learnerId === payload.index) : -1

      if (selectedEntryIndex > -1) {
        state.data[selectedEntryIndex].notes = payload.value
      }
    },
    //
    SET_REGISTER_CUSTOM_REQUESTLOADED: (state, payload) => {
      state.custom.requestLoaded = payload
    }
  },
  actions: {
    getRegister ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_REGISTER')
        commit('CLEAR_REGISTER_CUSTOM')
        axios({
          method: 'get',
          url: `${api.classroom}/digital-register-list/${id}`
        }).then(response => {
          commit('GET_REGISTER', response.data)
          commit('SET_REGISTER_CUSTOM_REQUESTLOADED', true)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    createRegister ({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.classroom}/digital-register-bulk`,
          data: state.data
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Register create',
            message: `Register has been successfully created.`,
            status: 'success'
          })
          commit('CLEAR_REGISTER')
          commit('CLEAR_REGISTER_CUSTOM')
          commit('CLEAR_SUMMARY')
          commit('CLEAR_SUMMARY_CUSTOM')
          commit('CLEAR_SESSION')
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Register create',
            message: `Register hasn't been created.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    completeRegister ({ commit, getters, rootState, dispatch }) {
      return new Promise((resolve, reject) => {
        const sessionId = getters.getSessionId
        const sessionItemId = getters.getRegisterId
        axios({
          method: 'post',
          url: `${api.classroom}/occurrence/commit/${sessionId}/attendance/${sessionItemId}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Register complete',
            message: `Register has been successfully completed.`,
            status: 'success',
            linkText: 'Go to register',
            linkTo: {
              name: 'ebs-session-register',
              params: { eventId: rootState.route.params.eventId, sessionId: rootState.route.params.sessionId, registerId: rootState.route.params.registerId }
            }
          })
          commit('CLEAR_REGISTER')
          commit('CLEAR_REGISTER_CUSTOM')
          commit('CLEAR_SUMMARY')
          commit('CLEAR_SUMMARY_CUSTOM')
          router.push({ name: 'ebs-session-show', params: { eventId: rootState.route.params.eventId, sessionId: rootState.route.params.sessionId } })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Register complete',
            message: `Register hasn't been completed.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    //
    resetRegister (context) {
      context.commit('RESET_REGISTER')
    },
    //
    setRegisterAttendance ({ commit }, data) {
      commit('SET_REGISTER_ATTENDANCE', data)
    },
    setRegisterAbsenceReason ({ commit }, data) {
      commit('SET_REGISTER_ABSENCEREASON', data)
    },
    setRegisterAbsenceReasonValid ({ commit }, data) {
      commit('SET_REGISTER_ABSENCEREASONVALID', data)
    },
    setRegisterNotes ({ commit }, data) {
      commit('SET_REGISTER_NOTES', data)
    }
  }
}

export default ebsRegister
