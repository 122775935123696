<template>
    <div :class="[
        'component',
        'component__label_list'
    ]">
        <div v-if="title" class="component__label_list_title">{{ title }}</div>
        <ul v-if="items && items.length === 1 && wrapLimit === 1" class="component__label_list_items">
            <li
                class="component__label_list_items_item"
                v-for="(item, index) in items"
                :key="index"
            >
                <component-label :truncate="18" :label="item"></component-label>
            </li>
        </ul>
        <component-button
            v-if="items && items.length > wrapLimit"
            class="component__label_list_readmorebutton"
            :name="'component-label-list-readmore-button'"
            :text="true"
            :underline="true"
            :theme="'grey'"
            :size="'tiny'"
        >{{ `${items.length}` }} {{ itemName.constructor === String ? (items.length === 1 ? itemName : itemName + 's') : (items.length === 1 ? itemName[0] : itemName[1]) }}</component-button>
        <div v-show="false" class="component__label_list_popupbox">
            <ul v-if="items ? items.length : false" class="component__label_list_popupbox_items">
                <li
                    class="component__label_list_popupbox_items_item"
                    v-for="(item, index) in items"
                    :key="index"
                >
                    <component-label :label="item"></component-label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import ComponentLabel from '@/components/default/shared/ComponentLabel.vue'

export default {
  name: 'component-label-list',
  components: {
    ComponentLabel,
    ComponentButton: () => import('@/components/default/shared/ComponentButton')
  },
  props: {
    title: {
      type: String
    },
    items: {
      type: Array
    },
    wrapLimit: {
      type: Number,
      default: 1
    },
    itemName: {
      type: [String, Array],
      default: 'label'
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentlabellist";
</style>
