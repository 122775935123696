import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { checkResponseNotFound } from '@/interceptorsHelpers'

const lmsSuperadminCustomerContact = {
  state: {
    data: {
      address: '',
      customerId: '',
      email: '',
      id: '',
      name: '',
      notes: '',
      phone: ''
    },
    default: {}
  },
  getters: {
    getLmsSuperadminCustomerContact: state => {
      return state.data
    },
    getLmsSuperadminCustomerContactAddress: state => {
      return state.data.address
    },
    getLmsSuperadminCustomerContactCustomerid: state => {
      return state.data.customerId
    },
    getLmsSuperadminCustomerContactEmail: state => {
      return state.data.email
    },
    getLmsSuperadminCustomerContactId: state => {
      return state.data.id
    },
    getLmsSuperadminCustomerContactName: state => {
      return state.data.name
    },
    getLmsSuperadminCustomerContactNotes: state => {
      return state.data.notes
    },
    getLmsSuperadminCustomerContactPhone: state => {
      return state.data.phone
    }
  },
  mutations: {
    LMS_SUPERADMIN_CUSTOMER_GET_CONTACT (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    //
    LMS_SUPERADMIN_CUSTOMER_CLEAR_CONTACT (state) {
      state.data = {
        address: '',
        customerId: '',
        email: '',
        id: '',
        name: '',
        notes: '',
        phone: ''
      }
      state.default = JSON.parse(JSON.stringify(state.data))
    },
    LMS_SUPERADMIN_CUSTOMER_RESET_CONTACT (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_ADDRESS (state, payload) {
      state.data.address = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_CUSTOMERID (state, payload) {
      state.data.customerId = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_EMAIL (state, payload) {
      state.data.email = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_NAME (state, payload) {
      state.data.name = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_NOTES (state, payload) {
      state.data.notes = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_PHONE (state, payload) {
      state.data.phone = payload
    }
  },
  actions: {
    lmsSuperadminCustomerContactClear (context) {
      context.commit('LMS_SUPERADMIN_CUSTOMER_CLEAR_CONTACT')
    },
    lmsSuperadminCustomerContactReset (context) {
      context.commit('LMS_SUPERADMIN_CUSTOMER_RESET_CONTACT')
    },
    //
    lmsSuperadminCustomerContactGet (context, id) {
      context.commit('LMS_SUPERADMIN_CUSTOMER_CLEAR_CONTACT')
      axios({
        method: 'get',
        url: `${api.subscriptionmanager}/customer-contacts/${id}`
      }).then(response => {
        context.commit('LMS_SUPERADMIN_CUSTOMER_GET_CONTACT', response.data)
      }).catch(function (error) {
        checkResponseNotFound(error.response.status)
      })
    },
    lmsSuperadminCustomerContactRemove ({ commit, getters }, id) {
      const contactId = id || getters.getLmsSuperadminCustomerContactId
      const customerId = String(getters.getLmsSuperadminCustomerId)
      return axios.delete(
        `${api.subscriptionmanager}/customer-contacts/${contactId}`
      ).then(function () {
        commit('LMS_SUPERADMIN_CUSTOMER_CLEAR_CONTACT')
        if (id === undefined) {
          router.push({ name: 'lms-superadmin-customer-edit', params: { id: customerId } })
        }
      })
    },
    lmsSuperadminCustomerContactUpdate ({ commit, getters, state, dispatch }, redirect = true) {
      return new Promise((resolve, reject) => {
        const customerId = String(getters.getLmsSuperadminCustomerId)
        return axios({
          method: 'patch',
          url: `${api.subscriptionmanager}/customer-contacts`,
          data: state.data
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Contact update',
            message: 'Contact has been successfully updated.',
            status: 'success'
          })
          commit('LMS_SUPERADMIN_CUSTOMER_CLEAR_CONTACT')
          commit('LMS_SUPERADMIN_CUSTOMER_CLEAR')
          if (redirect) {
            router.push({ name: 'lms-superadmin-customer-edit-contacts', params: { id: customerId } })
          }
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Contact update',
            message: `Contact hasn't been updated.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminCustomerContactCreate ({ commit, state, getters, dispatch }, redirect = true) {
      return new Promise((resolve, reject) => {
        const customerId = String(getters.getLmsSuperadminCustomerId)
        dispatch('lmsSuperadminCustomerContactSetCustomerid', customerId)
        return axios({
          method: 'post',
          url: `${api.subscriptionmanager}/customer-contacts`,
          data: state.data
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Contact create',
            message: 'Contact has been successfully created.',
            status: 'success'
          })
          commit('LMS_SUPERADMIN_CUSTOMER_CLEAR_CONTACT')
          commit('LMS_SUPERADMIN_CUSTOMER_CLEAR')
          if (redirect) {
            router.push({ name: 'lms-superadmin-customer-edit-contacts', params: { id: customerId } })
          }
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Contact create',
            message: `Contact hasn't been created.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    //
    lmsSuperadminCustomerContactSetAddress ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_ADDRESS', value)
    },
    lmsSuperadminCustomerContactSetCustomerid ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_CUSTOMERID', value)
    },
    lmsSuperadminCustomerContactSetEmail ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_EMAIL', value)
    },
    lmsSuperadminCustomerContactSetId ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_ID', value)
    },
    lmsSuperadminCustomerContactSetName ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_NAME', value)
    },
    lmsSuperadminCustomerContactSetNotes ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_NOTES', value)
    },
    lmsSuperadminCustomerContactSetPhone ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_CONTACT_PHONE', value)
    }
  }
}

export default lmsSuperadminCustomerContact
