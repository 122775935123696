import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  custom: {
    externalTrainings: []
  },
  default: ''
}

const ebsCatalogItems = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsCatalogItems: state => {
      return state.data
    },
    getEbsCatalogItemsMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.name,
          value: item.id
        }
      })
    },
    getEbsCatalogItemsMappedIdNameStatus: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name
        }
      })
    },
    getEbsCatalogItemsMappedLabelValueExternalTrainings: state => {
      const externalTrainings = state.custom.externalTrainings
      let rawItems = JSON.parse(JSON.stringify(state.data))

      if (!rawItems.length) return []

      for (let training of externalTrainings) {
        const trainingMappingId = training.mappingCatalogItem
        if (trainingMappingId) {
          const rawItemMappedIndex = rawItems.findIndex(obj => obj.id === trainingMappingId)
          if (rawItemMappedIndex > -1) {
            rawItems[rawItemMappedIndex].externalTrainings = rawItems[rawItemMappedIndex].externalTrainings || []
            rawItems[rawItemMappedIndex].externalTrainings.push(training)
          }
        }
      }

      return rawItems.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.externalTrainings && item.externalTrainings.length ? `${item.name} <span>Related to ${item.externalTrainings.length} external ${item.externalTrainings.length === 1 ? 'record' : 'records'}</span>` : item.name,
          value: item.id
        }
      })
    },
    getEbsCatalogItemsMappedIdNameStatusExternalTrainings: state => {
      const externalTrainings = state.custom.externalTrainings
      let rawItems = JSON.parse(JSON.stringify(state.data))

      if (!rawItems.length) return []

      for (let training of externalTrainings) {
        const trainingMappingId = training.mappingCatalogItem
        if (trainingMappingId) {
          const rawItemMappedIndex = rawItems.findIndex(obj => obj.id === trainingMappingId)
          if (rawItemMappedIndex > -1) {
            rawItems[rawItemMappedIndex].externalTrainings = rawItems[rawItemMappedIndex].externalTrainings || []
            rawItems[rawItemMappedIndex].externalTrainings.push(training.courseName)
          }
        }
      }

      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          externalTrainings: item.externalTrainings
        }
      })
    },
    getEbsCatalogItemsPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    EBS_CATALOGITEMS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    EBS_CATALOGITEMS_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    },
    EBS_CATALOGITEMS_SET_DATA (state, payload) {
      state.data = payload
    },
    EBS_CATALOGITEMS_SET_CUSTOM (state, payload) {
      state.custom = payload
    },
    EBS_CATALOGITEMS_SET_PAGINATION (state, payload) {
      state.pagination = payload
    }
  },
  actions: {
    ebsCatalogItemsGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        let queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const workspaceId = data.requestData && data.requestData.workspaceId ? (data.requestData.workspaceId !== undefined ? data.requestData.workspaceId : '') : ''
        const unitId = data.requestData && data.requestData.unitId ? (data.requestData.unitId !== undefined ? data.requestData.unitId : '') : ''
        const externalTrainings = data.requestData && data.requestData.externalTrainings ? data.requestData.externalTrainings : ''
        queryUrlParams += externalTrainings ? '&externalTrainings=1' : ''

        commit('EBS_CATALOGITEMS_CLEAR')
        commit('EBS_CATALOGITEMS_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog/learner${workspaceId ? '/' + workspaceId : ''}${unitId ? '/' + unitId : ''}${queryUrlParams}`
        }).then(response => {
          commit('EBS_CATALOGITEMS_SET_DATA', response.data.data)
          commit('EBS_CATALOGITEMS_SET_CUSTOM', { externalTrainings: response.data.externalTrainings })
          commit('EBS_CATALOGITEMS_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemsGetReleased ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        commit('EBS_CATALOGITEMS_CLEAR')
        commit('EBS_CATALOGITEMS_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/courses/latest-released${queryUrlParams}`
        }).then(response => {
          commit('EBS_CATALOGITEMS_SET_DATA', response.data.data)
          commit('EBS_CATALOGITEMS_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemsGetOldLMS ({ commit, getters }) {
      axios({
        method: 'post',
        url: `${api.courseCatalog}/courses/find`,
        data: {
          domain: getters.getAuthUserSecondStageTokenDataDomain,
          status: 0,
          simplified: true
        }
      }).then(function (response) {
        commit('EBS_CATALOGITEMS_SET_DATA', response.data)
      })
    }
  }
}

export default ebsCatalogItems
