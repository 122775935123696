import axios from 'axios'
import api from '@/api'

const data = {
  data: '',
  default: ''
}

const tagsContent = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getTagsContent: state => {
      return state.data
    },
    getTagsContentMapped: state => {
      const tags = state.data || []
      return tags.map(obj => {
        return {
          label: obj.name,
          value: obj.tag
        }
      })
    }
  },
  mutations: {
    TAGS_CONTENT_GET (state, payload) {
      state.data = payload
    }
  },
  actions: {
    tagsContentGet ({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.learningactivities}/content-tags`
        }).then(response => {
          commit('TAGS_CONTENT_GET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default tagsContent
