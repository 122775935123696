import axios from 'axios'
import api from '@/api'

const data = {
  data: [],
  default: [],
  custom: {
    basicDefaultEmailAddress: '@melearning.co.uk'
  }
}

const lmsSuperadminCustomerEmailSenders = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminCustomerEmailSenders: state => {
      let itemsArr = JSON.parse(JSON.stringify(state.data))
      return itemsArr
    },
    getLmsSuperadminCustomerEmailSendersDefault: state => {
      let emailSenders = JSON.parse(JSON.stringify(state.data))
      let defaultEmailSender = emailSenders.find(obj => obj.default === true)
      return defaultEmailSender || { emailAddress: state.custom.basicDefaultEmailAddress }
    }
  },
  mutations: {
    LMS_SUPERADMIN_CUSTOMER_GET_EMAILSENDERS (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    //
    LMS_SUPERADMIN_CUSTOMER_CLEAR_EMAILSENDERS (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    LMS_SUPERADMIN_CUSTOMER_RESET_EMAILSENDERS (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    LMS_SUPERADMIN_CUSTOMER_SET_EMAILSENDERS_ADDRESS (state, payload) {
      state.data.address = payload
    }
  },
  actions: {
    lmsSuperadminCustomerEmailSendersClear (context) {
      context.commit('LMS_SUPERADMIN_CUSTOMER_CLEAR_EMAILSENDERS')
    },
    lmsSuperadminCustomerEmailSendersReset (context) {
      context.commit('LMS_SUPERADMIN_CUSTOMER_RESET_EMAILSENDERS')
    },
    //
    lmsSuperadminCustomerEmailSendersGet ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        commit('LMS_SUPERADMIN_CUSTOMER_CLEAR_EMAILSENDERS')

        const customerId = data.requestData.customerId

        axios({
          method: 'get',
          url: `${api.emailservice}/email-senders-list/${customerId}`
        }).then(response => {
          let itemsArr = JSON.parse(JSON.stringify(response.data))
          itemsArr.unshift({
            emailAddress: state.custom.basicDefaultEmailAddress,
            status: 'verified'
          })
          commit('LMS_SUPERADMIN_CUSTOMER_GET_EMAILSENDERS', itemsArr)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminCustomerEmailSendersCreate ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const customerId = data.customerId
        const emailAddress = data.emailAddress

        axios({
          method: 'post',
          url: `${api.emailservice}/email-sender`,
          data: {
            customerId,
            emailAddress
          }
        }).then(response => {
          const emailSenderId = response.data.id
          dispatch('lmsSuperadminCustomerEmailSendersVerify', emailSenderId).then(() => {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Email sender create',
              message: `Email sender has been successfully created. Check sender's inbox to verify an email address.`,
              status: 'success'
            })
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email sender create',
            message: `Email sender hasn't been created.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminCustomerEmailSendersSetDefault ({ dispatch }, emailId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.emailservice}/email-sender-default/${emailId}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email sender make default',
            message: `Email sender has been successfully set as default.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email sender make default',
            message: `Email sender hasn't been set as default.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminCustomerEmailSendersUnsetDefault ({ dispatch }, emailId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${api.emailservice}/email-sender-clear/${emailId}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email sender unset from default',
            message: `Email sender has been successfully unset from default.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email sender unset from default',
            message: `Email sender hasn't been unset from default.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminCustomerEmailSendersRemove ({ dispatch }, emailId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${api.emailservice}/email-sender/${emailId}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email sender remove',
            message: `Email sender has been successfully removed.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email sender remove',
            message: `Email sender hasn't been removed.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminCustomerEmailSendersVerify ({ dispatch }, emailId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.emailservice}/verify-email-sender/${emailId}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email sender verify',
            message: `Email sender received a verification message. Please check an sender's inbox.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email sender verify',
            message: `Email sender hasn't received a verification message. Please try again or contact an administrator.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminCustomerEmailSendersTest ({ dispatch }, emailId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.emailservice}/test-email-sender/${emailId}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email sender test',
            message: `Email sender received a test message. Please check an sender's inbox.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email sender test',
            message: `Email sender hasn't received a test message. Please try again or contact an administrator.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    //
    lmsSuperadminCustomerEmailSendersSetAddress ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_EMAILSENDERS_ADDRESS', value)
    }
  }
}

export default lmsSuperadminCustomerEmailSenders
