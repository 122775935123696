const defaultNotificationToasts = {
  state: {
    data: []
  },
  getters: {
    getDefaultNotificationToasts: state => {
      return state.data
    }
  },
  mutations: {
    DEFAULT_NOTIFICATION_TOASTS_ADD (state, payload) {
      let toasts = state.data
      const notificationId = payload.id
      const existingToastByIdIndex = notificationId ? toasts.findIndex(obj => obj.id === notificationId) : -1
      const existingToastByTitleIndex = toasts && toasts.length ? toasts.findIndex(obj => obj.title === payload.title && obj.status === payload.status) : false

      if (existingToastByTitleIndex !== -1 && payload.status === 'error') {
        state.data.splice(existingToastByTitleIndex, 1)
      }

      if (notificationId && existingToastByIdIndex > -1) {
        toasts[existingToastByIdIndex].status = payload.status !== undefined ? payload.status : toasts[existingToastByIdIndex].status
        toasts[existingToastByIdIndex].title = payload.title !== undefined ? payload.title : toasts[existingToastByIdIndex].title
        toasts[existingToastByIdIndex].message = payload.message !== undefined ? payload.message : toasts[existingToastByIdIndex].message
        toasts[existingToastByIdIndex].iconHidden = payload.iconHidden !== undefined ? payload.iconHidden : toasts[existingToastByIdIndex].iconHidden
        toasts[existingToastByIdIndex].iconName = payload.iconName !== undefined ? payload.iconName : toasts[existingToastByIdIndex].iconName
        toasts[existingToastByIdIndex].linkTo = payload.linkTo !== undefined ? payload.linkTo : toasts[existingToastByIdIndex].linkTo
        toasts[existingToastByIdIndex].linkText = payload.linkText !== undefined ? payload.linkText : toasts[existingToastByIdIndex].linkText
        toasts[existingToastByIdIndex].preventClose = payload.preventClose !== undefined ? payload.preventClose : toasts[existingToastByIdIndex].preventClose
        toasts[existingToastByIdIndex].position = payload.position !== undefined ? payload.position : toasts[existingToastByIdIndex].position
      } else {
        state.data.push({
          id: payload.id,
          status: payload.status,
          title: payload.title,
          message: payload.message,
          iconHidden: payload.iconHidden,
          iconName: payload.iconName,
          linkTo: payload.linkTo,
          linkText: payload.linkText,
          preventClose: payload.preventClose,
          position: payload.position
        })
      }
    },
    DEFAULT_NOTIFICATION_TOASTS_REMOVE (state, payload) {
      if (state.data[payload]) {
        state.data[payload].status = ''
        state.data[payload].title = ''
        state.data[payload].message = ''
        state.data[payload].iconHidden = ''
        state.data[payload].iconName = ''
        state.data[payload].linkTo = ''
        state.data[payload].linkText = ''
        state.data[payload].preventClose = ''
        state.data[payload].position = ''
      }
    },
    DEFAULT_NOTIFICATION_TOAST_CLEAR: (state) => {
      state.data = []
    },
    DEFAULT_NOTIFICATION_TOAST_CLEAR_EMPTY: (state) => {
      const toasts = state.data

      const recursiveLoopForLastArrayItem = (index) => {
        if (
          index > -1 &&
        (
          toasts[index].title === '' ||
        toasts[index].title === undefined
        )
        ) {
          toasts.splice(index, 1)
          recursiveLoopForLastArrayItem(index - 1)
        }
      }

      recursiveLoopForLastArrayItem(toasts.length - 1)
    }
  },
  actions: {
    defaultNotificationToastsAdd ({ commit }, payload) {
      commit('DEFAULT_NOTIFICATION_TOASTS_ADD', payload)
    },
    defaultNotificationToastsRemove ({ commit }, payload) {
      commit('DEFAULT_NOTIFICATION_TOASTS_REMOVE', payload)
      commit('DEFAULT_NOTIFICATION_TOAST_CLEAR_EMPTY')
    },
    defaultNotificationToastsClear ({ commit }) {
      commit('DEFAULT_NOTIFICATION_TOAST_CLEAR')
    }
  }
}

export default defaultNotificationToasts
