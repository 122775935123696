<template>
    <div :class="['component', 'component__emptystate', size ? 'component__emptystate--' + size : '']">
        <div class="container">
            <div class="component__emptystate_box">
                <div class="columns fullheight is-vcentered">
                    <div class="column has-text-centered">
                        <div
                            v-if="titleOutput"
                            class="component__emptystate_box_title"
                        >{{ titleOutput }}</div>
                        <div
                            v-if="content"
                            class="component__emptystate_box_content"
                        >{{ content }}</div>
                        <template v-if="submitButtonActive">
                            <div class="component__emptystate_box_actions">
                                <component-button
                                    :theme="'default'"
                                    :name="'component-emptystate-box-actions-create'"
                                    @click="submit"
                                >{{ submitButtonText }}</component-button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'component-empty-state',
  components: {
    ComponentButton: () => import('@/components/default/shared/ComponentButton')
  },
  props: {
    item: {
      type: String,
      default: 'items'
    },
    title: {
      type: String
    },
    content: {
      type: String
    },
    submitButtonActive: {
      type: Boolean,
      default: true
    },
    submitButtonText: {
      type: String,
      default: 'Submit'
    },
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    titleOutput () {
      const title = this.title

      if (title !== undefined) {
        return title
      } else {
        return `No results`
      }
    }
  },
  methods: {
    submit: function () {
      this.$emit('emit-submit')
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentemptystate";
</style>
