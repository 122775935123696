import axios from 'axios'
import api from '@/api'
import { fromUTCDateTimeToLocalISOCustom, sortByKey, toCapitalize } from '@/functions'

const data = {
  data: {
    calendarReport: '',
    calendarReportFilters: {},
    generatedReport: {
      filters: '',
      data: {
        labels: '',
        data: ''
      },
      pagination: ''
    },
    fetchReport: {
      filters: {}
    },
    definedReports: []
  }
}

const ebsReports = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getReportCalendar: state => {
      return state.data.calendarReport
    },
    getEbsReportsDefinedReports: state => {
      return state.data.definedReports
    },
    getReportGeneratedReportFilters: state => {
      return state.data.generatedReport.filters
    },
    getReportGeneratedReportData: state => {
      return state.data.generatedReport.data
    },
    getReportGeneratedReportPagination: state => {
      return state.data.generatedReport.pagination
    },
    getReportFetchReportFilters: state => {
      return state.data.fetchReport.filters
    }
  },
  mutations: {
    SET_REPORT_CALENDAR (state, payload) {
      const events = []

      const reformatEventDateTime = (eventData, extraData) => {
        eventData.dateFrom = fromUTCDateTimeToLocalISOCustom(eventData.dateFrom, true)
        eventData.dateTo = fromUTCDateTimeToLocalISOCustom(eventData.dateTo, true)

        if (eventData.allOccurrences?.length) {
          for (let j = 0; j < eventData.allOccurrences.length; j++) {
            eventData.allOccurrences[j].dateFrom = fromUTCDateTimeToLocalISOCustom(eventData.allOccurrences[j].dateFrom, true)
            eventData.allOccurrences[j].dateTo = fromUTCDateTimeToLocalISOCustom(eventData.allOccurrences[j].dateTo, true)
          }
        } else {
          eventData = { ...extraData, ...eventData }
          eventData.allOccurrences = extraData.allOccurrences
        }

        if (eventData.occurrences?.length) {
          for (let k = 0; k < eventData.occurrences.length; k++) {
            eventData.occurrences[k].dateFrom = fromUTCDateTimeToLocalISOCustom(eventData.occurrences[k].dateFrom, true)
            eventData.occurrences[k].dateTo = fromUTCDateTimeToLocalISOCustom(eventData.occurrences[k].dateTo, true)
          }
        } else {
          eventData = { ...extraData, ...eventData }
          eventData.occurrences = extraData.occurrences
        }

        return eventData
      }

      for (let event of payload.data) {
        if (event.allOccurrences.length === 1) {
          events.push(reformatEventDateTime(event))
        } else {
          for (let occurrence of event.allOccurrences) {
            events.push(reformatEventDateTime(occurrence, event))
          }
        }
      }

      state.data.calendarReport = events
    },
    SET_REPORT_DEFINEDREPORTS (state, payload) {
      for (let item of payload) {
        const reportRaw = item.report
        let reportOutput

        switch (reportRaw) {
          case 'bookings':
            reportOutput = 'Event bookings'
            break
          case 'moduleActivity':
            reportOutput = 'Module completion'
            break
          case 'courseFeedbacks':
            reportOutput = 'Course feedback'
            break
          case 'trainers':
            reportOutput = 'Trainer feedback'
            break
          case 'enrolments':
            reportOutput = 'Course completion'
            break
          default:
            reportOutput = toCapitalize(reportRaw)
        }

        item.reportAreaMapped = reportOutput
        item.combined = item.name + ' ' + item.description
      }

      state.data.definedReports = sortByKey(payload, 'updated_at', 'desc', 'date')
    },
    //
    SET_REPORT_CALENDARFILTERS (state, payload) {
      let filters = state.data.calendarReportFilters
      state.data.calendarReportFilters = { ...filters, ...payload }
    },
    SET_REPORT_GENERATEDREPORTFILTERS (state, payload) {
      for (let i = 0; i < payload.length; i++) {
        if (payload[i].type === 'multiselect') {
          payload[i].values = payload[i].values.map((obj) => {
            return {
              label: obj.name + obj.id,
              customLabel: obj.name,
              value: obj.id
            }
          })
        }
      }
      state.data.generatedReport.filters = payload
    },
    SET_REPORT_GENERATEDREPORTDATA (state, payload) {
      state.data.generatedReport.data = payload
    },
    SET_REPORT_GENERATEDREPORTPAGINATION (state, payload) {
      state.data.generatedReport.pagination = payload
    },
    SET_REPORT_FETCHREPORTFILTERS (state, payload) {
      let filters = state.data.fetchReport.filters
      state.data.fetchReport.filters = { ...filters, ...payload }
    },
    //
    CLEAR_REPORT (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    CLEAR_REPORT_DEFINEDREPORTS (state) {
      state.data.definedReports = []
    },
    CLEAR_REPORT_CALENDAR_FILTERS (state) {
      state.data.calendarReportFilters = {}
    },
    CLEAR_REPORT_CALENDAR (state) {
      state.data.calendarReport = ''
    },
    CLEAR_REPORT_GENERATEDREPORTFILTERS (state) {
      state.data.generatedReport.filters = ''
    },
    CLEAR_REPORT_GENERATEDREPORTDATA (state) {
      state.data.generatedReport.data = {
        labels: '',
        data: ''
      }
    },
    CLEAR_REPORT_GENERATEDREPORTPAGINATION (state) {
      state.data.generatedReport.pagination = ''
    },
    CLEAR_REPORT_FETCHREPORT (state) {
      state.data.fetchReport = {
        filters: {}
      }
    }
  },
  actions: {
    getReportCalendar ({ commit, state, getters }, payload) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_REPORT_CALENDAR')
        const requestQuery = payload
        const isOldLMSToken = getters.getAuthUserIsSecondStageTokenOldLMS
        const domain = getters.getAuthUserSecondStageTokenDataDomain

        let endpoint = `${api.classroom}/report/calendar`

        endpoint += isOldLMSToken || requestQuery ? '?' : ''
        endpoint += isOldLMSToken ? `domain=${domain}` : ''
        endpoint += isOldLMSToken && requestQuery ? '&' : ''
        endpoint += requestQuery ? `dateStart=${requestQuery.dateStart}&dateEnd=${requestQuery.dateEnd}` : ''
        endpoint += requestQuery && requestQuery.workspaceId ? `&workspaceId=${requestQuery.workspaceId}` : ''
        endpoint += requestQuery && requestQuery.unitId ? `&unit=${requestQuery.unitId}` : ''

        axios({
          method: 'post',
          url: endpoint,
          data: state.data.calendarReportFilters
        }).then(response => {
          commit('SET_REPORT_CALENDAR', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getReportGeneratedReportFilters ({ commit, getters }, payload) {
      return new Promise((resolve, reject) => {
        const isOldLMSToken = getters.getAuthUserIsSecondStageTokenOldLMS
        const domain = getters.getAuthUserSecondStageTokenDataDomain
        if (!domain && isOldLMSToken) return
        axios({
          method: 'GET',
          url: `${api.classroom}/report/${payload}-filters`
        }).then(response => {
          let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          if (typeof timeZone === 'undefined') {
            timeZone = 'Europe/London'
          }
          commit('SET_REPORT_GENERATEDREPORTFILTERS', response.data)
          commit('SET_REPORT_FETCHREPORTFILTERS', { domain: domain })
          commit('SET_REPORT_FETCHREPORTFILTERS', { timezone: timeZone })
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getReportGeneratedReportTable ({ commit, getters }, payload) {
      return new Promise((resolve) => {
        commit('CLEAR_REPORT_GENERATEDREPORTDATA')
        commit('CLEAR_REPORT_GENERATEDREPORTPAGINATION')

        const filters = getters.getReportFetchReportFilters
        if (!filters.timezone) {
          let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          if (typeof timeZone === 'undefined') {
            timeZone = 'Europe/London'
          }
          filters.timezone = timeZone
        }

        axios({
          method: 'POST',
          url: `${api.classroom}/report/${payload}`,
          data: filters
        }).then(function (response) {
          commit('SET_REPORT_GENERATEDREPORTDATA', response.data.data)
          commit('SET_REPORT_GENERATEDREPORTPAGINATION', response.data.pagination)
          resolve(response)
        })
      })
    },
    getReportGeneratedReportCSV ({ getters }, payload) {
      return new Promise((resolve, reject) => {
        const filters = getters.getReportFetchReportFilters
        if (!filters.timezone) {
          let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          if (typeof timeZone === 'undefined') {
            timeZone = 'Europe/London'
          }
          filters.timezone = timeZone
        }
        axios({
          method: 'POST',
          url: `${api.classroom}/report/${payload}/csv`,
          data: filters,
          responseType: 'arraybuffer'
        }).then((response) => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.getElementById('download-report-csv')
            link.href = url
            link.setAttribute('download', `${payload}-report.csv`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), 'download-report-csv')
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportsGetDefinedReports ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_REPORT_DEFINEDREPORTS')
        axios({
          method: 'get',
          url: `${api.reportengine}/reports`
        }).then(response => {
          commit('SET_REPORT_DEFINEDREPORTS', response.data.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    //
    clearReport ({ commit }) {
      commit('CLEAR_REPORT')
    },
    clearReportDefinedReports ({ commit }) {
      commit('CLEAR_REPORT_DEFINEDREPORTS')
    },
    clearReportCalendar ({ commit }) {
      commit('CLEAR_REPORT_CALENDAR')
    },
    clearReportCalendarFilters ({ commit }) {
      commit('CLEAR_REPORT_CALENDAR_FILTERS')
    },
    clearReportGenerated ({ commit }) {
      commit('CLEAR_REPORT_GENERATEDREPORTFILTERS')
      commit('CLEAR_REPORT_GENERATEDREPORTDATA')
      commit('CLEAR_REPORT_GENERATEDREPORTPAGINATION')
      commit('CLEAR_REPORT_FETCHREPORT')
    },
    clearReportGeneratedReportFilters ({ commit }) {
      commit('CLEAR_REPORT_GENERATEDREPORTFILTERS')
    },
    clearReportGeneratedReportTable ({ commit }) {
      commit('CLEAR_REPORT_GENERATEDREPORTDATA')
      commit('CLEAR_REPORT_GENERATEDREPORTPAGINATION')
    },
    clearReportFetchReportFilters ({ commit }) {
      commit('CLEAR_REPORT_FETCHREPORT')
    },
    //
    setReportFetchReportFilters ({ commit }, payload) {
      // if function used manually then payload needs to has structure like:
      // { key: value }
      // in cases of use in filters structure is:
      // { label: String, type: String, values: Array/Object }
      commit('SET_REPORT_FETCHREPORTFILTERS', payload)
    },
    setReportCalendarFilters ({ commit }, payload) {
      // if function used manually then payload needs to has structure like:
      // { key: value }
      // in cases of use in filters structure is:
      // { label: String, type: String, values: Array/Object }
      commit('SET_REPORT_CALENDARFILTERS', payload)
    }
  }
}

export default ebsReports
