import axios from 'axios'
import api from '@/api'

const data = {
  data: '',
  default: ''
}

const lmsSuperadminGroup = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminGroup: state => {
      return state.data
    }
  },
  mutations: {
    LMS_SUPERADMIN_GROUP_SET (state, payload) {
      state.data = payload
    },
    LMS_SUPERADMIN_GROUP_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    }
  },
  actions: {
    lmsSuperadminGroupGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const groupId = data.id

        commit('LMS_SUPERADMIN_GROUP_CLEAR')
        axios({
          method: 'get',
          url: `${api.sso}/workspace-group/${groupId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_GROUP_SET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminGroupUpdate ({ getters }, data) {
      return new Promise((resolve, reject) => {
        const name = data.name
        const groupId = data.id
        const workspaceId = data.workspaceId || getters.getAuthUserSecondStageTokenDataWorkspaceId
        const customerId = data.customerId || getters.getAuthUserSecondStageTokenDataCustomerId
        const suggestedCourses = data.suggestedCourses || []
        const disableSuggestedCourses = data.disableSuggestedCourses || []
        const requestMethod = groupId ? 'patch' : 'post'
        const requestUrl = groupId ? `/workspace-group/${groupId}` : `/workspace-group`
        let bodyData = {
          workspaceId,
          customerId,
          suggestedCourses,
          disableSuggestedCourses
        }

        if (name) {
          bodyData.name = name
        }

        axios({
          method: requestMethod,
          url: `${api.sso}${requestUrl}`,
          data: bodyData
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminGroupGetSuggestedCourses ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('lmsSuperadminGroupGet', { id: data.id }).then(response => {
          resolve(response.data.suggestedCourses)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminGroupUpdateSuggestedCourses ({ getters }, data) {
      return new Promise((resolve, reject) => {
        let groupId = data.id
        let suggestedCourses = data.suggestedCourses
        let workspaceId = data.workspaceId || getters.getAuthUserSecondStageTokenDataWorkspaceId
        let bodyData = {}

        bodyData.workspaceId = workspaceId
        if (suggestedCourses) {
          bodyData.suggestedCourses = suggestedCourses
        }

        axios({
          method: 'patch',
          url: `${api.sso}/workspace-group/suggested-courses/${groupId}`,
          data: bodyData
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminGroupRemove ({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const groupId = data.id
        const index = data.index

        if (!groupId) {
          commit('LMS_SUPERADMIN_GROUPS_REMOVE', index)
          resolve()
        } else {
          axios({
            method: 'delete',
            url: `${api.sso}/workspace-group/${groupId}`
          }).then(response => {
            commit('LMS_SUPERADMIN_GROUP_SET', response.data)
            dispatch('defaultNotificationToastsAdd', {
              title: 'Group remove',
              message: 'Group has been successfully removed.',
              status: 'success'
            })
            resolve(response)
          }).catch(error => {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Group remove',
              message: `Group hasn't been removed because it has assigned users.`,
              status: 'error'
            })
            reject(error)
          })
        }
      })
    },
    lmsSuperadminGroupClear ({ commit }) {
      commit('LMS_SUPERADMIN_GROUP_CLEAR')
    }
  }
}

export default lmsSuperadminGroup
