import axios from 'axios'
import api from '@/api'
import { fromKebabCaseToString, toCapitalize } from '@/functions'

const lmsSuperadminCustomerRoles = {
  state: {
    data: [],
    default: []
  },
  getters: {
    getLmsSuperadminCustomerRoles: state => {
      const roles = state.data
      if (!roles.length) return []
      return roles.map((role) => {
        return {
          label: toCapitalize(fromKebabCaseToString(role)),
          value: role
        }
      })
    },
    getLmsSuperadminCustomerRolesMappedIdName: state => {
      const roles = state.data
      if (!roles.length) return []
      return roles.map((role) => {
        return {
          id: role,
          name: toCapitalize(fromKebabCaseToString(role))
        }
      })
    }
  },
  mutations: {
    LMS_SUPERADMIN_CUSTOMER_ROLES_GET (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    //
    LMS_SUPERADMIN_CUSTOMER_ROLES_CLEAR (state) {
      state.data = []
      state.default = JSON.parse(JSON.stringify(state.data))
    }
  },
  actions: {
    lmsSuperadminCustomerRolesClear (context) {
      context.commit('LMS_SUPERADMIN_CUSTOMER_ROLES_CLEAR')
    },
    //
    lmsSuperadminCustomerRolesGet ({ commit, getters }, id) {
      let customerId = id.constructor === String ? id : (id && id.requestData ? id.requestData : getters.getAuthUserSecondStageTokenDataCustomerId)
      return new Promise((resolve, reject) => {
        commit('LMS_SUPERADMIN_CUSTOMER_ROLES_CLEAR')
        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/v2/customer/roles/${customerId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_CUSTOMER_ROLES_GET', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default lmsSuperadminCustomerRoles
