<template>
    <div class="header__top_account_menu_thumbnail">
        <template v-if="isLoggedInUserInpersonated">
            <button
                :class="[
                    'header__top_account_menu_thumbnail_button',
                    'header__top_account_menu_thumbnail_button--admin'
                ]"
                id="header-top-account-menu-thumbnail-button-admin"
            >
                {{ loggedInOriginUserNameInitials }}
            </button>
        </template>
        <button
            :class="[
                'header__top_account_menu_thumbnail_button',
                buttonActive ? 'header__top_account_menu_thumbnail_button--active' : '',
                isLoggedInUserInpersonated ? 'header__top_account_menu_thumbnail_button--inpersonated' : ''
            ]"
            @click="toggleBox"
            id="header-top-account-menu-thumbnail-button"
        >
            {{ loggedInUserNameInitials }}
        </button>
        <template v-if="(user.isLearningExperienceAdministrator() || user.isManager()) && requestsLength">
            <div @click="redirectToEnrolments" class="header__top_account_menu_thumbnail_notifications">
                <div class="header__top_account_menu_thumbnail_notifications_number">{{ requestsLength }}</div>
            </div>
        </template>
    </div>
</template>

<script>
import { User } from '@/User'

export default {
  name: 'header-top-account-menu-thumbnail',
  data () {
    return {
      user: new User()
    }
  },
  props: {
    buttonActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    requestsLength () {
      return this.$store.getters.getEbsRequestsTotalResults
    },
    loggedInUserNameInitials () {
      return this.$store.getters.getAuthUserSecondStageTokenDataNameInitials
    },
    loggedInOriginUserNameInitials () {
      return this.$store.getters.getAuthUserSecondStageTokenDataOriginUserNameInitials
    },
    isLoggedInUserInpersonated () {
      return this.user.isInpersonated()
    },
    secondStageToken () {
      return this.$store.getters.getAuthUserSecondStageTokenData
    }
  },
  watch: {
    secondStageToken () {
      this.user = new User()
    }
  },
  methods: {
    toggleBox () {
      this.$parent.toggleBox()
    },
    redirectToEnrolments () {
      this.$router.push({ name: 'ebs-requests-list' })
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/layout/header/headertopaccountmenuthumbnail";
</style>
