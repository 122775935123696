import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const lmsSuperadminCustomers = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminCustomers: state => {
      return state.data
    },
    getLmsSuperadminCustomersMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.name,
          value: item.id,
          services: item.services,
          extraData: item.workspaces
        }
      })
    },
    getLmsSuperadminCustomersMappedIdNameStatus: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          status: item.status,
          services: item.services,
          extraData: item.workspaces
        }
      })
    },
    getLmsSuperadminCustomersPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    LMS_SUPERADMIN_CUSTOMERS_SET (state, payload) {
      state.data = payload
    },
    LMS_SUPERADMIN_CUSTOMERS_SET_PAGINATION (state, payload) {
      state.pagination = payload
    },
    LMS_SUPERADMIN_CUSTOMERS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_CUSTOMERS_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsSuperadminCustomersGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        commit('LMS_SUPERADMIN_CUSTOMERS_CLEAR')
        commit('LMS_SUPERADMIN_CUSTOMERS_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/customers${queryUrlParams}`
        }).then(function (response) {
          commit('LMS_SUPERADMIN_CUSTOMERS_SET', response.data.data !== undefined ? response.data.data : response.data)
          commit('LMS_SUPERADMIN_CUSTOMERS_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminCustomersGetForCourseLicenceManager ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        commit('LMS_SUPERADMIN_CUSTOMERS_CLEAR')
        commit('LMS_SUPERADMIN_CUSTOMERS_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/course-licence-manager-customers${queryUrlParams}`
        }).then(function (response) {
          commit('LMS_SUPERADMIN_CUSTOMERS_SET', response.data.data !== undefined ? response.data.data : response.data)
          commit('LMS_SUPERADMIN_CUSTOMERS_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default lmsSuperadminCustomers
