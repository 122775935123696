import axios from 'axios'
import api from '@/api'
import { getNestedByArray } from '@/functions'

const rawUnit = {
  children: [],
  workspaceId: '',
  customerId: '',
  id: '',
  name: '',
  parentId: ''
}

const data = {
  data: {
    children: [],
    workspaceId: '',
    customerId: '',
    id: '',
    name: '',
    parentId: ''
  },
  default: '',
  custom: {
    customerId: '',
    workspaceId: '',
    unitsWithCourses: [],
    requestPending: false
  }
}

const lmsSuperadminOrgUnits = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminOrgUnits: state => {
      return state.data
    },
    getLmsSuperadminOrgUnitsId: state => {
      return state.data.id
    },
    getLmsSuperadminOrgUnitsCustomCustomerId: state => {
      return state.custom.customerId
    },
    getLmsSuperadminOrgUnitsCustomWorkspaceId: state => {
      return state.custom.workspaceId
    },
    getLmsSuperadminOrgUnitsWorkspaceId: state => {
      return state.data.workspaceId
    },
    getLmsSuperadminOrgUnitsChildren: state => {
      return state.data.children
    },
    getLmsSuperadminOrgUnitsCustomRequestPending: state => {
      return state.custom.requestPending
    },
    getLmsSuperadminOrgUnitsWithCourses: state => {
      return state.custom.unitsWithCourses
    },
    getLmsSuperadminOrgUnitsMappedLabelValue: state => {
      let unitsArr = []
      const recursiveLoopCollectUnits = (node, nodeIndex, parentName) => {
        const nodes = node.children
        for (let i in nodes) {
          if (nodes[i].id) {
            unitsArr.push({
              label: nodes[i].name,
              customLabel: parentName ? `${ nodes[i].name } <span>${ parentName.join(' > ') }</span>` : nodes[i].name,
              value: nodes[i].id,
              parentPath: parentName ? parentName.join(' / ') + ` / ${ nodes[i].name }` : nodes[i].name
            })
          }
          if (nodes[i] && nodes[i].children) {
            recursiveLoopCollectUnits(nodes[i], nodeIndex ? [...nodeIndex, i] : i, parentName ? [...parentName, nodes[i].name] : [nodes[i].name])
          }
        }
      }

      recursiveLoopCollectUnits(state.data)

      return unitsArr
    },
    getLmsSuperadminOrgUnitsMappedIdName: state => {
      let unitsArr = []
      const recursiveLoopCollectUnits = (node, nodeIndex, parentName) => {
        const nodes = node.children
        for (let i in nodes) {
          if (nodes[i].id) {
            unitsArr.push({
              id: nodes[i].id,
              name: nodes[i].name
            })
          }
          if (nodes[i] && nodes[i].children) {
            recursiveLoopCollectUnits(nodes[i], nodeIndex ? [...nodeIndex, i] : i, parentName ? [...parentName, nodes[i].name] : [nodes[i].name])
          }
        }
      }

      recursiveLoopCollectUnits(state.data)

      return unitsArr
    },
    getLmsSuperadminOrgUnitsMappedIdNameSuggestedCourses: state => {
      let unitsArr = []
      const recursiveLoopCollectUnits = (node, nodeIndex, parentName) => {
        const nodes = node.children
        for (let i in nodes) {
          if (nodes[i].id) {
            unitsArr.push({
              id: nodes[i].id,
              name: nodes[i].name,
              suggestedCourses: nodes[i].suggestedCourses
            })
          }
          if (nodes[i] && nodes[i].children) {
            recursiveLoopCollectUnits(nodes[i], nodeIndex ? [...nodeIndex, i] : i, parentName ? [...parentName, nodes[i].name] : [nodes[i].name])
          }
        }
      }

      recursiveLoopCollectUnits(state.data)

      return unitsArr
    }
  },
  mutations: {
    LMS_SUPERADMIN_ORGUNITS_SET (state, payload) {
      state.data = payload
    },
    LMS_SUPERADMIN_ORGUNITS_ADD (state, payload) {
      let unit = JSON.parse(JSON.stringify(rawUnit))
      const indexParent = payload ? JSON.parse(JSON.stringify(payload)) : []
      let indexParentArr = []
      let parent
      let parentId

      for (let i in indexParent) {
        indexParentArr.push('children')
        indexParentArr.push(indexParent[i])
      }
      parent = getNestedByArray(state.data, indexParentArr)
      parentId = parent ? parent.id : undefined

      unit.parentId = parentId

      if (payload) {
        const index = JSON.parse(JSON.stringify(payload))
        let indexArr = ['children']

        for (let i in index) {
          indexArr.push(index[i])
          indexArr.push('children')
        }

        getNestedByArray(state.data, indexArr).unshift(unit)
      } else {
        state.data.children.unshift(unit)
      }
    },
    LMS_SUPERADMIN_ORGUNITS_REMOVE (state, payload) {
      const index = payload
      const indexLast = index.pop()
      let stateData = state.data
      let indexArr = ['children']

      for (let i in index) {
        indexArr.push(index[i])
        indexArr.push('children')
      }

      getNestedByArray(stateData, indexArr).splice(indexLast, 1)
    },
    LMS_SUPERADMIN_ORGUNITS_SET_VALUE (state, payload) {
      let stateData = state.data
      const data = JSON.parse(JSON.stringify(payload))
      const index = data.index
      const indexLast = index.pop()
      const key = data.key
      const value = data.value

      let indexArr = ['children']

      for (let i in index) {
        indexArr.push(index[i])
        indexArr.push('children')
      }

      getNestedByArray(stateData, indexArr)[indexLast][key] = value
    },
    LMS_SUPERADMIN_ORGUNITS_CLEAR_CUSTOM (state) {
      state.custom = JSON.parse(JSON.stringify(data.custom))
    },
    LMS_SUPERADMIN_ORGUNITS_CLEAR_DATA (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    LMS_SUPERADMIN_ORGUNITS_SET_CUSTOM_CUSTOMERID (state, payload) {
      state.custom.customerId = payload
    },
    LMS_SUPERADMIN_ORGUNITS_SET_CUSTOM_WORKSPACEID (state, payload) {
      state.custom.workspaceId = payload
    },
    LMS_SUPERADMIN_ORGUNITS_SET_CUSTOM_UNITSWITHCOURSES (state, payload) {
      state.custom.unitsWithCourses = payload
    },
    LMS_SUPERADMIN_ORGUNITS_SET_CUSTOM_REQUESTPENDING (state, payload) {
      state.custom.requestPending = payload
    },
    LMS_SUPERADMIN_ORGUNITS_SET_WORKSPACEID (state, payload) {
      state.data.workspaceId = payload
    }
  },
  actions: {
    lmsSuperadminOrgUnitsGet ({ commit, getters, state }, data) {
      return new Promise((resolve, reject) => {
        let workspaceId
        const preventAddUnit = data ? data.preventAddUnit : false
        if (data) {
          if (data.constructor === String) {
            workspaceId = data
          } else {
            if (data.requestData !== undefined && data.requestData !== '') {
              workspaceId = data.requestData
            } else {
              workspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
            }
          }
        } else {
          workspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        }

        if (!workspaceId) {
          commit('LMS_SUPERADMIN_ORGUNITS_SET_WORKSPACEID', workspaceId)
          if (!state.data.children.length) {
            commit('LMS_SUPERADMIN_ORGUNITS_ADD')
          }
          reject(new Error('noWorkspaceId'))
        }

        commit('LMS_SUPERADMIN_ORGUNITS_SET_CUSTOM_REQUESTPENDING', true)
        axios({
          method: 'get',
          url: `${ api.subscriptionmanager }/organisation-unit/${ workspaceId }`,
          data: data
        }).then(response => {
          commit('LMS_SUPERADMIN_ORGUNITS_SET', response.data)
          if (!preventAddUnit) {
            if (response.data.children.length === 0) {
              commit('LMS_SUPERADMIN_ORGUNITS_ADD', [0])
            }
          }
          resolve(response.data)
        }).catch(error => {
          if (data) {
            commit('LMS_SUPERADMIN_ORGUNITS_SET_WORKSPACEID', workspaceId)
            if (!preventAddUnit) {
              if (JSON.parse(JSON.stringify(state.data.children.length === 0))) {
                commit('LMS_SUPERADMIN_ORGUNITS_ADD')
              }
            }
          }
          reject(error)
        }).finally(() => {
          commit('LMS_SUPERADMIN_ORGUNITS_SET_CUSTOM_REQUESTPENDING', false)
        })
      })
    },
    lmsSuperadminOrgUnitsWithCourses ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        const workspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        commit('LMS_SUPERADMIN_ORGUNITS_CLEAR_DATA')
        return axios({
          method: 'get',
          url: `${ api.subscriptionmanager }/unit-with-courses/${ workspaceId }`
        }).then(response => {
          commit('LMS_SUPERADMIN_ORGUNITS_SET_CUSTOM_UNITSWITHCOURSES', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminOrgUnitsAdd ({ commit }, data) {
      commit('LMS_SUPERADMIN_ORGUNITS_ADD', data)
    },
    lmsSuperadminOrgUnitsSetValue ({ commit }, data) {
      commit('LMS_SUPERADMIN_ORGUNITS_SET_VALUE', data)
    },
    lmsSuperadminOrgUnitsSetCustomCustomerId ({ commit }, data) {
      commit('LMS_SUPERADMIN_ORGUNITS_SET_CUSTOM_CUSTOMERID', data)
    },
    lmsSuperadminOrgUnitsSetCustomWorkspaceId ({ commit }, data) {
      commit('LMS_SUPERADMIN_ORGUNITS_SET_CUSTOM_WORKSPACEID', data)
    },
    lmsSuperadminOrgUnitsClearData ({ commit }) {
      commit('LMS_SUPERADMIN_ORGUNITS_CLEAR_DATA')
    },
    lmsSuperadminOrgUnitsClearCustom ({ commit }) {
      commit('LMS_SUPERADMIN_ORGUNITS_CLEAR_CUSTOM')
    },
    lmsSuperadminOrgUnitsSwitchWorkspace ({ commit, dispatch }, data) {
      return dispatch('lmsSuperadminOrgUnitsGet', data).catch(() => {
        commit('LMS_SUPERADMIN_ORGUNITS_SET_WORKSPACEID', data)
      })
    }
  }
}

export default lmsSuperadminOrgUnits
