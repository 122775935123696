<template>
    <div
        id="header__top"
        :class="[
            'header__top',
            accessedCustomerRoleArea ? 'header__top--inverse' : ''
        ]"
    >
        <div class="container">
            <div :class="[
                'header__top_inner'
            ]">
                <div class="columns header__top_inner--columns is-vcentered columns--grid is-12-desktop">
                    <div class="column is-hidden-tablet is-3-mobile">
                        <component-button
                            v-if="isUserLoggedIn && hasSelectedProfile"
                            :class="'header__top_inner_hamburgerbutton'"
                            :align="'left'"
                            :name="'header-top-hamburger-button'"
                            :icon="true"
                            :icon-name="hamburgerMenuActive ? 'chevron-left' : 'menu'"
                            :icon-raw="true"
                            :theme="''"
                            @click="switchHamburgerMenuActive"
                        ></component-button>
                    </div>
                    <div
                        class="column has-text-centered-mobile is-narrow-tablet"
                    >
                        <div class="columns fullheight is-vcentered">
                            <div class="column">
                                <div class="header__top_logo">
                                    <div class="columns is-block-mobile is-vcentered">
                                        <div class="column is-narrow">
                                            <router-link :key="headerTopAccountKey" :disabled="!logoUrl || !logoLinkAccessible" :to="logoUrl">
                                                <component-logo
                                                    :file-id="workspaceLogo"
                                                    :height="workspaceLogoHeight"
                                                    :inverse-style="accessedCustomerRoleArea"
                                                />
                                            </router-link>
                                        </div>
                                        <template v-if="isWorkspaceNameDisplayed">
                                            <div class="column is-hidden-mobile is-narrow">
                                                <component-separator :vertical="true"></component-separator>
                                            </div>
                                            <div class="column">
                                                <component-text
                                                    :class="'header__top_logo_workspacename'"
                                                    :size="'tiny'"
                                                >{{ workspaceName }}
                                                </component-text>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-align-self-bottom is-3-mobile column--custom-mobile">
                        <header-top-account
                            :key="headerTopAccountKey"
                            :hamburger-menu-active="hamburgerMenuActive"
                            :hamburger-menu-touched="hamburgerMenuTouched"
                            :inverse-style="accessedCustomerRoleArea"
                        ></header-top-account>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ComponentLogo from '@/components/default/shared/ComponentLogo'
import HeaderTopAccount from '@/components/default/layout/header/HeaderTopAccount'
import ComponentButton from '@/components/default/shared/ComponentButton'
import ComponentText from '@/components/default/shared/ComponentText'
import ComponentSeparator from '@/components/default/shared/ComponentSeparator'
import { User } from '@/User'

export default {
  components: {
    ComponentSeparator,
    ComponentText,
    ComponentButton,
    HeaderTopAccount,
    ComponentLogo
  },
  name: 'header-top',
  data () {
    return {
      headerTopAccountKey: 1,
      hamburgerMenuActive: false,
      hamburgerMenuTouched: false,
      user: new User()
    }
  },
  computed: {
    accessedCustomerRoleArea () {
      return Boolean(this.user.getCustomerRoleArea())
    },
    logoUrl () {
      let route = ''

      if (!this.isUserLoggedIn) {
        route = { name: 'auth-login' }
      } else {
        if (this.hasSelectedProfile) {
          if (this.user.isSiteAdmin()) {
            route = { name: 'home' }
          } else if ((this.user.isLearningExperienceAdministrator() || this.user.isManager()) && this.user.hasAnyCustomerService(['LMS'])) {
            route = { name: 'home' }
          } else if (this.user.isUserAdministrator()) {
            route = { name: 'home' }
          } else if (this.user.isCourseCreator() && this.user.hasAnyCustomerService(['CCS'])) {
            route = { name: 'home' }
          } else if (this.user.isCourseLicenceManager() && this.user.hasAnyCustomerService(['CCS'])) {
            route = { name: 'home' }
          } else if (this.user.isEventCoordinator() && this.user.hasAnyCustomerService(['EBS'])) {
            route = { name: 'home' }
          } else if (this.user.isInfrastructureManager() && this.user.hasAnyCustomerService(['EBS'])) {
            route = { name: 'home' }
          } else if (this.user.isTrainer() && this.user.hasAnyCustomerService(['EBS'])) {
            route = { name: 'dashboard-trainer' }
          } else if (this.user.isLearner()) {
            if (this.user.hasAnyCustomerService(['LMS'])) {
              route = { name: 'dashboard-courses' }
            } else if (this.user.hasAnyCustomerService(['EBS'])) {
              route = { name: 'dashboard-learner' }
            }
          } else {
            route = { name: 'lms-user-account-personal' }
          }
        } else {
          route = { name: 'lms-user-workspace-list' }
        }
      }

      return route
    },
    logoLinkAccessible () {
      return (
        this.user.isSiteAdmin() ||
      this.user.isEventCoordinator() ||
      this.user.isInfrastructureManager() ||
      this.user.isLearningExperienceAdministrator() ||
      this.user.isUserAdministrator() ||
      this.user.isCourseCreator() ||
      this.user.isCourseLicenceManager() ||
      this.user.isSuperadmin()
      )
    },
    secondStageTokenOrigin () {
      return this.$store.getters.getAuthUserSecondStageTokenDataOrigin
    },
    secondStageTokenWorkspaceId () {
      return this.$store.getters.getAuthUserSecondStageTokenDataWorkspaceId
    },
    isWorkspaceNameDisplayed () {
      return this.$store.getters.defaultWorkspaceCustomisationDisplayName
    },
    workspaceName () {
      return this.$store.getters.defaultWorkspaceName
    },
    workspaceLogo () {
      return this.$store.getters.defaultWorkspaceCustomisationWorkspaceLogo
    },
    workspaceLogoHeight () {
      return this.$store.getters.defaultWorkspaceCustomisationWorkspaceLogoHeight
    },
    watchComputed () {
      return this.secondStageTokenWorkspaceId + this.secondStageTokenOrigin + this.logoUrl
    },
    isUserLoggedIn () {
      return this.$store.getters.getAuthUserFirstStageTokenData
    },
    hasSelectedProfile () {
      return Boolean(this.secondStageTokenWorkspaceId)
    }
  },
  watch: {
    watchComputed () {
      this.headerTopAccountKey++
    },
    secondStageTokenWorkspaceId () {
      setTimeout(() => {
        this.user = new User()
      }, 100)
      this.hamburgerMenuTouched = false
    }
  },
  methods: {
    switchHamburgerMenuActive () {
      this.hamburgerMenuActive = !this.hamburgerMenuActive
      this.hamburgerMenuTouched = true
    },
    closeHamburgerMenu (event) {
      const path = event.path || event.composedPath()

      if (!path || !this.hamburgerMenuActive) return
      let close = true
      for (let i = 0; i < path.length; i++) {
        if (!path[i].className) continue
        if (
          path[i].classList.contains('header__top_inner_hamburgerbutton')
        ) {
          close = false
        }
      }
      if (close) {
        this.hamburgerMenuActive = false
      }
    }
  },
  created () {
    this.$eventBus.$on('set-second-stage-token', () => {
      this.user = new User()
    })
    this.$eventBus.$on('set-customer-area', () => {
      this.user = new User()
    })
  },
  updated () {
    this.$eventBus.$off('set-second-stage-token')
    this.$eventBus.$off('set-customer-area')
    this.$eventBus.$on('set-second-stage-token', () => {
      this.user = new User()
    })
    this.$eventBus.$on('set-customer-area', () => {
      this.user = new User()
    })
  },
  mounted () {
    const overlay = document.getElementById('app')
    overlay.addEventListener('click', this.closeHamburgerMenu)
  },
  beforeDestroy () {
    const overlay = document.getElementById('app')
    overlay.removeEventListener('click', this.closeHamburgerMenu)
    this.$eventBus.$off('set-second-stage-token')
    this.$eventBus.$off('set-customer-area')
  }
}
</script>
<style lang="scss">
    @import "~@/assets/scss/layout/header/headertop";
</style>
