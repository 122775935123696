<template>
    <div :class="'header__top_account'">
        <div class="header__top_account_inner">
            <div class="columns fullheight is-variable is-2">
                <template v-if="allowTopAccountNav">
                    <div :class="[
                        'column',
                        'column--custom-nav'
                    ]">
                        <div class="columns is-variable is-6">
                            <div class="column">
                                <header-top-account-nav
                                    v-if="allowForMenu"
                                    :class="[
                                        hamburgerMenuActive ? 'header__top_account_nav--active' : 'header__top_account_nav--inactive',
                                        hamburgerMenuTouched && allowTopAccountNav ? 'header__top_account_nav--touched' : '',
                                        inverseStyle ? 'header__top_account_nav--inverse' : ''
                                    ]"
                                    :inverse-style="inverseStyle"
                                ></header-top-account-nav>
                            </div>
                        </div>
                    </div>
                    <div class="column is-narrow column--custom-actions">
                        <div class="columns is-vcentered is-variable is-3">
                            <div class="column is-narrow">
                                <header-top-account-menu/>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="!allowTopAccountNav && !$route.meta.hideAuthLogin">
                    <div class="column"></div>
                    <div class="column has-text-right is-narrow">
                        <header-top-account-login/>
                    </div>
                    <template v-if="false">
                        <div class="column"></div>
                        <div class="column is-narrow">
                            <header-top-account-signup/>
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderTopAccountNav from '@/components/default/layout/header/HeaderTopAccountNav'
import HeaderTopAccountMenu from '@/components/default/layout/header/HeaderTopAccountMenu'
import HeaderTopAccountLogin from '@/components/default/layout/header/HeaderTopAccountLogin'
import HeaderTopAccountSignup from '@/components/default/layout/header/HeaderTopAccountSignup'
import { User } from '@/User'
import * as Cookies from 'js-cookie'

export default {
  name: 'header-top-account',
  data () {
    return {
      user: new User()
    }
  },
  components: { HeaderTopAccountSignup, HeaderTopAccountLogin, HeaderTopAccountMenu, HeaderTopAccountNav },
  props: {
    hamburgerMenuActive: {
      type: Boolean
    },
    hamburgerMenuTouched: {
      type: Boolean
    },
    inverseStyle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    allowForMenu () {
      const customerRoleArea = Cookies.get('customerRoleArea')
      const userIsSuperadmin = this.user.isSuperadmin()
      const isUserLoggedInToWorkspace = Boolean(this.$store.getters.getAuthUserSecondStageTokenDataWorkspaceId)
      const isLoggedInAsTrainer = customerRoleArea === 'trainer'
      const isLoggedInAsMasterAdministrator = customerRoleArea === 'master_administrator'

      const secondStageToken = this.$store.getters.getAuthUserSecondStageTokenData
      const isFromSSO = this.user.isTokenFromSSO()
      const routeName = this.$route.name

      if (!routeName) return

      return (
        isUserLoggedInToWorkspace ||
      isLoggedInAsTrainer ||
      isLoggedInAsMasterAdministrator ||
      (
        secondStageToken &&
      isFromSSO
      ) ||
      userIsSuperadmin
      )
    },
    allowTopAccountNav () {
      const firstStageToken = this.$store.getters.getAuthUserFirstStageTokenData
      const secondStageToken = this.$store.getters.getAuthUserSecondStageTokenData
      const isSecondStageTokenFromOldLMS = this.user.isTokenFromOldLms()
      const isFromSSO = this.user.isTokenFromSSO()
      const routeName = this.$route.name

      if (!routeName) return

      return (
        firstStageToken ||
      (
        secondStageToken &&
      (isSecondStageTokenFromOldLMS || isFromSSO)
      )
      )
    },
    checkIsInvitation () {
      return Boolean(this.$store.getters.getAuthUserSignUpInvitation)
    }
  }
}
</script>
