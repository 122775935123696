import axios from 'axios'
import api from '@/api'

const data = {
  pagination: '',
  data: '',
  default: ''
}

const storageFiles = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getStorageFiles (state) {
      return state.data
    },
    getStorageFilesPagination (state) {
      return state.pagination
    }
  },
  mutations: {
    STORAGE_FILES_SET (state, payload) {
      state.data = payload
    },
    STORAGE_FILES_PAGINATION_SET (state, payload) {
      state.pagination = payload
    },
    STORAGE_FILES_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    STORAGE_FILES_PAGINATION_CLEAR (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    storageFilesRemove ({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${api.files}/api/library`,
          data: {
            files: data
          }
        }).then(response => {
          commit('STORAGE_FILES_CLEAR')
          commit('STORAGE_FILES_PAGINATION_CLEAR')
          dispatch('defaultNotificationToastsAdd', {
            title: 'File remove',
            message: `File has been removed successfully.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'File remove',
            message: `There were issues while removing the file.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    storageFilesGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const customerId = data ? (data.customerId !== undefined ? `&customerId=${data.customerId}` : '') : ''

        commit('STORAGE_FILES_CLEAR')
        commit('STORAGE_FILES_PAGINATION_CLEAR')

        axios({
          method: 'get',
          url: `${api.files}/api/library${queryUrlParams}${customerId}`,
          data: data
        }).then(response => {
          commit('STORAGE_FILES_SET', response.data.data)
          commit('STORAGE_FILES_PAGINATION_SET', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    storageFilesUpload ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.files}/api/library`,
          data: data,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Media upload',
            message: `Media has been successfully uploaded.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Media upload',
            message: `Media hasn't been uploaded.`,
            status: 'error'
          })
          reject(error)
        })
      })
    }
  }
}

export default storageFiles
