export default {
  header: {
    nav: {
      calendar: 'Calendar',
      courses: 'Courses',
      events: 'Events',
      venues: 'Venues',
      trainers: 'Trainers',
      reports: 'Reports',
      notifications: 'Notifications',
      achievements: 'Achievements',
      myTrainings: 'My training',
      myBookings: 'My events'
    },
    topAccountNav: {
      menu: {
        learning: 'learning',
        customers: 'customers',
        workspaces: 'workspaces',
        events: 'events',
        settings: 'settings',
        mediaLibrary: 'media library',
        superadmins: 'superadmins',
        myWorkspaces: 'my workspaces',
        login: 'login',
        signUp: 'sign Up',
        reports: 'reports',
        setup: 'setup',
        admin: 'admin',
        users: 'users',
        dashboard: 'dashboard'
      },
      titles: {
        learning: 'Learning',
        dashboard: 'Dashboard',
        eventBooking: 'Event booking',
        events: 'Events',
        settings: 'Settings',
        myWorkspaces: 'My workspaces',
        reports: 'Reports',
        setup: 'Setup',
        admin: 'Admin',
        users: 'Users'
      }
    },
    topAccountMenuBox: {
      titles: {
        loggedAs: 'Logged as:',
        access: 'Accessed workspace',
        noWorkspaceSelected: 'No workspace selected'
      },
      menu: {
        personalInfo: 'Personal Info',
        security: 'Security',
        history: 'History'
      },
      buttons: {
        logout: 'Log out',
        backToLms: 'Back to LMS',
        switch: 'Switch',
        choose: 'Choose'
      }
    }
  },
  footer: {
    footerText: 'Me Learning Ltd, Registered in England and Wales: Company Number: 5842638' +
    ' Registered office: Europa House, 46-50 Southwick Square, Southwick, Brighton BN42 4FJ',
    footerCopyright: 'Me Learning'
  },
  auth: {
    login: {
      buttons: {
        login: 'Log in | Login',
        forgot: 'Forgot password?',
        signUp: 'Sign up',
        logInTo: 'Log in to another account?',
        confirm: 'Confirm'
      },
      separator: 'Don\'t have an account?',
      labels: {
        email: 'Email address',
        password: 'Password',
        authenticationCode: 'Authentication Code',
        newPassword: 'New password',
        confirmPassword: 'Confirm new password'
      }
    },
    resetPassword: {
      title: {
        reset: 'Reset password ',
        info: 'Enter the email address associated with your account, and we’ll email you a link to reset your password.'
      },
      labels: {
        email: 'Email address',
        verification: 'Verification code',
        newPassword: 'New password',
        confirmPassword: 'Confirm new password'
      },
      buttons: {
        back: 'Back to login',
        send: 'Send reset code',
        next: 'Next step',
        confirmPassword: 'Confirm password'
      }
    },
    show: {
      title: {
        contentAccount: 'Do you already have an account?',
        contentNewUser: 'Are you a new user?'
      },
      notification: 'Use your existing account to log in or create new one to access the workspace.',
      buttons: {
        LogIn: 'Log in',
        SignUp: 'Sign up'
      },
      notificationContentJs: 'Use your existing account to log in or create new one to access the workspace.'
    },
    twoFactorAuth: {
      title: 'Two-Factor Authentication',
      labels: {
        backupCode: 'Enter an authenticator app code or a backup code',
        rememberThisDevice: 'Remember this device',
        changeConfiguration: 'Change configuration',
        authenticationApp: 'Authentication app configuration',
        verificationCoda: 'Verification code'
      },
      text: {
        twoFactorAuthIsCurrently: 'Two Factor Authentication is currently',
        enabled: 'enabled',
        youAreGenerating: 'You are generating verification codes using an authenticator app.',
        disabled: 'disabled',
        ifYouEnableTwoFactorAuth: 'If you enable Two Factor Authentication, you will need to provide an additional verification code when you use a password to log into Me Learning on a device you haven\'t used before. Me Learning will send those codes as emails, or you can configure an authenticator app to generate codes for you.',
        setUpTwoFactorAuth: 'You have to set up two factor authentication to perform any kind of action on your account.',
        youCant: 'You can\'t disable two factor authentication security as long as you are superadmin.',
        scanThisCode: 'Scan this code with your authenticator app:',
        enterThe6Digit: 'Once you\'ve added Me Learning to your authenticator app, enter the 6-digit code that it generates below.'
      },
      buttons: {
        verify: 'Verify',
        troubleReceivingCode: 'Trouble receiving code?',
        enableTwoFactorAuth: 'Enable two factor authentication',
        disableTwoFactorAuth: 'Disable two factor authentication',
        cancel: 'Cancel',
        verifyAndUpdate: 'Verify and update',
        changePassword: 'Change password',
        savePassword: 'Save Password'
      },
      notify: {
        titleDisable: 'Disable two factor authentication',
        textDoYouWant: 'Do you want to disable two factor authentication?',
        dismiss: 'Dismiss',
        confirm: 'Confirm'
      },
      router: {
        customMessage: 'You have to set up two factor authentication.',
        setSuccessfully: 'Two factor authentication has been set successfully.'
      }
    },
    twoFactorHelp: {
      title: {
        troubleReceivingCode: 'Trouble receiving code?',
        howDoesWork: 'How does two factor authetication work?',
        authenticationCode: 'Can\'t get a authentication code?',
        stillHavingTrouble: 'Still having trouble?'
      },
      buttons: {
        learnMore: 'Learn more',
        signWithCode: 'Sign up with a backup code',
        getFurtherHelp: 'Get further help',
        backToLogin: 'Back to login'
      }
    },
    signUpForm: {
      title: {
        signUp: 'Sign up',
        doYouHave: 'Already have a Me Learning account?',
        willBeAccepted: 'Your invitation will be accepted right after signing up.'
      },
      buttons: {
        signUp: 'Sign up',
        logIn: 'Log in'
      },
      labels: {
        email: 'Email address',
        firstName: 'First name',
        lastName: 'Last name',
        createPassword: 'Create a password',
        iWantToReceive: 'I want to receive marketing messages from Me Learning. I can opt out of receiving these at any time in my account settings or via the link in the message.'
      }
    },
    invitation: {
      content: {
        someIssues: `Some issues has occurred while performing`,
        ofAnInvitation: `of an invitation. Please try again or contact an administrator.`,
        rejected: 'You have rejected an invitation to workspace.',
        accepted: 'You have accepted an invitation to workspace.'
      }
    }
  },
  dashboard: {
    titles: {
      activeSession: 'Active sessions',
      completedSessions: 'Completed sessions',
      name: 'Name',
      startDate: 'Start Date',
      location: 'Location',
      spaces: 'Spaces',
      status: 'Status',
      room: 'Room'
    },
    labels: {
      searchSession: 'Search session',
      date: 'Date',
      time: 'Time',
      trainers: 'Trainers',
      location: 'Location'
    },
    buttons: {
      viewDetails: 'View details',
      addToOutlook: 'Add to Outlook',
      showMap: 'Show map',
      close: 'Close'
    },
    notifications: {
      completeFeedback: 'Complete feedback',
      completeAssessment: 'Complete assessment',
      completeRegister: 'Complete register',
      default: 'View',
      workspaceSwitched: `Your workspace has been switched.`,
      cantBeSwitchingWorkspace: `You can't be redirected to the session due to issues with switching workspace.`
    }
  },
  lms: {
    customer: {
      title: {
        contacts: 'Contacts',
        customers: 'Customers',
        subscriptions: 'subscriptions',
        services: 'Services'
      },
      labels: {
        details: 'Details',
        contacts: 'Contacts',
        services: 'Services',
        workspaces: 'Workspaces',
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        address: 'Address',
        notes: 'Notes',
        url: 'url',
        emailPhone: 'email/phone',
        actions: 'actions',
        status: 'Status',
        active: 'Active',
        draft: 'Draft',
        archived: 'Archived',
        currentSubscription: 'current subscription',
        subscriptionStart: 'subscription start',
        subscriptionEnd: 'subscription end',
        created: 'created',
        cancelled: 'cancelled'
      },
      buttons: {
        edit: 'Edit',
        remove: 'Remove',
        save: 'Save',
        cancel: 'Cancel'
      },
      viwAction: {
        title: `Create new contact`,
        titleName: 'Editing: ',
        notifyTitle: 'contact',
        notifyTitleEvent: 'event',
        cancelButtonText: 'Stop adding',
        cancelButtonTextEvent: 'Stop editing',
        manageButton: 'Manage',
        editButton: 'Edit',
        service: 'Service',
        notifyStopManaging: 'stop managing',
        buttonStopManaging: 'Back'
      },
      filter: {
        searchPlaceholder: 'Search contact',
        searchPlaceholderService: 'Search service',
        searchPlaceholderWorkspace: 'Search workspace',
        buttonTextWorkspace: 'Add workspace',
        buttonText: 'Add contact',
        searchCustomer: 'Search customer',
        addCustomer: 'Add customer',
        addSubscription: 'Add subscription'
      },
      emptyState: {
        contact: 'contact',
        contacts: `Contacts can be added when customer is created. Save customer data first.`,
        titleCreated: 'No contacts have been created yet.',
        titleCreatedWorkspaces: 'No Workspaces have been created yet',
        titleNotFound: 'No contacts has been found',
        titleNotFoundServices: 'No services has been found',
        workspace: 'workspace',
        titleNotFoundWorkspaces: 'No workspaces has been found',
        titleServicesCanBe: `Services can be managed when customer is created. Save customer data first.`,
        contentPlease: 'Please modify your search request and try again.',
        contentClick: 'Click Add workspace to create a new one.',
        customer: 'customer',
        customerNotFound: 'No customers has been found',
        customerNotCreate: 'No customers has been created yet',
        itemSubscription: 'subscription',
        titleSubscription: 'No subscriptions has been created yet'
      },
      notify: {
        titleText: `Save changes`,
        titleTextValidation: `Something's not quite right`,
        contentText: `You're going to leave the page. Do you want to save changes to this customer?`,
        contentValidation: `Please check that you have filled out all the fields in the form correctly and try again.`,
        cancelButton: `Cancel`,
        customButton: `Don't save`,
        dismiss: `Dismiss`,
        submitButton: `Confirm`,
        closeButton: `Close`,
        create: 'Create',
        titleTextRemove: `Remove contact`,
        notifyProcess: 'cancel adding',
        notifyTitle: 'customer',
        titleNewCustomer: 'Create new customer',
        contentTextRemove: `Do you want to remove this contact?`,
        titleCancelSubscription: `Cancel subscription`,
        contentAreYouSureCancel: `Are you sure you want to cancel this subscription?`,
        createSubscription: `Create subscription`,
        contentAreYouSureCreate: `Are you sure you want to create new subscription?`
      },
      validatorJs: `Dates in the subscription must be not in conflict with other ones.`
    },
    user: {
      account: {
        sidebar: {
          personalInfo: 'Personal Info',
          security: 'Security',
          history: 'History',
          multiFactionAuthentication: 'Two factor authentication'
        },
        personal: {
          labels: {
            firstName: 'First name',
            lastName: 'Last name',
            email: 'Email',
            loggedAs: 'Logged as: '
          }
        },
        security: {
          title: {
            text: 'Change password'
          },
          labels: {
            currentPassword: 'Current password',
            newPassword: 'New password',
            confirmPassword: 'Confirm password'
          },
          notify: {
            titleText: `Something's not quite right`,
            contentText: `Please check that you have filled out all the fields in the form correctly and try again.`,
            changePassword: 'Change password',
            contentPassword: 'Do you want to change your password?',
            cancelButtonText: `Close`,
            confirmButtonText: 'Confirm'
          }
        },
        invitation: {
          title: 'Invitation',
          button: {
            signIn: 'Sign in'
          }
        },
        history: {
          title: {
            history: 'History',
            historyNotFound: 'history not found'
          },
          filter: {
            search: 'Search History',
            pleaseModifyYourSearchRequest: 'Please modify your search request and try again.',
            noHistoryHasBeenRegisteredYet: 'No history has been registered yet.'
          },
          labels: {
            event: 'Event',
            sampleDescription: 'Date time',
            notes: 'Notes'
          },
          invitation: {
            someIssues: `Some issues has occurred while performing`,
            contentPlease: `of an invitation. Please try again or contact an administrator.`,
            rejected: 'Workspace invitation has been rejected.',
            accepted: 'Workspace invitation has been accepted.',
            already: 'Workspace invitation has been already resolved.'
          }
        },
        mfa: {
          title: {
            multiFactionAuthentication: 'Two factor authentication',
            youHaveMfa: 'You have to set up two factor authentication to perform any kind of action on your account.',
            firstStep: '1. Scan QR code by your Google Authenticator mobile app',
            secondStep: '2. Type generated authentication code',
            enabled: '2FA security is enabled',
            youCantDisable: 'You can\'t disable 2FA security as long as you are superadmin.',
            doYouWant: 'Do you want to disable 2FA security?'
          },
          buttons: {
            submitCode: 'Submit Code',
            disableMfa: 'Disable 2FA'
          },
          customMessage: {
            successfullyDisabled: 'You have successfully disabled two factor authentication security.',
            successfullyForTheUser: 'You have successfully reset two factor authentication for the user.'
          }
        }
      },
      achievements: {
        labels: {
          achievements: 'Achievements',
          createNewAchievement: 'Create new achievement',
          editAchievement: 'Edit achievement',
          details: 'Achievement detail',
          completion: 'Completion rules',
          name: 'Name',
          actions: 'Actions',
          status: 'Status',
          reward: 'Reward',
          description: 'Description'
        },
        text: {
          achievements: 'Achievements',
          cancel: 'cancel adding',
          addReward: 'Add reward',
          cantFindReward: 'Can\'t find reward? You can make one by',
          clickingHere: 'clicking here',
          HereYouCanSetupTheTasks: 'Here you can setup the tasks that must be complete to be rewarded with this achievement.'
        },
        name: {
          achievements: 'achievements',
          search: 'Search achievements',
          reward: 'reward'
        },
        buttons: {
          add: 'Create achievement',
          cancel: 'Stop editing',
          edit: 'Edit',
          preview: 'Preview',
          close: 'Close',
          saveAchievement: 'Save achievement',
          next: 'Next',
          save: 'Save',
          view: 'View',
          resetChanges: 'Reset changes',
          saveChanges: 'Save changes'
        },
        notify: {
          titleText: `Save changes`,
          titleTextValidation: `Something's not quite right`,
          contentText: `You're going to leave the page. Do you want to save changes to this customer?`,
          contentValidation: `Please check that you have filled out all the fields in the form correctly and try again.`,
          cancelButton: `Cancel`,
          customButton: `Don't save`,
          dismiss: `Dismiss`,
          submitButton: `Confirm`,
          closeButton: `Close`,
          create: 'Create',
          titleTextRemove: `Remove contact`,
          notifyProcess: 'cancel adding',
          notifyTitle: 'customer',
          titleNewCustomer: 'Create new customer',
          contentTextRemove: `Do you want to remove this contact?`,
          titleCancelSubscription: `Cancel subscription`,
          contentAreYouSureCancel: `Are you sure you want to cancel this subscription?`,
          createSubscription: `Create subscription`,
          contentAreYouSureCreate: `Are you sure you want to create new subscription?`,
          successfullyUpdated: 'Achievements successfully updated.',
          wasNotBeenUpdated: 'Achievements was not been updated.',
          saveChanges: 'Save changes',
          leaveThePage: `You're going to leave the page. Do you want to save changes to this achievement?`,
          created: 'Achievement successfully created.',
          notCreated: 'Achievement has not been created.',
          removed: 'Achievement successfully removed.',
          somethingWentWrong: 'Something went wrong while removing achievement. Try again or contact an administrator.'
        }
      },
      reward: {
        labels: {
          rewardName: 'Reward name',
          description: 'Description',
          rewardType: 'Reward type',
          chooseRewards: 'Choose rewards',
          certificate: 'Certificate',
          content: 'Content'
        },
        notifications: {
          updated: 'Reward successfully updated.',
          notUpdated: 'Reward was not been updated.',
          created: 'Reward successfully created.',
          notCreated: 'Reward has not been created.'
        }
      }
    },
    workspace: {
      title: {
        welcomeBack: 'Welcome back',
        pleaseChoose: 'Please choose a space to access',
        workspaces: 'Workspaces',
        services: 'Services',
        users: 'Users'
      },
      search: {
        searchWorkspaces: 'Search workspaces',
        searchUsers: 'Search users',
        noWorkspacesHasBeenFound: 'No workspaces has been found',
        pleaseModifyYourSearch: 'Please modify your search request and try again.',
        youAreNotAssigned: 'You are not assigned to any workspace yet.',
        event: 'Event',
        addWorkspace: 'Add workspace'
      },
      buttons: {
        logout: 'Log out',
        access: 'Access',
        inviteUser: 'Invite learner',
        resend: 'Resend',
        cancel: 'Cancel',
        edit: 'Edit',
        view: 'View'
      },
      viewAction: {
        titleNewInvitation: `Create new invitation`,
        titleNewWorkspace: 'Create new workspace',
        notifyTitleWorkspace: 'workspace',
        notifyTitleEvent: 'event',
        processWorkspace: 'cancel adding',
        processStopEditing: 'Stop editing',
        cancelButton: 'Cancel',
        editing: 'Editing: ',
        user: 'user',
        processStopAdding: 'Stop adding',
        stopCreatingButton: 'Stop creating',
        contact: 'contact',
        cancelBackButton: 'Back to learners list'
      },
      labels: {
        customer: 'Customer',
        name: 'Workspace name',
        firstName: 'First name',
        lastName: 'Last name',
        expiryDate: 'Expiry date',
        description: 'Description',
        status: 'Status',
        active: 'Active',
        draft: 'Draft',
        archived: 'Archived',
        details: 'Details',
        users: 'Users',
        roles: 'Roles',
        email: 'Email',
        actions: 'Actions',
        inactive: 'Inactive',
        expired: 'Expired'
      },
      emptyState: {
        user: 'User',
        workspace: 'workspace',
        workspaceNotFound: 'No workspaces has been found',
        workspaceNotCreated: 'No Workspaces have been created yet',
        userNotFound: 'No learners have been found',
        userNoUser: 'No learners have been created yet',
        userCanBe: `Learners can be added when workspace is created. Save workspace data first.`,
        content: 'Please modify your search request and try again.',
        contentClick: 'Click Add workspace to create a new one.',
        saveChanges: `Save changes`,
        leaveThePage: `You're going to leave the page. Do you want to save changes to this workspace?`,
        dontSave: `Don't save`,
        confirm: `Confirm`,
        cancel: `Cancel`
      },
      notify: {
        titleTextValidation: `Something's not quite right`,
        contentValidation: `Please check that you have filled out all the fields in the form correctly and try again.`,
        closeButton: `Close`,
        cancelInvitation: `Revoke invitation`,
        areYouSureCancelInvitation: `Are you sure want to revoke this invitation?`,
        confirm: `Confirm`,
        dismiss: `Dismiss`,
        titleResend: `Resend invitation`,
        areYouSureResend: `Are you sure want to resend this invitation?`
      }
    },
    superadmin: {
      title: {
        superadmins: 'Superadmins',
        superadmin: 'superadmin',
        addSuperadmin: 'Add superadmin'
      },
      labels: {
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        status: 'Status',
        active: 'Active',
        inactive: 'Inactive',
        expired: 'Archived',
        actions: 'actions',
        name: 'Name'
      },
      viewAction: {
        titleNewInvitation: 'Create new invitation',
        notifyTitleSuperadmin: 'Superadmin',
        processCancel: 'cancel adding',
        cancelButtonText: 'Back to superadmin list',
        searchSuperadmin: 'Search Superadmin'
      },
      buttons: {
        resend: 'Resend',
        cancel: 'Cancel',
        inviteSuperadmin: 'Invite superadmin',
        view: 'View',
        edit: 'Edit',
        resetMFA: 'Reset 2FA',
        resetPassword: 'Reset password'
      },
      emptyState: {
        titleNotFoundSuperAdmin: 'No superadmin has been found',
        titleNotCreated: 'No superadmin has been created yet',
        contentPlease: 'Please modify your search request and try again.'
      },
      popNotify: {
        validationError: `Something's not quite right`,
        contentTextValid: `Please check that you have filled out all the fields in the form correctly and try again.`,
        closeButton: `Close`,
        cancelInvitation: `Cancel invitation`,
        resendInvitation: `Resend invitation`,
        contentTextInvitation: `Are you sure want to cancel this invitation?`,
        dismissButton: `Dismiss`,
        confirmButton: `Confirm`,
        removeAdmin: `Remove admin`,
        content: `Are you sure want to remove this admin?`,
        cancel: `Cancel`,
        process: 'cancel adding',
        resetMFA: 'Reset 2FA',
        textMFA: `Are you sure you want to reset user's two factor authentication tool?`,
        titlePassword: `Send password reset?`,
        resetPassword: 'Continue',
        textPassword: `The user will receive an email with instructions and a unique code to reset their password.`
      }
    },
    mediaLibrary: {
      titles: {
        mediaLibrary: 'Media library'
      },
      labels: {
        title: 'Title',
        description: 'Description',
        altText: 'Alt text',
        isPrivate: 'Is private'
      },
      navigator: {
        titleNavigator: 'Delete media',
        areYouSure: 'Are you sure you want to delete this media'
      },
      buttons: {
        upload: 'Upload',
        close: 'Close'
      },
      text: {
        selectWorkspace: 'You have to select workspace to access Media Library.',
        checkThisOption: 'Check this option if you don\'t want to share this file with anyone',
        fileName: 'File name',
        fileType: 'File type',
        uploadDate: 'Upload date',
        validationError: `Something's not quite right`,
        fieldsAreNotValid: 'Please check that you have filled out all the fields in the form correctly and try again.'
      },
      storageFile: {
        errorDownload: 'There has been error while downloading the media.',
        removedSuccessfully: 'File has been removed successfully.',
        removingFile: 'There were issues while removing the file.',
        updatedTheMedia: 'You have successfully updated the media.',
        errorUpdatedTheMedia: 'There has been error while updating the media.',
        somethingWentWrong: 'Something went wrong while downloading media. Please try again or contact an administrator.',
        successfully: 'Files has been uploaded successfully.',
        customMessage: 'There were issues while uploading files.'
      },
      componentLibrary: {
        delete: 'Delete',
        deleteMedia: `Delete media`,
        areYouSure: `Are you sure you want to delete the selected media`,
        pleaseModify: 'Please modify your search request and try again.',
        noItems: 'No items have been found',
        selectMultimedia: 'Select multiple',
        upload: 'You have to select workspace before uploading any media.'
      }
    }
  },
  ebs: {
    calendar: {
      title: {
        spacesAvailable: 'spaces available'
      },
      labels: {
        events: 'Events',
        iAmBooked: 'I am booked onto',
        iAmTraining: 'I am training',
        available: 'Available on my enrolled courses',
        venues: 'Venues',
        rooms: 'Rooms',
        trainers: 'Trainers'
      },
      buttons: {
        viewDetails: 'View details',
        today: 'Today',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        list: 'List',
        generate: 'Generate'
      }
    },
    emails: {
      title: {
        search: 'Search templates',
        NotFound: 'No emailtemplates have been found',
        NotCreated: 'No emailtemplates have been created yet'
      },
      content: {
        tryAgain: 'Please modify your search request and try again.',
        content: 'Emailtemplates are the facilitators of a session and are in charge of the register and summary report.'
      }
    },
    events: {
      add: {
        title: {
          newEvent: 'Create new event',
          event: 'event'
        },
        labels: {
          details: 'Details',
          sessions: 'Session settings',
          bookings: 'Bookings',
          cancellations: 'Cancellations',
          feedback: 'Completions and feedback',
          cost: 'Costs'
        },
        buttons: {
          cancelAdd: 'cancel adding',
          cancel: 'Cancel'
        }
      },
      edit: {
        title: {
          event: 'event',
          editing: 'Editing: ',
          notifyEditing: 'stop editing',
          theBookingLimitText: 'The booking limit will be set to the smallest room for sessions using multiple rooms.',
          before: 'before session start date',
          ConfigureBookingForm: 'Configure booking form',
          learnersMust: 'Learners must complete a booking form when booking onto a session',
          addNewReason: 'Add a new reason on each line',
          cancellationReason: 'Cancellation reasons',
          automaticCancellations: 'Automatic cancellations',
          completion: 'Completion',
          hintsEvent: 'Events are completed when a learner is present (or has a valid absence) on all session dates. Can be overwritten on the summary report by the trainer.',
          registerAttendance: 'Registers are where attendance is recorded. There is one register for each date of a session.',
          whoCan: 'Who can complete registers',
          trainers: 'Trainers',
          absenceReason: 'Absence reasons',
          askAbsenceReason: 'Ask for absence reason',
          askWhenAbsenceReason: 'Ask learners for a reason when they are absent from a session or date',
          feedback: 'Feedback',
          trainersWillLeave: 'Trainers will leave this feedback on the summary report',
          configureFeedback: 'Configure feedback form',
          associateACost: 'Associate a cost with the event',
          changeEventCost: 'Change the event cost for different sectors and services',
          thisEventIsBeing: 'This event is being used on these courses',
          titleDetails: 'Active events are visible to learners in the course library. Draft events are visible to administrators and event coordinators.' +
          ' Archived events are hidden in the course library. Events can only be archived if they have no active sessions and aren’t being used on any courses.',
          sessionsAreInstances: 'Set your default settings sessions below when creating event.',
          theDefaultLocation: 'The default location will be automatically selected for newly created sessions. Locations can be changed by editing a session. Can’t find the right location?',
          trainersArePeople: 'Trainers are people who facilitate an event and take attendance.',
          titleDefaultTrainers: 'Default trainer(s) will be automatically selected for newly created sessions. Trainers can be changed by editing a session. Can’t find the right trainer?',
          acceptingBookings: 'Accepting bookings',
          bookingForm: 'Booking form',
          registers: 'Registers',
          sessionReminder: 'Session reminder'
        },
        name: {
          roomCapacity: 'Room capacity',
          specificValue: 'Specific value',
          hours: 'Hours',
          days: 'Days',
          beforeStart: 'before session start date',
          attendance: 'Attendance',
          whenSessionEnds: 'When session ends (automatic)',
          whenAllRegisters: 'When all registers are completed',
          trainerAssessment: 'Trainer assessment',
          learnersAreMarked: 'Learners are marked as passed/not passed on the assessment report by the trainer.',
          assignedToThisEvent: 'Assigned to this event',
          anyEventCoordinator: 'Any event coordinator',
          addNewReason: 'Add a new reason on each line',
          name: 'name',
          description: 'Description',
          image: 'Image',
          coordinator: 'coordinator',
          active: 'Active',
          draft: 'Draft',
          sessionReminderNotificationLearner: 'Session reminder - for learner',
          sessionReminderNotificationTrainer: 'Session reminder - for trainer'
        },
        notification: {
          maximumBookingReached: 'Minimum booking reached',
          bookingCapacity: 'Booking capacity is reached on session',
          trainerFeedback: 'Trainer feedback',
          pleaseComplete: 'Please complete the form below'
        },
        buttons: {
          stopEditing: 'Stop editing',
          bookOnto: `Book onto session`,
          viewVenues: 'View venues.',
          viewTrainers: 'View trainers.'
        },
        labels: {
          maximumBookingLimits: 'Maximum booking limit',
          minimumBookingLimits: 'Minimum booking limit',
          enableRegisters: 'Enable registers',
          bookingLimits: 'Booking limits',
          setLimitTo: 'Set booking limit to',
          accept: 'Accept bookings up to',
          allowBooking: 'Allow bookings without course enrolment',
          bookingForm: 'Booking form',
          learnersCanCancel: 'Learners can cancel their bookings',
          AllowBookingsCancel: 'Allow bookings to be cancelled',
          beforeSession: 'before session start date',
          askForCancellationReason: 'Ask for cancellation reason',
          askLearners: 'Ask learners for a reason when they cancel their booking',
          cancellationReason: 'Cancellation reasons',
          addNewReason: 'Add a new reason on each line',
          automaticallyCancel: `Automatically cancel session if minimum bookings aren't reached`,
          absenceReason: 'Absence reasons',
          askTrainersToLeave: 'Ask trainers to leave feedback after a session ends',
          eventCost: 'Event cost',
          eventHasCost: 'Event has a cost',
          costOverrides: 'Cost overrides',
          name: 'Name',
          eventImage: 'Event images',
          eventCoordinator: 'Event coordinator',
          defaultLocation: 'Default location',
          thisEventHasTrainers: 'This event has trainers',
          defaultTrainers: 'Default trainers',
          sidebarDetails: 'Details',
          sidebarSessions: 'Session settings',
          sidebarBookings: 'Bookings',
          sidebarCancellations: 'Cancellations',
          sidebarCompletionsAndFeedback: 'Completions and feedback',
          sidebarCost: 'Cost',
          sessionCoordinators: 'Session coordinators',
          registers: 'Registers',
          clickToSelect: 'Click to select',
          calculateEventCompletionOn: 'Calculate event completion on',
          eventCoordinators: 'Event coordinators',
          status: 'Status',
          sessionReminderCheck: 'Send a session reminder to learners and trainers',
          sessionReminderAfter: 'before session start date'
        },
        notify: {
          titleError: `Something's not quite right`,
          textNotValid: `Please check that you have filled out all the fields in the form correctly and try again.`,
          buttonClose: `Close`,
          titleChanges: `Changes won't affect sessions`,
          textKeepInMind: `At least one field in the event is going to be updated. Keep in mind these changes won't affect to the already existing sessions.`,
          buttonConfirm: `Confirm`,
          titleDraftSession: `Inactive session`,
          textDraftSession: `At least one session in the event is not activated.`,
          titleDraftEvent: `Inactive session`,
          textDraftEvent: `Events with inactive statuses are not visible for learners.`,
          titleSession: `Changes won't affect sessions`,
          textSession: '`At least one field in the event is going to be updated. Keep in mind these changes won\'t affect to the already existing sessions.`'
        }
      },
      list: {
        title: {
          events: 'Events',
          selectEvent: 'Select an event'
        },
        buttons: {
          buttonCreateEvent: 'Create event',
          buttonCancel: 'Cancel',
          addSelectEvent: 'Add selected event',
          selected: 'Selected',
          select: 'Select',
          buttonView: 'View'
        },
        labels: {
          searchEvents: 'Search events',
          name: 'Name',
          sessions: 'Sessions',
          nextSession: 'Next session',
          status: 'Status',
          courses: 'Courses',
          actions: 'Actions',
          venues: 'Venues',
          rooms: 'Rooms',
          trainers: 'Trainers'
        },
        emptyState: {
          titleEventsNoFound: 'No events have been found',
          contentEventModify: 'Please modify your search request and try again.',
          titleEventsNotCreate: 'No events have been created yet',
          contentSessionWhichLearners: 'Events contain sessions, which learners can book onto and attend at a given place or time.'
        }
      },
      show: {
        title: {
          event: 'Event',
          events: 'Events'
        },
        buttons: {
          buttonPrint: 'Print details',
          buttonEdit: 'Edit event'
        }
      }
    },
    reports: {
      title: {
        reports: 'Reports',
        events: 'Events',
        sessions: 'Sessions',
        displayOrDownloadCCSV: 'Display or download CSV',
        report: 'report.',
        reportView: 'report'
      },
      buttons: {
        details: 'Details',
        generate: 'Generate',
        exportCSV: 'Export CSV'
      },
      note: {
        noDataHasBeenLoaded: 'No data has been loaded yet. Set filters and click "Apply" button to create the report.',
        selectedFilter: 'There is no data loaded for selected filters. Modify them and generate the report once again.'
      }
    },
    rooms: {
      add: {
        title: {
          create: 'Create new room',
          notifyTitle: 'room',
          notifyProcess: 'cancel adding'
        },
        buttonCancel: 'Cancel'
      },
      edit: {
        title: {
          editing: `Editing: `,
          notifyTitle: 'room',
          notifyProcess: 'stop editing',
          venues: 'Venues',
          visibility: 'Visibility'
        },
        text: {
          errorText: 'Please enter a maximum capacity for the venue.',
          instructions: 'Instructions detailing how to get to the room after arriving at the venue.',
          activeRooms: 'Active rooms and their rooms can be used in events. Draft rooms are visible to administrators and event coordinators and cannot be used in events.' +
          'Rooms can only be reverted to draft if they have are not being used for any active sessions.'
        },
        labels: {
          name: 'Name',
          description: 'Description',
          images: 'Images',
          location: 'Location',
          capacity: 'Capacity',
          cost: 'Cost',
          status: 'Status'
        },
        name: {
          name: 'name',
          description: 'description',
          images: 'images',
          location: 'location',
          capacity: 'capacity',
          cost: 'cost',
          status: 'status'
        },
        items: {
          perHour: 'Per hour',
          perDay: 'Per day',
          perWeek: 'Per week',
          active: 'Active',
          draft: 'Draft'
        },
        notify: {
          title: `Something's not quite right`,
          content: `Please check that you have filled out all the fields in the form correctly and try again.`,
          close: `Close`
        },
        buttons: {
          stop: 'Stop editing',
          go: 'Go back'
        }
      },
      list: {
        title: {
          rooms: 'Rooms'
        },
        text: {
          search: 'Search rooms',
          capacity: 'Capacity: ',
          people: 'people'
        },
        buttons: {
          createRooms: 'Create room',
          view: 'View'
        },
        emptyState: {
          room: 'room',
          titleFound: 'No rooms have been found',
          modify: 'Please modify your search request and try again.',
          titleCreated: 'No rooms have been created yet',
          takePlace: 'Rooms sit inside a venue and are where sessions take place.'
        },
        labels: {
          name: 'name',
          capacity: 'capacity',
          status: 'status',
          actions: 'actions'
        }
      },
      show: {
        title: {
          venues: 'Venues',
          process: 'room'
        },
        buttons: {
          edit: 'Edit room',
          view: 'View settings'
        },
        contactRoom: 'Contact'
      }
    },
    sessions: {
      add: {
        title: {
          createSession: 'Create new session',
          session: 'Session',
          process: 'cancel adding'
        },
        name: {
          events: 'Events'
        },
        labels: {
          details: 'Details',
          booking: 'Booking',
          cancellations: 'Cancellations',
          feedback: 'Completions and feedback',
          cost: 'Cost'
        },
        buttonCancel: 'Cancel'
      },
      edit: {
        title: {
          session: 'session',
          processStop: 'stop editing',
          editing: `Editing: `,
          bookingLimits: 'Booking limits',
          acceptBookings: 'Accepting bookings',
          configureBookingForm: 'Configure booking form',
          completion: 'Completion',
          whoCan: 'Who can complete registers',
          feedback: 'Feedback',
          configureFeedback: 'Configure feedback form',
          automaticCancellations: 'Automatic cancellations',
          dateAndTime: 'Date and time',
          visibility: 'Visibility',
          bookingForm: 'Booking form',
          absenceReasons: 'Absence reasons',
          registers: 'Registers'
        },
        name: {
          events: 'Events',
          roomCapacity: 'Room capacity',
          specificValue: 'Specific value',
          hours: 'Hours',
          days: 'Days',
          maximumBookings: 'Maximum bookings reached',
          attendance: 'Attendance',
          whenSessionEnds: 'When session ends (automatic)',
          whenAllRegisters: 'When all registers are completed',
          trainerAssessment: 'Trainer assessment',
          assignedTo: 'Assigned to this session',
          anySession: 'Any session coordinator',
          nameActive: 'Active',
          nameDraft: 'Draft'
        },
        labels: {
          minimumBookingLimits: 'Minimum booking limit',
          enableRegisters: 'Enable registers',
          setBookingLimitTo: 'Set booking limit to',
          maximumLimit: 'Maximum booking limit',
          acceptBookings: 'Accept bookings up to',
          afterStart: 'before session start date',
          allowBookings: 'Allow bookings without course enrolment',
          learnerIsBooked: 'Learner is booked onto session',
          notificationTo: 'Notification to Event coordinator',
          bookingForm: 'Booking form',
          learnersCanCancel: 'Learners can cancel their bookings',
          allowBooking: 'Allow bookings to be cancelled',
          beforeStart: 'before session start date',
          askReason: 'Ask for cancellation reason',
          cancellationReason: 'Cancellation reasons',
          allowOther: `Allow 'Other' reasons`,
          automaticallyCancel: `Automatically cancel session if minimum bookings aren't reached`,
          calculate: 'Calculate session completion on',
          trainers: 'Trainers',
          askAbsenceReason: 'Ask for absence reason',
          absenceReason: 'Absence reasons',
          askTrainersToLeave: 'Ask trainers to leave feedback after a session ends',
          sessionHasACost: 'Session has a cost',
          sessionCost: 'Session cost',
          costOverrides: 'Cost overrides',
          labelFrom: 'From',
          labelTo: 'To',
          labelLocation: 'Location',
          labelStatus: 'Status',
          details: 'Details',
          booking: 'Booking',
          cancellations: 'Cancellations',
          feedback: 'Completions and feedback',
          cost: 'Cost',
          sessionCoordinators: 'Session coordinators'
        },
        buttons: {
          stop: 'Stop editing'
        },
        text: {
          bookingLimit: 'The booking limit will be set to the smallest room for sessions using multiple rooms.',
          learnersMustComplete: 'Learners must complete a booking form when booking onto a session',
          askLearners: 'Ask learners for a reason when they cancel their booking',
          addANewReason: 'Add a new reason on each line',
          beforeStart: 'before session start date',
          sessionAreCompleted: 'Sessions are completed when a learner is present (or has a valid absence) on all session dates. Can be overwritten on the summary report by the trainer.',
          learnersAreMarked: 'Learners are marked as passed/not passed on the assessment report by the trainer.',
          registersAreWhere: 'Registers are where attendance is recorded. There is one register for each date of a session.',
          askLearnersAbsent: 'Ask learners for a reason when they are absent from a session or date',
          trainersWillLeave: 'Trainers will leave this feedback on the summary report',
          associateACost: 'Associate a cost with the session',
          changeSession: 'Change the session cost for different sectors and services',
          addAnotherDate: 'Add another date',
          sameLocation: 'Same location per date',
          sameTrainers: 'Same trainers per date',
          differentLocation: 'Different location per date',
          differentTrainers: 'Different trainers per date',
          thisDateTime: 'This date/time is outside of the',
          trainer: 'trainer',
          trainers: 'trainers',
          standardWorkingHours: 'standard working hours.',
          activeSession: 'Active sessions are visible to learners in the course library.' +
          'Draft sessions are visible to administrators and event coordinators.' +
          'Sessions can only be made draft if they have no bookings.'
        },
        notify: {
          errorText: `Something's not quite right`,
          contentText: `Please check that you have filled out all the fields in the form correctly and try again.`,
          closeNotify: `Close`,
          validateCustomError: `The 'from' date field must be before or equal 'to' date field.`,
          validateCustomErrorTo: `The 'to' date field must be after or equal 'from' date field.`,
          beforeNotify: `before:`,
          afterNotify: `after:`
        },
        formNotification: {
          name: 'Maximum bookings reached',
          label: 'Booking capacity is reached on session'
        }
      },
      list: {
        title: {
          sessionsTitle: 'Sessions',
          expressInterest: 'Express interest in future session dates',
          youWillBeNotified: 'You will be notified when any future sessions are created.',
          eventSubscriptions: 'Event subscriptions'
        },
        name: {
          expressedInterest: 'Expressed interest'
        },
        labels: {
          name: 'name',
          email: 'email',
          username: 'username',
          date: 'date',
          location: 'locations',
          trainers: 'trainers',
          spacesAvailable: 'spaces available',
          status: 'status',
          actions: 'actions'
        },
        text: {
          searchSessions: 'Search sessions',
          listOfPeople: 'List of people that expressed interest in event'
        },
        buttons: {
          unsubscribe: 'Unsubscribe',
          noSuitable: 'No suitable sessions?',
          cancel: 'Cancel',
          expressInterest: 'Express interest',
          createSession: 'Create session',
          close: 'Close',
          remove: 'Remove',
          confirm: 'Confirm',
          view: 'View'
        },
        emptyState: {
          noSessionsHasBeenFound: 'No sessions has been found',
          pleaseModifyYourSearch: 'Please modify your search request and try again.',
          noSessionsHasBeenCreatedYet: 'No sessions has been created yet',
          sessionsAreOccurrences: 'Sessions are occurrences of an event - and what learners book onto.'
        },
        notify: {
          removeSubscriptions: 'Remove subscriptions',
          selectedSubscriptions: 'Selected subscriptions will be removed from the event.'
        },
        return: {
          noSpacesAvailable: 'No spaces available',
          spaces: ` spaces`,
          space: ` space`,
          available: ` available`
        }
      },
      register: {
        title: {
          date: 'Date',
          register: `Register`,
          valid: 'Valid',
          invalid: 'Invalid',
          preview: 'Preview booking form'
        },
        name: {
          events: 'Events',
          present: 'Present',
          absent: 'Absent',
          chooseReason: 'Choose reason',
          valid: 'Valid',
          invalid: 'Invalid'
        },
        labels: {
          present: 'Present',
          absent: 'Absent',
          name: 'name',
          email: 'email',
          attendance: 'attendance',
          bookingForm: 'Booking Form',
          notes: 'Notes'
        },
        buttons: {
          resetChanges: 'Reset changes',
          saveChanges: 'Save changes',
          commit: 'Submit',
          view: 'View'
        },
        notify: {
          validationError: `Something's not quite right`,
          validationText: `Please check that you have filled out all the fields in the form correctly and try again.`,
          close: 'Close',
          completeRegister: `Submit register`,
          complitingText: `Submitting the register report will set the completion status for all learners on this session and cannot be undone. Do you want to continue?`,
          cancel: `Cancel`,
          complete: `Submit register report`
        },
        return: {
          completeTheRegister: 'Complete the register by marking the attendance for all people listed.',
          register: 'register',
          printing: 'printing',
          success: 'success'
        }
      },
      show: {
        title: {
          cancelSession: 'Cancel session',
          trainerAssessment: 'Trainer assessment',
          of: 'of',
          unableBecause: 'Unable to book because:',
          please: 'Please:',
          cancellationReason: 'Cancellation reason',
          feedbackForm: 'Feedback form',
          trainerFeedback: 'Trainer feedback',
          actionNotAllowed: 'Action not allowed',
          thereAreNotEnough: 'There are not enough spaces available',
          bookingForm: 'Booking form',
          removeFromWaitlist: 'Remove from waitlist',
          invitePerson: 'Invite person',
          bookPerson: 'Book person',
          cancelInvite: 'Cancel invite',
          resendInvite: 'Resend invite',
          declineBooking: 'Decline booking request',
          approveBooking: 'Approve booking request',
          cancelBooking: 'Cancel booking',
          cancellation: 'Cancellation',
          previewBookingForm: 'Preview booking form',
          otherEvent: `Other event sessions`
        },
        name: {
          events: 'Events',
          session: 'session'
        },
        text: {
          cancelSession: 'Cancel session',
          cancelSessionAnd: 'Cancel session and copy bookings',
          editSession: 'Edit session',
          pleaseCompleteTheForm: 'Please complete the form below to book onto this session.',
          pleaseConfirmYourBooking: `Please confirm your booking by clicking the 'Book onto session' button below.`,
          areYouSure: 'Are you sure you want to cancel your booking?',
          coordinatorWillReceive: 'The event coordinator will receive a notification of your cancellation.',
          attendance: 'Attendance',
          reason: 'Reason',
          searchPeople: 'Search people',
          bookOnTo: 'Book onto session',
          alertMustBe: 'Learners must be enrolled on courses containing this event to be bookable.',
          bookPeople: 'Book people',
          selectPeople: 'Select people to book onto this session. Selected people will receive a booking confirmation notification.',
          davidBowie: 'David Bowie completed this booking form on 1st January 2019:',
          areYouSureRemove: 'Are you sure you want to remove this person from the waitlist?' + '\n\n' + 'They will recieve a notification informing them of their removal.',
          areYouSureInvite: 'Are you sure you want to invite this person to the session?' + '\n\n' + 'A notification will be sent to the person notifying them of session invite.',
          areYouSureBook: 'Are you sure you want to book this person to the session?' + '\n\n' + 'A notification will be sent to the person notifying them of their booking.',
          areYouWontCancel: 'Are you sure you want to cancel this person’s invite?',
          areYouSureResend: 'Are you sure you want to resend an invite this person?' + '\n\n' + 'A notification will be sent to the person notifying them of session invite.',
          areYouSureDecline: 'Are you sure you want to decline this booking request?' + '\n\n' + 'A notification will be sent to the person notifying them of their declined booking request.',
          areYouSureApprove: 'Are you sure you want to approve this booking request?' + '\n\n' + 'A notification will be sent to the person notifying them of their successful booking.',
          areYouSureCancel: 'Are you sure you want to cancel this person’s booking?' + '\n\n' + 'A notification will be sent to the person notifying them of their booking cancellation.'
        },
        labels: {
          dates: 'dates',
          rooms: 'rooms',
          trainers: 'trainers',
          availableSpaces: 'available spaces',
          id: 'id',
          name: 'name',
          email: 'email',
          username: 'username',
          status: 'status',
          actions: 'actions'
        },
        buttons: {
          printFlyer: 'Print details',
          dismiss: 'Dismiss',
          print: 'Print',
          view: 'View',
          bookOnto: 'Book onto session',
          cancelBooking: 'Cancel booking',
          cancel: 'Cancel',
          goBack: 'Go back',
          moreDetails: 'More details',
          apply: 'Apply',
          bookPeople: 'Book people',
          close: 'Close',
          remove: 'Remove',
          continue: 'Continue',
          cancelInvite: 'Cancel Invite',
          declineRequest: 'Decline request',
          approveRequest: 'Approve request',
          viewBookingForm: 'View booking form',
          invite: 'Invite',
          book: 'Book',
          resend: 'Resend',
          decline: 'Decline',
          approve: 'Approve',
          viewCancellation: 'View cancellation',
          viewAll: 'View all',
          leaveWaitlist: 'Leave waitlist',
          joinWaitlist: 'Join waitlist',
          save: 'Save'
        },
        notify: {
          session: 'session',
          sessionCancel: 'cancel',
          unableToArchiveSession: 'Unable to archive session',
          unableToArchiveSessionText: 'Unable to archive sessions which are accepting bookings or have bookings. Please cancel the session before attempting to delete.',
          close: 'Close',
          bookPerson: 'Book person onto session',
          pleaseComplete: 'Please complete the form below to book user onto this session.',
          cancel: 'Cancel',
          bookOnto: 'Book onto session',
          bookPeople: 'Book people onto session',
          textPeople: 'This event has a booking form enabled, bulk booking will not fill out these booking forms.',
          bulkBooking: 'Bulk booking',
          textBooking: 'Are you sure you want to bulk book on the session all selected people?',
          confirm: 'Confirm',
          pleaseCompleteThe: 'Please complete the form below',
          youHaveBooked: 'You have booked onto this session',
          somethingWentWrong: 'Something went wrong. Try again or contact an administrator.'
        },
        table: {
          transferBooking: 'Transfer bookings',
          selectSession: 'Select session to transfer bookings.',
          close: 'Close',
          confirm: 'Confirm'
        },
        description: {
          permanentlyCancelLong: `Do you want to permanently cancel this session? There are some active bookings on it. You may just cancel this session or cancel it and copy all active bookings to another session`,
          permanentlyCancelMedium: `Do you want to permanently cancel this session? There are some active bookings on it but there is no another active session where you may move them`,
          permanentlyCancelShort: `Do you want to permanently cancel this session?`,
          register: `Register`,
          assessmentReport: 'Assessment Report',
          bookingCancelled: 'booking has been cancelled on',
          becauseReason: 'because of the following reason:',
          acceptingCancellationsUntil: 'Accepting cancellations until',
          acceptingBookingsUntil: 'Accepting bookings until'
        }
      },
      summary: {
        title: {
          trainerAssessment: `Trainer assessment`,
          assessmentReport: 'Assessment report',
          completeText: 'Complete the assessment report by marking the people listed.'
        },
        text: {
          date: 'Date',
          absent: 'Absent (Valid reason) Bereavement'
        },
        labels: {
          passed: 'Passed',
          notPassed: 'Not passed',
          name: 'name',
          email: 'email',
          attendance: 'attendance',
          assessment: 'assessment',
          notes: 'notes'
        },
        buttons: {
          resetChanges: 'Reset changes',
          saveChanges: 'Save changes',
          commit: 'Submit',
          close: 'Close',
          details: 'Details'
        },
        notify: {
          completeSummary: `Submit summary`,
          completeSummaryText: `Submitting the summary report will set the completion status for all learners on this session and cannot be undone. Do you want to continue?`,
          cancel: `Cancel`,
          completeSummaryReport: `Submit summary report`,
          validError: `Something's not quite right`,
          validText: `Please check that you have filled out all the fields in the form correctly and try again.`,
          close: `Close`
        },
        return: {
          presentFor: 'Present for',
          dates: 'dates',
          date: 'date'
        }
      }
    },
    trainers: {
      add: {
        alert: 'All learner accounts are currently in use, create a new learner account to create a trainer.',
        title: {
          newTrainer: 'Create new trainer',
          trainer: 'trainer',
          cancelAdding: 'cancel adding'
        },
        buttonCancel: 'Cancel',
        nameTrainer: 'Trainer'
      },
      edit: {
        title: {
          editing: 'Editing: ',
          notifyTitle: 'trainer',
          stopProcess: 'stop editing'
        },
        buttons: {
          stop: 'Stop editing'
        },
        labels: {
          learnerAccount: 'Learner account',
          typeToSearch: 'Type to search learner email, name or username',
          name: 'Name',
          email: 'Email',
          images: 'Images'
        },
        text: {
          chooseLearner: 'Choose a learner account to form the basis of the new trainer. If a learner account doesn’t already' +
          'exist for the new trainer, create a new learner account.',
          standardWorkingHours: 'Standard working hours'
        },
        name: {
          name: 'name',
          user: 'user',
          email: 'email',
          images: 'images'
        },
        notify: {
          validError: `Something's not quite right`,
          validText: `Please check that you have filled out all the fields in the form correctly and try again.`,
          validContent: `Close`
        }
      },
      list: {
        title: {
          trainers: 'Trainers'
        },
        labels: {
          name: 'name',
          email: 'email',
          actions: 'actions'
        },
        buttons: {
          createTrainer: 'Create trainer',
          view: 'View'
        },
        text: {
          searchTrainer: 'Search trainers'
        },
        emptyState: {
          foundTitle: 'No trainers have been found',
          foundContent: 'Please modify your search request and try again.',
          createTitle: 'No trainers have been created yet',
          createContent: 'Trainers are the facilitators of a session and are in charge of the register and summary report.'
        }
      },
      show: {
        notifyTitle: 'trainer',
        buttonEdit: 'Edit trainer',
        nameTrainer: 'Trainers',
        titleHours: 'Standard working hours'
      }
    },
    venues: {
      add: {
        title: {
          newVenue: 'Create new venue',
          notifyVenue: 'venue',
          notifyCancel: 'Cancel',
          cancelAdding: 'cancel adding'
        },
        buttons: {
          cancel: 'Cancel'
        },
        name: {
          venues: 'Venues'
        }
      },
      edit: {
        title: {
          editing: 'Editing: ',
          notifyVenue: 'venue',
          stopEditing: 'stop editing',
          contactInformation: 'Contact information',
          visibility: 'Visibility',
          sharingTitle: 'Sharing across workspaces'
        },
        buttons: {
          stopEditing: 'Stop editing',
          goBack: 'Go back'
        },
        labels: {
          name: 'Name',
          description: 'Description',
          images: 'Images',
          email: 'Email',
          phone: 'Phone',
          status: 'Status'
        },
        name: {
          venues: 'venues',
          name: 'name',
          description: 'description',
          images: 'images',
          email: 'email',
          phone: 'phone',
          status: 'status',
          active: 'Active',
          draft: 'Draft',
          visible: 'Visible',
          hidden: 'Hidden'
        },
        textActiveVenues: 'Active venues and their rooms can be used in events.' +
        'Draft venues are visible to administrators and event coordinators and cannot be used in events.' +
        'Venues can only be reverted to draft if they have are not being used for any active sessions.',
        notify: {
          validError: `Something's not quite right`,
          validContent: `Please check that you have filled out all the fields in the form correctly and try again.`,
          close: `Close`
        }
      },
      list: {
        title: {
          venues: 'Venues'
        },
        text: {
          searchVenues: 'Search venues'
        },
        labels: {
          name: 'name',
          rooms: 'rooms',
          status: 'status',
          actions: 'actions'
        },
        buttons: {
          createVenues: 'Create venue',
          view: 'View'
        },
        emptyState: {
          foundTitle: 'No venues have been found',
          foundContent: 'Please modify your search request and try again.',
          createTitle: 'No venues have been created yet',
          createContent: 'Venues contain rooms, which are where sessions take place.'
        },
        return: {
          room: 'room',
          rooms: 'rooms'
        }
      },
      show: {
        notifyTitle: 'venue',
        buttonEdit: 'Edit venue',
        buttonView: 'View settings',
        nameVenue: 'Venue',
        contact: 'Contact'
      }
    }
  },
  forms: {
    ruleForm: {
      title: {
        rule: 'Rule',
        childRule: 'Child rule',
        grandChildRule: 'Grand child rule',
        add: 'Add'
      },
      labels: {
        and: 'And',
        or: 'Or',
        subset: 'Subset',
        completedWhat: 'Complete what?',
        nameOf: 'Name of',
        item: 'item',
        achievement: 'Achievement',
        moduleRule: 'Module',
        course: 'Course',
        event: 'Event',
        workbook: 'Workbook'
      },
      text: {
        completeAchievementIf: 'Complete achievement if',
        completeRuleIf: 'Complete rule if',
        rulesCompleted: 'of these rules has been completed',
        conditionsCompleted: 'of these conditions has been completed',
        maximumNumber: 'You have reached maximum number of nesting.'
      },
      notify: {
        titleText: `Delete rule`,
        contentText: `Do you want to remove this rule?`,
        cancelButtonText: 'Cancel',
        submitButtonText: 'Confirm'
      }
    },
    dataVvAs: {
      name: 'name',
      hour: 'hour',
      firstName: 'name',
      lastName: 'name',
      location: 'location',
      trainers: 'trainers',
      currentPassword: 'current password',
      newPassword: 'new password',
      confirmPassword: 'confirm password',
      notes: 'notes',
      status: 'status',
      email: 'email',
      phone: 'phone',
      customer: 'customer',
      url: 'url',
      roles: 'roles',
      address: 'address',
      capacity: 'capacity',
      minimumBookingLimits: 'minimum booking limit',
      setLimitTo: 'set booking limit to',
      maximumBookingLimits: 'maximum booking limit',
      acceptBookings: 'accept bookings up to',
      cancellationReason: 'cancellation reasons',
      absenceReason: 'absence reasons',
      eventCost: 'event cost',
      sessionCost: 'session cost',
      learnerAccount: 'learner account',
      expiryDate: 'expiry date',
      code: 'code'
    },
    submit: {
      title: 'Some of your fields are not valid',
      buttons: {
        resetChanges: 'Reset changes',
        saveChanges: 'Save changes'
      },
      notify: {
        title: `Reset changes`,
        content: `Do you want to reset all recent changes?`,
        cancel: `Cancel`,
        submit: `Confirm`
      }
    },
    price: '£',
    radioHorizontal: {
      label: 'Other',
      text: 'Describe anything you want'
    },
    select: {
      title: 'Event is not being used on any courses',
      label: 'Other reason',
      default: 'Type to search',
      buttons: {
        close: 'Close adding field',
        add: 'Add more items'
      }
    },
    customForm: {
      title: {
        thereMustBe: 'There must be at least one field in the form.',
        editField: 'Edit field',
        addField: 'Add field',
        addOption: 'Add a new option for each line',
        updateField: 'Update field',
        AddFieldTo: 'Add field to form'
      },
      labels: {
        fieldLabel: 'Field label',
        fieldType: 'Field type',
        text: 'Text',
        checkbox: 'Checkbox',
        singleChoice: 'Single choice',
        multiChoice: 'Multi choice',
        options: 'Options',
        addOtherOption: "`Add 'Other' option`",
        helpText: 'Help text',
        mandatory: 'Field is mandatory'
      },
      emptyState: {
        titleNotCreated: 'No fields have been created yet',
        contentNotCreated: 'Add text, checkboxes, single and multiple choice options.'
      },
      buttons: {
        preview: 'Preview',
        addField: 'Add field',
        cancel: 'Cancel',
        submitForm: 'Submit form',
        AddFieldTo: 'Add field to form',
        updateField: 'Update field'
      },
      default: {
        completeTheForm: 'Complete the form below to book onto session',
        submitForm: 'Submit form',
        bookOntoSession: 'Book onto session'
      }
    },
    editor: {
      dynamicTags: 'Dynamic tags',
      MeLearning: '© Me Learning'
    },
    image: {
      title: {
        dragAndDrop: 'Drag and drop a file to upload',
        or: 'or',
        PleaseEnsureUploaded: 'Please ensure uploaded images are JPG, JPEG or PNG formats',
        clickTheButton: 'Use the button below to select files.'
      },
      buttons: {
        primarySelect: 'Select',
        upload: 'Upload',
        change: 'Change files',
        selectSingle: 'Choose image',
        selectMultiple: 'Choose images'
      },
      notify: {
        titleRemove: 'Remove image',
        contentRemove: 'Are you sure you want to remove this image?',
        remove: 'Remove',
        cancel: 'Cancel'
      }
    },
    location: {
      title: {
        address: 'Address'
      },
      labels: {
        address1: 'Address 1',
        address2: 'Address 2',
        townCity: 'Town / City',
        county: 'County',
        postCode: 'Postcode',
        country: 'Country'
      },
      buttons: {
        toggle: 'Toggle to address search',
        enter: 'Enter address manually'
      },
      notify: {
        title: 'Enter the address manually',
        content: 'This will clear any current address details',
        submit: 'Confirm',
        cancel: 'Cancel'
      },
      translatedLanguages: {
        poland: 'Poland'
      }
    },
    listSelect: {
      filter: {
        search: 'Search ',
        add: 'Add '
      },
      emptyState: {
        titleNo: 'No',
        titleFound: 's has been found',
        content: 'Please modify your search request and try again.',
        titleCreated: `s has been created yet.`
      },
      buttons: {
        selected: 'Selected',
        select: 'Select'
      }
    },
    date: {
      days: {
        monday: 'monday',
        tuesday: 'tuesday',
        wednesday: 'wednesday',
        thursday: 'thursday',
        friday: 'friday',
        saturday: 'saturday',
        sunday: 'sunday'
      },
      labels: {
        dateFrom: 'From',
        dateTo: 'To'
      }
    },
    checkbox: {
      active: 'ACTIVE',
      draft: 'DRAFT',
      inactive: 'INACTIVE'
    },
    notification: 'notification when'
  },
  filter: {
    moreMenu: {
      title: {
        cost: 'Cost',
        capacity: 'Capacity'
      },
      buttons: {
        apply: 'Apply',
        clear: 'Clear',
        moreFilters: 'More filters'
      },
      options: {
        lessThan: 'Less than',
        hour: 'Hour',
        one: 'One',
        two: 'Two'
      }
    },
    select: {
      itemExtra: `Specific`,
      reset: 'Reset'
    },
    sort: {
      buttonSortBy: 'Sort by',
      menuToDo: 'To do'
    },
    baseFilter: {
      search: 'Search',
      name: 'name',
      submit: 'Submit',
      cancel: 'Cancel'
    }
  },
  shared: {
    components: {
      title: {
        details: 'Details',
        capacity: 'Capacity',
        people: 'people',
        cost: 'Cost',
        per: 'per',
        currency: '£',
        NotAvailable: 'Not available',
        note: 'The hours listed can only be edited by system administrators, and therefore might not be a true reflection of the trainers actual availability.',
        errorLoading: 'Something went wrong and data couldn\'t be loaded. Please try again later.',
        location: 'Location'
      },
      notify: {
        titleActions: `Delete`,
        contentTextRemove: `Do you want to remove this`,
        contentTextArchive: `Do you want to archive this`,
        contentTextPermanently: `permanently?`,
        contentTextDoYouWantTo: `Do you want to`,
        contentTextThis: 'this',
        cancel: `Cancel`,
        confirm: `Confirm`,
        delete: `Delete`,
        archive: 'Archive'
      },
      buttons: {
        cancel: 'Cancel',
        create: 'Create',
        edit: 'Edit',
        confirm: 'Confirm',
        close: 'Close'
      },
      props: {
        sending: 'sending',
        actionNotAllowed: 'Action not allowed',
        consider: 'Consider:',
        titleItemsNotCreated: 'No items have been created yet',
        noItemsHaveBeenFound: 'No items have been found',
        pleaseModifyYourSearch: 'Please modify your search request and try again.',
        searchItems: 'Search items'
      },
      labels: {
        bulk: 'Bulk actions'
      },
      return: {
        selected: 'selected',
        statusError: 'error',
        customMessageSomethingWrong: 'Something went wrong while removing achievement. Try again or contact an administrator.'
      }
    },
    pop: {
      title: {
        format: 'Format',
        email: 'email',
        trigger: 'Trigger',
        sent: 'Sent',
        sentFrom: 'Sent from',
        sentTo: 'Sent to',
        willBeVisibleCc: 'Cc recipients will be visible to other the recipients.',
        willBeVisibleBcc: 'Bcc recipients will be visible to other the recipients.',
        successfully: 'successfully',
        ed: 'ed.',
        error: 'There was an error when',
        the: 'the',
        pleaseTryAgain: 'Please try again.'
      },
      labels: {
        chooseReason: 'Choose reason',
        subject: 'Subject',
        typeToAdd: 'Type to add',
        search: 'Search'
      },
      buttons: {
        close: 'Close',
        reset: 'Reset',
        custom: 'Custom',
        update: 'Update',
        CcOrBcc: 'Cc or Bcc',
        leaveNow: 'Leave now',
        continueWorking: 'Continue working',
        view: 'View.'
      },
      props: {
        alertMessage: 'Alert message',
        leaveNow: 'Leave now',
        continue: 'Continue working',
        saved: 'Your changes won\'t be saved',
        DoYouWantToContinue: 'Do you want to continue?',
        custom: 'Custom'
      }
    },
    viewAction: {
      notify: {
        remove: 'Remove',
        archive: `Archive`,
        contentArchive: `Do you want to archive this`,
        cancel: `Cancel`,
        confirm: 'Confirm',
        contentDoYouWant: `Do you want to`,
        contentThis: `this`,
        contentSelected: 'selected',
        itemName: 'item | items'
      },
      props: {
        edit: 'Edit',
        cancel: 'Cancel',
        custom: 'Custom',
        customTitle: 'Custom title',
        customText: 'Custom text',
        confirm: 'Confirm',
        create: 'Create'
      }
    },
    navigator: {
      buttons: {
        back: 'Back',
        previous: 'Previous',
        next: 'Next',
        delete: 'Delete'
      },
      props: {
        deleteItem: 'Delete item',
        areYouSure: 'Are you sure you want to delete this item'
      }
    }
  },
  notifications: {
    createVenue: 'A venue with that name already exists. Please try a different name',
    updateVenue: 'A venue with that name already exists. Please try a different name',
    createRoom: 'A room with that name already exists in this venue. Please try a different name',
    updateRoom: 'A room with that name already exists in this venue. Please try a different name',
    sessionAlreadyStarted: 'Unable to book onto this session because it has already started. Please book onto another session',
    sessionFullyBooked: 'Unable to book onto this session because it is fully booked. Please book onto another session',
    learnerEnrolment: 'Unable to book onto this session because you are not enrolled onto a course containing this event. Please ensure you are enrolled onto a course which contains this event',
    sessionNoLongerAccepting: 'Unable to book onto this session because it is no longer accepting bookings. Please book onto another session',
    bookingValidatorEventHasNoCourses: 'event is not associated with any courses',
    bookingValidatorFullyBooked: 'session is fully booked',
    bookingValidatorAlreadyStarted: 'session has already started',
    bookingValidatorLearnerAlreadyBooked: 'you are already booked onto session in this event',
    bookingValidatorNoLongerAccepting: 'session no longer accepting bookings',
    bookingValidatorTrainerNotAllowed: 'you are the assigned trainer',
    bookingValidatorLmsUnavailable: 'the LMS is unavailable',
    bookingValidatorLearnerEnrolment: 'not enrolled onto a course containing this event',
    BookingValidatorProfileExpirationDate: 'your profile will expire before this session ends'
  },
  notificationsHelpers: {
    the: ', the',
    comma: ', ',
    andThe: ' and the ',
    and: ' and ',
    ToThis: 'This',
    hasBeenSetOnThe: 'has been set on the',
    pleaseRemove: '. Please remove them from the',
    before: 'before attempting to delete them',
    hintContact: 'contact the event administrator',
    hintBook: 'book onto another session',
    hintProfileExpired: 'contact the administrator',
    hintEnsure: 'ensure you are enrolled onto a course which contains this event',
    hindRefresh: 'refresh and try again'
  },
  store: {
    auth: {
      customMessageExpired: `You have been logged out due to inactivity.`,
      customMessageRefreshing: `You have been logged out due to inactivity. \nPlease login again to continue.`,
      customMessageToken: 'You have been redirected due to token problems.',
      customMessageLogged: `You have been logged out due to inactivity. \nPlease login again to continue.`,
      customMessageWorkspaces: 'Your workspace session has expired.',
      customMessageRedirected: 'You have been redirected due to session configuration problems.',
      customMessageMissingSession: 'You have been redirected due to a missing session.',
      customMessageLoggedSuccessfully: 'You have been logged in successfully.',
      customMessageSomeErrors: 'Some errors occurred while logging in.',
      customMessageLoggedOutSuccessfully: 'You have been logged out successfully.',
      customMessageSessionRemoved: 'Your workspace session has been removed.',
      customMessageVerificationCode: 'The verification code has been sent to your email address. Please check your inbox.',
      customMessageAccountCreated: 'Your account has been created successfully. Use your email address and password to log in.',
      customMessageInvitationIsNotValid: `You can't sign up because invitation is not valid. Please try again or contact an administrator.`,
      //
      commitError: 'There were errors while creating the new account.',
      commitSuccessfullyCreated: 'You have successfully created a new account. Please confirm your address email first.',
      commitSomeError: 'Some errors occurred while creating a new account. Please try again.',
      commitExistsError: 'User with this email address already exists.',
      commitErrorsWhileTryingToReset: 'There were errors while trying to reset the password.',
      commitSuccessfullyResetPassword: 'You have successfully reset the password. You can now log in using new details.',
      //
      errorMessageNoExisting: 'There is no existing user with this email.',
      errorMessageTryAfter: 'Attempt limit exceeded, please try after some time.',
      errorMessageTryAgain: 'Some issues occurred, please try again.',
      errorInvalidVerification: 'Invalid verification code provided, please try again.',
      errorPasswordFailed: 'Password failed to satisfy constraint. Please use stronger password.',
      errorAttemptLimit: 'Attempt limit exceeded, please try after some time.',
      errorPleaseTryAgain: 'Some issues occurred, please try again.',
      errorSomethingWentWrong: 'Something went wrong. Try to type the code once again.',
      errorUserHasNoRole: 'User has no selected role'
    },
    default: {
      title: 'requesting an action'
    },
    ebs: {
      ebsBooking: {
        titleBulkBooking: 'bulk booking',
        customMessageCreatingBulkBooking: 'Booking successfully created.',
        customMessageCreatingError: 'The booking has not been created.',
        customMessageRemovingBulkBooking: 'Booking successfully cancelled.',
        customMessageRemovingError: 'The booking has not been cancelled.'
      },
      ebsEmail: {
        titleEmail: 'email',
        customMessageSavingEmail: 'Email successfully saved.',
        customMessageSavingError: 'The email has not been saved.'
      },
      ebsEvent: {
        labelSpecialRequirements: 'Special requirements',
        labelYourSpecialRequirements: 'Your special requirements',
        titleEvent: 'event',
        //
        customMessageEditEvent: 'Event successfully updated.',
        customMessageEditError: 'The event has not been updated.',
        customMessageCreatingEvent: 'Event successfully created.',
        customMessageCreatingError: 'The event has not been created.',
        customMessageRemovingEvent: 'Event successfully removed.'
      },
      ebsRegister: {
        titleRegister: 'register',
        customMessageEditRegister: 'Register successfully saved.',
        customMessageEditError: 'The register has not been saved.',
        customMessageCompletingRegister: 'Register successfully submitted.',
        customMessageCompletingError: 'The register has not been submitted.'
      },
      ebsRoom: {
        commit: 'A room with that name already exists in this venue. Please try a different name.',
        titleRoom: 'room',
        //
        customMessageEditRoom: 'Room successfully updated.',
        customMessageEditError: 'The room has not been updated.',
        customMessageCreatingRoom: 'Room successfully created.',
        customMessageCreatingError: 'The room has not been created.',
        customMessageRemovingRoom: 'Room successfully removed.'
      },
      ebsSession: {
        titleSession: 'session',
        customMessageEditSession: 'Session successfully updated.',
        customMessageEditError: 'The session has not been updated.',
        customMessageCreatingSession: 'Session successfully created.',
        customMessageCreatingError: 'The session has not been created.',
        customMessageRemovingSession: 'Session successfully cancelled.',
        customMessageRemovingError: 'The session has not been cancelled.'
      },
      ebsSubscription: {
        commitSuccessfullyExpressed: 'Successfully expressed interest in event.',
        commitInterestInEvent: 'interest in event',
        commitSuccessfullyCancelled: 'Successfully cancelled expression of interest.',
        commitExpressionOfInterest: 'expression of interest',
        commitCancelledSubEvent: 'Successfully cancelled subscriptions in event.',
        commitSubscriptions: 'subscriptions in event',
        commitJoined: 'Successfully joined session waitlist.',
        commitWaitlist: 'waitlist on session',
        commitExpression: 'expression of interest'
      },
      ebsVenue: {
        customMessage: 'A venue with that name already exists. Please try a different name.',
        customMessageEditVenue: 'Venue successfully updated.',
        customMessageEditError: 'The venue has not been updated.',
        customMessageCreatingVenue: 'Venue successfully created.',
        customMessageCreatingError: 'The venue has not been created.',
        customMessageRemovingVenue: 'Venue successfully removed.'
      },
      ebsSessionFeedback: {
        titleFeedback: 'feedback',
        customMessageEditFeedback: 'Feedback successfully updated.',
        customMessageEditError: 'Feedback was not been updated.'
      },
      ebsSummary: {
        titleSummary: 'summary',
        customMessageEditSummary: 'Summary successfully saved.',
        customMessageEditError: 'The summary has not been saved.',
        customMessageCompletingSummary: 'Summary successfully submitted.',
        customMessageCompletingError: 'The summary has not been submitted.'
      },
      ebsTrainer: {
        titleTrainer: 'trainer',
        customMessageEditTrainer: 'Trainer successfully updated.',
        customMessageEditError: 'Trainer was not been updated.',
        customMessageCreatingTrainer: 'Trainer successfully created.',
        customMessageCreatingError: 'Trainer was not created.'
      }
    },
    lms: {
      superadmin: {
        lmsSuperadminAdmin: {
          customMessageCreatedSuperAdminRole: 'You have successfully created super admin role.',
          customMessageSomethingWentWrong: 'Something went wrong while creating super admin role. Try again or contact an administrator.',
          customMessageSomethingWentWrongWhile: 'Something went wrong while downloading admin details. Please try again or contact an administrator.',
          customMessageSomethingWentWrongRemoving: 'Something went wrong while removing super admin. Try again or contact an administrator.'
        },
        lmsSuperadminCustomer: {
          titleCustomer: 'customer',
          customMessageEditCustomer: 'Customer successfully updated.',
          customMessageEditError: 'Customer was not been updated.',
          customMessageCreatingCustomer: 'Customer successfully created.',
          customMessageCreatingError: 'Customer was not created.',
          customMessageUpdatingTrainer: 'Customer successfully updated.',
          customMessageUpdatingError: 'Customer was not been updated.'
        },
        lmsSuperadminWorkspace: {
          titleCustomer: 'workspace',
          customMessageEditWorkspace: 'Workspace successfully updated.',
          customMessageEditError: 'Workspace was not been updated.',
          customMessageCreatingWorkspace: 'Workspace successfully created.',
          customMessageCreatingError: 'Workspace was not created.',
          customMessageUpdatingTrainer: 'Workspace successfully updated.',
          customMessageUpdatingError: 'Workspace was not been updated.'
        },
        lmsSuperadminCustomerSubscription: {
          titleCustomerSubscription: 'subscription',
          customMessageCreatingSubscription: 'Subscription successfully created.',
          customMessageCreatingError: 'Subscription was not created.',
          customMessageRemovingSubscription: 'Subscription successfully cancelled.',
          customMessageRemovingError: 'The subscription has not been cancelled.'
        },
        lmsSuperadminAdminWorkspaceUser: {
          titleUser: 'user',
          titleUserInvitation: 'user invitation',
          //
          customMessageInvitedInvitation: 'User successfully Invited.',
          customMessageUpdatedInvitation: 'User successfully updated.',
          customMessageUpdatedError: 'User was not been updated.',
          customMessageCancelInvitation: 'Invitation successfully cancelled.',
          customMessageCancelError: 'The invitation has not been cancelled.',
          customMessageResendingInvitation: 'Invitation successfully resent.',
          customMessageResendingError: 'The invitation has not been resend.'
        }
      },
      user: {
        lmsUserAccount: {
          customMessageWrongWhileDownloading: 'Something went wrong while downloading user details. Please try again or contact an administrator.',
          customMessageSuccessfullyUpdated: 'You have successfully updated user details.',
          customMessageWrongWhileUpdating: 'Something went wrong while downloading user details. Please try again or contact an administrator.',
          customMessageWrongWhilePerforming: 'Something went wrong while performing user update. Please try again or contact an administrator.',
          customMessageError: 'There were errors while changing password. Please try again or contact an administrator.',
          customMessageSuccessfully: 'You have successfully updated your password.'
        },
        lmsUserInvitation: {
          customMessageSuccessfully: 'You have successfully accepted an invitation to the workspace.',
          customMessageRejected: 'You have rejected an invitation to the workspace.'
        }
      }
    }
  },
  interceptors: {
    customMessageProblems: 'You have been logged out due to authentication problems.'
  }
}
