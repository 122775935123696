<template>
    <div
        class="component component__logo"
    >
        <div class="component__logo_image">
            <template v-if="fileId">
                <component-image
                    :key="fileId"
                    :id="fileId"
                    :clickable="false"
                    :height="height"
                    :alt="'Form LMS by Me Learning'"
                ></component-image>
            </template>
            <template v-else>
                <component-image
                    :key="'default-logo'"
                    :clickable="false"
                    :height="height"
                    :src="inverseStyle ? svgLogoInverse : svgLogo"
                    :alt="'Form LMS by Me Learning'"
                ></component-image>
            </template>
        </div>
        <template v-if="label">
            <component-text
                :key="rerenderIndex"
                :class="'component__logo_label'"
                :size="'small'"
            >{{ label }}
            </component-text>
        </template>
    </div>
</template>

<script>

import ComponentText from '@/components/default/shared/ComponentText'
import { User } from '@/User'

export default {
  name: 'component-logo',
  components: {
    ComponentText,
    ComponentImage: () => import('@/components/default/shared/ComponentImage')
  },
  data () {
    return {
      rerenderIndex: 0,
      user: new User(),
      svgLogo: require('@/assets/img/logo.svg'),
      svgLogoInverse: require('@/assets/img/logo-inverse.svg')
    }
  },
  computed: {
    metaTitleOutput () {
      return this.$meta().inject().titleChunk
    }
  },
  props: {
    fileId: {
      type: String
    },
    label: {
      type: String
    },
    height: {
      type: Number,
      default: 25
    },
    inverseStyle: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    label () {
      this.rerenderIndex++
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentlogo";
</style>
