import axios from 'axios'
import api from '@/api'
import { toISODateUTC } from '@/functions'

const data = {
  data: {
    customerId: '',
    createdBy: '',
    status: 'active',
    fromDate: '',
    toDate: '',
    name: '',
    setupType: 'melearning',
    serviceKey: 'SSO',
    metadata: '',
    attributeMappingOverride: {
      firstName: '',
      lastName: '',
      email: ''
    },
    urls: {
      acsUrl: '',
      entityId: ''
    }
  },
  default: ''
}

const ebsServiceSSO = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsServiceSSO: state => {
      return state.data
    },
    getEbsServiceSSOId: state => {
      return state.data.id
    },
    getEbsServiceSSOName: state => {
      return state.data.name
    },
    getEbsServiceSSOFromDate: state => {
      return state.data.fromDate
    },
    getEbsServiceSSOToDate: state => {
      return state.data.toDate
    },
    getEbsServiceSSOMetadata: state => {
      return state.data.metadata
    },
    getEbsServiceSSOUrls: state => {
      return state.data.urls
    },
    getEbsServiceSSOAttributes: state => {
      return state.data.attributeMappingOverride
    }
  },
  mutations: {
    EBS_SERVICE_SSO_GET (state, payload) {
      let payloadData = payload
      payloadData.fromDate = payloadData.fromDate ? toISODateUTC(payloadData.fromDate) : ''
      payloadData.toDate = payloadData.toDate ? toISODateUTC(payloadData.toDate) : ''
      payloadData.urls = JSON.parse(JSON.stringify(data.data.urls))
      payloadData.attributeMappingOverride = payloadData.attributeMappingOverride || JSON.parse(JSON.stringify(data.data.attributeMappingOverride))
      payloadData.metadata = payloadData.idpMetadata || ''

      state.data = payloadData
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    //
    EBS_SERVICE_SSO_SET_NAME (state, payload) {
      state.data.name = payload
    },
    EBS_SERVICE_SSO_SET_FROMDATE (state, payload) {
      state.data.fromDate = payload
    },
    EBS_SERVICE_SSO_SET_TODATE (state, payload) {
      state.data.toDate = payload
    },
    EBS_SERVICE_SSO_SET_METADATA (state, payload) {
      state.data.metadata = payload
    },
    EBS_SERVICE_SSO_SET_URLS_ACSURL (state, payload) {
      state.data.urls.acsUrl = payload
    },
    EBS_SERVICE_SSO_SET_URLS_ENTITYID (state, payload) {
      state.data.urls.entityId = payload
    },
    EBS_SERVICE_SSO_SET_ATTRIBUTES (state, payload) {
      state.data.attributeMappingOverride[payload.key] = payload.value
    },
    EBS_SERVICE_SSO_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    EBS_SERVICE_SSO_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    }
  },
  actions: {
    ebsServiceSSOCreate ({ commit, state, getters }, data) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        stateData.customerId = data.customerId
        stateData.createdBy = getters.getAuthUserSecondStageTokenDataID

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/sso-subscriptions`,
          data: stateData
        }).then(response => {
          commit('EBS_SERVICE_SSO_GET', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsServiceSSOUpdate ({ state, dispatch }, data) {
      return new Promise((resolve) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        const subscriptionId = data.subscriptionId || stateData.id
        const metadata = stateData.metadata
        let requestsCount = 0

        let requestsArr = [
          'ebsServiceSSOUpdateMelSetup'
        ]

        if (metadata) {
          requestsArr.push('ebsServiceSSOUpdateCustomerSetup')
        }

        for (let request of requestsArr) {
          dispatch(request, subscriptionId).finally(() => {
            requestsCount++
            if (requestsCount === requestsArr.length) {
              resolve()
            }
          })
        }
      })
    },
    ebsServiceSSOUpdateMelSetup ({ state }, data) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        stateData.metadata = undefined
        stateData.attributeMappingOverride = undefined

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/sso-subscriptions/${data}`,
          data: stateData
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsServiceSSOUpdateCustomerSetup ({ state }, data) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        const metadata = stateData.metadata
        const attributeMappingOverride = stateData.attributeMappingOverride

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/sso-subscriptions-customer-setup/${data}`,
          data: {
            metadata,
            attributeMappingOverride
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsServiceSSOGetSAMLMetadata ({ state }, data) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        const subscriptionId = data || stateData.id

        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/saml/metadata/${subscriptionId}`
        }).then(response => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `metadata.xml`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `metadata.xml`)
          }
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsServiceSSOGetSAMLUrls ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        const subscriptionId = data || stateData.id

        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/saml/metadata/${subscriptionId}/urls`
        }).then(response => {
          commit('EBS_SERVICE_SSO_SET_URLS_ACSURL', response.data.acsUrl)
          commit('EBS_SERVICE_SSO_SET_URLS_ENTITYID', response.data.entityId)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsServiceSSOClear ({ commit }, data) {
      commit('EBS_SERVICE_SSO_CLEAR', data)
    },
    ebsServiceSSOSetData ({ commit }, data) {
      commit('EBS_SERVICE_SSO_GET', data)
    },
    ebsServiceSSOSetName ({ commit }, data) {
      commit('EBS_SERVICE_SSO_SET_NAME', data)
    },
    ebsServiceSSOSetToDate ({ commit }, data) {
      commit('EBS_SERVICE_SSO_SET_TODATE', data)
    },
    ebsServiceSSOSetFromDate ({ commit }, data) {
      commit('EBS_SERVICE_SSO_SET_FROMDATE', data)
    },
    ebsServiceSSOSetMetadata ({ commit }, data) {
      commit('EBS_SERVICE_SSO_SET_METADATA', data)
    },
    ebsServiceSSOSetAttributes ({ commit }, data) {
      commit('EBS_SERVICE_SSO_SET_ATTRIBUTES', data)
    }
  }
}

export default ebsServiceSSO
