import Vue from 'vue'
import {
  calendarDate,
  calendarTime,
  decodeEmail,
  encodeEmail,
  fromArrayToStringComma,
  fromObjectToStringComma,
  fromStringToArrayRadio,
  fromStringToArraySelect,
  fromCamelCaseToString,
  fromKebabCaseToString,
  fromStringToArray,
  fromLocalTimeToUTC,
  fromUTCDateTimeToLocalISOCustom,
  fromUTCTimeToLocal,
  getHoursDifference,
  getDomPath,
  handleCopyToClipboard,
  isIE11,
  isInIframe,
  removeLastOneChar,
  removeLastTwoChar,
  removeLastThreeChar,
  sortByKey,
  toCapitalize,
  toDataURL,
  toUncapitalise,
  toKebabCase,
  toSnakeCase,
  toUnicase,
  toString,
  toUrlQuery,
  toLocaleDateTime,
  toLocaleTime,
  toISODate,
  toISODateUTC
} from '@/functions'

Vue.mixin({
  methods: {
    calendarDate (value) {
      return calendarDate(value)
    },
    calendarTime (value) {
      return calendarTime(value)
    },
    decodeEmail (value) {
      return decodeEmail(value)
    },
    encodeEmail (value) {
      return encodeEmail(value)
    },
    fromArrayToStringComma (value) {
      return fromArrayToStringComma(value)
    },
    fromObjectToStringComma (value, key) {
      return fromObjectToStringComma(value, key)
    },
    fromStringToArrayRadio (value) {
      return fromStringToArrayRadio(value)
    },
    fromStringToArraySelect (value) {
      return fromStringToArraySelect(value)
    },
    fromCamelCaseToString (value) {
      return fromCamelCaseToString(value)
    },
    fromKebabCaseToString (value) {
      return fromKebabCaseToString(value)
    },
    fromStringToArray (value) {
      return fromStringToArray(value)
    },
    fromUTCDateTimeToLocalISOCustom (value) {
      return fromUTCDateTimeToLocalISOCustom(value)
    },
    fromLocalTimeToUTC (value) {
      return fromLocalTimeToUTC(value)
    },
    fromUTCTimeToLocal (value) {
      return fromUTCTimeToLocal(value)
    },
    getHoursDifference (from, to, suffix) {
      return getHoursDifference(from, to, suffix)
    },
    getDomPath (value) {
      return getDomPath(value)
    },
    handleCopyToClipboard (value, name) {
      return handleCopyToClipboard(value, name)
    },
    isIE11 () {
      return isIE11()
    },
    isInIframe () {
      return isInIframe()
    },
    removeLastOneChar (value) {
      return removeLastOneChar(value)
    },
    removeLastTwoChar (value) {
      return removeLastTwoChar(value)
    },
    removeLastThreeChar (value) {
      return removeLastThreeChar(value)
    },
    sortByKey (value) {
      return sortByKey(value)
    },
    toCapitalize (value) {
      return toCapitalize(value)
    },
    toDataURL (value) {
      return toDataURL(value)
    },
    toUncapitalise (value) {
      return toUncapitalise(value)
    },
    toKebabCase (value) {
      return toKebabCase(value)
    },
    toSnakeCase (value) {
      return toSnakeCase(value)
    },
    toUnicase (value) {
      return toUnicase(value)
    },
    toString (value) {
      return toString(value)
    },
    toUrlQuery (value) {
      return toUrlQuery(value)
    },
    toLocaleDateTime (value) {
      return toLocaleDateTime(value)
    },
    toLocaleTime (value) {
      return toLocaleTime(value)
    },
    toISODate (value) {
      return toISODate(value)
    },
    toISODateUTC (value) {
      return toISODateUTC(value)
    }
  }
})
