<template>
    <tr class="pop__table_grid_item">
        <template v-if="checkbox">
            <td class="pop__table_box_main_table_item_checkbox checkbox">
                <list-table-check
                    :name="`${index}`"
                    :index="index"
                    :emit-checkbox-name="`${listEmitName}-checkbox-action`"
                    :emit-list-name="`${listEmitName}-list-action`"
                    :checked="itemChecked || Boolean(disallow && disallow.length && item.id && disallow.includes(item.id))"
                    :allow-single="allowSingle"
                    :id="item.id"
                    :disabled="item.disabled || Boolean(disallow && disallow.length && item.id && disallow.includes(item.id))"
                    @emit-check="handleEmitCheckbox"
                />
            </td>
        </template>
        <template v-for="(label, labelIndex) in labels">
            <td
                :key="labelIndex"
                :class="`pop__table_box_main_table_item_${label.key.toLowerCase()}`"
            >
                <template v-if="label.key === 'status' || label.type === 'status'">
                    <component-status :status="item[label.key]"></component-status>
                </template>
                <tempalte v-else-if="label.type === 'label'">
                    <component-label-list :items="item[label.key]" :limit-active="true"></component-label-list>
                </tempalte>
                <template v-else-if="label.type === 'datetime'">
                    <template v-if="item[label.key]">
                        {{ toLocaleDateTime(fromUTCDateTimeToLocalISOCustom(item[label.key])) }}
                        <component-text :size="'tiny'">
                            Time: {{ toLocaleTime(fromUTCDateTimeToLocalISOCustom(item[label.key])) }}
                        </component-text>
                    </template>
                    <template v-else>-</template>
                </template>
                <template v-else-if="label.type === 'array' && label.subType === 'object'">
                    {{ item[label.key].map(obj => Object.values(obj)).join(', ') }}
                </template>
                <template v-else-if="label.type === 'array'">
                    <template v-if="item[label.key] && item[label.key].length">
                        <component-text-list
                            :items="item[label.key]"
                            :limit-active="true"
                            :limit-items="1"
                        ></component-text-list>
                    </template>
                    <template v-else>-</template>
                </template>
                <template v-else>
                    <component-text :theme="item.disabled ? 'dark-grey' : 'black'" :size="label.size">{{ item[label.key] }}</component-text>
                </template>
            </td>
        </template>
    </tr>
</template>

<script>
import ListTableCheck from '@/components/default/shared/ListTableCheck'
import ComponentLabelList from '@/components/default/shared/ComponentLabelList.vue'

export default {
  name: 'pop-table-grid-item',
  components: {
    ComponentLabelList,
    ComponentTextList: () => import('@/components/default/shared/ComponentTextList'),
    ComponentText: () => import('@/components/default/shared/ComponentText'),
    ComponentStatus: () => import('@/components/default/shared/ComponentStatus'),
    ListTableCheck
  },
  props: {
    disallow: {
      type: [Array, String, Number]
    },
    allowSingle: {
      type: Boolean,
      default: false
    },
    item: {
      type: [Array, Object],
      required: true
    },
    labels: {
      type: Array
    },
    index: {
      type: Number
    },
    checkbox: {
      type: Boolean,
      default: false
    },
    listEmitName: {
      type: String,
      default: 'emit'
    },
    selected: {
      type: [Array, Object]
    }
  },
  watch: {
    item () {
      this.$eventBus.$off(`${this.listEmitName}-list-action`)
    }
  },
  computed: {
    itemChecked () {
      return this.selected.findIndex(obj => obj.id === this.item.id) > -1
    }
  },
  methods: {
    handleEmitCheckbox (data) {
      this.$emit('emit', { message: 'emit-check', value: { data: data, item: this.item } })
    }
  }
}
</script>
