<template>
    <div :class="[
        'form',
        'form__checkbox',
        `form__checkbox--${size}`,
        switcher ? 'form__checkbox--switcher' : '',
        (switcher && disabled) ? 'form__checkbox--switcher-disabled' : '',
        disabled ? 'form__checkbox--disabled' : '',
        margin ? `form__checkbox--margin-${margin}` : '',
    ]">
        <div class="form__checkbox_title_switcher" v-if="switcher && label">{{label | toCapitalize}}</div>
        <label
            :for="name | toKebabCase"
            class="form__checkbox_title"
        >
            <input
                type="checkbox"
                :class="[
                `form__checkbox_input`,
                checked ? `form__checkbox_input--checked` : '']"
                :disabled="disabled"
                :id="name | toKebabCase"
                :name="name | toKebabCase"
                :value="newValue"
                :checked="newValue"
                :data-vv-as="dataVvAs ? dataVvAs : name.toLowerCase()"
                v-validate="vValidate"
                @click="setNewValue"
            >
            <span
                v-if="sublabel"
                :class="'form__checkbox_title_sublabel'"
            >{{ sublabel }}</span>
            <span v-if="!switcher">{{ (switcher ? '' : label) | toCapitalize }}<span class="asterisk" v-if="required && !switcher">*</span></span>
            <template v-if="switcher">
                <div class="form__checkbox_title_switcher_box" v-if="switcher">
                    <span>{{ (switcher ? '' : label) | toCapitalize }}<span class="asterisk" v-if="required">*</span></span>
                    <div class="form__checkbox_title_switcher_separator_grey"></div>
                    <div class="form__checkbox_title_switcher_separator_blue"></div>
                    <div class="form__checkbox_title_switcher_active">
                        {{ switcherTextActive }}
                    </div>
                    <div class="form__checkbox_title_switcher_inactive">
                        {{ switcherTextInactive }}
                    </div>
                </div>
            </template>
        </label>
        <span
            class="form__error form__checkbox_error"
            v-if="errors.first(toKebabCase(name))"
        >{{ errors.first(toKebabCase(name)) }}</span>
        <template v-if="$slots.default">
            <div class="form__checkbox_hint">
                <slot></slot>
            </div>
        </template>
    </div>
</template>

<script>
export default {
  name: 'form-checkbox',
  data () {
    return {
      checked: false
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Boolean, Number]
    },
    label: {
      type: String,
      required: true,
      default: 'Label'
    },
    sublabel: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    //
    dataVvAs: {
      type: String,
      default: ''
    },
    vValidate: {
      default: ''
    },
    size: {
      // one of: ['small', 'normal', 'large']
      type: String,
      default: 'normal'
    },
    switcher: {
      type: Boolean,
      default: false
    },
    prevent: {
      type: Boolean,
      default: false
    },
    switcherTextActive: {
      type: String,
      default: function () {
        return this.$t('forms.checkbox.active')
      }
    },
    switcherTextInactive: {
      type: String,
      default: function () {
        return this.$t('forms.checkbox.inactive')
      }
    },
    margin: {
      type: String
    }
  },
  computed: {
    newValue () {
      return this.value
    }
  },
  methods: {
    setChecked (value) {
      this.checked = value
    },
    setNewValue (event) {
      if (this.prevent) {
        event.preventDefault()
      }
      this.emitInput(!this.value)
    },
    emitInput (data) {
      this.$emit('input', data)
    }
  },
  watch: {
    value: function (value) {
      this.setChecked(value)
    }
  },
  mounted: function () {
    this.setChecked(this.value)
  },
  inject: ['$validator']
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/forms/formcheckbox";
</style>
