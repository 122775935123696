import axios from 'axios'
import api from '@/api'
import { sortByKey } from '@/functions'
import { checkResponseNotFound } from '@/interceptorsHelpers'

const lmsUserCustomerworkspaces = {
  state: {
    data: ''
  },
  getters: {
    getLmsUserCustomerworkspaces: state => {
      return state.data
    }
  },
  mutations: {
    LMS_SUPERADMIN_CUSTOMERWORKSPACES_SET (state, payload) {
      state.data = sortByKey(payload, 'name')
    },
    LMS_SUPERADMIN_CUSTOMERWORKSPACES_CLEAR (state) {
      state.data = ''
    }
  },
  actions: {
    lmsUserCustomerworkspacesGet (context, data) {
      const id = data.customerId
      const status = data.status
      context.commit('LMS_SUPERADMIN_CUSTOMERWORKSPACES_CLEAR')
      axios({
        method: 'get',
        url: `${api.subscriptionmanager}/customer-workspaces/${id}?status=${status}`
      }).then(response => {
        context.commit('LMS_SUPERADMIN_CUSTOMERWORKSPACES_SET', response.data)
      }).catch(function (error) {
        checkResponseNotFound(error.response.status)
      })
    }
  }
}

export default lmsUserCustomerworkspaces
