<template>
    <div class="container fullheight">
        <div :class="
            [
                'filter',
                overlay ? 'filter--overlay' : ''
            ]
        ">
            <div class="filter__main">
                <div class="columns is-block-mobile is-vcentered is-variable is-4">
                    <template v-if="noteActive">
                        <div class="column is-narrow">
                            <component-note>{{ noteText }}</component-note>
                        </div>
                    </template>
                    <template v-if="searchActive">
                        <div class="column is-narrow">
                            <filter-search
                                :search-placeholder="searchPlaceholder"
                                :value="filterSearch"
                                @emit-input="setFilterSearch"
                            />
                        </div>
                    </template>
                    <template v-if="detailsActive">
                        <div class="column is-narrow">
                            <filter-details
                                :items-number="detailsItemsNumber"
                                :items-number-of="detailsItemsNumberOf"
                                :items-name="detailsItemsName"
                                :page-size-select-value="filterPageSize"
                                :default-page-size-select-value="filterDefaultPageSize"
                                :page-size-select-active="searchQueryable"
                                :page-size-select-items="detailsPageSizeSelectItems"
                                @emit-filter-page-size="setFilterPageSize"
                            />
                        </div>
                    </template>
                    <template v-if="moreActive">
                        <div class="column custom--block">
                            <filter-more :filter-more="moreItems"/>
                        </div>
                    </template>
                    <div class="column is-hidden-mobile"></div>
                    <template v-if="sortActive">
                        <div class="column custom--block is-narrow">
                            <filter-sort
                                :filters="sortItems"
                                @emit-switch-list-view="emitSwitchListView"
                            />
                        </div>
                    </template>
                    <template v-if="selectActive && !selectInline">
                        <div class="column is-narrow">
                            <component-button
                                :align="'left'"
                                :name="'filter-select-button'"
                                :icon="true"
                                :icon-align="'right'"
                                :icon-name="`chevron-${selectBox ? 'up' : 'down'}`"
                                :theme="'default'"
                                @click="toggleSelectBox"
                            >Filter</component-button>
                        </div>
                    </template>
                    <template v-if="infoActive">
                        <div class="column is-narrow">
                            <component-note>{{ infoContent }}</component-note>
                        </div>
                    </template>
                    <template v-if="actionsActive && visibility(actionsVisibility)">
                        <div class="column column--button is-narrow">
                            <filter-actions
                                :secondary-button-text="actionsSecondaryButtonText"
                                :secondary-button-active="actionsSecondaryButtonActive"
                                :secondary-button-animate-loading="actionsSecondaryButtonAnimateLoading"
                                :secondary-button-disabled="actionsSecondaryButtonDisabled"
                                :primary-button-text="actionsPrimaryButtonText"
                                :primary-button-active="actionsPrimaryButtonActive"
                                :primary-button-animate-loading="actionsPrimaryButtonAnimateLoading"
                                :primary-button-disabled="actionsPrimaryButtonDisabled"
                                @emit-secondary="emitActionsSecondary"
                                @emit-primary="emitActionsPrimary"
                            />
                            <template v-if="actionsCustomButtonActive">
                                <component-button
                                    ref="basefiltercustombuttonactive"
                                    :icon="actionsCustomButtonIcon"
                                    :icon-name="actionsCustomButtonIconName"
                                    :animate-loading="actionsCustomButtonAnimateLoading"
                                    :inverse="true"
                                    :icon-raw="actionsCustomButtonIconRaw"
                                    :theme="actionsCustomButtonTheme"
                                    :name="'base-filter-actions-custom'"
                                    :notify-active="actionsCustomNotifyActive"
                                    :notify-title="actionsCustomNotifyTitle"
                                    :notify-text="actionsCustomNotifyText"
                                    :notify-size="actionsCustomNotifySize"
                                    :notify-cancel-text="actionsCustomNotifyCancelText"
                                    :notify-custom-active="actionsCustomNotifyCustomActive"
                                    :notify-custom-text="actionsCustomNotifyCustomText"
                                    :notify-custom-danger="actionsCustomNotifyCustomDanger"
                                    :notify-custom-animate-loading="actionsCustomNotifyCustomAnimateLoading"
                                    :notify-submit-active="actionsCustomNotifySubmitActive"
                                    :notify-submit-text="actionsCustomNotifySubmitText"
                                    :notify-submit-danger="actionsCustomNotifySubmitDanger"
                                    :notify-submit-animate-loading="actionsCustomNotifySubmitAnimateLoading"
                                    :notify-select-active="actionsCustomNotifySelectActive"
                                    :notify-select-title="actionsCustomNotifySelectTitle"
                                    :notify-select-items="actionsCustomNotifySelectItems"
                                    :notify-select-hint="actionsCustomNotifySelectHint"
                                    :notify-prevent-close="actionsCustomNotifyPreventClose"
                                    @emit-notify-custom="emitNotifyCustom"
                                    @emit-notify-submit="emitNotifySubmit"
                                    @click="emitActionsCustom"
                                >{{ actionsCustomButtonText }}</component-button>
                            </template>
                        </div>
                    </template>
                    <template v-if="listActive">
                        <div class="column is-hidden-mobile is-narrow">
                            <filter-list
                                :filters="listItems"
                                :list-view="listView"
                                :tooltip-active="filterListTooltipActive"
                                @emit-switch-list-view="emitSwitchListView"
                            />
                        </div>
                    </template>
                    <template v-if="checkboxActive">
                        <div class="column is-narrow">
                            <filter-checkbox
                                @emit-input="emitCheckbox"
                            />
                        </div>
                    </template>
                </div>
            </div>
            <div v-show="selectBox || selectInline" class="filter__selectbox">
                <div class="columns is-variable">
                    <div class="column is-hidden-mobile"></div>
                    <template v-if="selectItems">
                        <div class="column is-narrow-desktop">
                            <div class="columns is-block-mobile is-vcentered is-variable is-4">
                                <template v-for="(item, index) in selectItems">
                                    <div class="column is-narrow" :key="index">
                                        <filter-select
                                            :select-item="item"
                                            @emit-filter="emitFilterSelect"
                                        />
                                    </div>
                                </template>
                                <template v-if="selectButtonActive">
                                    <div class="column is-narrow">
                                        <component-button
                                            :class="'filter__selectbox_submit'"
                                            :name="'base-filter-select-button'"
                                            :animate-loading="selectButtonAnimateLoading"
                                            :animate-loading-force="selectButtonAnimateLoadingForce"
                                            :animate-loading-event-name="selectButtonAnimateLoadingName"
                                            @click="emitFilterSelectButton"
                                        >{{ selectButtonText }}
                                        </component-button>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FilterSearch from '@/components/default/filters/FilterSearch'
import FilterSelect from '@/components/default/filters/FilterSelect'
import FilterSort from '@/components/default/filters/FilterSort'
import FilterList from '@/components/default/filters/FilterList'
import FilterMore from '@/components/default/filters/FilterMore'
import FilterActions from '@/components/default/filters/FilterActions'
import FilterCheckbox from '@/components/default/filters/FilterCheckbox'
import { User } from '@/User'
import { debounce } from '@/functions'
import FilterDetails from '@/components/default/filters/FilterDetails'
import ComponentNote from '@/components/default/shared/ComponentNote'

export default {
  components: {
    ComponentNote,
    FilterDetails,
    ComponentButton: () => import('@/components/default/shared/ComponentButton'),
    FilterCheckbox,
    FilterActions,
    FilterMore,
    FilterSort,
    FilterList,
    FilterSelect,
    FilterSearch
  },
  data () {
    return {
      user: new User(),
      filterSearch: '',
      filterSelect: {},
      filterSelectExtended: {},
      selectBox: false
    }
  },
  name: 'base-filter',
  props: {
    filterSearchDefault: {
      type: String
    },
    filterListTooltipActive: {
      type: Boolean
    },
    filterData: {
      type: [Array, String, Object],
      default: () => []
    },
    filterPageSize: {
      type: Number
    },
    filterDefaultPageSize: {
      type: Number,
      default: 12
    },
    //
    checkboxActive: {
      type: Boolean,
      default: false
    },
    detailsActive: {
      type: Boolean,
      default: false
    },
    detailsItemsName: {
      type: [String, Array]
    },
    detailsItemsNumber: {
      type: Number
    },
    detailsPageSizeSelectItems: {
      type: Array
    },
    detailsItemsNumberOf: {
      type: [Number, Boolean, String]
    },
    searchPlaceholder: {
      type: String,
      default: 'Search'
    },
    searchActive: {
      type: Boolean,
      default: false
    },
    searchByField: {
      type: [String, Array],
      default: 'name'
    },
    searchQueryable: {
      type: Boolean,
      default: false
    },
    //
    selectActive: {
      type: Boolean,
      default: false
    },
    selectInline: {
      type: Boolean,
      default: false
    },
    selectItems: {
      /*
      * Must be Array of Object
      * [
      *     {
      *         key: String,
      *         label: String,
      *         type: String (one of ['checkbox', 'multiselect', 'date']),
      *         values: Array,
      *         getItemsGetter: String,
      *         getItemsDispatch: String
      *     }
      * ]
      */
      type: [Object, Array, String],
      default: () => {
      }
    },
    selectButtonActive: {
      type: Boolean,
      default: true
    },
    selectButtonAnimateLoading: {
      type: Boolean,
      default: true
    },
    selectButtonAnimateLoadingForce: {
      type: Boolean
    },
    selectButtonAnimateLoadingName: {
      type: String,
      default: 'filter-select'
    },
    selectButtonText: {
      type: String,
      default: 'Apply'
    },
    //
    moreItems: {
      type: [Array, Object],
      default: () => {
      }
    },
    moreActive: {
      type: Boolean,
      default: false
    },
    noteActive: {
      type: Boolean,
      default: false
    },
    noteText: {
      type: String
    },
    //
    overlay: {
      type: Boolean,
      default: true
    },
    //
    sortItems: {
      type: [Array, Object],
      default: () => {
      }
    },
    sortActive: {
      type: Boolean,
      default: false
    },
    //
    listItems: {
      type: [Array, Object],
      default: () => {
      }
    },
    listView: {
      type: String
    },
    infoActive: {
      type: Boolean,
      default: false
    },
    infoContent: {
      type: String
    },
    listActive: {
      type: Boolean,
      default: true
    },
    // one of roles from User.js
    actionsVisibility: {
      type: [Array],
      default: () => []
    },
    actionsActive: {
      type: Boolean,
      default: false
    },
    actionsPrimaryButtonActive: {
      type: Boolean,
      default: true
    },
    actionsPrimaryButtonDisabled: {
      type: Boolean,
      default: false
    },
    actionsPrimaryButtonText: {
      type: String,
      default: function () {
        return this.$t('filter.baseFilter.submit')
      }
    },
    actionsPrimaryButtonAnimateLoading: {
      type: Boolean,
      default: false
    },
    actionsSecondaryButtonActive: {
      type: Boolean,
      default: true
    },
    actionsSecondaryButtonDisabled: {
      type: Boolean,
      default: false
    },
    actionsSecondaryButtonText: {
      type: String,
      default: function () {
        return this.$t('filter.baseFilter.cancel')
      }
    },
    actionsSecondaryButtonAnimateLoading: {
      type: Boolean,
      default: false
    },
    actionsCustomButtonIcon: {
      type: Boolean,
      default: false
    },
    actionsCustomButtonIconRaw: {
      type: Boolean,
      default: false
    },
    actionsCustomButtonIconName: {
      type: String,
      default: 'base-filter-custom-button'
    },
    actionsCustomButtonText: {
      type: String
    },
    actionsCustomButtonTheme: {
      type: String,
      default: 'default'
    },
    actionsCustomButtonActive: {
      type: Boolean,
      default: false
    },
    actionsCustomButtonAnimateLoading: {
      type: Boolean,
      default: false
    },
    actionsCustomNotifyActive: {
      type: Boolean,
      default: false
    },
    actionsCustomNotifyTitle: {
      type: String,
      default: function () {
        return this.$t('shared.viewAction.props.customTitle')
      }
    },
    actionsCustomNotifyText: {
      type: String,
      default: function () {
        return this.$t('shared.viewAction.props.customText')
      }
    },
    actionsCustomNotifySize: {
      type: String
    },
    actionsCustomNotifyCancelText: {
      type: String,
      default: function () {
        return this.$t('shared.viewAction.props.cancel')
      }
    },
    actionsCustomNotifyCustomActive: {
      type: Boolean,
      default: false
    },
    actionsCustomNotifyCustomText: {
      type: String,
      default: function () {
        return this.$t('shared.viewAction.props.custom')
      }
    },
    actionsCustomNotifyCustomDanger: {
      type: Boolean,
      default: false
    },
    actionsCustomNotifyCustomAnimateLoading: {
      type: Boolean,
      default: true
    },
    actionsCustomNotifySubmitActive: {
      type: Boolean,
      default: true
    },
    actionsCustomNotifySubmitText: {
      type: String,
      default: function () {
        return this.$t('shared.viewAction.props.confirm')
      }
    },
    actionsCustomNotifySubmitDanger: {
      type: Boolean,
      default: false
    },
    actionsCustomNotifySubmitAnimateLoading: {
      type: Boolean,
      default: true
    },
    actionsCustomNotifySelectActive: {
      type: Boolean,
      default: false
    },
    actionsCustomNotifySelectTitle: {
      type: String,
      default: ''
    },
    actionsCustomNotifySelectItems: {
      type: [Array, Object],
      default: () => {
      }
    },
    actionsCustomNotifySelectHint: {
      type: String,
      default: ''
    },
    actionsCustomNotifyPreventClose: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasPreselectedFilterSelectItems () {
      return this.selectItems?.findIndex(obj => obj.preselected && obj.preselected.length) > -1
    }
  },
  methods: {
    toggleSelectBox () {
      this.selectBox = !this.selectBox
    },
    closeMenus (event) {
      const path = event.path || event.composedPath()

      if (!path) return
      let closeFilter = true
      for (let i = 0; i < path.length; i++) {
        if (!path[i].className || path[i].className.constructor !== String) continue
        if (
          path[i].className === 'filter__select' ||
        path[i].className === 'filter__menu'
        ) {
          closeFilter = false
        }
      }
      if (closeFilter) {
        this.$eventBus.$emit('filter-select-close-menus')
      }
    },
    visibility (roles) {
      if (!roles.length) {
        return true
      }
      const userRoles = this.user.listPermissions()
      return roles.some(value => userRoles.includes(value))
    },
    //
    setFilterSearch (value) {
      this.filterSearch = value
    },
    setFilterPageSize (value) {
      this.$emit('emit-filter-page-size', value)
    },
    //
    emitSwitchListView (value) {
      this.$emit('emit-switch-list-view', value)
    },
    //
    emitActionsPrimary () {
      this.$emit('emit-actions-primary')
    },
    emitActionsSecondary () {
      this.$emit('emit-actions-secondary')
    },
    emitActionsCustom () {
      this.$emit('emit-actions-custom')
    },
    emitNotifyCustom () {
      this.$emit('emit-actions-custom-notify-custom')
    },
    emitNotifySubmit () {
      this.$emit('emit-actions-custom-notify-submit')
    },
    emitCheckbox (data) {
      this.$emit('emit-checkbox', data)
    },
    emitFilterSelect (data) {
      let filtersExtended = this.filterSelectExtended
      let filters = this.filterSelect
      let key
      let value
      if (!data.type) {
        key = Object.keys(data)[0]
        value = data[key]
        filters[key] = value || undefined
        filtersExtended[key] = value || undefined
      } else if (data.type === 'date') {
        filters['dateStart'] = data.values.from || undefined
        filters['dateEnd'] = data.values.to || undefined
        filtersExtended['dateStart'] = data.values.from || undefined
        filtersExtended['dateEnd'] = data.values.to || undefined
      } else if (data.type === 'checkbox' || data.type === 'checkbox_multiselect') {
        key = data.key
        value = data.values
        filters[key] = value.length ? value : undefined
        filtersExtended[key] = value.length ? value : undefined
      } else if (data.type === 'multiselect') {
        key = data.key
        value = data.values ? data.values.map(obj => {
          return obj.value
        }) : ''
        filters[key] = value.length ? value : undefined
        filtersExtended[key] = data.values
      } else if (data.type === 'org-units') {
        key = data.key
        value = data.values ? data.values.map(obj => {
          return obj.id
        }) : ''
        filters[key] = value.length ? value : undefined
        filtersExtended[key] = data.values
      }
      this.$emit('emit-filter-select', filters)
      this.$emit('emit-filter-select-extended', filtersExtended)
    },
    emitFilterSelectButton () {
      this.$emit('emit-filter-select-button')
    },
    emitFilterSearch (data) {
      this.$emit('emit-filter-search', data)
    },
    emitFilterResult () {
      let filterSearch = String(this.filterSearch).toLowerCase()
      let outputData = JSON.parse(JSON.stringify(this.filterData))
      if (!outputData.length) return

      if (this.filterSearch) {
        outputData = outputData.filter(obj => {
          let searchPhrase = ''
          if (this.searchByField.constructor === Array) {
            for (let index in this.searchByField) {
              searchPhrase += obj[this.searchByField[index]]
              if (index !== (this.searchByField.length - 1)) {
                searchPhrase += ' '
              }
            }
          } else {
            searchPhrase = obj[this.searchByField]
          }
          if (obj.constructor === Object) {
            return searchPhrase.toLowerCase().includes(filterSearch)
          } else if (obj.constructor === Array) {
            const item = obj.find(subItem => subItem.label === this.searchByField)
            return item.value.toLowerCase().includes(filterSearch)
          }
        })
      }

      outputData = outputData.length ? outputData : null

      this.$emit('emit-filter-result', outputData)
    }
  },
  mounted () {
    this.emitFilterResult()
    const overlay = document.getElementById('app')
    overlay.addEventListener('click', this.closeMenus)

    if (this.filterSearchDefault) {
      this.filterSearch = this.filterSearchDefault
    }
    if (this.hasPreselectedFilterSelectItems) {
      this.selectBox = true
    }
  },
  watch: {
    filterSearch:
    debounce(function (value) {
      if (!this.searchQueryable) {
        this.emitFilterResult()
        this.emitFilterSearch(value)
      } else {
        this.$emit('emit-filter-query', value)
      }
    }, 800)
  },
  beforeDestroy () {
    const overlay = document.getElementById('app')
    overlay.removeEventListener('click', this.closeMenus)
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/ebs/filters/filter";
</style>
