import axios from 'axios'
import api from '@/api'

const data = {
  data: [],
  pagination: {},
  default: ''
}

const ebsLicenceConsumptions = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsLicenceConsumptionsData: state => {
      return state.data
    },
    getEbsLicenceConsumptionsPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    EBS_LICENCE_CONSUMPTIONS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    EBS_LICENCE_CONSUMPTIONS_SET_DATA (state, payload) {
      for (let enrolment of payload) {
        enrolment.attempts = []
      }
      state.data = payload
    },
    EBS_LICENCE_CONSUMPTIONS_SET_PAGINATION (state, payload) {
      state.pagination = payload
    },
    EBS_LICENCE_CONSUMPTIONS_SET_ATTEMPTS (state, payload) {
      let enrolment = state.data.find(obj => obj.id === payload.enrolmentId)
      enrolment.attempts = payload.attempts
    }
  },
  actions: {
    ebsLicenceConsumptionsGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const contractId = data.requestData

        axios({
          method: 'get',
          url: `${api.learningactivities}/licence-consumption-details/${contractId}`
        }).then(response => {
          commit('EBS_LICENCE_CONSUMPTIONS_SET_DATA', response.data.data)
          commit('EBS_LICENCE_CONSUMPTIONS_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsLicenceConsumptionsGetAttempts ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const enrolmentId = data

        axios({
          method: 'get',
          url: `${api.learningactivities}/enrolment-attempts/${enrolmentId}`
        }).then(response => {
          commit('EBS_LICENCE_CONSUMPTIONS_SET_ATTEMPTS', { enrolmentId: enrolmentId, attempts: response.data.data })
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsLicenceConsumptionsGetCSV (ctx, data) {
      return new Promise((resolve, reject) => {
        const contractId = data.contractId
        const courseName = data.courseName
        const customerName = data.customerName

        axios({
          method: 'get',
          url: `${api.learningactivities}/licence-consumption-details-csv/${contractId}`,
          responseType: 'arraybuffer'
        }).then((response) => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.getElementById('download-licence-consumptions-csv')
            link.href = url
            link.setAttribute('download', `${courseName}-${customerName}-license-consumption.csv`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `${courseName}-${customerName}-license-consumption.csv`)
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsLicenceConsumptionsClear ({ commit }) {
      commit('EBS_LICENCE_CONSUMPTIONS_CLEAR')
    }
  }
}

export default ebsLicenceConsumptions
