<template>
    <div class="filter__menu">
        {{ $t('filter.sort.menuToDo') }}
    </div>
</template>

<script>
export default {
  name: 'filter-sort-menu'
}
</script>
