import { Storage } from '@/Storage'

export class TablePreferences {
  static setTablePreferences (data) {
    const name = data.name
    const listView = data.listView
    const sortBy = data.sortBy
    const sortDirection = data.sortDirection

    let tablePreferences = Storage.getLocal('tablePreferences') ? JSON.parse(Storage.getLocal('tablePreferences')) : []
    const indexOfExists = tablePreferences ? tablePreferences.findIndex(obj => obj.name === name) : {}

    if (name) {
      if (indexOfExists > -1) {
        tablePreferences[indexOfExists].listView = listView
        tablePreferences[indexOfExists].sortBy = sortBy
        tablePreferences[indexOfExists].sortDirection = sortDirection
      } else {
        tablePreferences.push({
          name: name,
          listView: listView,
          sortBy: sortBy,
          sortDirection: sortDirection
        })
      }

      Storage.setLocal('tablePreferences', JSON.stringify(tablePreferences))
    }
  }

  static getTablePreferences (name) {
    const tablePreferences = JSON.parse(Storage.getLocal('tablePreferences'))
    let selected = tablePreferences ? tablePreferences.find(obj => obj.name === name) : {}
    return selected
  }
}
