import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    customerId: '',
    customerName: ''
  },
  default: ''
}

const lmsSuperadminMasteradmin = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminMasteradminFirstName (state) {
      return state.data.firstName
    },
    getLmsSuperadminMasteradminLastName (state) {
      return state.data.lastName
    },
    getLmsSuperadminMasteradminEmail (state) {
      return state.data.emailAddress
    },
    getLmsSuperadminMasteradminCustomerId (state) {
      return state.data.customerId
    },
    getLmsSuperadminMasteradminCustomerName (state) {
      return state.data.customerName
    }
  },
  mutations: {
    LMS_SUPERADMIN_MASTERADMIN_GET (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_MASTERADMIN_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    LMS_SUPERADMIN_MASTERADMIN_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_MASTERADMIN_SET_FIRSTNAME (state, payload) {
      state.data.firstName = payload
    },
    LMS_SUPERADMIN_MASTERADMIN_SET_LASTNAME (state, payload) {
      state.data.lastName = payload
    },
    LMS_SUPERADMIN_MASTERADMIN_SET_EMAIL (state, payload) {
      state.data.emailAddress = payload
    },
    LMS_SUPERADMIN_MASTERADMIN_SET_CUSTOMERID (state, payload) {
      state.data.customerId = payload
    },
    LMS_SUPERADMIN_MASTERADMIN_SET_CUSTOMERNAME (state, payload) {
      state.data.customerName = payload
    }
  },
  actions: {
    lmsSuperadminMasteradminCreate ({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        const stateData = JSON.parse(JSON.stringify(state.data))

        axios({
          method: 'post',
          url: `${api.sso}/v2/master-admin`,
          data: stateData
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Admin invited',
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          const responseStatus = error.response.status
          let titleText

          if (responseStatus === 412) {
            titleText = 'Admin already exists'
          } else {
            titleText = 'Admin not invited'
          }

          dispatch('defaultNotificationToastsAdd', {
            title: titleText,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminMasteradminRemove ({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${api.sso}/v2/master-admin/${id}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Admin removed',
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Admin not removed',
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminMasteradminReset ({ commit }) {
      commit('LMS_SUPERADMIN_MASTERADMIN_RESET')
    },
    lmsSuperadminMasteradminClear ({ commit }) {
      commit('LMS_SUPERADMIN_MASTERADMIN_CLEAR')
    },
    //
    lmsSuperadminMasteradminSetFirstName ({ commit }, value) {
      commit('LMS_SUPERADMIN_MASTERADMIN_SET_FIRSTNAME', value)
    },
    lmsSuperadminMasteradminSetLastName ({ commit }, value) {
      commit('LMS_SUPERADMIN_MASTERADMIN_SET_LASTNAME', value)
    },
    lmsSuperadminMasteradminSetEmail ({ commit }, value) {
      commit('LMS_SUPERADMIN_MASTERADMIN_SET_EMAIL', value)
    },
    lmsSuperadminMasteradminSetCustomerId ({ commit }, value) {
      commit('LMS_SUPERADMIN_MASTERADMIN_SET_CUSTOMERID', value)
    },
    lmsSuperadminMasteradminSetCustomerName ({ commit }, value) {
      commit('LMS_SUPERADMIN_MASTERADMIN_SET_CUSTOMERNAME', value)
    }
  }
}

export default lmsSuperadminMasteradmin
