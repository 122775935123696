import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const ebsModules = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsModules: state => {
      return state.data
    },
    getEbsModulesMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: `${item.name} (${item.majorVersion}.${item.minorVersion})`,
          name: item.name,
          value: item.scormContentId,
          versionId: item.versionId
        }
      })
    },
    getEbsModulesMappedIdNameVersionLabels: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.scormContentId,
          name: item.name,
          version: item.majorVersion + '.' + item.minorVersion,
          labels: item.labels ? item.labels.map(obj => Object.values(obj)[0]).join(', ') : []
        }
      })
    },
    getEbsModulesPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    EBS_MODULES_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    EBS_MODULES_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    },
    EBS_MODULES_SET (state, payload) {
      state.data = payload
    },
    EBS_MODULES_SET_PAGINATION (state, payload) {
      state.pagination = payload
    }
  },
  actions: {
    ebsModulesGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        commit('EBS_MODULES_CLEAR')
        commit('EBS_MODULES_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/modules${queryUrlParams}`
        }).then(response => {
          commit('EBS_MODULES_SET', response.data.data)
          commit('EBS_MODULES_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModulesClear ({ commit }) {
      commit('EBS_MODULES_CLEAR')
      commit('EBS_MODULES_CLEAR_PAGINATION')
    },
    ebsModulesGetSCORM ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        commit('EBS_MODULES_CLEAR')
        commit('EBS_MODULES_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/list-scorm-content${queryUrlParams}`
        }).then(response => {
          commit('EBS_MODULES_SET', response.data.data)
          commit('EBS_MODULES_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsModulesGetSCORMReleased ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        commit('EBS_MODULES_CLEAR')
        commit('EBS_MODULES_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/scorm-modules/released${queryUrlParams}`
        }).then(response => {
          commit('EBS_MODULES_SET', response.data.data)
          commit('EBS_MODULES_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ebsModules
