<template>
    <header
        v-if="allowHeader"
        class="header"
        id="header"
    >
        <header-top/>
        <transition-group name="fade" mode="out-in">
            <template v-if="false">
                <header-breadcrumb :key="'header-breadcrumb'"/>
            </template>
            <template v-if="Boolean(navItems.length > 1)">
                <header-nav
                    :key="'header-nav'"
                    :items="navItems"
                ></header-nav>
            </template>
        </transition-group>
        <pop-toast/>
    </header>
</template>

<script>
import HeaderTop from '@/components/default/layout/header/HeaderTop'
import HeaderBreadcrumb from '@/components/default/layout/header/HeaderBreadcrumb'
import HeaderNav from '@/components/default/layout/header/HeaderNav'
import { User } from '@/User'
import PopToast from '@/components/default/shared/PopToast'

export default {
  components: {
    PopToast,
    HeaderNav,
    HeaderBreadcrumb,
    HeaderTop
  },
  name: 'the-header',
  data () {
    return {
      user: new User()
    }
  },
  computed: {
    navItems () {
      let navArr = [
        // home
        {
          name: this.$t('header.nav.calendar'),
          routeName: 'ebs-calendar',
          visible: () => (
            (
              this.user.isEventCoordinator() ||
          this.user.isInfrastructureManager() ||
          this.user.isSiteAdmin() ||
          this.user.isTrainer() ||
          this.user.isLearner()
            ) &&
          this.user.hasAnyCustomerService(['EBS']) &&
          this.$route.meta.allowedFor === 'home'
          )
        },
        // catalogue
        {
          name: this.$t('header.nav.courses'),
          routeName: 'ebs-course-list',
          visible: () => (
            (
              (
                (
                  this.user.isCourseCreator() ||
          this.user.isCourseLicenceManager() ||
          this.user.isLearner() ||
          this.user.isLearningExperienceAdministrator() ||
          this.user.isManager()
                ) &&
          this.user.hasAnyCustomerService(['CCS'])
              ) ||
          (
            (
              this.user.isLearningExperienceAdministrator()
            ) &&
          this.user.hasAnyCustomerService(['LMS'])
          )
            ) &&
          this.$route.meta.allowedFor === 'catalogue'
          )
        },
        {
          name: 'Requests',
          routeName: 'ebs-requests-list',
          visible: () => (
            (
              this.user.isLearningExperienceAdministrator() ||
          this.user.isManager()
            ) &&
          this.$route.meta.allowedFor === 'catalogue'
          )
        },
        {
          name: this.$t('header.nav.events'),
          routeName: 'ebs-event-list',
          visible: () => (
            (
              this.user.isEventCoordinator() ||
          this.user.isInfrastructureManager() ||
          this.user.isSiteAdmin() ||
          this.user.isTrainer() ||
          this.user.isManager()
            ) &&
          this.user.hasAnyCustomerService(['EBS']) &&
          this.$route.meta.allowedFor === 'catalogue'
          )
        },
        {
          routeName: 'ebs-module-list',
          name: 'Modules',
          visible: () => (
            this.user.isCourseCreator() &&
          this.user.hasAnyCustomerService(['CCS']) &&
          this.$route.meta.allowedFor === 'catalogue'
          )
        },
        {
          routeName: 'ebs-licence-list',
          name: 'Licences',
          visible: () => (
            (
              this.user.isCourseLicenceManager() ||
          this.user.isCourseCreator()
            ) &&
          this.user.hasAnyCustomerService(['CCS']) &&
          this.$store.getters.getLmsSuperadminCustomerOptionsCCSAllowedCustomers.length &&
          this.$route.meta.allowedFor === 'catalogue'
          )
        },
        // users
        {
          name: 'Users',
          routeName: 'lms-superadmin-user-list',
          visible: () => (
            (
              (
                this.user.isSuperadmin() ||
          this.user.isUserAdministrator() ||
          this.user.isSiteAdmin() ||
          this.user.isManager()
              ) &&
          this.user.isTokenFromNewLms()
            ) &&
          this.$route.meta.allowedFor === 'users'
          )
        },
        {
          name: 'Site admins and trainers',
          routeName: 'ebs-admin-list',
          visible: () => (
            (
              this.user.isSuperadmin() ||
          (
            (
              this.user.isOwner() ||
          this.user.isSiteAdmin()
            ) &&
          this.user.getCustomerRoleArea() === 'master_administrator'
          )
            ) &&
          this.$route.meta.allowedFor === 'users'
          )
        },
        {
          name: 'Organisation units',
          routeName: 'lms-superadmin-org-units-edit',
          visible: () => (
            this.user.isSuperadmin() ||
          this.user.isSiteAdmin() ||
          this.user.isUserAdministrator()
          ) &&
          this.user.isTokenFromNewLms() &&
          this.user.hasSelectedProfile() &&
          this.$route.meta.allowedFor === 'users'
        },
        {
          name: 'Groups',
          routeName: 'lms-superadmin-groups-edit',
          visible: () => (
            this.user.isSuperadmin() ||
          this.user.isSiteAdmin() ||
          this.user.isUserAdministrator()
          ) &&
          this.user.isTokenFromNewLms() &&
          this.user.hasSelectedProfile() &&
          this.$route.meta.allowedFor === 'users'
        },
        {
          name: 'Auto-enrolments',
          routeName: 'ebs-autoenrolments-list',
          visible: () => (
            this.user.isLearningExperienceAdministrator() &&
          this.user.hasAnyCustomerService(['LMS']) &&
          this.$route.meta.allowedFor === 'users'
          )
        },
        {
          name: 'Workflows',
          routeName: 'ebs-workflows-list',
          visible: () => (
            this.user.isLearningExperienceAdministrator() &&
          this.user.hasAnyCustomerService(['LMS']) &&
          this.$route.meta.allowedFor === 'users'
          )
        },
        // reports
        {
          name: 'Dashboard',
          routeName: 'ebs-report-dashboard-show',
          visible: () => (
            (
              this.user.isTokenFromNewLms() &&
          this.user.hasAnyCustomerService(['LMS', 'EBS']) &&
          (
            this.user.isLearningExperienceAdministrator() ||
          this.user.isSiteAdmin() ||
          this.user.isManager() ||
          this.user.isSuperadmin()
          )
            ) &&
          this.$route.meta.allowedFor === 'report'
          )
        },
        {
          name: 'Reports',
          routeName: 'ebs-report-list',
          visible: () => (
            (
              (
                this.user.isTokenFromOldLms() &&
          this.user.isEventCoordinator()
              ) ||
          (
            this.user.isTokenFromNewLms() &&
          this.user.hasAnyCustomerService(['LMS']) &&
          (
            this.user.isSuperadmin() ||
          this.user.isSiteAdmin() ||
          this.user.isLearningExperienceAdministrator()
          )
          ) ||
          (
            this.user.isTokenFromNewLms() &&
          this.user.hasAnyCustomerService(['EBS']) &&
          (
            this.user.isSuperadmin() ||
          this.user.isSiteAdmin() ||
          this.user.isEventCoordinator()
          )
          )
            ) &&
          this.$route.meta.allowedFor === 'report'
          )
        },
        // admin
        {
          name: 'Site settings',
          routeName: 'ebs-site-settings-edit',
          routeParams: { id: this.user.workspaceId },
          visible: () => (
            (
              this.user.isSuperadmin() ||
          this.user.isAdmin() ||
          this.user.isSiteAdmin()
            ) &&
          this.user.hasSelectedProfile() &&
          this.$route.meta.allowedFor === 'setup'
          )
        },
        {
          name: this.$t('header.nav.venues'),
          routeName: 'ebs-venue-list',
          visible: () => (
            (
              this.user.isEventCoordinator() ||
          this.user.isInfrastructureManager() ||
          this.user.isSiteAdmin()
            ) &&
          this.user.hasAnyCustomerService(['EBS']) &&
          this.$route.meta.allowedFor === 'setup'
          )
        },
        {
          name: this.$t('header.topAccountNav.menu.mediaLibrary'),
          routeName: 'media-library-list',
          visible: () => (
            (
              this.user.isUserAdministrator() ||
          this.user.isEventCoordinator() ||
          this.user.isLearningExperienceAdministrator() ||
          this.user.isInfrastructureManager() ||
          this.user.isTrainer() ||
          (this.user.isSiteAdmin() && this.user.hasSelectedProfile())
            ) &&
          this.user.hasSelectedProfile() &&
          this.$route.meta.allowedFor === 'setup'
          )
        },
        {
          name: this.$t('header.nav.notifications'),
          routeName: 'lms-notification-list',
          visible: () => (
            (
              this.user.isDigitalWorkbookAdmin() ||
          this.user.isEventCoordinator() ||
          this.user.isUserAdministrator() ||
          this.user.isLearningExperienceAdministrator() ||
          (this.user.isSiteAdmin() && this.user.hasSelectedProfile())
            ) &&
          this.user.hasAnyCustomerService(['EBS', 'LMS', 'DW']) &&
          this.$route.meta.allowedFor === 'setup'
          )
        },
        {
          name: this.$t('header.topAccountNav.menu.customers'),
          routeName: 'lms-superadmin-customer-list',
          visible: () => (
            (this.user.isSuperadmin()) &&
          this.$route.meta.allowedFor === 'setup'
          )
        },
        {
          name: this.$t('header.topAccountNav.menu.workspaces'),
          routeName: 'lms-superadmin-workspace-list',
          visible: () => (
            (
              this.user.isSuperadmin() ||
          (
            this.user.isSiteAdmin() &&
          !this.user.hasSelectedProfile()
          )
            ) &&
          this.$route.meta.allowedFor === 'setup'
          )
        },
        {
          name: 'Archived',
          routeName: 'lms-superadmin-archived-list',
          visible: () => (
            (
              this.user.isSuperadmin()
            ) &&
          this.$route.meta.allowedFor === 'setup'
          )
        },
        // {
        //   name: 'Audit log',
        //   routeName: 'lms-superadmin-audit-log-list',
        //   visible: () => (
        //   (
        //   this.user.isSuperadmin() ||
        //   this.user.isSiteAdmin()
        //   ) &&
        //   this.$route.meta.allowedFor === 'setup'
        //   )
        // },
        {
          name: this.$t('header.nav.achievements'),
          routeName: 'lms-user-achievement-list',
          visible: () => (
            false &&
          this.user.isLearningExperienceAdministrator() &&
          this.user.hasAnyCustomerService(['EBS', 'LMS']) &&
          this.$route.meta.allowedFor === 'setup'
          )
        },
        // my learning
        {
          name: 'My courses',
          routeName: 'dashboard-courses',
          visible: () => (
            this.user.isLearner() &&
          this.user.isTokenFromNewLms() &&
          this.user.hasAnyCustomerService(['LMS']) &&
          this.$route.meta.allowedFor === 'my-learning'
          )
        },
        {
          name: 'My completions',
          routeName: 'dashboard-completions',
          visible: () => (
            this.user.isLearner() &&
          this.user.isTokenFromNewLms() &&
          this.$route.meta.allowedFor === 'my-learning'
          )
        },
        {
          name: 'My training',
          routeName: 'dashboard-trainer',
          visible: () => (
            this.user.isTrainer() &&
          this.$route.meta.allowedFor === 'my-learning'
          )
        },
        {
          name: 'Browse courses',
          routeName: 'dashboard-browse',
          visible: () => (
            (
              this.user.isLearner() ||
          this.user.isManager()
            ) &&
          this.user.isTokenFromNewLms() &&
          this.$route.meta.allowedFor === 'my-learning'
          )
        }
      ]

      const navArrOutput = navArr.filter(item => {
        return item.visible()
      })

      return navArrOutput
    },
    allowHeader () {
      return !this.$route.meta.hiddenHeader
    },
    allowBreadcrumbs () {
      let routeName = this.$route.name

      if (!routeName) return false

      return (
        routeName !== '404' &&
      this.user.hasCustomerServices(['EBS'])
      )
    },
    allowNav () {
      let routeName = this.$route.name
      let routeMetaAllowed = this.$route.meta.allowedFor

      if (!routeName) return false

      return (
        routeName !== '404' &&
      (
        routeMetaAllowed === 'home' ||
      routeMetaAllowed === 'catalogue' ||
      (
        routeMetaAllowed === 'users' &&
      (
        (
          (
            this.user.isSuperadmin() ||
      this.user.isSiteAdmin() ||
      this.user.isLearningExperienceAdministrator() ||
      this.user.isEventCoordinator()
          ) &&
      this.user.isTokenFromNewLms()
        ) ||
      (
        this.user.isEventCoordinator() &&
      this.user.isTokenFromOldLms()
      )
      )
      ) ||
      routeMetaAllowed === 'report' ||
      routeMetaAllowed === 'setup' ||
      routeMetaAllowed === 'my-learning'
      )
      )
    },
    secondStageToken () {
      return this.$store.getters.getAuthUserSecondStageTokenData
    }
  },
  watch: {
    secondStageToken () {
      this.user = new User()
    },
    allowNav () {
      setTimeout(() => this.$eventBus.$emit('handle-mobile-sticky-effect'), 300)
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/layout/header/header";
</style>
