import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: '',
  custom: {
    customer: {
      name: null,
      id: null
    }
  }
}

const ebsAdmins = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getAdmins: state => {
      return state.data
    },
    getAdminsMappedLabelValue: state => {
      const rawAdmins = state.data
      if (!rawAdmins.length) return []
      return rawAdmins.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.name,
          value: item.id
        }
      })
    },
    getAdminsMappedIdNameEmail: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          email: item.email
        }
      })
    },
    getAdminsPagination: state => {
      return state.pagination
    },
    getAdminsCustomCustomerId: state => {
      return state.custom.customer.id
    },
    getAdminsCustomCustomerName: state => {
      return state.custom.customer.name
    }
  },
  mutations: {
    SET_ADMINS (state, payload) {
      state.data = payload
    },
    SET_ADMINS_PAGINATION (state, payload) {
      state.pagination = payload
    },
    SET_ADMINS_CUSTOM_CUSTOMER (state, payload) {
      state.custom.customer = payload
    },
    CLEAR_ADMINS (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    CLEAR_ADMINS_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    getAdmins ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data && data.queryUrlParams ? data.queryUrlParams : '?pageSize=9999'
        const customerId = getters.getAuthUserSecondStageTokenDataCustomerId || getters.getAdminsCustomCustomerId

        commit('CLEAR_ADMINS')
        commit('CLEAR_ADMINS_PAGINATION')

        axios({
          method: 'get',
          url: `${api.sso}/v2/master-admin/${customerId}/list${queryUrlParams}`
        }).then(response => {
          commit('SET_ADMINS', response.data.data)
          commit('SET_ADMINS_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    setAdminsCustomCustomer ({ commit }, data) {
      commit('SET_ADMINS_CUSTOM_CUSTOMER', data)
    }
  }
}

export default ebsAdmins
