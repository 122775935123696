<template>
    <div :class="[
    'app',
    user.listPermissions(),
    isPrint ? 'print' : ''
    ]" id="app">
        <a class="skip-to-content-link" href="#main">Skip to content</a>
        <the-header/>
        <the-main id="main" />
        <the-footer/>
    </div>
</template>

<script>
import TheHeader from '@/components/default/layout/header/TheHeader'
import TheMain from '@/components/default/layout/main/TheMain'
import TheFooter from '@/components/default/layout/footer/TheFooter'
import { User } from '@/User'
import { isMobileView } from '@/functions'

export default {
  data () {
    return {
      user: new User(),
      lastScrollPosition: 0
    }
  },
  components: {
    TheFooter,
    TheMain,
    TheHeader
  },
  computed: {
    route: function () {
      return this.$route
    },
    isPrint: function () {
      return this.$route.meta.print
    }
  },
  methods: {
    handleMobileStickyEffect () {
      const lastScrollPosition = this.lastScrollPosition
      const appEl = document.getElementById('app')
      const headerEl = document.getElementById('header')
      const headerElHeight = headerEl ? headerEl.offsetHeight : 0
      const headerNavEl = document.getElementById('header__nav')
      const headerNavElHeight = headerNavEl ? headerNavEl.offsetHeight : 0
      const mainEl = document.getElementById('main')
      const appElScrollTopPosition = appEl.scrollTop

      if (!isMobileView()) {
        mainEl.style.paddingTop = 0
        return
      }

      mainEl.style.paddingTop = (headerElHeight + headerNavElHeight) + 'px'

      if (headerNavEl) {
        if (appElScrollTopPosition > headerElHeight) {
          if (lastScrollPosition < appElScrollTopPosition) {
            headerNavEl.classList.remove('header__nav--visible')
            headerNavEl.classList.add('header__nav--hidden')
          } else {
            headerNavEl.classList.remove('header__nav--hidden')
            headerNavEl.classList.add('header__nav--visible')
          }
        }
      }

      if (headerEl) {
        if (appElScrollTopPosition > 0) {
          headerEl.classList.add('header--sticky')
        } else {
          headerEl.classList.remove('header--sticky')
          if (headerNavEl) {
            headerNavEl.classList.remove('header__nav--hidden')
            headerNavEl.classList.remove('header__nav--visible')
          }
        }
      }

      this.lastScrollPosition = appElScrollTopPosition
    }
  },
  updated () {
    this.handleMobileStickyEffect()
  },
  mounted () {
    const overlay = document.getElementById('app')
    overlay.addEventListener('scroll', this.handleMobileStickyEffect)
    overlay.addEventListener('resize', this.handleMobileStickyEffect)
    this.$eventBus.$on('handle-mobile-sticky-effect', this.handleMobileStickyEffect)
  },
  beforeDestroy () {
    const overlay = document.getElementById('app')
    overlay.removeEventListener('scroll', this.handleMobileStickyEffect)
    overlay.removeEventListener('resize', this.handleMobileStickyEffect)
    this.$eventBus.$off('handle-mobile-sticky-effect')
  }
}
</script>
