import axios from 'axios'
import api from '@/api'
import { sortByKey } from '@/functions'
import { User } from '@/User'

const data = {
  data: {
    pagination: {},
    data: [],
    enrolledUsers: '',
    users: '',
    coordinators: []
  },
  default: ''
}

const ebsUsers = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getUsers: state => {
      return state.data.users
    },
    getUsersToEnrolMappedLabelValue: state => {
      const rawItems = state.data.users
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.firstname + item.lastname + item.id,
          customLabel: item.firstname + ' ' + item.lastname + ' (' + item.email + ')',
          value: item.id
        }
      })
    },
    getUsersToEnrolMappedIdNameStatus: state => {
      const rawItems = state.data.users
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          profileId: item.profileId,
          firstName: item.firstName + ' ' + item.lastName,
          emailAddress: item.emailAddress,
          organisationUnitName: item.organisationUnitName,
          status: item.enrolmentState || (item.isEnrolled ? 'enrolled' : 'not-enrolled'),
          completionsCount: item.completionsCount,
          lastCompletion: item.lastCompletion,
          disabled: item.isEnrolled
        }
      })
    },
    getUsersToWithdrawMappedLabelValue: state => {
      const rawItems = state.data.users
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.firstname + item.lastname + item.id,
          customLabel: item.firstname + ' ' + item.lastname + ' (' + item.email + ')',
          value: item.id
        }
      })
    },
    getUsersToWithdrawMappedIdNameStatus: state => {
      const rawItems = state.data.users
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          firstName: item.firstName + ' ' + item.lastName,
          emailAddress: item.emailAddress,
          enrolmentState: item.enrolmentState,
          enrolmentDate: item.enrolmentDate,
          lastCompletion: item.lastCompletion,
          disabled: !item.isEnrolled && item.enrolmentState !== 'awaiting_feedback'
        }
      })
    },
    getEnrolledUsers: state => {
      return state.data.enrolledUsers
    },
    getEnrolledUsersMappedLabelValue: state => {
      const rawItems = state.data.enrolledUsers
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.firstName + item.lastName + item.profileId,
          customLabel: item.firstName + ' ' + item.lastName + ' (' + item.email + ')',
          value: item.profileId
        }
      })
    },
    getEnrolledUsersMappedIdNameStatus: state => {
      const rawItems = state.data.enrolledUsers
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.profileId,
          userId: item.userId,
          name: item.firstName + ' ' + item.lastName,
          email: item.email,
          username: item.username,
          workspaceName: item.workspaceName,
          enrolledInParentCourse: item.enrolledInParentCourse
        }
      })
    },
    getEnrolledUsersTable: state => {
      const objectData = JSON.parse(JSON.stringify(state.data.enrolledUsers))
      let objectDataArr = ''
      if (objectData !== '') {
        objectDataArr = objectData.map(function (obj) {
          return [
            {
              isId: true,
              label: 'id',
              value: obj.id
            },
            {
              label: 'name',
              value: `${obj.firstname} ${obj.lastname}`
            },
            {
              label: 'email',
              value: obj.email
            },
            {
              label: 'username',
              value: obj.username
            }
          ]
        })
      }
      return objectDataArr
    },
    getCoordinators: state => {
      return state.data.coordinators
    },
    getCoordinatorsMappedLabelValue: state => {
      const user = new User()
      const rawItems = state.data.coordinators
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.l_fname + item.l_lname + item.lms_user_id,
          customLabel: item.l_fname + ' ' + item.l_lname + ` (${user.isSecondStageTokenFromOldLMS ? `${item.l_uname} - ` : ''}${item.l_mail})`,
          value: item.lms_user_id
        }
      })
    },
    getCoordinatorsMappedIdNameStatus: state => {
      const user = new User()
      const rawItems = state.data.coordinators
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.lms_user_id,
          name: item.l_fname + ' ' + item.l_lname + ` (${user.isSecondStageTokenFromOldLMS ? `${item.l_uname} - ` : ''}${item.l_mail})`,
          email: item.l_mail
        }
      })
    },

    getUsersPagination: state => {
      return state.data.pagination
    }
  },
  mutations: {
    SET_USERS (state, payload) {
      state.data.users = sortByKey(payload, 'l_fname')
    },
    SET_ENROLLED_USERS (state, payload) {
      state.data.enrolledUsers = sortByKey(payload, 'firstname')
    },
    SET_COORDINATORS (state, payload) {
      state.data.coordinators = sortByKey(payload, 'l_fname')
    },
    SET_USERS_PAGINATION (state, payload) {
      state.data.pagination = payload
    },
    //
    CLEAR_USERS (state) {
      state.data.users = JSON.parse(JSON.stringify(data.data.users))
    },
    CLEAR_ENROLLED_USERS (state) {
      state.data.enrolledUsers = JSON.parse(JSON.stringify(data.data.enrolledUsers))
    },
    CLEAR_COORDINATORS (state) {
      state.data.coordinators = JSON.parse(JSON.stringify(data.data.coordinators))
    },
    CLEAR_USERS_PAGINATION (state) {
      state.data.pagination = JSON.parse(JSON.stringify(data.data.pagination))
    }
  },
  actions: {
    getUsers ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'

        commit('CLEAR_USERS')
        commit('CLEAR_USERS_PAGINATION')

        axios({
          method: 'get',
          url: `${api.classroom}/users/available${queryUrlParams}`
        }).then(response => {
          commit('SET_USERS', response.data.data)
          commit('SET_USERS_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getUsersToEnrol ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const catalogItemId = data.requestData.catalogItemId
        const workspaceId = data.requestData.workspaceId || getters.getAuthUserSecondStageTokenDataWorkspaceId
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const customParams = data ? (data.customParams !== undefined ? `&${data.customParams}` : '') : ''

        commit('CLEAR_USERS')
        commit('CLEAR_USERS_PAGINATION')

        axios({
          method: 'get',
          url: `${api.reportengine}/user-list/to-enrol/${catalogItemId}/${workspaceId}${queryUrlParams}${customParams}`
        }).then(response => {
          commit('SET_USERS', response.data.data)
          commit('SET_USERS_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getUsersToWithdraw ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const catalogItemId = data.requestData.catalogItemId
        const workspaceId = data.requestData.workspaceId || getters.getAuthUserSecondStageTokenDataWorkspaceId
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const customParams = data ? (data.customParams !== undefined ? `&${data.customParams}` : '') : ''

        commit('CLEAR_USERS')
        commit('CLEAR_USERS_PAGINATION')

        axios({
          method: 'get',
          url: `${api.reportengine}/user-list/to-withdraw/${catalogItemId}/${workspaceId}${queryUrlParams}${customParams}`
        }).then(response => {
          commit('SET_USERS', response.data.data)
          commit('SET_USERS_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getEnrolledUsers ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'

        commit('CLEAR_ENROLLED_USERS')
        commit('CLEAR_USERS_PAGINATION')

        axios({
          method: 'get',
          url: `${api.reportengine}/user-list/for-booking/${getters.getEventId}${queryUrlParams}`
        }).then(function (response) {
          commit('SET_ENROLLED_USERS', response.data.data)
          commit('SET_USERS_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getUsersEnrolledToCatalogItem (context, data) {
      const learnersIds = data.learnersIds
      const catalogItemId = data.catalogItemId

      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.learningactivities}/users-enrolled-to-catalog-item/${catalogItemId}?learners=${learnersIds}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getCoordinators ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        const isOldLMSToken = getters.getAuthUserIsSecondStageTokenOldLMS
        let method
        let url
        let data
        if (isOldLMSToken) {
          method = 'post'
          url = `${api.sso}/users/role`
          data = {
            domain: getters.getAuthUserSecondStageTokenDataDomain,
            role: 'ebs_event_coordinator'
          }
        } else {
          method = 'get'
          url = `${api.sso}/v2/profile-users/event_coordinator`
          data = undefined
        }

        axios({
          method: method,
          url: url,
          data: data
        }).then(response => {
          commit('SET_COORDINATORS', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ebsUsers
