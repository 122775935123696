import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}
const lmsSuperadminDeletedObjects = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminDeletedObjectsData: state => {
      return state.data
    },
    getLmsSuperadminDeletedObjectsPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    LMS_SUPERADMIN_DELETED_OBJECTS_SET_DATA (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_DELETED_OBJECTS_SET_PAGINATION (state, payload) {
      state.pagination = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_DELETED_OBJECTS_CLEAR_DATA (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_DELETED_OBJECTS_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsSuperadminDeletedObjectsGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'

        commit('LMS_SUPERADMIN_DELETED_OBJECTS_CLEAR_DATA')
        commit('LMS_SUPERADMIN_DELETED_OBJECTS_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.reportengine}/archive-items${queryUrlParams}`
        }).then(response => {
          commit('LMS_SUPERADMIN_DELETED_OBJECTS_SET_DATA', response.data.data)
          commit('LMS_SUPERADMIN_DELETED_OBJECTS_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default lmsSuperadminDeletedObjects
