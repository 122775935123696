<template>
    <ul class="header__top_account_menu_box_enrolmentrequests">
        <li>
            <div class="columns is-vcentered">
                <div class="column">
                    <component-button
                        class="header__top_account_menu_box_enrolmentrequests_item"
                        :text="true"
                        :theme="''"
                        :size="''"
                        :align="'left'"
                        :name="'header-top_account-menu-box-enrolmentrequests-button'"
                        @click="performRedirect"
                    >Enrolment requests
                    </component-button>
                </div>
                <div class="column is-narrow">
                    <component-status :size="'small'" :status="requestsLength" :type="'primary'"></component-status>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>

import ComponentButton from '@/components/default/shared/ComponentButton'
import ComponentStatus from '@/components/default/shared/ComponentStatus'

export default {
  name: 'header-top-account-menu-box-enrolmentrequests',
  components: { ComponentStatus, ComponentButton },
  computed: {
    requestsLength () {
      return String(this.$store.getters.getEbsRequestsTotalResults)
    }
  },
  methods: {
    performRedirect () {
      this.$router.push({ name: 'ebs-requests-list' })
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/layout/header/headertopaccountmenuboxenrolmentrequests";
</style>
