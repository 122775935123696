import axios from 'axios'
import api from '@/api'

const data = {
  data: '',
  default: ''
}
const lmsSuperadminDeletedObject = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminDeletedObject: state => {
      return state.data
    }
  },
  mutations: {
    LMS_SUPERADMIN_DELETED_OBJECT_SET (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_DELETED_OBJECT_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    }
  },
  actions: {
    lmsSuperadminDeletedObjectGet ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('LMS_SUPERADMIN_DELETED_OBJECT_CLEAR')

        axios({
          method: 'get',
          url: `${api.reportengine}/archive-item/${id}`
        }).then(response => {
          commit('LMS_SUPERADMIN_DELETED_OBJECT_SET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminDeletedObjectRemove ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const sequenceId = data.sequenceId
        const type = data.type

        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/recover/${type}/${sequenceId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_DELETED_OBJECT_SET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default lmsSuperadminDeletedObject
