import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { toISODate } from '@/functions'

const CancelToken = axios.CancelToken
let cancelReportDashboardGetDataDigitalLearningEnrolments

const data = {
  data: {
    data: {
      digitalLearning: [],
      systemUsage: {
        users: '',
        logins: '',
        trainingTime: '',
        courses: '',
        totalEnrolments: '',
        totalCompletions: ''
      },
      learningActivity: {
        completions: [],
        totalTime: []
      },
      courseCompletionOverview: []
    },
    filters: {
      digital_learning: {
        temporary: [],
        permanent: []
      },
      system_usage: {
        temporary: [],
        permanent: []
      },
      learning_activity: {
        temporary: [],
        permanent: []
      },
      course_completion: {
        temporary: [],
        permanent: []
      }
    }
  },
  custom: {},
  default: ''
}

const ebsReportDashboard = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsReportDashboardDataLearningActivityCompletions: state => {
      return state.data.data.learningActivity.completions
    },
    getEbsReportDashboardDataLearningActivityTotalTime: state => {
      return state.data.data.learningActivity.totalTime
    },
    getEbsReportDashboardDataSystemUsageUsers: state => {
      return state.data.data.systemUsage.users
    },
    getEbsReportDashboardDataSystemUsageLogins: state => {
      return state.data.data.systemUsage.logins
    },
    getEbsReportDashboardDataSystemUsageTrainingTime: state => {
      return state.data.data.systemUsage.trainingTime
    },
    getEbsReportDashboardDataSystemUsageCourses: state => {
      return state.data.data.systemUsage.courses
    },
    getEbsReportDashboardDataSystemUsageTotalEnrolments: state => {
      return state.data.data.systemUsage.totalEnrolments
    },
    getEbsReportDashboardDataSystemUsageTotalCompletions: state => {
      return state.data.data.systemUsage.totalCompletions
    },
    getEbsReportDashboardDataCourseCompletionOverview: state => {
      return state.data.data.courseCompletionOverview
    },
    getEbsReportDashboardDataDigitalLearning: state => {
      const rawData = state.data.data.digitalLearning
      let dataCategories = rawData.map(obj => obj.name)
      let dataSeries = []

      dataSeries.push({
        name: 'Not started',
        data: rawData.map(obj => obj.not_started)
      })
      dataSeries.push({
        name: 'In progress',
        data: rawData.map(obj => obj.in_progress)
      })
      dataSeries.push({
        name: 'Completed',
        data: rawData.map(obj => obj.completed)
      })

      return { categories: dataCategories, series: dataSeries, rawData: rawData }
    },
    getEbsReportDashboardFiltersTemporary: state => filterKey => {
      return state.data.filters[filterKey].temporary
    },
    getEbsReportDashboardFiltersLearningActivity: state => {
      return state.data.filters.learning_activity.temporary
    },
    getEbsReportDashboardFiltersPermanentDigitalLearningCourses: state => {
      const digitalLearningFilter = state.data.filters.digital_learning.permanent
      const digitalLearningFilterCourses = digitalLearningFilter ? digitalLearningFilter.find(obj => obj.key === 'courses') : null
      const digitalLearningFilterCoursesValues = digitalLearningFilterCourses ? digitalLearningFilterCourses.values : []

      return digitalLearningFilterCoursesValues
    },
    getEbsReportDashboardFiltersPermanentSystemUsage: state => {
      return state.data.filters.system_usage.permanent
    }
  },
  mutations: {
    EBS_REPORT_DASHBOARD_GET_FILTERS (state, payload) {
      const filterKey = payload.filterKey
      const todaysDate = new Date()
      let responseData = JSON.parse(JSON.stringify(payload.responseData))
      let dateFilterIndex

      if (responseData.constructor === Array) {
        responseData = { permanent: [], temporary: [] }
        dateFilterIndex = -1
      } else {
        dateFilterIndex = responseData.temporary.indexOf(obj => obj.key === 'dates')
      }

      if (dateFilterIndex > -1) {
        responseData.temporary[dateFilterIndex].values.to = toISODate(todaysDate)
        if (responseData.temporary[dateFilterIndex].values.range === 'last-7-days') {
          responseData.temporary[dateFilterIndex].values.from = toISODate(new Date(todaysDate.setDate(todaysDate.getDate() - 6)))
        } else if (responseData.temporary[dateFilterIndex].values.range === 'last-30-days') {
          responseData.temporary[dateFilterIndex].values.from = toISODate(new Date(todaysDate.setDate(todaysDate.getDate() - 29)))
        } else if (responseData.temporary[dateFilterIndex].values.range === 'last-12-months') {
          responseData.temporary[dateFilterIndex].values.from = toISODate(new Date(todaysDate.setMonth(todaysDate.getMonth() - 12)))
        }
      } else {
        if (filterKey !== 'system_usage') {
          responseData.temporary.push({
            key: 'dates',
            values: {
              to: toISODate(todaysDate),
              from: toISODate(new Date(todaysDate.setDate(todaysDate.getDate() - 29))),
              range: 'last-30-days'
            }
          })
        }
      }

      state.data.filters[filterKey] = responseData
    },
    EBS_REPORT_DASHBOARD_SET_DATA_LEARNINGACTIVITY_COMPLETIONS (state, payload) {
      state.data.data.learningActivity.completions = payload
    },
    EBS_REPORT_DASHBOARD_SET_DATA_LEARNINGACTIVITY_TOTALTIME (state, payload) {
      state.data.data.learningActivity.totalTime = payload
    },
    EBS_REPORT_DASHBOARD_SET_DATA_SYSTEMUSAGE_USERS (state, payload) {
      state.data.data.systemUsage.users = payload
    },
    EBS_REPORT_DASHBOARD_SET_DATA_SYSTEMUSAGE_LOGINS (state, payload) {
      state.data.data.systemUsage.logins = payload
    },
    EBS_REPORT_DASHBOARD_SET_DATA_SYSTEMUSAGE_TRAININGTIME (state, payload) {
      state.data.data.systemUsage.trainingTime = payload
    },
    EBS_REPORT_DASHBOARD_SET_DATA_SYSTEMUSAGE_COURSES (state, payload) {
      state.data.data.systemUsage.courses = payload
    },
    EBS_REPORT_DASHBOARD_SET_DATA_SYSTEMUSAGE_TOTALENROLMENTS (state, payload) {
      state.data.data.systemUsage.totalEnrolments = payload
    },
    EBS_REPORT_DASHBOARD_SET_DATA_SYSTEMUSAGE_TOTALCOMPLETIONS (state, payload) {
      state.data.data.systemUsage.totalCompletions = payload
    },
    EBS_REPORT_DASHBOARD_SET_DATA_COURSECOMPLETIONOVERVIEW (state, payload) {
      state.data.data.courseCompletionOverview = payload
    },
    EBS_REPORT_DASHBOARD_SET_DATA_DIGITALLEARNING (state, payload) {
      state.data.data.digitalLearning = payload
    },
    EBS_REPORT_DASHBOARD_SET_FILTERS (state, payload) {
      const payloadFilterData = JSON.parse(JSON.stringify(payload.data))
      const payloadFilterKey = JSON.parse(JSON.stringify(payload.filterKey))
      const payloadFilterType = JSON.parse(JSON.stringify(payload.type))
      let payloadFilterDataValues = payloadFilterData
      let filters = JSON.parse(JSON.stringify(state.data.filters[payloadFilterKey][payloadFilterType]))
      let existingFilterIndex = filters.findIndex(obj => obj.key === payloadFilterData.key)

      if (existingFilterIndex > -1) {
        filters[existingFilterIndex].values = payloadFilterDataValues.values
        if (payloadFilterDataValues.values.constructor === Array && payloadFilterDataValues.values.length === 0) {
          filters.splice(existingFilterIndex, 1)
        }
      } else {
        filters.push({
          key: payloadFilterData.key,
          values: payloadFilterDataValues.values
        })
      }

      state.data.filters[payloadFilterKey][payloadFilterType] = filters
    },
    EBS_REPORT_DASHBOARD_CLEAR_DATA (state) {
      state.data.data = JSON.parse(JSON.stringify(data.data.data))
    },
    EBS_REPORT_DASHBOARD_CLEAR_FILTERS (state) {
      state.data.filters = JSON.parse(JSON.stringify(data.data.filters))
    }
  },
  actions: {
    ebsReportDashboardGetDataDigitalLearning ({ commit, state }) {
      return new Promise((resolve, reject) => {
        let filtersArr = []
        let filtersStr = ''
        const stateFiltersTemporary = state.data.filters.digital_learning.temporary
        const stateFiltersPermanent = state.data.filters.digital_learning.permanent
        const filterDateFrom = stateFiltersTemporary.find(obj => obj.key === 'dates')
        const filterDateFromOutput = filterDateFrom ? filterDateFrom.values.from : null
        const filterDateTo = stateFiltersTemporary.find(obj => obj.key === 'dates')
        const filterDateToOutput = filterDateTo ? filterDateTo.values.to : null
        const filterOrgUnits = stateFiltersTemporary.find(obj => obj.key === 'orgUnits')
        const filterOrgUnitsOutput = filterOrgUnits ? filterOrgUnits.values.map(obj => obj.id).join(',') : null
        const filterGroups = stateFiltersTemporary.find(obj => obj.key === 'groups')
        const filterGroupsOutput = filterGroups ? filterGroups.values.map(obj => obj.value).join(',') : null
        const filterCourses = stateFiltersPermanent.find(obj => obj.key === 'courses')
        const filterCoursesOutput = filterCourses ? filterCourses.values.map(obj => obj.id).join(',') : null

        if (filterDateFrom) filtersArr.push(`fromDate=${filterDateFromOutput}`)
        if (filterDateTo) filtersArr.push(`toDate=${filterDateToOutput}`)
        if (filterOrgUnits) filtersArr.push(`orgUnits=${filterOrgUnitsOutput}`)
        if (filterGroups) filtersArr.push(`groups=${filterGroupsOutput}`)
        if (filterCourses) filtersArr.push(`catalogItems=${filterCoursesOutput}`)

        for (let index in filtersArr) {
          if (Number(index) === 0) {
            filtersStr = '?'
          } else {
            filtersStr += '&'
          }
          filtersStr += filtersArr[index]
        }

        axios({
          method: 'get',
          url: `${api.reportengine}/dashboard/report/digital-learning-graph-data${filtersStr}`
        }).then(response => {
          commit('EBS_REPORT_DASHBOARD_SET_DATA_DIGITALLEARNING', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportDashboardGetDataDigitalLearningEnrolmentsCancelRequest () {
      if (cancelReportDashboardGetDataDigitalLearningEnrolments) cancelReportDashboardGetDataDigitalLearningEnrolments()
    },
    ebsReportDashboardGetDataDigitalLearningEnrolments ({ dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        let filtersArr = []
        let filtersStr = ''
        let reportDescriptionArr = []
        let reportDescriptionStr = ''
        const stateFiltersTemporary = state.data.filters.digital_learning.temporary
        const filterDateFrom = stateFiltersTemporary.find(obj => obj.key === 'dates')
        const filterDateFromOutput = filterDateFrom ? filterDateFrom.values.from : null
        const filterDateTo = stateFiltersTemporary.find(obj => obj.key === 'dates')
        const filterDateToOutput = filterDateTo ? filterDateTo.values.to : null
        const filterOrgUnits = stateFiltersTemporary.find(obj => obj.key === 'orgUnits')
        const filterOrgUnitsOutput = filterOrgUnits ? filterOrgUnits.values.map(obj => obj.id).join(',') : null
        const filterGroups = stateFiltersTemporary.find(obj => obj.key === 'groups')
        const filterGroupsOutput = filterGroups ? filterGroups.values.map(obj => obj.value).join(',') : null
        const filterEnrolmentState = data && data.enrolmentState ? data.enrolmentState : null

        const catalogItemId = data && data.catalogItem ? data.catalogItem.id : null
        const catalogItemName = data && data.catalogItem ? data.catalogItem.name : null

        if (filterDateFrom) {
          filtersArr.push(`fromDate=${filterDateFromOutput}`)
          reportDescriptionArr.push(`From date: ${filterDateFrom.values.from}`)
        }
        if (filterDateTo) {
          filtersArr.push(`toDate=${filterDateToOutput}`)
          reportDescriptionArr.push(`To date: ${filterDateTo.values.to}`)
        }
        if (filterOrgUnits) {
          filtersArr.push(`orgUnits=${filterOrgUnitsOutput}`)
          reportDescriptionArr.push(`Organisation units: ${filterOrgUnits.values.map(obj => obj.name).join(', ')}`)
        }
        if (filterGroups) {
          filtersArr.push(`groups=${filterGroupsOutput}`)
          reportDescriptionArr.push(`Groups: ${filterGroups.values.map(obj => obj.customLabel).join(', ')}`)
        }
        if (filterEnrolmentState) {
          filtersArr.push(`state=${filterEnrolmentState}`)
        }
        if (filterEnrolmentState) {
          let enrolmentStateOutput
          switch (filterEnrolmentState) {
            case 'enrolled':
              enrolmentStateOutput = 'Not started'
              break
            case 'in_progress':
              enrolmentStateOutput = 'In progress'
              break
            case 'completed':
              enrolmentStateOutput = 'Completed'
              break
          }
          reportDescriptionArr.push(`Enrolment state: ${enrolmentStateOutput}`)
        }

        reportDescriptionStr = reportDescriptionArr.join('\n')

        for (let index in filtersArr) {
          if (Number(index) === 0) {
            filtersStr = '?'
          } else {
            filtersStr += '&'
          }
          filtersStr += filtersArr[index]
        }

        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelReportDashboardGetDataDigitalLearningEnrolments = ex
          }),
          method: 'get',
          url: `${api.reportengine}/dashboard/report/enrolment-list/${catalogItemId}${filtersStr}`
        }).then(response => {
          let headers
          let data

          data = response.data.map(obj => {
            if (filterEnrolmentState === 'completed') {
              return [`${obj.firstName} ${obj.lastName}`, obj.emailAddress, obj.created_at, obj.updated_at]
            } else {
              return [`${obj.firstName} ${obj.lastName}`, obj.emailAddress, obj.created_at]
            }
          })
          if (filterEnrolmentState === 'completed') {
            headers = ['Learner name', 'Learner email', 'Enrolment date', 'Completion date']
          } else {
            headers = ['Learner name', 'Learner email', 'Enrolment date']
          }

          dispatch('ebsReportBuilderSetName', `${catalogItemName} enrolment status report`)
          dispatch('ebsReportBuilderSetDescription', reportDescriptionStr)
          dispatch('ebsReportBuilderBuilderSetGeneratedReport', { headers, data })
          router.push({ name: 'ebs-report-builder-execute-resolved' })
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportDashboardGetDataLearningActivity ({ commit, state }) {
      return new Promise((resolve) => {
        let filtersArr = []
        let filtersStr = ''
        let countRequests = 0
        const typesArr = ['completions', 'total-time']
        const stateFilters = state.data.filters.learning_activity.temporary
        const filterDateFrom = stateFilters.find(obj => obj.key === 'dates')
        const filterDateFromOutput = filterDateFrom ? filterDateFrom.values.from : null
        const filterDateTo = stateFilters.find(obj => obj.key === 'dates')
        const filterDateToOutput = filterDateTo ? filterDateTo.values.to : null
        const filterOrgUnits = stateFilters.find(obj => obj.key === 'orgUnits')
        const filterOrgUnitsOutput = filterOrgUnits ? filterOrgUnits.values.map(obj => obj.id).join(',') : null
        const filterGroups = stateFilters.find(obj => obj.key === 'groups')
        const filterGroupsOutput = filterGroups ? filterGroups.values.map(obj => obj.value).join(',') : null

        if (filterDateFrom) filtersArr.push(`fromDate=${filterDateFromOutput}`)
        if (filterDateTo) filtersArr.push(`toDate=${filterDateToOutput}`)
        if (filterOrgUnits) filtersArr.push(`orgUnits=${filterOrgUnitsOutput}`)
        if (filterGroups) filtersArr.push(`groups=${filterGroupsOutput}`)

        for (let index in filtersArr) {
          if (Number(index) === 0) {
            filtersStr = '?'
          } else {
            filtersStr += '&'
          }
          filtersStr += filtersArr[index]
        }

        const dispatchAction = (type) => {
          return new Promise((resolve, reject) => {
            axios({
              method: 'get',
              url: `${api.reportengine}/dashboard/report/learning-activity/${type}${filtersStr}`
            }).then(response => {
              let commitName
              if (type === 'completions') {
                commitName = 'EBS_REPORT_DASHBOARD_SET_DATA_LEARNINGACTIVITY_COMPLETIONS'
              } else if (type === 'total-time') {
                commitName = 'EBS_REPORT_DASHBOARD_SET_DATA_LEARNINGACTIVITY_TOTALTIME'
              }
              commit(commitName, response.data)
              resolve(response.data)
            }).catch(error => {
              reject(error)
            })
          })
        }

        for (let type of typesArr) {
          dispatchAction(type).finally(() => {
            countRequests++
            if (countRequests === typesArr.length) {
              resolve()
            }
          })
        }
      })
    },
    ebsReportDashboardGetDataCourseCompletion ({ commit, state }) {
      return new Promise((resolve, reject) => {
        let filtersArr = []
        let filtersStr = ''
        const stateFilters = state.data.filters.course_completion.temporary
        const filterDateFrom = stateFilters.find(obj => obj.key === 'dates')
        const filterDateFromOutput = filterDateFrom ? filterDateFrom.values.from : null
        const filterDateTo = stateFilters.find(obj => obj.key === 'dates')
        const filterDateToOutput = filterDateTo ? filterDateTo.values.to : null
        const filterOrgUnits = stateFilters.find(obj => obj.key === 'orgUnits')
        const filterOrgUnitsOutput = filterOrgUnits ? filterOrgUnits.values.map(obj => obj.id).join(',') : null
        const filterGroups = stateFilters.find(obj => obj.key === 'groups')
        const filterGroupsOutput = filterGroups ? filterGroups.values.map(obj => obj.value).join(',') : null

        if (filterDateFrom) filtersArr.push(`fromDate=${filterDateFromOutput}`)
        if (filterDateTo) filtersArr.push(`toDate=${filterDateToOutput}`)
        if (filterOrgUnits) filtersArr.push(`orgUnits=${filterOrgUnitsOutput}`)
        if (filterGroups) filtersArr.push(`groups=${filterGroupsOutput}`)

        for (let index in filtersArr) {
          if (Number(index) === 0) {
            filtersStr = '?'
          } else {
            filtersStr += '&'
          }
          filtersStr += filtersArr[index]
        }

        axios({
          method: 'get',
          url: `${api.reportengine}/dashboard/report/course-completion-overview${filtersStr}`
        }).then(response => {
          commit('EBS_REPORT_DASHBOARD_SET_DATA_COURSECOMPLETIONOVERVIEW', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportDashboardGetDataSystemUsageUsers ({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/dashboard/report/users`
        }).then(response => {
          commit('EBS_REPORT_DASHBOARD_SET_DATA_SYSTEMUSAGE_USERS', response.data.usersCount)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportDashboardGetDataSystemUsageLogins ({ commit }, data) {
      const scope = data ? data.selectOption : 'year'
      const weekends = data ? data.checkboxOption : true

      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/dashboard/report/workspace-logins/${scope}${weekends ? `/weekends` : ''}`
        }).then(response => {
          const loginCount = response.data.averageLoginCount
          commit('EBS_REPORT_DASHBOARD_SET_DATA_SYSTEMUSAGE_LOGINS', loginCount)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportDashboardGetDataSystemUsageTrainingTime ({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/dashboard/report/enrolment-total-training-time`
        }).then(response => {
          commit('EBS_REPORT_DASHBOARD_SET_DATA_SYSTEMUSAGE_TRAININGTIME', response.data.total_hours)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportDashboardGetDataSystemUsageCourses ({ commit, dispatch }) {
      return new Promise((resolve) => {
        dispatch('ebsCoursesGetForAutoenrolmentsWorkflows').then(response => {
          commit('EBS_REPORT_DASHBOARD_SET_DATA_SYSTEMUSAGE_COURSES', response.pagination.totalResults)
        }).finally(() => {
          resolve()
        })
      })
    },
    ebsReportDashboardGetDataSystemUsageTotalEnrolments ({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/dashboard/report/enrolments`
        }).then(response => {
          commit('EBS_REPORT_DASHBOARD_SET_DATA_SYSTEMUSAGE_TOTALENROLMENTS', response.data.enrolmentsCount)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportDashboardGetDataSystemUsageTotalCompletions ({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/dashboard/report/enrolments/completed`
        }).then(response => {
          commit('EBS_REPORT_DASHBOARD_SET_DATA_SYSTEMUSAGE_TOTALCOMPLETIONS', response.data.enrolmentsCount)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportDashboardUpdateFilters ({ state }, data) {
      return new Promise((resolve, reject) => {
        const filterKey = data.filterKey
        const filterData = data.filterData || state.data.filters[filterKey]

        axios({
          method: 'post',
          url: `${api.reportengine}/report-filter/${filterKey}`,
          data: {
            filterMetadata: filterData
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportDashboardResetFilters ({ dispatch }) {
      return new Promise(() => {
        const filterKeysArr = ['digital_learning', 'system_usage', 'learning_activity', 'course_completion']

        for (let filterKey of filterKeysArr) {
          dispatch('ebsReportDashboardGetFilters', filterKey).then(response => {
            const filterData = response ? response.filterMetadata : ''
            if (filterData && filterData.constructor === Object && filterData.temporary.length) {
              dispatch('ebsReportDashboardUpdateFilters', {
                filterKey,
                filterData: {
                  permanent: filterData.permanent,
                  temporary: []
                }
              })
            }
          })
        }
      })
    },
    ebsReportDashboardGetFilters ({ commit, dispatch }, filterKey) {
      return new Promise((resolve, reject) => {
        dispatch('ebsReportDashboardClearFilters')

        axios({
          method: 'get',
          url: `${api.reportengine}/report-filter/${filterKey}`
        }).then(response => {
          const responseData = response.data ? response.data.filterMetadata : []
          resolve(response.data)
          commit('EBS_REPORT_DASHBOARD_GET_FILTERS', { filterKey, responseData })
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportDashboardSetFilters ({ commit }, data) {
      commit('EBS_REPORT_DASHBOARD_SET_FILTERS', data)
    },
    ebsReportDashboardClearData ({ commit }) {
      commit('EBS_REPORT_DASHBOARD_CLEAR_DATA')
    },
    ebsReportDashboardClearFilters ({ commit }) {
      commit('EBS_REPORT_DASHBOARD_CLEAR_FILTERS')
    }
  }
}

export default ebsReportDashboard
