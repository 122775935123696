import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { checkResponseNotFound } from '@/interceptorsHelpers'

const ebsRoom = {
  state: {
    data: {
      sharingWorkspaces: [],
      activeRelations: {
        event: false,
        session: false
      },
      capacity: 10,
      cost: 0,
      costPeriod: 'hour',
      description: '',
      files: [],
      id: '',
      location: '',
      name: '',
      status: 'draft',
      venue: {
        address: {},
        contactEmail: '',
        contactPhone: '',
        description: '',
        files: [],
        id: '',
        name: '',
        status: '',
        subdomains: []
      }
    },
    default: {}
  },
  getters: {
    getRoom: state => {
      return state.data
    },
    //
    getRoomId: state => {
      return state.data.id
    },
    getRoomName: state => {
      return state.data.name
    },
    getRoomDescription: state => {
      return state.data.description
    },
    getRoomImages: state => {
      return state.data.files
    },
    getRoomLocation: state => {
      return state.data.location
    },
    getRoomCapacity: state => {
      return state.data.capacity
    },
    getRoomCost: state => {
      return state.data.cost
    },
    getRoomCostPeriod: state => {
      return state.data.costPeriod
    },
    getRoomStatus: state => {
      return state.data.status
    },
    getRoomVenueId: state => {
      if (state.data.venue) {
        return state.data.venue.id
      }
    },
    getRoomVenueName: state => {
      if (state.data.venue) {
        return state.data.venue.name
      }
    },
    getRoomVenueContactEmail: state => {
      if (state.data.venue) {
        return state.data.venue.contactEmail
      }
    },
    getRoomVenueContactPhone: state => {
      if (state.data.venue) {
        return state.data.venue.contactPhone
      }
    },
    getRoomVenueAddress: state => {
      if (state.data.venue) {
        return state.data.venue.address
      }
    },
    getRoomVenueFormattedAddress: state => {
      if (state.data.venue) {
        return state.data.venue.address.formatted_address
      }
    },
    //
    getRoomHasActiveRelations: state => {
      const activeRelations = state.data.activeRelations
      let hasActiveRelations

      for (let item in activeRelations) {
        if (activeRelations[item]) hasActiveRelations = true
      }

      return hasActiveRelations
    },
    //
    getRoomAllowEdit: (state, getters) => {
      return !getters.getRoomHasActiveRelations && getters.getRoomStatus !== 'archived'
    },
    getRoomSharingWorkspaces: state => {
      return state.data.sharingWorkspaces
    }
  },
  mutations: {
    GET_ROOM (state, payload) {
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    //
    CLEAR_ROOM (state) {
      state.data = {
        sharingWorkspaces: [],
        activeRelations: {
          event: false,
          session: false
        },
        capacity: 10,
        cost: 0,
        costPeriod: 'hour',
        description: '',
        files: [],
        id: '',
        location: '',
        name: '',
        status: 'draft',
        venue: {
          address: {},
          contactEmail: '',
          contactPhone: '',
          description: '',
          files: [],
          id: '',
          name: '',
          status: '',
          subdomains: []
        }
      }
      state.default = JSON.parse(JSON.stringify(state.data))
    },
    RESET_ROOM (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    SET_ROOM_NAME (state, payload) {
      state.data.name = payload
    },
    SET_ROOM_DESCRIPTION (state, payload) {
      state.data.description = payload
    },
    SET_ROOM_IMAGES (state, payload) {
      const currentImages = JSON.parse(JSON.stringify(state.data.files))
      state.data.files = [...currentImages, ...payload]
    },
    SET_ROOM_LOCATION (state, payload) {
      state.data.location = payload
    },
    SET_ROOM_CAPACITY (state, payload) {
      state.data.capacity = payload
    },
    SET_ROOM_COST (state, payload) {
      state.data.cost = payload
    },
    SET_ROOM_COST_PERIOD (state, payload) {
      state.data.costPeriod = payload
    },
    SET_ROOM_STATUS (state, payload) {
      state.data.status = payload
    },
    //
    SET_ROOM_VENUEID (state, payload) {
      state.data.venueId = payload
    },
    SET_ROOM_SHARING_WORKSPACES (state, payload) {
      let sharingWorkspaces = JSON.parse(JSON.stringify(state.data.sharingWorkspaces))
      const id = payload.id
      const value = payload.value
      const indexOfWorkspace = sharingWorkspaces.indexOf(id)

      if (value === true) {
        if (indexOfWorkspace === -1) sharingWorkspaces.push(id)
      } else {
        if (indexOfWorkspace > -1) sharingWorkspaces.splice(indexOfWorkspace, 1)
      }
      state.data.sharingWorkspaces = sharingWorkspaces
    },
    //
    REMOVE_ROOM_IMAGES (state, key) {
      state.data.files.splice(key, 1)
    }
  },
  actions: {
    getRoom ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_ROOM')
        axios({
          method: 'get',
          url: `${api.classroom}/rooms/${id}`
        }).then(response => {
          commit('GET_ROOM', response.data)
          resolve(response.data)
        }).catch(error => {
          checkResponseNotFound(error.response.status)
          reject(error)
        })
      })
    },
    //
    clearRoom (context) {
      context.commit('CLEAR_ROOM')
    },
    resetRoom (context) {
      context.commit('RESET_ROOM')
    },
    removeRoom ({ commit, rootState, dispatch }, id) {
      axios
        .delete(`${api.classroom}/rooms/${id}`)
        .then(() => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Room remove',
            message: `Room has been successfully removed.`,
            status: 'success'
          })
          commit('CLEAR_ROOM')
          commit('CLEAR_ROOMS')
          commit('CLEAR_VENUE')
          commit('CLEAR_VENUES')
          router.push({ name: 'ebs-venue-show', params: { id: rootState.route.params.venueId } })
        }).catch(error => {
          const errorType = error.response.data.errors.message
          const usedByEvents = error.response.data.data.map(obj => `- ${obj.name}`)
          const usedByEventsList = usedByEvents.join('\r\n')
          let toastMessage

          if (errorType === 'item_in_use') {
            toastMessage = `Room hasn't been removed because it's used by following events:\n${usedByEventsList}`
          } else {
            toastMessage = `Room hasn't been removed`
          }

          dispatch('defaultNotificationToastsAdd', {
            title: 'Room remove',
            message: toastMessage,
            status: 'error'
          })
        })
    },
    archiveRoom ({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'patch',
          url: `${api.classroom}/rooms/archive/${id}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Room archived',
            message: `Room has been successfully archived.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Room not archived',
            message: `Room has not been archived.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    restoreRoom ({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'patch',
          url: `${api.classroom}/rooms/restore/${id}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Room restored',
            message: `Room has been successfully restored.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Room not restored',
            message: `Room has not been restored.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    updateRoom ({ commit, rootState, state, dispatch }) {
      let roomId = state.data.id
      //
      commit('SET_ROOM_VENUEID', rootState.route.params.venueId)
      //
      axios({
        method: 'patch',
        url: `${api.classroom}/rooms`,
        data: state.data
      }).then(function () {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Room update',
          message: `Room has been successfully updated.`,
          status: 'success'
        })
        commit('CLEAR_VENUE')
        commit('CLEAR_VENUES')
        commit('CLEAR_ROOMS')
        commit('CLEAR_ROOM')
        router.push({ name: 'ebs-room-show', params: { id: roomId } })
      }).catch(function () {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Room update',
          message: `Room hasn't been updated.`,
          status: 'error'
        })
      })
    },
    createRoom ({ commit, rootState, state, dispatch }) {
      commit('SET_ROOM_VENUEID', rootState.route.params.venueId)
      //
      axios({
        method: 'post',
        url: `${api.classroom}/rooms`,
        data: state.data
      }).then(function (response) {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Room create',
          message: `Room has been successfully created.`,
          status: 'success',
          linkText: 'Go to room',
          linkTo: {
            name: 'ebs-room-show',
            params: { venueId: rootState.route.params.venueId, roomId: response.data.id }
          }
        })
        commit('CLEAR_VENUE')
        commit('CLEAR_VENUES')
        commit('CLEAR_ROOMS')
        router.push({ name: 'ebs-venue-show', params: { id: rootState.route.params.venueId } })
      }).catch(function () {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Room create',
          message: `Room hasn't been created.`,
          status: 'error'
        })
      })
    },
    //
    setRoomName (context, value) {
      context.commit('SET_ROOM_NAME', value)
    },
    setRoomDescription (context, value) {
      context.commit('SET_ROOM_DESCRIPTION', value)
    },
    setRoomImages (context, data) {
      data = data.map(value => {
        return {
          fileId: value
        }
      })
      context.commit('SET_ROOM_IMAGES', data)
    },
    setRoomLocation (context, value) {
      context.commit('SET_ROOM_LOCATION', value)
    },
    setRoomCapacity (context, value) {
      context.commit('SET_ROOM_CAPACITY', value)
    },
    setRoomCost (context, value) {
      context.commit('SET_ROOM_COST', value)
    },
    setRoomCostPeriod (context, value) {
      context.commit('SET_ROOM_COST_PERIOD', value)
    },
    setRoomStatus (context, value) {
      context.commit('SET_ROOM_STATUS', value)
    },
    //
    removeRoomImages (context, key) {
      context.commit('REMOVE_ROOM_IMAGES', key)
    },
    setRoomSharingWorkspaces ({ commit }, data) {
      commit('SET_ROOM_SHARING_WORKSPACES', data)
    }
  }
}

export default ebsRoom
