import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    firstName: '',
    id: '',
    personal: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      status: '',
      customFields: [],
      api: false,
      profiles: []
    },
    security: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    }
  },
  default: ''
}

const lmsUserAccount = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsUserAccountId (state) {
      return state.data.id
    },
    getLmsUserAccount (state) {
      return state.data.personal
    },
    getLmsUserAccountPersonalAPI (state) {
      return state.data.personal.api
    },
    getLmsUserAccountPersonalFirstName (state) {
      return state.data.personal.firstName
    },
    getLmsUserAccountPersonalLastName (state) {
      return state.data.personal.lastName
    },
    getLmsUserAccountPersonalEmail (state) {
      return state.data.personal.emailAddress
    },
    getLmsUserAccountPersonalCustomFields (state) {
      return state.data.personal.customFields
    },
    getLmsUserAccountSecurityOldPassword (state) {
      return state.data.security.oldPassword
    },
    getLmsUserAccountSecurityNewPassword (state) {
      return state.data.security.newPassword
    },
    getLmsUserAccountSecurityConfirmNewPassword (state) {
      return state.data.security.confirmNewPassword
    }
  },
  mutations: {
    LMS_USER_ACCOUNT_GET (state, payload) {
      let newData = JSON.parse(JSON.stringify(state.data))
      newData.id = payload.id
      newData.personal.firstName = payload.firstName
      newData.personal.lastName = payload.lastName
      newData.personal.emailAddress = payload.emailAddress
      newData.personal.status = payload.status
      newData.personal.customFields = payload.customFields || []
      newData.personal.api = payload.api

      state.data = newData
      state.default = JSON.parse(JSON.stringify(newData))
    },
    LMS_USER_ACCOUNT_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    LMS_USER_ACCOUNT_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_USER_ACCOUNT_SECURITY_CLEAR (state) {
      state.data.security = JSON.parse(JSON.stringify(data.data.security))
    },
    //
    LMS_USER_ACCOUNT_SET_PERSONAL_FIRSTNAME (state, payload) {
      state.data.personal.firstName = payload
    },
    LMS_USER_ACCOUNT_SET_PERSONAL_LASTNAME (state, payload) {
      state.data.personal.lastName = payload
    },
    LMS_USER_ACCOUNT_SET_PERSONAL_EMAIL (state, payload) {
      state.data.personal.emailAddress = payload
    },
    LMS_USER_ACCOUNT_SET_PERSONAL_CUSTOMFIELD (state, payload) {
      let customFields = JSON.parse(JSON.stringify(state.data.personal.customFields))
      let existingCustomFieldIndex = customFields.findIndex(obj => obj.id === payload.id)

      if (existingCustomFieldIndex > -1) {
        if (payload.value !== '') {
          customFields[existingCustomFieldIndex].value = payload.value
        } else {
          customFields.splice(existingCustomFieldIndex, 1)
        }
      } else {
        customFields.push({
          id: payload.id,
          value: payload.value
        })
      }

      state.data.personal.customFields = customFields
    },
    LMS_USER_ACCOUNT_SET_SECURITY_OLDPASSWORD (state, payload) {
      state.data.security.oldPassword = payload
    },
    LMS_USER_ACCOUNT_SET_SECURITY_NEWPASSWORD (state, payload) {
      state.data.security.newPassword = payload
    },
    LMS_USER_ACCOUNT_SET_SECURITY_CONFIRMNEWPASSWORD (state, payload) {
      state.data.security.confirmNewPassword = payload
    }
  },
  actions: {
    lmsUserAccountGet ({ commit, dispatch }, id) {
      return new Promise((resolve, reject) => {
        return axios({
          method: 'get',
          url: `${api.sso}/v2/users/${id}`
        }).then(response => {
          commit('LMS_USER_ACCOUNT_GET', response.data)
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'User get',
            message: `Something went wrong while downloading user details. Please try again or contact an administrator.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsUserAccountUpdate ({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('lmsUserAccountUpdateHandleCognito').then(() => {
          dispatch('lmsUserAccountUpdateHandleSSO').then(() => {
            dispatch('authUserRefreshFirstStageToken').then(() => {
              dispatch('defaultNotificationToastsAdd', {
                title: 'User update',
                message: `You have successfully updated user details.`,
                status: 'success'
              })
              resolve()
            }).catch((error) => {
              dispatch('defaultNotificationToastsAdd', {
                title: 'User update',
                message: `Something went wrong while downloading user details. Please try again or contact an administrator.`,
                status: 'error'
              })
              dispatch('authUserLogout')
              reject(error)
            })
          }).catch((error) => {
            dispatch('defaultNotificationToastsAdd', {
              title: 'User update',
              message: `Something went wrong while performing user update. Please try again or contact an administrator.`,
              status: 'error'
            })
            reject(error)
          })
        }).catch((error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'User update',
            message: `Something went wrong while performing user update. Please try again or contact an administrator.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsUserAccountUpdateHandleCognito ({ state }) {
      return new Promise((resolve, reject) => {
        const firstName = state.data.personal.firstName
        const lastName = state.data.personal.lastName
        const userPoolId = process.env.VUE_APP_COGNITO_USERPOOL_ID
        const clientId = process.env.VUE_APP_COGNITO_CLIENT_ID
        const poolData = {
          UserPoolId: userPoolId,
          ClientId: clientId
        }
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
        const cognitoUser = userPool.getCurrentUser()

        let attributeList = []
        let attributeFirstName = {
          Name: 'name',
          Value: firstName
        }
        let attributeLastName = {
          Name: 'family_name',
          Value: lastName
        }

        attributeFirstName = new AmazonCognitoIdentity.CognitoUserAttribute(attributeFirstName)
        attributeLastName = new AmazonCognitoIdentity.CognitoUserAttribute(attributeLastName)
        attributeList.push(attributeFirstName, attributeLastName)

        if (cognitoUser != null) {
          cognitoUser.getSession((err) => {
            if (err) {
              reject(err)
            }
            cognitoUser.updateAttributes(attributeList, function (err) {
              if (err) {
                reject(err)
              }
              resolve()
            })
          })
        }
      })
    },
    lmsUserAccountUpdateHandleSSO ({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        const stateDefault = state.default
        const stateDefaultEmail = stateDefault.personal.emailAddress

        const stateData = state.data
        const stateDataEmail = stateData.personal.emailAddress

        let requestsArr = []
        let requestsCounter = 0
        let requestsSuccess = 0

        requestsArr.push('lmsUserAccountUpdateDetails')

        if (stateDefaultEmail !== stateDataEmail) {
          requestsArr.push('lmsUserAccountUpdateEmail')
        }

        for (let fn of requestsArr) {
          dispatch(fn).then(() => {
            requestsSuccess++
          }).finally(() => {
            requestsCounter++
            if (Number(requestsCounter) === Number(requestsArr.length)) {
              if (Number(requestsSuccess) === Number(requestsArr.length)) {
                resolve()
              } else {
                reject(new Error())
              }
            }
          })
        }
      })
    },
    lmsUserAccountUpdateDetails ({ state, getters }, data) {
      return new Promise((resolve, reject) => {
        const userHasSelectedProfile = getters.getLmsSuperadminWorkspaceUserProfileId
        const loggedInUserProfileWorkspaceId = getters.getLmsSuperadminWorkspaceUserProfileWorkspaceId

        let stateData = data || JSON.parse(JSON.stringify(state.data.personal))
        stateData.workspaceId = stateData.workspaceId || (userHasSelectedProfile && loggedInUserProfileWorkspaceId ? loggedInUserProfileWorkspaceId : undefined)

        let rewrittenCustomFields = {}

        if (stateData.customFields) {
          for (let field of stateData.customFields) {
            if (field.value) {
              rewrittenCustomFields[field.id] = field.value
            }
          }

          stateData.customFields = rewrittenCustomFields
        }

        stateData.id = stateData.id || state.data.id

        return axios({
          method: 'patch',
          url: `${api.sso}/v2/users`,
          data: stateData
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsUserAccountUpdateEmail ({ state }, data) {
      return new Promise((resolve, reject) => {
        let stateData = state.data

        const userId = data ? data.id : stateData.id
        const email = data ? data.emailAddress : stateData.personal.emailAddress

        return axios({
          method: 'patch',
          url: `${api.sso}/v2/user/${userId}/update-email`,
          data: {
            emailAddress: email
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsUserAccountReset ({ commit }) {
      commit('LMS_USER_ACCOUNT_RESET')
    },
    lmsUserAccountSecurityUpdate ({ getters, dispatch }) {
      return new Promise((resolve, reject) => {
        const oldPassword = getters.getLmsUserAccountSecurityOldPassword
        const newPassword = getters.getLmsUserAccountSecurityNewPassword
        const userPoolId = process.env.VUE_APP_COGNITO_USERPOOL_ID
        const clientId = process.env.VUE_APP_COGNITO_CLIENT_ID
        const poolData = {
          UserPoolId: userPoolId,
          ClientId: clientId
        }
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
        const cognitoUser = userPool.getCurrentUser()

        cognitoUser.getSession(function (err) {
          if (err) {
            reject(new Error('errorValidateRefreshToken'))
          }
        })
        cognitoUser.changePassword(oldPassword, newPassword, (err, data) => {
          if (err) {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Password change',
              message: `There were errors while changing password. Please try again or contact an administrator.`,
              status: 'error'
            })
            return reject(err)
          } else {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Password change',
              message: `You have successfully updated your password.`,
              status: 'success'
            })
            return resolve(data)
          }
        })
      })
    },
    lmsUserAccountClear ({ commit }) {
      commit('LMS_USER_ACCOUNT_CLEAR')
    },
    lmsUserAccountSecurityClear ({ commit }) {
      commit('LMS_USER_ACCOUNT_SECURITY_CLEAR')
    },
    //
    lmsUserAccountSetPersonalFirstName ({ commit }, value) {
      commit('LMS_USER_ACCOUNT_SET_PERSONAL_FIRSTNAME', value)
    },
    lmsUserAccountSetPersonalLastName ({ commit }, value) {
      commit('LMS_USER_ACCOUNT_SET_PERSONAL_LASTNAME', value)
    },
    lmsUserAccountSetPersonalEmail ({ commit }, value) {
      commit('LMS_USER_ACCOUNT_SET_PERSONAL_EMAIL', value)
    },
    lmsUserAccountSetPersonalCustomField ({ commit }, value) {
      commit('LMS_USER_ACCOUNT_SET_PERSONAL_CUSTOMFIELD', value)
    },
    lmsUserAccountSetSecurityOldPassword ({ commit }, value) {
      commit('LMS_USER_ACCOUNT_SET_SECURITY_OLDPASSWORD', value)
    },
    lmsUserAccountSetSecurityNewPassword ({ commit }, value) {
      commit('LMS_USER_ACCOUNT_SET_SECURITY_NEWPASSWORD', value)
    },
    lmsUserAccountSetSecurityConfirmNewPassword ({ commit }, value) {
      commit('LMS_USER_ACCOUNT_SET_SECURITY_CONFIRMNEWPASSWORD', value)
    }
  }
}

export default lmsUserAccount
