<template>
    <footer
        v-if="allowFooter"
        class="footer"
        id="footer"
    >
        <div class="container">
            <template v-if="workspaceCustomisationHelpdeskPhone || workspaceCustomisationHelpdeskEmail">
                <div class="footer__helpdesk">
                    <component-title :size="'small'">Help and support</component-title>
                    <component-text
                        :class="'footer__helpdesk_details'"
                        :is-slot="true"
                    >
                        Please contact us if you need help or support with your learning:
                        <template v-if="workspaceCustomisationHelpdeskEmail"><a :href="'mailto:' + workspaceCustomisationHelpdeskEmail">{{ workspaceCustomisationHelpdeskEmail }}</a></template>
                        <template v-if="workspaceCustomisationHelpdeskPhone && workspaceCustomisationHelpdeskEmail"> / </template>
                        <template v-if="workspaceCustomisationHelpdeskPhone">{{ workspaceCustomisationHelpdeskPhone }}</template>
                    </component-text>
                </div>
            </template>
            <div class="footer__links">
                <div class="columns is-block-mobile has-text-centered-mobile column--attribution">
                    <div class="column">
                        <component-logo></component-logo>
                    </div>
                    <div class="column">
                        <nav aria-label="Footer menu" class="footer__links_nav">
                            <div class="columns is-block-mobile is-variable">
                                <div class="column has-text-centered">
                                    <component-button
                                        :name="'footer-links-terms'"
                                        :theme="''"
                                        :size="'medium'"
                                        :text="true"
                                        :to-external="'https://www.melearning.co.uk/terms-and-conditions'"
                                        :target="true"
                                    >Terms and conditions
                                    </component-button>
                                </div>
                                <div class="column has-text-centered">
                                    <component-button
                                        :name="'footer-links-terms'"
                                        :theme="''"
                                        :size="'medium'"
                                        :text="true"
                                        :to-external="'https://www.melearning.co.uk/privacy-and-cookie-policy'"
                                        :target="true"
                                    >Privacy and cookie policy
                                    </component-button>
                                </div>
                                <div class="column has-text-centered">
                                    <component-button
                                        :name="'footer-links-reset-onboarding'"
                                        :theme="''"
                                        :size="'medium'"
                                        :text="true"
                                        :loading="true"
                                        @click="this.resetOnboarding"
                                    >Reset welcome and help banners
                                    </component-button>
                                </div>
                                <div class="column has-text-centered">
                                    <component-button
                                        :name="'footer-links-release-notes'"
                                        :theme="''"
                                        :size="'medium'"
                                        :text="true"
                                        :to-external="'https://formguide.melearning.co.uk/hc/en-gb/articles/360014342177-Release-notes'"
                                        :target="true"
                                    >Release notes
                                    </component-button>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div class="column has-text-right-tablet">
                        <component-text>Copyright ©{{ copyrightYear() }} Me Learning Ltd</component-text>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import ComponentText from '@/components/default/shared/ComponentText'
import ComponentLogo from '@/components/default/shared/ComponentLogo'
import ComponentButton from '@/components/default/shared/ComponentButton'

export default {
  name: 'the-footer',
  components: {
    ComponentButton,
    ComponentLogo,
    ComponentText,
    ComponentTitle: () => import('@/components/default/shared/ComponentTitle')
  },
  computed: {
    allowFooter () {
      return !this.$route.meta.hiddenFooter
    },
    workspaceCustomisationHelpdeskEmail () {
      return this.$store.getters.defaultWorkspaceCustomisationHelpdeskEmail
    },
    workspaceCustomisationHelpdeskPhone () {
      return this.$store.getters.defaultWorkspaceCustomisationHelpdeskPhone
    }
  },
  methods: {
    copyrightYear: function () {
      return new Date().getFullYear()
    },
    resetOnboarding () {
      this.$store.dispatch('defaultInductionMessagesReset').finally(() => {
        this.$eventBus.$emit('stop-reset-onboarding')
      })
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/layout/footer/footer";
</style>
