import axios from 'axios'
import api from '@/api'

const data = {
  data: {
    hideEventsCatalogue: false
  },
  custom: {
    requestLoaded: false
  },
  default: {}
}

const defaultCustomerOptions = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getDefaultCustomerOptions: state => {
      return state.data
    },
    getDefaultCustomerOptionsHideEventsCatalogue: state => {
      return state.data.hideEventsCatalogue
    }
  },
  mutations: {
    DEFAULT_CUSTOMER_OPTIONS_GET (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload)) || JSON.parse(JSON.stringify(data.data))

      state.data = payloadData
    },
    DEFAULT_CUSTOMER_OPTIONS_SET_CUSTOM_REQUESTLOADED (state, payload) {
      state.custom.requestLoaded = payload
    }
  },
  actions: {
    defaultCustomerOptionsGetOptions ({ commit, getters, state }, data) {
      return new Promise((resolve, reject) => {
        const isUserLoggedInToWorkspace = Boolean(getters.getAuthUserSecondStageTokenDataWorkspaceId)
        const customerId = data && data.customerId ? data.customerId : getters.getAuthUserSecondStageTokenDataCustomerId
        const force = data && data.force ? data.force : false
        const requestLoaded = state.custom.requestLoaded

        if ((!customerId || !isUserLoggedInToWorkspace || requestLoaded) && !force) return

        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/customer/${customerId}/options`
        }).then(response => {
          commit('DEFAULT_CUSTOMER_OPTIONS_GET', response.data)
          commit('DEFAULT_CUSTOMER_OPTIONS_SET_CUSTOM_REQUESTLOADED', true)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default defaultCustomerOptions
