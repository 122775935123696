var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header__top_account_nav"},[_c('div',{staticClass:"header__top_account_nav_items"},[_c('nav',{attrs:{"aria-label":"Top navigation"}},[_c('div',{staticClass:"columns is-block-mobile fullheight is-variable is-0"},[_vm._l((_vm.itemsOutput),function(item,index){return [(item.separator)?_c('div',{key:`${index}-separator`,class:[
                        'column',
                        'is-hidden-mobile',
                        'is-narrow'
                    ]},[_c('div',{staticClass:"header__top_account_nav_items_separator"},[_c('component-separator',{attrs:{"vertical":true}})],1)]):_vm._e(),_c('div',{key:index,class:[
                    'column',
                    item.name !== '' ? 'is-narrow' : '',
                    index === 0 ? 'is-hidden-mobile' : ''
                    ]},[_c('component-button',{class:[
                            'header__top_nav_item',
                            `header__top_nav_item--${item.name}`,
                            item.name === '' ? `header__top_nav_item--blank` : ''
                        ],attrs:{"to":{ name: item.routeName },"theme":'nav',"inverse":_vm.inverseStyle,"size":'',"icon-raw":item.icon,"icon":item.icon,"icon-name":item.iconName,"align":'left',"name":`header-top-account-nav-items-button-${item.name}`}},[_vm._v(_vm._s(!item.icon ? _vm.toCapitalize(item.title) : '')+" ")])],1)]})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }