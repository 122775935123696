<template>
    <div class="filter__sort_button">
        <component-button
            :name="'filter-sort-button-switch'"
            :icon="true"
            :icon-name="'chevron-down'"
            :icon-raw="true"
            @click="$emit('switch-menu')"
        >{{ $t('filter.sort.buttonSortBy') }}</component-button>
    </div>
</template>

<script>
import ComponentButton from '@/components/default/shared/ComponentButton'
export default {
  name: 'filter-sort-button',
  components: { ComponentButton }
}
</script>
