import axios from 'axios'
import api from '@/api'

const data = {
  data: [],
  pagination: {}
}

const ebsEnrolments = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsCertificatesData: state => {
      let certificates = JSON.parse(JSON.stringify(state.data))
      let isDefaultCertificate = certificates.length ? certificates.find(obj => obj.workspaceDefault) : false
      certificates.unshift(
        {
          name: 'Me Learning Certificate of Completion',
          blank: true,
          workspaceDefault: !isDefaultCertificate
        })
      return certificates
    },
    getEbsCertificatesDataMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems || !rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.name,
          value: item.id
        }
      })
    },
    getEbsCertificatesDataMappedIdNameStatus: state => {
      const rawItems = state.data
      if (!rawItems || !rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name
        }
      })
    },
    getEbsCertificatesPagination: state => {
      return state.pagination
    },
    getEbsCertificatesDefault: state => {
      let certificates = JSON.parse(JSON.stringify(state.data))
      let defaultCertificate = certificates.length ? certificates.find(obj => obj.workspaceDefault) : null

      return defaultCertificate
    }
  },
  mutations: {
    EBS_CERTIFICATES_SET_DATA (state, payload) {
      state.data = payload
    },
    EBS_CERTIFICATES_SET_PAGINATION (state, payload) {
      state.pagination = payload
    },
    EBS_CERTIFICATES_CLEAR_DATA (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    EBS_CERTIFICATES_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    ebsCertificatesGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data && data.requestData && data.requestData.workspaceId ? data.requestData.workspaceId : ''

        axios({
          method: 'get',
          url: `${api.learningactivities}/certificate/content${workspaceId ? `?workspaceId=${workspaceId}` : ``}`
        }).then(response => {
          commit('EBS_CERTIFICATES_SET_DATA', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ebsEnrolments
