import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { fromLocalTimeToUTC, fromUTCTimeToLocal } from '@/functions'

const data = {
  data: {
    id: '',
    customerId: '',
    customerName: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    isOwner: false,
    isSiteAdmin: true,
    isTrainer: false,
    trainerWorkingHours: [
      {
        weekDay: 0,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      },
      {
        weekDay: 1,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      },
      {
        weekDay: 2,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      },
      {
        weekDay: 3,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      },
      {
        weekDay: 4,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      },
      {
        weekDay: 5,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      },
      {
        weekDay: 6,
        workFrom: '',
        workFromLocal: '',
        workTo: '',
        workToLocal: ''
      }
    ],
    adminAlreadyExists: false
  },
  default: {}
}

const ebsAdmin = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getAdmin: state => {
      return state.data
    },
    //
    getAdminId: state => {
      return state.data.id
    },
    getAdminFirstName: state => {
      return state.data.firstName
    },
    getAdminLastName: state => {
      return state.data.lastName
    },
    getAdminEmail: state => {
      return state.data.emailAddress
    },
    getAdminCustomerId: state => {
      return state.data.customerId
    },
    getAdminCustomerName: state => {
      return state.data.customerName
    },
    getAdminIsOwner: state => {
      return state.data.isOwner
    },
    getAdminIsSiteAdmin: state => {
      return state.data.isSiteAdmin
    },
    getAdminIsTrainer: state => {
      return state.data.isTrainer
    },
    getAdminTrainerWorkingHours: state => {
      return state.data.trainerWorkingHours
    },
    getAdminAdminAlreadyExists: state => {
      return state.data.adminAlreadyExists
    },
    getAdminDefaultFirstName: state => {
      return state.default.firstName
    },
    getAdminDefaultLastName: state => {
      return state.default.lastName
    }
  },
  mutations: {
    GET_ADMIN (state, payload) {
      if (payload.trainerWorkingHours) {
        for (let i = 0; i < payload.trainerWorkingHours.length; i++) {
          const timeStart = fromUTCTimeToLocal(payload.trainerWorkingHours[i].workFrom)
          const timeEnd = fromUTCTimeToLocal(payload.trainerWorkingHours[i].workTo)
          payload.trainerWorkingHours[i].weekday = undefined
          payload.trainerWorkingHours[i].weekDay = i
          payload.trainerWorkingHours[i].workFromLocal = timeStart || undefined
          payload.trainerWorkingHours[i].workToLocal = timeEnd || undefined
        }
      } else {
        payload.trainerWorkingHours = JSON.parse(JSON.stringify(data.data.trainerWorkingHours))
      }

      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    //
    CLEAR_ADMIN (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    RESET_ADMIN (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    SET_ADMIN_ID (state, payload) {
      state.data.id = payload
    },
    SET_ADMIN_FIRST_NAME (state, payload) {
      state.data.firstName = payload
    },
    SET_ADMIN_LAST_NAME (state, payload) {
      state.data.lastName = payload
    },
    SET_ADMIN_EMAIL (state, payload) {
      state.data.emailAddress = payload
    },
    SET_ADMIN_ISOWNER (state, payload) {
      state.data.isOwner = payload
    },
    SET_ADMIN_ISSITEADMIN (state, payload) {
      state.data.isSiteAdmin = payload
    },
    SET_ADMIN_ISTRAINER (state, payload) {
      state.data.isTrainer = payload
    },
    SET_ADMIN_TRAINERWORKINGHOURS (state, payload) {
      if (state.data.trainerWorkingHours[payload].workFrom) {
        state.data.trainerWorkingHours[payload].workFrom = ''
        state.data.trainerWorkingHours[payload].workFromLocal = ''
      } else {
        state.data.trainerWorkingHours[payload].workFrom = fromLocalTimeToUTC('09:00')
        state.data.trainerWorkingHours[payload].workFromLocal = '09:00'
      }
      if (state.data.trainerWorkingHours[payload].workTo) {
        state.data.trainerWorkingHours[payload].workTo = ''
        state.data.trainerWorkingHours[payload].workToLocal = ''
      } else {
        state.data.trainerWorkingHours[payload].workTo = fromLocalTimeToUTC('17:00')
        state.data.trainerWorkingHours[payload].workToLocal = '17:00'
      }
    },
    SET_ADMIN_TRAINERWORKINGHOURS_WORKFROMLOCAL (state, payload) {
      state.data.trainerWorkingHours[payload.key].workFromLocal = payload.value
    },
    SET_ADMIN_TRAINERWORKINGHOURS_WORKTOLOCAL (state, payload) {
      state.data.trainerWorkingHours[payload.key].workToLocal = payload.value
    },
    SET_ADMIN_TRAINERWORKINGHOURS_WORKFROM (state, payload) {
      const time = state.data.trainerWorkingHours[payload.key].workFromLocal
      if (!(time)) return
      state.data.trainerWorkingHours[payload.key].workFrom = fromLocalTimeToUTC(time)
    },
    SET_ADMIN_TRAINERWORKINGHOURS_WORKTO (state, payload) {
      const time = state.data.trainerWorkingHours[payload.key].workToLocal
      if (!(time)) return
      state.data.trainerWorkingHours[payload.key].workTo = fromLocalTimeToUTC(time)
    },
    SET_ADMIN_ALREADYEXISTS (state, payload) {
      state.data.adminAlreadyExists = payload
    }
  },
  actions: {
    getAdmin ({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const userId = data.userId

        const authUserGetUserSSO = () => {
          return dispatch('authUserGetUserSSO', userId)
        }
        const getTrainer = () => {
          return dispatch('getTrainer', userId)
        }

        authUserGetUserSSO().then(response => {
          const responseData = response
          const userProfiles = responseData.profiles
          const customerRoles = responseData.customerRoles
          const customerId = userProfiles[0].customerId
          const customerName = userProfiles[0].customerName

          let dataPayload = {}

          dataPayload.id = responseData.id
          dataPayload.firstName = responseData.firstName
          dataPayload.lastName = responseData.lastName
          dataPayload.emailAddress = responseData.emailAddress
          dataPayload.isOwner = customerRoles.includes('owner')
          dataPayload.isSiteAdmin = customerRoles.includes('master_administrator') || customerRoles.includes('owner')
          dataPayload.isTrainer = customerRoles.includes('trainer')
          dataPayload.customerId = customerId
          dataPayload.customerName = customerName

          if (customerRoles.includes('trainer')) {
            getTrainer().then(trainerResponse => {
              const trainerResponseData = trainerResponse
              const workingHours = trainerResponseData.workingHours

              dataPayload.trainerWorkingHours = workingHours

              commit('GET_ADMIN', dataPayload)
              resolve()
            }).catch(error => {
              reject(new Error(error))
            })
          } else {
            commit('GET_ADMIN', dataPayload)
            resolve()
          }
        }).catch(error => {
          reject(new Error(error))
        })
      })
    },
    //
    clearAdmin ({ commit }) {
      commit('CLEAR_ADMIN')
    },
    updateAdminDetails ({ dispatch, state }) {
      return new Promise((resolve) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        let stateDefaultData = JSON.parse(JSON.stringify(state.default))
        let adminType

        if (stateData.isOwner) {
          adminType = 'Owner'
        } else if (stateData.isSiteAdmin) {
          adminType = 'Admin'
        } else if (stateData.isTrainer) {
          adminType = 'Trainer'
        } else {
          adminType = 'User'
        }

        const lmsUserAccountUpdateDetails = () => {
          const userData = {
            id: stateData.id,
            firstName: stateData.firstName,
            lastName: stateData.lastName,
            emailAddress: stateData.emailAddress
          }

          return dispatch('lmsUserAccountUpdateDetails', userData)
        }

        const lmsUserAccountUpdateEmail = () => {
          const userData = {
            id: stateData.id,
            emailAddress: stateData.emailAddress
          }

          return dispatch('lmsUserAccountUpdateEmail', userData)
        }

        let requestsArr = []
        let requestsCounter = 0

        if (
          stateData.firstName !== stateDefaultData.firstName ||
        stateData.lastName !== stateDefaultData.lastName
        ) {
          requestsArr.push(lmsUserAccountUpdateDetails)
        }

        if (stateData.emailAddress !== stateDefaultData.emailAddress) {
          requestsArr.push(lmsUserAccountUpdateEmail)
        }

        if (requestsArr.length) {
          for (let fn of requestsArr) {
            fn().finally(() => {
              requestsCounter++
              if (Number(requestsCounter) === Number(requestsArr.length)) {
                dispatch('defaultNotificationToastsAdd', {
                  title: `${adminType} details successfully updated`,
                  message: ``,
                  status: 'success'
                })
                resolve()
              }
            })
          }
        } else {
          dispatch('defaultNotificationToastsAdd', {
            title: `${adminType} details successfully updated`,
            message: ``,
            status: 'success'
          })
          resolve()
        }
      })
    },
    updateAdminRoles ({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        const userId = stateData.id
        const customerId = stateData.customerId
        let bodyData = {}
        let adminType

        bodyData.roles = []

        if (stateData.isOwner) {
          bodyData.roles.push('owner')
          bodyData.roles.push('master_administrator')
        }
        if (stateData.isSiteAdmin) {
          bodyData.roles.push('master_administrator')
        }
        if (stateData.isTrainer) {
          bodyData.roles.push('trainer')
          bodyData.workingDaysAndHours = stateData.trainerWorkingHours
        }

        if (stateData.isOwner) {
          adminType = 'Owner'
        } else if (stateData.isSiteAdmin) {
          adminType = 'Admin'
        } else if (stateData.isTrainer) {
          adminType = 'Trainer'
        } else {
          adminType = 'User'
        }

        bodyData.roles = bodyData.roles.filter((obj, index) => {
          return bodyData.roles.findIndex(findObj => findObj === obj) === index
        })

        axios({
          method: 'patch',
          url: `${api.sso}/v2/master-admin/${userId}/${customerId}`,
          data: bodyData
        }).then(() => {
          dispatch('defaultNotificationToastsAdd', {
            title: `${adminType} roles successfully updated`,
            message: ``,
            status: 'success'
          })
          resolve()
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: `${adminType} roles not updated`,
            message: ``,
            status: 'error'
          })
          reject(new Error(error))
        })
      })
    },
    createAdmin ({ state, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        let bodyData = {}
        let adminType

        const customerId = getters.getAuthUserSecondStageTokenDataCustomerId || getters.getAdminsCustomCustomerId

        bodyData.firstName = stateData.firstName
        bodyData.lastName = stateData.lastName
        bodyData.emailAddress = stateData.emailAddress
        bodyData.customerId = customerId
        bodyData.roles = []

        if (stateData.isOwner) {
          bodyData.roles.push('owner')
          bodyData.roles.push('master_administrator')
        }
        if (stateData.isSiteAdmin) {
          bodyData.roles.push('master_administrator')
        }
        if (stateData.isTrainer) {
          bodyData.roles.push('trainer')
          bodyData.workingDaysAndHours = stateData.trainerWorkingHours
        }

        if (stateData.isOwner) {
          adminType = 'Owner'
        } else if (stateData.isSiteAdmin) {
          adminType = 'Admin'
        } else if (stateData.isTrainer) {
          adminType = 'Trainer'
        } else {
          adminType = 'User'
        }

        bodyData.roles = bodyData.roles.filter((obj, index) => {
          return bodyData.roles.findIndex(findObj => findObj === obj) === index
        })

        axios({
          method: 'post',
          url: `${api.sso}/v2/master-admin`,
          data: bodyData
        }).then(() => {
          router.push({ name: 'ebs-admin-list' })
          dispatch('defaultNotificationToastsAdd', {
            title: `${adminType} successfully invited`,
            message: ``,
            status: 'success'
          })
          resolve()
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: `${adminType} not invited`,
            message: ``,
            status: 'error'
          })
          reject(new Error(error))
        })
      })
    },
    adminCheckEmail ({ commit, dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        const authUserCheckExistsSSO = dispatch('authUserCheckExistsSSO', data)
        const alreadyFoundAUser = state.data.id

        authUserCheckExistsSSO.then(response => {
          if (response === null) reject(new Error('null'))

          const firstName = response.firstName || ''
          const lastName = response.lastName || ''
          const id = response.id || ''
          const customerRoles = response.customerRoles

          if (customerRoles && customerRoles.length) {
            commit('SET_ADMIN_ALREADYEXISTS', true)
            if (customerRoles.includes('admin')) {
              commit('SET_ADMIN_ISSITEADMIN', true)
            } else {
              commit('SET_ADMIN_ISSITEADMIN', false)
            }
            if (customerRoles.includes('owner')) {
              commit('SET_ADMIN_ISOWNER', true)
            } else {
              commit('SET_ADMIN_ISOWNER', false)
            }
            if (customerRoles.includes('trainer')) {
              commit('SET_ADMIN_ISTRAINER', true)
            } else {
              commit('SET_ADMIN_ISTRAINER', false)
            }
          } else {
            commit('SET_ADMIN_ISSITEADMIN', false)
            commit('SET_ADMIN_ISOWNER', false)
            commit('SET_ADMIN_ISTRAINER', false)
            commit('SET_ADMIN_ALREADYEXISTS', false)
          }

          commit('SET_ADMIN_FIRST_NAME', firstName)
          commit('SET_ADMIN_LAST_NAME', lastName)
          commit('SET_ADMIN_ID', id)
          resolve(response)
        }).catch(error => {
          if (alreadyFoundAUser) {
            commit('SET_ADMIN_FIRST_NAME', '')
            commit('SET_ADMIN_LAST_NAME', '')
          }
          commit('SET_ADMIN_ID', '')
          commit('SET_ADMIN_ALREADYEXISTS', false)
          reject(error)
        })
      })
    },
    //
    setAdminName ({ commit }, value) {
      commit('SET_ADMIN_NAME', value)
    },
    setAdminFirstName ({ commit }, value) {
      commit('SET_ADMIN_FIRST_NAME', value)
    },
    setAdminLastName ({ commit }, value) {
      commit('SET_ADMIN_LAST_NAME', value)
    },
    setAdminEmail ({ commit }, value) {
      commit('SET_ADMIN_EMAIL', value)
    },
    setAdminIsOwner ({ commit }, value) {
      commit('SET_ADMIN_ISOWNER', value)
    },
    setAdminIsSiteAdmin ({ commit }, value) {
      commit('SET_ADMIN_ISSITEADMIN', value)
    },
    setAdminIsTrainer ({ commit }, value) {
      commit('SET_ADMIN_ISTRAINER', value)
    },
    setAdminTrainerWorkingHours ({ commit }, value) {
      commit('SET_ADMIN_TRAINERWORKINGHOURS', value)
    },
    setAdminWorkingHoursWorkFrom ({ commit }, data) {
      commit('SET_ADMIN_TRAINERWORKINGHOURS_WORKFROMLOCAL', { key: data.key, value: data.value })
      commit('SET_ADMIN_TRAINERWORKINGHOURS_WORKFROM', { key: data.key, value: data.value })
    },
    setAdminWorkingHoursWorkTo ({ commit }, data) {
      commit('SET_ADMIN_TRAINERWORKINGHOURS_WORKTOLOCAL', { key: data.key, value: data.value })
      commit('SET_ADMIN_TRAINERWORKINGHOURS_WORKTO', { key: data.key, value: data.value })
    }
  }
}

export default ebsAdmin
