const api = {
  infrastructure: process.env.VUE_APP_API_ENDPOINT,
  bookingservice: process.env.VUE_APP_API_BOOKING_SERVICE,
  classroom: process.env.VUE_APP_CLASSROOM_API,
  courseCatalog: process.env.VUE_APP_COURSE_CATALOG_API,
  files: process.env.VUE_APP_JS_UPLOAD_ENDPOINT,
  googlekey: process.env.VUE_APP_GOOGLE_API_KEY,
  googletagmanagerkey: process.env.VUE_APP_GOOGLE_TAG_MANAGER_API_KEY,
  sso: process.env.VUE_APP_SINGLE_SIGN_ON_API,
  subscriptionmanager: process.env.VUE_APP_SUBSCRIPTION_MANAGER,
  emailservice: process.env.VUE_APP_EMAIL_SERVICE,
  pdfprinter: process.env.VUE_APP_PDFPRINTER_API,
  learningactivities: process.env.VUE_APP_LEARNING_ACTIVITIES_API,
  reportengine: process.env.VUE_APP_REPORT_ENGINE,
  euma: process.env.VUE_APP_EUMA,
  admintools: process.env.VUE_APP_ADMINTOOLS,
  domain: process.env.VUE_APP_LMS_SAAS_URL
}
export default api
