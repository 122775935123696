import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const ebsLicences = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsLicences: state => {
      return state.data
    },
    getEbsLicencesPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    EBS_LICENCES_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    EBS_LICENCES_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    },
    EBS_LICENCES_SET (state, payload) {
      state.data = payload
    },
    EBS_LICENCES_SET_PAGINATION (state, payload) {
      state.pagination = payload
    }
  },
  actions: {
    ebsLicencesGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        commit('EBS_LICENCES_CLEAR')
        commit('EBS_LICENCES_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/list-offers${queryUrlParams}`
        }).then(response => {
          commit('EBS_LICENCES_SET', response.data.data)
          commit('EBS_LICENCES_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default ebsLicences
