import axios from 'axios'
import api from '@/api'
import { toISODateUTC } from '@/functions'

const lmsSuperadminCustomerSubscription = {
  state: {
    data: {
      createdBy: '',
      customerId: '',
      fromDate: '',
      serviceKey: '',
      status: '',
      toDate: ''
    },
    default: {},
    custom: {
      createError: ''
    }
  },
  getters: {
    getLmsSuperadminCustomerSubscriptionFromDate: state => {
      return state.data.fromDate
    },
    getLmsSuperadminCustomerSubscriptionToDate: state => {
      return state.data.toDate
    },
    getLmsSuperadminCustomerSubscriptionCustomCreateerror: state => {
      return state.custom.createError
    }
  },
  mutations: {
    LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_CLEAR (state) {
      state.data = {
        createdBy: '',
        customerId: '',
        fromDate: '',
        serviceKey: '',
        status: '',
        toDate: ''
      }
      state.default = JSON.parse(JSON.stringify(state.data))
    },
    LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_SET_FROMDATE (state, payload) {
      state.data.fromDate = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_SET_TODATE (state, payload) {
      state.data.toDate = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_CLEAR_CUSTOM_CREATEERROR (state) {
      state.custom.createError = ''
    },
    LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_SET_CUSTOM_CREATEERROR (state, payload) {
      state.custom.createError = payload
    }
  },
  actions: {
    lmsSuperadminCustomerSubscriptionCreate ({ commit, state, getters, rootState, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let data = JSON.parse(JSON.stringify(state.data))
        data.customerId = getters.getLmsSuperadminCustomerId
        data.createdBy = getters.getAuthUserSecondStageTokenDataID
        data.status = toISODateUTC(data.fromDate) <= toISODateUTC(new Date()) ? 'active' : 'inactive'
        data.serviceKey = payload?.serviceKey || rootState.route.params.serviceKey
        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/subscriptions`,
          data: data
        }).then(function (response) {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Subscription create',
            message: 'Subscription has been successfully created.',
            status: 'success'
          })
          commit('LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_CLEAR')
          resolve(response)
        }).catch(function (error) {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Subscription create',
            message: `Subscription hasn't been created.`,
            status: 'error'
          })
          commit('LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_SET_CUSTOM_CREATEERROR', error.response.data.errors.message)
          reject(new Error(error))
        })
      })
    },
    lmsSuperadminCustomerSubscriptionCancel ({ commit, getters, dispatch }, subscriptionId) {
      return new Promise((resolve, reject) => {
        let data = {}
        data.subscriptionId = subscriptionId
        data.cancelledBy = getters.getAuthUserSecondStageTokenDataID
        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/subscriptions/cancel`,
          data: data
        }).then(function (response) {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Subscription cancel',
            message: 'Subscription has been successfully cancelled.',
            status: 'success'
          })
          commit('LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_CLEAR')
          resolve(response)
        }).catch(function (error) {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Subscription cancel',
            message: `Subscription hasn't been cancelled.`,
            status: 'error'
          })
          reject(new Error(error))
        })
      })
    },
    lmsSuperadminCustomerSubscriptionClear ({ commit }, data) {
      commit('LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_CLEAR', data)
      commit('LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_CLEAR_CUSTOM_CREATEERROR', data)
    },
    //
    lmsSuperadminCustomerSubscriptionSetFromDate ({ commit }, data) {
      commit('LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_SET_FROMDATE', data)
    },
    lmsSuperadminCustomerSubscriptionSetToDate ({ commit }, data) {
      commit('LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_SET_TODATE', data)
    },
    lmsSuperadminCustomerSubscriptionSetCustomCreateerror ({ commit }, data) {
      commit('LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_SET_CUSTOM_CREATEERROR', data)
    }
  }
}

export default lmsSuperadminCustomerSubscription
