<template>
    <div :class="[
    'component',
    'component__loading',
    size ? `component__loading--${size}` : ''
    ]">
        <div class="component__loading_icon">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <template v-if="$slots.default">
            <div class="component__loading_text">
                <slot></slot>
            </div>
        </template>
    </div>
</template>

<script>
export default {
  name: 'component-loading',
  data () {
    return {
      errorLoading: false
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    size: {
      type: String
    }
  },
  methods: {
    showError: function () {
      this.errorLoading = true
    },
    hideError: function () {
      this.errorLoading = false
    }
  },
  mounted () {
    this.$eventBus.$on(`stop-loading${this.name ? '-' + this.name : ''}`, this.hideError)
    this.$eventBus.$on(`error-loading${this.name ? '-' + this.name : ''}`, this.showError)
  },
  beforeDestroy () {
    this.$eventBus.$off(`stop-loading${this.name ? '-' + this.name : ''}`)
    this.$eventBus.$off(`error-loading${this.name ? '-' + this.name : ''}`)
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentloading";
</style>
