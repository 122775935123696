import axios from 'axios'
import api from '@/api'

const ebsServices = {
  state: {
    data: '',
    default: ''
  },
  getters: {
    getServices: state => {
      return state.data
    }
  },
  mutations: {
    GET_SERVICES (state, payload) {
      for (let i = 0; i < payload.data.length; i++) {
        payload.data[i].referenceType = 'sector'
        payload.data[i].reference = {
          domain: payload.domain,
          id: payload.data[i].id
        }
        payload.data[i].value = ''
        for (let j = 0; j < payload.data[i].services.length; j++) {
          payload.data[i].services[j].referenceType = 'service'
          payload.data[i].services[j].reference = {
            domain: payload.domain,
            id: payload.data[i].services[j].id
          }
          payload.data[i].services[j].value = ''
        }
      }
      state.data = payload.data
      state.default = JSON.parse(JSON.stringify(payload.data))
    },
    //
    CLEAR_SERVICES (state) {
      state.data = ''
    },
    RESET_SERVICES (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    SET_SERVICE_VALUE (state, payload) {
      const value = payload.value
      const parentIndex = payload.parentIndex
      const childIndex = payload.childIndex
      if (childIndex > -1) {
        state.data[parentIndex].services[childIndex].value = value
      } else {
        state.data[parentIndex].value = value
      }
    },
    SET_SERVICES_DEFAULT (state, payload) {
      if (!payload) return
      state.data = JSON.parse(JSON.stringify(payload))
      state.default = JSON.parse(JSON.stringify(payload))
    }
  },
  actions: {
    getServices ({ commit, getters }) {
      if (!getters.getAuthUserSecondStageTokenDataDomain) return

      axios
        .get(`${api.subscriptionmanager}/get-sectors-services/${getters.getAuthUserSecondStageTokenDataDomain}`)
        .then(function (response) {
          commit('GET_SERVICES', { data: response.data, domain: getters.getAuthUserSecondStageTokenDataDomain })
        })
    },
    setServicesDefault ({ commit }, data) {
      commit('SET_SERVICES_DEFAULT', data)
    }
  }
}

export default ebsServices
